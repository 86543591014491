import { Avatar, Popover, Typography } from 'antd'
import { useSelector } from 'react-redux'
import { CaretUpDown } from '@phosphor-icons/react'

import { useEffect, useState } from 'react'
import TeamsSelectorPopover from './TeamSelectorPopover'

function TeamHeader({ mobile = false }) {
    const { team } = useSelector((state: any) => state.team)
    const [open, setOpen] = useState(false)

    const TextSizeDependingLetters = (length: number = 0) => {
        let defaultSize = '12px'
        if (length > 15) defaultSize = '12px'
        if (length > 20) defaultSize = '10px'
        if (length > 25) defaultSize = '8px'
        if (length > 30) defaultSize = '8px'
        if (length > 35) defaultSize = '8px'

        return defaultSize
    }

    useEffect(() => {}, [team])

    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen)
    }

    return (
        <>
            <div
                className="d-flex flex-row align-items-center"
                onClick={() => {
                    // dispatch(openModal('teamSelectionVisible'))
                    // dispatch(openModal('integrationModalVisible'))
                }}
                style={{
                    // borderBottom: '1px solid #D8DAE5'
                    padding: '15px 10px',
                    cursor: 'pointer',
                    backgroundColor: 'white',
                    minHeight: '63px',
                }}
            >
                {/* <Dropdown menu={{ items }} trigger={['click']}>
                    

                </Dropdown> */}

                <Popover
                    content={<TeamsSelectorPopover closePop={() => setOpen(false)} />}
                    trigger="click"
                    placement="right"
                    open={open}
                    onOpenChange={handleOpenChange}
                    arrow={false}
                >
                    {mobile ? (
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ width: '50px', height: '30px', borderRadius: '50%' }}
                        >
                            <Avatar size={25} src={team?.brand?.logo} />
                        </div>
                    ) : (
                        <div
                            className="d-flex align-items-center rounded-md"
                            style={{ width: '100%', border: '1px solid var(--neutral-4)', padding: '5px' }}
                        >
                            <Avatar style={{ backgroundColor: '#D3D9FE' }} size={25} src={team?.brand?.logo} />
                            <Typography.Text
                                style={{
                                    flex: 1,
                                    padding: '0px 10px',
                                    maxLines: 1,
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    fontSize: TextSizeDependingLetters(team?.brand?.alias?.length),
                                }}
                                className="highliteheader"
                            >
                                {team?.brand?.alias}
                            </Typography.Text>
                            <div
                                className="d-flex flex-column align-items-center justify-content-center"
                                style={{
                                    backgroundColor: 'transparent',
                                    padding: '3px',
                                    borderRadius: '4px',
                                }}
                            >
                                <CaretUpDown className="neutral-2" style={{ fontSize: '16px' }} />
                            </div>
                        </div>
                    )}
                </Popover>
            </div>
        </>
    )
}

export default TeamHeader
