import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import WebooksCreateForm from '../components/Forms/WebhooksCreateForm'
import { closeModal } from "../context/modalsSlice";
import { setData } from "../context/dataSlice";

export function EditWebhooksModal() {


    const dispatch = useDispatch()
    const { editWebhooksModalVisible } = useSelector((state: any) => state.modals)
    const { preloadedWebhookData } = useSelector((state: any) => state.data)

    return (
        <Modal
            open={editWebhooksModalVisible}
            footer={null}
            title="Editar webhook"
            onCancel={() => {
                dispatch(closeModal('editWebhooksModalVisible'))
                dispatch(setData({
                    item: 'preloadedWebhookData',
                    data: null
                }))
            }}
        >
            <WebooksCreateForm preloadedWebhookData={preloadedWebhookData} />
        </ Modal>
    )
}