import { Button, Form, Modal, Row, Typography, message } from 'antd'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { useState } from 'react'
import { TextInput } from '../components/Forms/Inputs'

interface ForgotPasswordModalProps {
    open: boolean
    close: () => void
}

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({ open, close }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    /**
     * It sends a password reset email to the user's email address
     * @param vals - The values of the form.
     */
    const RecoverAccount = async (vals: any) => {
        try {
            setLoading(true)
            await sendPasswordResetEmail(getAuth(), vals.email)
            setLoading(false)
            message.success('Hemos enviado un correo para re-establecer tu contraseña')
            close()
        } catch (Error) {
            setError(error)
            setLoading(false)
        }
    }

    return (
        <Modal title="Recupera tu contraseña" open={open} onCancel={close} footer={null}>
            <Typography.Text type="secondary">
                Ingresa tu correo para que enviemos un enlace para recuperar tu contraseña.
            </Typography.Text>
            <Form onFinish={RecoverAccount} style={{ marginTop: '20px' }} layout="vertical">
                <Form.Item
                    label="Correo electrónico"
                    name={'email'}
                    rules={[{ required: true, message: 'Por favor escribe tu correo', type: 'email' }]}
                >
                    <TextInput name={'correo'} placeholder="Correo electrónico" />
                </Form.Item>
                {error && <Typography.Text type="danger">{error}</Typography.Text>}
                <Row justify="center">
                    <Button htmlType="submit" loading={loading} type="primary">
                        Recuperar cuenta
                    </Button>
                </Row>
            </Form>
        </Modal>
    )
}

export default ForgotPasswordModal
