import { Alert, Button, Divider, Form, message, Modal, Row, Space, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../context/modalsSlice'
import { IntegrationBulletPoint, IntegrationOnboarding } from '../../components/Integrations/IntegrationOnboarding'
import { integrations } from '../../datasets/Integrations'
import { useEffect, useState } from 'react'
import { addParamToUrl } from '../../functions/UrlParams'
import { Copy, Robot } from '@phosphor-icons/react'
import { GetTeamHelper } from '../../context/databaseContextHelpers'
import SwitchHolder from '../../components/Forms/SwitchHolder'
import { SelectInput, TextInput } from '../../components/Forms/Inputs'
import { SignedInternalAPIRequest } from '../../functions/APIRequest'
import { useAuth } from 'reactfire'
import { updateAutomaticInvoicingIntegration } from './helpers'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { paymentForms } from '../../datasets/Fiscal'

export const ClipModal = () => {
    const dispatch = useDispatch()
    const auth = useAuth()
    const { clipModalVisible } = useSelector((state: any) => state.modals)
    const { team } = useSelector((state: any) => state.team)

    const urls = {
        live: `https://us-central1-gigstackpro.cloudfunctions.net/clipconnectionapp/v1/webhook/${team.id}/true`,
        test: `https://us-central1-gigstackpro.cloudfunctions.net/clipconnectionapp/v1/webhook/${team.id}/false`,
    }

    const [loading, setLoading] = useState<null | string>(null)
    const integration = integrations(team).find((i) => i.id === 'clip')
    const [step, setStep] = useState(team?.clip?.completed ? 1 : 0)
    const [automaticInvoicing, setAutomaticInvoicing] = useState(team?.clip?.automaticInvoicing ?? false)
    const [form] = Form.useForm()

    useEffect(() => {
        if (clipModalVisible) {
            addParamToUrl('open', 'clip')
        }
    }, [clipModalVisible])

    const handleSubmit = async () => {
        try {
            setLoading('submit')

            const values = await form.validateFields()

            if(team?.clip?.completed) {
                await updateDoc(doc(getFirestore(), `teams/${team?.id}`), {
                    'clip.defaults.serviceDescription': values.serviceDescription,
                    'clip.defaults.paymentForm': values.paymentForm,
                })
                await GetTeamHelper(team?.id, dispatch)
                setLoading(null)
                
                dispatch(closeModal('clipModalVisible'))
                return message.success('Configuración actualizada')
            }

            const data = await SignedInternalAPIRequest(
                { ...values, teamId: team?.id },
                'clipconnectionapp/v1/connect',
                auth.currentUser
            )

            console.log('Data: ', data)

            if (data?.message) {
                await GetTeamHelper(team?.id, dispatch)
                setAutomaticInvoicing(true)
                message.success('Configuración actualizada')
            }
            setLoading(null)
        } catch (error: any) {
            const errorMessage = error?.message?.includes('401') ? 'Credenciales inválidas' : error?.message
            message.error(errorMessage || 'Ocurrió un error al actualizar la configuración')
            setLoading(null)
        }
    }


    const ToRender = () => {
        switch (step) {
            case 0:
                return (
                    <IntegrationOnboarding
                        logo={integration?.logo ?? ''}
                        title="Conecta Clip con gigstack"
                        description="Cobra y factura automáticamente con Clip"
                        bullets={integration?.bullets as IntegrationBulletPoint[]}
                        IntegrationFooter={
                            <Row justify="space-between" style={{ padding: '20px 24px 20px 24px' }}>
                                <Row>
                                    <Button type="primary" onClick={() => setStep(step + 1)}>
                                        Conectar
                                    </Button>
                                </Row>
                            </Row>
                        }
                    />
                )
            case 1:
                return (
                    <div style={{ padding: '20px 24px' }}>
                        <Typography.Text className="mediumparagraphbold">Conecta tu cuenta en Clip</Typography.Text>
                        <Typography.Text className="smallparagraph descriptions">
                            Conecta tu cuenta y automatiza todas las transacciones que recibas.
                        </Typography.Text>

                        <Form form={form} layout="vertical" style={{ marginTop: '15px' }} onFinish={handleSubmit} initialValues={{
                            currency: 'MXN',
                            paymentForm: '06',
                            amount: '100.00'
                        }}>
                            <Divider />
                            {team?.clip?.completed && (
                                <Space direction='vertical'>
                                    <SwitchHolder
                                        changeState={async () => {
                                            await updateAutomaticInvoicingIntegration({
                                                automaticInvoicing,
                                                dispatch, 
                                                setAutomaticInvoicing,
                                                team,
                                                integrationName: 'clip'
                                            })
                                        }}
                                        value={automaticInvoicing}
                                        switchState={automaticInvoicing}
                                        icon={<Robot className="icon clickable" weight="regular" size={16} />}
                                        title={'Activar facturación automática de clip'}
                                        description="Intentaremos facturar automáticamente tus ventas de clip, si no es posible, crearemos un recibo de venta para que tu cliente genere su factura"
                                    />

                                    <Divider />
                                    
                                    <TextInput defaultValue={team?.clip?.defaults?.serviceDescription || 'Venta de clip'} label="Descripción por defecto para las ordenes" name="serviceDescription" description='Usaremos esta descripción para las órdenes sin descripción' />    
                                    
                                    <SelectInput name='paymentForm' label='Forma de pago por defecto' defaultValue={team?.clip?.defaults?.paymentForm || '06'} options={paymentForms} description='Esta opción se utilizará cuando clip no proporcione el método de pago' />
                                    
                                    <Divider style={{ margin: '0px 0px' }} />

                                    <Row justify={'end'} style={{ marginTop: '20px' }}>
                                        <Button loading={loading === 'submit'} type="primary" htmlType="submit">
                                            Guardar
                                        </Button>
                                    </Row>
                                </Space>
                            )}
                            {!team?.clip?.completed && (
                                <>
                                    <Typography.Text className="mediumparagraphbold">1. Ingresa tus credenciales</Typography.Text>
                                    <p className="smallparagraph">Puedes generar tus credenciales <a href='https://dashboard.developer.clip.mx/credentials' target='_blank' rel='noreferrer'> aquí</a></p>
                                    
                                    <TextInput
                                        label="API Key"
                                        name="apiKey"
                                        rules={[{ required: true, message: 'Por favor ingresa tu API Key' }]}
                                    />
                                    <TextInput
                                        label="Secret Key"
                                        name="secretKey"
                                        rules={[{ required: true, message: 'Por favor ingresa tu Secret Key' }]}
                                    />

                                    <Divider />

                                    <Typography.Text className="mediumparagraphbold">2. Configura el Webhook</Typography.Text>
                                    <p className="smallparagraph">Ingresa la siguiente URL en la <a href='https://dashboard.developer.clip.mx/webhooks' target='_blank' rel='noreferrer'>configuración de Clip</a></p>

                                    <Typography.Text className="mediumparagraphbold" style={{ flex: 1 }}>
                                        Endpoint de pruebas
                                    </Typography.Text>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginTop: '5px',
                                            marginBottom: '15px',
                                            padding: '15px',
                                            border: '1px dotted #E0E0E0',
                                            borderRadius: '5px',
                                        }}
                                    >
                                        <Row align="middle">
                                            <Typography.Text className="smallparagraph" style={{ flex: 1 }}>
                                                {urls.test}
                                            </Typography.Text>
                                            <Copy
                                                className="icon clickable"
                                                weight="regular"
                                                size={16}
                                                style={{ marginLeft: '10px' }}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        urls.test
                                                    )
                                                    message.success('URL copiada al portapapeles')
                                                }}
                                            />
                                        </Row>
                                    </div>

                                    <Typography.Text className="mediumparagraphbold" style={{ flex: 1 }}>
                                        Endpoint de producción
                                    </Typography.Text>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginTop: '5px',
                                            padding: '15px',
                                            border: '1px dotted #E0E0E0',
                                            borderRadius: '5px',
                                        }}
                                    >
                                        <Row align="middle">
                                            <Typography.Text className="smallparagraph" style={{ flex: 1 }}>
                                                {urls.live}
                                            </Typography.Text>
                                            <Copy
                                                className="icon clickable"
                                                weight="regular"
                                                size={16}
                                                style={{ marginLeft: '10px' }}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        urls.live
                                                    )
                                                    message.success('URL copiada al portapapeles')
                                                }}
                                            />
                                        </Row>
                                    </div>

                                    <Alert message="Cuando quieras emitir facturas válidas de tus ventas procesadas por Clip, utiliza el endpoint de producción" type="warning" style={{ marginTop: '20px' }} />
                                    
                                    <Row justify="end" style={{ marginTop: '35px' }}>
                                        <Button loading={loading === 'submit'} type="primary" htmlType="submit">
                                            Conectar mi cuenta
                                        </Button>
                                    </Row>
                                </>
                            )}
                        </Form>
                    </div>
                )
            default:
                return <></>
        }
    }

    return (
        <Modal
            open={clipModalVisible}
            onCancel={() => {
                dispatch(closeModal('clipModalVisible'))
            }}
            className="integrationModal"
            title={null}
            footer={null}
            style={{ top: 55 }}
        >
            <ToRender />
        </Modal>
    )
}
