import { Alert, Button, DatePicker, Form, Modal, Progress, Radio, Row, Space, Typography, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
import { SelectInput } from '../components/Forms/Inputs'
import { useEffect, useState } from 'react'
import { tagsColors } from '../components/Forms/TagsColors'
import { SignedInternalAPIRequest } from '../functions/APIRequest'
import { useAuth } from 'reactfire'
import moment from 'moment'
import { CheckCircle } from '@phosphor-icons/react'

const DownloadForm = () => {
    const [showDateSelector, setShowDateSelector] = useState<boolean>(false)
    const { downloadInfo } = useSelector((state: any) => state.data)

    return (
        <div>
            <SelectInput
                options={[
                    {
                        label: 'Excel',
                        value: 'excel',
                    },
                    {
                        label: 'Zip con archivos PDF',
                        value: 'pdf-zip',
                    },
                    {
                        label: 'Zip con archivos XML',
                        value: 'xml-zip',
                    },
                    // {
                    //     label: 'Archivo Zip con PDF y XML',
                    //     value: 'zip'
                    // },
                    {
                        label: 'JSON',
                        value: 'json',
                    },
                ]}
                label="Formato de descarga"
                name="fileFormat"
            />

            <Form.Item label="Intervalo de fechas" name="period">
                <Radio.Group
                    onChange={(v) => {
                        if (v.target.value === 'customPeriod') {
                            setShowDateSelector(true)
                        } else {
                            setShowDateSelector(false)
                        }
                    }}
                >
                    <Space direction="vertical">
                        <Radio value="today"> Hoy </Radio>
                        <Radio value="currentWeek"> Últimos 7 días </Radio>
                        <Radio value="currentMonth"> Mes actual </Radio>
                        <Radio value="lastMonth"> Mes pasado </Radio>
                        <Radio value="customPeriod"> Personalizado </Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>

            {showDateSelector && (
                <Form.Item name="customDate">
                    <DatePicker.RangePicker picker="date" />
                </Form.Item>
            )}

            <Alert
                message={
                    <p>
                        Exportaremos como <strong>máximo 2,000 {downloadInfo?.readableType}</strong>, si necesitas
                        exportar un número mayor por favor ponte en contacto con soporte
                    </p>
                }
                type="warning"
                showIcon
                style={{ marginTop: '20px' }}
            />
        </div>
    )
}

const DownloadInProgressView = ({ success }: { success: boolean }) => {
    const [percent, setPercent] = useState<number>(0)

    useEffect(() => {
        const interval = setInterval(() => {
            setPercent((prevPercent) => {
                const nextPercent = prevPercent + 5
                if (nextPercent === 95) {
                    clearInterval(interval)
                }
                return nextPercent
            })
        }, 100)

        return () => clearInterval(interval)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <Typography.Text>Estamos generando tu archivo, esto puede tardar unos minutos</Typography.Text>
            <Row align="middle" justify="center">
                <Progress
                    percent={success ? 100 : percent}
                    status="active"
                    strokeColor={tagsColors.find((c) => c.name === 'purple')?.text}
                />
            </Row>
        </div>
    )
}

const DownloadSuccessView = () => {
    return (
        <div>
            <Space direction="vertical" align="center" style={{ width: '100%' }}>
                <CheckCircle weight="regular" size={34} color={tagsColors.find((c) => c.name === 'green')?.text} />
                <Typography.Text>¡Tu archivo está listo!</Typography.Text>
                <Typography.Text type="secondary">Lo hemos guardado en tu carpeta de descargas</Typography.Text>
            </Space>
        </div>
    )
}

const handleDatesByPeriod = (period: string, customDate: any) => {
    switch (period) {
        case 'today':
            return [moment().startOf('day').valueOf(), moment().endOf('day').valueOf()]
        case 'currentWeek':
            return [moment().startOf('week').valueOf(), moment().endOf('week').valueOf()]
        case 'currentMonth':
            return [moment().startOf('month').valueOf(), moment().endOf('month').valueOf()]
        case 'lastMonth':
            return [
                moment().subtract(1, 'month').startOf('month').valueOf(),
                moment().subtract(1, 'month').endOf('month').valueOf(),
            ]
        case 'customPeriod':
            return [customDate[0].startOf('day').valueOf(), customDate[1].endOf('day').valueOf()]
        default:
            return [moment().startOf('day').valueOf(), moment().endOf('day').valueOf()]
    }
}

export const DownloadsModal = () => {
    const auth = useAuth()
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const { team } = useSelector((state: any) => state.team)
    const { downloadsModalVisible } = useSelector((state: any) => state.modals)
    const { testmode, downloadInfo } = useSelector((state: any) => state.data)

    const [step, setStep] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)

    const { readableType, type, clientId } = downloadInfo || {}

    const toRender = () => {
        switch (step) {
            case 0:
                return <DownloadForm />
            case 1:
                return <DownloadInProgressView success={success} />
            case 2:
                return <DownloadSuccessView />
        }
    }

    const handleFileType = (fileType: string) => {
        switch (fileType) {
            case 'excel':
                return 'xlsx'
            case 'json':
                return 'json'
            case 'pdf-zip':
                return 'pdf'
            case 'xml-zip':
                return 'xml'
            case 'zip':
                return 'pdf-xml'
            default:
                return 'xlsx'
        }
    }

    const handleDownloadEndpoint = (fileType: string) => {
        if (fileType === 'pdf-zip' || fileType === 'xml-zip' || fileType === 'zip') {
            return `exportFiles/v1/invoices/files`
        }

        return `exportFiles/v2/${type}`
    }

    const handleDownloadFile = async (values: any) => {
        const { customDate, fileFormat, period } = values

        const [startDate, endDate] = handleDatesByPeriod(period, customDate)

        setStep(1)
        setLoading(true)

        try {
            const body = {
                livemode: !testmode,
                period: [startDate, endDate],
                startDate,
                endDate,
                // fileType: fileFormat === 'excel' ? 'xlsx' : 'json',
                fileType: handleFileType(fileFormat),
                filterByStatus: null,
                team: team.id,
                clientId: clientId || null,
            }
            console.log(handleDownloadEndpoint(fileFormat))

            await SignedInternalAPIRequest(
                body,
                handleDownloadEndpoint(fileFormat),
                auth.currentUser,
                { isFile: true, fileName: `${readableType}-${moment().format('DD/MM/YYYY')}`, fileExtension: 'xlsx' },
                'POST',
            )

            setSuccess(true)

            setTimeout(() => {
                setStep(2)
                setLoading(false)
            }, 1000)
        } catch (error: any) {
            console.log(error)
            message.error(error?.message ?? error?.error ?? 'Ocurrió un error al descargar el archivo')
            setStep(0)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (downloadsModalVisible) {
            setStep(0)
            setLoading(false)
            form.setFieldsValue({
                fileFormat: 'excel',
                period: 'today',
            })
        } else {
            form.resetFields()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [downloadsModalVisible])

    return (
        <Modal
            open={downloadsModalVisible}
            closable
            title={`Exportar ${readableType}`}
            onCancel={() => dispatch(closeModal('downloadsModalVisible'))}
            width="50%"
            footer={null}
        >
            {step !== 2 && (
                <Typography.Text type="secondary">
                    Toda la información se descargará usando la zona horaria de Ciudad de México
                </Typography.Text>
            )}
            <Form form={form} onFinish={handleDownloadFile} layout="vertical" style={{ marginTop: '20px' }}>
                {toRender()}
            </Form>
            <Row align="middle" justify="end">
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    style={{ marginTop: '20px' }}
                    onClick={() => {
                        if (step === 2) {
                            dispatch(closeModal('downloadsModalVisible'))
                        } else {
                            form.submit()
                        }
                    }}
                >
                    {' '}
                    {step === 2 ? 'Cerrar' : 'Descargar'}{' '}
                </Button>
            </Row>
        </Modal>
    )
}
