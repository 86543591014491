import React, { useState } from 'react'
import { Form, FormInstance, Col, Row, Button, Modal, message, Typography } from 'antd'
import TaxesTable from '../Tables/TaxesTable'
import { useDispatch, useSelector } from 'react-redux'
import TaxesForm from './TaxesForm'
import { setTeam } from '../../context/teamsSlice'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { RemoveUndefined } from '../../functions/helpers'
import { CheckBoxInput } from './Inputs'
import { PlusCircle } from '@phosphor-icons/react/dist/ssr'

interface DefaultTaxesFormProps {
    incomingForm?: FormInstance
}

const ModalTaxesAdd = ({ open, update, close }: { open: boolean; update: Function; close: any }) => {
    const [form] = Form.useForm()
    return (
        <Modal open={open} title={null} footer={null} onCancel={close}>
            <TaxesForm
                incomingForm={form}
                returnCreated={async (v) => {
                    if (update) update(v)
                }}
            />
        </Modal>
    )
}

const DefaultTaxesForm: React.FC<DefaultTaxesFormProps> = ({ incomingForm }) => {
    const [form] = Form.useForm()
    const useForm = incomingForm ?? form
    const { team } = useSelector((state: any) => state.team)

    var [addTax, setAddTax] = useState({
        visible: false,
        type: '',
    })

    const dispatch = useDispatch()

    const TableTax = ({ type }: { type: string }) => {
        return (
            <TaxesTable
                customDelete={async (v: any) => {
                    try {
                        let preparedArray = (team.defaults[type] ?? []).map((t: any) => {
                            return {
                                ...t,
                                id: `${t.rate}${t.type}${t.factor}${t.inclusive}${t.withholding ?? ''}`,
                            }
                        })
                        preparedArray = preparedArray.filter(
                            (tax: any) =>
                                tax.id !== `${v.rate}${v.type}${v.factor}${v.inclusive}${v.withholding ?? ''}`,
                        )

                        const key = `defaults.${type}`
                        await updateDoc(doc(getFirestore(), 'teams', team.id), {
                            [key]: preparedArray,
                        })
                        dispatch(
                            setTeam({
                                ...team,
                                defaults: {
                                    ...team.defaults,
                                    [type]: preparedArray,
                                },
                            }),
                        )
                        message.success('Impuesto eliminado correctamente.')
                    } catch (error: any) {
                        message.error(error?.message)
                    }
                }}
                incomingTaxes={team.defaults?.[type] ?? []}
                customAction={() => {
                    setAddTax({
                        visible: true,
                        type,
                    })
                }}
            />
        )
    }

    const initialValues = Object.keys(team.defaults ?? {}).reduce((acc, key) => {
        return {
            ...acc,
            [`defaults.${key}`]: team.defaults[key],
        }
    }, {})

    return (
        <Form
            form={useForm}
            layout="vertical"
            onValuesChange={async (v, values) => {
                dispatch(
                    setTeam({
                        ...team,
                        defaults: {
                            ...team.defaults,
                            ...Object.keys(v).reduce((acc, key) => {
                                return {
                                    ...acc,
                                    [key?.split('.')[1]]: v[key],
                                }
                            }, {}),
                        },
                    }),
                )
                await updateDoc(doc(getFirestore(), 'teams', team.id), {
                    ...v,
                })
            }}
            initialValues={{
                ...team.defaults,
                ...initialValues,
            }}
        >
            <ModalTaxesAdd
                open={addTax.visible}
                update={async (v: any) => {
                    const taxTypes = [
                        { key: 'iva_16', type: 'IVA', rate: '0.16', inclusive: true },
                        { key: 'iva_no_16', type: 'IVA', rate: '0.16', inclusive: false },
                        { key: 'isr_30', type: 'ISR', rate: '0.30', inclusive: true },
                        { key: 'isr_no_30', type: 'ISR', rate: '0.30', inclusive: false },
                    ]

                    const taxesToAdd = taxTypes
                        .filter((taxType) => v[taxType.key])
                        .map((taxType) => ({
                            type: taxType.type,
                            rate: taxType.rate,
                            factor: 'Tasa',
                            inclusive: taxType.inclusive,
                            withholding: false,
                        }))

                    if (v.customTax) {
                        taxesToAdd.push({
                            type: v.type,
                            rate: v.rate,
                            factor: v.factor,
                            inclusive: v.inclusive,
                            withholding: v.withholding,
                        })
                    }

                    const updatedDefaults = { ...team.defaults }

                    for (const tax of taxesToAdd) {
                        const key = `defaults.${addTax.type}`
                        updatedDefaults[addTax.type] = [...(updatedDefaults[addTax.type] ?? []), RemoveUndefined(tax)]

                        await updateDoc(doc(getFirestore(), 'teams', team.id), {
                            [key]: [...(updatedDefaults[addTax.type] ?? [])],
                        })
                    }

                    dispatch(
                        setTeam({
                            ...team,
                            defaults: updatedDefaults,
                        }),
                    )
                }}
                close={() => {
                    setAddTax({
                        visible: false,
                        type: '',
                    })
                }}
            />
            <Row gutter={16}>
                <Col className="w-100" xs={24} style={{ marginBottom: '55px' }}>
                    <Row justify="space-between" align="middle" style={{ marginBottom: '15px' }}>
                        <label>Impuestos para facturas en MXN</label>
                        <Button
                            className="btn-link"
                            onClick={() => {
                                setAddTax({
                                    visible: true,
                                    type: 'defaultTaxes',
                                })
                            }}
                        >
                            <PlusCircle style={{ marginRight: '10px' }} size={16} />
                            Añadir
                        </Button>
                    </Row>
                    <TableTax type="defaultTaxes" />
                </Col>
                <Col xs={24} style={{ marginBottom: '55px' }}>
                    <CheckBoxInput
                        placeholder="Forzar impuestos en USD"
                        name="defaults.forcedTaxesOnUSD"
                        label="Agregar impuestos para USD"
                        content={
                            <Typography.Text className="smallparagraph descriptions">
                                Siempre que un pago sea en USD utilizaremos los impuestos a continuación.
                            </Typography.Text>
                        }
                    />
                </Col>
                {team?.defaults?.forcedTaxesOnUSD && (
                    <Col xs={24} style={{ marginBottom: '25px' }}>
                        <Row justify="space-between" align="middle" style={{ marginBottom: '15px' }}>
                            <label>Impuestos para facturas en USD</label>
                            <Button
                                className="btn-link"
                                onClick={() => {
                                    setAddTax({
                                        visible: true,
                                        type: 'taxesUSDDefaults',
                                    })
                                }}
                            >
                                <PlusCircle style={{ marginRight: '10px' }} size={16} />
                                Añadir
                            </Button>
                        </Row>
                        <TableTax type="taxesUSDDefaults" />
                    </Col>
                )}
            </Row>
        </Form>
    )
}

export default DefaultTaxesForm
