import React from 'react'
import { Form, Col, Row, Alert, Typography, Button } from 'antd'
import { FormInstance } from 'antd/lib'
import { TextAreaInput } from './Inputs'
import { useDispatch, useSelector } from 'react-redux'
import { Info } from '@phosphor-icons/react'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { setTeam } from '../../context/teamsSlice'

interface AddendaFormProps {
    incomingForm?: FormInstance
    buttonTitle?: string
}

const AddendaForm: React.FC<AddendaFormProps> = ({ incomingForm, buttonTitle }) => {
    const { team } = useSelector((state: any) => state.team)
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const usingForm = incomingForm ?? form

    // Rest of the component code

    return (
        <Form
            form={usingForm}
            layout="vertical"
            initialValues={{ ...team?.defaults }}
            onFinish={(values: any) => {
                dispatch(setTeam({ ...team, defaults: { ...team?.defaults, addenda: values.addenda } }))
                updateDoc(doc(getFirestore(), 'teams', team?.id), {
                    'defaults.addenda': values.addenda ?? '',
                })
            }}
        >
            <Row>
                <Col xs={24}>
                    <TextAreaInput name="addenda" label="Addenda" placeholder="Adenda que agregaremos a la factura." />
                </Col>
                <Col xs={24}>
                    <Alert
                        message={
                            <Typography.Text className="smallparagraph descriptions">
                                La addenda se agregará al XML y al PDF de la factura, asegúrate de que sea una addenda
                                válida.
                            </Typography.Text>
                        }
                        type="info"
                        showIcon
                        icon={<Info className="icon" weight="regular" />}
                    />
                </Col>
                <Col xs={24} style={{ marginTop: '25px' }}>
                    <Row justify="end">
                        <Button type="primary" htmlType="submit">
                            {buttonTitle ? buttonTitle : 'Actualizar addenda'}
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Form>
    )
}

export default AddendaForm
