import { Col, Input, Row } from 'antd'
import { TextInput } from './Inputs'

interface AddressFormInputLayoutProps {
    useGoogleMaps?: boolean
    disabled: boolean
}

const AddressFormInputLayout = ({ useGoogleMaps, disabled }: AddressFormInputLayoutProps) => {
    return (
        <>
            {useGoogleMaps && (
                <div style={{ margin: '0px 0px 15px 0px' }}>
                    <label htmlFor="google-maps-input">Busca tu dirección</label>
                    <Input id="google-maps-input" placeholder="" disabled={disabled} />
                </div>
            )}

            <TextInput
                name="street"
                label="Calle"
                rules={[{ required: true, message: 'Debes agregar la calle' }]}
                disabled={disabled}
            />

            <Row gutter={16}>
                <Col span={12}>
                    <TextInput
                        name="exterior"
                        label="Número exterior"
                        rules={[{ required: true, message: 'Debes agregar el número exterior' }]}
                        disabled={disabled}
                    />
                </Col>
                <Col span={12}>
                    <TextInput name="interior" label="Número interior" disabled={disabled} />
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <TextInput
                        name="zip"
                        label="Código postal"
                        rules={[{ required: true, message: 'Debes agregar el código postal' }]}
                        disabled={disabled}
                    />
                </Col>
                <Col span={12}>
                    <TextInput
                        name="neighborhood"
                        label="Colonia"
                        rules={[{ required: true, message: 'Dees agregar la colonia' }]}
                        disabled={disabled}
                    />
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <TextInput
                        name="city"
                        label="Ciudad"
                        rules={[{ required: true, message: 'Debes agregar la ciudad' }]}
                        disabled={disabled}
                    />
                </Col>
                <Col span={12}>
                    <TextInput
                        name="state"
                        label="Estado"
                        rules={[{ required: true, message: 'Debes agregar el estado' }]}
                        disabled={disabled}
                    />
                </Col>
            </Row>
        </>
    )
}

export default AddressFormInputLayout
