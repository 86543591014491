import { ConfigProvider, Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import { usePostHog } from 'posthog-js/react'
import { useState } from 'react'

export default function PricingDeckSwitch({
    parentCallback,
}: {
    parentCallback: (type: 'monthly' | 'yearly') => void
}) {
    const [subscription, setSubscription] = useState('monthly')
    const posthog = usePostHog()

    const handleChange = (e: RadioChangeEvent) => {
        try {
            posthog.capture('onboarding_plan_periodicity_selected', {
                v: 'v2',
                periodicity: e.target.value,
            })
        } catch (error) {}
        setSubscription(e.target.value)
        parentCallback(e.target.value)
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Radio: {
                        buttonSolidCheckedColor: 'var(--neutral-2)',
                        buttonBg: 'var(--neutral-4)',
                        buttonCheckedBg: 'var(--neutral-4)',
                        colorPrimary: 'var(--white)',
                        colorPrimaryHover: 'var(--white)',
                    },
                },
            }}
        >
            <div className="flex items-center justify-center h-screen bg-gray-100">
                <Radio.Group
                    value={subscription}
                    onChange={handleChange}
                    buttonStyle="solid"
                    className="rounded-lg p-base-bold"
                    style={{ height: '40px', alignItems: 'center', padding: '5px 5px', background: 'var(--neutral-4)' }}
                >
                    <Radio.Button
                        value="monthly"
                        className="custom-radio-button"
                        style={{ height: '30px', lineHeight: '46px', padding: '0 20px' }}
                    >
                        Mensual
                    </Radio.Button>
                    <Radio.Button
                        value="annual"
                        className="custom-radio-button"
                        style={{ height: '30px', lineHeight: '46px', padding: '0 20px' }}
                    >
                        Anual (Ahorra 16%)
                    </Radio.Button>
                </Radio.Group>
            </div>
        </ConfigProvider>
    )
}
