import { Tag, Tooltip, Typography } from 'antd'
import { readableElementSource } from '../../functions/helpers'

export const ElementFromTag = ({
    record,
    recordType,
}: {
    record: any
    recordType: 'invoice' | 'payment' | 'receipt' | 'client' | 'service'
}) => {
    const readableType =
        recordType === 'invoice'
            ? 'Factura creada'
            : recordType === 'payment'
              ? 'Pago creado'
              : recordType === 'receipt'
                ? 'Recibo creado'
                : recordType === 'client'
                  ? 'Cliente'
                  : 'Servicio'
    const title = `${readableType} desde ${readableElementSource(record.from)}`
    return (
        <Tooltip title={title}>
            <Tag className="bg-white rounded-full">
                <Typography.Text className="p-small-medium">
                {readableElementSource(record.from)}
                </Typography.Text>
            </Tag>
        </Tooltip>
    )
}
