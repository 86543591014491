import { CheckCircle } from "@phosphor-icons/react"
import { Button, Row, Space, Tag, Typography } from "antd"
import InvoiceActions from "../ElementActions/InvoiceActions"
import PaymentsActions from "../ElementActions/PaymentsActions"
import { useDispatch } from "react-redux"
import { closeModal } from "../../context/modalsSlice"

const SuccessActions = ({ resourceType, resource } : { resourceType: string, resource: any}) => {

    const dispatch = useDispatch()

    switch (resourceType) {
        case 'invoice':
            return <InvoiceActions invoice={resource} from="successView" />
        case 'payment':
            return <PaymentsActions payment={resource} from="successView" />
        default:
            return <Row justify="space-between">
            <Button className='btn-secondary' onClick={() => {
                dispatch(closeModal('invoiceModalVisible'))
                dispatch(closeModal('paymentModalVisible'))
            }}>
                Cerrar
            </Button>
        </Row>
    }
}

export const SuccessView = ({ title, description, tags, resourceType, resource } : { title: string, description: string, tags: Array<string>, resourceType: string, resource: any }) => {
    return <div className="d-flex" style={{ alignContent: 'center', justifyContent: 'center' }}>
        <Space direction="vertical" align="center" style={{ margin: '50px', textAlign: 'center' }}>
            <CheckCircle
                weight="regular"
                size={64}
            />
            <Typography.Text className="h4-bold" style={{ marginTop: '20px' }}>
                {title}
            </Typography.Text>
            <Typography.Text className="p-large-regular">
                {description}
            </Typography.Text>

            {tags.map((tag, index) => {
                return <Tag key={tag} className="bg-primary-4 primary-1 p-small-bold rounded-lg" style={{ border: 'none', padding: '0px 10px' }}>
                    {tag}
                </Tag>
            })}

            <Row style={{ marginTop: '40px' }}>
                <SuccessActions resourceType={resourceType} resource={resource} />
            </Row>
        </Space>
    </div>
}