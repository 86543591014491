import { Tooltip, Typography } from 'antd'
import { Query, getCountFromServer } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { MetroSpinner } from 'react-spinners-kit'

interface WrapperProps {
    clientId: string

    loadingComponent?: React.ReactNode
    query: Query
    pastPeriodQuery?: Query
    renderData?: (data: number, pastData?: number) => React.ReactNode
}

const CountDataWrapper: React.FC<WrapperProps> = ({
    clientId,
    loadingComponent,
    query,
    renderData,
    pastPeriodQuery,
}) => {
    // Your component logic goes here

    const { testmode } = useSelector((state: any) => state.data)

    const [status, setStatus] = useState('loading')
    const [data, setData] = useState<any>([])
    const [pastData, setPastData] = useState<any>([])
    const [error, setError] = useState('')

    const getData = async () => {
        try {
            if (status !== 'loading') return
            const d = await getCountFromServer(query)
            if (pastPeriodQuery) {
                const pastD = await getCountFromServer(pastPeriodQuery)
                setPastData(pastD.data().count)
            }
            await new Promise((resolve) => setTimeout(resolve, 500))
            setData(d.data().count)
            setStatus('success')
        } catch (error: any) {
            setError(error.message ?? '')
            setStatus('error')
        }
    }

    useEffect(() => {
        getData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testmode])

    // const [totalCount, setTotalCount] = useState(0)

    //EFFECTS

    return (
        // Your JSX code goes here
        <div>
            {status === 'loading' &&
                (!loadingComponent ? <MetroSpinner color="#8666FF" size={20} /> : loadingComponent)}
            {status === 'error' && (
                <Tooltip title={error ?? ''}>
                    <Typography.Text className="smallparagraph">Ocurrió un error</Typography.Text>
                </Tooltip>
            )}
            {status === 'success' && renderData && renderData(data, pastData)}
        </div>
    )
}

export default CountDataWrapper
