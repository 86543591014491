import { useNavigate } from 'react-router-dom'
import { SearchParamInURL, getItemsAmounts, readableElementSource } from '../functions/helpers'
import { Avatar, Button, Col, Descriptions, Form, Modal, Row, Tag, Tooltip, Typography, message } from 'antd'
import { useFirestoreCollectionData, useFirestoreDocData } from 'reactfire'
import { arrayUnion, collection, doc, getFirestore, query, updateDoc, where } from 'firebase/firestore'
import { optimizedPaymentDefSet, paymentSet } from '../../interfaces/paymentsDef'
import EmailsTable from '../components/ComponentIndividual/EmailSend'
import { RelationsHandler } from '../components/Relations/RelationsHandler'
import HeaderForViewDetail from '../components/ComponentIndividual/HeaderForViewDetail'
import PaymentsActions from '../components/ElementActions/PaymentsActions'
import moment from 'moment'
import { PaymentStatusTag } from '../components/UI/ElementStatus'
import ItemsSelection from '../components/ComponentIndividual/ItemsSelection'
import ProductsServicesTotals from '../components/ComponentIndividual/ProductsServicesTotals'
import { CaretCircleDoubleDown, CaretCircleDoubleUp, CreditCard, X } from '@phosphor-icons/react'
import { Suspense, useState } from 'react'
import { openModal } from '../context/modalsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { setData } from '../context/dataSlice'
import { SelectInput, TextAreaInput } from '../components/Forms/Inputs'
import { paymentForms } from '../datasets/Fiscal'
import { createdByObject } from '../components/ComponentIndividual/helpers'
import { LogsRelatedToElement } from '../components/Logs/LogsRelatedToElement'
import { usePermissions } from '../customHooks/usePermissions'

const MarkAsPaidModal = ({ open, close, data }: { open: boolean; close: Function; data: any }) => {
    const { user } = useSelector((state: any) => state.user)
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(false)
    return (
        <Modal
            open={open}
            closable={false}
            style={{ top: 5 }}
            onCancel={() => close()}
            title={
                <div style={{ position: 'relative', paddingBottom: '0px' }}>
                    <Typography.Text
                        className="smallheader"
                        style={{
                            position: 'absolute',
                            //center the text
                            left: '50%',
                            transform: 'translateX(-50%)',
                            paddingTop: '5px',
                        }}
                    >
                        Completar pago
                    </Typography.Text>
                    <Row>
                        <Col xs={24} style={{ margin: 'auto' }}>
                            <Row justify="space-between" align="middle">
                                <X className="icon clickable" size={20} onClick={() => close()} />
                                <></>
                                <Button type="primary" onClick={() => form.submit()}>
                                    Completar
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </div>
            }
            footer={null}
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={async () => {
                    setLoading(true)
                    try {
                        await updateDoc(doc(getFirestore(), 'payments', data?.fid), {
                            status: 'succeeded',
                            payment_form: form.getFieldValue('payment_form'),
                            notes: (data?.notes ?? '') + ' ' + form.getFieldValue('notes'),
                            logs: arrayUnion({
                                timestamp: moment().valueOf(),
                                message: 'Pago completado',
                                user: user.uid,
                            }),
                        })
                        setLoading(false)
                        form.resetFields()
                        close()
                    } catch (error) {
                        setLoading(false)
                    }
                }}
            >
                <Row justify="center">
                    <Col xs={24}>
                        <Row justify="start" align="middle" style={{ marginBottom: '15px', marginTop: '25px' }}>
                            <Typography.Text className="smallheader descriptions">
                                ¿Estás seguro que deseas marcar como pagado este pago?
                            </Typography.Text>
                        </Row>
                        <Row>
                            <Col xs={24}>
                                <SelectInput
                                    options={paymentForms}
                                    label="Forma de pago"
                                    rules={[{ required: true, message: 'Selecciona una forma de pago' }]}
                                    placeholder="Forma de pago"
                                    name="payment_form"
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '15px' }}>
                            <Col xs={24}>
                                <TextAreaInput name="notes" label="Notas" placeholder="Agrega notas adicionales" />
                            </Col>
                        </Row>
                        <Row justify="space-between" align="middle">
                            <div></div>
                            <Button loading={loading} type="primary" onClick={() => form.submit()}>
                                Completar
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

const PaymentDetailsView = () => {
    const [show, setShow] = useState<any[]>([])
    const [completePayment, setCompletePayment] = useState(false)
    const { team } = useSelector((state: any) => state.team)
    const { user } = useSelector((state: any) => state.user)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const fs = getFirestore()
    const id = SearchParamInURL('id')

    if (!id) {
        message.warning('No se encontró el id del pago')
        navigate('payments')
    }

    const paymentRef = doc(fs, 'payments', id ?? '')
    const {
        status,
        data,
        error: paymentError,
    } = useFirestoreDocData(paymentRef, {
        suspense: true,
    })
    const {
        status: emailStatus,
        data: emailsData,
        error: emailsError,
    } = useFirestoreCollectionData(query(collection(fs, 'emails'), where('related', '==', `payments/${id}`)), {
        suspense: true,
    })
    if (status === 'loading' || !data) {
        return <>Cargando...</>
    }

    if (status === 'error' || emailStatus === 'error' || paymentError || emailsError) {
        return (
            <>
                <Typography.Text onClick={() => navigate('payments')}>Volver</Typography.Text>
            </>
        )
    }

    const HandleShow = (id: string) => {
        if (show.includes(id)) {
            setShow(show.filter((s) => s !== id))
        } else {
            setShow([...show, id])
        }
    }

    const ShowIcon = ({ id }: { id: string }) => {
        return show.includes(id) ? (
            <CaretCircleDoubleUp size={18} className="icon" weight="regular" style={{ marginLeft: '5px' }} />
        ) : (
            <CaretCircleDoubleDown size={18} style={{ marginLeft: '5px' }} className="icon" weight="regular" />
        )
    }
    const amounts = getItemsAmounts(data?.items, data?.paidIn === 'bank')

    const ActionsHeader = () => {
        const { canCreatePayment } = usePermissions()
        return (
            <Col xs={24}>
                <div
                    style={{
                        borderRadius: '10px',
                        backgroundColor: '#F9FAFC',
                        padding: '10px',
                    }}
                >
                    <Row justify="space-between" align="middle">
                        <Typography.Text className="smallparagraphbold">Acciones</Typography.Text>
                        <Row>
                            <PaymentsActions
                                payment={{ ...data, emails: typeof data?.emails === 'object' ? [] : data?.emails }}
                            />
                            {data?.status !== 'succeeded' && (
                                <Tooltip title="¿Marcar como pagado?">
                                    {!canCreatePayment || <CreditCard
                                        style={{ marginLeft: '5px' }}
                                        size={20}
                                        onClick={() => {
                                            setCompletePayment(true)
                                        }}
                                        weight="regular"
                                        className="icon clickable"
                                    />}
                                </Tooltip>
                            )}
                        </Row>
                    </Row>
                </div>
            </Col>
        )
    }

    const PaymentDetailsGeneral = () => {
        let details = [
            {
                key: 'id',
                label: <Typography.Text className="smallparagraphbold">ID del pago</Typography.Text>,
                children: <Typography.Text className="smallparagraph ">{data?.id}</Typography.Text>,
            },
            {
                key: 'automations',
                label: <Typography.Text className="smallparagraphbold">Automatizaciones</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">{data?.automations?.length ?? 0}</Typography.Text>
                ),
            },
            {
                key: 'currency',
                label: <Typography.Text className="smallparagraphbold">Moneda</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">{data?.currency?.toUpperCase()}</Typography.Text>
                ),
            },
            {
                key: 'exchange_rate',
                label: <Typography.Text className="smallparagraphbold">Tipo de cambio</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        1 {data?.currency} = {data?.exchange} MXN
                    </Typography.Text>
                ),
            },
            {
                key: 'total',
                label: <Typography.Text className="smallparagraphbold">Total del pago</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        {amounts.totalString} {data?.currency}{' '}
                    </Typography.Text>
                ),
            },
            {
                key: 'paid_amount',
                label: <Typography.Text className="smallparagraphbold">Total pagado</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        {data?.status === 'succeeded' ? amounts.totalString : '$0'} {data?.currency}{' '}
                    </Typography.Text>
                ),
            },
            {
                key: 'source',
                label: <Typography.Text className="smallparagraphbold">Fuente</Typography.Text>,
                children: (
                    <Tag color="#D3D9FF">
                        <Typography.Text className="smallparagraphbold blue">
                            {readableElementSource(data.from ?? '')}
                        </Typography.Text>
                    </Tag>
                ),
            },
            {
                key: 'date',
                label: <Typography.Text className="smallparagraphbold">Fecha</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        {moment(data?.timestamp).format('DD MMMM YYYY HH:mm')}{' '}
                    </Typography.Text>
                ),
            },
            {
                key: 'Fecha de pago',
                label: <Typography.Text className="smallparagraphbold">Fecha de pago</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        {(data.succeededTimestamp || data.status_transitions) &&
                            moment(data.succeededTimestamp || data.status_transitions.paid_at * 1000).format(
                                'DD MMMM YYYY HH:mm',
                            )}{' '}
                    </Typography.Text>
                ),
            },
        ]

        if (data?.review_status === 'pending') {
            details.push({
                key: 'review_status',
                label: <Typography.Text className="smallparagraphbold">Por revisar</Typography.Text>,
                children: (
                    <Button
                        onClick={() => {
                            dispatch(
                                setData({
                                    item: 'payment',
                                    data: data,
                                }),
                            )
                            dispatch(openModal('approveModalVisible'))
                        }}
                    >
                        Revisar pago
                    </Button>
                ),
            })
        }

        if (data.global) {
            details.push({
                key: 'global',
                label: <Typography.Text className="smallparagraphbold">Factura global</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        {data?.global?.periodicity === 'month' ? 'Mensual' : data?.global?.periodicity}{' '}
                        {moment(data?.global?.months, 'MM').format('MMMM')} {data?.global?.year}{' '}
                    </Typography.Text>
                ),
            })
        }

        if (data?.from === 'manual' || !data?.from) {
            details.push({
                ...createdByObject({ resource: data, team, user }),
            })
        }
        
        return (
            <Col xs={24} lg={24}>
                <div
                    style={{
                        width: '100%',
                        padding: '20px',
                        borderRadius: '10px',
                        background:
                            'linear-gradient(180deg, rgba(186, 216, 239, 0.34) 0%, rgba(134, 102, 255, 0.06) 100%)',
                    }}
                    className="d-flex flex-column"
                >
                    <Descriptions
                        title={
                            <Row justify="space-between" align="middle">
                                <Typography.Text className="mediumparagraphbold">
                                    Información general del pago
                                </Typography.Text>
                                <PaymentStatusTag shape="tag" record={paymentSet(data)} />
                            </Row>
                        }
                        layout="vertical"
                        size="small"
                        items={details}
                    />
                </div>
            </Col>
        )
    }

    const ProductAndServices = () => {
        return (
            <Col xs={24} lg={24} style={{ marginTop: '25px', marginBottom: '15px' }}>
                <div className="d-flex flex-column">
                    <Row align="middle" className="clickable" onClick={() => HandleShow('products')}>
                        <Typography.Text className="smallparagraph descriptions">Productos / Servicios</Typography.Text>
                        <div
                            style={{ height: '1px', borderBottom: '1px solid #D8DAE5', flex: 1, marginLeft: '15px' }}
                        ></div>
                        <ShowIcon id="products" />
                    </Row>
                    {show.includes('products') && (
                        <div
                            className="d-flex flex-column"
                            style={{
                                marginTop: '5px',
                                backgroundColor: '#fafbfd',
                                padding: '10px',
                                borderRadius: '15px',
                            }}
                        >
                            <ItemsSelection incomingServices={data?.internalItems} disabled={true} hideTitle={true} />
                            <Row justify="end">
                                <ProductsServicesTotals incomingItems={data?.internalItems} boxed={false} />
                            </Row>
                        </div>
                    )}
                </div>
            </Col>
        )
    }

    const EmailsRelated = () => {
        return (
            <Col xs={24}>
                <EmailsTable
                    customHeader={
                        <Row align="middle" style={{ marginBottom: '15px' }}>
                            <Typography.Text className="smallparagraph descriptions">Correos enviados</Typography.Text>
                            <div
                                style={{
                                    height: '1px',
                                    borderBottom: '1px solid #D8DAE5',
                                    flex: 1,
                                    marginLeft: '15px',
                                }}
                            ></div>
                        </Row>
                    }
                    emailsData={emailsData}
                    emailStatus={emailStatus}
                />
            </Col>
        )
    }

    return (
        <Suspense>
            <MarkAsPaidModal
                data={data}
                open={completePayment}
                close={() => {
                    setCompletePayment(false)
                }}
            />
            <div>
                <HeaderForViewDetail data={data} title={`Pago por ${amounts.totalString} ${data?.currency}`} />
                <Row gutter={[16, 16]} style={{ padding: '20px 20px' }}>
                    <Col xs={24}>
                        <Row>
                            <Avatar
                                style={{
                                    verticalAlign: 'middle',
                                    marginRight: '10px',
                                    background:
                                        'linear-gradient(180deg, rgba(186, 216, 239, 0.34) 0%, rgba(134, 102, 255, 0.06) 100%)',
                                }}
                                size={40}
                            >
                                <Typography.Text className="smallparagraphbold">
                                    {(data?.client?.legal_name ?? '')[0]}
                                </Typography.Text>
                            </Avatar>
                            <div className="d-flex flex-column">
                                <Typography.Text className="smallheader">{data?.client?.legal_name}</Typography.Text>
                                <Typography.Text className="smallparagraph descriptions">
                                    {data?.client?.rfc} - {data?.client?.email}
                                </Typography.Text>
                                <Typography.Text className="smallparagraph descriptions">
                                    {typeof data?.client?.address?.country === 'string'
                                        ? data?.client?.address?.country
                                        : data?.client?.address?.country?.label ?? ''}{' '}
                                    - C.P. {data?.client?.address?.zip}
                                </Typography.Text>
                            </div>
                        </Row>
                    </Col>
                    <ActionsHeader />

                    <PaymentDetailsGeneral />

                    <ProductAndServices />

                    <Col xs={24} lg={24}>
                        <Row
                            align="middle"
                            style={{ marginBottom: '15px' }}
                            className="clickable"
                            onClick={() => HandleShow('receipts')}
                        >
                            <Typography.Text className="smallparagraph descriptions">
                                Recibos relacionados
                            </Typography.Text>
                            <div
                                style={{
                                    height: '1px',
                                    borderBottom: '1px solid #D8DAE5',
                                    flex: 1,
                                    marginLeft: '15px',
                                }}
                            ></div>
                            <ShowIcon id="receipts" />
                        </Row>
                        {show.includes('receipts') && (
                            <div
                                style={{
                                    marginTop: '5px',
                                    backgroundColor: '#fafbfd',
                                    padding: '10px',
                                    borderRadius: '15px',
                                }}
                            >
                                <RelationsHandler
                                    title="Recibos relacionados"
                                    viewType="table"
                                    type="payments"
                                    collection="receipts"
                                    subcollection="payments"
                                    item={optimizedPaymentDefSet(data ?? {})}
                                    itemId={id}
                                />
                            </div>
                        )}
                    </Col>

                    <Col xs={24} lg={24}>
                        <Row
                            align="middle"
                            style={{ marginBottom: '15px' }}
                            className="clickable"
                            onClick={() => HandleShow('invoices')}
                        >
                            <Typography.Text className="smallparagraph descriptions">
                                Facturas relacionadas
                            </Typography.Text>
                            <div
                                style={{
                                    height: '1px',
                                    borderBottom: '1px solid #D8DAE5',
                                    flex: 1,
                                    marginLeft: '15px',
                                }}
                            ></div>
                            <ShowIcon id="invoices" />
                        </Row>
                        {show.includes('invoices') && (
                            <div
                                style={{
                                    marginTop: '5px',
                                    backgroundColor: '#fafbfd',
                                    padding: '10px',
                                    borderRadius: '15px',
                                }}
                            >
                                <RelationsHandler
                                    title="Facturas relacionados"
                                    viewType="table"
                                    type="payments"
                                    collection="invoices"
                                    subcollection="payments"
                                    item={optimizedPaymentDefSet(data ?? {})}
                                    itemId={id}
                                />
                            </div>
                        )}
                    </Col>
                    <EmailsRelated />
                    <Col xs={24} lg={24}>
                        <Row
                            align="middle"
                            style={{ marginBottom: '15px' }}
                            className="clickable"
                            onClick={() => HandleShow('logs')}
                        >
                            <Typography.Text className="smallparagraph descriptions">
                                Eventos enviados
                            </Typography.Text>
                            <div
                                style={{
                                    height: '1px',
                                    borderBottom: '1px solid #D8DAE5',
                                    flex: 1,
                                    marginLeft: '15px',
                                }}
                            ></div>
                            <ShowIcon id="logs" />
                        </Row>
                        {show.includes('logs') && (
                            <div
                                style={{
                                    marginTop: '5px',
                                    backgroundColor: '#fafbfd',
                                    padding: '10px',
                                    borderRadius: '15px',
                                }}
                            >
                                <LogsRelatedToElement id={id} />
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        </Suspense>
    )
}

export default PaymentDetailsView
