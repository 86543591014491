import { Alert, Button, Col, Form, message, Modal, Row, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../context/modalsSlice";
import { setData } from "../context/dataSlice";
import { SelectInput, TextInput } from "../components/Forms/Inputs";
import { CFDIUses, paymentForms, paymentMethods, taxRegimes } from "../datasets/Fiscal";
import { countryLabeledListWithCodeAlpha3 } from "../datasets/Countries";
import ItemsSelection from "../components/ComponentIndividual/ItemsSelection";
import ProductsServicesTotals from "../components/ComponentIndividual/ProductsServicesTotals";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { useState } from "react";
import { curreciesSmallLabel } from "../datasets/Currencies";

export function EditFailedMassInvoice() {
    const { editFailedMassInvoiceVisible } = useSelector((state: any) => state.modals)
    const { preloadedFailedInvoiceData, selectedServices } = useSelector(
        (state: any) => state.data,
    )
    const { team } = useSelector((state: any) => state.team)

    const [form] = Form.useForm()

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const handleUpdateInvoice = async (values: any) => {
        try {
            setLoading(true)
            const updatedInvoice = {
                "data.client.legalName": values.legalName,
                "data.client.address.country": values.country,
                "data.client.address.zip": values.zip,
                "data.client.rfc": values.rfc,
                "data.client.tax_system": values.tax_system,
                "data.series": values.series,
                "data.use": values.use,
                "data.payment_form": values.payment_form,
                "data.payment_method": values.payment_method,
                "data.currency": values.currency,
                "data.exchange": values.exchange,
                "data.internalItems": selectedServices[0] ? selectedServices : preloadedFailedInvoiceData?.data?.internalItems,
                "status": "processing",
                "run": true
            }
            const invoiceRef = doc(getFirestore(), preloadedFailedInvoiceData?.route)
            await updateDoc(invoiceRef, updatedInvoice)

            message.success('Factura actualizada correctamente')
            dispatch(closeModal('editFailedMassInvoiceVisible'))
            dispatch(
                setData({
                    item: 'preloadedInvoiceData',
                    data: null,
                }),
            )
            setLoading(false)
        } catch (error: any) {
            console.log(error)
            message.error(error.message || 'Ocurrió un error al actualizar la factura')
            setLoading(false)
        }
    }

    return (
        <Modal
            open={editFailedMassInvoiceVisible}
            footer={null}
            title={<div style={{paddingBottom: "15px"}}>
                <Typography.Text
                    className="h6-bold"
                    style={{
                        position: 'absolute',
                        //center the text
                        left: '50%',
                        transform: 'translateX(-50%)',
                        paddingTop: '5px'
                    }}
                >
                    Confirma la información
                </Typography.Text>
            </div>}
            onCancel={() => {
                dispatch(closeModal('editFailedMassInvoiceVisible'))
                dispatch(
                    setData({
                        item: 'preloadedInvoiceData',
                        data: null,
                    }),
                )
            }}
            width={800}
        >
            <Form
                layout="vertical"
                onFinish={handleUpdateInvoice}
            >
                <Row
                    style={{paddingBottom: '15px', paddingTop: '20px'}}
                    >
                    <Alert 
                        style={{ width: '100%' }}
                        message={
                            <Typography.Text className="p-small-regular">
                                {preloadedFailedInvoiceData?.error}
                            </Typography.Text>
                        }   
                        type="error"
                    />
                </Row>
                <Row>
                    <Col xs={24}>
                        <TextInput
                            rules={[{ required: true, message: 'Este campo es requerido' }]}
                            label="Nombre Legal"
                            name="legalName"
                            initialValue={preloadedFailedInvoiceData?.data?.client?.legalName}
                        />
                    </Col>
                </Row>
                <Row style={{ justifyContent: 'space-between' }}>
                    <Col xs={11}>
                        <SelectInput
                            name="country"
                            onChange={(v: any) => {
                                form.validateFields(['rfc'])
                                if (v !== 'MEX') {
                                    form.setFieldsValue({
                                        tax_system: '616',
                                    })
                                }
                            }}
                            placeholder="Mexico"
                            label="País"
                            options={countryLabeledListWithCodeAlpha3.map((c: any) => {
                                return {
                                    label: c.name,
                                    value: c.code,
                                }
                            })}
                            rules={[{ required: true, message: 'Este campo es requerido' }]}
                            initialValues={preloadedFailedInvoiceData?.data?.client?.address?.country}
                        />
                    </Col>
                    <Col xs={11}>
                        <TextInput
                            rules={[{ required: true, message: 'Este campo es requerido' }]}
                            label="Código Postal"
                            name="zip"
                            initialValue={preloadedFailedInvoiceData?.data?.client?.address?.zip}
                        />
                    </Col>
                </Row>
                <Row
                    style={{
                        justifyContent: 'space-between',
                    }}
                >
                    <Col xs={11}>
                        <TextInput
                            rules={[{ required: true, message: 'Este campo es requerido' }]}
                            label="RFC"
                            name="rfc"
                            initialValue={preloadedFailedInvoiceData?.data?.client?.rfc}
                        />
                    </Col>
                    <Col xs={11}>
                        <SelectInput
                            name="tax_system"
                            placeholder="Régimen Fiscal"
                            label="Régimen Fiscal"
                            options={taxRegimes.map((c) => {
                                return {
                                    label: c.label,
                                    value: c.value,
                                }
                            })}
                            initialValues={preloadedFailedInvoiceData?.data?.client?.tax_system}
                            rules={[{ required: true, message: 'Este campo es requerido' }]}
                        />
                    </Col>
                </Row>
                <Row style={{ justifyContent: 'space-between' }}>
                    <Col xs={11}>
                        <TextInput
                            rules={[{ required: true, message: 'Este campo es requerido' }]}
                            label="Serie"
                            name="series"
                            initialValue={preloadedFailedInvoiceData?.data?.series}
                        />
                    </Col>
                    <Col xs={11}>
                        <SelectInput
                            name="use"
                            label="Uso del CFDI"
                            options={CFDIUses}
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor selecciona el uso de la factura',
                                },
                            ]}
                            initialValues={preloadedFailedInvoiceData?.data?.use}
                        />
                    </Col>
                </Row>
                <Row style={{ justifyContent: 'space-between' }}>
                    <Col xs={11}>
                        <SelectInput
                            name="payment_form"
                            label="Forma de pago"
                            placeholder="Selecciona una forma de pago"
                            options={paymentForms}
                            onChange={(v: any) => {
                                if (team?.defaults?.manualInvoicesConfig?.['PUE'] === v) {
                                    form.setFieldsValue({
                                        paymentMethod: 'PUE',
                                    })
                                }
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor selecciona la forma de pago',
                                },
                            ]}
                            initialValues={preloadedFailedInvoiceData?.data?.payment_form}
                        />
                    </Col>
                    <Col xs={11}>
                        <SelectInput
                            name="payment_method"
                            label="Método de pago"
                            placeholder="Selecciona un método de pago"
                            options={paymentMethods}
                            onChange={(v: any) => {
                                if (team?.defaults?.manualInvoicesConfig?.[v]) {
                                    form.setFieldsValue({
                                        paymentForm: team?.defaults?.manualInvoicesConfig?.[v] || '99',
                                    })
                                }
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor selecciona el método de pago',
                                },
                            ]}
                            initialValues={preloadedFailedInvoiceData?.data?.payment_method}
                        />
                    </Col>
                </Row>
                <Row style={{ justifyContent: 'space-between' }}>
                    <Col xs={11}>
                    <SelectInput
                            name="currency"
                            label="Divisa"
                            options={curreciesSmallLabel}
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor selecciona el método de pago',
                                },
                            ]}
                            initialValues={preloadedFailedInvoiceData?.data?.currency.toUpperCase()}
                        />
                    </Col>
                    <Col xs={11}>
                        <TextInput
                                rules={[{ required: true, message: 'Este campo es requerido' }]}
                                label="Tipo de cambio"
                                name="exchange"
                                initialValue={preloadedFailedInvoiceData?.data?.exchange}
                            />
                    </Col>
                </Row>
                <>
                <ItemsSelection incomingServices={preloadedFailedInvoiceData?.data?.internalItems} disabled={false} hideTitle={true} />
                            <Row justify="end">
                                <ProductsServicesTotals incomingItems={preloadedFailedInvoiceData?.data?.internalItems} boxed={false} />
                            </Row>
                </>
                <Row justify="end" style={{ marginTop: '10px' }}>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Actualizar y facturar
                    </Button>
                </Row>
            </Form>
        </Modal>
    )
}