interface CountryCodes {
    code: string
    name: string
    alpha2?: string
    eu: boolean
}

export const countryLabeledListWithCodeAlpha: CountryCodes[] = [
    {
        code: 'AFG',
        name: 'Afghanistan',
        eu: false,
    },
    {
        code: 'ALA',
        name: 'Åland Islands',
        eu: false,
    },
    {
        code: 'ALB',
        name: 'Albania',
        eu: false,
    },
    {
        code: 'DZA',
        name: 'Algeria',
        eu: false,
    },
    {
        code: 'ASM',
        name: 'American Samoa',
        eu: false,
    },
    {
        code: 'AND',
        name: 'Andorra',
        eu: false,
    },
    {
        code: 'AGO',
        name: 'Angola',
        eu: false,
    },
    {
        code: 'AIA',
        name: 'Anguilla',
        eu: false,
    },
    {
        code: 'ATA',
        name: 'Antarctica',
        eu: false,
    },
    {
        code: 'ATG',
        name: 'Antigua and Barbuda',
        eu: false,
    },
    {
        code: 'ARG',
        name: 'Argentina',
        eu: false,
    },
    {
        code: 'ARM',
        name: 'Armenia',
        eu: false,
    },
    {
        code: 'ABW',
        name: 'Aruba',
        eu: false,
    },
    {
        code: 'AUS',
        name: 'Australia',
        eu: false,
    },
    {
        code: 'AUT',
        name: 'Austria',
        eu: true,
    },
    {
        code: 'AZE',
        name: 'Azerbaijan',
        eu: false,
    },
    {
        code: 'BHS',
        name: 'Bahamas',
        eu: false,
    },
    {
        code: 'BHR',
        name: 'Bahrain',
        eu: false,
    },
    {
        code: 'BGD',
        name: 'Bangladesh',
        eu: false,
    },
    {
        code: 'BRB',
        name: 'Barbados',
        eu: false,
    },
    {
        code: 'BLR',
        name: 'Belarus',
        eu: false,
    },
    {
        code: 'BEL',
        name: 'Belgium',
        eu: true,
    },
    {
        code: 'BLZ',
        name: 'Belize',
        eu: false,
    },
    {
        code: 'BEN',
        name: 'Benin',
        eu: false,
    },
    {
        code: 'BMU',
        name: 'Bermuda',
        eu: false,
    },
    {
        code: 'BTN',
        name: 'Bhutan',
        eu: false,
    },
    {
        code: 'BOL',
        name: 'Bolivia, Plurinational State of',
        eu: false,
    },
    {
        code: 'BES',
        name: 'Bonaire, Sint Eustatius and Saba',
        eu: false,
    },
    {
        code: 'BIH',
        name: 'Bosnia and Herzegovina',
        eu: false,
    },
    {
        code: 'BWA',
        name: 'Botswana',
        eu: false,
    },
    {
        code: 'BVT',
        name: 'Bouvet Island',
        eu: false,
    },
    {
        code: 'BRA',
        name: 'Brazil',
        eu: false,
    },
    {
        code: 'IOT',
        name: 'British Indian Ocean Territory',
        eu: false,
    },
    {
        code: 'BRN',
        name: 'Brunei Darussalam',
        eu: false,
    },
    {
        code: 'BGR',
        name: 'Bulgaria',
        eu: true,
    },
    {
        code: 'BFA',
        name: 'Burkina Faso',
        eu: false,
    },
    {
        code: 'BDI',
        name: 'Burundi',
        eu: false,
    },
    {
        code: 'KHM',
        name: 'Cambodia',
        eu: false,
    },
    {
        code: 'CMR',
        name: 'Cameroon',
        eu: false,
    },
    {
        code: 'CAN',
        name: 'Canada',
        eu: false,
    },
    {
        code: 'CPV',
        name: 'Cape Verde',
        eu: false,
    },
    {
        code: 'CYM',
        name: 'Cayman Islands',
        eu: false,
    },
    {
        code: 'CAF',
        name: 'Central African Republic',
        eu: false,
    },
    {
        code: 'TCD',
        name: 'Chad',
        eu: false,
    },
    {
        code: 'CHL',
        name: 'Chile',
        eu: false,
    },
    {
        code: 'CHN',
        name: 'China',
        eu: false,
    },
    {
        code: 'CXR',
        name: 'Christmas Island',
        eu: false,
    },
    {
        code: 'CCK',
        name: 'Cocos (Keeling) Islands',
        eu: false,
    },
    {
        code: 'COL',
        name: 'Colombia',
        eu: false,
    },
    {
        code: 'COM',
        name: 'Comoros',
        eu: false,
    },
    {
        code: 'COG',
        name: 'Congo',
        eu: false,
    },
    {
        code: 'COD',
        name: 'Congo, the Democratic Republic of the',
        eu: false,
    },
    {
        code: 'COK',
        name: 'Cook Islands',
        eu: false,
    },
    {
        code: 'CRI',
        name: 'Costa Rica',
        eu: false,
    },
    {
        code: 'CIV',
        name: "Côte d'Ivoire",
        eu: false,
    },
    {
        code: 'HRV',
        name: 'Croatia',
        eu: true,
    },
    {
        code: 'CUB',
        name: 'Cuba',
        eu: false,
    },
    {
        code: 'CUW',
        name: 'Curaçao',
        eu: false,
    },
    {
        code: 'CYP',
        name: 'Cyprus',
        eu: true,
    },
    {
        code: 'CZE',
        name: 'Czech Republic',
        eu: true,
    },
    {
        code: 'DNK',
        name: 'Denmark',
        eu: true,
    },
    {
        code: 'DJI',
        name: 'Djibouti',
        eu: false,
    },
    {
        code: 'DMA',
        name: 'Dominica',
        eu: false,
    },
    {
        code: 'DOM',
        name: 'Dominican Republic',
        eu: false,
    },
    {
        code: 'ECU',
        name: 'Ecuador',
        eu: false,
    },
    {
        code: 'EGY',
        name: 'Egypt',
        eu: false,
    },
    {
        code: 'SLV',
        name: 'El Salvador',
        eu: false,
    },
    {
        code: 'GNQ',
        name: 'Equatorial Guinea',
        eu: false,
    },
    {
        code: 'ERI',
        name: 'Eritrea',
        eu: false,
    },
    {
        code: 'EST',
        name: 'Estonia',
        eu: true,
    },
    {
        code: 'ETH',
        name: 'Ethiopia',
        eu: false,
    },
    {
        code: 'FLK',
        name: 'Falkland Islands (Malvinas)',
        eu: false,
    },
    {
        code: 'FRO',
        name: 'Faroe Islands',
        eu: false,
    },
    {
        code: 'FJI',
        name: 'Fiji',
        eu: false,
    },
    {
        code: 'FIN',
        name: 'Finland',
        eu: true,
    },
    {
        code: 'FRA',
        name: 'France',
        eu: true,
    },
    {
        code: 'GUF',
        name: 'French Guiana',
        eu: false,
    },
    {
        code: 'PYF',
        name: 'French Polynesia',
        eu: false,
    },
    {
        code: 'ATF',
        name: 'French Southern Territories',
        eu: false,
    },
    {
        code: 'GAB',
        name: 'Gabon',
        eu: false,
    },
    {
        code: 'GMB',
        name: 'Gambia',
        eu: false,
    },
    {
        code: 'GEO',
        name: 'Georgia',
        eu: false,
    },
    {
        code: 'DEU',
        name: 'Germany',
        eu: true,
    },
    {
        code: 'GHA',
        name: 'Ghana',
        eu: false,
    },
    {
        code: 'GIB',
        name: 'Gibraltar',
        eu: false,
    },
    {
        code: 'GRC',
        name: 'Greece',
        eu: true,
    },
    {
        code: 'GRL',
        name: 'Greenland',
        eu: false,
    },
    {
        code: 'GRD',
        name: 'Grenada',
        eu: false,
    },
    {
        code: 'GLP',
        name: 'Guadeloupe',
        eu: false,
    },
    {
        code: 'GUM',
        name: 'Guam',
        eu: false,
    },
    {
        code: 'GTM',
        name: 'Guatemala',
        eu: false,
    },
    {
        code: 'GGY',
        name: 'Guernsey',
        eu: false,
    },
    {
        code: 'GIN',
        name: 'Guinea',
        eu: false,
    },
    {
        code: 'GNB',
        name: 'Guinea-Bissau',
        eu: false,
    },
    {
        code: 'GUY',
        name: 'Guyana',
        eu: false,
    },
    {
        code: 'HTI',
        name: 'Haiti',
        eu: false,
    },
    {
        code: 'HMD',
        name: 'Heard Island and McDonald Islands',
        eu: false,
    },
    {
        code: 'VAT',
        name: 'Holy See (Vatican City State)',
        eu: false,
    },
    {
        code: 'HND',
        name: 'Honduras',
        eu: false,
    },
    {
        code: 'HKG',
        name: 'Hong Kong',
        eu: false,
    },
    {
        code: 'HUN',
        name: 'Hungary',
        eu: true,
    },
    {
        code: 'ISL',
        name: 'Iceland',
        eu: false,
    },
    {
        code: 'IND',
        name: 'India',
        eu: false,
    },
    {
        code: 'IDN',
        name: 'Indonesia',
        eu: false,
    },
    {
        code: 'IRN',
        name: 'Iran, Islamic Republic of',
        eu: false,
    },
    {
        code: 'IRQ',
        name: 'Iraq',
        eu: false,
    },
    {
        code: 'IRL',
        name: 'Ireland',
        eu: true,
    },
    {
        code: 'IMN',
        name: 'Isle of Man',
        eu: false,
    },
    {
        code: 'ISR',
        name: 'Israel',
        eu: false,
    },
    {
        code: 'ITA',
        name: 'Italy',
        eu: true,
    },
    {
        code: 'JAM',
        name: 'Jamaica',
        eu: false,
    },
    {
        code: 'JPN',
        name: 'Japan',
        eu: false,
    },
    {
        code: 'JEY',
        name: 'Jersey',
        eu: false,
    },
    {
        code: 'JOR',
        name: 'Jordan',
        eu: false,
    },
    {
        code: 'KAZ',
        name: 'Kazakhstan',
        eu: false,
    },
    {
        code: 'KEN',
        name: 'Kenya',
        eu: false,
    },
    {
        code: 'KIR',
        name: 'Kiribati',
        eu: false,
    },
    {
        code: 'PRK',
        name: "Korea, Democratic People's Republic of",
        eu: false,
    },
    {
        code: 'KOR',
        name: 'Korea, Republic of',
        eu: false,
    },
    {
        code: 'KWT',
        name: 'Kuwait',
        eu: false,
    },
    {
        code: 'KGZ',
        name: 'Kyrgyzstan',
        eu: false,
    },
    {
        code: 'LAO',
        name: "Lao People's Democratic Republic",
        eu: false,
    },
    {
        code: 'LVA',
        name: 'Latvia',
        eu: true,
    },
    {
        code: 'LBN',
        name: 'Lebanon',
        eu: false,
    },
    {
        code: 'LSO',
        name: 'Lesotho',
        eu: false,
    },
    {
        code: 'LBR',
        name: 'Liberia',
        eu: false,
    },
    {
        code: 'LBY',
        name: 'Libya',
        eu: false,
    },
    {
        code: 'LIE',
        name: 'Liechtenstein',
        eu: false,
    },
    {
        code: 'LTU',
        name: 'Lithuania',
        eu: true,
    },
    {
        code: 'LUX',
        name: 'Luxembourg',
        eu: true,
    },
    {
        code: 'MAC',
        name: 'Macao',
        eu: false,
    },
    {
        code: 'MKD',
        name: 'Macedonia, the former Yugoslav Republic of',
        eu: false,
    },
    {
        code: 'MDG',
        name: 'Madagascar',
        eu: false,
    },
    {
        code: 'MWI',
        name: 'Malawi',
        eu: false,
    },
    {
        code: 'MYS',
        name: 'Malaysia',
        eu: false,
    },
    {
        code: 'MDV',
        name: 'Maldives',
        eu: false,
    },
    {
        code: 'MLI',
        name: 'Mali',
        eu: false,
    },
    {
        code: 'MLT',
        name: 'Malta',
        eu: true,
    },
    {
        code: 'MHL',
        name: 'Marshall Islands',
        eu: false,
    },
    {
        code: 'MTQ',
        name: 'Martinique',
        eu: false,
    },
    {
        code: 'MRT',
        name: 'Mauritania',
        eu: false,
    },
    {
        code: 'MUS',
        name: 'Mauritius',
        eu: false,
    },
    {
        code: 'MYT',
        name: 'Mayotte',
        eu: false,
    },
    {
        code: 'MEX',
        name: 'Mexico',
        eu: false,
    },
    {
        code: 'FSM',
        name: 'Micronesia, Federated States of',
        eu: false,
    },
    {
        code: 'MDA',
        name: 'Moldova, Republic of',
        eu: false,
    },
    {
        code: 'MCO',
        name: 'Monaco',
        eu: false,
    },
    {
        code: 'MNG',
        name: 'Mongolia',
        eu: false,
    },
    {
        code: 'MNE',
        name: 'Montenegro',
        eu: false,
    },
    {
        code: 'MSR',
        name: 'Montserrat',
        eu: false,
    },
    {
        code: 'MAR',
        name: 'Morocco',
        eu: false,
    },
    {
        code: 'MOZ',
        name: 'Mozambique',
        eu: false,
    },
    {
        code: 'MMR',
        name: 'Myanmar',
        eu: false,
    },
    {
        code: 'NAM',
        name: 'Namibia',
        eu: false,
    },
    {
        code: 'NRU',
        name: 'Nauru',
        eu: false,
    },
    {
        code: 'NPL',
        name: 'Nepal',
        eu: false,
    },
    {
        code: 'NLD',
        name: 'Netherlands',
        eu: true,
    },
    {
        code: 'NCL',
        name: 'New Caledonia',
        eu: false,
    },
    {
        code: 'NZL',
        name: 'New Zealand',
        eu: false,
    },
    {
        code: 'NIC',
        name: 'Nicaragua',
        eu: false,
    },
    {
        code: 'NER',
        name: 'Niger',
        eu: false,
    },
    {
        code: 'NGA',
        name: 'Nigeria',
        eu: false,
    },
    {
        code: 'NIU',
        name: 'Niue',
        eu: false,
    },
    {
        code: 'NFK',
        name: 'Norfolk Island',
        eu: false,
    },
    {
        code: 'MNP',
        name: 'Northern Mariana Islands',
        eu: false,
    },
    {
        code: 'NOR',
        name: 'Norway',
        eu: false,
    },
    {
        code: 'OMN',
        name: 'Oman',
        eu: false,
    },
    {
        code: 'PAK',
        name: 'Pakistan',
        eu: false,
    },
    {
        code: 'PLW',
        name: 'Palau',
        eu: false,
    },
    {
        code: 'PSE',
        name: 'Palestinian Territory, Occupied',
        eu: false,
    },
    {
        code: 'PAN',
        name: 'Panama',
        eu: false,
    },
    {
        code: 'PNG',
        name: 'Papua New Guinea',
        eu: false,
    },
    {
        code: 'PRY',
        name: 'Paraguay',
        eu: false,
    },
    {
        code: 'PER',
        name: 'Peru',
        eu: false,
    },
    {
        code: 'PHL',
        name: 'Philippines',
        eu: false,
    },
    {
        code: 'PCN',
        name: 'Pitcairn',
        eu: false,
    },
    {
        code: 'POL',
        name: 'Poland',
        eu: true,
    },
    {
        code: 'PRT',
        name: 'Portugal',
        eu: true,
    },
    {
        code: 'PRI',
        name: 'Puerto Rico',
        eu: false,
    },
    {
        code: 'QAT',
        name: 'Qatar',
        eu: false,
    },
    {
        code: 'REU',
        name: 'Réunion',
        eu: false,
    },
    {
        code: 'ROU',
        name: 'Romania',
        eu: true,
    },
    {
        code: 'RUS',
        name: 'Russian Federation',
        eu: false,
    },
    {
        code: 'RWA',
        name: 'Rwanda',
        eu: false,
    },
    {
        code: 'BLM',
        name: 'Saint Barthélemy',
        eu: false,
    },
    {
        code: 'SHN',
        name: 'Saint Helena, Ascension and Tristan da Cunha',
        eu: false,
    },
    {
        code: 'KNA',
        name: 'Saint Kitts and Nevis',
        eu: false,
    },
    {
        code: 'LCA',
        name: 'Saint Lucia',
        eu: false,
    },
    {
        code: 'MAF',
        name: 'Saint Martin (French part)',
        eu: false,
    },
    {
        code: 'SPM',
        name: 'Saint Pierre and Miquelon',
        eu: false,
    },
    {
        code: 'VCT',
        name: 'Saint Vincent and the Grenadines',
        eu: false,
    },
    {
        code: 'WSM',
        name: 'Samoa',
        eu: false,
    },
    {
        code: 'SMR',
        name: 'San Marino',
        eu: false,
    },
    {
        code: 'STP',
        name: 'Sao Tome and Principe',
        eu: false,
    },
    {
        code: 'SAU',
        name: 'Saudi Arabia',
        eu: false,
    },
    {
        code: 'SEN',
        name: 'Senegal',
        eu: false,
    },
    {
        code: 'SRB',
        name: 'Serbia',
        eu: false,
    },
    {
        code: 'SYC',
        name: 'Seychelles',
        eu: false,
    },
    {
        code: 'SLE',
        name: 'Sierra Leone',
        eu: false,
    },
    {
        code: 'SGP',
        name: 'Singapore',
        eu: false,
    },
    {
        code: 'SXM',
        name: 'Sint Maarten (Dutch part)',
        eu: false,
    },
    {
        code: 'SVK',
        name: 'Slovakia',
        eu: true,
    },
    {
        code: 'SVN',
        name: 'Slovenia',
        eu: true,
    },
    {
        code: 'SLB',
        name: 'Solomon Islands',
        eu: false,
    },
    {
        code: 'SOM',
        name: 'Somalia',
        eu: false,
    },
    {
        code: 'ZAF',
        name: 'South Africa',
        eu: false,
    },
    {
        code: 'SGS',
        name: 'South Georgia and the South Sandwich Islands',
        eu: false,
    },
    {
        code: 'SSD',
        name: 'South Sudan',
        eu: false,
    },
    {
        code: 'ESP',
        name: 'Spain',
        eu: true,
    },
    {
        code: 'LKA',
        name: 'Sri Lanka',
        eu: false,
    },
    {
        code: 'SDN',
        name: 'Sudan',
        eu: false,
    },
    {
        code: 'SUR',
        name: 'Suriname',
        eu: false,
    },
    {
        code: 'SJM',
        name: 'Svalbard and Jan Mayen',
        eu: false,
    },
    {
        code: 'SWZ',
        name: 'Swaziland',
        eu: false,
    },
    {
        code: 'SWE',
        name: 'Sweden',
        eu: true,
    },
    {
        code: 'CHE',
        name: 'Switzerland',
        eu: false,
    },
    {
        code: 'SYR',
        name: 'Syrian Arab Republic',
        eu: false,
    },
    {
        code: 'TWN',
        name: 'Taiwan, Province of China',
        eu: false,
    },
    {
        code: 'TJK',
        name: 'Tajikistan',
        eu: false,
    },
    {
        code: 'TZA',
        name: 'Tanzania, United Republic of',
        eu: false,
    },
    {
        code: 'THA',
        name: 'Thailand',
        eu: false,
    },
    {
        code: 'TLS',
        name: 'Timor-Leste',
        eu: false,
    },
    {
        code: 'TGO',
        name: 'Togo',
        eu: false,
    },
    {
        code: 'TKL',
        name: 'Tokelau',
        eu: false,
    },
    {
        code: 'TON',
        name: 'Tonga',
        eu: false,
    },
    {
        code: 'TTO',
        name: 'Trinidad and Tobago',
        eu: false,
    },
    {
        code: 'TUN',
        name: 'Tunisia',
        eu: false,
    },
    {
        code: 'TUR',
        name: 'Turkey',
        eu: false,
    },
    {
        code: 'TKM',
        name: 'Turkmenistan',
        eu: false,
    },
    {
        code: 'TCA',
        name: 'Turks and Caicos Islands',
        eu: false,
    },
    {
        code: 'TUV',
        name: 'Tuvalu',
        eu: false,
    },
    {
        code: 'UGA',
        name: 'Uganda',
        eu: false,
    },
    {
        code: 'UKR',
        name: 'Ukraine',
        eu: false,
    },
    {
        code: 'ARE',
        name: 'United Arab Emirates',
        eu: false,
    },
    {
        code: 'GBR',
        name: 'United Kingdom',
        eu: true,
    },
    {
        code: 'USA',
        name: 'United States',
        eu: false,
    },
    {
        code: 'UMI',
        name: 'United States Minor Outlying Islands',
        eu: false,
    },
    {
        code: 'URY',
        name: 'Uruguay',
        eu: false,
    },
    {
        code: 'UZB',
        name: 'Uzbekistan',
        eu: false,
    },
    {
        code: 'VUT',
        name: 'Vanuatu',
        eu: false,
    },
    {
        code: 'VEN',
        name: 'Venezuela, Bolivarian Republic of',
        eu: false,
    },
    {
        code: 'VNM',
        name: 'Viet Nam',
        eu: false,
    },
    {
        code: 'VGB',
        name: 'Virgin Islands, British',
        eu: false,
    },
    {
        code: 'VIR',
        name: 'Virgin Islands, U.S.',
        eu: false,
    },
    {
        code: 'WLF',
        name: 'Wallis and Futuna',
        eu: false,
    },
    {
        code: 'ESH',
        name: 'Western Sahara',
        eu: false,
    },
    {
        code: 'YEM',
        name: 'Yemen',
        eu: false,
    },
    {
        code: 'ZMB',
        name: 'Zambia',
        eu: false,
    },
    {
        code: 'ZWE',
        name: 'Zimbabwe',
        eu: false,
    },
]

export const countryLabeledListWithCodeAlpha3: CountryCodes[] = [
    {
        code: 'AFG',
        alpha2: 'AF',
        name: 'Afghanistan',
        eu: false,
    },
    {
        code: 'ALA',
        alpha2: 'AX',
        name: 'Åland Islands',
        eu: false,
    },
    {
        code: 'ALB',
        alpha2: 'AL',
        name: 'Albania',
        eu: false,
    },
    {
        code: 'DZA',
        alpha2: 'DZ',
        name: 'Algeria',
        eu: false,
    },
    {
        code: 'ASM',
        alpha2: 'AS',
        name: 'American Samoa',
        eu: false,
    },
    {
        code: 'AND',
        alpha2: 'AD',
        name: 'Andorra',
        eu: false,
    },
    {
        code: 'AGO',
        alpha2: 'AO',
        name: 'Angola',
        eu: false,
    },
    {
        code: 'AIA',
        alpha2: 'AI',
        name: 'Anguilla',
        eu: false,
    },
    {
        code: 'ATA',
        alpha2: 'AQ',
        name: 'Antarctica',
        eu: false,
    },
    {
        code: 'ATG',
        alpha2: 'AG',
        name: 'Antigua and Barbuda',
        eu: false,
    },
    {
        code: 'ARG',
        alpha2: 'AR',
        name: 'Argentina',
        eu: false,
    },
    {
        code: 'ARM',
        alpha2: 'AM',
        name: 'Armenia',
        eu: false,
    },
    {
        code: 'ABW',
        alpha2: 'AW',
        name: 'Aruba',
        eu: false,
    },
    {
        code: 'AUS',
        alpha2: 'AU',
        name: 'Australia',
        eu: false,
    },
    {
        code: 'AUT',
        alpha2: 'AT',
        name: 'Austria',
        eu: true,
    },
    {
        code: 'AZE',
        alpha2: 'AZ',
        name: 'Azerbaijan',
        eu: false,
    },
    {
        code: 'BHS',
        alpha2: 'BS',
        name: 'Bahamas',
        eu: false,
    },
    {
        code: 'BHR',
        alpha2: 'BH',
        name: 'Bahrain',
        eu: false,
    },
    {
        code: 'BGD',
        alpha2: 'BD',
        name: 'Bangladesh',
        eu: false,
    },
    {
        code: 'BRB',
        alpha2: 'BB',
        name: 'Barbados',
        eu: false,
    },
    {
        code: 'BLR',
        alpha2: 'BY',
        name: 'Belarus',
        eu: false,
    },
    {
        code: 'BEL',
        alpha2: 'BE',
        name: 'Belgium',
        eu: true,
    },
    {
        code: 'BLZ',
        alpha2: 'BZ',
        name: 'Belize',
        eu: false,
    },
    {
        code: 'BEN',
        alpha2: 'BJ',
        name: 'Benin',
        eu: false,
    },
    {
        code: 'BMU',
        alpha2: 'BM',
        name: 'Bermuda',
        eu: false,
    },
    {
        code: 'BTN',
        alpha2: 'BT',
        name: 'Bhutan',
        eu: false,
    },
    {
        code: 'BOL',
        alpha2: 'BO',
        name: 'Bolivia',
        eu: false,
    },
    {
        code: 'BES',
        alpha2: 'BQ',
        name: 'Bonaire, Sint Eustatius and Saba',
        eu: false,
    },
    {
        code: 'BIH',
        alpha2: 'BA',
        name: 'Bosnia and Herzegovina',
        eu: false,
    },
    {
        code: 'BWA',
        alpha2: 'BW',
        name: 'Botswana',
        eu: false,
    },
    {
        code: 'BVT',
        alpha2: 'BV',
        name: 'Bouvet Island',
        eu: false,
    },
    {
        code: 'BRA',
        alpha2: 'BR',
        name: 'Brazil',
        eu: false,
    },
    {
        code: 'IOT',
        alpha2: 'IO',
        name: 'British Indian Ocean Territory',
        eu: false,
    },
    {
        code: 'BRN',
        alpha2: 'BN',
        name: 'Brunei Darussalam',
        eu: false,
    },
    {
        code: 'BGR',
        alpha2: 'BG',
        name: 'Bulgaria',
        eu: true,
    },
    {
        code: 'BFA',
        alpha2: 'BF',
        name: 'Burkina Faso',
        eu: false,
    },
    {
        code: 'BDI',
        alpha2: 'BI',
        name: 'Burundi',
        eu: false,
    },
    {
        code: 'KHM',
        alpha2: 'KH',
        name: 'Cambodia',
        eu: false,
    },
    {
        code: 'CMR',
        alpha2: 'CM',
        name: 'Cameroon',
        eu: false,
    },
    {
        code: 'CAN',
        alpha2: 'CA',
        name: 'Canada',
        eu: false,
    },
    {
        code: 'CPV',
        alpha2: 'CV',
        name: 'Cape Verde',
        eu: false,
    },
    {
        code: 'CYM',
        alpha2: 'KY',
        name: 'Cayman Islands',
        eu: false,
    },
    {
        code: 'CAF',
        alpha2: 'CF',
        name: 'Central African Republic',
        eu: false,
    },
    {
        code: 'TCD',
        alpha2: 'TD',
        name: 'Chad',
        eu: false,
    },
    {
        code: 'CHL',
        alpha2: 'CL',
        name: 'Chile',
        eu: false,
    },
    {
        code: 'CHN',
        alpha2: 'CN',
        name: 'China',
        eu: false,
    },
    {
        code: 'CXR',
        alpha2: 'CX',
        name: 'Christmas Island',
        eu: false,
    },
    {
        code: 'CCK',
        alpha2: 'CC',
        name: 'Cocos (Keeling) Islands',
        eu: false,
    },
    {
        code: 'COL',
        alpha2: 'CO',
        name: 'Colombia',
        eu: false,
    },
    {
        code: 'COM',
        alpha2: 'KM',
        name: 'Comoros',
        eu: false,
    },
    {
        code: 'COG',
        alpha2: 'CG',
        name: 'Congo',
        eu: false,
    },
    {
        code: 'COD',
        alpha2: 'CD',
        name: 'Congo, Democratic Republic of the',
        eu: false,
    },
    {
        code: 'COK',
        alpha2: 'CK',
        name: 'Cook Islands',
        eu: false,
    },
    {
        code: 'CRI',
        alpha2: 'CR',
        name: 'Costa Rica',
        eu: false,
    },
    {
        code: 'CIV',
        alpha2: 'CI',
        name: "Côte d'Ivoire",
        eu: false,
    },
    {
        code: 'HRV',
        alpha2: 'HR',
        name: 'Croatia',
        eu: true,
    },
    {
        code: 'CUB',
        alpha2: 'CU',
        name: 'Cuba',
        eu: false,
    },
    {
        code: 'CUW',
        alpha2: 'CW',
        name: 'Curaçao',
        eu: false,
    },
    {
        code: 'CYP',
        alpha2: 'CY',
        name: 'Cyprus',
        eu: true,
    },
    {
        code: 'CZE',
        alpha2: 'CZ',
        name: 'Czech Republic',
        eu: true,
    },
    {
        code: 'DNK',
        alpha2: 'DK',
        name: 'Denmark',
        eu: true,
    },
    {
        code: 'DJI',
        alpha2: 'DJ',
        name: 'Djibouti',
        eu: false,
    },
    {
        code: 'DMA',
        alpha2: 'DM',
        name: 'Dominica',
        eu: false,
    },
    {
        code: 'DOM',
        alpha2: 'DO',
        name: 'Dominican Republic',
        eu: false,
    },
    {
        code: 'ECU',
        alpha2: 'EC',
        name: 'Ecuador',
        eu: false,
    },
    {
        code: 'EGY',
        alpha2: 'EG',
        name: 'Egypt',
        eu: false,
    },
    {
        code: 'SLV',
        alpha2: 'SV',
        name: 'El Salvador',
        eu: false,
    },
    {
        code: 'GNQ',
        alpha2: 'GQ',
        name: 'Equatorial Guinea',
        eu: false,
    },
    {
        code: 'ERI',
        alpha2: 'ER',
        name: 'Eritrea',
        eu: false,
    },
    {
        code: 'EST',
        alpha2: 'EE',
        name: 'Estonia',
        eu: true,
    },
    {
        code: 'ETH',
        alpha2: 'ET',
        name: 'Ethiopia',
        eu: false,
    },
    {
        code: 'FLK',
        alpha2: 'FK',
        name: 'Falkland Islands (Malvinas)',
        eu: false,
    },
    {
        code: 'FRO',
        alpha2: 'FO',
        name: 'Faroe Islands',
        eu: false,
    },
    {
        code: 'FJI',
        alpha2: 'FJ',
        name: 'Fiji',
        eu: false,
    },
    {
        code: 'FIN',
        alpha2: 'FI',
        name: 'Finland',
        eu: true,
    },
    {
        code: 'FRA',
        alpha2: 'FR',
        name: 'France',
        eu: true,
    },
    {
        code: 'GUF',
        alpha2: 'GF',
        name: 'French Guiana',
        eu: false,
    },
    {
        code: 'PYF',
        alpha2: 'PF',
        name: 'French Polynesia',
        eu: false,
    },
    {
        code: 'ATF',
        alpha2: 'TF',
        name: 'French Southern Territories',
        eu: false,
    },
    {
        code: 'GAB',
        alpha2: 'GA',
        name: 'Gabon',
        eu: false,
    },
    {
        code: 'GMB',
        alpha2: 'GM',
        name: 'Gambia',
        eu: false,
    },
    {
        code: 'GEO',
        alpha2: 'GE',
        name: 'Georgia',
        eu: false,
    },
    {
        code: 'DEU',
        alpha2: 'DE',
        name: 'Germany',
        eu: true,
    },
    {
        code: 'GHA',
        alpha2: 'GH',
        name: 'Ghana',
        eu: false,
    },
    {
        code: 'GIB',
        alpha2: 'GI',
        name: 'Gibraltar',
        eu: false,
    },
    {
        code: 'GRC',
        alpha2: 'GR',
        name: 'Greece',
        eu: true,
    },
    {
        code: 'GRL',
        alpha2: 'GL',
        name: 'Greenland',
        eu: false,
    },
    {
        code: 'GRD',
        alpha2: 'GD',
        name: 'Grenada',
        eu: false,
    },
    {
        code: 'GLP',
        alpha2: 'GP',
        name: 'Guadeloupe',
        eu: false,
    },
    {
        code: 'GUM',
        alpha2: 'GU',
        name: 'Guam',
        eu: false,
    },
    {
        code: 'GTM',
        alpha2: 'GT',
        name: 'Guatemala',
        eu: false,
    },
    {
        code: 'GGY',
        alpha2: 'GG',
        name: 'Guernsey',
        eu: false,
    },
    {
        code: 'GIN',
        alpha2: 'GN',
        name: 'Guinea',
        eu: false,
    },
    {
        code: 'GNB',
        alpha2: 'GW',
        name: 'Guinea-Bissau',
        eu: false,
    },
    {
        code: 'GUY',
        alpha2: 'GY',
        name: 'Guyana',
        eu: false,
    },
    {
        code: 'HTI',
        alpha2: 'HT',
        name: 'Haiti',
        eu: false,
    },
    {
        code: 'HMD',
        alpha2: 'HM',
        name: 'Heard Island and McDonald Islands',
        eu: false,
    },
    {
        code: 'VAT',
        alpha2: 'VA',
        name: 'Holy See (Vatican City State)',
        eu: false,
    },
    {
        code: 'HND',
        alpha2: 'HN',
        name: 'Honduras',
        eu: false,
    },
    {
        code: 'HKG',
        alpha2: 'HK',
        name: 'Hong Kong',
        eu: false,
    },
    {
        code: 'HUN',
        alpha2: 'HU',
        name: 'Hungary',
        eu: true,
    },
    {
        code: 'ISL',
        alpha2: 'IS',
        name: 'Iceland',
        eu: false,
    },
    {
        code: 'IND',
        alpha2: 'IN',
        name: 'India',
        eu: false,
    },
    {
        code: 'IDN',
        alpha2: 'ID',
        name: 'Indonesia',
        eu: false,
    },
    {
        code: 'IRN',
        alpha2: 'IR',
        name: 'Iran, Islamic Republic of',
        eu: false,
    },
    {
        code: 'IRQ',
        alpha2: 'IQ',
        name: 'Iraq',
        eu: false,
    },
    {
        code: 'IRL',
        alpha2: 'IE',
        name: 'Ireland',
        eu: true,
    },
    {
        code: 'IMN',
        alpha2: 'IM',
        name: 'Isle of Man',
        eu: false,
    },
    {
        code: 'ISR',
        alpha2: 'IL',
        name: 'Israel',
        eu: false,
    },
    {
        code: 'ITA',
        alpha2: 'IT',
        name: 'Italy',
        eu: true,
    },
    {
        code: 'JAM',
        alpha2: 'JM',
        name: 'Jamaica',
        eu: false,
    },
    {
        code: 'JPN',
        alpha2: 'JP',
        name: 'Japan',
        eu: false,
    },
    {
        code: 'JEY',
        alpha2: 'JE',
        name: 'Jersey',
        eu: false,
    },
    {
        code: 'JOR',
        alpha2: 'JO',
        name: 'Jordan',
        eu: false,
    },
    {
        code: 'KAZ',
        alpha2: 'KZ',
        name: 'Kazakhstan',
        eu: false,
    },
    {
        code: 'KEN',
        alpha2: 'KE',
        name: 'Kenya',
        eu: false,
    },
    {
        code: 'KIR',
        alpha2: 'KI',
        name: 'Kiribati',
        eu: false,
    },
    {
        code: 'PRK',
        alpha2: 'KP',
        name: "Korea, Democratic People's Republic of",
        eu: false,
    },
    {
        code: 'KOR',
        alpha2: 'KR',
        name: 'Korea, Republic of',
        eu: false,
    },
    {
        code: 'KWT',
        alpha2: 'KW',
        name: 'Kuwait',
        eu: false,
    },
    {
        code: 'KGZ',
        alpha2: 'KG',
        name: 'Kyrgyzstan',
        eu: false,
    },
    {
        code: 'LAO',
        alpha2: 'LA',
        name: "Lao People's Democratic Republic",
        eu: false,
    },
    {
        code: 'LVA',
        alpha2: 'LV',
        name: 'Latvia',
        eu: true,
    },
    {
        code: 'LBN',
        alpha2: 'LB',
        name: 'Lebanon',
        eu: false,
    },
    {
        code: 'LSO',
        alpha2: 'LS',
        name: 'Lesotho',
        eu: false,
    },
    {
        code: 'LBR',
        alpha2: 'LR',
        name: 'Liberia',
        eu: false,
    },
    {
        code: 'LBY',
        alpha2: 'LY',
        name: 'Libya',
        eu: false,
    },
    {
        code: 'LIE',
        alpha2: 'LI',
        name: 'Liechtenstein',
        eu: false,
    },
    {
        code: 'LTU',
        alpha2: 'LT',
        name: 'Lithuania',
        eu: true,
    },
    {
        code: 'LUX',
        alpha2: 'LU',
        name: 'Luxembourg',
        eu: true,
    },
    {
        code: 'MAC',
        alpha2: 'MO',
        name: 'Macao',
        eu: false,
    },
    {
        code: 'MKD',
        alpha2: 'MK',
        name: 'North Macedonia',
        eu: false,
    },
    {
        code: 'MDG',
        alpha2: 'MG',
        name: 'Madagascar',
        eu: false,
    },
    {
        code: 'MWI',
        alpha2: 'MW',
        name: 'Malawi',
        eu: false,
    },
    {
        code: 'MYS',
        alpha2: 'MY',
        name: 'Malaysia',
        eu: false,
    },
    {
        code: 'MDV',
        alpha2: 'MV',
        name: 'Maldives',
        eu: false,
    },
    {
        code: 'MLI',
        alpha2: 'ML',
        name: 'Mali',
        eu: false,
    },
    {
        code: 'MLT',
        alpha2: 'MT',
        name: 'Malta',
        eu: true,
    },
    {
        code: 'MHL',
        alpha2: 'MH',
        name: 'Marshall Islands',
        eu: false,
    },
    {
        code: 'MTQ',
        alpha2: 'MQ',
        name: 'Martinique',
        eu: false,
    },
    {
        code: 'MRT',
        alpha2: 'MR',
        name: 'Mauritania',
        eu: false,
    },
    {
        code: 'MUS',
        alpha2: 'MU',
        name: 'Mauritius',
        eu: false,
    },
    {
        code: 'MYT',
        alpha2: 'YT',
        name: 'Mayotte',
        eu: false,
    },
    {
        code: 'MEX',
        alpha2: 'MX',
        name: 'Mexico',
        eu: false,
    },
    {
        code: 'FSM',
        alpha2: 'FM',
        name: 'Micronesia, Federated States of',
        eu: false,
    },
    {
        code: 'MDA',
        alpha2: 'MD',
        name: 'Moldova, Republic of',
        eu: false,
    },
    {
        code: 'MCO',
        alpha2: 'MC',
        name: 'Monaco',
        eu: false,
    },
    {
        code: 'MNG',
        alpha2: 'MN',
        name: 'Mongolia',
        eu: false,
    },
    {
        code: 'MNE',
        alpha2: 'ME',
        name: 'Montenegro',
        eu: false,
    },
    {
        code: 'MSR',
        alpha2: 'MS',
        name: 'Montserrat',
        eu: false,
    },
    {
        code: 'MAR',
        alpha2: 'MA',
        name: 'Morocco',
        eu: false,
    },
    {
        code: 'MOZ',
        alpha2: 'MZ',
        name: 'Mozambique',
        eu: false,
    },
    {
        code: 'MMR',
        alpha2: 'MM',
        name: 'Myanmar',
        eu: false,
    },
    {
        code: 'NAM',
        alpha2: 'NA',
        name: 'Namibia',
        eu: false,
    },
    {
        code: 'NRU',
        alpha2: 'NR',
        name: 'Nauru',
        eu: false,
    },
    {
        code: 'NPL',
        alpha2: 'NP',
        name: 'Nepal',
        eu: false,
    },
    {
        code: 'NLD',
        alpha2: 'NL',
        name: 'Netherlands',
        eu: true,
    },
    {
        code: 'NCL',
        alpha2: 'NC',
        name: 'New Caledonia',
        eu: false,
    },
    {
        code: 'NZL',
        alpha2: 'NZ',
        name: 'New Zealand',
        eu: false,
    },
    {
        code: 'NIC',
        alpha2: 'NI',
        name: 'Nicaragua',
        eu: false,
    },
    {
        code: 'NER',
        alpha2: 'NE',
        name: 'Niger',
        eu: false,
    },
    {
        code: 'NGA',
        alpha2: 'NG',
        name: 'Nigeria',
        eu: false,
    },
    {
        code: 'NIU',
        alpha2: 'NU',
        name: 'Niue',
        eu: false,
    },
    {
        code: 'NFK',
        alpha2: 'NF',
        name: 'Norfolk Island',
        eu: false,
    },
    {
        code: 'MNP',
        alpha2: 'MP',
        name: 'Northern Mariana Islands',
        eu: false,
    },
    {
        code: 'NOR',
        alpha2: 'NO',
        name: 'Norway',
        eu: false,
    },
    {
        code: 'OMN',
        alpha2: 'OM',
        name: 'Oman',
        eu: false,
    },
    {
        code: 'PAK',
        alpha2: 'PK',
        name: 'Pakistan',
        eu: false,
    },
    {
        code: 'PLW',
        alpha2: 'PW',
        name: 'Palau',
        eu: false,
    },
    {
        code: 'PSE',
        alpha2: 'PS',
        name: 'Palestine, State of',
        eu: false,
    },
    {
        code: 'PAN',
        alpha2: 'PA',
        name: 'Panama',
        eu: false,
    },
    {
        code: 'PNG',
        alpha2: 'PG',
        name: 'Papua New Guinea',
        eu: false,
    },
    {
        code: 'PRY',
        alpha2: 'PY',
        name: 'Paraguay',
        eu: false,
    },
    {
        code: 'PER',
        alpha2: 'PE',
        name: 'Peru',
        eu: false,
    },
    {
        code: 'PHL',
        alpha2: 'PH',
        name: 'Philippines',
        eu: false,
    },
    {
        code: 'PCN',
        alpha2: 'PN',
        name: 'Pitcairn',
        eu: false,
    },
    {
        code: 'POL',
        alpha2: 'PL',
        name: 'Poland',
        eu: true,
    },
    {
        code: 'PRT',
        alpha2: 'PT',
        name: 'Portugal',
        eu: true,
    },
    {
        code: 'PRI',
        alpha2: 'PR',
        name: 'Puerto Rico',
        eu: false,
    },
    {
        code: 'QAT',
        alpha2: 'QA',
        name: 'Qatar',
        eu: false,
    },
    {
        code: 'REU',
        alpha2: 'RE',
        name: 'Réunion',
        eu: false,
    },
    {
        code: 'ROU',
        alpha2: 'RO',
        name: 'Romania',
        eu: true,
    },
    {
        code: 'RUS',
        alpha2: 'RU',
        name: 'Russian Federation',
        eu: false,
    },
    {
        code: 'RWA',
        alpha2: 'RW',
        name: 'Rwanda',
        eu: false,
    },
    {
        code: 'BLM',
        alpha2: 'BL',
        name: 'Saint Barthélemy',
        eu: false,
    },
    {
        code: 'SHN',
        alpha2: 'SH',
        name: 'Saint Helena, Ascension and Tristan da Cunha',
        eu: false,
    },
    {
        code: 'KNA',
        alpha2: 'KN',
        name: 'Saint Kitts and Nevis',
        eu: false,
    },
    {
        code: 'LCA',
        alpha2: 'LC',
        name: 'Saint Lucia',
        eu: false,
    },
    {
        code: 'MAF',
        alpha2: 'MF',
        name: 'Saint Martin (French part)',
        eu: false,
    },
    {
        code: 'SPM',
        alpha2: 'PM',
        name: 'Saint Pierre and Miquelon',
        eu: false,
    },
    {
        code: 'VCT',
        alpha2: 'VC',
        name: 'Saint Vincent and the Grenadines',
        eu: false,
    },
    {
        code: 'WSM',
        alpha2: 'WS',
        name: 'Samoa',
        eu: false,
    },
    {
        code: 'SMR',
        alpha2: 'SM',
        name: 'San Marino',
        eu: false,
    },
    {
        code: 'STP',
        alpha2: 'ST',
        name: 'Sao Tome and Principe',
        eu: false,
    },
    {
        code: 'SAU',
        alpha2: 'SA',
        name: 'Saudi Arabia',
        eu: false,
    },
    {
        code: 'SEN',
        alpha2: 'SN',
        name: 'Senegal',
        eu: false,
    },
    {
        code: 'SRB',
        alpha2: 'RS',
        name: 'Serbia',
        eu: false,
    },
    {
        code: 'SYC',
        alpha2: 'SC',
        name: 'Seychelles',
        eu: false,
    },
    {
        code: 'SLE',
        alpha2: 'SL',
        name: 'Sierra Leone',
        eu: false,
    },
    {
        code: 'SGP',
        alpha2: 'SG',
        name: 'Singapore',
        eu: false,
    },
    {
        code: 'SXM',
        alpha2: 'SX',
        name: 'Sint Maarten (Dutch part)',
        eu: false,
    },
    {
        code: 'SVK',
        alpha2: 'SK',
        name: 'Slovakia',
        eu: true,
    },
    {
        code: 'SVN',
        alpha2: 'SI',
        name: 'Slovenia',
        eu: true,
    },
    {
        code: 'SLB',
        alpha2: 'SB',
        name: 'Solomon Islands',
        eu: false,
    },
    {
        code: 'SOM',
        alpha2: 'SO',
        name: 'Somalia',
        eu: false,
    },
    {
        code: 'ZAF',
        alpha2: 'ZA',
        name: 'South Africa',
        eu: false,
    },
    {
        code: 'SGS',
        alpha2: 'GS',
        name: 'South Georgia and the South Sandwich Islands',
        eu: false,
    },
    {
        code: 'SSD',
        alpha2: 'SS',
        name: 'South Sudan',
        eu: false,
    },
    {
        code: 'ESP',
        alpha2: 'ES',
        name: 'Spain',
        eu: true,
    },
    {
        code: 'LKA',
        alpha2: 'LK',
        name: 'Sri Lanka',
        eu: false,
    },
    {
        code: 'SDN',
        alpha2: 'SD',
        name: 'Sudan',
        eu: false,
    },
    {
        code: 'SUR',
        alpha2: 'SR',
        name: 'Suriname',
        eu: false,
    },
    {
        code: 'SJM',
        alpha2: 'SJ',
        name: 'Svalbard and Jan Mayen',
        eu: false,
    },
    {
        code: 'SWZ',
        alpha2: 'SZ',
        name: 'Eswatini',
        eu: false,
    },
    {
        code: 'SWE',
        alpha2: 'SE',
        name: 'Sweden',
        eu: true,
    },
    {
        code: 'CHE',
        alpha2: 'CH',
        name: 'Switzerland',
        eu: false,
    },
    {
        code: 'SYR',
        alpha2: 'SY',
        name: 'Syrian Arab Republic',
        eu: false,
    },
    {
        code: 'TWN',
        alpha2: 'TW',
        name: 'Taiwan',
        eu: false,
    },
    {
        code: 'TJK',
        alpha2: 'TJ',
        name: 'Tajikistan',
        eu: false,
    },
    {
        code: 'TZA',
        alpha2: 'TZ',
        name: 'Tanzania, United Republic of',
        eu: false,
    },
    {
        code: 'THA',
        alpha2: 'TH',
        name: 'Thailand',
        eu: false,
    },
    {
        code: 'TLS',
        alpha2: 'TL',
        name: 'Timor-Leste',
        eu: false,
    },
    {
        code: 'TGO',
        alpha2: 'TG',
        name: 'Togo',
        eu: false,
    },
    {
        code: 'TKL',
        alpha2: 'TK',
        name: 'Tokelau',
        eu: false,
    },
    {
        code: 'TON',
        alpha2: 'TO',
        name: 'Tonga',
        eu: false,
    },
    {
        code: 'TTO',
        alpha2: 'TT',
        name: 'Trinidad and Tobago',
        eu: false,
    },
    {
        code: 'TUN',
        alpha2: 'TN',
        name: 'Tunisia',
        eu: false,
    },
    {
        code: 'TUR',
        alpha2: 'TR',
        name: 'Turkey',
        eu: false,
    },
    {
        code: 'TKM',
        alpha2: 'TM',
        name: 'Turkmenistan',
        eu: false,
    },
    {
        code: 'TCA',
        alpha2: 'TC',
        name: 'Turks and Caicos Islands',
        eu: false,
    },
    {
        code: 'TUV',
        alpha2: 'TV',
        name: 'Tuvalu',
        eu: false,
    },
    {
        code: 'UGA',
        alpha2: 'UG',
        name: 'Uganda',
        eu: false,
    },
    {
        code: 'UKR',
        alpha2: 'UA',
        name: 'Ukraine',
        eu: false,
    },
    {
        code: 'ARE',
        alpha2: 'AE',
        name: 'United Arab Emirates',
        eu: false,
    },
    {
        code: 'GBR',
        alpha2: 'GB',
        name: 'United Kingdom',
        eu: false,
    },
    {
        code: 'USA',
        alpha2: 'US',
        name: 'United States',
        eu: false,
    },
    {
        code: 'UMI',
        alpha2: 'UM',
        name: 'United States Minor Outlying Islands',
        eu: false,
    },
    {
        code: 'URY',
        alpha2: 'UY',
        name: 'Uruguay',
        eu: false,
    },
    {
        code: 'UZB',
        alpha2: 'UZ',
        name: 'Uzbekistan',
        eu: false,
    },
    {
        code: 'VUT',
        alpha2: 'VU',
        name: 'Vanuatu',
        eu: false,
    },
    {
        code: 'VEN',
        alpha2: 'VE',
        name: 'Venezuela, Bolivarian Republic of',
        eu: false,
    },
    {
        code: 'VNM',
        alpha2: 'VN',
        name: 'Viet Nam',
        eu: false,
    },
    {
        code: 'VGB',
        alpha2: 'VG',
        name: 'Virgin Islands, British',
        eu: false,
    },
    {
        code: 'VIR',
        alpha2: 'VI',
        name: 'Virgin Islands, U.S.',
        eu: false,
    },
    {
        code: 'WLF',
        alpha2: 'WF',
        name: 'Wallis and Futuna',
        eu: false,
    },
    {
        code: 'ESH',
        alpha2: 'EH',
        name: 'Western Sahara',
        eu: false,
    },
    {
        code: 'YEM',
        alpha2: 'YE',
        name: 'Yemen',
        eu: false,
    },
    {
        code: 'ZMB',
        alpha2: 'ZM',
        name: 'Zambia',
        eu: false,
    },
    {
        code: 'ZWE',
        alpha2: 'ZW',
        name: 'Zimbabwe',
        eu: false,
    },
]

export const mexicanStates = [
    { label: 'Aguascalientes', value: 'Aguascalientes' },
    { label: 'Baja California', value: 'Baja California' },
    { label: 'Baja California Sur', value: 'Baja California Sur' },
    { label: 'Campeche', value: 'Campeche' },
    { label: 'Chiapas', value: 'Chiapas' },
    { label: 'Chihuahua', value: 'Chihuahua' },
    { label: 'Coahuila', value: 'Coahuila' },
    { label: 'Colima', value: 'Colima' },
    { label: 'Ciudad de México', value: 'Ciudad de México' },
    { label: 'Durango', value: 'Durango' },
    { label: 'Guanajuato', value: 'Guanajuato' },
    { label: 'Guerrero', value: 'Guerrero' },
    { label: 'Hidalgo', value: 'Hidalgo' },
    { label: 'Jalisco', value: 'Jalisco' },
    { label: 'México', value: 'México' },
    { label: 'Michoacán', value: 'Michoacán' },
    { label: 'Morelos', value: 'Morelos' },
    { label: 'Nayarit', value: 'Nayarit' },
    { label: 'Nuevo León', value: 'Nuevo León' },
    { label: 'Oaxaca', value: 'Oaxaca' },
    { label: 'Puebla', value: 'Puebla' },
    { label: 'Querétaro', value: 'Querétaro' },
    { label: 'Quintana Roo', value: 'Quintana Roo' },
    { label: 'San Luis Potosí', value: 'San Luis Potosí' },
    { label: 'Sinaloa', value: 'Sinaloa' },
    { label: 'Sonora', value: 'Sonora' },
    { label: 'Tabasco', value: 'Tabasco' },
    { label: 'Tamaulipas', value: 'Tamaulipas' },
    { label: 'Tlaxcala', value: 'Tlaxcala' },
    { label: 'Veracruz', value: 'Veracruz' },
    { label: 'Yucatán', value: 'Yucatán' },
    { label: 'Zacatecas', value: 'Zacatecas' },
]
