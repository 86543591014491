import { Checkbox, Divider, Form, Slider, Space, Table, Tabs, Typography, message } from 'antd'
import SwitchHolder from './SwitchHolder'
import { useDispatch, useSelector } from 'react-redux'
import { ShootingStar } from '@phosphor-icons/react'
import { setUser } from '../../context/userSlice'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { ChipsSelect, SelectInput, customTagRender } from './Inputs'
import { tagsColors } from './TagsColors'
import { useState } from 'react'
import { InvoicesColumns } from '../Tables/Columns/InvoicesColumns'
import { fakeInvoiceData } from '../../datasets/datadummy'
import { invoiceDefSet } from '../../../interfaces/invoicesDef'
import { setData } from '../../context/dataSlice'

const InvoicesColumnsSettings = () => {
    const tealColor = tagsColors.find((color: any) => color.name === 'teal')

    const [columns, setColumns] = useState<string[]>([])

    const availableColumns = [
        { label: 'Cliente', value: 'clientInfo' },
        { label: 'Estado', value: 'status' },
        { label: 'Monto', value: 'amount' },
        { label: 'Fecha', value: 'timestamp' },
        { label: 'Automatizaciones', value: 'automations' },
        { label: 'Relaciones', value: 'relations' },
        { label: 'Acciones', value: 'actions' },
    ]

    return (
        <div>
            <ChipsSelect
                name="invoicesColumns"
                change={(v: any) => {
                    setColumns(() => v)
                }}
                options={availableColumns}
                customTag={(props: any) =>
                    customTagRender({ ...props, color: { bg: tealColor?.bg, text: tealColor?.text } })
                }
            />

            <Table
                columns={[
                    {
                        dataIndex: 'title',
                        title: 'Columna',
                        width: '18%',
                        render: (text: string) => (
                            <Typography.Text className="mediumparagraphbold">{text}</Typography.Text>
                        ),
                    },
                    {
                        dataIndex: 'example',
                        title: 'Previsualización',
                        width: '30%',
                        render: (text: string, record: any) => {
                            return InvoicesColumns({ record: invoiceDefSet(fakeInvoiceData), columnName: record.key })
                        },
                    },
                    {
                        dataIndex: 'width',
                        title: 'Ancho',
                        width: '36%',
                        render: (text: string, record: any) => {
                            return <Slider min={50} max={500} style={{ color: '#6B7CF9' }} />
                        },
                    },
                    {
                        dataIndex: 'fixed',
                        title: 'Fijo',
                        width: '18%',
                        render: (text: string, record: any) => {
                            return (
                                <Space>
                                    <Checkbox>Der</Checkbox>
                                    <Checkbox>Izq</Checkbox>
                                </Space>
                            )
                        },
                    },
                ]}
                dataSource={columns.map((column: string) => {
                    return {
                        key: column,
                        title: availableColumns.find((c: any) => c.value === column)?.label,
                    }
                })}
                pagination={false}
            />
        </div>
    )
}

const PaymentsColumnsSettings = () => {
    return <div></div>
}

const ReceiptsColumnsSettings = () => {
    return <div></div>
}

const ClientsColumnsSettings = () => {
    return <div></div>
}

const ProductsColumnsSettings = () => {
    return <div></div>
}

export const InterfaceSettings = () => {
    const dispatch = useDispatch()
    const fs = getFirestore()

    const { user } = useSelector((state: any) => state.user)
    const { team } = useSelector((state: any) => state.team)

    return (
        <Form layout="vertical">
            <SwitchHolder
                changeState={async (v: any) => {
                    try {
                        dispatch(
                            setUser({
                                ...user,
                                interfaceSettings: {
                                    ...(user?.interfaceSettings ?? {}),
                                    [team?.id]: { ...(user?.interfaceSettings ?? {})[team?.id], testmode: v },
                                },
                            }),
                        )
                        dispatch(
                            setData({
                                item: 'testmode',
                                data: v,
                            }),
                        )
                        await updateDoc(doc(getFirestore(), 'users', user?.uid), {
                            [`interfaceSettings.${team?.id}.testmode`]: v,
                        })

                        message.success('Información actualizada correctamente')
                    } catch (error: any) {
                        message.error(error?.message ?? 'Ocurrió un error al actualizar la información.')
                    }
                }}
                switchState={(user?.interfaceSettings ?? {})[team?.id]?.testmode}
                icon={<ShootingStar size={20} className="icon" weight="regular" />}
                title={'Activar modo prueba'}
                description="Mantendremos este modo como predeterminado durante toda tu sesión"
            />
            <Divider />

            <SelectInput
                name="searchCollection"
                label="Colección por defecto en búsquedas"
                options={[
                    { label: 'Pagos', value: 'payments' },
                    { label: 'Facturas', value: 'invoices' },
                    { label: 'Recibos de venta', value: 'receipts' },
                ]}
                defaultValue={(user?.interfaceSettings ?? {})?.[team?.id]?.searchCollection}
                description="Utilizaremos esta catergoría para mostrar los primeros resultados de una búsqueda"
                onChange={async (v: any) => {
                    try {
                        dispatch(
                            setUser({
                                ...user,
                                interfaceSettings: {
                                    ...(user?.interfaceSettings ?? {}),
                                    [team?.id]: { ...(user?.interfaceSettings ?? {})[team?.id], searchCollection: v },
                                },
                            }),
                        )
                        await updateDoc(doc(fs, 'users', user?.uid), {
                            [`interfaceSettings.${team?.id}.searchCollection`]: v,
                        })
                        message.success('Información actualizada correctamente')
                    } catch (error: any) {
                        message.error(error?.message ?? 'Ocurrió un error al actualizar la información.')
                    }
                }}
            />

            <div style={{ marginTop: '50px' }}>
                <div className="d-flex flex-column" style={{ marginBottom: '10px' }}>
                    <Typography.Text className="mediumparagraphbold">Columnas de tablas</Typography.Text>
                    <Typography.Text className="smallparagraph descriptions">
                        Selecciona las columnas que deseas visualizar en las tablas
                    </Typography.Text>
                </div>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        { label: 'Facturas', key: '1', children: <InvoicesColumnsSettings /> },
                        { label: 'Pagos', key: '2', children: <PaymentsColumnsSettings /> },
                        { label: 'Recibos de venta', key: '3', children: <ReceiptsColumnsSettings /> },
                        { label: 'Clientes', key: '4', children: <ClientsColumnsSettings /> },
                        { label: 'Productos', key: '5', children: <ProductsColumnsSettings /> },
                    ]}
                />
            </div>
        </Form>
    )
}
