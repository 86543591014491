import { Avatar, Col, Row, Tag, Typography } from 'antd'
import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts'

import { generateCode, returnCurrencyValue } from '../../functions/helpers'
import { ArrowArcLeft, MoneyWavy, Percent, QuestionMark, TrendUp } from '@phosphor-icons/react'
import moment from 'moment'
import { useSelector } from 'react-redux'
const formateer = new Intl.NumberFormat('es-MX', {
    style: 'decimal',
    currency: 'MXN',
    minimumFractionDigits: 0,
})
export const PaymentsAreaChart = ({
    pastChartData,
    chartData,
    title = 'Pagos',
}: {
    pastChartData: any
    chartData: any
    title: string
}) => {
    const areas = [
        <Area
            type="monotone"
            dataKey="payments"
            stackId="1"
            strokeWidth={1.5}
            display="Monto cobrado"
            stroke="#b4e3d1"
            fillOpacity={1}
            fill="url(#Pagoscompletos)"
            key={'payments1'}
        />,
        <Area
            type="monotone"
            dataKey="paymentsNotSucceeded"
            display="Monto pendiente"
            activeDot={{ r: 1 }}
            stackId="2"
            strokeWidth={1.5}
            stroke="#ffdd9f"
            fillOpacity={1}
            fill="url(#Pagospendientes)"
            key={'payments2'}
        />,
    ]
    const colors = ['#b4e3d1', '#ffdd9f']
    const color1 = colors[0]
    const color2 = colors[1]

    const CustomTooltip = ({ active, payload, label }: { active: any; payload: any; label: any }) => {
        if (active && payload && payload.length) {
            return (
                <div
                    className="d-flex flex-column"
                    style={{
                        backgroundColor: 'white',
                        padding: '10px',
                        zIndex: 1000,
                        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                        borderRadius: '5px',
                        minWidth: 'auto',
                        position: 'relative',
                    }}
                >
                    <Typography.Text className="smallparagraph descriptions" style={{ marginBottom: '5px' }}>
                        {payload[0].payload?.name}
                    </Typography.Text>
                    {payload.map((p: any) => {
                        return (
                            <Row key={generateCode(5)} style={{ marginBottom: '5px' }}>
                                <Typography.Text className="smallparagraph descriptions" style={{ marginRight: '5px' }}>
                                    {p.display}
                                </Typography.Text>
                                <Tag>{returnCurrencyValue(p.value)}</Tag>
                            </Row>
                        )
                    })}
                </div>
                // <div className="custom-tooltip">
                //     <p className="label">{`${label} : ${payload[0].value}`}</p>
                //     <p className="intro">{getIntroOfPage(label)}</p>
                //     <p className="desc">Anything you want can be displayed here.</p>
                // </div>
            )
        }

        return null
    }
    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={chartData} stackOffset="none">
                <defs>
                    <linearGradient
                        id={`Pagoscompletos`}
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                        // color={color1}
                    >
                        <stop offset="5%" color={color1} stopColor={color1} stopOpacity={0.8} />
                        <stop offset="95%" color={color1} stopColor={color1} stopOpacity={0} />
                    </linearGradient>
                    <linearGradient
                        id={`Pagospendientes`}
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                        // color={color2}
                    >
                        <stop offset="5%" color={color2} stopColor={color2} stopOpacity={0.8} />
                        <stop offset="95%" color={color2} stopColor={color2} stopOpacity={0} />
                    </linearGradient>
                </defs>

                <Tooltip
                    content={({ active, payload, label }) => (
                        <CustomTooltip active={active} payload={payload} label={label} />
                    )}
                />
                {/* <XAxis dataKey="name"
                padding="no-gap"
                tick={
                    ({ x, y, stroke, payload }) => {
                        return <g transform={`translate(${x},${y})`} fill='#cecece'>
                            <text x={2} y={4} dy={2} textAnchor="start" fontSize={"8px"} fill="#f7f7f7" transform="rotate(0)">
                                {payload.value}
                            </text>
                        </g>
                    }
                } /> */}
                {areas.map((area, index) => {
                    return area
                })}
            </AreaChart>
        </ResponsiveContainer>
    )
}

export const FullChartPayments = ({
    data,
    pastPeriod,
    refElement,
    payments,
}: {
    data: any
    refElement: React.MutableRefObject<null>
    pastPeriod: any
    payments: any
}) => {
    const { period } = useSelector((state: any) => state.data)
    return (
        <Col xs={24}>
            <div
                ref={refElement}
                style={{
                    backgroundColor: '#F8F8F8',
                    padding: '20px',
                    borderRadius: '8px',
                    marginTop: '10px',
                    height: '240px',
                }}
                className="d-flex flex-column"
            >
                <Row justify="space-between">
                    <Row align="middle" style={{ marginTop: '4px' }}>
                        <Avatar
                            style={{ backgroundColor: 'white', marginRight: '10px' }}
                            icon={<TrendUp weight="regular" color="#334055" />}
                        />
                        <div className="d-flex flex-column">
                            <Typography.Text className="smallheader">Pagos del periodo</Typography.Text>
                            <Typography.Text className="smallparagraph descriptions" style={{ fontSize: '10px' }}>
                                {moment(period?.start).format('DD MMM YYYY')} -{' '}
                                {moment(period?.end).format('DD MMM YYYY')}
                            </Typography.Text>
                        </div>
                    </Row>
                    <Typography.Text className="smallheader">
                        {returnCurrencyValue(payments?.current?.succeeded?.amount)}
                    </Typography.Text>
                </Row>
                <div style={{ height: '150px' }}>
                    <PaymentsAreaChart pastChartData={pastPeriod} chartData={data} title="Pagos" />
                </div>
                <Row justify="space-between" style={{ marginTop: '5px' }}>
                    {/* DIVIDE THE AMOUNT OF DATES IN 3 DATES 
                    dates
                    */}
                    <Typography.Text
                        className="smallparagraph descriptions"
                        style={{ borderLeft: '1px solid #cecece', paddingLeft: '5px', fontSize: '10px' }}
                    >
                        {moment(period?.start).format('DD MMM')}
                    </Typography.Text>
                    <Typography.Text className="smallparagraph descriptions" style={{ fontSize: '10px' }}>
                        {
                            // moment(period?.start).add(moment(period.start).diff(period.start, 'days') / 3, 'days').format('DD MMM')
                            moment(period?.start)
                                .add(moment(period.end).diff(moment(period.start), 'days') / 3, 'days')
                                .format('DD MMM')
                        }
                    </Typography.Text>
                    <Typography.Text
                        className="smallparagraph descriptions"
                        style={{ borderRight: '1px solid #cecece', paddingRight: '5px', fontSize: '10px' }}
                    >
                        {moment(period?.end).format('DD MMM')}
                    </Typography.Text>
                </Row>
            </div>
        </Col>
    )
}

export const PaymentsCard = ({
    data,
    refElement,
    payments,
}: {
    data: any
    refElement: React.MutableRefObject<null>
    payments: any
}) => {
    return (
        <Col xs={12} lg={8}>
            <div
                ref={refElement}
                style={{
                    backgroundColor: '#F8F8F8',
                    padding: '20px',
                    borderRadius: '8px',
                    minHeight: '160px',
                    marginBottom: '10px',
                }}
                className="d-flex flex-column"
            >
                <Avatar style={{ backgroundColor: 'white' }} icon={<MoneyWavy weight="regular" color="#334055" />} />
                <Typography.Text className="smallheader" style={{ marginTop: '4px' }}>
                    {
                        formateer.format(payments?.current?.succeeded?.total)
                        // returnCurrencyValue(payments?.current?.succeeded)
                    }
                </Typography.Text>

                <Typography.Text className="smallparagraph descriptions">pagos completos del periodo</Typography.Text>
            </div>
        </Col>
    )
}

export const PeriodGrowthCard = ({
    data,
    refElement,
    payments,
}: {
    data: any
    refElement: React.MutableRefObject<null>
    payments: any
}) => {
    const pastPeriod = payments?.past?.succeeded?.amount ?? 0
    const currentPeriod = payments?.current?.succeeded?.amount ?? 0
    const growthRate = (((currentPeriod - pastPeriod) / pastPeriod) * 100).toFixed(2)

    return (
        <Col xs={24} lg={8}>
            <div
                ref={refElement}
                style={{
                    backgroundColor: '#F8F8F8',
                    padding: '20px',
                    borderRadius: '8px',
                    minHeight: '160px',
                    marginBottom: '5px',
                }}
                className="d-flex flex-column"
            >
                <Avatar style={{ backgroundColor: 'white' }} icon={<Percent weight="regular" color="#334055" />} />
                <Typography.Text className="smallheader" style={{ marginTop: '4px' }}>
                    {growthRate}%
                </Typography.Text>
                <Typography.Text className="smallparagraph descriptions">
                    Revenue growth rate del periodo
                    <QuestionMark />
                </Typography.Text>
            </div>
        </Col>
    )
}

export const RefundsCard = ({ payments }: { payments: any }) => {
    if (payments?.current?.refunded?.total <= 0) return <></>
    return (
        <Col xs={12} lg={24}>
            <div
                // ref={refElement}
                style={{
                    backgroundColor: '#F8F8F8',
                    padding: '20px',
                    borderRadius: '8px',
                    marginBottom: '10px',
                }}
                className="d-flex flex-row"
            >
                <Avatar style={{ backgroundColor: 'white' }} icon={<ArrowArcLeft weight="regular" color="#334055" />} />
                <div className="d-flex flex-column" style={{ marginLeft: '15px' }}>
                    <Row>
                        <Typography.Text className="smallheader" style={{ marginTop: '4px' }}>
                            {
                                //
                                returnCurrencyValue(payments?.current?.refunded?.amount)
                            }
                        </Typography.Text>
                        <div style={{ marginLeft: '5px' }}>
                            <Tag>{formateer.format(payments?.current?.refunded?.total)}</Tag>
                        </div>
                    </Row>

                    <Typography.Text className="smallparagraph descriptions">
                        pagos reembolsados del periodo
                    </Typography.Text>
                </div>
            </div>
        </Col>
    )
}
