import { useEffect, useRef, useState } from 'react'
import { Button, Input, InputRef, Space, Table, Tooltip, Typography } from 'antd'
import {
    query,
    collection,
    orderBy,
    getFirestore,
    where,
    limit,
    startAfter,
    getCountFromServer,
    getDocs,
} from 'firebase/firestore'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestoreCollectionData } from 'reactfire'
import { OptimizedPaymentDef, Payment, optimizedPaymentDefSet } from '../../../interfaces/paymentsDef'
import { PaymentColumnsObject } from './ColumnsSettlers'
import { useLocation, useNavigate } from 'react-router-dom'
import { FilterConfirmProps } from 'antd/lib/table/interface'
import { MagnifyingGlass, X } from '@phosphor-icons/react'
import { ColumnType } from 'antd/es/table'
import { PaymentsColumns } from './Columns/PaymentsColumns'
import { SearchParamInURL } from '../../functions/helpers'
import { setData } from '../../context/dataSlice'

const PaymentsTable = ({
    max: incomingMax = 10,
    search,
    startPeriod,
    endPeriod,
}: {
    max: number
    search?: any
    startPeriod?: number | null
    endPeriod?: number | null
}) => {
    const ps = SearchParamInURL('ps') ?? 10
    const p = SearchParamInURL('page') ?? 1

    const navigate = useNavigate()
    const location = useLocation()

    //CONTEXTS
    const dispatch = useDispatch()
    const { selectedPayments } = useSelector((state: any) => state.data)
    const { testmode } = useSelector((state: any) => state.data)
    const { team } = useSelector((state: any) => state.team)

    //STATES
    const [newLoading, setNewLoading] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    const [pageSize, setPageSize] = useState(ps ?? 10)
    const [currentPage, setCurrentPage] = useState(p ?? 1)
    const [items, setItems] = useState<(typeof def)[]>([])
    const [activatePages, setActivatePages] = useState(true)
    const searchInput = useRef<InputRef>(null)

    const source = 'payments'
    let def: OptimizedPaymentDef | Payment
    let defSet = (data: any) => {
        return optimizedPaymentDefSet(data)
    }

    //IMPORTS
    const fs = getFirestore()

    // HOOKS
    // const navigate = useNavigate()

    let max = incomingMax

    if (p !== 1) {
        max = Number(ps) * Number(p)
    }

    let resetTrigger = false

    type DataIndex = keyof typeof def

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        setActivatePages(resetTrigger || !selectedKeys[0] ? true : false)
        confirm()

        resetTrigger = false
    }

    const handleReset = (clearFilters: () => void) => {
        resetTrigger = true
        // setSearchText('')
        clearFilters()
    }

    //VARIABLES
    let initialQuery =
        startPeriod && endPeriod
            ? query(
                  collection(fs, source),
                  where('team', '==', team?.id ?? ''),
                  where('timestamp', '>=', startPeriod),
                  where('timestamp', '<=', endPeriod),
                  where('livemode', '==', !testmode),
                  orderBy('timestamp', 'desc'),
                  limit(max),
              )
            : query(
                  collection(fs, source),
                  where('team', '==', team?.id ?? ''),
                  orderBy('timestamp', 'desc'),
                  where('livemode', '==', !testmode),
                  limit(max),
              )
    let totalCountQuery =
        startPeriod && endPeriod
            ? query(
                  collection(fs, source),
                  where('team', '==', team?.id ?? ''),
                  where('timestamp', '>=', startPeriod),
                  where('timestamp', '<=', endPeriod),
                  orderBy('timestamp', 'desc'),
                  where('livemode', '==', !testmode),
              )
            : query(
                  collection(fs, source),
                  where('team', '==', team?.id ?? ''),
                  where('livemode', '==', !testmode),
                  orderBy('timestamp', 'desc'),
              )

    //DATA
    const { status: initialLoadStatus, data } = useFirestoreCollectionData(initialQuery, {
        idField: 'fid',
    })

    //FUNCTIONS
    const getInitialCount = async () => {
        setTotalCount((await getCountFromServer(totalCountQuery)).data().count)
    }

    const getNewData = async ({ lastItem: newItem, ps, p }: { lastItem: number; ps: number; p: number }) => {
        if (ps * p < items.length) return
        setNewLoading(true)
        const newDataQuery =
            startPeriod && endPeriod
                ? query(
                      collection(fs, source),
                      where('team', '==', team?.id ?? ''),
                      where('timestamp', '>=', startPeriod),
                      where('timestamp', '<=', endPeriod),
                      orderBy('timestamp', 'desc'),
                      limit(ps * p - items.length + 1),
                      where('livemode', '==', !testmode),
                      startAfter(newItem),
                  )
                : query(
                      collection(fs, source),
                      where('team', '==', team?.id ?? ''),
                      orderBy('timestamp', 'desc'),
                      limit(ps * p - items.length + 1),
                      where('livemode', '==', !testmode),
                      startAfter(newItem),
                  )
        let newItems = await getDocs(newDataQuery)
        setItems([...items, ...(newItems.docs.map((c) => defSet(c.data())) ?? [])])
        setNewLoading(false)
    }

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: OptimizedPaymentDef[]) => {
            dispatch(
                setData({
                    item: 'selectedPayments',
                    data: selectedRows,
                }),
            )
        },

        getCheckboxProps: (record: OptimizedPaymentDef) => ({
            disabled: !record.id, // Column configuration not to be checked
            name: record.id,
        }),
    }

    //EFFECTS
    useEffect(() => {
        getInitialCount()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, testmode, team?.id])

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<typeof def> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} className="d-flex flex-column" onKeyDown={(e) => e.stopPropagation()}>
                <Tooltip
                    title={`Solo buscará en los pagos que ya se hayan cargado en la lista (${
                        items.length + max
                    } de ${totalCount}).`}
                >
                    <Typography.Text className="smallparagraph descriptions" style={{ marginBottom: '5px' }}>
                        Búsqueda local
                    </Typography.Text>
                </Tooltip>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Typography.Text
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch(selectedKeys as string[], confirm, dataIndex)
                        }}
                        className="smallparagraph descriptions clickable"
                    >
                        Restablecer
                    </Typography.Text>

                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<MagnifyingGlass />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>

                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close()
                        }}
                    >
                        <X className="icon" />
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => {
            return (
                <MagnifyingGlass
                    style={{
                        color: filtered ? '#8666FF' : '#50566B',
                    }}
                    size={16}
                />
            )
        },
        onFilter: (value, record) => {
            return (
                (record[dataIndex] ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                (record.id ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                (record.fid ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                (record.client?.name ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                (record.client?.legalName ?? record.client?.legal_name ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                (record.client?.id ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                (record.client?.rfc ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                (record.client?.tax_id ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                (record.metadata?.internalId ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                (record.client?.email ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                (record.client?.phone ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                (record.client?.company ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                (record.metadata?.orderId ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase())
            )
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
            }
        },
        render: (text, record) =>
            PaymentsColumns({
                record,
                columnName: 'clientInfo',
                navigate: () => {
                    dispatch(setData({ item: 'anchorLocation', data: `${location.pathname}${location.search}` }))
                    navigate(`/paymentDetails?id=${record.fid}&page=${currentPage}&ps=${pageSize}&pn=payments`)
                },
            }),
    })

    return (
        <Table
            scroll={{ x: 1500 }}
            rowKey={(rec: any) => rec?.id ?? 'as'}
            loading={initialLoadStatus === 'loading' || newLoading}
            pagination={
                activatePages
                    ? {
                          total: totalCount,
                          defaultPageSize: Number(pageSize),
                          defaultCurrent: Number(currentPage),
                          pageSize: Number(pageSize),
                          current: Number(currentPage),
                          onChange(p, ps) {
                              setPageSize(ps)
                              setCurrentPage(p)
                              let allItems = [...data, ...items]
                              getNewData({
                                  lastItem: allItems[allItems.length - 1]?.timestamp,
                                  ps,
                                  p,
                              })
                              const path = `/payments?page=${p}&ps=${ps}`
                              navigate(path)
                          },
                      }
                    : {
                          defaultPageSize: Number(pageSize),
                          pageSize: Number(pageSize),
                      }
            }
            columns={PaymentColumnsObject({
                customKeysToShow: null,
                navigate: (p: string) => navigate(p),
            }).map((c) => {
                let column = c
                if (c.dataIndex === 'clientInfo') {
                    column = {
                        ...column,
                        ...getColumnSearchProps('clientName'),
                    }
                }
                if (c.dataIndex === 'status') {
                    column = {
                        ...column,
                        filterMode: 'tree',
                        filterSearch: true,
                        filters: [
                            {
                                text: 'Pagado',
                                value: 'succeeded',
                            },
                            {
                                text: 'Por revisar',
                                value: 'review_requested',
                            },
                            {
                                text: 'Pendiente',
                                value: 'requires_payment_method',
                            },
                            {
                                text: 'Acción Requerida',
                                value: 'requires_action',
                            },
                            {
                                text: 'Pagado parcialmente',
                                value: 'partially_paid',
                            },
                        ],
                        onFilter: (value: string, record: any) => record.status.includes(value),
                    }
                }
                return column
            })}
            dataSource={[...(data ?? [])?.map((d) => defSet(d)), ...(items ?? []).map((d) => defSet(d))]}
            rowSelection={{
                type: 'checkbox',
                selectedRowKeys: selectedPayments && [...selectedPayments.map((c: OptimizedPaymentDef) => c.id)],
                ...rowSelection,
            }}
        />
    )
}

export default PaymentsTable
