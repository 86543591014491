import React, { useState } from 'react'
import { FormInstance, Col, Row, Form, Button, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { SelectInput, TextInput } from './Inputs'
import moment from 'moment'
import { useAuth } from 'reactfire'
import { SignedInternalAPIRequest } from '../../functions/APIRequest'
import { closeModal } from '../../context/modalsSlice'

interface PropsDef {
    incomingForm?: FormInstance
    buttonTitle?: string
}

const InviteNewMemberForm: React.FC<PropsDef> = ({ incomingForm, buttonTitle }) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const usingForm = incomingForm ?? form
    const [messageApi, contextHolder] = message.useMessage()
    const { team } = useSelector((state: any) => state.team)
    const { user } = useSelector((state: any) => state.user)
    const auth = useAuth()

    const [loading, setLoading] = useState(false)

    const onFinish = async (values: any) => {
        try {
            setLoading(true)
            await usingForm.validateFields()
            messageApi.open({
                type: 'loading',
                content: 'Actualizando información...',
                duration: 0,
            })
            const inviteObj = {
                email: values.email,
                rol: values.rol,
                status: 'pending',
                teamId: team?.id,
                billingId: team?.billingAccount,
                expiring: moment().add(15, 'days').valueOf(),
                accepted: false,
                teamName: team?.brand?.alias,
                emailOwner: user?.email,
                billingName: null,
                type: 'team',
            }
            const res = await SignedInternalAPIRequest(inviteObj, 'handleSeats/v1/create', auth.currentUser)
            message.success(res.message)

            dispatch(closeModal('settingsDrawerVisible'))
            setLoading(false)
            usingForm.resetFields()
        } catch (e: any) {
            message.error(e?.message ?? 'Ocurrió un error')
            setLoading(false)
        }
    }
    // const handleInviteMember = async (v) => {
    //     try {
    //         setLoading(true)
    //         const inviteObj = {
    //             email: v.email,
    //             rol: inviteType === 'team' ? v.rol.value : 'admin',
    //             status: "pending",
    //             teamId: inviteType === 'team' ? team?.id : null,
    //             billingId: billingAccount.id,
    //             expiring: moment().add(7, 'day').valueOf(),
    //             accepted: false,
    //             teamName: team?.alias ?? null,
    //             emailOwner: user?.email ?? auth?.currentUser?.email ?? '',
    //             billingName: inviteType === 'billingAccount' ? billingAccount?.legalName : null,
    //             type: inviteType
    //         }
    //         const res = await SignedInternalAPIRequest(inviteObj, 'handleSeats/v1/create', auth.currentUser)
    //         message.success(res.message)
    //         setLoading(false)
    //         formRef.resetFields()
    //         handleCloseInviteModal()
    //     } catch (error) {
    //         message.error(error.message)
    //         setLoading(false)
    //     }
    // }
    return (
        <Form layout="vertical" form={usingForm} onFinish={onFinish}>
            {/* Form content goes here */}
            {contextHolder}
            <Row>
                <Col xs={24}>
                    <TextInput
                        name="email"
                        label="Correo electrónico"
                        placeholder="Correo electrónico"
                        rules={[
                            {
                                required: true,
                                message: 'Ingresa un correo electrónico',
                            },
                            {
                                type: 'email',
                                message: 'Ingresa un correo electrónico válido',
                            },
                        ]}
                    />
                </Col>
                <Col xs={24}>
                    <SelectInput
                        options={[
                            { label: 'Editor', value: 'editor' },
                            { label: 'Visor', value: 'viewer' },
                            { label: 'Administrador', value: 'admin' },
                        ]}
                        name="rol"
                        label="Rol"
                        placeholder="Rol"
                        rules={[
                            {
                                required: true,
                                message: 'Selecciona un rol',
                            },
                        ]}
                    />
                </Col>
                <Col span={24}>
                    <Row justify="end">
                        <Button type="primary" htmlType="submit" loading={loading}>
                            {buttonTitle ?? 'Enviar invitación'}
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Form>
    )
}

export default InviteNewMemberForm
