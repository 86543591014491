import { CaretCircleLeft, CaretCircleRight, X } from '@phosphor-icons/react'
import { Button, Checkbox, Row, Tag, Typography, message } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setData } from '../../context/dataSlice'
import _ from 'lodash'
import { returnCurrencyValue } from '../../functions/helpers'
import { arrayUnion, doc, getFirestore, updateDoc } from 'firebase/firestore'

export const CheckIfRelating = ({ element, collection }: { element: any; collection: string }) => {
    const { relating } = useSelector((state: any) => state.data)
    const dispatch = useDispatch()

    if (!relating) return <></>
    if (relating?.element?.id === element?.id)
        return (
            <Tag color="purple" className="clickable">
                Relacionando elementos a este recurso
            </Tag>
        )
    if (relating?.elementsToRelate?.find((el: any) => el.id === element.id && el.collection === collection)) {
        return (
            <Tag
                color="green"
                className="clickable"
                closable
                onClose={() => {
                    dispatch(
                        setData({
                            item: 'relating',
                            data: {
                                ...relating,
                                elementsToRelate: (relating?.elementsToRelate ?? [])?.filter((el: any) => {
                                    if (el.id !== element.id || el.collection !== collection) {
                                        return el
                                    } else {
                                        return null
                                    }
                                }),
                            },
                        }),
                    )
                }}
            >
                Relacionado
            </Tag>
        )
    } else {
        return (
            <Tag
                color="blue"
                className="clickable"
                onClick={() => {
                    dispatch(
                        setData({
                            item: 'relating',
                            data: {
                                ...relating,
                                elementsToRelate: [
                                    ...(relating.elementsToRelate ?? []),
                                    {
                                        id: element.id,
                                        collection,
                                        ...element,
                                    },
                                ],
                            },
                        }),
                    )
                }}
            >
                Agregar relación
            </Tag>
        )
    }
}

const RelatingElementsSignal: React.FC = () => {
    const { relating } = useSelector((state: any) => state.data)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const { element, collection } = relating ?? {}
    const elements = relating?.elementsToRelate ?? []

    // Group elements by collection
    const collections = _.groupBy(elements, 'collection')

    const [hidden, setHidden] = useState(false)

    const ref = React.createRef<HTMLDivElement>()

    const collectionsReadable = [
        {
            key: 'invoices',
            value: 'Facturas',
        },
        {
            key: 'receipts',
            value: 'Recibos',
        },
        {
            key: 'payments',
            value: 'Pagos',
        },
        {
            key: 'clients',
            value: 'Clientes',
        },
        {
            key: 'products',
            value: 'Productos',
        },
    ]

    const MainElement = () => {
        const el = element
        if (!el) return <></>
        return (
            <div
                className="d-flex flex-column"
                style={{
                    backgroundColor: '#F8FAFC',
                    padding: '10px',
                    marginTop: '10px',
                    borderRadius: '5px',
                }}
            >
                <Row align="middle" justify="space-between">
                    <div className="d-flex flex-column">
                        <Typography.Text className="smallparagraphbold" style={{}}>
                            {el.client?.name ?? el.client?.legal_name ?? el.customer?.name}
                        </Typography.Text>
                        <Typography.Text
                            className="smallparagraph descriptions"
                            style={{ marginTop: '2px', fontSize: '10px' }}
                        >
                            {el.uuid ?? el.id}
                        </Typography.Text>
                    </div>
                    {/* <CheckCircle size={17} color='#090A0B' weight='regular' style={{ marginLeft: '5px' }} /> */}
                    <Typography.Text
                        className="smallparagraph descriptions"
                        style={{ marginTop: '2px', fontSize: '10px' }}
                    >
                        {returnCurrencyValue(collection === 'payments' ? el.amount / 100 : el.total)}
                    </Typography.Text>
                </Row>
            </div>
        )
    }

    const RelateDocs = async () => {
        try {
            setLoading(true)
            const mainCollection = collection

            for (const subEl of elements) {
                await updateDoc(doc(getFirestore(), subEl?.collection, subEl.fid ?? subEl?.id), {
                    [mainCollection]: arrayUnion(element?.fid ?? element?.id),
                })
            }
            const updateMaster: any = {}
            for (const col of _.keys(collections)) {
                updateMaster[col] = arrayUnion(...collections[col].map((el: any) => el.fid ?? el.id))
            }

            await updateDoc(doc(getFirestore(), mainCollection, element?.fid ?? element?.id), updateMaster)
            // wait 2 secs
            await new Promise((r) => setTimeout(r, 2000))
            dispatch(
                setData({
                    item: 'relating',
                    data: null,
                }),
            )
            message.success('Se han relacionado los elementos.')
            setLoading(false)
        } catch (error: any) {
            setLoading(false)
            message.error(error?.message)
        }
    }

    return (
        <div
            ref={ref}
            className={` ${hidden ? 'bsh ' : 'shake bsh'}`}
            style={{
                display: relating ? 'block' : 'none',
                position: 'fixed',
                top: 10,
                right: 10,
                zIndex: 900000,
                padding: '15px',
                fontSize: '20px',
                fontWeight: 'bold',
                textAlign: 'center',
                backgroundColor: '#fff',
                transform: hidden ? 'translateX(90%)' : 'translateX(0%)',
                // boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;',
                transition: 'transform 0.3s ease',
                maxWidth: '350px',
                minWidth: '350px',
                borderRadius: '8px',
            }}
        >
            <div className="d-flex flex-column">
                <Row justify="space-between" align="middle">
                    <Row>
                        {!hidden ? (
                            <CaretCircleRight
                                onClick={() => setHidden(!hidden)}
                                size={20}
                                className="icon"
                                style={{ cursor: 'pointer' }}
                            />
                        ) : (
                            <CaretCircleLeft
                                onClick={() => setHidden(!hidden)}
                                size={20}
                                style={{ cursor: 'pointer' }}
                            />
                        )}
                        <Typography.Text className="smallparagraph descriptions" style={{ marginLeft: '5px' }}>
                            Relacionar elementos a {collection}
                        </Typography.Text>
                    </Row>
                    <X
                        onClick={() =>
                            dispatch(
                                setData({
                                    item: 'relating',
                                    data: null,
                                }),
                            )
                        }
                        style={{ cursor: 'pointer' }}
                        size={18}
                        className="icon"
                    />
                </Row>

                <MainElement />

                {collections &&
                    !hidden &&
                    Object.keys(collections).map((collection: string) => {
                        return (
                            <div key={collection} className="d-flex flex-column">
                                <Typography.Text
                                    className="smallparagraphbold"
                                    style={{
                                        marginTop: '10px',
                                        borderBottom: '1px solid #cecece',
                                        paddingBottom: '4px',
                                    }}
                                >
                                    {collectionsReadable.find((k: any) => k.key === collection)?.value}
                                </Typography.Text>
                                <div className="d-flex flex-column">
                                    {collections[collection].map((el: any) => {
                                        return (
                                            <Row
                                                key={el.id}
                                                justify="space-between"
                                                style={{
                                                    backgroundColor: '#fff',
                                                    padding: '0px',
                                                    borderRadius: '5px',
                                                    marginTop: '10px',
                                                }}
                                            >
                                                <Row>
                                                    <Checkbox
                                                        checked
                                                        className="blackcheck"
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => {
                                                            let newElements = [...(elements ?? [])].filter(
                                                                (elm: any) => elm.id !== el.id,
                                                            )
                                                            dispatch(
                                                                setData({
                                                                    item: 'relating',
                                                                    data: {
                                                                        ...relating,
                                                                        elementsToRelate: newElements,
                                                                    },
                                                                }),
                                                            )
                                                        }}
                                                    />
                                                    <div className="d-flex flex-column">
                                                        <Typography.Text
                                                            className="smallparagraph"
                                                            style={{ marginTop: '0px' }}
                                                        >
                                                            {el.client?.name ??
                                                                el.client?.legal_name ??
                                                                el.customer?.name}
                                                        </Typography.Text>
                                                        <Typography.Text
                                                            className="smallparagraph descriptions"
                                                            style={{ marginTop: '2px' }}
                                                        >
                                                            {el.uuid ?? el.id}
                                                        </Typography.Text>
                                                    </div>
                                                </Row>
                                                {/* <CheckIfRelating element={el} collection={collection} /> */}
                                                <Typography.Text
                                                    className="smallparagraph "
                                                    style={{ marginTop: '2px', fontSize: '10px' }}
                                                >
                                                    {returnCurrencyValue(
                                                        collection === 'payments' ? el.amount / 100 : el.total,
                                                    )}
                                                </Typography.Text>
                                            </Row>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}

                {elements?.length > 0 && (
                    <Row justify="end" style={{ marginTop: '25px' }}>
                        <Button type="primary" onClick={RelateDocs} loading={loading}>
                            Relacionar elementos
                        </Button>
                    </Row>
                )}
            </div>
        </div>
    )
}

export default RelatingElementsSignal
