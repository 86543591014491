import { Table } from "antd"
import { collection, getFirestore, query, where, orderBy } from "firebase/firestore"
import moment from "moment"
import { useSelector } from "react-redux"
import { useFirestoreCollectionData } from "reactfire"
import { MassInvoiceTableAction } from "../ElementActions/SingleActions/MassInvoiceTableAction"


export const MassInvoicesTable = () => {

    const { testmode } = useSelector((state: any) => state.data)
    const { team } = useSelector((state: any) => state.team)

    const columns = [
        {
            title: 'Tipo',
            dataIndex: 'action',
            key: 'type',
            render: (action: string) => {
                return action === 'create_invoices' ? 'Emisión de facturas' : 'Cancelación'
            }
        },
        {
            title: 'Estatus',
            dataIndex: 'status',
            key: 'status',
            render: (status: string, record: any) => {

                const { invoicesProcessed, totalInvoices, invoicesFailed } = record

                let statusText = '';
                if (status === 'processing') {
                    statusText = 'En proceso';
                } else if (status === 'failed') {
                    statusText = 'Fallido';
                } else if(status === 'enqueued') {
                    statusText = 'En proceso';
                }

                if (invoicesProcessed >= (totalInvoices - invoicesFailed)) {
                    statusText = 'Completado'
                }

                return statusText;
            }
        },
        {
            title: 'Facturas procesadas',
            dataIndex: 'totalInvoices',
            key: 'processed',
            render: (invoicesProcessed: number, record: any) => {
                if(invoicesProcessed > record.totalInvoices) {
                    return `${record.totalInvoices} / ${record.totalInvoices}`
                } else {
                    return `${invoicesProcessed || 0} / ${record.totalInvoices || 0}`
                }
            },
        },
        {
            title: 'Facturas fallidas',
            dataIndex: 'invoicesFailed',
            key: 'failed',
            render: (invoicesFailed: number, record: any) => {
                return `${invoicesFailed || 0} / ${record.totalInvoices || 0}`
            },
        },
        {
            title: 'Inicio',
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (timestamp: number) => {
                return moment(timestamp).format('DD/MM/YYYY HH:mm')
            }
        },
        {
            title: 'Final',
            dataIndex: 'endedAt',
            key: 'endDate',
            render: (endedAt: number, record: any) => {
                if (endedAt && record.invoicesProcessed >= (record.totalInvoices - record.invoicesFailed)) {
                    return moment(endedAt).format('DD/MM/YYYY HH:mm')
                } else {
                    return ''
                }
            }
        },
        {
            title: "", 
            width: '5%',
            dataIndex: 'id',
            key:'id',
            render: (id: string) => <MassInvoiceTableAction id={id}/>,
        }
    ]

    const q = query(
        collection(getFirestore(), `massiveActions`),
        where('action', '==', 'create_invoices'),
        where('team', '==', team?.id || ''),
        where('livemode', '==', !testmode),
        orderBy('timestamp', 'desc'),
    )


    const { data } = useFirestoreCollectionData(q, {
        idField: 'id',
    })

    return (
        <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={{ x: 'max-content' }}
        />
    )
}