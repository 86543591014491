import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestore, useFirestoreDocData } from 'reactfire'
import { TeamDef } from '../../interfaces/teamDef'
import { GetBATeamsHelper, GetBillingAccountHelper, GetClients, GetServices, GetTeamHelper } from '../context/databaseContextHelpers'
import { setData } from '../context/dataSlice'
import { getBillingData } from '../functions/Billing'
import { message } from 'antd'

export const useTeam = (): {
    status: 'loading' | 'success' | 'error'
    error: Error | undefined
    hasEmitted: boolean
    team: TeamDef
} => {
    const { team } = useSelector((state: any) => state.team)
    const fs = getFirestore()

    const teamRef = doc(fs, 'teams', team?.id || 'gh')
    const { status, data, error, hasEmitted } = useFirestoreDocData(teamRef, {
        idField: 'id',
        initialData: team,
    })

    const teamTyped: TeamDef = (data ?? {}) as TeamDef

    return {
        status,
        error,
        hasEmitted,
        team: teamTyped,
    }
}

export const useChangeTeam = () => {
    const fs = useFirestore()
    const dispatch = useDispatch()
    const { user } = useSelector((state: any) => state.user)


    const changeTeam = async (team: TeamDef) => {
        try {
            GetBATeamsHelper(user?.uid, dispatch)
            await updateDoc(doc(fs, 'users', user?.uid), {
                lastTeamViewed: team.id,
                lastBillingAccountViewed: team?.billingAccount,
            })
            dispatch(
                setData({
                    item: 'clients',
                    data: [],
                }),
            )
            GetTeamHelper(team.id, dispatch)
            GetBillingAccountHelper(team?.billingAccount, dispatch)
            GetServices(dispatch, team.id)
            GetClients(dispatch, team.id)
            //wait 2 seconds
            await new Promise((r) => setTimeout(r, 500))
            getBillingData({ team: team, user: user, dispatch })

        } catch (error) {
            console.error(error)
            message.error('Error al cambiar de cuenta')
        }
    }

    return {
        changeTeam,
    }
}