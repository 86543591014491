import { TrashSimple } from '@phosphor-icons/react'
import { Popconfirm, Row, Typography } from 'antd'
import { MetroSpinner } from 'react-spinners-kit'

type Props = {
    action: () => void
    loading: boolean
    full?: boolean
}

const DeleteAction = ({ action, loading, full }: Props) => {
    const LoadingIcon = () => {
        return <MetroSpinner size={16} color="#686769" />
    }

    return (
        <Row>
            {loading ? (
                <LoadingIcon />
            ) : (
                <Popconfirm
                    title="Eliminar recurso?"
                    okButtonProps={{ danger: true }}
                    okText="Eliminar"
                    cancelText="Cancelar"
                    onConfirm={action}
                    cancelButtonProps={{ type: 'dashed' }}
                >
                    <TrashSimple size={18} weight="regular" className="icon iconDanger clickable" />
                </Popconfirm>
            )}
            {full && <Typography.Text style={{ margin: 0, padding: 0, marginLeft: '5px' }}>Borrar</Typography.Text>}
        </Row>
    )
}

export default DeleteAction
