import React from 'react'
import { Form, Col, Row, Alert, Typography, Button, message } from 'antd'
import { FormInstance } from 'antd/lib'
import { TextAreaInput } from './Inputs'
import { useDispatch, useSelector } from 'react-redux'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { setTeam } from '../../context/teamsSlice'
import { closeModal } from '../../context/modalsSlice'

interface AddendaFormProps {
    incomingForm?: FormInstance
    buttonTitle?: string
}

const InvoiceCustomMessageForm: React.FC<AddendaFormProps> = ({ incomingForm, buttonTitle }) => {
    const { team } = useSelector((state: any) => state.team)
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const usingForm = incomingForm ?? form

    // Rest of the component code

    return (
        <Form
            form={usingForm}
            layout="vertical"
            initialValues={{ ...team?.defaults }}
            onFinish={async (values: any) => {
                try {
                    dispatch(
                        setTeam({ ...team, defaults: { ...team?.defaults, invoicePDFExtra: values.invoicePDFExtra } }),
                    )
                    await updateDoc(doc(getFirestore(), 'teams', team?.id), {
                        'defaults.invoicePDFExtra': values.invoicePDFExtra ?? '',
                    })
                    message.success('Información actualizada correctamente')
                    dispatch(closeModal('settingsDrawerVisible'))
                } catch (error: any) {
                    message.error('Ocurrió un error al actualizar la información.')
                }
            }}
        >
            <Row>
                <Col xs={24}>
                    <TextAreaInput
                        name="invoicePDFExtra"
                        label="Información extra en el PDF"
                        placeholder="Información extra"
                    />
                </Col>
                <Col xs={24}>
                    <Alert
                        message={
                            <Typography.Text className="smallparagraph descriptions">
                                Para modificar los colores y logo, por favor realiza la actualización desde los ajustes
                                de la marca.
                            </Typography.Text>
                        }
                        type="info"
                        showIcon
                    />
                </Col>

                <Col xs={24} style={{ marginTop: '25px' }}>
                    <Row justify="end">
                        <Button type="primary" htmlType="submit">
                            {buttonTitle ? buttonTitle : 'Actualizar mensaje'}
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Form>
    )
}

export default InvoiceCustomMessageForm
