import { saveAs } from 'file-saver'
interface AuthUser {
    getIdToken(): Promise<string>
}

interface Extra {
    isFile?: boolean
    fileName?: string
    fileExtension?: string
    forceFolio?: number
}

interface Options {
    method: string
    json: boolean
    headers: {
        'cache-control': string
        'Content-Type': string
        accept: string
        authorization: string
    }
    body?: string
    timeout?: number
    signal?: AbortSignal
}

interface ResponseData {
    data: () => Promise<any>
}

const wrapPromise = (promise: Promise<any>) => {
    let status = 'pending'
    let result: any = null
    let error: any = null
    let suspender = promise.then(
        (res) => {
            status = 'success'
            result = res
        },
        (err) => {
            status = 'error'
            error = err
        },
    )
    return {
        read() {
            if (status === 'pending') {
                throw suspender
            } else if (status === 'error') {
                throw error
            } else {
                return result
            }
        },
    }
}

const SignedInternalAPIRequest = async (
    body: any = {},
    endPoint: string = '',
    authUser: any,
    extra?: Extra,
    type: string = 'POST',
    useHeadersClass: boolean = false,
    testing: boolean = false,
): Promise<any> => {
    const getInfo = async () => {
        const token = await authUser.getIdToken()
        const controller = new AbortController()
        const timeout = 30000
        const timeoutId = setTimeout(() => controller.abort(), timeout)
        let headers = {
            'cache-control': 'no-cache',
            'Content-Type': 'application/json',
            accept: 'application/json',
            authorization: `Bearer ${token}`,
        }
        var options: Options = {
            method: type,
            json: true,
            headers,
            body: JSON.stringify(body),
            timeout,
            signal: controller.signal,
        }
        if (type === 'GET') {
            options = {
                method: 'GET',
                json: true,
                headers,
                timeout,
                signal: controller.signal,
            }
        }
        clearTimeout(timeoutId)
        try {
            const url = process.env.REACT_APP_INTERNAL_API_URL || 'https://us-central1-gigstackpro.cloudfunctions.net'
            const request = new Request(`${url}/${endPoint}`, options)
            const response = await fetch(request)

            var res
            if (response.status >= 200 && response.status < 400) {
                if (extra?.isFile) {
                    try {
                        // DOWNLOAD BUFFER FROM RESPONSE
                        var blob = await response.blob()
                        saveAs(blob, extra.fileName || 'downloaded-file')
                        return blob
                    } catch (error) {}
                } else {
                    try {
                        const json = await response.json()
                        res = json
                        return res
                    } catch (error) {
                        res = response.body
                        throw res
                    }
                }
                return res
            } else {
                try {
                    const json = await response.json()
                    res = json
                    throw res
                } catch (error) {
                    res = error
                    throw res
                }
            }
        } catch (error) {
            throw error
        }
    }
    return getInfo()
}
const fetchData = (body: any = {}, endPoint: string = '', authUser: AuthUser, extra: Extra): ResponseData => {
    const apiPromise = SignedInternalAPIRequest(body, endPoint, authUser, extra)
    return {
        data: wrapPromise(apiPromise).read(), // Call the read() method to get the data
    }
}

const SignedInternalAPIRequestTest = async (
    body: any = {},
    endPoint: string = '',
    authUser: AuthUser,
    extra: Extra,
    type: string = 'POST',
): Promise<any> => {
    const getInfo = async () => {
        const token = await authUser.getIdToken()
        const headers = {
            'cache-control': 'no-cache',
            'Content-Type': 'application/json',
            accept: 'application/json',
            authorization: `Bearer ${token}`,
        }
        var options: Options = {
            method: type,
            json: true,
            headers,
            body: JSON.stringify(body),
        }
        if (type === 'GET') {
            options = {
                method: 'GET',
                json: true,
                headers,
            }
        }
        const request = new Request(`https://us-central1-gigstackpro.cloudfunctions.net/${endPoint}`, options)
        const response = await fetch(request)
        var res
        if (extra?.isFile) {
            try {
                var blob = await response.blob()
                return blob
            } catch (error) {}
        }
        try {
            const json = await response.json()
            res = json
        } catch (error) {
            res = response.body
            throw res
        }
        if (response.status >= 200 && response.status < 300) {
            return res
        } else {
            throw res
        }
    }
    return getInfo()
}

const SignedCustomInternalAPIRequest = async (
    body: any = {},
    endPoint: string = '',
    token: any,
    type: string = 'POST',
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const headers = {
                'cache-control': 'no-cache',
                'Content-Type': 'application/json',
                accept: 'application/json',
                authorization: `Bearer ${token}`,
            }
            var options: Options = {
                method: type,
                json: true,
                headers,
                body: JSON.stringify(body),
            }
            const url = process.env.REACT_APP_INTERNAL_API_URL || 'https://us-central1-gigstackpro.cloudfunctions.net'
            const request = new Request(`${url}/${endPoint}`, options)
            const response = await fetch(request)
            var res
            try {
                const json = await response.json()
                res = json
            } catch (error) {
                res = response.body
            }
            if (response.status >= 200 && response.status < 300) {
                resolve(res)
            } else {
                reject(res)
            }
        } catch (error) {
            reject(error)
        }
    })
}

export { fetchData, SignedInternalAPIRequest, SignedInternalAPIRequestTest, SignedCustomInternalAPIRequest }
