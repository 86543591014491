import { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Input, InputRef, Space, Table, Typography } from 'antd'
import { FilterConfirmProps } from 'antd/lib/table/interface'
import {
    query,
    collection,
    orderBy,
    getFirestore,
    where,
    limit,
    startAfter,
    getCountFromServer,
    getDocs,
} from 'firebase/firestore'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestoreCollectionData } from 'reactfire'
// import { useNavigate } from 'react-router-dom';
import { ClientDef, clientSet } from '../../../interfaces/clientDef'
import { ClientsColumns } from './Columns/ClientsColumns'
import { setData } from '../../context/dataSlice'
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'
import { useLocation, useNavigate } from 'react-router-dom'

import { ColumnType } from 'antd/es/table'
import { MagnifyingGlass, X } from '@phosphor-icons/react'

const ClientsTable = ({ max = 10 }: { max: number }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const source = 'clients'
    let def: ClientDef
    let defSet = (data: any) => {
        return clientSet(data)
    }

    //IMPORTS
    const fs = getFirestore()

    // HOOKS
    // const navigate = useNavigate()

    //CONTEXTS
    const { team } = useSelector((state: any) => state.team)
    const { selectedClients, testmode } = useSelector((state: any) => state.data)

    //STATES

    const [newLoading, setNewLoading] = useState(false)

    const memoizedNewLoading = useMemo(() => newLoading, [newLoading])
    const [totalCount, setTotalCount] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [items, setItems] = useState<(typeof def)[]>([])

    const [, /*searchText*/ setSearchText] = useState('')
    const [, /*searchedColumn*/ setSearchedColumn] = useState('')
    const searchInput = useRef<InputRef>(null)

    type DataIndex = keyof typeof def

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = (clearFilters: () => void) => {
        clearFilters()
        setSearchText('')
    }

    //VARIABLES
    var initialQuery = query(
        collection(fs, source),
        where('team', '==', team?.id ?? ''),
        orderBy('timestamp', 'desc'),
        where('livemode', '==', !testmode),
        limit(max),
    )
    var totalCountQuery = query(
        collection(fs, source),
        where('team', '==', team?.id ?? ''),
        where('livemode', '==', !testmode),
        orderBy('timestamp', 'desc'),
    )

    //DATA
    const { status: initialLoadStatus, data } = useFirestoreCollectionData(initialQuery, {
        idField: 'fid',
    })

    //FUNCTIONS
    const getInitialCount = async () => {
        setTotalCount((await getCountFromServer(totalCountQuery)).data().count)
    }

    const getNewData = async ({ lastItem: newItem, ps, p }: { lastItem: number; ps: number; p: number }) => {
        if (ps * p < items.length) return
        setNewLoading(true)
        var newItems = await getDocs(
            query(
                collection(fs, source),
                where('team', '==', team?.id ?? ''),
                orderBy('timestamp', 'desc'),
                where('livemode', '==', !testmode),
                limit(ps * p - items.length + 1),
                startAfter(newItem),
            ),
        )
        // setLastItem(newItem)

        setItems([...items, ...(newItems.docs.map((c) => defSet(c.data())) ?? [])])
        setNewLoading(false)
    }

    useEffect(() => {
        if (!memoizedNewLoading) {
            // Wait 1 sec to change the state
            setTimeout(() => {
                setNewLoading(false)
            }, 1000)
        }
    }, [team?.id, testmode, memoizedNewLoading])

    //EFFECTS
    useEffect(() => {
        getInitialCount()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    var keysToShow: any = {
        name: 'Cliente',
        company: 'Compañía',
        email: 'Email',
        profile: 'Perfil Fiscal',
        timestamp: 'Creación',
        balance: 'Balance',
        actions: 'Acciones',
    }

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: ClientDef[]) => {
            dispatch(
                setData({
                    item: 'selectedClients',
                    data: selectedRows,
                }),
            )
            //SAVE SELECTED ON STATE TAKING IN MIND THE SEARCH AND FILTER
            // setSelectedRows(selectedRowKeys)
        },

        getCheckboxProps: (record: ClientDef) => ({
            disabled: !record.id, // Column configuration not to be checked
            name: record.id,
        }),
    }

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<typeof def> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Typography.Text
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch(selectedKeys as string[], confirm, dataIndex)
                        }}
                        className="smallparagraph descriptions clickable"
                    >
                        Restablecer
                    </Typography.Text>

                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<MagnifyingGlass />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>

                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close()
                        }}
                    >
                        <X className="icon" />
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => {
            return (
                <MagnifyingGlass
                    style={{
                        color: filtered ? '#8666FF' : '#50566B',
                    }}
                    size={16}
                />
            )
        },
        onFilter: (value, record) =>
            (record[dataIndex] ?? '')
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()) ||
            (record.company ?? '')
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()) ||
            (record?.name ?? '')
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()) ||
            (record?.email ?? '')
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()) ||
            (record?.phone ?? '')
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()) ||
            (record?.company ?? '')
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),

        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
            }
        },
        render: (text, record) =>
            ClientsColumns({
                record,
                columnName: dataIndex,
                navigate: () => {
                    if (dataIndex !== 'name') return
                    dispatch(setData({item: 'anchorLocation', data: `${location.pathname}${location.search}`}))
                    navigate(`/clientDetails?id=${record.id}`)
                },
            }),
    })

    return (
        <ErrorBoundary>
            <Table
                //allow scroll
                scroll={{ x: 1500 }}
                rowKey={(rec: any) => rec?.id ?? 'as'}
                loading={initialLoadStatus === 'loading' || newLoading}
                rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: [...selectedClients?.map((c: ClientDef) => c.id)],
                    ...rowSelection,
                }}
                pagination={{
                    total: totalCount,
                    defaultPageSize: pageSize,
                    defaultCurrent: currentPage,
                    pageSize: pageSize,
                    current: currentPage,
                    onChange(p, ps) {
                        setPageSize(ps)
                        setCurrentPage(p)
                        var allItems = [...data, ...items]
                        getNewData({ lastItem: allItems[allItems?.length - 1]?.timestamp, ps, p })
                    },
                }}
                columns={[
                    ...Object.keys(keysToShow).map((k: any) => {
                        var el: any = {
                            title: keysToShow[k],
                            width: k === 'name' ? '20%' : 'auto',
                            dataIndex: k,

                            render: (text: any, record: typeof def) => (
                                <div
                                    onClick={() => {
                                        if (k === 'name') {
                                            navigate(`/clientDetails?id=${record.id}`)
                                        }
                                    }}
                                >
                                    {ClientsColumns({ record, columnName: k })}
                                </div>
                            ),
                        }
                        if (k === 'actions') {
                            el['fixed'] = 'right'
                            el['width'] = '7%'
                        }
                        if (k === 'name') {
                            el['fixed'] = 'left'
                            el['width'] = '15%'
                        }
                        if (k === 'name' || k === 'company') {
                            el = {
                                ...el,
                                ...getColumnSearchProps(k),
                            }
                        }
                        return el
                    }),
                ]}
                dataSource={[...(data ?? [])?.map((d) => defSet(d)), ...items]}
            />
        </ErrorBoundary>
    )
}

export default ClientsTable
