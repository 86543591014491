import { Col, Divider, Row, message } from 'antd'
import React from 'react'
// import { SearchParamInURL } from '../../functions/helpers';
import SettingsDivider from './Components/SettingsDivider'
import { SettingCard } from './Components/SettingCard'
import { UserList, UserPlus } from '@phosphor-icons/react'
import { useDispatch } from 'react-redux'
import { setData } from '../../context/dataSlice'
import { openModal } from '../../context/modalsSlice'
import { usePermissions } from '../../customHooks/usePermissions'
import { addParamToUrl } from '../../functions/UrlParams'

const SeatsSettings: React.FC = () => {
    // const { team } = useSelector((state: any) => state.team)
    // const { user } = useSelector((state: any) => state.user)
    // const { billingAccount } = useSelector((state: any) => state.billingAccount)

    // const subtab = SearchParamInURL('subtab')
    // const [selectedSubtab, setSelectedSubtab] = useState(subtab ?? 'keys')

    const dispatch = useDispatch()
    const { isTeamAdmin } = usePermissions()

    return (
        // Your JSX code goes here
        <Row
            gutter={{
                xs: 10,
                md: 12,
            }}
        >
            <Col xs={24}>
                {' '}
                <Divider />{' '}
            </Col>

            <SettingsDivider
                title={'Miembros del equipo'}
                description={'Configura y visualiza los miembros del equipo actuales.'}
                options={[
                    <SettingCard
                        title="Asientos"
                        description="Visualiza los miembros del equipo"
                        icon={<UserList size={20} color="#6B7CF9" weight="regular" />}
                        onClick={() => {
                            if (!isTeamAdmin)
                                return message.warning('No tienes permisos para administrar los asientos del equipo')
                            dispatch(
                                setData({
                                    item: 'settingsDrawerConfig',
                                    data: {
                                        type: 'seats',
                                        title: 'Asientos',
                                        description: 'Visualiza los miembros del equipo actuales.',
                                    },
                                }),
                            )
                            dispatch(openModal('settingsDrawerVisible'))
                            addParamToUrl('open', 'settings_seats')
                        }}
                    />,
                ]}
            />
            <Col xs={24}>
                {' '}
                <Divider />{' '}
            </Col>
            <SettingsDivider
                title={'Invitaciones'}
                description={'Administra las invitaciones a nuevas cuentas.'}
                options={[
                    // <SettingCard
                    //     title="Invitaciones pendientes"
                    //     description="Revisa las invitaciones pendientes de ser aceptadas"
                    //     icon={<Chair size={20} color='#6B7CF9' weight='regular' />}
                    // />,
                    <SettingCard
                        title="Nueva invitación"
                        description="Enviar invitación a un nuevo usuario al equipo actual"
                        icon={<UserPlus size={20} color="#6B7CF9" weight="regular" />}
                        onClick={() => {
                            if (!isTeamAdmin)
                                return message.warning(
                                    'No tienes permisos para administrar las invitaciones del equipo',
                                )
                            dispatch(
                                setData({
                                    item: 'settingsDrawerConfig',
                                    data: {
                                        type: 'seats.invite',
                                        title: 'Nueva invitación',
                                        description: 'Enviar invitación a un nuevo usuario al equipo actual',
                                    },
                                }),
                            )
                            dispatch(openModal('settingsDrawerVisible'))
                            addParamToUrl('open', 'settings_invites')
                        }}
                    />,
                ]}
            />
        </Row>
    )
}

export default SeatsSettings
