import { Space, Tag, Tooltip, Typography } from 'antd'
import { ClientDef } from '../../../../interfaces/clientDef'

import moment from 'moment'
import ClientActions from '../../ElementActions/ClientActions'
import { EnvelopeSimple, ShootingStar } from '@phosphor-icons/react'
import { CopyableElementId } from '../../UI/CopyableElementId'
import { returnCurrencyValue } from '../../../functions/helpers'
// import { useNavigate } from "react-router-dom"

type TypeClientColumnName =
    | 'id'
    | 'balance'
    | 'amount'
    | 'clientInfo'
    | 'email'
    | 'rfc'
    | 'status'
    | 'timestamp'
    | 'from'
    | 'invoiceType'
    | 'series'
    | 'relations'
    | 'actions'
    | 'name'
    | 'company'
    | 'profile'
    | keyof ClientDef

/**
 * 
 *     var keysToShow: any = {
        name: 'Cliente',
        company: 'Compañia',
        email: 'Email',
        profile: 'Perfil',
        timestamp: 'Creación',
        totalInvoiced: 'Total facturado',
        actions: ''
    }
 */

interface ClientsColumnsProps {
    record: ClientDef
    columnName: TypeClientColumnName
    text?: string
    navigate?: Function
    from?: string
}

export const ClientsColumns = ({ record, columnName, text, navigate, from }: ClientsColumnsProps) => {
    switch (columnName) {
        case 'name':
            const wrappedText =
                (record.name ?? record.legalName ?? '')?.length > 10
                    ? (record.name ?? record.legalName)?.substring(0, 10) + '...'
                    : (record.name ?? record.legalName)

            return (
                <Space direction="vertical">
                    <Typography.Text
                        className="p-base-regular neutral-1 clickable"
                        onClick={() => {
                            navigate && navigate(record.id)
                        }}
                        style={{
                            //max chars 30 then ellipsis
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {wrappedText}
                        {!record?.livemode && (
                            <Tooltip title="Modo prueba">
                                <ShootingStar
                                    size={16}
                                    className="icon"
                                    style={{
                                        marginLeft: '5px',
                                    }}
                                />
                            </Tooltip>
                        )}
                    </Typography.Text>
                    <CopyableElementId id={record?.id} idName="ID" />
                </Space>
            )
        case 'company':
            const companyText = record.company || 'Sin compañia'
            return <Typography.Text className="p-base-regular">{companyText}</Typography.Text>
        case 'id':
            return <Typography.Link>{record.id}</Typography.Link>
        case 'amount':
            return <></>
        case 'timestamp':
            return (
                <Typography.Text className="p-base-regular">
                    {moment(record.timestamp).format('DD MMM YYYY HH:mm') ?? ''}
                </Typography.Text>
            )
        case 'profile':
            return (
                <Tag color={record.profile?.color} style={{ color: record?.profile?.textColor }}>
                    {record?.profile?.name}
                </Tag>
            )
        case 'clientInfo':
            // const elements = []
            // if (record?.company) elements.push(record?.company)
            // if (record?.id || record?.id) elements.push(record?.id ?? record?.id)
            // if (record?.metadata?.stripeId) elements.push(record?.metadata?.stripeId)
            // const name = record.legal_name ?? record?.name
            // const nameTruncated = name?.length > 20 ? name?.substring(0, 20) + '...' : name

            return <></>
        case 'email':
            const emailText = record.email || 'Sin email'
            return (
                <Typography.Text className="p-base-regular" style={{ textDecoration: 'lowercase' }}>
                    {emailText}
                    {record.bcc &&
                        (record.bcc.length > 0 ?? (
                            <Tooltip
                                title={
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        {record.bcc.map((bcc, i) => (
                                            <span key={i}>{bcc}</span>
                                        ))}
                                    </div>
                                }
                            >
                                <Tag style={{ fontSize: '10px', marginLeft: '5px' }}>
                                    <EnvelopeSimple size={10} className="icon" weight="regular" />
                                    {record.bcc.length}+
                                </Tag>
                            </Tooltip>
                        ))}
                </Typography.Text>
            )
        case 'rfc':
            const rfcText = record.rfc || 'Sin RFC'
            return <Typography.Text className="p-base-regular">{rfcText}</Typography.Text>
        case 'balance':
            return (
                <Typography.Text className="p-base-regular">
                    {returnCurrencyValue((record?.stripeBalance || 0) / 100)}
                </Typography.Text>
            )
        case 'from':
            return <></>
        case 'status':
            return <></>
        case 'relations':
            return <></>
        case 'actions':
            return <ClientActions client={record} showFull={false} from={from} />
        default:
            return <Typography.Text className="p-base-regular">{text}</Typography.Text>
    }
}
