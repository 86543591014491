import { message, Modal, Space, Typography } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { closeModal } from "../context/modalsSlice"
import { generateCode } from "../functions/helpers"
import moment from "moment"
import { OptimizedPaymentDef } from "../../interfaces/paymentsDef"
import { doc, getFirestore, setDoc } from "firebase/firestore"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { setData } from "../context/dataSlice"

export const ConfirmMassivePayInvoiceModal = () => {

    const dispatch = useDispatch()
    const { selectedPayments } = useSelector((state: any) => state.data)
    const { confirmMassivePayInvoiceModalVisible } = useSelector((state: any) => state.modals)
    const { testmode } = useSelector((state: any) => state.data)
    const { team } = useSelector((state: any) => state.team)
    const { user } = useSelector((state: any) => state.user)
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    const paymentsIds = selectedPayments.map((p: OptimizedPaymentDef) => p.id)

    const handleCreateMassiveInvoice = async () => {
        setLoading(true)
        try {
            const massiveInvoiceDoc = {
                id: generateCode(15, 'mact'),
                timestamp: moment().valueOf(),
                livemode: !testmode,
                action: 'create_invoices',
                type: 'payments',
                storageRef: null, 
                paymentsIds: paymentsIds,
                status: 'processing',
                team: team.id,
                owner: user.uid,
            }
            await setDoc(doc(getFirestore(), 'massiveActions', massiveInvoiceDoc.id), massiveInvoiceDoc)

            dispatch(closeModal('confirmMassivePayInvoiceModalVisible'))
            setLoading(false)
            dispatch(setData({ item: 'selectedPayments', data: [] }))
            navigate(`/invoices?page=1&ps=10&subtab=massiveActions`)
            message.success('Facturas creadas exitosamente')
        } catch (error: any) {
            message.error(error.message || 'Ocurrió un error al crear las facturas')
        }
    }

    return(
        <Modal
            open={confirmMassivePayInvoiceModalVisible}
            title={`${selectedPayments.length} ${selectedPayments.length > 1 ? 'pagos serán facturados' : 'pago será facturado'}`}
            onCancel={() => {
                dispatch(closeModal('confirmMassivePayInvoiceModalVisible')
            )}}
            onOk={handleCreateMassiveInvoice}
            confirmLoading={loading}
        >
            <Space direction={"vertical"}>
                <Typography.Text>
                Una vez que continúes facturaremos o, en su caso, crearemos portales de auto-factura para los pagos seleccionados. 
                </Typography.Text>
                <Typography.Text>
                Podrás consultar el estado dentro de Facturación&gt;Masivas. 
                </Typography.Text>

            </Space>      
        </Modal>
    )

}