import { Button, Col, message, Modal, Row } from 'antd'
import { arrayUnion, doc, getFirestore, updateDoc } from 'firebase/firestore'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {} from 'reactfire'
import { closeModal } from '../context/modalsSlice'
import { setData } from '../context/dataSlice'
import { SearchResultsView } from '../search/ViewAllComponente'

const AddRelatedPayment = () => {
    const dispatch = useDispatch()
    const { relations } = useSelector((state: any) => state.data)
    const { relatedPaymentModalVisible } = useSelector((state: any) => state.modals)
    // const { team } = useSelector((state: any) => state.team);

    const [loading, setLoading] = useState(false)
    const [selectedPayments, setSelectedPayments] = useState<any[]>([])

    // const q = query(collection(getFirestore(), 'payments'), where('team', '==', team.id),orderBy('timestamp', 'desc'));
    // const { /*status*/ data } = useFirestoreCollectionData(q, {
    //     idField: 'id'
    // });

    // const columns = [
    //     {
    //         title: 'Cliente',
    //         dataIndex: (['client', 'name'] || ['client', 'legal_name']),
    //         key: 'clientName'
    //     },
    //     {
    //         title: 'Estado',
    //         dataIndex: 'status',
    //         key: 'status',
    //         render: (status: any) => (<PaymentStatusTag record={status} shape="circle" />)
    //     },
    //     {
    //         title: 'Total',
    //         dataIndex: 'internalItems',
    //         key: 'amount',
    //         render: (internalItems: any) => getItemsAmounts(internalItems).totalString
    //     },
    //     {
    //         title: 'Date',
    //         dataIndex: 'timestamp',
    //         key: 'timestamp',
    //         render: (timestamp: any) => moment(timestamp).format('YYYY-MM-DD')
    //     },

    // ];

    // const rowSelection: any = {
    //     onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
    //         setSelectedPayments(selectedRows)
    //     },
    //     getCheckboxProps: (record: any) => ({
    //         disabled: !record.id,
    //         name: record.id,
    //     }),
    // };

    return (
        <Modal
            open={relatedPaymentModalVisible}
            onCancel={() => {
                dispatch(
                    setData({
                        item: 'relations',
                        data: {},
                    }),
                )
                dispatch(closeModal('relatedPaymentModalVisible'))
                setSelectedPayments([])
            }}
            width={'100%'}
            footer={null}
            title={'Asociar un pago'}
        >
            <Col>
                <div style={{ height: '60vh', overflowY: 'scroll' }}>
                    <SearchResultsView showIndexSelector={false} />
                </div>
                <Row justify="end">
                    <Button
                        disabled={selectedPayments.length === 0 || loading}
                        loading={loading}
                        type="primary"
                        onClick={async () => {
                            setLoading(true)
                            try {
                                const ids = selectedPayments.map((p: any) => p.id)
                                await updateDoc(doc(getFirestore(), relations.relatedTo, relations.element), {
                                    payments: arrayUnion(...ids),
                                })
                                message.success('Se han asociado las facturas.')
                                dispatch(closeModal('relatedPaymentModalVisible'))
                                dispatch(
                                    setData({
                                        item: 'relations',
                                        data: {},
                                    }),
                                )
                                setLoading(false)
                                setSelectedPayments([])
                            } catch (error: any) {
                                message.error(error.message)
                                dispatch(
                                    setData({
                                        item: 'relations',
                                        data: {},
                                    }),
                                )
                                dispatch(closeModal('relatedPaymentModalVisible'))
                                setLoading(false)
                                setSelectedPayments([])
                            }
                        }}
                    >
                        Relacionar
                    </Button>
                </Row>
            </Col>
        </Modal>
    )
}

export default AddRelatedPayment
