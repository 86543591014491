import { CheckFat, LinkSimpleHorizontalBreak } from '@phosphor-icons/react'
import { Tooltip, Typography, message } from 'antd'
import React, { useState } from 'react'
import { MetroSpinner } from 'react-spinners-kit'

type Props = {
    toCopy: string
    loading: boolean
    isMenu?: boolean
    menuTitle?: string
}

const CopyAction = ({ toCopy, loading, isMenu, menuTitle }: Props) => {
    const [copying, setCopying] = useState(false)

    const LoadingIcon = () => {
        return <MetroSpinner size={16} color="#686769" />
    }
    return (
        <div
            style={{
                cursor: 'pointer',
                marginRight: '5px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            onClick={async () => {
                setCopying(true)
                navigator.clipboard.writeText(toCopy)
                message.success('Copiado al portapapeles')
                await new Promise((resolve) => setTimeout(resolve, 500))
                setCopying(false)
            }}
        >
            {isMenu ? (
                <Typography> {menuTitle ?? 'Copiar al portapapeles'}</Typography>
            ) : (
                <Tooltip title="Copiar al portapapeles">
                    {copying ? (
                        <CheckFat size={18} weight="regular" className="icon" />
                    ) : loading ? (
                        <LoadingIcon />
                    ) : (
                        <LinkSimpleHorizontalBreak size={18} weight="regular" className="icon clickable" />
                    )}
                </Tooltip>
            )}
        </div>
    )
}

export default CopyAction
