export const modalsFromUrl = [
    {
        type: 'payment',
        modal: 'paymentModalVisible',
        dataCtx: 'payment',
    },
    {
        type: 'registerPayment',
        modal: 'registerPaymentModalVisible',
    },
    {
        type: 'invoice',
        modal: 'invoiceModalVisible',
        dataCtx: 'invoice',
    },
    {
        type: 'receipt',
        modal: 'receiptModalVisible',
    },
    {
        type: 'service',
        modal: 'createServiceVisible',
    },
    {
        type: 'client',
        modal: 'createClientDrawerVisible',
    },
    {
        type: 'sat',
        modal: 'satModalVisible',
    },
    {
        type: 'stripe',
        modal: 'stripeModalVisible',
    },
    {
        type: 'openpay',
        modal: 'openpayModalVisible',
    },
    {
        type: 'spei',
        modal: 'speiModalVisible',
    },
    {
        type: 'zapier',
        modal: 'zapierModalVisible',
    },
    {
        type: 'airtable',
        modal: 'airtableModalVisible',
    },
    {
        type: 'sheets',
        modal: 'googleSheetsModalVisible',
    },
    {
        type: 'whmcs',
        modal: 'whmcsModalVisible',
    },
    {
        type: 'hilos',
        modal: 'hilosModalVisible',
    },
    {
        type: 'settings_brand',
        modal: 'settingsDrawerVisible',
        data: {
            item: 'settingsDrawerConfig',
            data: {
                type: 'brand',
                title: 'Información de la marca',
                description: 'Edita el logo, colores y comunicación pública para tus clientes.',
            },
        },
    },
    {
        type: 'settings_portal',
        modal: 'settingsDrawerVisible',
        data: {
            item: 'settingsDrawerConfig',
            data: {
                type: 'customerPortal',
                title: 'Portal de clientes',
                description: 'Edita el acceso al portal de cliente.',
            },
        },
    },
    {
        type: 'settings_personal',
        modal: 'settingsDrawerVisible',
        data: {
            item: 'settingsDrawerConfig',
            data: {
                type: 'personal',
                title: 'Mi información',
                description: 'Edita nombre, correo, etc.',
            },
        },
    },
    {
        type: 'settings_billing',
        modal: 'settingsDrawerVisible',
        data: {
            item: 'settingsDrawerConfig',
            data: {
                type: 'billing',
                title: 'Facturación',
                description: 'Configura la información de tu cuenta de facturación',
            },
        },
    },
    {
        type: 'settings_subscription',
        modal: 'settingsDrawerVisible',
        data: {
            item: 'settingsDrawerConfig',
            data: {
                type: 'subscription',
                title: 'Suscripción',
                description: 'Genera, actualiza o cancela la suscripción a gigstack.pro',
            },
        },
    },
    {
        type: 'settings_security',
        modal: 'settingsDrawerVisible',
        data: {
            item: 'settingsDrawerConfig',
            data: {
                type: 'security',
                title: 'Mis accesos',
                description: 'Consulta las sesiones y detalles de acceso.',
            },
        },
    },
    {
        type: 'settings_automatic_invoices',
        modal: 'settingsDrawerVisible',
        data: {
            item: 'settingsDrawerConfig',
            data: {
                type: 'defaults.invoice',
                title: 'Configura las facturas automáticas',
                description: 'La información predeterminada de tus facturas para las automatizaciones',
            },
        },
    },
    {
        type: 'settings_taxes',
        modal: 'settingsDrawerVisible',
        data: {
            item: 'settingsDrawerConfig',
            data: {
                type: 'defaults.taxes',
                title: 'Configura los impuestos',
                description: 'Los impuestos predefinidos de la cuenta.',
            },
        },
    },
    {
        type: 'settings_global_invoice',
        modal: 'settingsDrawerVisible',
        data: {
            item: 'settingsDrawerConfig',
            data: {
                type: 'defaults.globalInvoice',
                title: 'Configura la factura global',
                description: 'Para las conciliaciones automáticas a final de un periodo',
            },
        },
    },
    {
        type: 'settings_invoices_personalization',
        modal: 'settingsDrawerVisible',
        data: {
            item: 'settingsDrawerConfig',
            data: {
                type: 'defaults.invoicePDFExtra',
                title: 'Personaliza tus facturas',
                description: 'Agrega una sección de mensaje personalizada para tus clientes en el pdf de tus facturas',
            },
        },
    },
    {
        type: 'settings_addenda',
        modal: 'settingsDrawerVisible',
        data: {
            item: 'settingsDrawerConfig',
            data: {
                type: 'defaults.addenda',
                title: 'Addenda',
                description: 'Se incorpora en el CFDI en su formato XML y se agregará al PDF.',
            },
        },
    },
    {
        type: 'settings_invoices_emails',
        modal: 'settingsDrawerVisible',
        data: {
            item: 'settingsDrawerConfig',
            data: {
                type: 'defaults.receiptsEmailsTemplate',
                title: 'Plantilla de correo electrónico',
                description: 'Configura el mensaje que reciben tus clientes al recibir una nueva factura.',
            },
        },
    },
    {
        type: 'settings_limit_days',
        modal: 'settingsDrawerVisible',
        data: {
            item: 'settingsDrawerConfig',
            data: {
                type: 'defaults.limitDaysToPay',
                title: 'Días límite de pago',
                description:
                    'Los días límite de pago para los pagos abiertos, enviaremos notificaciones y recordatorios',
            },
        },
    },
    {
        type: 'settings_payments_reminders',
        modal: 'settingsDrawerVisible',
        data: {
            item: 'settingsDrawerConfig',
            data: {
                type: 'defaults.paymentsReminderDays',
                title: 'Recordatorios de pago',
                description: 'Configura los recordatorios de pago basado en los días límite',
            },
        },
    },
    {
        type: 'settings_payments_emails',
        modal: 'settingsDrawerVisible',
        data: {
            item: 'settingsDrawerConfig',
            data: {
                type: 'defaults.paymentsEmailsTemplate',
                title: 'Plantilla de correo electrónico',
                description: 'Configura el mensaje que reciben tus clientes al recibir un nuevo pago.',
            },
        },
    },
    {
        type: 'settings_receipts',
        modal: 'settingsDrawerVisible',
        data: {
            item: 'settingsDrawerConfig',
            data: {
                type: 'salesReceipts',
                title: 'Recibos de venta y portal de auto-factura.',
                description: 'Información y ajustes de los recibos de venta y portal de auto-factura.',
            },
        },
    },
    {
        type: 'settings_receipts_emails',
        modal: 'settingsDrawerVisible',
        data: {
            item: 'settingsDrawerConfig',
            data: {
                type: 'defaults.receiptsEmailsTemplate',
                title: 'Plantilla de correo electrónico',
                description: 'Configura el mensaje que reciben tus clientes al recibir un nuevo recibo de venta.',
            },
        },
    },
    {
        type: 'settings_apikeys',
        modal: 'settingsDrawerVisible',
        data: {
            item: 'settingsDrawerConfig',
            data: {
                type: 'apikeys',
                title: 'Llaves del API',
                description: 'Consulta y administra las llaves para utilizar el API de gigstack',
            },
        },
    },
    {
        type: 'settings_webhooks',
        modal: 'settingsDrawerVisible',
        data: {
            item: 'settingsDrawerConfig',
            data: {
                type: 'webhooks.list',
                title: 'Webhooks',
                description: 'Administra los webhooks que ya has creado.',
            },
        },
    },
    {
        type: 'settings_webhooks_create',
        modal: 'settingsDrawerVisible',
        data: {
            item: 'settingsDrawerConfig',
            data: {
                type: 'webhooks.create',
                title: 'Nuevo Webhook',
                description: 'Crea un nuevo webhook para recibir información de los eventos que ocurren en gigstack.',
            },
        },
    },
    {
        type: 'settings_seats',
        modal: 'settingsDrawerVisible',
        data: {
            item: 'settingsDrawerConfig',
            data: {
                type: 'seats',
                title: 'Asientos',
                description: 'Visualiza los miembros del equipo actuales.',
            },
        },
    },
    {
        type: 'settings_invites',
        modal: 'settingsDrawerVisible',
        data: {
            item: 'settingsDrawerConfig',
            data: {
                type: 'seats.invite',
                title: 'Nueva invitación',
                description: 'Enviar invitación a un nuevo usuario al equipo actual',
            },
        },
    },
]
