import { Button, Col, Form, Modal, Row, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal, setElementSelected } from '../context/modalsSlice'
import { NumberInput, SelectInput, TextInput } from '../components/Forms/Inputs'
import { useEffect, useState } from 'react'
import { DuplicateInvoice } from '../functions/helpers'
import ItemsSelection from '../components/ComponentIndividual/ItemsSelection'
import { useAuth } from 'reactfire'
import { InvoiceDef } from '../../interfaces/invoicesDef'
import { paymentForms } from '../datasets/Fiscal'
import { useNavigate } from 'react-router-dom'
import { setData } from '../context/dataSlice'

const StampInvoiceModal = () => {
    const navigate = useNavigate()
    const { stampInvoiceModalVisible } = useSelector((state: any) => state.modals)
    const { invoice } = useSelector((state: any) => state.modals)
    const { selectedServices } = useSelector((state: any) => state.data)
    const dispatch = useDispatch()
    const auth = useAuth()
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    useEffect(
        () => {
            if (invoice?.uuid) {
                form.setFieldsValue({
                    series: invoice?.series,
                    folio_number: invoice?.folio_number,
                    payment_method: invoice?.payment_method,
                    payment_form: invoice?.payment_form,
                })
            }
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [invoice?.uuid],
    )

    const handleInvoice = async (invObject: InvoiceDef) => {
        setLoading(true)
        await DuplicateInvoice({
            invoice: {
                ...invObject,
                internalItems: selectedServices,
                items: selectedServices,
            },
            currentUser: auth.currentUser,
        })
        setLoading(false)
        dispatch(closeModal('stampInvoiceModalVisible'))
        dispatch(
            setElementSelected({
                element: 'invoice',
                data: null,
            }),
        )

        dispatch(
            setData({
                item: 'testmode',
                data: false,
            }),
        )

        navigate('/invoices?page=1&ps=10&subtab=overview')
    }
    return (
        <Modal
            title={null}
            width={window.innerWidth > 768 ? '70%' : '100%'}
            style={{ top: 20 }}
            open={stampInvoiceModalVisible}
            onCancel={() => {
                dispatch(closeModal('stampInvoiceModalVisible'))
                dispatch(
                    setElementSelected({
                        element: 'invoice',
                        data: null,
                    }),
                )
                setLoading(false)
            }}
            footer={null}
        >
            <div style={{ padding: '20px 20px', display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>
                <Typography.Title level={5} style={{ margin: 0, padding: 0 }}>
                    Timbrar factura {invoice?.uuid}
                </Typography.Title>
                <Typography.Text type="secondary">
                    Puedes modificar la serie y el número de folio antes de timbrar
                </Typography.Text>
                <Form
                    form={form}
                    layout="vertical"
                    style={{ marginTop: '20px' }}
                    onFinish={(values) => {
                        handleInvoice({
                            ...invoice,
                            series: values.series,
                            folio_number: Number(values.folio_number),
                            items: invoice.items,
                            internalItems: invoice.internalItems,
                            payment_form: values.payment_form,
                            payment_method: values.payment_method,
                        })
                    }}
                >
                    <Row gutter={12}>
                        <Col span={12}>
                            <TextInput
                                initialValue={invoice?.series}
                                name="series"
                                label="Serie"
                                rules={[{ required: true, message: 'Por favor escribe la serie de la factura' }]}
                            />
                        </Col>
                        <Col span={12}>
                            <NumberInput
                                initialValue={invoice?.folio_number}
                                name="folio_number"
                                label="Folio"
                                min={0}
                                max={100000000000}
                                step={1}
                                rules={[{ required: true, message: 'Por favor escribe el folio de la factura' }]}
                            />
                        </Col>
                    </Row>
                    <SelectInput
                        label="Método de pago"
                        defaultValue={invoice?.payment_method ?? 'PUE'}
                        name="payment_method"
                        options={[
                            { label: 'Pago en una sola exhibición', value: 'PUE' },
                            { label: 'Pago en parcialidades', value: 'PPD' },
                        ]}
                        placeholder="Método de pago"
                        rules={[{ required: true, message: 'Por favor añade este campo' }]}
                    />
                    <SelectInput
                        defaultValue={invoice?.payment_form ?? '01'}
                        name="payment_form"
                        label="Forma de pago"
                        options={paymentForms}
                        rules={[{ required: true, message: 'Por favor añade este campo' }]}
                        placeholder="Forma de pago"
                    />
                    <ItemsSelection
                        // incomingServices={invoice?.internalItems}
                        disabled={false}
                        hideTitle={true}

                        // changeIncoming={(incoming: any) => {
                        //     dispatch(setElementSelected({
                        //         element: 'invoice',
                        //         data: { ...invoice, internalItems: incoming }
                        //     }))
                        // }}
                    />

                    {/* <ProductAndServices data={invoice?.items} /> */}
                    <Row justify={'center'}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ marginTop: '20px' }}
                            disabled={loading ? true : false}
                            loading={loading}
                        >
                            {loading ? 'Timbrando' : 'Timbrar'}
                        </Button>
                    </Row>
                </Form>
            </div>
        </Modal>
    )
}

export default StampInvoiceModal
