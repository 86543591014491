import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetClients } from '../../context/databaseContextHelpers'
import { SearchAutocomplete } from '../Forms/Inputs'
import { Form, Popover, Row, Tag, Typography } from 'antd'
import { doc, getDoc, getFirestore } from 'firebase/firestore'
import { setData, setElementSelected, updateData } from '../../context/dataSlice'
import { openModal } from '../../context/modalsSlice'
import { ClientDef, clientSet } from '../../../interfaces/clientDef'
import { Buildings, EnvelopeSimple, IdentificationBadge, Pencil, User, X } from '@phosphor-icons/react'
import { tagsColors } from '../Forms/TagsColors'
import { addParamToUrl } from '../../functions/UrlParams'

interface Props {
    defaultClient?: any
    onClientSelect?: any
}

const SelectClientCard: React.FC<Props> = ({ defaultClient, onClientSelect }) => {
    const dispatch = useDispatch()
    const { clients, client, clientsLoaded } = useSelector((state: any) => state.data)
    const { team } = useSelector((state: any) => state.team)
    const [loading, setLoading] = useState<string[]>([])

    const [search, setSearch] = useState(
        defaultClient ? clients.find((c: any) => c.id === defaultClient)?.id ?? '' : '',
    )

    const [form] = Form.useForm()

    // useEffect(() => {
    //     if (clients.length <= 0 && !clientsLoaded) {
    //         setLoading([...loading, 'initialData'])
    //         GetClients(dispatch, team.id, (incomingClients) => {

    //             if (defaultClient) {
    //                 const def = defaultClient ? incomingClients.find((c: any) => c.id === defaultClient) : null

    //                 console.log('DEF!!', def)
    //                 if (!def) return
    //                 setSearch(def?.id ?? '')
    //                 dispatch(setElementSelected({ data: def, item: 'client' }))
    //                 form.setFieldsValue({
    //                     clientSelect: {
    //                         label: def?.name ?? def?.email ?? def?.id,
    //                         value: def?.name ?? def?.email ?? def?.id,
    //                         key: def?.id
    //                     }
    //                 })
    //             }
    //         })
    //     } else {
    //         if (client) {
    //             form.setFieldsValue({
    //                 clientSelect: {
    //                     label: client?.name ?? client?.email ?? client?.id,
    //                     value: client?.name ?? client?.email ?? client?.id,
    //                     key: client?.id
    //                 }
    //             })
    //         }
    //         setLoading(loading.filter(l => l !== 'initialData'))
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [clients])

    const SetDefault = ({ incomingClients }: { incomingClients?: ClientDef[] }) => {
        if (defaultClient) {
            const def = defaultClient ? (incomingClients ?? clients).find((c: any) => c.id === defaultClient) : null

            if (!def) return
            setSearch(def?.id ?? '')
            dispatch(setElementSelected({ data: def, item: 'client' }))
            form.setFieldsValue({
                clientSelect: {
                    label: def?.name ?? def?.email ?? def?.id,
                    value: def?.name ?? def?.email ?? def?.id,
                    key: def?.id,
                },
            })
        }
    }

    useEffect(() => {
        if (clients.length <= 0 && !clientsLoaded) {
            setLoading([...loading, 'initialData'])
            GetClients(dispatch, team.id, (incomingClients) => {
                SetDefault({ incomingClients })
            })

            if (client) {
                form.setFieldsValue({
                    clientSelect: {
                        label: client?.name ?? client?.email ?? client?.id,
                        value: client?.name ?? client?.email ?? client?.id,
                        key: client?.id,
                    },
                })
                // if (onClientSelect) {
                //     onClientSelect(client)
                // }
            } else form.resetFields()
        } else {
            setLoading(loading.filter((l) => l !== 'initialData'))
            SetDefault({})
            if (client && client?.id) {
                form.setFieldsValue({
                    clientSelect: {
                        label: client?.name ?? client?.email ?? client?.id,
                        value: client?.name ?? client?.email ?? client?.id,
                        key: client?.id,
                    },
                })
                // if (onClientSelect) {
                //     onClientSelect(client)
                // }
            } else form.resetFields()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clients, client])

    const SelectedClientPopover = () => {
        const clientWithDef = clientSet(client)

        const country =
            typeof clientWithDef?.address?.country === 'string'
                ? clientWithDef?.address?.country
                : clientWithDef?.address?.country?.label ?? 'Sin dirección'
        return (
            <div className="d-flex flex-column" style={{ minWidth: '200px' }}>
                <Row style={{ width: '100%' }}>
                    <div
                        className="d-flex flex-column"
                        style={{
                            justifyContent: 'start',
                            width: '100%',
                        }}
                    >
                        <Row
                            align="top"
                            justify="space-between"
                            style={{ paddingBottom: '5px', marginBottom: '8px', borderBottom: '1px solid #D8DAE5' }}
                        >
                            <Typography.Text
                                onClick={() => {
                                    navigator.clipboard.writeText(clientWithDef?.id ?? '')
                                }}
                                className="smallparagraphbold"
                            >
                                {clientWithDef?.name} / {clientWithDef?.company}
                            </Typography.Text>
                            {/* <X size={15} style={{ marginLeft: '5px' }} weight='regular' className='iconDanger clickable' onClick={() => {
                                dispatch(setElementSelected({ data: null, item: 'client' }))
                                setSearch('')
                                form.resetFields()
                            }} /> */}
                            <Pencil
                                size={15}
                                style={{ marginLeft: '5px' }}
                                weight="regular"
                                className="iconColor clickable"
                                onClick={() => {
                                    dispatch(openModal('createClientModalVisible'))
                                    dispatch(
                                        setData({
                                            item: 'client',
                                            data: client,
                                        }),
                                    )
                                }}
                            />
                        </Row>

                        {clientWithDef.email && (
                            <Row align="middle">
                                <EnvelopeSimple
                                    className="icon"
                                    size={12}
                                    style={{ marginRight: '5px' }}
                                    weight="regular"
                                />
                                <Typography.Text className="smallparagraph">{clientWithDef?.email}</Typography.Text>
                            </Row>
                        )}
                        {clientWithDef?.rfc && (
                            <Row style={{ marginTop: '3px' }}>
                                <IdentificationBadge
                                    className="icon"
                                    size={12}
                                    style={{ marginRight: '5px' }}
                                    weight="regular"
                                />
                                <Typography.Text className="smallparagraph descriptions">
                                    {clientWithDef?.legalName} / {clientWithDef?.rfc ?? 'Sin RFC'}
                                </Typography.Text>
                            </Row>
                        )}
                        {country && (
                            <Row style={{ marginTop: '3px' }}>
                                <Buildings className="icon" size={12} style={{ marginRight: '5px' }} weight="regular" />
                                <Typography.Text className="smallparagraph descriptions">
                                    {country ?? 'Sin dirección'}
                                </Typography.Text>
                            </Row>
                        )}
                        <Row style={{ marginTop: '10px' }}>
                            {/* <Typography.Text className='smallparagraph' style={{ marginRight: '10px' }}>Perfil de facturación</Typography.Text> */}
                            <Tag
                                color={clientWithDef?.profile?.color}
                                style={{ color: clientWithDef?.profile?.textColor }}
                            >
                                {clientWithDef?.profile?.name}
                            </Tag>
                        </Row>
                    </div>
                </Row>
            </div>
        )
    }

    const ClientNotFound = () => {
        return (
            <div className="d-flex flex-column" style={{ padding: '5px 10px' }}>
                <Typography.Text className="smallparagraphbold" style={{ marginBottom: '5px' }}>
                    {search
                        ? `No encontramos clientes con esta búsqueda "${search}"`
                        : `Busca un cliente por nombre, correo, id`}
                </Typography.Text>
                <Typography.Text type="secondary" className="smallparagraph descriptions">
                    Intenta buscar por nombre o{' '}
                    <span
                        className="primary smallparagraphbold"
                        style={{
                            cursor: 'pointer',
                            marginLeft: '5px',
                        }}
                        onClick={() => {
                            //createClientDrawerVisible
                            dispatch(openModal('createClientModalVisible'))
                            dispatch(setData({
                                item: 'client',
                                data: null,
                            }))
                        }}
                    >
                        crea un nuevo cliente
                    </span>
                </Typography.Text>
            </div>
        )
    }

    const options = clients
        ?.filter((c: any) => {
            if (!c) return false
            if (search?.length <= 0) return false

            const filterValue =
                (c.id ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((search as string).toLowerCase()) ||
                (c.shortUrl ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((search as string).toLowerCase()) ||
                // search by client name
                (c.name ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((search as string).toLowerCase()) ||
                (c?.email ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((search as string).toLowerCase()) ||
                (c?.phone ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((search as string).toLowerCase()) ||
                (c?.company ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((search as string).toLowerCase()) ||
                (c?.legalName ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((search as string).toLowerCase()) ||
                (c?.rfc ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((search as string).toLowerCase()) ||
                (c?.legal_name ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((search as string).toLowerCase())

            return filterValue
        })
        ?.map((c: any) => {
            return {
                value: c?.name ?? c?.email ?? c?.id,
                key: c?.id,
                label: (
                    <Row align="middle" justify="space-between">
                        <div key={c?.id} className="d-flex flex-column">
                            <Typography.Text style={{ fontSize: '11px' }}>
                                {c?.name ?? c?.email ?? c?.id}
                            </Typography.Text>
                            <Row>
                                {c?.company && (
                                    <Typography.Text
                                        style={{ fontSize: '10px', color: '#cecece', marginRight: '5px' }}
                                        type="secondary"
                                    >
                                        {c?.company} |
                                    </Typography.Text>
                                )}
                                {c?.legal_name && (
                                    <Typography.Text
                                        style={{ fontSize: '10px', color: '#cecece', marginRight: '5px' }}
                                        type="secondary"
                                    >
                                        {c?.legalName} |
                                    </Typography.Text>
                                )}
                                {c?.email && (
                                    <Typography.Text
                                        style={{ fontSize: '10px', color: '#cecece', marginRight: '5px' }}
                                        type="secondary"
                                    >
                                        {c?.email} |
                                    </Typography.Text>
                                )}
                                {c?.rfc && (
                                    <Typography.Text
                                        style={{ fontSize: '10px', color: '#cecece', marginRight: '5px' }}
                                        type="secondary"
                                    >
                                        {c?.rfc ?? 'Sin RFC'} |
                                    </Typography.Text>
                                )}
                                {c?.metadata?.stripeId && (
                                    <Typography.Text
                                        style={{ fontSize: '10px', color: '#cecece', marginRight: '5px' }}
                                        type="secondary"
                                    >
                                        {c?.c?.metadata?.stripeId ?? ''}
                                    </Typography.Text>
                                )}
                                {/* <Typography.Text>{c.id}</Typography.Text> */}
                            </Row>
                        </div>
                        <Row align="middle">
                            {/* <Typography.Text className='smallparagraph' style={{ marginRight: '10px' }}>Perfil de facturación</Typography.Text> */}
                            <Tag color={c?.profile?.color} style={{ color: c?.profile?.textColor }}>
                                {c?.profile?.name}
                            </Tag>
                        </Row>
                    </Row>
                ),
            }
        })

    // console.log('CLIENTS', clients, client, search, defaultClient, form.getFieldValue('clientSelect'))

    return (
        <div style={{ width: '100%' }}>
            <Form
                form={form}
                initialValues={{
                    clientSelect: defaultClient
                        ? {
                              label:
                                  clients?.find((c: any) => c.id === defaultClient)?.name ??
                                  clients?.find((c: any) => c.id === defaultClient)?.email ??
                                  clients?.find((c: any) => c.id === defaultClient)?.id,
                              value: clients?.find((c: any) => c.id === defaultClient)?.id,
                              key: clients?.find((c: any) => c.id === defaultClient)?.id,
                          }
                        : {},
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault()
                    }
                }}
            >
                {client?.id ? (
                    <div
                        style={{
                            padding: '7px 10px',
                            border: '1px solid #D8DAE5',
                            borderRadius: '4px',
                        }}
                    >
                        <Row align="middle" justify="space-between">
                            <Popover content={<SelectedClientPopover />}>
                                <Row align="middle" className="clickable">
                                    <User size={13} style={{ marginRight: '3px' }} weight="regular" />
                                    {/* <Typography.Text className='paragraphbold' style={{ margin: 0, padding: 0 }}>{client?.name}</Typography.Text> */}
                                    <Tag
                                        closable
                                        onClose={() => {
                                            dispatch(setElementSelected({ data: null, item: 'client' }))
                                            setSearch('')
                                            form.resetFields()
                                        }}
                                        color={tagsColors.filter((t) => t.name === 'purple')[0].bg}
                                        style={{
                                            color: tagsColors.filter((t) => t.name === 'purple')[0].text,
                                            marginLeft: '5px',
                                        }}
                                    >
                                        {client?.name}
                                    </Tag>
                                </Row>
                            </Popover>
                            <X
                                size={13}
                                style={{ marginRight: '3px' }}
                                weight="regular"
                                className="iconDanger clickable"
                            />
                        </Row>
                    </div>
                ) : (
                    <SearchAutocomplete
                        popovercontent={<SelectedClientPopover />}
                        value={defaultClient ? clients.find((c: any) => c.id === defaultClient)?.name : ''}
                        isLoading={(clients ?? []).length <= 0 && !clientsLoaded}
                        styleProps={{
                            width: '100%',
                        }}
                        notFoundContent={<ClientNotFound />}
                        options={options}
                        name="clientSelect"
                        label={null}
                        placeholder="Buscar cliente"
                        description={''}
                        disabled={clients.length <= 0 && !clientsLoaded}
                        onChange={(v) => {
                            if (v === null || v === '' || v === undefined) {
                                dispatch(setElementSelected({ data: {}, item: 'client' }))
                            }

                            setSearch(v)
                        }}
                        onClear={() => {
                            setSearch('')
                            dispatch(setElementSelected({ data: {}, item: 'client' }))
                        }}
                        onSelect={async (v) => {
                            const lastUpdated = await (await getDoc(doc(getFirestore(), 'clients', v))).data()
                            dispatch(
                                updateData({
                                    item: 'clients',
                                    data: lastUpdated,
                                }),
                            )
                            dispatch(setElementSelected({ data: lastUpdated, item: 'client' }))
                            addParamToUrl('clientId', lastUpdated?.id)
                        }}
                    />
                )}
            </Form>
        </div>
    )
}

export default SelectClientCard
