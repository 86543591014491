import { Space, Typography } from 'antd'
import { TaxElement } from '../../../../interfaces/invoicesDef'
import { Trash } from '@phosphor-icons/react'
import { returnCurrencyValue } from '../../../functions/helpers'

type TypeTaxColumnName = 'type' | 'factor' | 'rate' | 'inclusive' | 'total' | 'actions' | 'withholding' | 'base'

export const TaxesColumns = ({
    record,
    columnName,
    taxes,
    customDelete,
}: {
    record: TaxElement
    columnName: TypeTaxColumnName
    taxes?: any[]
    customDelete?: Function
}) => {
    switch (columnName) {
        case 'type':
            return (
                <>
                    <div>
                        <Typography.Text className="smallparagraphbold primary clickable">
                            {record?.type}
                        </Typography.Text>
                    </div>
                </>
            )
        case 'factor':
            return (
                <Typography.Text className="smallparagraph" style={{ textAlign: 'right' }}>
                    {record.factor}
                </Typography.Text>
            )
        case 'rate':
            return (
                <Typography.Text className="smallparagraph descriptions" style={{ textAlign: 'right' }}>
                    {record.rate}
                </Typography.Text>
            )
        case 'inclusive':
            return (
                <Typography.Text className="smallparagraph descriptions" style={{ textAlign: 'right' }}>
                    {record.inclusive ? 'Incluído' : 'No Incluído'}
                </Typography.Text>
            )
        case 'total':
            return (
                <Typography.Text className="smallparagraphbold descriptions" style={{ textAlign: 'right' }}>
                    -
                </Typography.Text>
            )
        case 'base':
            return (
                <Typography.Text className="smallparagraphbold descriptions" style={{ textAlign: 'right' }}>
                    {returnCurrencyValue(record?.base as any)}
                </Typography.Text>
            )

        case 'withholding':
            return (
                <Typography.Text className="smallparagraph descriptions" style={{ textAlign: 'right' }}>
                    {record.withholding ? 'Retenido' : 'No retenido'}
                </Typography.Text>
            )
        case 'actions':
            return (
                <Space>
                    <Trash
                        size={20}
                        className="icon clickable"
                        onClick={() => {
                            if (customDelete) return customDelete(record)
                        }}
                    />
                </Space>
            )
    }
}
