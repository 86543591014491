import { Card, Divider, Empty, Modal, Row, Tag, Typography } from 'antd'
import SelectClientCard from '../components/Cards/SelectClientCard'
import { useDispatch, useSelector } from 'react-redux'
import { taxRegimes } from '../functions/helpers'
import { useEffect, useState } from 'react'
import { setData } from '../context/dataSlice'

export const SelectClientModal = ({ open, close }: { open: boolean; close: () => void }) => {
    const dispatch = useDispatch()
    const { client } = useSelector((state: any) => state.data)

    const taxSystem = taxRegimes.find((t) => t.value === client?.tax_system)
    const [initialClient, setInitialClient] = useState<any>(null)

    useEffect(() => {
        if (client && !initialClient) {
            setInitialClient(client)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client?.id])

    return (
        <Modal
            open={open}
            okText="Seleccionar cliente"
            onCancel={() => {
                if (initialClient) {
                    console.log('initialClient', initialClient)
                    dispatch(setData({ item: 'client', data: initialClient }))
                }
                close()
            }}
            onOk={() => {
                close()
            }}
        >
            <Typography.Title className="bigparagraph" level={5} style={{ margin: '5px 0px 0px 0px' }}>
                Selecciona el nuevo cliente
            </Typography.Title>
            <div style={{ margin: '15px 0px 20px 0px' }}>
                <SelectClientCard defaultClient={null} />
                <Card style={{ marginTop: '10px', padding: '0px' }}>
                    {client ? (
                        <div>
                            <Row align="middle" justify="space-between">
                                <Typography.Text className="smallparagraphbold" style={{ margin: 0 }}>
                                    {client?.legal_name}
                                </Typography.Text>
                                <Tag color={client?.profile?.color} style={{ color: client?.profile?.textColor }}>
                                    {client?.profile?.name}
                                </Tag>
                            </Row>
                            <Divider style={{ margin: '5px 0px 0px 0px' }} />
                            <Row align="middle" justify="space-between" style={{ margin: '10px 0px 5px 0px' }}>
                                <Typography.Text className="smallparagraph" style={{}}>
                                    {(taxSystem?.label ?? '')?.length < 50
                                        ? taxSystem?.label
                                        : taxSystem?.label.substring(0, 50) + '...'}
                                </Typography.Text>
                                <Typography.Text className="smallparagraphbold" style={{}}>
                                    {client?.rfc}
                                </Typography.Text>
                            </Row>
                            <Row align="middle" justify="space-between">
                                <Typography.Text className="smallparagraph" type="secondary" style={{}}>
                                    {client?.email}
                                </Typography.Text>
                                <Typography.Text className="smallparagraph" style={{}}>
                                    {client?.address?.street} {client?.address?.city}{' '}
                                    {client?.address?.zip || client?.zip}{' '}
                                    {typeof client?.address?.country === 'string'
                                        ? client?.address?.country
                                        : client?.address?.country?.label ?? ''}
                                </Typography.Text>
                            </Row>
                        </div>
                    ) : (
                        <Empty description="Ningun cliente seleccionado" />
                    )}
                </Card>
            </div>
        </Modal>
    )
}
