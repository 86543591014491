import { Card, Col, DatePicker, Form, Row, Skeleton, Statistic, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ArrowDown, ArrowUp, CalendarBlank, CurrencyDollar, ProhibitInset } from '@phosphor-icons/react'
// import { getAggregateFromServer, getCountFromServer, getFirestore, sum } from 'firebase/firestore';
import moment from 'moment'
import dayjs from 'dayjs'
// import { SelectInput } from '../Forms/Inputs';
// import { returnCurrencyValue } from '../../functions/helpers';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'
// import { pendingPaymentsQuery, succeededPaymentsQuery } from '../../queries/queries';
import { collection, getCountFromServer, getFirestore, query, where } from 'firebase/firestore'
import CountDataWrapper from '../../datawrappers/countsDataWrapper'
import SumDataWrapper from '../../datawrappers/sumDataWrapper'
import { returnCurrencyValue } from '../../functions/helpers'
import PendingPaymentsTable from '../Tables/PendingPaymentsTable'

interface PaymentsActionsInsightsProps {
    totalPendingCount: number
}

const PaymentsActionsInsights: React.FC<PaymentsActionsInsightsProps> = ({ totalPendingCount }) => {
    // const dispatch = useDispatch()
    const { team } = useSelector((state: any) => state.team)
    const { testmode } = useSelector((state: any) => state.data)

    const [form] = Form.useForm()
    //Periods to filter by
    const [period, setperiod] = useState(moment().startOf('month').valueOf())
    const [periodEnd, setperiodEnd] = useState(moment().endOf('month').valueOf())
    // const [pastPeriods, setPastPeriods] = useState<any>({ start: 0, end: 0 })

    const [currency /* setcurrency*/] = useState('MXN')
    const [reload, setReload] = useState(false)
    const [paymentVerificationCount, setPaymentVerificationCount] = useState({
        count: 0,
        loaded: false,
        visible: false,
        period: period,
        periodEnd: periodEnd,
        testmode: testmode,
        team: team?.id,
    })

    const [, /*loadedCounts*/ setloadedCounts] = useState(false)

    useEffect(() => {
        if (
            !paymentVerificationCount.loaded ||
            paymentVerificationCount?.testmode !== testmode ||
            paymentVerificationCount?.period !== period ||
            paymentVerificationCount?.periodEnd !== periodEnd ||
            paymentVerificationCount?.team !== team?.id
        ) {
            const q = query(
                collection(getFirestore(), 'payments'),
                where('team', '==', team?.id),
                where('status', '==', 'review_requested'),
                where('livemode', '==', !testmode),
            )
            getCountFromServer(q)
                .then((res) => {
                    setPaymentVerificationCount({
                        count: res.data().count,
                        loaded: true,
                        visible: paymentVerificationCount.visible,
                        period: period,
                        periodEnd: periodEnd,
                        testmode: testmode,
                        team: team?.id,
                    })
                })
                .catch((err) => {
                    setPaymentVerificationCount({
                        count: 0,
                        loaded: true,
                        visible: paymentVerificationCount.visible,
                        period: period,
                        periodEnd: periodEnd,
                        testmode: testmode,
                        team: team?.id,
                    })
                })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period, periodEnd, paymentVerificationCount.loaded, team?.id, testmode])

    useEffect(() => {
        return () => {
            setReload(true)
            new Promise((resolve) => setTimeout(resolve, 100)).then(() => {
                setReload(false)
            })
        }
    }, [testmode, period, periodEnd, team?.id])

    const PeriodSelector = () => {
        return (
            <Form.Item name="dates" style={{ margin: 0, padding: 0 }}>
                <DatePicker.RangePicker
                    style={{ width: '100%' }}
                    suffixIcon={<CalendarBlank size={17} weight="regular" className="icon" />}
                    format="DD MMM YYYY"
                    onChange={(a: any) => {
                        if (!a) return
                        setperiod(a[0].valueOf())
                        setperiodEnd(a[1].valueOf())
                        setloadedCounts(false)
                    }}
                    presets={[
                        {
                            label: `${moment().startOf('month').format('D')} al ${moment().endOf('month').format('D MMMM YYYY')}`,
                            value: [dayjs().startOf('month'), dayjs().endOf('month')],
                        },
                        {
                            label: `${moment().add(-1, 'month').startOf('month').format('D')} al ${moment().add(-1, 'month').endOf('month').format('D MMMM YYYY')}`,
                            value: [dayjs().add(-1, 'month').startOf('month'), dayjs().add(-1, 'month').endOf('month')],
                        },
                        { label: '60 días', value: [dayjs().add(-60, 'days').startOf('day'), dayjs().endOf('day')] },
                        { label: '90 días', value: [dayjs().add(-90, 'days').startOf('day'), dayjs().endOf('day')] },
                        { label: 'Año actual', value: [dayjs().startOf('year'), dayjs().endOf('day')] },
                        {
                            label: 'Año pasado',
                            value: [dayjs().add(-1, 'year').startOf('year'), dayjs().add(-1, 'year').endOf('year')],
                        },
                    ]}
                />
            </Form.Item>
        )
    }

    const HeaderPage = () => {
        return (
            <Col xs={24} style={{ marginBottom: '15px' }}>
                <div
                    style={{
                        backgroundColor: '#F9FAFC',
                        width: '100%',
                        padding: '15px',
                        borderRadius: '10px',
                    }}
                >
                    <Row justify="space-between">
                        <Row align="middle">
                            {/* {!loadedCounts && <MetroSpinner size={12} color="#8666FF" />} */}
                            <Typography.Text className="smallparagraphbold descriptions" style={{ marginLeft: '10px' }}>
                                Información del periodo {moment(period).format('D MMM YYYY ')} -{' '}
                                {moment(periodEnd).format('D MMM YYYY ')}
                            </Typography.Text>
                        </Row>

                        <Form
                            layout="vertical"
                            form={form}
                            initialValues={{
                                dates: [dayjs(period), dayjs(periodEnd)],
                                currency: currency ?? team?.currency ?? 'MXN',
                            }}
                        >
                            <Row>
                                <PeriodSelector />
                                {/* <SelectInput
                                placeholder='Moneda'
                                onChange={(e: any) => {
                                    setcurrency(e)
                                    setloadedCounts(false)
                                }}
                                customStyle={{ padding: 0, margin: 0, marginLeft: '5px', maxWidth: '100px' }}
                                name="currency" options={currecies} /> */}
                            </Row>
                        </Form>
                    </Row>
                </div>
            </Col>
        )
    }

    const PendingPaymentsHeader = () => {
        return (
            <Col xs={24}>
                <div style={{ backgroundColor: '#F9FAFC', padding: '10px' }}>
                    <Row justify="space-between">
                        <Row align="middle">
                            <Typography.Text className="smallheader" style={{ marginLeft: '10px' }}>
                                Pagos pendientes por revisar
                            </Typography.Text>
                            <Typography.Text
                                className="smallheader descriptions"
                                style={{ marginLeft: '10px', color: '#8666FF' }}
                            >
                                {paymentVerificationCount?.count}
                            </Typography.Text>
                        </Row>
                    </Row>
                </div>
            </Col>
        )
    }

    const PendingPaymentsReview = () => {
        if (paymentVerificationCount.count <= 0 && totalPendingCount <= 0) return <></>
        return (
            <Col xs={24}>
                <PendingPaymentsTable startPeriod={null} endPeriod={null} max={10} />
            </Col>
        )
    }

    const currentPeriod = period
    const currentPeriodEnd = periodEnd
    const days = moment(currentPeriodEnd).diff(moment(currentPeriod), 'days')
    const pastPeriod = moment(currentPeriod).add(-days, 'days').valueOf()
    const pastPeriodEnd = moment(currentPeriod).valueOf()

    return (
        <ErrorBoundary>
            <Row style={{ width: '100%' }}>
                <HeaderPage />
                <Col xs={24} style={{ marginBottom: '25px' }}>
                    <Row wrap gutter={12}>
                        <Card
                            bordered={false}
                            size="small"
                            style={{ marginRight: '5px', marginTop: '10px', padding: '0px' }}
                        >
                            {!reload && (
                                <CountDataWrapper
                                    query={query(
                                        collection(getFirestore(), 'payments'),
                                        where('team', '==', team.id),
                                        where('timestamp', '>=', period),
                                        where('timestamp', '<=', periodEnd),
                                        where('livemode', '==', !testmode),
                                    )}
                                    pastPeriodQuery={query(
                                        collection(getFirestore(), 'payments'),
                                        where('team', '==', team.id),
                                        where('timestamp', '>=', pastPeriod),
                                        where('timestamp', '<=', pastPeriodEnd),
                                        where('livemode', '==', !testmode),
                                    )}
                                    clientId=""
                                    loadingComponent={<Skeleton.Input active={true} size="small" />}
                                    renderData={(count: number, pastCount: number | undefined) => {
                                        const diff = count - (pastCount ?? 0)
                                        const percentage = (diff / (pastCount ?? 0)) * 100
                                        return (
                                            <>
                                                <Statistic
                                                    title={
                                                        <Typography.Text className="smallparagraphbold">
                                                            Pagos solicitados
                                                        </Typography.Text>
                                                    }
                                                    value={count}
                                                    precision={0}
                                                    valueStyle={{ color: '#989BAB' }}
                                                    prefix={
                                                        percentage > 0 ? (
                                                            <ArrowUp weight="regular" className="icon" size="15" />
                                                        ) : (
                                                            <ArrowDown weight="regular" className="danger" size="15" />
                                                        )
                                                    }
                                                    suffix=""
                                                />
                                                <Tooltip title={`Periodo anterior: ${pastCount ?? 0}`}>
                                                    <Typography.Text
                                                        className={
                                                            'smallparagraph ' +
                                                            (percentage > 0 ? ' descriptions' : ' danger')
                                                        }
                                                        style={{ marginLeft: '10px' }}
                                                    >
                                                        {percentage > 0 ? '+' : ''}
                                                        {percentage.toFixed(2)}%
                                                    </Typography.Text>
                                                </Tooltip>
                                            </>
                                        )
                                    }}
                                />
                            )}
                        </Card>

                        <Card size="small" bordered={false} style={{ marginRight: '5px', marginTop: '10px' }}>
                            {!reload && (
                                <SumDataWrapper
                                    sumOnKey="translatedAmountToMXN"
                                    query={query(
                                        collection(getFirestore(), 'payments'),
                                        where('team', '==', team.id),
                                        where('status', '==', 'succeeded'),
                                        where('timestamp', '>=', period),
                                        where('timestamp', '<=', periodEnd),
                                        where('livemode', '==', !testmode),
                                    )}
                                    pastPeriodQuery={query(
                                        collection(getFirestore(), 'payments'),
                                        where('team', '==', team.id),
                                        where('status', '==', 'succeeded'),
                                        where('timestamp', '>=', pastPeriod),
                                        where('timestamp', '<=', pastPeriodEnd),
                                        where('livemode', '==', !testmode),
                                    )}
                                    clientId=""
                                    loadingComponent={<Skeleton.Input active={true} size="small" />}
                                    renderData={(count: number, pastSum: number | undefined) => {
                                        const diff = count - (pastSum ?? 0)
                                        const percentage = (diff / (pastSum ?? 1)) * 100

                                        return (
                                            <>
                                                <Statistic
                                                    title={
                                                        <Typography.Text className="smallparagraphbold">
                                                            Total Cobrado
                                                        </Typography.Text>
                                                    }
                                                    value={count / 100}
                                                    precision={2}
                                                    valueStyle={{ color: '#989BAB' }}
                                                    prefix={
                                                        <CurrencyDollar weight="regular" className="icon" size="15" />
                                                    }
                                                    suffix=""
                                                />
                                                <Tooltip
                                                    title={`Periodo anterior: ${returnCurrencyValue((pastSum ?? 0) / 100)}`}
                                                >
                                                    <Typography.Text
                                                        className={
                                                            'smallparagraph ' +
                                                            (percentage > 0 ? ' descriptions' : ' danger')
                                                        }
                                                        style={{ marginLeft: '10px' }}
                                                    >
                                                        {percentage > 0 ? '+' : ''}
                                                        {isNaN(percentage) ? '' : `${percentage.toFixed(2)}%`}
                                                    </Typography.Text>
                                                </Tooltip>
                                            </>
                                        )
                                    }}
                                />
                            )}
                        </Card>
                        <Card size="small" bordered={false} style={{ marginRight: '5px', marginTop: '10px' }}>
                            {!reload && (
                                <CountDataWrapper
                                    query={query(
                                        collection(getFirestore(), 'payments'),
                                        where('team', '==', team.id),
                                        where('refunded', '==', true),
                                        where('timestamp', '>=', period),
                                        where('timestamp', '<=', periodEnd),
                                        where('livemode', '==', !testmode),
                                    )}
                                    clientId=""
                                    loadingComponent={<Skeleton.Input active={true} size="small" />}
                                    renderData={(count: number) => {
                                        return (
                                            <Statistic
                                                title={
                                                    <Typography.Text className="smallparagraphbold">
                                                        Pagos reembolsados
                                                    </Typography.Text>
                                                }
                                                value={count}
                                                precision={0}
                                                valueStyle={{ color: '#989BAB' }}
                                                prefix={<ProhibitInset weight="regular" className="danger" size="15" />}
                                                suffix=""
                                            />
                                        )
                                    }}
                                />
                            )}
                        </Card>
                    </Row>
                </Col>

                <PendingPaymentsHeader />
                <PendingPaymentsReview />
            </Row>
        </ErrorBoundary>
    )
}

export default PaymentsActionsInsights
