import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import PricingCardDeck from '../../components/ComponentIndividual/PricingCardDeck'
import { preserveQueryParams } from '../../functions/helpers'
import Login from '../../new-onboarding/auth/Login'
import Signup from '../../new-onboarding/auth/Signup'

const PreserveQuery = () => {
    const location = useLocation()
    const currentSearchParams = new URLSearchParams(location.search)
    const loginPath = preserveQueryParams('/login', currentSearchParams)

    return <Navigate to={loginPath} replace />
}

const PublicRoutes = () => {
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/register" element={<Signup />} />
            <Route path="/memberships" element={<PricingCardDeck />} />

            {/* Navigate to login */}
            <Route path="/*" element={<PreserveQuery />} />
        </Routes>
    )
}

export default PublicRoutes
