import speiLogo from '../../assets/images/speiSquare.png'
import stripeLogo from '../../assets/images/stripeSquare.png'

export interface PaymentMethod {
    details: string
    id: string
    logo: string
    manualConfirmation: boolean
    name: string
    requiredKey?: string
    secondaryLogo?: string
    description?: string
    action?: string
    processor?: string
    processorLogo?: string
}

export const availableMethods: PaymentMethod[] = [
    {
        details: 'Paga con tu tarjeta de crédito o débito',
        id: 'card',
        logo: 'https://pro-gigstack.s3.us-east-2.amazonaws.com/icons/Cards2.svg',
        manualConfirmation: false,
        name: 'Tarjeta de crédito y débito',
        requiredKey: 'paymentIntent',
        description: 'Tu cliente podrá pagar con tarjeta de crédito o débito',
        processor: 'stripe',
        processorLogo: stripeLogo,
    },
    {
        details: 'Pago en efectivo en tiendas OXXO',
        id: 'oxxo',
        logo: 'https://js.stripe.com/v3/fingerprinted/img/oxxo-96b6ab36d23607973cb466bec56d187b.svg',
        manualConfirmation: false,
        name: 'OXXO',
        requiredKey: 'paymentIntent',
        description: 'Tu cliente podrá pagar en efectivo en tiendas OXXO',
        processor: 'stripe',
        processorLogo: stripeLogo,
    },
    {
        details: 'Pago con transferencia desde tu cuenta bancaria',
        id: 'bank',
        logo: speiLogo, //"https://pro-gigstack.s3.us-east-2.amazonaws.com/icons/+bank.png",
        manualConfirmation: true,
        name: 'SPEI',
        description: 'Tu cliente podrá pagar con transferencia bancaria a la cuenta que configuraste en Gigstack.',
        action: 'Ver mi información bancaria',
        processor: 'spei',
        processorLogo: speiLogo,
    },
    {
        details: 'Pago con transferencia desde tu cuenta bancaria',
        id: 'customer_balance',
        logo: speiLogo, //"https://pro-gigstack.s3.us-east-2.amazonaws.com/icons/+bank.png",
        manualConfirmation: true,
        name: 'SPEI (Stripe)',
        description: 'Tu cliente podrá pagar con transferencia bancaria y se acreditará automáticamente',
        action: 'Ver cuenta bancaria de Stripe',
        processor: 'stripe',
        processorLogo: stripeLogo,
    },
]
