import { Row, Space, Tooltip, Typography } from 'antd'
import { getItemsAmounts } from '../../../functions/helpers'
import moment from 'moment'
import { CaretRight, Pencil, ShootingStar, UserPlus } from '@phosphor-icons/react'
import { ElementRelations } from '../../Relations/ElementRelations'
import { ReceiptStatusTag } from '../../UI/ElementStatus'
import { OptimizedReceiptDef, ReceiptDef } from '../../../../interfaces/receiptsDef'
import ReceiptActions from '../../ElementActions/ReceiptsActions'
import { SelectInput } from '../../Forms/Inputs'
import { paymentForms } from '../../../datasets/Fiscal'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { useDispatch } from 'react-redux'
import { closeModal, openModal } from '../../../context/modalsSlice'
import { setData } from '../../../context/dataSlice'
import { ElementFromTag } from '../../UI/ElementFrom'
import { CopyableElementId } from '../../UI/CopyableElementId'

type TypeReceiptColumnName =
    | 'id'
    | 'amount'
    | 'clientInfo'
    | 'clientEmail'
    | 'rfc'
    | 'status'
    | 'timestamp'
    | 'from'
    | 'invoiceType'
    | 'series'
    | 'relations'
    | 'actions'
    | 'paymentMethod'
    | 'validUntil'
    | any

export const ReceiptsColumns = ({
    record,
    columnName,
    navigate,
    from
}: {
    record: ReceiptDef | OptimizedReceiptDef
    columnName: TypeReceiptColumnName
    navigate?: Function
    from?: string
}) => {
    const dispatch = useDispatch()

    switch (columnName) {
        case 'id':
            return <Typography.Link>{record.id}</Typography.Link>
        case 'amount':
            return (
                <Row justify="end">
                    <Typography.Text className="p-base-regular">
                        {getItemsAmounts(record.items, false).totalString} {record.currency.toLocaleUpperCase()}
                    </Typography.Text>
                </Row>
            )
        case 'timestamp':
            return (
                <Space direction="vertical" size={1}>
                    <Typography.Text className="p-base-regular">
                        {moment(record.timestamp).format('DD/MM/YY')}
                    </Typography.Text>
                    <Typography.Text className="p-base-regular">
                        {moment(record.timestamp).format('HH:mm')}
                    </Typography.Text>
                </Space>
            )
        case 'validUntil':
            return record.status === 'completed' ? (
                <></>
            ) : (
                <Space direction="vertical" size={1}>
                    <Typography.Text className="p-base-regular">
                        {moment(record.validUntil).format('DD/MM/YY')}
                    </Typography.Text>
                    <Typography.Text className="p-base-regular">
                        {moment(record.validUntil).format('HH:mm')}
                    </Typography.Text>
                </Space>
            )
        case 'clientInfo':
            const showAddClient = !record.clientId
            const name =
                record?.client?.legal_name ||
                record?.client?.legalName ||
                record?.client?.name ||
                record?.client?.company ||
                record?.client?.email ||
                'Sin nombre'
            const nameTruncated = (name ?? '')?.length > 30 ? name?.substring(0, 30) + '...' : name
            return (
                <Space direction="vertical" size={0}>
                    <Row>
                        <Tooltip title={name}>
                            <Typography.Text
                                className="p-base-regular neutral-1 clickable"
                                style={{ margin: 0, padding: 0, flex: 1 }}
                                onClick={navigate ? () => navigate(`/receiptDetails?id=${record.id}`) : () => {}}
                            >
                                {nameTruncated.toUpperCase()}
                            </Typography.Text>
                        </Tooltip>
                        {showAddClient && (
                            <Tooltip title="Asignar cliente">
                                <UserPlus
                                    onClick={() => {
                                        dispatch(
                                            setData({
                                                item: 'assignTo',
                                                data: { ...record, collection: 'receipts' },
                                            }),
                                        )
                                        dispatch(openModal('assignClientModalVisible'))
                                    }}
                                    size={18}
                                    style={{ marginLeft: '10px' }}
                                    className="icon clickable"
                                />
                            </Tooltip>
                        )}
                        {!record?.livemode && (
                            <Tooltip title="Modo prueba">
                                <ShootingStar
                                    size={16}
                                    className="icon"
                                    style={{
                                        marginLeft: '5px',
                                    }}
                                />
                            </Tooltip>
                        )}
                    </Row>
                    <Typography.Text ellipsis className="p-base-regular" style={{ fontSize: '10px' }}>
                        {record?.client?.name}
                    </Typography.Text>
                    <Typography.Text
                        ellipsis
                        className="p-base-regular"
                        style={{ fontSize: '10px' }}
                        editable={{
                            icon: <Pencil size={10} />,
                            onChange: async (e) => {
                                let toUpdate = {}
                                const value = e.replace('Order Id: ', '')
                                toUpdate = { metadata: { ...record.metadata, orderId: value } }

                                if (record.whmcsInvoiceId) {
                                    const value = e.replace('WHMCS: ', '')
                                    toUpdate = { whmcsInvoiceId: value }
                                }

                                try {
                                    await updateDoc(doc(getFirestore(), 'receipts', record.id), toUpdate)
                                } catch (error) {
                                    console.log(error)
                                }
                            },
                        }}
                    >
                        {record?.whmcsInvoiceId
                            ? `WHMCS: ${record?.whmcsInvoiceId}`
                            : `Order Id: ${record?.metadata?.orderId || ''}`}
                    </Typography.Text>
                    <CopyableElementId id={record?.id} idName="ID" />
                    {/* <CheckIfRelating element={record} collection="receipts" /> */}
                </Space>
            )
        case 'clientEmail':
            return <span>{record.client?.email}</span>
        case 'rfc':
            return <span>{record.client?.rfc}</span>
        case 'from':
            return (
                <Row style={{ margin: 'auto' }}>
                    {/* <Tooltip title={`Recibo creado desde ${record.from ?? 'payment'}`}>
                    <Tag color="#D3D9FF">
                        <Typography.Text className="smallparagraphbold blue" >{readableReceiptSource(record)}</Typography.Text>
                    </Tag>
                </Tooltip> */}
                    <ElementFromTag record={record} recordType="receipt" />
                </Row>
            )
        case 'status':
            return <ReceiptStatusTag record={record} shape="circle" />
        case 'relations':
            return <ElementRelations record={record} elementType="receipts" />
        case 'actions':
            return from === 'typesenseSearch' ? (
                <Tooltip title="Ver detalles">
                    <CaretRight
                        style={{ fontSize: '24px', cursor: 'pointer' }}
                        onClick={() => {
                            navigate && navigate()
                            return dispatch(closeModal('searchResultContainerVisible'))
                        }}
                    />
                </Tooltip>
            ) : (
                <ReceiptActions receipt={record} showFull={false} hide={false} />
            )

        case 'paymentMethod':
            return (
                <SelectInput
                    disabled={record.status === 'completed'}
                    onChange={async (v: any) => {
                        await updateDoc(doc(getFirestore(), 'receipts', record.id), {
                            payment_form: v,
                        })
                    }}
                    customStyle={{ margin: 0, padding: 0 }}
                    defaultValue={record.payment_form}
                    options={paymentForms}
                    name="paymentMethod"
                />
            )

        default:
            return <></>
    }
}
