import { Col, Row, Typography } from 'antd'
import type { NoticeCard as NoticeCardType } from '../../customHooks/useNoticeBoard'
import moment from 'moment' 
import { useDispatch } from 'react-redux'
import { openModal } from '../../context/modalsSlice'

const CsdCardDescription = ({ card }: { card: NoticeCardType }) => {
    const firstHalf = card.description.split('.')[0]
    const secondHalf = card.description.split('.')[1]

    const dispatch = useDispatch()

    return (
        <Row style={{ paddingBottom: '5px' }}>
            <Typography.Text className="p-base-regular">
                <span>
                    {firstHalf} <b>&nbsp;{moment(card.expirationDate).format('dddd D MMMM')}</b>
                </span>
            </Typography.Text>
            <Typography.Text className="p-base-regular">
                <span>
                    {secondHalf.split(',')[0]},&nbsp;
                    {/* clickable text */}
                    <Typography
                        className="p-base-bold"
                        style={{ 
                            textDecoration: 'underline',
                            cursor: 'pointer'
                         }}
                        onClick={() => {
                            dispatch(openModal('satModalVisible'))
                        }}
                    >
                        {`${secondHalf.split(',')[1]} →`}
                    </Typography>
                </span>
            </Typography.Text>
        </Row>
    )
}

export default function NoticeCard({ card }: { card: NoticeCardType }) {
    const isCsdCard = card.id === 'csdToExpire' || card.id === 'csdExpired'

    return (
        <div
            className="rounded-md"
            style={{
                width: '50%',
                background: 'var(--primary-8)',
                display: 'flex',
                flexDirection: 'row',
                padding: '10px',
                margin: "10px"
            }}
        >
            <Col style={{ padding: '10px' }}>{card.icon()}</Col>
            <Col>
                <Row style={{ paddingTop: '5px' }}>
                    <Typography.Text className="p-large-bold">{card.title}</Typography.Text>
                </Row>
                {isCsdCard && CsdCardDescription({ card })} 
            </Col>
        </div>
    )
}
