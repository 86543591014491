import { useEffect, useRef, useState } from 'react'
import { Button, Input, InputRef, Space, Table, Tooltip, Typography } from 'antd'
import { query, collection, orderBy, getFirestore, where, getCountFromServer } from 'firebase/firestore'
import { useSelector } from 'react-redux'
import { useFirestoreCollectionData } from 'reactfire'
import { OptimizedReccuringDef, Recurring, RecurringSet } from '../../../interfaces/RecurringDef'
import { RecurringInvoicesColumns } from './Columns/RecurringInvoicesColumns'
import { ColumnType } from 'antd/lib/table'
import { MagnifyingGlass, X } from '@phosphor-icons/react'
import { FilterConfirmProps } from 'antd/es/table/interface'

const RecurringInvoicesTable = ({ max = 10, from, clientId }: { max: number; from?: string; clientId?: string }) => {
    const { testmode } = useSelector((state: any) => state.data)

    // const source = "payments"
    let def: OptimizedReccuringDef | Recurring
    // let defSet = (data: any) => {
    //     return optimizedRecurringDefSet(data)
    // }

    //IMPORTS
    const fs = getFirestore()

    // HOOKS
    //onst navigate = useNavigate()

    //CONTEXTS
    const { team } = useSelector((state: any) => state.team)

    //STATES
    // const [newLoading, setNewLoading] = useState(false)
    const [, /*totalCount*/ setTotalCount] = useState(0)
    // const [pageSize, setPageSize] = useState(10)
    // const [currentPage, setCurrentPage] = useState(1)
    const [items /*setItems*/] = useState<(typeof def)[]>([])
    const [, /*searchText*/ setSearchText] = useState('')
    const [, /*searchedColumn*/ setSearchedColumn] = useState('')
    const searchInput = useRef<InputRef>(null)

    type DataIndex = keyof typeof def

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = (clearFilters: () => void) => {
        clearFilters()
        setSearchText('')
    }

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<typeof def> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{ padding: 8, width: 'auto' }}
                className="d-flex flex-column"
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Tooltip title={`Solo buscará en los pagos que ya se hayan cargado en la lista.`}>
                    <Typography.Text className="smallparagraph descriptions" style={{ marginBottom: '5px' }}>
                        Búsqueda local
                    </Typography.Text>
                </Tooltip>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Typography.Text
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch(selectedKeys as string[], confirm, dataIndex)
                        }}
                        className="smallparagraph descriptions clickable"
                    >
                        Restablecer
                    </Typography.Text>

                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<MagnifyingGlass />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>

                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close()
                        }}
                    >
                        <X className="icon" />
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => {
            return (
                <MagnifyingGlass
                    style={{
                        color: filtered ? '#8666FF' : '#50566B',
                    }}
                    size={16}
                />
            )
        },
        onFilter: (value, record) => {
            return (
                (record[dataIndex] ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                (record.id ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                (record?.client?.company ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                (record?.client?.name ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                (record?.client?.email ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                (record?.client?.phone ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                //search by nanme and company name of the client removing accents
                (
                    record?.client?.company
                        ?.normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .toLowerCase() ?? ''
                ).includes(
                    (value as string)
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .toLowerCase(),
                )
            )
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
            }
        },
        render: (text, record) => <RecurringInvoicesColumns record={record} columnName="client" />,
        // RecurringPaymentsColumns({
        //     record,
        //     columnName: 'client',

        // }),
    })

    //VARIABLES
    let initialQuery: any

    initialQuery = query(
        collection(fs, `recurringEvents`),
        where('team', '==', team?.id ?? ''),
        where('type', '==', 'invoice'),
        where('replicateElement.livemode', '==', !testmode),
        orderBy('timestamp', 'desc'),
    )

    if (from === 'clientsView') {
        initialQuery = query(
            collection(fs, `recurringEvents`),
            where('team', '==', team?.id ?? ''),
            where('type', '==', 'invoice'),
            where('client.id', '==', clientId),
            where('replicateElement.livemode', '==', !testmode),
            orderBy('timestamp', 'desc'),
        )
    }

    //DATA
    const { data } = useFirestoreCollectionData(initialQuery, {
        idField: 'fid',
    })

    //FUNCTIONS
    const getInitialCount = async () => {
        setTotalCount((await getCountFromServer(initialQuery)).data().count)
    }

    // const getNewData = async ({ lastItem: newItem, ps, p }: { lastItem: number, ps: number, p: number }) => {
    //     if (ps * p < items.length) return
    //     setNewLoading(true)
    //     var newItems = await getDocs(query(collection(fs, source), where('team', '==', team?.id ?? ''), orderBy('timestamp', 'desc'), limit((ps * p) - items.length + 1), startAfter(newItem)))
    //     // setLastItem(newItem)

    //     setItems([...items, ...(newItems.docs.map((c) => defSet(c.data())) ?? [])])
    //     setNewLoading(false)
    // }

    //EFFECTS
    useEffect(() => {
        getInitialCount()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, testmode])

    var keysToShow: any = {
        client: 'cliente',
        status: 'estado',
        amount: 'Total',
        create: 'Creado',
        temporality: 'Temporalidad',
        relations: 'Eventos',
        nextRun: 'Siguente evento',
        Acciones: '',
    }

    return (
        <Table
            // rowKey={(rec: any) => rec?.id ?? 'as'}
            //loading={initialLoadStatus === "loading" || newLoading}
            // pagination={{
            //     total: totalCount,
            //     defaultPageSize: pageSize,
            //     defaultCurrent: currentPage,
            //     pageSize: pageSize,
            //     current: currentPage,
            //     onChange(p, ps) {
            //         setPageSize(ps)
            //         setCurrentPage(p)
            //         var allItems = [...data, ...items]
            //         getNewData({ lastItem: allItems[allItems.length - 1]?.timestamp, ps, p })
            //     },

            // }}
            scroll={{ x: 1500 }}
            columns={[
                ...Object.keys(keysToShow).map((k: any) => {
                    let col: any = {
                        title: keysToShow[k],
                        dataIndex: k,
                        render: (text: any, record: typeof def) => (
                            <div key={k} style={{ cursor: 'pointer' }}>
                                {/* {RecurringPaymentsColumns({ record, columnName: k })} */}
                                <RecurringInvoicesColumns record={record} columnName={k} />
                            </div>
                        ),
                    }
                    if (k === 'client') {
                        col.fixed = 'left'
                        col = {
                            ...col,
                            ...getColumnSearchProps(k),
                        }
                    }
                    if (k === 'status') col.fixed = 'left'
                    if (k === 'status') col.width = '7%'
                    if (k === 'Acciones') col.fixed = 'right'
                    if (k === 'Acciones') col.width = '10%'
                    return col
                }),
                // {
                //     title: 'Actions',
                //     dataIndex: 'actions',
                //     render: (text: any, record: typeof def) => <PaymentsActions payment={record} hide={false} />
                // }
            ]}
            dataSource={[...(data ?? [])?.map((d) => RecurringSet(d), ...items)]}
        />
    )
}

export default RecurringInvoicesTable
