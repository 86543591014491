import React from 'react'
import { useSelector } from 'react-redux'
import { getItemsAmounts, returnCurrencyValue } from '../../functions/helpers'
import { Popover, Row, Typography } from 'antd'
import { Question } from '@phosphor-icons/react'

const ProductsServicesTotals = ({ boxed, incomingItems }: { boxed: boolean; incomingItems?: any[] }) => {
    const boxedStyle: React.CSSProperties = boxed
        ? {
              padding: '10px 20px',
              borderRadius: '10px',
              backgroundColor: 'white',
              boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
          }
        : {
              minWidth: '150px',
          }
    const { selectedServices } = useSelector((state: any) => state.data)
    const amounts = getItemsAmounts((incomingItems ?? []).length > 0 ? incomingItems : selectedServices, false)

    const SecondaryElement = ({ title, value, help }: { title: string; value: string; help?: string }) => {
        return (
            <Row justify="space-between" style={{ marginBottom: '5px' }}>
                <Row align="middle">
                    <Typography.Text className="smallparagraph descriptions">{title}</Typography.Text>
                    {help && (
                        <Popover
                            content={
                                <div className="d-flex flex-column" style={{ maxWidth: '200px' }}>
                                    <Typography.Text className="smallparagraphbold">Ayuda</Typography.Text>
                                    <Typography.Text className="smallparagraph" style={{ marginTop: '5px' }}>
                                        {help}
                                    </Typography.Text>
                                </div>
                            }
                        >
                            <Question size={15} style={{ marginLeft: '3px' }} className="icon" weight="regular" />
                        </Popover>
                    )}
                </Row>
                <Typography.Text className="smallparagraph descriptions">{value}</Typography.Text>
            </Row>
        )
    }
    const MainElement = ({ title, value, help }: { title: string; value: string; help?: string }) => {
        return (
            <Row justify="space-between" style={{ marginBottom: '5px' }}>
                <Row align="middle">
                    <Typography.Text className="smallparagraphbold descriptions">{title}</Typography.Text>
                    {help && (
                        <Popover
                            content={
                                <div className="d-flex flex-column" style={{ maxWidth: '200px' }}>
                                    <Typography.Text className="smallparagraphbold">Ayuda</Typography.Text>
                                    <Typography.Text className="smallparagraph" style={{ marginTop: '5px' }}>
                                        {help}
                                    </Typography.Text>
                                </div>
                            }
                        >
                            <Question size={15} style={{ marginLeft: '3px' }} className="icon" weight="regular" />
                        </Popover>
                    )}
                </Row>

                <Typography.Text className="smallparagraphbold descriptions">{value}</Typography.Text>
            </Row>
        )
    }
    return (
        <Row justify="end">
            <div className="d-flex flex-column" style={boxedStyle}>
                <SecondaryElement
                    title="Subtotal"
                    value={amounts.subtotalString}
                    help="El total de la suma de los productos antes de cualquier impuesto."
                />
                <SecondaryElement
                    title="Impuestos"
                    value={returnCurrencyValue(/*amounts.taxesIncluded + */ amounts.taxes)}
                    help="El total de la suma de los impuestos incluidos aplicados a los productos."
                />
                {amounts.retentions > 0 && (
                    <SecondaryElement
                        title="Impuestos retenidos"
                        value={returnCurrencyValue(amounts.retentions)}
                        help="El total de la suma de los impuestos retenidos aplicados a los productos."
                    />
                )}
                {amounts.masterDiscount > 0 && (
                    <SecondaryElement
                        title="Descuentos"
                        value={amounts.masterDiscountString}
                        help="El total de la suma de los descuentos aplicados a los productos."
                    />
                )}
                <MainElement title="Total" value={amounts.totalString} />
            </div>
        </Row>
    )
}

export default ProductsServicesTotals
