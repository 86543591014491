export interface BankDef {
    completed: boolean
    country: string
    holder_name: string
    iban?: string
    swift?: string
    voucherRequired?: boolean
    clabe?: string
}

export const bankDefSet = (object: any) => {
    const bank: BankDef = {
        completed: object['completed'] ?? null,
        country: object['country'] ?? null,
        holder_name: object['holder_name'] ?? null,
        iban: object['iban'] ?? null,
        swift: object['swift'] ?? null,
        voucherRequired: object['voucherRequired'] ?? null,
        clabe: object['clabe'] ?? null,
    }

    return bank
}

export interface FacturapiDef {
    completed: boolean
    accountId: string
    certificate?: object
    certsError?: string
    created_at: string
    customization?: object
    fiel?: object
    id: string
    is_production_ready: boolean
    keys: {
        live_key: string
        test_key: string
    }
    legal: object
    pending_steps: any
    receipts: object
}

export interface FacturapiKeysDef {
    live_key: string
    test_key: string
}

export const facturapiKeysDefSet = (object: any) => {
    const keys: FacturapiKeysDef = {
        live_key: object['live_key'] ?? null,
        test_key: object['test_key'] ?? null,
    }
    return keys
}

export const facturapiDefSet = (object: any) => {
    const facturapi: FacturapiDef = {
        completed: object['completed'] ?? null,
        accountId: object['accountId'] ?? null,
        certificate: object['certificate'] ?? null,
        certsError: object['certsError'] ?? null,
        created_at: object['created_at'] ?? null,
        customization: object['customization'] ?? null,
        fiel: object['fiel'] ?? null,
        id: object['id'] ?? null,
        is_production_ready: object['is_production_ready'] ?? null,
        keys: facturapiKeysDefSet(object['keys'] ?? {}),
        legal: object['legal'] ?? null,
        pending_steps: object['pending_steps'] ?? null,
        receipts: object['receipts'] ?? null,
    }
    return facturapi
}

export interface StripeDef {
    access_token?: string
    accountId: string
    automaticInvoice: boolean
    automaticInvoicing?: boolean
    onlyAutomaticInvoicingOnTestMode?: boolean
    clientCustomField?: boolean
    completed: boolean
    livemode: boolean
    refresh_token?: string
    scope?: string
    stripe_publishable_key: string
    stripe_user_id: string
    token_type?: string
    endpointSecretLive?: string
    endpointSecretTest?: string
    liveSecretKey?: string
    testSecretKey?: string
    connectType?: string
}

export const stripeDefSet = (object: any) => {
    const stripe: StripeDef = {
        access_token: object['access_token'] ?? null,
        accountId: object['accountId'] ?? null,
        automaticInvoice: object['automaticInvoice'] ?? null,
        clientCustomField: object['clientCustomField'] ?? null,
        completed: object['completed'] ?? null,
        livemode: object['livemode'] ?? null,
        refresh_token: object['refresh_token'] ?? null,
        scope: object['scope'] ?? null,
        stripe_publishable_key: object['stripe_publishable_key'] ?? null,
        stripe_user_id: object['stripe_user_id'] ?? null,
        token_type: object['token_type'] ?? null,
        endpointSecretLive: object['endpointSecretLive'] ?? null,
        endpointSecretTest: object['endpointSecretTest'] ?? null,
        liveSecretKey: object['liveSecretKey'] ?? null,
        testSecretKey: object['testSecretKey'] ?? null,
        connectType: object['connectType'] ?? null,
    }
    return stripe
}

export interface HilosDef {
    completed: boolean
    connectedAt: number
    keys: { token: string }
}

export interface HilosKeysDef {
    token: string
}
export const hilosKeysDefSet = (object: any) => {
    const keys: HilosKeysDef = {
        token: object['token'] ?? null,
    }
    return keys
}

export const hilosDefSet = (object: any) => {
    const hilos: HilosDef = {
        completed: object['completed'] ?? null,
        connectedAt: object['connectedAt'] ?? null,
        keys: hilosKeysDefSet(object['keys'] ?? {}),
    }
    return hilos
}

export interface WebhookDef {
    id: string
    name: string
    url: string
    status: string
    events: string[]
    created: string
}

export const webhookDefSet = (object: any) => {
    const webhook: WebhookDef = {
        id: object['id'] ?? null,
        name: object['name'] ?? null,
        url: object['url'] ?? null,
        status: object['status'] ?? null,
        events: object['events'] ?? null,
        created: object['created'] ?? null,
    }
    return webhook
}

export interface WHMCSDef {
    automaticInvoicing?: boolean
    livemode?: boolean
    completed?: boolean
}

export const whmcsDefSet = (object: any) => {
    const WHMCS: WHMCSDef = {
        automaticInvoicing: object['automaticInvoicing'] ?? null,
        livemode: object['livemode'] ?? null,
        completed: object['completed'] ?? null,
    }
    return WHMCS
}

export interface OpenPayDef {
    accountId: string
    production?: boolean
    sandbox?: boolean
    connectType: 'api'
    automaticInvoicing: boolean
    completed: boolean
    livemode: boolean
    liveSecretKey: string
    livePublicKey: string
    password: string
    url: string
}

export const openPayDefSet = (object: any) => {
    const openpay: OpenPayDef = {
        accountId: object['accountId'] ?? null,
        production: object['production'] ?? null,
        sandbox: object['sandbox'] ?? null,
        connectType: object['connectType'] ?? null,
        automaticInvoicing: object['automaticInvoicing'] ?? null,
        completed: object['completed'] ?? null,
        livemode: object['livemode'] ?? null,
        liveSecretKey: object['liveSecretKey'] ?? null,
        livePublicKey: object['livePublicKey'] ?? null,
        password: object['password'] ?? null,
        url: object['url'] ?? null,
    }

    return openpay
}

export interface WoocomerceDef {
    completed: boolean
    connectedAt: number
    convertOnKey: string
    automaticInvoicing: boolean
    links: {
        live: string
        test: string
    }
    webhookSecret?: string
}

export interface PayPalDef {
    completed: boolean
    connectedAt: number
    access_token: string
    token_expiration: string
    automaticInvoicing: boolean
    links: {
        live: string
        test: string
    }
}

export interface ClipDef {
    completed: boolean
    connectedAt: number
    automaticInvoicing: boolean
    apiKey: string
    secretKey: string
    defaults: {
        serviceDescription: string
        paymentForm: string
    }
    links: {
        live: string
        test: string
    }
}

export const woocomerceDefSet = (object: any) => {
    const woocomerce: WoocomerceDef = {
        completed: object['completed'] ?? null,
        connectedAt: object['connectedAt'] ?? null,
        convertOnKey: object['convertOnKey'] ?? 'completed',
        automaticInvoicing: object['automaticInvoicing'] ?? false,
        links: {
            live: object['live'] ?? null,
            test: object['test'] ?? null,
        },
    }
    return woocomerce
}
