import moment from 'moment'
import { AddressDef, addressSet } from './clientDef'
import {
    BankDef,
    ClipDef,
    FacturapiDef,
    HilosDef,
    OpenPayDef,
    PayPalDef,
    StripeDef,
    WHMCSDef,
    WebhookDef,
    WoocomerceDef,
    bankDefSet,
    facturapiDefSet,
    hilosDefSet,
    openPayDefSet,
    stripeDefSet,
    whmcsDefSet,
} from './integrationsDef'
import { TaxElement, taxElementSet } from './invoicesDef'

export interface BrandDef {
    alias?: string
    logo?: string
    primaryColor?: string
    secondaryColor?: string
}

export const brandDefSet = (object: any) => {
    const brand: BrandDef = {
        alias: object['alias'] ?? '',
        logo: object['logo'] ?? '',
        primaryColor: object['primaryColor'] ?? '#044737',
        secondaryColor: object['secondaryColor'] ?? '#F4F7F2',
    }
    return brand
}

export interface TeamMemberDef {
    email: string
    expenses: string
    id: string
    invoices: string
    isAdmin: boolean
    payments: string
    rol: string
    services: string
}

export const teamMemberDefSet = (object: any) => {
    const teamMember: TeamMemberDef = {
        email: object['email'] ?? null,
        expenses: object['expenses'] ?? null,
        id: object['id'] ?? null,
        invoices: object['invoices'] ?? null,
        isAdmin: object['isAdmin'] ?? null,
        payments: object['payments'] ?? null,
        rol: object['rol'] ?? null,
        services: object['services'] ?? null,
    }
    return teamMember
}

export interface DefaultsDef {
    availNationality?: { code: string; label: string; value: string }[]
    avoidReceipts?: boolean
    avoidForeignReceiptsEmails?: boolean
    defaultTaxes?: TaxElement[]
    emailTemplates?: object[]
    invoice_folio?: number
    invoice_folio_payments?: number
    invoice_folio_egress?: number
    invoice_series?: string
    invoice_series_egress?: string
    invoice_series_payments?: string
    periodicity?: string
    product_key?: string
    unit_key?: string
    unit_name?: string
    usage?: string
    currency?: string
    invoiceInitialLoad?: string
    avoidGlobalInvoiceEmailsOnTest?: boolean
    completed?: boolean
    skipOnboarding?: boolean
}

export const defaultsDefSet = (object: any) => {
    const defaults: DefaultsDef = {
        availNationality: object['availNationality'] ?? [],
        avoidForeignReceiptsEmails: object['avoidForeignReceiptsEmails'] ?? false,
        avoidReceipts: object['avoidReceipts'] ?? null,
        defaultTaxes: object['defaultTaxes']?.map((t: object) => taxElementSet(t)) ?? [],
        emailTemplates: object['emailTemplates'] ?? [],
        invoice_folio: object['invoice_folio'] ?? null,
        invoice_folio_payments: object['invoice_folio_payments'] ?? null,
        invoice_folio_egress: object['invoice_folio_egress'] ?? null,
        invoice_series: object['invoice_series'] ?? null,
        invoice_series_egress: object['invoice_series_egress'] ?? null,
        invoice_series_payments: object['invoice_series_payments'] ?? null,
        periodicity: object['periodicity'] ?? null,
        product_key: object['product_key'] ?? null,
        unit_key: object['unit_key'] ?? null,
        unit_name: object['unit_name'] ?? null,
        invoiceInitialLoad: object['invoiceInitialLoad'] ?? null,
        usage: object['usage'] ?? null,
        avoidGlobalInvoiceEmailsOnTest: object['avoidGlobalInvoiceEmailsOnTest'] ?? null,
        completed: object['completed'] ?? null,
        skipOnboarding: object['skipOnboarding'] ?? null,
    }
    return defaults
}

export interface TipsDef {
    type: 'info' | 'error' | 'warning' | 'success'
    message: string
}

const tipsDefSet = (object: any) => {
    const tip: TipsDef = {
        type: object['type'] ?? 'info',
        message: object['message'] ?? '',
    }
    return tip
}

export interface TeamDef {
    address?: AddressDef
    id: string
    bank: BankDef
    billingAccount: string
    brand: BrandDef
    certsError?: string
    contactEmails?: string[]
    defaults: DefaultsDef
    facturapi: FacturapiDef
    woocomerce?: WoocomerceDef
    paypal?: PayPalDef
    clip?: ClipDef
    customerPortalId?: string
    invoice_folio: number
    invoice_folio_payments: number
    invoice_folio_egress: number
    invoice_serie?: string
    invoice_serie_egress?: string
    invoice_serie_payments?: string
    invoicesCreated?: number
    legalName?: string
    legal_name?: string
    members: TeamMemberDef[]
    membersIds: string[]
    owner: string
    paymentsCreated?: number
    receiptsCreated?: number
    rfc: string
    setupAt: number
    setupComplete: boolean
    social: object
    stripe: StripeDef
    supportEmail: string
    tax_system: string
    tax_system_m: {
        label: string
        value: string
    }
    timestamp: number
    website: string
    hilos: HilosDef
    webhooks: WebhookDef[]
    openpay: OpenPayDef
    whmcs: WHMCSDef
    tips?: TipsDef[]
    airtable?: any
    zapier?: any
    googlesheets?: any
    typesense?: {
        completed: boolean
        searchKeyGenerated: number
        keys: {
            live_key: string
        }
    }
    meilisearch?: {
        completed: boolean
        searchKeyGenerated: number
        keys: {
            live_key: string
        }
    }
}

export const teamDefSet = (object: any): TeamDef => {
    const team: TeamDef = {
        address: addressSet(object ? (object['address'] ?? {}) : {}),
        id: object['id'] ?? '',
        bank: bankDefSet(object['bank'] ?? {}),
        billingAccount: object['billingAccount'] ?? null,
        brand: brandDefSet(object['brand'] ?? {}),
        certsError: object['certsError'] ?? null,
        contactEmails: object['contactEmails'] ?? [],
        defaults: defaultsDefSet(object['defaults'] ?? {}),
        facturapi: facturapiDefSet(object['facturapi'] ?? {}),
        invoice_folio: object['invoice_folio'] ?? null,
        invoice_folio_payments: object['invoice_folio_payments'] ?? null,
        invoice_folio_egress: object['invoice_folio_egress'] ?? null,
        invoice_serie: object['invoice_serie'] ?? null,
        invoice_serie_egress: object['invoice_serie_egress'] ?? null,
        invoice_serie_payments: object['invoice_serie_payments'] ?? null,
        invoicesCreated: object['invoicesCreated'] ?? null,
        legalName: object['legalName'] ?? null,
        legal_name: object['legal_name'] ?? null,
        members: object['members'] ?? [],
        membersIds: object['membersIds'] ?? [],
        owner: object['owner'] ?? null,
        paymentsCreated: object['paymentsCreated'] ?? null,
        receiptsCreated: object['receiptsCreated'] ?? null,
        rfc: object['rfc'] ?? null,
        setupAt: object['setupAt'] ?? null,
        setupComplete: object['setupComplete'] ?? null,
        social: object['social'] ?? null,
        stripe: stripeDefSet(object['stripe'] ?? {}),
        supportEmail: object['supportEmail'] ?? null,
        tax_system: object['tax_system'] ?? null,
        tax_system_m: object['tax_system_m'] ?? null,
        timestamp: object['timestamp'] ?? moment().valueOf(),
        website: object['website'] ?? null,
        hilos: hilosDefSet(object['hilos'] ?? {}),
        webhooks: object['webhooks'] ?? null,
        openpay: openPayDefSet(object['openpay'] ?? {}),
        whmcs: whmcsDefSet(object['whmcs'] ?? {}),
        tips: (object['tips'] ?? []).map((t: any) => tipsDefSet(t)),
        customerPortalId: object['customerPortalId'] ?? null,
    }
    return team
}
