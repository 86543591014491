import React from 'react'
import { FormInstance, Col, Row, Form, Button, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { NumberInput } from './Inputs'
import { setTeam } from '../../context/teamsSlice'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { closeModal } from '../../context/modalsSlice'

interface PropsDef {
    incomingForm?: FormInstance
    buttonTitle?: string
}

const LimitDaysToPayForm: React.FC<PropsDef> = ({ incomingForm, buttonTitle }) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const usingForm = incomingForm ?? form
    const [messageApi, contextHolder] = message.useMessage()
    const { team } = useSelector((state: any) => state.team)

    const onFinish = async (values: any) => {
        try {
            messageApi.open({
                type: 'loading',
                content: 'Actualizando información...',
                duration: 0,
            })
            dispatch(setTeam({ ...team, defaults: { ...team?.defaults, ...values } }))
            await updateDoc(doc(getFirestore(), 'teams', team?.id), {
                'defaults.limitDaysToPay': values.limitDaysToPay ?? '',
            })
            messageApi.destroy()
            message.success('Información actualizada correctamente')
            dispatch(closeModal('settingsDrawerVisible'))
        } catch (error: any) {
            message.error('Ocurrió un error al actualizar la información.')
        }
    }

    return (
        <Form layout="vertical" form={usingForm} onFinish={onFinish} initialValues={{ ...team?.defaults }}>
            {/* Form content goes here */}
            {contextHolder}
            <Row>
                <Col xs={24}>
                    <NumberInput
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingresa un número de días límite para pagar',
                            },
                        ]}
                        label="Días límite de pago"
                        placeholder='"x" días límite para pagar'
                        name="limitDaysToPay"
                        min={0}
                        max={366}
                        step={1}
                    />
                </Col>
                <Col span={24}>
                    <Row justify="end">
                        <Button type="primary" htmlType="submit">
                            {buttonTitle ?? 'Actualizar información'}
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Form>
    )
}

export default LimitDaysToPayForm
