import { LightbulbFilament, StarFour } from '@phosphor-icons/react'
import { Avatar, Card, Col, Image, Row, Space, Tag, Typography } from 'antd'
import { useSelector } from 'react-redux'

export const InvoicePreview = ({ invoiceSection }: { invoiceSection: 'fiscaData' | 'products' }) => {
    const { team } = useSelector((state: any) => state.team)
    const { fiscalInformationOnb: fiscalInf } = useSelector((state: any) => state.data)

    const FiscalData = () => {
        return (
            <Space direction="vertical" size={5} style={{ width: '100%' }}>
                <Typography className="p-base-bold">{fiscalInf?.legal_name || 'Nombre legal'}</Typography>
                <Typography className="p-base-regular">{fiscalInf?.addressStr || 'Dirección fiscal'}</Typography>
                <Typography className="p-base-regular">
                    <span className="p-base-bold">RFC: </span> {fiscalInf?.rfc || 'XXXX000000XXX'}
                </Typography>
                <Typography className="p-base-regular">
                    <span className="p-base-bold">Régimen Fiscal: </span>{' '}
                    {fiscalInf?.tax_system_label || 'Persona Moral'}
                </Typography>
            </Space>
        )
    }

    const ProductsSection = () => {
        const { defaultsOnb: defaults } = useSelector((state: any) => state.data)

        return (
            <div>
                <Row className="table-header">
                    <Col span={6}>
                        <Typography className="p-base-bold">Clave Prod/Serv</Typography>
                    </Col>
                    <Col span={6}>
                        <Typography className="p-base-bold">Concepto</Typography>
                    </Col>
                    <Col span={6}>
                        <Typography className="p-base-bold">Unidad</Typography>
                    </Col>
                    <Col span={6} style={{ textAlign: 'right' }}>
                        <Typography className="p-base-bold">Importe</Typography>
                    </Col>
                </Row>
                <Row className="table-row">
                    <Col span={6}>{defaults?.product_key || ''}</Col>
                    <Col span={6}>Tu producto</Col>
                    <Col span={6}>
                        {defaults?.unit_name || ''} ({defaults?.unit_key})
                    </Col>
                    <Col span={6} style={{ textAlign: 'right' }}>
                        MXN $30.00
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <Row justify="center">
            <Col>
                <Space direction="vertical" size="large" style={{ width: '100%' }}>
                    <div>
                        <Typography className="h5-bold neutral-1" style={{ marginBottom: 8 }}>
                            Vista previa de una factura
                        </Typography>
                        <Typography className="p-base-regular neutral-2 fade-in-animation">
                            Vista previa que refleja la apariencia de la sección del emisor en una factura final.
                        </Typography>
                    </div>

                    <Card className="rounded-xl" style={{ width: '100%' }}>
                        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                            <Avatar size={64} src={team?.brand?.logo} icon={!team?.brand?.logo && <Image />} />
                            <Row justify="space-between">
                                <Col span={12}>
                                    <Typography className="h5-bold neutral-1">Factura</Typography>
                                </Col>
                                <Col span={5} style={{ textAlign: 'center' }}>
                                    <div style={{}}>
                                        <Typography className="p-base-bold">Folio</Typography>
                                        <Tag
                                            className="rounded-sm"
                                            style={{
                                                backgroundColor: 'var(--neutral-5)',
                                                padding: '5px 15px',
                                                border: 'none',
                                            }}
                                        >
                                            01 PREVIEW
                                        </Tag>
                                    </div>
                                </Col>
                            </Row>
                            <Space direction="vertical" size={2} style={{ width: '100%' }}>
                                <Typography className="p-base-bold">
                                    {invoiceSection === 'fiscaData' ? 'Emisor' : 'Productos / Servicios'}
                                </Typography>
                                {/** Make a horizontal line of 3px of height with a div*/}
                                <div
                                    style={{
                                        backgroundColor: team?.brand?.primaryColor || 'var(--primary-1)',
                                        height: 3,
                                        width: '100%',
                                    }}
                                />
                            </Space>
                            {invoiceSection === 'fiscaData' && <FiscalData />}
                            {invoiceSection === 'products' && <ProductsSection />}
                        </Space>
                    </Card>
                </Space>
            </Col>
        </Row>
    )
}

const CSDSAdvantages = () => {
    return (
        <Row justify="center">
            <Col sm={18} md={18} lg={16}>
                <Space direction="vertical" size="large" style={{ width: '100%' }}>
                    <div>
                        <Typography className="h5-bold neutral-1" style={{ marginBottom: 8 }}>
                            ¿Qué son los Sellos de Certificado Digital?
                        </Typography>
                        <Typography className="p-base-regular neutral-2">
                            Un Certificado de Sello Digital (CSD) es un certificado digital específico para emitir
                            facturas electrónicas (CFDI) y otros documentos fiscales, expedido por el SAT.
                        </Typography>
                    </div>
                    <Row align="middle">
                        <LightbulbFilament size={18} style={{ marginRight: '10px' }} />
                        <Typography className="p-base-bold neutral–2">
                            Recuerda que el CSD no es igual que la FIEL
                        </Typography>
                    </Row>
                    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                        <Typography className="h5-bold neutral-1" style={{ marginBottom: 8 }}>
                            Beneficios
                        </Typography>
                        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                            <Tag
                                className="rounded-xl"
                                style={{ border: 'none', backgroundColor: 'var(--green-11)', padding: '0px 15px' }}
                            >
                                <StarFour
                                    weight="fill"
                                    size={16}
                                    color="var(--primary-1)"
                                    style={{ marginRight: '5px' }}
                                />
                                <Typography className="p-large-bold primary-1">Conexión segura</Typography>
                            </Tag>
                            <Tag
                                className="rounded-xl"
                                style={{ border: 'none', backgroundColor: 'var(--primary-1)', padding: '0px 15px' }}
                            >
                                <StarFour
                                    weight="fill"
                                    size={16}
                                    color="var(--green-11)"
                                    style={{ marginRight: '5px' }}
                                />
                                <Typography className="p-large-bold green-11">Validación directa con el SAT</Typography>
                            </Tag>
                            <Tag
                                className="rounded-xl"
                                style={{ border: 'none', backgroundColor: 'var(--primary-8)', padding: '0px 15px' }}
                            >
                                <StarFour
                                    weight="fill"
                                    size={16}
                                    color="var(--neutral-2)"
                                    style={{ marginRight: '5px' }}
                                />
                                <Typography className="p-large-bold neutral-2">Integridad de los datos</Typography>
                            </Tag>
                        </Space>
                    </Space>
                </Space>
            </Col>
        </Row>
    )
}

const DefaultsSideBar = ({ localStep }: { localStep: number }) => {
    switch (localStep) {
        case 1:
            return <InvoicePreview invoiceSection="fiscaData" />
        case 2:
            return <CSDSAdvantages />
        case 3:
            return <InvoicePreview invoiceSection="products" />
        default:
            return <></>
    }
}

export default DefaultsSideBar
