import { Button, Form, Modal, Row, Timeline, Typography, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
import { ClockClockwise, PaperPlaneTilt, Receipt } from '@phosphor-icons/react'
import moment from 'moment'
import { getItemsAmounts } from '../functions/helpers'
import { useEffect, useState } from 'react'
import { MetroSpinner } from 'react-spinners-kit'
import { colors } from '../designSystem/colors'
import ReceiptActions from '../components/ElementActions/ReceiptsActions'
import { SignedInternalAPIRequest } from '../functions/APIRequest'
import { useAuth } from 'reactfire'
import { SelectInput } from '../components/Forms/Inputs'
import { setData } from '../context/dataSlice'
import { arrayUnion, doc, getFirestore, updateDoc } from 'firebase/firestore'
import { paymentForms } from '../datasets/Fiscal'

const TimelineItem = ({
    title,
    highlight,
    description,
    textAfterHighlight = '',
}: {
    title: string
    highlight?: string
    description?: string
    textAfterHighlight?: string
}) => {
    return (
        <div className="d-flex flex-column">
            <Typography.Text className="smallparagraphbold">
                {title}
                {highlight && (
                    <Typography.Text className="smallparagraphbold primary">
                        {`\u00A0 ${highlight} \u00A0`}
                    </Typography.Text>
                )}
                {textAfterHighlight}
            </Typography.Text>
            <Typography.Text className="smallparagraph descriptions">{description}</Typography.Text>
        </div>
    )
}

export const AutoInvoiceModal = (props: any) => {
    const dispatch = useDispatch()
    const fs = getFirestore()
    const auth = useAuth()

    const { autoinvoiceProcessModalVisible } = useSelector((state: any) => state.modals)
    const { team } = useSelector((state: any) => state.team)
    const { payment } = useSelector((state: any) => state.data)

    const [state, setState] = useState({
        itemLoading: 0,
        itemSuccess: 0,
        itemError: 0,
        receipt: null,
        missingPaymentForm: false,
        buttonDisabled: false,
    })

    useEffect(() => {
        if (!payment?.payment_form) setState({ ...state, missingPaymentForm: true })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoinvoiceProcessModalVisible])

    const amounts = getItemsAmounts(payment?.internalItems ?? [], payment?.paidIn === 'bank')
    const periodicity =
        (typeof team?.defaults?.periodicity === 'object'
            ? team?.defaults?.periodicty?.value
            : team?.defaults?.periodicity) || 'month'

    const generateReceipt = async () => {
        setState({ ...state, itemLoading: 1 })

        try {
            const receipt = {
                client: payment.client,
                items: payment?.internalItems ?? payment?.items,
                currency: payment?.currency,
                livemode: payment.livemode,
                periodicity: periodicity ?? 'month',
                payment_form: payment.payment_form
                    ? paymentForms.find((p: any) => p.value === payment?.payment_form)
                    : null,
                exchange: payment?.exchange,
                test: !payment.livemode,
                validDays: moment()
                    .endOf(team?.defaults?.periodicity ?? 'month')
                    .valueOf(),
                validUntil: moment()
                    .endOf(team?.defaults?.periodicity ?? 'month')
                    .valueOf(),
                type: 'create_receipt',
                team: payment?.team ?? team?.id ?? '',
                billingAccount: payment?.billingAccount,
                payments: [payment?.fid],
                paymentId: payment?.fid,
                from: 'selfInvoiceProcess',
            }

            const res = await SignedInternalAPIRequest({ ...receipt }, 'receiptsApp/v1/create', auth.currentUser)

            await updateDoc(doc(fs, 'payments', payment?.fid), {
                receipts: arrayUnion(res?.receipt?.id),
            })

            // setState({ ...state, itemLoading: 2, itemSuccess: 2, receipt: res?.receipt })
            setState((prevState) => ({ ...prevState, itemLoading: 2, itemSuccess: 2, receipt: res?.receipt }))

            setTimeout(() => {
                // setState({ ...state, itemSuccess: 3, itemLoading: 3 })
                setState((prevState) => ({ ...prevState, itemSuccess: 3, itemLoading: 3 }))
            }, 3000)

            setTimeout(() => {
                setState((prevState) => ({ ...prevState, buttonDisabled: true, itemLoading: 0 }))
                // dispatch(closeModal('autoinvoiceProcessModalVisible'))
            }, 5000)
        } catch (error: any) {
            console.log(error)
            setState({ ...state, itemLoading: 0, itemError: 1 })
            message.error(error?.message ?? 'Error al generar recibo')
        }
    }

    const handleItemTitle = ({ item }: { item: number }) => {
        if (item === 1 && state.itemLoading < 1) return 'Generaremos un recibo de venta por'
        if (item === 1 && state.itemLoading === 1) return 'Generando recibo de venta por'
        if (item === 1 && state.itemError === 1) return 'Error al generar recibo por'
        if (item === 1 && state.itemSuccess >= 1) return 'Recibo generado correctamente por'

        if (item === 2 && state.itemLoading < 2) return 'Enviaremos el enlace del portal a'
        if (item === 2 && state.itemLoading === 2) return 'Enviando correo a'
        if (item === 2 && state.itemError === 2) return 'Error al enviar correo'
        if (item === 2 && state.itemSuccess >= 2) return 'Correo enviado correctamente a'
        if (item === 2) return 'Enviaremos el enlace del portal a'

        if (item === 3 && state.itemLoading < 3) return 'Tu cliente tendrá hasta el'
        if (item === 3) return 'Tu cliente tiene hasta el'

        return ''
    }

    return (
        <Modal
            open={autoinvoiceProcessModalVisible}
            onCancel={() => {
                setState({
                    missingPaymentForm: false,
                    itemLoading: 0,
                    itemError: 0,
                    itemSuccess: 0,
                    receipt: null,
                    buttonDisabled: false,
                })
                dispatch(closeModal('autoinvoiceProcessModalVisible'))
            }}
            footer={null}
            title="Iniciar proceso de auto-factura"
        >
            <p className="smallparagraph descriptions">
                Con este proceso tu cliente podrá emitir su factura, cónoce más{' '}
                <a
                    href="https://helpcenter.gigstack.pro/es/articles/6946723-facturacion-automatica"
                    target="_blank"
                    rel="noreferrer"
                >
                    aquí
                </a>
            </p>

            {state.missingPaymentForm && (
                <Row justify="start" style={{ width: '100%', marginTop: '10px' }}>
                    <Form layout="vertical" style={{ width: '100%' }}>
                        <SelectInput
                            label="Debes seleccionar el método de pago"
                            name="paymentForm"
                            options={paymentForms}
                            rules={[{ required: true, message: 'Debes seleccionar el método de pago' }]}
                            onChange={(value: any) => {
                                dispatch(
                                    setData({
                                        item: 'payment',
                                        data: {
                                            ...payment,
                                            payment_form: value,
                                        },
                                    }),
                                )
                            }}
                        />
                    </Form>
                </Row>
            )}

            <Timeline
                style={{ margin: state.missingPaymentForm ? '15px 15px 0px 15px' : '35px 15px 0px 15px' }}
                items={[
                    {
                        dot:
                            state.itemLoading === 1 ? (
                                <MetroSpinner size={15} color={colors.primary} />
                            ) : (
                                <Receipt
                                    size={18}
                                    weight="regular"
                                    className="icon"
                                    color={
                                        state.itemError === 1
                                            ? colors.red
                                            : state.itemSuccess >= 1
                                              ? colors.green
                                              : colors.icons_normal
                                    }
                                />
                            ),
                        children: (
                            <TimelineItem
                                title={handleItemTitle({ item: 1 })}
                                highlight={`${amounts.totalString} ${payment?.currency}`}
                                description="Tardará unos segundos"
                            />
                        ),
                    },
                    {
                        dot:
                            state.itemLoading === 2 ? (
                                <MetroSpinner size={15} color={colors.primary} />
                            ) : (
                                <PaperPlaneTilt
                                    size={18}
                                    weight="regular"
                                    className="icon"
                                    color={
                                        state.itemError <= 2 && state.itemError > 0
                                            ? colors.red
                                            : state.itemSuccess >= 2
                                              ? colors.green
                                              : colors.icons_normal
                                    }
                                />
                            ),
                        children: (
                            <TimelineItem
                                title={handleItemTitle({ item: 2 })}
                                highlight={payment?.client?.email || 'sin correo registrado'}
                                description="Tardará unos segundos"
                            />
                        ),
                    },
                    {
                        dot: (
                            <ClockClockwise
                                size={18}
                                weight="regular"
                                className="icon"
                                color={
                                    state.itemError === 1
                                        ? colors.red
                                        : state.itemSuccess >= 2
                                          ? colors.green
                                          : colors.icons_normal
                                }
                            />
                        ),
                        children: (
                            <TimelineItem
                                title={handleItemTitle({ item: 3 })}
                                highlight={moment().endOf(periodicity).format('DD/MM/YYYY HH:mm')}
                                textAfterHighlight="para generar su factura"
                                description={moment().endOf(periodicity).fromNow()}
                            />
                        ),
                    },
                ]}
            />

            {state.receipt && (
                <Row justify="center" style={{ marginBottom: '20px' }}>
                    <ReceiptActions receipt={state.receipt} hide={false} />
                </Row>
            )}

            <Row justify="center">
                <Button
                    type="primary"
                    disabled={!payment?.payment_form || state.buttonDisabled}
                    loading={state.itemLoading > 0}
                    onClick={() => {
                        generateReceipt()
                    }}
                >
                    Iniciar proceso
                </Button>
            </Row>
        </Modal>
    )
}
