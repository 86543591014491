import { User } from "@phosphor-icons/react"
import { Row, Typography } from "antd"
import { returnReadableOwnerInfo } from "../../functions/helpers"
import { TeamDef } from "../../../interfaces/teamDef"
import { UserDef } from "../../../interfaces/userDef"



export const createdByObject = ({ resource,  team, user } : { resource: any, team: TeamDef, user: UserDef }) => {

    const cretedBy = returnReadableOwnerInfo({ resource, team, user })

    return {
        key: 'created_by',
        label: <Typography.Text className="smallparagraphbold">Creado por</Typography.Text>,
        children: (
            <Row>
                <User size={14} style={{ marginRight: '5px' }} />
                <Typography.Text className="smallparagraph ">{cretedBy.name ?? cretedBy.email}</Typography.Text>
            </Row>
        ),
    }
}