import { collection, doc, getDoc, getDocs, getFirestore, query, updateDoc, where } from 'firebase/firestore'
import { setBillingAccount } from './billingAccountSlice'
import { billingAccountDefSet } from '../../interfaces/billingAccountDef'
import { setTeam, setTeams, setTeamsBA } from './teamsSlice'
import { TeamDef } from '../../interfaces/teamDef'
import { setUser } from './userSlice'
import { userDefSet } from '../../interfaces/userDef'
import { useFirestore } from 'reactfire'
import { setData } from './dataSlice'
import { CompleteProfileElement } from '../../interfaces/clientDef'

export const GetBillingAccountHelper = async (baId: string, dispatch: any) => {
    try {
        const fs = getFirestore()
        const ba = await getDoc(doc(fs, 'billingAccounts', baId))
        if (!ba.exists()) {
            throw new Error('Billing Account does not exist')
        }
        dispatch(setBillingAccount(billingAccountDefSet(ba.data())))
    } catch (error) {
        throw error
    }
}

export const GetUserHelper = async (uid: string, dispatch: any) => {
    try {
        const fs = getFirestore()
        const ba = await getDoc(doc(fs, 'users', uid))
        if (!ba.exists()) {
            throw new Error('Billing Account does not exist')
        }
        dispatch(setUser(userDefSet(ba.data())))
    } catch (error) {
        throw error
    }
}

export const GetTeamHelper = async (tmId: string, dispatch: any) => {
    try {
        const fs = getFirestore()
        const team = await getDoc(doc(fs, 'teams', tmId))
        if (!team.exists()) {
            throw new Error('Team Account does not exist')
        }

        const tm = team.data() as TeamDef
        dispatch(setTeam({ ...tm, rawMembers: null, invoice_folio_payments: team.data().invoice_folio_payments || 1 }))
    } catch (error) {
        throw error
    }
}

export const SetTeamFolioHelper = async (tmId: string, folio: number, invoiceType: string) => {
    let toUpdate: any = {}

    if (invoiceType === 'I') toUpdate.invoice_folio = folio
    else if (invoiceType === 'E') toUpdate.invoice_folio_egress = folio
    else toUpdate.invoice_folio_payments = folio

    try {
        if (Object.keys(toUpdate).length > 0) {
            const fs = getFirestore()
            await updateDoc(doc(fs, 'teams', tmId), toUpdate)
        }
    } catch (error) {
        throw error
    }
}

export const GetTeamsHelper = async (userId: string, dispatch: any) => {
    try {
        const fs = useFirestore()

        const q = query(collection(fs, 'teams'), where('membersIds', 'array-contains', userId))
        const teams = await getDocs(q)
        const ts = teams.docs.map((doc) => doc.data() as TeamDef)
        dispatch(setTeams(ts))
    } catch (error) {}
}

export const GetBATeamsHelper = async (userId: string, dispatch: any) => {
    try {
        const fs = getFirestore()
        const q = query(collection(fs, 'teams'), where('membersIds', 'array-contains', userId ?? 'js'))

        const teams = await getDocs(q)
        if (teams.empty) {
            throw new Error('No teams found')
        }

        const ts = setTeamsBA(teams.docs.map((doc) => doc.data() as TeamDef))
        dispatch(ts)

        return teams
    } catch (error) {
        throw error
    }
}

export const GetExchangeRates = async (dispatch: any) => {
    try {
        const fs = getFirestore()
        const rates = await getDoc(doc(fs, 'rates', 'rates'))
        if (!rates.exists()) {
            throw new Error('Billing Account does not exist')
        }
        dispatch(setData({ item: 'exchangeRates', data: rates.data().conversion_rates }))
    } catch (error) {
        throw error
    }
}

export const GetClients = async (dispatch: any, teamId: string, callback?: (clients: any) => void) => {
    try {
        const fs = getFirestore()
        const q = query(collection(fs, 'clients'), where('team', '==', teamId))
        const clients = await getDocs(q)

        if (clients.empty) {
            dispatch(setData({ item: 'clients', data: [] }))
            dispatch(setData({ item: 'clientsLoaded', data: true }))
            if (callback) {
                callback([])
            }
        } else {
            dispatch(setData({ item: 'clientsLoaded', data: true }))
            dispatch(
                setData({
                    item: 'clients',
                    data: clients.docs.map((doc) => {
                        return {
                            id: doc.id,
                            name: doc.data().name,
                            legalName: doc.data().legal_name ?? doc.data().legalName,
                            legal_name: doc.data().legal_name ?? doc.data().legalName,
                            tax_system: doc.data().tax_system ?? doc.data().taxSystem,
                            taxSystem: doc.data().tax_system ?? doc.data().taxSystem,
                            tax_id: doc.data().tax_id,
                            rfc: doc.data().rfc,
                            address: doc.data().address,
                            zip: doc.data().zip || doc.data().address?.zip,
                            company: doc.data().company,
                            email: doc.data().email,
                            phone: doc?.data()?.phone,
                            bcc: doc.data().bcc,
                            timestamp: doc.data().timestamp,
                            ignoreSuggestions: doc.data().ignoreSuggestions || [],
                            profile: CompleteProfileElement({ record: doc.data() }),
                            defaults: doc?.data()?.defaults ?? {},
                            metadata: doc?.data()?.metadata ?? {},
                        }
                    }),
                }),
            )
            if (callback) {
                callback(
                    clients.docs.map((doc) => {
                        return {
                            id: doc.id,
                            name: doc.data().name,
                            legalName: doc.data().legalName,
                            tax_id: doc.data().tax_id,
                            company: doc.data().company,
                            email: doc.data().email,
                            phone: doc?.data()?.phone,
                            bcc: doc.data().bcc,
                            timestamp: doc.data().timestamp,
                        }
                    }),
                )
            }
        }
    } catch (error) {
        throw error
    }
}

export const GetServices = async (dispatch: any, teamId: string, callback?: (clients: any) => void) => {
    try {
        const fs = getFirestore()

        const q = query(collection(fs, 'services'), where('team', '==', teamId))
        const services = await getDocs(q)

        if (services.empty) {
            dispatch(setData({ item: 'services', data: [] }))
            dispatch(setData({ item: 'servicesLoaded', data: true }))
        } else {
            dispatch(setData({ item: 'servicesLoaded', data: true }))
            dispatch(
                setData({
                    item: 'services',
                    data: services.docs.map((doc) => {
                        return {
                            id: doc.id,
                            name: doc.data().name,
                            taxes: doc.data().taxes,
                            paymentType: doc.data().paymentType,
                            quantity: doc.data().quantity,
                            product_key: doc.data().product_key,
                            unit_key: doc.data().unit_key,
                            unit_name: doc.data().unit_name,
                            total: doc.data().total,
                            timestamp: doc.data().timestamp,
                        }
                    }),
                }),
            )
            if (callback) {
                callback(
                    services.docs.map((doc) => {
                        return {
                            id: doc.id,
                            name: doc.data().name,
                            taxes: doc.data().taxes,
                            paymentType: doc.data().paymentType,
                            quantity: doc.data().quantity,
                            product_key: doc.data().product_key,
                            unit_key: doc.data().unit_key,
                            unit_name: doc.data().unit_name,
                            total: doc.data().total,
                            timestamp: doc.data().timestamp,
                        }
                    }),
                )
            }
        }
    } catch (error) {
        throw error
    }
}

export const GetClientHelper = async (dispatch: any, clientId: string, callback?: (client: any) => void) => {
    try {
        const fs = getFirestore()
        const client = await getDoc(doc(fs, 'clients', clientId))
        if (!client.exists()) {
            throw new Error('Client does not exist')
        }
        dispatch(setData({ item: 'client', data: client.data() }))
        if (callback) {
            callback(client.data())
        }
    } catch (error) {
        throw error
    }
}

export const GetServicesByIdsHelper = async (
    dispatch: any,
    servicesIds: string[],
    callback?: (services: any) => void,
) => {
    try {
        const fs = getFirestore()
        const services = await Promise.all(
            servicesIds.map(async (serviceId) => {
                const service = await getDoc(doc(fs, 'services', serviceId))
                if (!service.exists()) {
                    throw new Error('Service does not exist')
                }
                return service
            }),
        )

        if (services.length === 0) {
            return []
        }
        const servicesData = services.map((service) => service.data())
        dispatch(setData({ item: 'selectedServices', data: servicesData }))
    } catch (error) {
        throw error
    }
}
