import { getAuth, GoogleAuthProvider, signInWithPopup, updateProfile } from 'firebase/auth'
import { doc, getDoc, getFirestore, setDoc, updateDoc } from 'firebase/firestore'
import moment from 'moment'
import { BillingAccountDef, billingAccountDefSet } from '../../../interfaces/billingAccountDef'
import { TeamDef, teamDefSet } from '../../../interfaces/teamDef'
import { setBillingAccount } from '../../context/billingAccountSlice'
import { setTeam } from '../../context/teamsSlice'
import { generateCode } from '../../functions/helpers'

export const handleGoogleSignIn = async (setLoading: Function, navigate: any) => {
    setLoading((l: any) => [...l, 'google'])

    try {
        await new Promise((resolve) => setTimeout(resolve, 2000))
        const provider = new GoogleAuthProvider()
        const result = await signInWithPopup(getAuth(), provider)
        if (!result.user.displayName) {
            await updateProfile(result.user, {
                displayName: result.user?.email?.split('@')[0],
            })
            await updateDoc(doc(getFirestore(), 'users', result.user.uid), {
                name: result.user?.email?.split('@')[0],
                firstName: result.user?.email?.split('@')[0],
                lastName: '',
                country: 'MEX',
                email: result.user.email,
                uid: result.user.uid,
            })
            navigate('/gettingStarted')
        }
        console.log('Google sign-in successful')
    } catch (error) {
        console.error('Google sign-in failed:', error)
    } finally {
        setLoading((l: any) => l.filter((e: any) => e !== 'google'))
    }
}

export const createBillingAccount = async (
    newBillingAccountData: Partial<BillingAccountDef>,
    user: any,
    dispatch: any,
    auth: any,
) => {
    // Check if user has a billing account ID
    try {
        if (user?.billingAccount) {
            console.log('🚀 ~ createBillingAccount ~ user.billingAccount:', user.billingAccount)
            // Double-check if the billing account exists in Firestore
            const billingAccountRef = doc(getFirestore(), 'billingAccounts', user.billingAccount)
            const billingAccountDoc = await getDoc(billingAccountRef)

            if (billingAccountDoc.exists()) {
                return billingAccountDoc.data()
            }
        }

        const newBillingAccount = billingAccountDefSet({
            ...newBillingAccountData,
            id: generateCode(15, 'ba'),
            timestamp: moment().valueOf(),
            owner: auth.currentUser?.uid || '',
            admins: [auth.currentUser?.uid || ''],
            membersIds: [auth.currentUser?.uid || ''],
            teamMembers: [{ uid: auth.currentUser?.uid || '', role: 'admin' }],
        })
        console.log(newBillingAccount)
        // return
        console.log({ ...user, billingAccount: newBillingAccount.id })

        const newDocRef = doc(getFirestore(), 'billingAccounts', newBillingAccount.id)
        await setDoc(newDocRef, newBillingAccount)

        dispatch(setBillingAccount(newBillingAccount))

        return newBillingAccount
    } catch (error) {
        console.log(error)
    }
}

export const createTeam = async (newTeamData: Partial<TeamDef>, user: any, dispatch: any, auth: any) => {
    try {
        const currentUserDetails = {
            email: user?.email ?? '',
            expenses: 'editor',
            id: auth.currentUser?.uid,
            invoices: 'editor',
            isAdmin: true,
            isSuperAdmin: true,
            payments: 'editor',
            rol: 'admin',
            services: 'editor',
            status: 'active',
        }

        const team = teamDefSet({
            id: generateCode(12, 'team'),
            ...(newTeamData || {}),
            owner: auth.currentUser?.uid,
            membersIds: [auth.currentUser?.uid],
            members: [currentUserDetails],
        })

        await Promise.all([setDoc(doc(getFirestore(), 'teams', team.id), team, { merge: true })])

        dispatch(setTeam(team))

        return team as TeamDef
    } catch (error) {
        console.log(error)
    }

    return null
}
