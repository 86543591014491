import { Typography } from 'antd'
import DynamicTabs from '../components/Menu/Tabs'
import GeneralSettings from '../components/Settings/GeneralSettings'
import DefaultsSettings from '../components/Settings/DefaultsSettings'
// import { SearchParamInURL } from '../functions/helpers';
import APISettings from '../components/Settings/APISettings'
import SeatsSettings from '../components/Settings/SeatsSettings'
import { usePermissions } from '../customHooks/usePermissions'
import { useDispatch, useSelector } from 'react-redux'
import { addParamToUrl, removeParamFromUrl } from '../functions/UrlParams'
import { setData } from '../context/dataSlice'

const SettingsPage: React.FC = () => {
    const dispatch = useDispatch()

    const { tab } = useSelector((state: any) => state.data)

    const { isTeamAdmin, canCreateApiKeys, canCreateWebhooks } = usePermissions()

    // const tab = SearchParamInURL('subtab')

    let tabsConfig = [
        {
            key: 'general',
            title: 'General',
            content: <GeneralSettings />,
        },
        {
            key: 'defaults',
            title: 'Automatizaciones',
            content: <DefaultsSettings />,
        },
        {
            key: 'api',
            title: 'API',
            content: <APISettings />,
        },
        {
            key: 'seats',
            title: 'Equipo',
            content: <SeatsSettings />,
        },
    ]
    const handleTabChange = (activeKey: string) => {
        addParamToUrl('tab', activeKey)
        removeParamFromUrl('subtab')
        dispatch(setData({ item: 'tab', data: activeKey }))
        dispatch(setData({ item: 'subtab', data: null }))
    }

    if (!isTeamAdmin && !canCreateApiKeys && !canCreateWebhooks) {
        tabsConfig = tabsConfig.filter((tab) => tab.key !== 'api')
    }

    if (!isTeamAdmin) {
        tabsConfig = tabsConfig.filter((tab) => tab.key !== 'seats')
    }

    if (!isTeamAdmin) {
        tabsConfig = tabsConfig.filter((tab) => tab.key !== 'defaults')
    }

    return (
        <>
            <Typography.Title className="h2-bold neutral-1">Configuración</Typography.Title>
            <DynamicTabs tabsConfig={tabsConfig} activeKey={tab ?? 'general'} onChange={handleTabChange} />
        </>
    )
}
export default SettingsPage
