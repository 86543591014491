import { Col, Row, Spin, Statistic, Tag, Typography } from 'antd'
import { collection, getFirestore, query, where } from 'firebase/firestore'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useFirestoreCollectionData } from 'reactfire'
import { ResponsiveContainer, AreaChart, Area, Tooltip, XAxis } from 'recharts'
import { returnCurrencyValue } from '../../functions/helpers'
import { ReactElement } from 'react'
import {
    AggregatedData,
    ChartData,
    DailyInsights,
    InsightWraperChildrenProps,
    NestedData,
    ResourceData,
    SubQueryData,
} from './InsightsInterfaces'
import { current } from '@reduxjs/toolkit'

// const sources = [
//     'stripe',
//     'paypal',
//     'openpay',
//     'conekta',
//     'mercado pago',
//     'clip',
//     'manual',
//     'api',
//     'receipt',
//     'substitute',
//     'global',
// ]
const aggregateAmounts = (data: any[], key: string, subKey?: string, subSubKey?: string): number => {
    return data
        .map((d: any) => {
            if (subSubKey) {
                return d[key]?.[subKey ?? '']?.[subSubKey]?.amount ?? 0
            } else if (subKey) {
                return d[key]?.[subKey]?.amount ?? 0
            } else {
                return d[key]?.amount ?? 0
            }
        })
        .reduce((a, b) => a + b, 0)
}
const getNestedData = (resource: ResourceData | undefined, rootData: any): NestedData => {
    const nestedData: NestedData = { availableKeys: [] }

    if (resource) {
        Object.keys(resource).forEach((key) => {
            nestedData.availableKeys.push(key)
            const total = (resource[key] as AggregatedData)?.total ?? 0
            const amount = (resource[key] as AggregatedData)?.amount ?? 0

            nestedData[`${key}Total`] = total
            nestedData[`${key}Amount`] = amount

            rootData[`${key}Total`] = (rootData[`${key}Total`] ?? 0) + total
            rootData[`${key}Amount`] = (rootData[`${key}Amount`] ?? 0) + amount

            if ((resource[key] as AggregatedData)?.hasSubKeys) {
                Object.keys(resource[key] as SubQueryData).forEach((subKey) => {
                    if (subKey !== 'hasSubKeys' && subKey !== 'total' && subKey !== 'amount') {
                        const subResource = (resource[key] as SubQueryData)[subKey]
                        if (subResource) {
                            const subTotal = subResource.total ?? 0
                            const subAmount = subResource.amount ?? 0

                            nestedData[`${key}_${subKey}Total`] = subTotal
                            nestedData[`${key}_${subKey}Amount`] = subAmount

                            rootData[`${key}_${subKey}Total`] = (rootData[`${key}_${subKey}Total`] ?? 0) + subTotal
                            rootData[`${key}_${subKey}Amount`] = (rootData[`${key}_${subKey}Amount`] ?? 0) + subAmount
                        }
                    }
                })
            }
        })
    }

    return nestedData
}
export const InsightsSpace = () => {
    const { team } = useSelector((state: any) => state.team)
    const { period } = useSelector((state: any) => state.data)
    //collection insights team.id daily
    const coll = collection(getFirestore(), 'insights', team.id, 'daily')
    const { status, data } = useFirestoreCollectionData(
        query(
            coll,
            where('reportDate', '>=', period?.start ?? moment().startOf('month').valueOf()),
            where('reportDate', '<=', period?.end ?? moment().endOf('month').valueOf()),
        ),
    )

    if (status === 'loading') {
        return <div>Loading...</div>
    }
    if (data?.length === 0) {
        return (
            <Col xs={24} style={{ padding: '20px 0px' }}>
                <Row justify="center">
                    <Typography.Text className="smallparagraph descriptions">
                        No hay datos para mostrar en el periodo seleccionado
                    </Typography.Text>
                </Row>
            </Col>
        )
    }
    const chartsData = data?.map((d: any) => {
        return {
            name: moment(d.reportDate).format('DD/MM/YYYY'),
            payments: d.payments?.succeeded?.amount,
            paymentsNotSucceeded: Object?.keys(d?.payments)
                .filter((key) => key !== 'succeeded')
                .map((key) => d.payments[key]?.amount ?? 0)
                .reduce((a, b) => a + b, 0),
            invoices: d.invoices?.valid?.amount,
            canceledInvoices: d.invoices?.canceled?.amount,
            receipts: d.receipts?.completed?.amount,
            receiptsPending: d.receipts?.pending?.amount,
            // substitutedInvoices: d.invoices?.
        }
    })

    const payments = {
        succeeded: data?.map((d: any) => d.payments?.succeeded?.amount).reduce((a, b) => a + b, 0),
        pending: Object?.keys(data[0]?.payments)
            .filter((key) => key !== 'succeeded')
            .map((key) => data?.map((d: any) => d.payments[key]?.amount ?? 0).reduce((a, b) => a + b, 0))
            .reduce((a, b) => a + b, 0),
        succeededCount: data?.map((d: any) => d.payments?.succeeded?.total).reduce((a, b) => a + b, 0),
        pendingCount: Object?.keys(data[0]?.payments)
            .filter((key) => key !== 'succeeded')
            .map((key) => data?.map((d: any) => d.payments[key]?.total ?? 0).reduce((a, b) => a + b, 0))
            .reduce((a, b) => a + b, 0),
    }
    const invoices = {
        canceled: data?.map((d: any) => d.invoices?.canceled?.amount).reduce((a, b) => a + b, 0),
        valid: data?.map((d: any) => d.invoices?.valid?.amount).reduce((a, b) => a + b, 0),
        canceledCount: data?.map((d: any) => d.invoices?.canceled?.total).reduce((a, b) => a + b, 0),
        validCount: data?.map((d: any) => d.invoices?.valid?.total).reduce((a, b) => a + b, 0),
    }
    const receipts = {
        pending: data?.map((d: any) => d.receipts?.pending?.amount).reduce((a, b) => a + b, 0),
        completed: data?.map((d: any) => d.receipts?.completed?.amount).reduce((a, b) => a + b, 0),
        pendingCount: data?.map((d: any) => d.receipts?.pending?.total).reduce((a, b) => a + b, 0),
        completedCount: data?.map((d: any) => d.receipts?.completed?.total).reduce((a, b) => a + b, 0),
    }

    const CustomTooltip = ({ active, payload, label }: { active: any; payload: any; label: any }) => {
        if (active && payload && payload.length) {
            return (
                <div
                    className="d-flex flex-column"
                    style={{
                        backgroundColor: 'white',
                        padding: '10px',
                        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                        borderRadius: '5px',
                        minWidth: 'auto',
                        position: 'relative',
                    }}
                >
                    <Typography.Text className="smallparagraph descriptions" style={{ marginBottom: '5px' }}>
                        {label}
                    </Typography.Text>
                    {payload.map((p: any) => {
                        return (
                            <Row key={p.dataKey} style={{ marginBottom: '5px' }}>
                                <Typography.Text className="smallparagraph descriptions" style={{ marginRight: '5px' }}>
                                    {p.display}
                                </Typography.Text>
                                <Tag>{returnCurrencyValue(p.value)}</Tag>
                            </Row>
                        )
                    })}
                </div>
                // <div className="custom-tooltip">
                //     <p className="label">{`${label} : ${payload[0].value}`}</p>
                //     <p className="intro">{getIntroOfPage(label)}</p>
                //     <p className="desc">Anything you want can be displayed here.</p>
                // </div>
            )
        }

        return null
    }

    const InsightCard = ({
        areas,
        gradientColors,
        total,
        title,
        tags,
    }: {
        areas: any[]
        gradientColors: string[]
        total: number
        title: string
        tags: ReactElement[]
    }) => {
        const color1 = gradientColors[0]
        const color2 = gradientColors[1]

        return (
            <Col
                xs={24}
                lg={8}
                xl={5}
                style={{
                    backgroundColor: '',
                }}
            >
                <div
                    style={{
                        position: 'relative',
                        height: '185px',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            zIndex: 2,
                        }}
                    >
                        <ResponsiveContainer width="100%" height="100%">
                            <AreaChart data={chartsData} stackOffset="silhouette">
                                <defs>
                                    <linearGradient
                                        id={`${title?.replace(' ', '')}1`}
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                        // color={color1}
                                    >
                                        <stop offset="5%" color={color1} stopColor={color1} stopOpacity={0.8} />
                                        <stop offset="95%" color={color1} stopColor={color1} stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient
                                        id={`${title?.replace(' ', '')}2`}
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                        // color={color2}
                                    >
                                        <stop offset="5%" color={color2} stopColor={color2} stopOpacity={0.8} />
                                        <stop offset="95%" color={color2} stopColor={color2} stopOpacity={0} />
                                    </linearGradient>
                                </defs>

                                <Tooltip
                                    content={({ active, payload, label }) => (
                                        <CustomTooltip active={active} payload={payload} label={label} />
                                    )}
                                />
                                <XAxis
                                    dataKey="name"
                                    padding="no-gap"
                                    tick={({ x, y, stroke, payload }) => {
                                        return (
                                            <g transform={`translate(${x},${y})`}>
                                                <text
                                                    x={2}
                                                    y={4}
                                                    dy={2}
                                                    textAnchor="start"
                                                    fontSize={'8px'}
                                                    fill="#666"
                                                    transform="rotate(0)"
                                                >
                                                    {payload.value}
                                                </text>
                                            </g>
                                        )
                                    }}
                                />
                                {areas.map((area, index) => {
                                    return area
                                })}
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                    <Statistic
                        style={{
                            width: '80%',
                            position: 'absolute',
                            top: '40%',
                            left: '52%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 1,
                        }}
                        title={
                            <Row>
                                <Typography.Text className="smallparagraph descriptions" style={{ marginRight: '5px' }}>
                                    {title}
                                </Typography.Text>
                                <Row wrap>
                                    {tags.map((tag, index) => {
                                        return tag
                                    })}
                                </Row>
                            </Row>
                        }
                        value={total}
                        precision={2}
                        valueStyle={{ color: '#3f8600' }}
                        // prefix={<ArrowUpOutlined />}
                        prefix="$"
                    />
                </div>
            </Col>
        )
    }

    return (
        <>
            <InsightCard
                title="Pagos completos"
                tags={[<Tag color="#b4e3d1">{payments?.succeededCount}</Tag>]}
                total={payments?.succeeded}
                gradientColors={['#b4e3d1', '#ffdd9f']}
                areas={[
                    <Area
                        type="monotoneX"
                        dataKey="payments"
                        stackId="1"
                        display="Monto cobrado"
                        stroke="#b4e3d1"
                        fillOpacity={1}
                        fill="url(#Pagoscompletos1)"
                    />,
                    <Area
                        type="monotone"
                        dataKey="paymentsNotSucceeded"
                        display="Monto pendiente"
                        activeDot={{ r: 1 }}
                        stackId="1"
                        stroke="#ffdd9f"
                        fillOpacity={1}
                        fill="url(#Pagoscompletos2)"
                    />,
                ]}
            />
            <InsightCard
                title="Facturas validas"
                tags={[<Tag color="#b4e3d1">{invoices.validCount}</Tag>]}
                total={invoices.valid}
                gradientColors={['#b4e3d1', '#ebaeae']}
                areas={[
                    <Area
                        type="monotone"
                        dataKey="invoices"
                        stackId="1"
                        display="Facturas validas"
                        stroke="#b4e3d1"
                        fillOpacity={1}
                        fill="url(#Facturasvalidas1)"
                    />,
                    <Area
                        type="monotone"
                        dataKey="canceledInvoices"
                        display="Facturas canceladas"
                        stackId="1"
                        stroke="#ebaeae"
                        fillOpacity={1}
                        fill="url(#Facturasvalidas2)"
                    />,
                ]}
            />
            <InsightCard
                title="Recibos pendientes"
                tags={[<Tag color="#a0e9ed">{receipts.pendingCount}</Tag>]}
                total={receipts.pending}
                gradientColors={['#b4e3d1', '#a0e9ed']}
                areas={[
                    <Area
                        type="monotone"
                        dataKey="receipts"
                        stackId="1"
                        display="Recibos completos"
                        stroke="#b4e3d1"
                        fillOpacity={1}
                        fill="url(#Recibospendientes1)"
                    />,
                    <Area
                        type="monotone"
                        dataKey="receiptsPending"
                        display="Recibos pendientes"
                        stackId="1"
                        stroke="#a0e9ed"
                        fillOpacity={1}
                        fill="url(#Recibospendientes2)"
                    />,
                ]}
            />
        </>
    )
}

const countElements = (object: any, data: any[], element: string) => {
    const keys = Object.keys(object)

    const elementData = data.map((d) => d[element])

    const singleKeys: string[] = []
    const joinKeys: string[] = []
    let preparedObject: any = {}
    for (const key of keys) {
        let element = object[key]
        let hasSubData = element?.hasSubKeys
        if (!hasSubData) {
            const total = elementData.map((d) => d?.[key]?.total ?? 0).reduce((a, b) => a + b, 0)
            const amount = elementData
                .map((d) => d?.[key]?.amount ?? 0)
                .reduce((a, b) => (isNaN(a) ? 0 : a) + (isNaN(b) ? 0 : b), 0)
            preparedObject[key] = { total, amount }
            singleKeys.push(key)
        } else {
            const subKeys = Object.keys(element)
            const subobject: any = {}
            for (const subKey of subKeys) {
                if (subKey !== 'hasSubKeys' && subKey !== 'total' && subKey !== 'amount') {
                    const total = elementData.map((d) => d?.[key]?.[subKey]?.total ?? 0).reduce((a, b) => a + b, 0)
                    const amount = elementData.map((d) => d?.[key]?.[subKey]?.amount ?? 0).reduce((a, b) => a + b, 0)
                    subobject[subKey] = { total, amount }
                }
            }
            joinKeys.push(key)
            preparedObject[key] = subobject
        }
    }
    // console.log(preparedObject, singleKeys, joinKeys)

    return { ...preparedObject, singleKeys, joinKeys }
}

const InsightSpaceWrapper = ({
    colors,
    children,
    fallback,
}: {
    colors?: string[]
    children: ({
        data,
        pastPeriod,
        invoices,
        payments,
        receipts,
        pastPeriodDates,
    }: {
        data: ChartData[]
        pastPeriod: ChartData[]
        invoices: { current: any; past: any }
        payments: { current: any; past: any }
        receipts: { current: any; past: any }
        pastPeriodDates: { start: number; end: number }
    }) => ReactElement
    fallback?: ReactElement
}) => {
    const { team } = useSelector((state: any) => state.team)
    const { period } = useSelector((state: any) => state.data)
    const coll = collection(getFirestore(), 'insights', team.id, 'daily')
    const { status, data } = useFirestoreCollectionData(
        query(
            coll,
            where('reportDate', '>=', period?.start ?? moment().startOf('month').valueOf()),
            where('reportDate', '<=', period?.end ?? moment().endOf('month').valueOf()),
        ),
    )

    const days = moment(period?.end).diff(moment(period?.start), 'days') + 1
    const isFullMonth = days === moment(period?.end).daysInMonth()

    const { status: status2, data: pastPeriodData } = useFirestoreCollectionData(
        query(
            coll,
            where(
                'reportDate',
                '>=',
                isFullMonth
                    ? moment(period?.start).subtract(1, 'months').startOf('month').valueOf()
                    : moment(period?.start).subtract(days, 'days').valueOf(),
            ),
            where('reportDate', '<=', moment(period?.end).subtract(days, 'days').valueOf()),
        ),
    )

    if (status === 'loading' || status2 === 'loading') {
        return <Spin />
    }

    if (data?.length === 0 && pastPeriodData?.length === 0) {
        return (
            fallback ?? (
                <Col xs={24} style={{ padding: '20px 0px' }}>
                    <Row justify="center">
                        <Typography.Text className="smallparagraph descriptions">
                            No hay datos para mostrar en el periodo seleccionado
                        </Typography.Text>
                    </Row>
                </Col>
            )
        )
    }
    const mapToChartData = (data: DailyInsights[]): ChartData[] => {
        return data.map((d) => {
            const rootData: any = {}

            const chartData: ChartData = {
                name: moment(d.reportDate).format('DD/MM/YYYY'),
                payments: aggregateAmounts([d], 'payments', 'succeeded'),
                paymentsNotSucceeded: aggregateAmounts([d], 'payments'),
                invoices: aggregateAmounts([d], 'invoices', 'valid', 'I'),
                canceledInvoices: aggregateAmounts([d], 'invoices', 'canceled', 'I'),
                receipts: aggregateAmounts([d], 'receipts', 'completed'),
                receiptsPending: aggregateAmounts([d], 'receipts', 'pending'),
                substitutedInvoices: aggregateAmounts([d], 'invoices', 'substitute', 'valid'),
                canceledSubstitutedInvoices: aggregateAmounts([d], 'invoices', 'substitute', 'canceled'),
                paymentOptions: getNestedData(d.payments, rootData),
                invoiceOptions: getNestedData(d.invoices, rootData),
                receiptOptions: getNestedData(d.receipts, rootData),
            }

            return { ...chartData, ...rootData }
        })
    }

    const chartsData = mapToChartData(data as DailyInsights[])
    const pastChartsData = mapToChartData(pastPeriodData as DailyInsights[])

    const totals = {
        payments: {
            current: data[0]?.payments ? countElements(data[0].payments, data, 'payments') : {},
            past: data[0]?.payments ? countElements(data[0].payments, pastPeriodData, 'payments') : {},
        },
        invoices: {
            current: data[0]?.invoices ? countElements(data[0].invoices, data, 'invoices') : {},
            past: data[0]?.invoices ? countElements(data[0].invoices, pastPeriodData, 'invoices') : {},
        },
        receipts: {
            current: data[0]?.receipts ? countElements(data[0].receipts, data, 'receipts') : {},
            past: data[0]?.receipts ? countElements(data[0].receipts, pastPeriodData, 'receipts') : {},
        },
    }

    const returnToChildren: InsightWraperChildrenProps = {
        data: chartsData,
        pastPeriod: pastChartsData,
        invoices: totals.invoices,
        payments: totals.payments,
        receipts: totals.receipts,
        pastPeriodDates: {
            start: isFullMonth
                ? moment(period?.start).subtract(1, 'months').startOf('month').valueOf()
                : moment(period?.start).subtract(days, 'days').valueOf(),
            end: moment(period?.end).subtract(days, 'days').valueOf(),
        },
    }

    return children(
        status === 'success' && status2 === 'success'
            ? returnToChildren
            : {
                  data: [],
                  pastPeriod: [],
                  invoices: {
                      current: {},
                      past: {},
                  },
                  payments: { current: {}, past: {} },
                  receipts: { current: {}, past: {} },
                  pastPeriodDates: {
                      start: isFullMonth
                          ? moment(period?.start).subtract(1, 'months').startOf('month').valueOf()
                          : moment(period?.start).subtract(days, 'days').valueOf(),
                      end: moment(period?.end).subtract(days, 'days').valueOf(),
                  },
              },
    )
}

export default InsightSpaceWrapper
