import { useSelector } from 'react-redux'

export interface PermissionsDef {
    canCreateInvoice: boolean
    canCreateReceipt: boolean
    canCreatePayment: boolean
    canCreateService: boolean
    canCreateClient: boolean
    canCreateVendor: boolean
    canViewPayments: boolean
    canViewInvoices: boolean
    canViewReceipts: boolean
    canViewServices: boolean
    canViewClients: boolean
    canViewVendors: boolean
    canCreateTeam: boolean
    canCreateWebhooks: boolean
    canCreateApiKeys: boolean
    canInstallApps: boolean
    isTeamAdmin: boolean
    isBillingAccountAdmin: boolean
}

export const usePermissions = () => {
    const { team } = useSelector((state: any) => state.team)
    const { user } = useSelector((state: any) => state.user)
    const { billingAccount } = useSelector((state: any) => state.billingAccount)

    const permissions: PermissionsDef = {
        canCreateInvoice: false,
        canCreateReceipt: false,
        canCreatePayment: false,
        canCreateService: false,
        canCreateClient: false,
        canCreateVendor: false,
        canViewPayments: false,
        canViewInvoices: false,
        canViewReceipts: false,
        canViewServices: false,
        canViewClients: false,
        canViewVendors: false,
        canCreateTeam: false,
        canCreateWebhooks: false,
        canCreateApiKeys: false,
        canInstallApps: false,
        isTeamAdmin: false,
        isBillingAccountAdmin: false,
    }

    const member = team?.members?.find((m: any) => m.id === user?.uid)

    if (!member) return permissions

    switch (member.invoices) {
        case 'editor':
            permissions.canCreateInvoice = true
            permissions.canViewInvoices = true
            break
        case 'viewer':
            permissions.canViewInvoices = true
            break
        default:
            break
    }

    switch (member.receipts) {
        case 'editor':
            permissions.canCreateReceipt = true
            permissions.canViewReceipts = true
            break
        case 'viewer':
            permissions.canViewReceipts = true
            break
        default:
            if (member.rol === 'admin') {
                permissions.canCreateReceipt = true
                permissions.canViewReceipts = true
            }
            break
    }

    switch (member.payments) {
        case 'editor':
            permissions.canCreatePayment = true
            permissions.canViewPayments = true
            break
        case 'viewer':
            permissions.canViewPayments = true
            break
        default:
            break
    }

    switch (member.services) {
        case 'editor':
            permissions.canCreateService = true
            permissions.canViewServices = true
            break
        case 'viewer':
            permissions.canViewServices = true
            break
        default:
            break
    }

    switch (member.clients) {
        case 'editor':
            permissions.canCreateClient = true
            permissions.canViewClients = true
            break
        case 'viewer':
            permissions.canViewClients = true
            break
        default:
            break
    }

    /** @note Definitely not sure about this */
    switch (member.vendors) {
        case 'editor':
            permissions.canCreateVendor = true
            permissions.canViewVendors = true
            break
        case 'viewer':
            permissions.canViewVendors = true
            break
        default:
            break
    }

    if (member.rol === 'admin' || member.rol === 'editor') {
        // All permissions are granted
        permissions.canCreateInvoice = true
        permissions.canCreateReceipt = true
        permissions.canCreatePayment = true
        permissions.canCreateService = true
        permissions.canCreateClient = true
        permissions.canViewPayments = true
        permissions.canViewInvoices = true
        permissions.canViewReceipts = true
        permissions.canViewServices = true
        permissions.canViewClients = true
        permissions.canCreateWebhooks = true
        permissions.canInstallApps = true
        permissions.canCreateApiKeys = true
        permissions.isTeamAdmin = true
    }

    if (billingAccount?.admins.includes(user?.uid)) {
        permissions.canCreateTeam = true
        permissions.isBillingAccountAdmin = true
    }

    return permissions
}
