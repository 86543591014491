import { usePostHog } from 'posthog-js/react'
import { useState } from 'react'

interface LogoAndColors {
    logo_url: string
    colors: string[]
    clusters?: number
}

const useLogoAndColors = () => {
    const posthog = usePostHog()
    const [data, setData] = useState<LogoAndColors | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<Error | null>(null)

    const fetchLogoAndColors = async (domain: string) => {
        if (!domain) return

        try {
            setLoading(true)
            try {
                posthog.capture('onboarding_website_fetch_logo', {
                    v: 'v2',
                    domain
                })
            } catch (error) {}
            // Check if running on localhost
            if (window.location.hostname === 'localhost1') {
                // Simulate API delay
                await new Promise((resolve) => setTimeout(resolve, 500))

                // Sample data for localhost
                setData({
                    logo_url: 'https://logo.clearbit.com/https://gigstack.pro',
                    colors: ['#8666fe', '#ade9bb'],
                    clusters: 5,
                })
            } else {
                const response = await fetch('https://us-central1-gigstackpro.cloudfunctions.net/getLogoAndColors', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ domain }),
                })

                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }

                const data = await response.json()

                setData(data)
            }
        } catch (err) {
            console.log('🚀 ~ fetchLogoAndColors ~ err:', err)
            setError(err instanceof Error ? err : new Error('An error occurred'))
        } finally {
            setLoading(false)
        }
    }

    const removeLogo = () => {
        try {
            posthog.capture('onboarding_logo_deleted', {
                v: 'v2',
            })
        } catch (error) {}
        setData((prevData) => (prevData ? { ...prevData, logo_url: '' } : null))
    }

    return {
        logo: data?.logo_url,
        colors: data?.colors,
        loading,
        error,
        fetchLogoAndColors,
        removeLogo, // New function to remove logo
    }
}

export default useLogoAndColors
