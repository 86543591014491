import { Pencil, Trash, X } from '@phosphor-icons/react'
import { Col, Drawer, Row, Typography, Button, Tooltip, Popconfirm } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal, openModal } from '../context/modalsSlice'
import { collection, deleteDoc, doc, getFirestore, orderBy, query, where } from 'firebase/firestore'
import { useFirestoreCollectionData } from 'reactfire'
import { setData } from '../context/dataSlice'

export const TemplateEmailsDrawer = () => {
    const dispatch = useDispatch()
    // const fs = getFirestore();
    const { templatesEmailsDrawerVisible } = useSelector((state: any) => state.modals)
    const { testmode } = useSelector((state: any) => state.data)
    const { team } = useSelector((state: any) => state.team)
    const accountsRef = query(
        collection(getFirestore(), `templates`),
        where('team', '==', team?.id ?? ''),
        orderBy('timestamp', 'desc'),
    )
    const { status: templatesStatus, data: templatesData } = useFirestoreCollectionData(accountsRef, {
        idField: 'id',
    })

    const selectedTemplate = (template: any) => {
        dispatch(
            setData({
                item: 'templateEmails',
                data: template,
            }),
        )
        dispatch(closeModal('templatesEmailsDrawerVisible'))
    }

    return (
        <Drawer
            style={{ marginTop: testmode ? '32px' : '0' }}
            closeIcon={<></>}
            closable={false}
            zIndex={1001}
            className="relevant-drawer"
            title={
                <Row justify="space-between">
                    <Col xs={24}>
                        <div style={{ position: 'relative', paddingBottom: '5px' }}>
                            <Typography.Text
                                className="smallheader"
                                style={{
                                    position: 'absolute',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    paddingTop: '5px',
                                }}
                            >
                                Plantillas para tus correos
                            </Typography.Text>
                            <Row justify="space-between" align="middle">
                                <X
                                    className="icon clickable"
                                    size={20}
                                    onClick={() => dispatch(closeModal('templatesEmailsDrawerVisible'))}
                                />
                                <Button
                                    type="primary"
                                    onClick={() => dispatch(openModal('createNewTemplateEmailModalVisible'))}
                                >
                                    Nueva
                                </Button>
                            </Row>
                        </div>
                    </Col>
                </Row>
            }
            onClose={() => dispatch(closeModal('templatesEmailsDrawerVisible'))}
            open={templatesEmailsDrawerVisible}
            width={'50%'}
        >
            {templatesStatus === 'loading' && (
                <Row>
                    <div>Loading...</div>
                </Row>
            )}
            {templatesData && templatesData.length === 0 && (
                <Row>
                    <div>No hay plantillas disponibles</div>
                </Row>
            )}
            {/* {templatesData && templatesData.length > 0 && (
                <Row>
                    {templatesData.map((template) => (
                        <Col xs={24} key={template.id} >
                            <Row align="middle">
                                <div
                                    onClick={() => selectedTemplate(template)}
                                    className="settingsContainer d-flex flex-column hoverBorderPrimary"
                                    style={{ padding: '10px 15px', marginTop: '10px', flex: 1 }}
                                >
                                    <Typography.Title level={5} style={{ fontSize: '14px' }}>{template.name}</Typography.Title>
                                    <Typography.Text style={{ fontSize: '12px', whiteSpace: 'pre-wrap' }} type="secondary">
                                        {template.content}
                                    </Typography.Text>
                                </div>
                                <Tooltip title="Eliminar plantilla">
                                    <Popconfirm title="¿Estás seguro de eliminar esta plantilla?" onConfirm={async () => {
                                        await deleteDoc(doc(fs, 'templates', template?.id))

                                    }
                                    } okText="Sí" cancelText="No">

                                        <Trash size={20} weight="regular" style={{ marginLeft: '10px' }}
                                        />
                                    </Popconfirm>
                                </Tooltip>

                                <Pencil size={20} weight="regular" style={{ marginLeft: '10px' }} onClick={() => {
                                    dispatch(openModal('createNewTemplateEmailModalVisible'));
                                    dispatch(setData({
                                        item: 'templateEmails',
                                        data: template
                                    }))
                                }
                                }
                                />
                            </Row>
                        </Col>
                    ))}
                </Row>
            )} */}
            {templatesData && templatesData.length > 0 && (
                <Row gutter={[16, 16]}>
                    {templatesData.map((template) => (
                        <Col xs={24} key={template.id}>
                            <Row align="middle">
                                <div
                                    onClick={() => selectedTemplate(template)}
                                    className="settingsContainer d-flex flex-column hoverBorderPrimary"
                                    style={{
                                        padding: '10px 15px',
                                        marginTop: '10px',
                                        flex: 1,
                                        border: '1px solid #ccc',
                                        borderRadius: '8px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Typography.Title level={5} style={{ fontSize: '14px' }}>
                                        {template.name}
                                    </Typography.Title>
                                    <Typography.Text
                                        style={{ fontSize: '12px', whiteSpace: 'pre-wrap' }}
                                        type="secondary"
                                    >
                                        {template.content}
                                    </Typography.Text>
                                </div>
                                <Tooltip title="Eliminar plantilla">
                                    <Popconfirm
                                        title="¿Estás seguro de eliminar esta plantilla?"
                                        onConfirm={async () => {
                                            await deleteDoc(doc(getFirestore(), 'templates', template?.id))
                                        }}
                                        okText="Sí"
                                        cancelText="No"
                                    >
                                        <Trash size={20} weight="regular" style={{ marginLeft: '10px' }} />
                                    </Popconfirm>
                                </Tooltip>
                                <Pencil
                                    size={20}
                                    weight="regular"
                                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                                    onClick={() => {
                                        dispatch(openModal('createNewTemplateEmailModalVisible'))
                                        dispatch(
                                            setData({
                                                item: 'templateEmails',
                                                data: template,
                                            }),
                                        )
                                    }}
                                />
                            </Row>
                        </Col>
                    ))}
                </Row>
            )}
        </Drawer>
    )
}
