import React, { useState } from 'react'
import { Alert, Button, Col, Descriptions, Form, Image, Modal, Row, Space, Tag, Typography, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
import { X } from '@phosphor-icons/react'
import moment from 'moment'
import { SelectInput } from '../components/Forms/Inputs'
import { paymentForms } from '../datasets/Fiscal'
import { getItemsAmounts } from '../functions/helpers'
import { SignedInternalAPIRequest } from '../functions/APIRequest'
import { useAuth, useFirestoreDocData } from 'reactfire'
import { arrayUnion, doc, getFirestore, updateDoc } from 'firebase/firestore'

const ApprovePayment: React.FC = () => {
    const dispatch = useDispatch()
    const { approveModalVisible } = useSelector((state: any) => state.modals)
    const { payment } = useSelector((state: any) => state.data)
    const auth = useAuth()
    const [loading, setLoading] = useState<string[]>([])
    const [form] = Form.useForm()
    const fs = getFirestore()

    const paymentRef = doc(fs, 'payments', payment.fid ?? payment.id ?? '')
    const { data } = useFirestoreDocData(paymentRef, {
        suspense: true,
    })

    const RetryPayment = async () => {
        try {
            setLoading([...loading, 'retry'])
            const body = {
                emailSubject: 'Pago no recibido', //required
                emailBody: `Hola ${
                    payment.client?.name ?? ''
                }, te contactamos ya que el pago que fue marcado como realizado por la cantidad de ${
                    getItemsAmounts(payment.items, payment.discount).totalString
                }, ${moment(payment.review_created_at).fromNow(true)}.`, //required
                emails: [payment.client?.email], //required
                message: `Hola ${
                    payment.client?.name ?? ''
                }, te contactamos ya que el pago que marcaste como completado hace ${moment(
                    payment.review_created_at,
                ).fromNow(true)} por un total de ${
                    getItemsAmounts(payment.items, payment.discount).totalString
                }, no ha sido recibido.`,
                messageTwo: `Si tienes dudas por favor responde a este correo.`,
                messageThree: '',
                goodbye: 'Por favor realiza el pago cuanto antes.',
                actiondisplay: 'display:flex;',
                action: 'Realizar pago', //BUTTON TEXT
                link: `${payment.shortURL}`, //BUTTON ACTION LINK
                type: 'payments', //payments,proposals, invoices,notes, etc.
                hrType: 'pagos', //Human readable type ex: Payment
                elementID: payment.fid, //ID of the element, ex: payment id.
            }
            if (payment.client) {
                await SignedInternalAPIRequest(body, 'requestSendEmailSeats', auth.currentUser)
            }

            await updateDoc(doc(getFirestore(), `payments/${payment.fid}`), {
                review_status: null,
                status: 'requires_payment_method',
                updatedBy: arrayUnion({
                    email: auth.currentUser?.email,
                    note: 'Status changed',
                    timestamp: moment().valueOf(),
                    uuid: auth.currentUser?.uid,
                }),
            })

            dispatch(closeModal('approveModalVisible'))
            setLoading([])
        } catch (error: any) {
            message.error(error.message)
            setLoading(loading.filter((item) => item !== 'retry'))
        }
    }

    const ReceivePayment = async (vals: any) => {
        try {
            setLoading([...loading, 'accept'])
            await updateDoc(doc(getFirestore(), `payments/${payment.fid}`), {
                review_status: 'succeeded',
                status: 'succeeded',
                manualSuccess: true,
                succeededTimestamp: moment().valueOf(),
                updatedBy: arrayUnion({
                    email: auth.currentUser?.email,
                    note: 'Payment approved',
                    timestamp: moment().valueOf(),
                    uuid: auth.currentUser?.uid,
                }),
                payment_form: vals.payment_form,
                paidIn: vals.payment_form === '03' ? 'bank' : vals.payment_form,
            })
            message.success('Pago confirmado')
            dispatch(closeModal('approveModalVisible'))
            setLoading([])
        } catch (error: any) {
            message.error(error.message)
            setLoading(loading.filter((item) => item !== 'accept'))
        }
    }

    const details = [
        {
            key: 'bank',
            label: <Typography.Text className="smallparagraphbold">Banco</Typography.Text>,
            children: (
                <Typography.Text className="smallparagraph ">
                    {(payment?.bank?.bank || data?.bank?.bank) ?? 'S/N'}{' '}
                </Typography.Text>
            ),
        },
        {
            key: 'clabe',
            label: <Typography.Text className="smallparagraphbold">CLABE</Typography.Text>,
            children: (
                <Typography.Text className="smallparagraph ">
                    {(payment?.bank?.clabe || data?.bank?.clabe) ?? 'S/N'}{' '}
                </Typography.Text>
            ),
        },
        {
            key: 'reference',
            label: <Typography.Text className="smallparagraphbold">Referencia / Concepto</Typography.Text>,
            children: (
                <Typography.Text className="smallparagraph ">
                    {(payment?.conceptReference || data?.conceptReference) ?? 'S/N'}{' '}
                </Typography.Text>
            ),
        },
        {
            key: 'date',
            label: <Typography.Text className="smallparagraphbold">Solicitud de revisión</Typography.Text>,
            children: (
                <Typography.Text className="smallparagraph ">
                    {moment(data?.review_created_at).format('DD/MM/YYYY HH:mm')}{' '}
                </Typography.Text>
            ),
        },
        {
            key: 'id',
            label: <Typography.Text className="smallparagraphbold">Identificar</Typography.Text>,
            children: (
                <Typography.Text className="smallparagraph ">{payment?.fid ?? payment?.id ?? ''}</Typography.Text>
            ),
        },
        {
            key: 'total',
            label: <Typography.Text className="smallparagraphbold">Total</Typography.Text>,
            children: (
                <Typography.Text className="smallparagraph ">{getItemsAmounts(payment?.items).total}</Typography.Text>
            ),
        },
    ]

    return (
        <Modal
            open={approveModalVisible}
            width={window.innerWidth > 768 ? '60%' : '90%'}
            style={{ top: 5 }}
            closable={false}
            footer={null}
            onCancel={() => dispatch(closeModal('approveModalVisible'))}
            title={
                <div style={{ position: 'relative', paddingBottom: '15px' }}>
                    <Typography.Text
                        className="smallheader"
                        style={{
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            paddingTop: '5px',
                        }}
                    >
                        Revisar pago
                    </Typography.Text>
                    <Row>
                        <Col xs={24} style={{ margin: 'auto' }}>
                            <Row justify="space-between" align="middle">
                                <X
                                    className="icon clickable"
                                    size={20}
                                    onClick={() => {
                                        dispatch(closeModal('approveModalVisible'))
                                    }}
                                />
                                <></>
                                <></>
                            </Row>
                        </Col>
                    </Row>
                </div>
            }
        >
            <Row>
                <Col xs={24} lg={24}>
                    <div
                        style={{
                            maxWidth: '100%',
                            padding: '20px',
                            borderRadius: '10px',
                            background:
                                'linear-gradient(180deg, rgba(186, 216, 239, 0.34) 0%, rgba(134, 102, 255, 0.06) 100%)',
                        }}
                        className="d-flex flex-column"
                    >
                        <Descriptions
                            className=""
                            column={2}
                            title={
                                <Row justify="space-between" align="middle">
                                    <Typography.Text className="mediumparagraphbold">
                                        Revisión del pago de {payment?.client?.name}{' '}
                                        {payment?.client?.company && (
                                            <Tag style={{ marginLeft: '5px' }} color="blue">
                                                {' '}
                                                {payment?.client?.company}
                                            </Tag>
                                        )}
                                    </Typography.Text>
                                    <></>
                                </Row>
                            }
                            layout="vertical"
                            size="small"
                            items={details}
                        />
                    </div>
                </Col>
                <Col xs={24} style={{ margin: '5px 0px' }}>
                    {(payment?.voucherConceptExists || data?.voucherConceptExists) && (
                        <Alert
                            type="success"
                            closable
                            message={
                                <Typography.Text className="smallheader">
                                    La referencia {payment?.conceptReference ?? data?.conceptReference ?? ''} existe en
                                    la captura.
                                </Typography.Text>
                            }
                            description={
                                <Typography.Text className="smallparagraph descriptions">
                                    Hemos analizado la imagen y hemos encontrado la referencia{' '}
                                    {payment?.conceptReference ?? data?.conceptReference ?? ''} en la captura, sin
                                    embargo te recomendamos revisar el banco.
                                </Typography.Text>
                            }
                        />
                    )}
                </Col>
                {(payment?.clientVoucher || data?.clientVoucher) && (
                    <Col xs={24} lg={24} className="d-flex flex-column" style={{ marginTop: '10px' }}>
                        <label>Comprobante</label>
                        <Image
                            src={payment?.clientVoucher ?? data?.clientVoucher}
                            style={{ maxWidth: '200px', maxHeight: '250px' }}
                        />
                    </Col>
                )}
                <Col xs={24}>
                    <Form form={form} onFinish={ReceivePayment} layout="vertical">
                        <SelectInput
                            rules={[
                                {
                                    required: true,
                                    message: 'Selecciona el método de pago',
                                },
                            ]}
                            label="Selecciona el método de pago"
                            placeholder="Selecciona el método de pago"
                            options={paymentForms}
                            name={'payment_form'}
                        />

                        <Row justify="center">
                            <Space>
                                <Button
                                    danger
                                    loading={loading.includes('retry')}
                                    onClick={RetryPayment}
                                    disabled={loading.length > 0}
                                >
                                    No he recibido el pago
                                </Button>
                                <Button
                                    loading={loading.includes('accept')}
                                    disabled={loading.length > 0}
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Aprobar pago
                                </Button>
                            </Space>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Modal>
    )
}

export default ApprovePayment
