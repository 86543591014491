import { Col, Row, Typography } from 'antd'
import React, { ReactNode } from 'react'

interface SettingsDividerProps {
    title: string
    description: string
    options: ReactNode[]
}

const SettingsDivider: React.FC<SettingsDividerProps> = ({ title, description, options }) => {
    return (
        <Col xs={24}>
            <Row
                gutter={{
                    xs: 10,
                    md: 12,
                }}
            >
                <Col xs={24} lg={8}>
                    <div className="d-flex flex-column">
                        <Typography.Text className="mediumheader">{title}</Typography.Text>
                        <Typography.Text className="smallparagraph descriptions" style={{ marginTop: '5px' }}>
                            {description}
                        </Typography.Text>
                    </div>
                </Col>
                <Col xs={24} lg={14}>
                    <Row gutter={{ xs: 10, md: 12, lg: 12, xl: 12 }} wrap>
                        {options}
                    </Row>
                </Col>
            </Row>
        </Col>
    )
}

export default SettingsDivider
