export interface FolioDef {
    automaticFolio: number
    automaticFolioTest: number
    forceAutomaticFolio: boolean
    customFolio: number
}

export const FolioDefSet = (object: any) => {
    const folio: FolioDef = {
        automaticFolio: object.automaticFolio ?? null,
        automaticFolioTest: object.automaticFolioTest ?? null,
        forceAutomaticFolio: object.forceAutomaticFolio ?? false,
        customFolio: object.customFolio ?? null,
    }
    return folio
}
