import React, { useState } from 'react'
import { Button, Divider, Form, Row, Typography } from 'antd'
import { CheckBoxInput, NumberInput, SelectInput, TextInput } from './Inputs'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../context/modalsSlice'

interface TaxesFormProps {
    incomingForm?: any
    returnCreated?: (data: any) => void,
    description?: string,
}

const TaxesForm: React.FC<TaxesFormProps> = ({ incomingForm, returnCreated, description }) => {
    const { invoiceComplement } = useSelector((state: any) => state.data)

    const [form] = Form.useForm()
    const useForm = incomingForm ?? form
    const dispatch = useDispatch()

    const [isIEPS, setIsIEPS] = useState(false)
    const [customTaxes, setCustomTaxes] = useState(false)
    // const [ivaSelected, setIvaSelected] = useState(false)
    const [isrSelected, setIsrSelected] = useState(false)

    const taxOptions = [
        {
            label: 'IVA',
            value: 'IVA',
        },
        {
            label: 'ISR',
            value: 'ISR',
        },
        {
            label: 'IEPS',
            value: 'IEPS',
        },
    ]

    const filterTaxOptions = () => {
        return taxOptions.filter((option) => {
            // if (option.value === 'IVA' && ivaSelected) {
            //     return false
            // }
            if (option.value === 'ISR' && isrSelected) {
                return false
            }
            return true
        })
    }

    return (
        <Form
            form={useForm}
            layout="vertical"
            style={{ marginTop: '15px' }}
            onValuesChange={() => {
                // const iva_16 = useForm.getFieldValue('iva_16')
                // const iva_no_16 = useForm.getFieldValue('iva_no_16')
                const isr_30 = useForm.getFieldValue('isr_30')
                const isr_no_30 = useForm.getFieldValue('isr_no_30')
                // setIvaSelected(iva_16 || iva_no_16)
                setIsrSelected(isr_30 || isr_no_30)
            }}
            onFinish={(v) => {
                if (returnCreated)
                    returnCreated({
                        ...v,
                        withholding: v.withholding ?? false,
                        inclusive: v.inclusive ?? false,
                    })
            }}
        >
            <Typography.Text className="h5-bold neutral-1">Selecciona los impuestos</Typography.Text>
            {description && <Typography.Text className="p-base-regular neutral-3">{description}</Typography.Text>}

            <Divider style={{ border: 'none', margin: '10px 0px' }} />
            
            <CheckBoxInput
                name="iva_16"
                propsStyle={{ marginBottom: '0px' }}
                content={<Typography.Text className="p-base-regular neutral-1">IVA incluído (16%)</Typography.Text>}
                onChange={() => {
                    if (useForm.getFieldValue('iva_no_16')) {
                        useForm.setFieldsValue({ iva_no_16: false })
                    }
                }}
            />
            <CheckBoxInput
                name="iva_no_16"
                content={<Typography.Text className="p-base-regular neutral-1">IVA no incluído (16%)</Typography.Text>}
                onChange={() => {
                    if (useForm.getFieldValue('iva_16')) {
                        useForm.setFieldsValue({ iva_16: false })
                    }
                }}
            />

            <Divider style={{ border: 'none', margin: '10px 0px' }} />

            <CheckBoxInput
                name="isr_30"
                propsStyle={{ marginBottom: '0px' }}
                content={<Typography.Text className="p-base-regular neutral-1">ISR incluído (30%)</Typography.Text>}
                onChange={() => {
                    if (useForm.getFieldValue('isr_no_30')) {
                        useForm.setFieldsValue({ isr_no_30: false })
                    }
                }}
            />

            <CheckBoxInput
                name="isr_no_30"
                content={<Typography.Text className="p-base-regular neutral-1">ISR no Incluido (30%)</Typography.Text>}
                onChange={() => {
                    if (useForm.getFieldValue('isr_30')) {
                        useForm.setFieldsValue({ isr_30: false })
                    }
                }}
            />

            <Divider style={{ border: 'none', margin: '10px 0px' }} />

            <CheckBoxInput
                name="customTax"
                content={<Typography.Text className="p-base-regular neutral-1">Personalizado</Typography.Text>}
                onChange={() => setCustomTaxes(!customTaxes)}
            />

            {customTaxes && (
                <>
                    <SelectInput
                        options={filterTaxOptions()}
                        onChange={(value: any) => {
                            if (value === 'IEPS') setIsIEPS(true)
                            else setIsIEPS(false)
                        }}
                        name="type"
                        placeholder="Tipo de impuesto"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor añade el tipo impuesto',
                            },
                        ]}
                        label="Tipo de impuesto"
                    />
                    <SelectInput
                        options={[
                            {
                                label: 'Tasa',
                                value: 'Tasa',
                            },
                            {
                                label: 'Cuota',
                                value: 'Cuota',
                            },
                            {
                                label: 'Exento',
                                value: 'Exento',
                            },
                        ]}
                        name="factor"
                        placeholder="Factor del impuesto"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor añade el factor del impuesto',
                            },
                        ]}
                        label="Factor del impuesto"
                    />
                    <NumberInput
                        rules={[
                            {
                                required: true,
                                message: 'Por favor añade el monto del impuesto',
                            },
                        ]}
                        name="rate"
                        label="Tasa"
                        min={0.0}
                        max={1}
                        step={0.01}
                        placeholder="Tasa del impuesto"
                    />
                    {invoiceComplement?.currentPaymentAmount && (
                        <NumberInput
                            name="base"
                            label="Base"
                            min={0.0}
                            max={1000000000000000}
                            step={0.01}
                            placeholder="Base del impuesto"
                        />
                    )}
                    {isIEPS && (
                        <TextInput name="ieps_mode" label="Modo de IEPS" placeholder="Modo de IEPS" readOnly={true} />
                    )}

                    <CheckBoxInput
                        name="inclusive"
                        propsStyle={{ marginBottom: '0px' }}
                        content={
                            <Typography.Text className="p-base-regular neutral-1">
                                Impuesto incluido en el precio
                            </Typography.Text>
                        }
                    />
                    <CheckBoxInput
                        name="withholding"
                        content={
                            <Typography.Text className="p-base-regular neutral-1">
                                Retenido por tu cliente
                            </Typography.Text>
                        }
                    />
                </>
            )}

            <Row justify="end" style={{ marginTop: '15px' }}>
                <Button
                    className="btn-secondary"
                    style={{
                        marginRight: '10px',
                    }}
                    onClick={() => {
                        useForm.resetFields()
                        dispatch(closeModal('addTaxModalVisible'))
                    }}
                >
                    Cancelar
                </Button>
                <Button className="btn-primary" htmlType="submit">
                    Añadir
                </Button>
            </Row>
        </Form>
    )
}

export default TaxesForm
