import { Col, Drawer, Row, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
import { MassiveInvoiceStatusTable } from '../components/Tables/MassiveInvoiceStatusTable'
import { X } from '@phosphor-icons/react'

export const MassiveInvoiceStatusDrawer = () => {
    const dispatch = useDispatch()
    const { massiveInvoiceStatusDrawerVisible } = useSelector((state:any) => state.modals)
    const { testmode } = useSelector((state:any) => state.data)

    return (
        <Drawer
            open={massiveInvoiceStatusDrawerVisible}
            style={{ marginTop: testmode ? '32px' : '0px', paddingBottom: testmode ? '32px' : '0px' }}
            title={
                <div style={{ position: 'relative', paddingBottom: '15px' }}>
                    <Typography.Text
                        className="smallheader"
                        style={{
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            paddingTop: '5px',
                        }}
                    >
                        Estado de facturación masiva
                    </Typography.Text>
                    <Row>
                        <Col xs={24} style={{ margin: 'auto' }}>
                            <Row justify="space-between" align="middle">
                                <X
                                    className="icon clickable"
                                    size={20}
                                    onClick={() => {
                                        dispatch(closeModal('massiveInvoiceStatusDrawerVisible'))
                                    }}
                                />
                                <></>
                            </Row>
                        </Col>
                    </Row>
                </div>
            }
            onClose={() => dispatch(closeModal('massiveInvoiceStatusDrawerVisible'))}
            closable={false}
            placement="right"
            width="100%"
            height="100%"
            closeIcon = {false}
        >
            <Row justify="center">
                <Col xs={23} lg={20} xl={20} xxl={18} style={{ margin: 'auto' }}>
                    {massiveInvoiceStatusDrawerVisible && <MassiveInvoiceStatusTable />}
                </Col>
            </Row>
        </Drawer>
    )
}
