import { createSlice } from '@reduxjs/toolkit'
import { TeamDef, teamDefSet } from '../../interfaces/teamDef'
import { doc, getDoc, getFirestore } from 'firebase/firestore'
import { userDefSet } from '../../interfaces/userDef'

interface TeamState {
    team: TeamDef
    teams: TeamDef[]
}

const initialState: TeamState = {
    team: {} as TeamDef,
    teams: [],
}

const getTeamInfo = (teamId: string) => {
    const fs = getFirestore()
    try {
        const ba = userDefSet(
            getDoc(doc(fs, 'teams', teamId)).then((doc) => {
                if (doc.exists()) {
                    return doc.data()
                } else {
                    throw new Error('Team account not found')
                }
            }),
        )
        return ba
    } catch (error) {
        throw error
    }
}

export const teamSlice: any = createSlice({
    name: 'team',
    initialState,
    reducers: {
        setTeam: (state, action) => {
            state.team = action.payload
        },
        getTeam: (state, action) => {
            state.team = teamDefSet(getTeamInfo(action.payload))
        },
        setTeamsBA: (state, action) => {
            state.teams = action.payload
        },
        pushTeamsBA: (state, action: any) => {
            state.teams = [...state.teams, action as TeamDef] // TODO:  Review this line
        },
        setTeams: (state, action) => {
            state.teams = action.payload
        },
    },
})

export const { setTeam, setTeamsBA, pushTeamsBA, setTeams, getTeam } = teamSlice.actions

export default teamSlice.reducer
