import { Users } from '@phosphor-icons/react'
import { Avatar, Col, Typography } from 'antd'
import { collection, getCountFromServer, getFirestore, query, where } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export const ClientsAmount = () => {
    const { team } = useSelector((state: any) => state.team)
    const formateer = new Intl.NumberFormat('es-MX', {
        style: 'decimal',
        currency: 'MXN',
        minimumFractionDigits: 0,
    })
    const q = query(collection(getFirestore(), 'clients'), where('team', '==', team?.id), where('livemode', '==', true))
    const [clientsCount, setClientsCount] = useState(0)
    useEffect(() => {
        if (clientsCount !== 0) return
        getCountFromServer(q).then((count) => {
            setClientsCount(count.data().count)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Col xs={24} lg={7}>
            <div
                className="d-flex flex-row"
                style={{ backgroundColor: '#F8F8F8', padding: '5px', borderRadius: '8px', marginBottom: '10px' }}
            >
                <Avatar
                    style={{ backgroundColor: 'white', marginRight: '10px' }}
                    icon={<Users weight="regular" color="#334055" />}
                />
                <Typography.Text className="smallparagraph">{formateer.format(clientsCount)} clientes</Typography.Text>
            </div>
        </Col>
    )
}
