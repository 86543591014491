import { useEffect, useRef, useState } from 'react'
import { Button, Input, InputRef, Space, Table, Tooltip, Typography } from 'antd'
import {
    query,
    collection,
    orderBy,
    getFirestore,
    where,
    limit,
    startAfter,
    getCountFromServer,
    getDocs,
} from 'firebase/firestore'
import { useSelector, useDispatch } from 'react-redux'
import { useFirestoreCollectionData } from 'reactfire'
import { MagnifyingGlass, X } from '@phosphor-icons/react'
import { FilterConfirmProps } from 'antd/lib/table/interface'
import { ColumnType } from 'antd/es/table'
import { SearchParamInURL } from '../../functions/helpers'
import { ServicesColumns } from './Columns/ServicesColumns'
import { InternalItem, internalItemSet } from '../../../interfaces/internalItemDef'
import { setData } from '../../context/dataSlice'
import { useNavigate } from 'react-router-dom'

const ServicesTable = ({ max: incomingMax = 10 }: { max: number }) => {
    type Def = InternalItem
    type DataIndex = keyof Def
    let def: InternalItem

    const { selectedServices } = useSelector((state: any) => state.data)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const source = 'services'
    const defSet = (data: any) => {
        return internalItemSet(data)
    }

    // IMPORTS
    const fs = getFirestore()

    // SELECTORS
    const { team } = useSelector((state: any) => state.team)

    // REFS
    const searchInput = useRef<InputRef>(null)

    // CONTEXTS
    const ps = SearchParamInURL('ps') ?? 10
    const p = SearchParamInURL('page') ?? 1

    // STATES
    const [newLoading, setNewLoading] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    const [pageSize, setPageSize] = useState(ps ?? 10)
    const [currentPage, setCurrentPage] = useState(p ?? 1)
    const [items, setItems] = useState<Def[]>([])

    const [activatePages, setActivatePages] = useState(true)

    // VARIABLES
    let resetTrigger = false
    let max = p !== 1 ? Number(ps) * Number(p) : incomingMax

    // CONSTANTS
    let initialQuery = query(
        collection(fs, source),
        where('team', '==', team?.id ?? ''),
        orderBy('timestamp', 'desc'),
        limit(max),
    )
    let totalCountQuery = query(
        collection(fs, source),
        where('team', '==', team?.id ?? ''),
        orderBy('timestamp', 'desc'),
    )

    const { status: initialLoadStatus, data } = useFirestoreCollectionData(initialQuery, {
        idField: 'id',
    })

    //EFFECTS
    useEffect(() => {
        getInitialCount()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, team?.id])

    // HANDLERS
    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        setActivatePages(resetTrigger || !selectedKeys[0] ? true : false)
        confirm()
        resetTrigger = false
    }

    const handleReset = (clearFilters: () => void) => {
        resetTrigger = true
        clearFilters()
    }

    //FUNCTIONS
    const getInitialCount = async () => {
        setTotalCount((await getCountFromServer(totalCountQuery)).data().count)
    }

    const getNewData = async ({ lastItem: newItem, ps, p }: { lastItem: number; ps: number; p: number }) => {
        if (ps * p < items.length) return
        setNewLoading(true)
        let newItems = await getDocs(
            query(
                collection(fs, source),
                where('team', '==', team?.id ?? ''),
                orderBy('timestamp', 'desc'),
                limit(ps * p - items.length + 1),
                startAfter(newItem),
            ),
        )

        setItems([...items, ...(newItems.docs.map((c) => defSet(c?.data())) ?? [])])
        setNewLoading(false)
    }

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<Def> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Tooltip
                    title={`Solo buscará en los productos/servicios que ya se hayan cargado en la lista (${
                        [...(data ?? [] ?? []), ...items].length
                    } de ${totalCount}).`}
                >
                    <Typography.Text className="smallparagraph descriptions" style={{ marginBottom: '5px' }}>
                        Búsqueda local
                    </Typography.Text>
                </Tooltip>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Typography.Text
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch(selectedKeys as string[], confirm, dataIndex)
                        }}
                        className="smallparagraph descriptions clickable"
                    >
                        Restablecer
                    </Typography.Text>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<MagnifyingGlass />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close()
                        }}
                    >
                        <X className="icon" />
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => {
            return (
                <MagnifyingGlass
                    style={{
                        color: filtered ? '#8666FF' : '#50566B',
                    }}
                    size={16}
                />
            )
        },
        onFilter: (value, record) => {
            const filterValue =
                (record[dataIndex] ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                (record.id ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase())

            return filterValue
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
            }
        },
    })

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: InternalItem[]) => {
            dispatch(
                setData({
                    item: 'selectedServices',
                    data: selectedRows,
                }),
            )
        },

        getCheckboxProps: (record: InternalItem) => ({
            disabled: !record.id, // Column configuration not to be checked
            name: record.id,
        }),
    }

    let keysToShow: any = {
        name: 'Concepto',
        // quantity: 'Cantidad',
        price: 'Precio',
        taxes: 'Impuestos',
        // total: 'Total',
        actions: 'Acciones',
    }

    return (
        <div>
            <Table
                rowKey={(rec) => rec?.id ?? 'as'}
                scroll={{ x: 800 }}
                loading={initialLoadStatus === 'loading' || newLoading}
                rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: [...(selectedServices || [])?.map((c: InternalItem) => c?.id)],
                    ...rowSelection,
                }}
                pagination={
                    activatePages
                        ? {
                              total: totalCount,
                              defaultPageSize: Number(pageSize),
                              defaultCurrent: Number(currentPage),
                              pageSize: Number(pageSize),
                              current: Number(currentPage),
                              onChange(p, ps) {
                                  setPageSize(ps)
                                  setCurrentPage(p)
                                  const allItems = [...data, ...items]
                                  getNewData({
                                      lastItem: allItems[allItems.length - 1]?.timestamp,
                                      ps,
                                      p,
                                  })
                                  const path = `/products?page=${p}&ps=${ps}`
                                  navigate(path)
                              },
                          }
                        : {
                              defaultPageSize: Number(pageSize),
                              pageSize: Number(pageSize),
                          }
                }
                columns={[
                    ...Object.keys(keysToShow).map((k: any) => {
                        let el: any = {
                            title: keysToShow[k],
                            dataIndex: k,
                            render: (text: any, record: typeof def) => ServicesColumns({ record, columnName: k }),
                        }
                        if (k === 'actions') {
                            el['fixed'] = 'right'
                            el['width'] = '15%'
                        }
                        if (k === 'name') {
                            el['fixed'] = 'left'
                            el['width'] = '30%'
                        }
                        return el
                    }),
                ].map((col) => {
                    let column = col
                    if (col.dataIndex === 'name') {
                        column = {
                            ...column,
                            ...getColumnSearchProps('name'),
                        }
                    }
                    return column
                })}
                dataSource={[...(data ?? [] ?? []), ...items]?.map((d: any) => defSet(d))}
            />
        </div>
    )
}

export default ServicesTable
