import { X } from '@phosphor-icons/react'
import { Col, Typography, Row, Tag } from 'antd'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

interface HeaderProps {
    data: any
    title?: string
}

const HeaderForViewDetail: React.FC<HeaderProps> = ({ title, data }) => {
    const navigate = useNavigate()
    const { anchorLocation } = useSelector((state: any) => state.data)

    const back = () => { 
        if (anchorLocation) return navigate(anchorLocation)
        else return window.history.back()
    }
    return (
        <Row>
            <Col
                xs={24}
                style={{
                    borderBottom: '1px solid #D8DAE5',
                    paddingBottom: '15px',
                }}
            >
                <Row justify="space-between" align="middle">
                    <Row align="middle">
                        <X size={20} className="icon clickable" onClick={back} style={{ marginRight: '5px' }} />
                        <Typography.Title
                            className="mainheader"
                            style={{ margin: 0, padding: 0, marginLeft: '10px' }}
                            level={5}
                        >
                            <span>{(title ?? '')?.charAt(0).toUpperCase() + (title ?? '')?.slice(1)}</span>
                            {/* de  {data?.client?.name ?? ''} por {returnCurrencyValue(data?.total ?? (data?.amount / 100) ?? 0)} {data?.currency?.toUpperCase()} */}
                        </Typography.Title>
                    </Row>
                    {!data?.livemode && <Tag color="blue">Modo de prueba</Tag>}
                </Row>
            </Col>
        </Row>
        // <div>

        //     <Row justify="start" style={{ marginLeft: '25px' }}>
        //         <Typography.Text type="secondary">
        //             Creado el {moment(data?.timestamp ?? 0).format('DD/MM/YYYY HH:mm')}
        //         </Typography.Text>
        //     </Row>
        // </div>
    )
}

export default HeaderForViewDetail
