import { ClientDef, clientSet } from './clientDef'
import { InternalItem } from './internalItemDef'

export interface ReceiptDef {
    id: string
    livemode: boolean
    amount: number
    currency: string
    clientId: string
    clientID: string
    client: ClientDef
    timestamp: number
    validUntil: number
    invoice: string | null
    status: string
    url?: string
    items: InternalItem[]
    internalItems: InternalItem[]
    description: string
    disallowInvoice: boolean
    errorMessage?: string
    payments?: string[]
    pdf: string
    periodicity: string
    shortUrl?: string
    stripeId: string
    total: number
    metadata?: any
    PDFExpiresAt: number
    from?: string
    exchange: number
    test: boolean
    payment_form: string
    invoices?: string[]
    receipts?: string[]
    paymentMethod: string
    invoiceErrorMessage?: string | null
    invoiceError?: string | null
    whmcsInvoiceId?: number
    hilosResponse?: any
}

export interface OptimizedReceiptDef {
    id: string
    livemode: boolean
    amount: number
    items: InternalItem[]
    currency: string
    clientId: string
    client: ClientDef
    timestamp: number
    validUntil: number
    invoice: string | null
    status: string
    paymentMethod: string
    shortUrl?: string
    from?: string
    invoices?: string[]
    receipts?: string[]
    payments?: string[]
    payment_form: string
    metadata: any
    disallowInvoice?: boolean
    whmcsInvoiceId?: number
    hilosResponse?: any
    invoiceError?: string | null
    invoiceErrorMessage?: string | null
    url?: string
}

export const receiptDefSet = (object: any) => {
    const receipt: ReceiptDef = {
        id: object['id'] ?? object['fid'] ?? null,
        livemode: object['livemode'] ?? null,
        amount: object['total'] ?? object['amount'] ?? null,
        currency: object['currency'] ?? null,
        clientId: object['clientId'] ?? object['clientID'] ?? null,
        clientID: object['clientId'] ?? object['clientID'] ?? null,
        client: clientSet(object['client'] ?? {}),
        timestamp: object['timestamp'] ?? null,
        validUntil: object['validUntil'] ?? null,
        invoice: object['invoice'] ?? null,
        status: object['status'] ?? null,
        items: object['items'] ?? [],
        internalItems: object['internalItems'] ?? [],
        description: object['description'] ?? '',
        disallowInvoice: object['disallowInvoice'] ?? false,
        errorMessage: object['errorMessage'] ?? null,
        payments: object['payments'] ?? [],
        pdf: object['pdf'] ?? '',
        periodicity: object['periodicity'] ?? '',
        shortUrl: object['url'] ?? null,
        total: object['total'] ?? 0,
        stripeId: object['stripeId'] ?? null,
        metadata: object['metadata'] ?? null,
        PDFExpiresAt: object['PDFExpiresAt'] ?? null,
        from: object['from'] ?? 'payment',
        exchange: object['exchange'] ?? 1,
        test: object['test'] ?? false,
        payment_form: object['payment_form'] ?? null,
        invoices: object['invoices'] ?? [],
        receipts: object['receipts'] ?? [],
        paymentMethod: object['paymentMethod'] ?? null,
        invoiceErrorMessage: object['invoiceErrorMessage'] ?? null,
        invoiceError: object['invoiceError'] ?? null,
        whmcsInvoiceId: object['whmcsInvoiceId'] ?? null,
        hilosResponse: object['hilosResponse'] ?? null,
        url: object['url'] ?? null,
    }

    return receipt
}

export const optimizedReceiptDefSet = (object: any) => {
    const receipt: OptimizedReceiptDef = {
        id: object['id'] ?? object['fid'] ?? null,
        items: object['items'] ?? [],
        livemode: object['livemode'] ?? null,
        amount: object['total'] ?? object['amount'] ?? null,
        currency: object['currency'] ?? null,
        clientId: object['clientId'] ?? null,
        client: clientSet(object['client'] ?? {}),
        timestamp: object['timestamp'] ?? null,
        validUntil: object['validUntil'] ?? null,
        invoice: object['invoice'] ?? null,
        status: object['status'] ?? null,
        paymentMethod: object['paymentMethod'] ?? null,
        shortUrl: object['url'] ?? null,
        from: object['from'] ?? 'payment',
        invoices: object['invoices'] ?? [],
        payment_form: object['payment_form'] ?? null,
        metadata: object['metadata'] ?? null,
        disallowInvoice: object['disallowInvoice'] ?? false,
        whmcsInvoiceId: object['whmcsInvoiceId'] ?? null,
        hilosResponse: object['hilosResponse'] ?? null,
        invoiceError: object['invoiceError'] ?? null,
        invoiceErrorMessage: object['invoiceErrorMessage'] ?? null,
        url: object['url'] ?? null,
    }

    return receipt
}
