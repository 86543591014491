import { Button } from 'antd'
import GoogleLogo from '../GoogleLogo'

interface GoogleSignInButtonProps {
    onClick: () => void
    loading: boolean
}

const GoogleSignInButton: React.FC<GoogleSignInButtonProps> = ({ onClick, loading }) => {
    return (
        <Button
            icon={<GoogleLogo />}
            onClick={onClick}
            htmlType="button"
            loading={loading}
            disabled={loading}
            block
            className="btn-outline"
        >
            Google
        </Button>
    )
}

export default GoogleSignInButton
