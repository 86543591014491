import { createSlice } from '@reduxjs/toolkit'
import { UserDef, userDefSet } from '../../interfaces/userDef'
import { doc, getDoc, getFirestore } from 'firebase/firestore'

interface UserState {
    user: UserDef
}

const initialState: UserState = {
    user: userDefSet({}),
}
const getUserInfo = (userId: string) => {
    const fs = getFirestore()
    try {
        const ba = userDefSet(
            getDoc(doc(fs, 'users', userId)).then((doc) => {
                if (doc.exists()) {
                    return doc.data()
                } else {
                    throw new Error('User account not found')
                }
            }),
        )
        return ba
    } catch (error) {
        throw error
    }
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload
        },
        getUser: (state, action) => {
            state.user = userDefSet(getUserInfo(action.payload))
        },
    },
})

export const { setUser, getUser } = userSlice.actions

export default userSlice.reducer
