import { Pencil } from '@phosphor-icons/react'
import { Row, Tooltip, Typography } from 'antd'
import { MetroSpinner } from 'react-spinners-kit'

type Props = {
    action: () => void
    loading: boolean
    full?: boolean
}

const EditAction = ({ action, loading, full }: Props) => {
    const LoadingIcon = () => {
        return <MetroSpinner size={16} color="#686769" />
    }

    return (
        <Tooltip title="Editar">
            <Row onClick={action} style={{ margin: 0, padding: 0 }}>
                {loading ? <LoadingIcon /> : <Pencil size={18} weight="regular" className="icon clickable" />}
                {full && <Typography.Text style={{ margin: 0, padding: 0, marginLeft: '5px' }}>Editar</Typography.Text>}
            </Row>
        </Tooltip>
    )
}

export default EditAction
