import moment from 'moment'
import { ClientDef, clientSet } from './clientDef'
import { InternalItem, internalItemSet } from './internalItemDef'
import { Automation, automationSet } from './automationsDef'

export interface PaymentLink {
    status: string
    amount: number
    total: number
    description: string
    currency: string
    items: InternalItem[]
    id: string
    team: string
    billingAccount: string
    livemode: boolean
    owner: string
    expiresAt?: number
    timestamp: number
    metadata?: any
    custom_method_types: string[]
    automations: Automation[]
    exchange: number
    relations?: string[]
    views?: number
}

export interface Payment {
    livemode?: boolean
    amount: number
    limitDaysToPay?: number
    description: string
    currency: string
    status: string
    internalStatus?: string
    internalItems: InternalItem[]
    items?: InternalItem[]
    custom_method_types?: string[]
    exchange?: number
    paidId?: string
    paidIn?: string
    id: string
    fid?: string
    v?: number
    skc?: boolean
    from?: string
    processor?: string
    relatedTo?: string
    invoices?: string[] | null
    payments?: string[] | null
    client?: ClientDef | null
    clientId?: string | null
    clientID?: string | null
    discount?: number
    payment?: any
    user?: any
    shortURL?: string | null
    shortUrl?: string | null
    token?: string | null
    team: string
    billingAccount?: string
    hasStripe?: boolean
    timestamp: number
    created?: number
    succeededTimestamp?: number | null
    payment_form?: string
    binnacle?: string | null
    event?: string
    recurringEventNumber?: number
    paymentIntent?: string
    amount_paid?: number
    amount_due?: number
    amount_remaining?: number
    automations: Automation[] | null
    receipts?: string[]
    createdAutomations?: string[]
    customer?: string
    clientName?: string
    clientLegalName?: string
    period_start?: number
    period_end?: number
    owner: string
    subtotal: number
    subtotal_excluding_tax?: number
    tax: number
    total?: number
    totalItemsAmounts?: object | null
    total_excluding_tax?: number | null
    webhook_delivered_at?: number | null
    manuallyUpdated?: number
    whmcsInvoiceId?: number | null
    whmcsTotalDiscount?: number | null
    whmcsItems?: any[]
    status_transitions?: any
    refunded?: boolean
    review_status?: string
    metadata?: any
    next_action?: any
}

export interface OptimizedPaymentDef {
    id: string
    fid: string
    status: string
    livemode?: boolean
    items?: InternalItem[]
    internalItems?: InternalItem[]
    automations: Automation[] | null
    amount: number
    description: string
    receipts: string[]
    payments: string[]
    discount?: any
    invoices: string[]
    currency: string
    from: string
    paidIn?: string
    clientId: string
    clientID: string
    client: ClientDef | null
    timestamp: number
    succeededTimestamp: number | null
    status_transitions?: any
    shortURL?: string | null
    clientName?: string
    clientLegalName?: string
    payment_form?: string
    refunded?: boolean
    review_status?: string
    metadata?: any
    whmcsInvoiceId?: number | null
    amount_remaining?: number
    next_action?: any
    paymentIntent?: string
}

export const paymentSet = (object: any) => {
    const payment: Payment = {
        livemode: object['livemode'] ?? null,
        amount: object['amount'] ?? null,
        description: object['description'] ?? null,
        currency: object['currency'] ?? null,
        status: object['status'] ?? null,
        internalStatus: object['internalStatus'] ?? null,
        internalItems: (object['internalItems'] ?? [])?.map((p: object) => internalItemSet(p)) ?? null,
        items: (object['items'] ?? [])?.map((p: object) => internalItemSet(p)) ?? null,
        custom_method_types: object['custom_method_types'] ?? null,
        exchange: object['exchange'] ?? null,
        paidId: object['paidId'] ?? null,
        id: object['fid'] ?? object['id'] ?? null,
        fid: object['fid'] ?? null,
        v: object['v'] ?? null,
        skc: object['skc'] ?? null,
        from: object['from'] ?? null,
        processor: object['processor'] ?? null,
        relatedTo: object['relatedTo'] ?? null,
        invoices: object['invoices'] ?? null,
        payments: object['payments'] ?? null,
        client: object['client'] ? clientSet(object['client']) : null,
        clientId: object['clientId'] ?? null,
        clientID: object['clientID'] ?? null,
        discount: object['discount'] ?? null,
        payment: object['payment'] ?? null,
        user: object['user'] ?? null,
        shortURL: object['shortURL'] ?? object['shortUrl'] ?? null,
        shortUrl: object['shortUrl'] ?? null,
        token: object['token'] ?? null,
        team: object['team'] ?? null,
        billingAccount: object['billingAccount'] ?? null,
        hasStripe: object['hasStripe'] ?? null,
        timestamp: object['timestamp'] ?? null,
        created: object['created'] ?? null,
        succeededTimestamp: object['succeededTimestamp'] ?? null,
        payment_form: object['payment_form'] ?? null,
        binnacle: object['binnacle'] ?? null,
        event: object['event'] ?? null,
        recurringEventNumber: object['recurringEventNumber'] ?? null,
        paymentIntent: object['paymentIntent'] ?? null,
        amount_paid: object['amount_paid'] ?? null,
        amount_due: object['amount_due'] ?? null,
        amount_remaining: object['amount_remaining'] ?? null,
        automations: (object['automations'] ?? [])?.map((a: any) => automationSet(a)) ?? null,
        receipts: object['receipts'] ?? null,
        createdAutomations: object['createdAutomations'] ?? null,
        customer: object['customer'] ?? null,
        period_start: object['period_start'] ?? null,
        period_end: object['period_end'] ?? null,
        subtotal: object['subtotal'] ?? null,
        subtotal_excluding_tax: object['subtotal_excluding_tax'] ?? null,
        tax: object['tax'] ?? null,
        totalItemsAmounts: object['totalItemsAmounts'] ?? null,
        total_excluding_tax: object['total_excluding_tax'] ?? null,
        webhook_delivered_at: object['webhook_delivered_at'] ?? null,
        paidIn: object['paidIn'] ?? null,
        manuallyUpdated: object['manuallyUpdated'] ?? null,
        owner: object['owner'] ?? null,
        whmcsInvoiceId: object['whmcsInvoiceId'] ?? null,
        whmcsTotalDiscount: object['whmcsTotalDiscount'] ?? null,
        whmcsItems: object['whmcsItems'] ?? [],
        limitDaysToPay: object['limitDaysToPay'] ?? null,
        status_transitions: object['status_transitions'] ?? null,
        clientName: object['client'] ? object['client']['name'] : '',
        clientLegalName: object['client'] ? object['client']['legalName'] ?? object['client']['legal_name'] : '',
        refunded: object['refunded'] ?? null,
        review_status: object['review_status'] ?? null,
        metadata: object['metadata'] ?? null,
        next_action: object['next_action'] ?? {},
    }
    return payment
}

export interface PaymentReadable {
    livemode?: boolean
    total: number
    description: string
    currency: string
    status: string
    items: InternalItem[]
    id: string
    team: string
    client: ClientDef
    receipts?: string[]
    invoices?: string[]
    timestamp: number
    shortURL?: string
    paidIn?: string
    discount?: number | null
    manuallyUpdated?: number
    clientId?: string
    clientID?: string
    clientName?: string
    clientLegalName?: string
    amount_remaining?: number
    next_action?: any
    paymentIntent?: string
}

export const paymentReadableSet = (object: Payment) => {
    const payment: PaymentReadable = {
        livemode: object['livemode'] ?? false,
        total: object['amount'] / 100 ?? null,
        description: object['description'] ?? null,
        currency: object['currency'] ?? null,
        status: object['status'] ?? object['internalStatus'],
        items: (object['items'] ?? [])?.map((p: object) => internalItemSet(p)),
        id: object['fid'] ?? object['id'],
        team: object['team'] ?? null,
        client: clientSet(object['client']) ?? null,
        clientId: object['clientId'] ?? object['clientID'] ?? '',
        clientID: object['clientId'] ?? object['clientID'] ?? '',
        receipts: object['receipts'] ?? [],
        invoices: object['invoices'] ?? [],
        timestamp: object['timestamp'] ?? moment().valueOf(),
        shortURL: object['shortURL'] ?? object['shortUrl'] ?? '',
        paidIn: object['paidIn'] ?? '',
        manuallyUpdated: object['manuallyUpdated'],
        clientName: object['client'] ? object['client']['name'] : '',
        clientLegalName: object['client'] ? object['client']['legalName'] ?? object['client']['legal_name'] ?? '' : '',
        amount_remaining: object['amount_remaining'] ?? 0,
        next_action: object['next_action'] ?? {},
        paymentIntent: object['paymentIntent'] ?? '',
    }

    return payment
}

export const optimizedPaymentDefSet = (object: any) => {
    const automations: any[] = typeof object['automations'] === 'boolean' ? [] : object['automations']
    const payment: OptimizedPaymentDef = {
        fid: object['fid'] ?? object['id'],
        id: object['fid'] ?? object['id'],
        status: object['status'] ?? null,
        items: object['items']?.map((p: any) => internalItemSet(p)) ?? null,
        internalItems: object['internalItems']?.map((p: any) => internalItemSet(p)) ?? null,
        paidIn: object['paidIn'] ?? null,
        discount: object['discount'] ?? null,
        livemode: object['livemode'] ?? null,
        amount: object['amount'] ?? null,
        automations: automations?.map((a: any) => automationSet(a)),
        receipts: object['receipts'] ?? null,
        invoices: object['invoices'] ?? null,
        payments: object['payments'] ?? null,
        from: object['from'] ?? null,
        description: object['description'] ?? null,
        currency: object['currency'] ?? null,
        clientID: object['clientId'] || object['clientID'] || '',
        clientId: object['clientId'] || object['clientID'] || '',
        client: object['client'] ? clientSet(object['client']) : null,
        timestamp: object['timestamp'] ?? null,
        succeededTimestamp: object['succeededTimestamp'] ?? null,
        shortURL: object['shortURL'] ?? object['shortUrl'] ?? null,
        clientName: object['client'] ? object['client']['name'] : '',
        clientLegalName: object['client'] ? object['client']['legalName'] ?? object['client']['legal_name'] : '',
        payment_form: object['payment_form'] ?? null,
        refunded: object['refunded'] ?? null,
        review_status: object['review_status'] ?? null,
        metadata: object['metadata'] ?? null,
        whmcsInvoiceId: object['whmcsInvoiceId'] ?? null,
        amount_remaining: object['amount_remaining'] ?? 0,
        next_action: object['next_action'] ?? {},
        paymentIntent: object['paymentIntent'] ?? '',
    }

    return payment
}
