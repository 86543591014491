import { getFunctions, httpsCallable } from 'firebase/functions'
import { setData } from '../context/dataSlice'
import { Dispatch } from 'redux'

export const getBillingData = async ({ team, user, dispatch }: { team: any; user: any; dispatch: Dispatch }) => {
    const functions = getFunctions()
    if (window.location.hostname === 'localhost') {
        functions.customDomain = 'http://127.0.0.1:5001/gigstackpro/us-central1'
    }
    const billingManager = httpsCallable(functions, 'internalbillingapp')
    const r = await billingManager({
        teamId: team?.id,
        uid: user?.uid,
        billingAccount: team?.billingAccount,
        action: 'me',
    })
    const data = r.data as any
    if ((data?.subscriptions?.data ?? []).length <= 0) return
    if (data?.subscriptions?.data.filter((s: any) => s.status === 'active').length <= 0) return
    const subscription = data?.subscriptions?.data.filter((s: any) => s.status === 'active')[0]
    const plan = subscription?.plan ?? {}
    const paused = subscription?.pause_collection
    // console.log('subscription', subscription);

    if (paused) {
        dispatch(
            setData({
                item: 'billing',
                data: {
                    subscription: {
                        ...subscription,
                        status: 'paused',
                    },
                    plan: plan,
                    product: data?.product ?? null,
                    customer: data?.customer,
                    paused: paused,
                },
            }),
        )
        return
    }

    dispatch(
        setData({
            item: 'billing',
            data: {
                subscription: subscription,
                plan: plan,
                product: data?.product ?? null,
                customer: data?.customer,
            },
        }),
    )
}
