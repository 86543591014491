import { Typography, message } from 'antd'
import { returnCurrencyValue } from '../../../functions/helpers'
import { InternalItem } from '../../../../interfaces/internalItemDef'
import { TaxElement } from '../../../../interfaces/invoicesDef'
import ServiceActions from '../../ElementActions/ServiceActions'
import { CopyableElementId } from '../../UI/CopyableElementId'

type TypeServicesColumnName =
    | 'name'
    | 'paymentType'
    | 'quantity'
    | 'price'
    | 'total'
    | 'status'
    | 'timestamp'
    | 'taxes'
    | 'actions'

export const ServicesColumns = ({
    record,
    columnName,
}: {
    record: InternalItem
    columnName: TypeServicesColumnName
}) => {
    switch (columnName) {
        case 'name':
            return (
                <>
                    <div className="d-flex flex-column">
                        <Typography.Text
                            onClick={() => {
                                //copy to clipboard
                                navigator.clipboard.writeText(record.id)
                                message.success('Copiado al portapapeles')
                            }}
                            className="p-base-regular neutral-1 clickable"
                        >
                            {record.name}
                        </Typography.Text>
                        {record?.property_tax_account && (
                            <Typography.Text className="smallparagraph descriptions">
                                Cta. Predial: {record?.property_tax_account}
                            </Typography.Text>
                        )}
                    </div>
                    <CopyableElementId id={record?.id} idName="ID" />
                </>
            )
        case 'taxes':
            return (
                <div className="d-flex flex-column">
                    {(record?.taxes || [])?.map((t: TaxElement, k: number) => {
                        return (
                            <Typography.Text key={k} className="p-base-regular" style={{ textAlign: 'right' }}>
                                {t.type} {Number(t.rate) * 1} {t.inclusive ? '(Inc.)' : ''}{' '}
                                {t.withholding ? '(Ret.)' : ''}
                            </Typography.Text>
                        )
                    })}
                </div>
            )
        case 'paymentType':
            return (
                <Typography.Text className="p-base-regular" style={{ textAlign: 'right' }}>
                    {record.paymentType.label}
                </Typography.Text>
            )
        case 'quantity':
            return (
                <Typography.Text className="p-base-regular" style={{ textAlign: 'right' }}>
                    {record.quantity}
                </Typography.Text>
            )
        case 'price':
            return (
                <Typography.Text className="p-base-regular">
                    {returnCurrencyValue(record.total)}
                </Typography.Text>
            )
        case 'total':
            return (
                <Typography.Text
                    onClick={() => console.log(record.amounts)}
                    className="p-base-regular"
                    style={{ textAlign: 'right' }}
                >
                    {returnCurrencyValue((record.amounts?.total ?? record.total) * record.quantity)}
                </Typography.Text>
            )

        case 'actions':
            return <ServiceActions service={record} />
    }
}
