import React, { useState } from 'react'
import { Col, Form, FormInstance, Row, List, Typography, Alert, Button } from 'antd'
import { useAuth } from 'reactfire'
import { Key } from '@phosphor-icons/react'
import { sendEmailVerification } from 'firebase/auth'

interface SecurityFormProps {
    incomingForm?: FormInstance
}

const SecurityForm: React.FC<SecurityFormProps> = ({ incomingForm }) => {
    const auth = useAuth()

    const [form] = Form.useForm()
    const [isSending, setIsSending] = useState(false)

    const usingForm = incomingForm ?? form

    const handleSendEmailVerification = async () => {
        if (!auth.currentUser || isSending) return

        setIsSending(true)

        try {
            await sendEmailVerification(auth.currentUser)
        } catch (error) {
            console.error(error)
        } finally {
            setIsSending(false)
        }
    }

    return (
        <Form form={usingForm}>
            {/* Form content goes here */}
            <Row>
                <Col xs={24} className="d-flex flex-column">
                    <label>Proveedores de inicio de sesión</label>
                    <List
                        itemLayout="horizontal"
                        dataSource={auth?.currentUser?.providerData ?? []}
                        renderItem={(item, index) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<Key weight="regular" className="icon" />}
                                    title={
                                        <Typography.Text className="smallparagraphbold descriptions">
                                            {item.providerId}
                                        </Typography.Text>
                                    }
                                    description={
                                        <Typography.Text className="smallparagraph descriptions">
                                            ID: {item.uid}
                                        </Typography.Text>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </Col>
                <Col xs={24} className="d-flex flex-column" style={{ marginTop: '15px' }}>
                    <label>Última sesión iniciada</label>
                    <Typography.Text className="smallparagraph descriptions">
                        {auth?.currentUser?.metadata?.lastSignInTime}
                    </Typography.Text>
                </Col>
                <Col xs={24} className="d-flex flex-column" style={{ marginTop: '15px' }}>
                    <label>Creado</label>
                    <Typography.Text className="smallparagraph descriptions">
                        {auth?.currentUser?.metadata?.creationTime}
                    </Typography.Text>
                </Col>

                <Col xs={24} style={{ marginTop: '40px' }}>
                    {!auth.currentUser?.emailVerified && (
                        <Alert
                            action={
                                <Button
                                    className="btn-primary"
                                    onClick={handleSendEmailVerification}
                                    loading={isSending}
                                    disabled={isSending}
                                >
                                    Enviar correo de verificación
                                </Button>
                            }
                            message={
                                <Typography.Text className="smallparagraph descriptions">
                                    Tu correo electrónico no ha sido verificado.
                                </Typography.Text>
                            }
                            type="warning"
                            showIcon
                        />
                    )}
                </Col>
            </Row>
        </Form>
    )
}

export default SecurityForm
