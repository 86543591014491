import React, { useState } from 'react'
import { Alert, Button, Col, Form, FormInstance, Image, Row, Typography, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { TextInput } from './Inputs'
import { useAuth } from 'reactfire'
import { SignedInternalAPIRequest } from '../../functions/APIRequest'
import { setTeam } from '../../context/teamsSlice'
import { Envelope, Key, Question } from '@phosphor-icons/react'

const CustomerPortalForm: React.FC = ({
    incomingForm,
    buttonTitle,
    onUpdate,
    onValuesUpdate,
}: {
    incomingForm?: FormInstance
    buttonTitle?: string
    onUpdate?: any
    onValuesUpdate?: (v: any) => void
}) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const useForm = incomingForm ?? form

    const auth = useAuth()

    const [loading, setloading] = useState(false)

    const { team } = useSelector((state: any) => state.team)

    const handleUpdateInformation = async (v: any) => {
        try {
            if (!v.customerPortalId) return
            setloading(true)
            const trimmedValue = v.customerPortalId.replace(/\s+/g, '')
            const response = await SignedInternalAPIRequest(
                { portalId: trimmedValue },
                'customerPortal/v1/Validate',
                auth.currentUser,
            )

            if (response.status === 'success') {
                dispatch(setTeam({ ...team, customerPortalId: v?.customerPortalId }))
                message.success('Creado correctamente.')
            } else {
                message.error(response.message)
            }

            setloading(false)
        } catch (error: any) {
            message.error(error?.message)
            setloading(false)
        }
    }

    return (
        <Form form={useForm} layout="vertical" onFinish={handleUpdateInformation}>
            <Row>
                {team?.customerPortalId ? (
                    <></>
                ) : (
                    <Col xs={24} style={{ marginBottom: '10px' }}>
                        <Alert
                            message={
                                <Typography className="smallparagraph descriptions">
                                    Una vez que selecciones un alias, no podrás cambiarlo.
                                </Typography>
                            }
                            type="info"
                            showIcon
                        />
                    </Col>
                )}
                {team?.customerPortalId !== undefined && team?.customerPortalId ? (
                    <div className="d-flex flex-column" style={{ width: '100%' }}>
                        <Button
                            onClick={() => {
                                navigator.clipboard.writeText(`https://portal.gigstack.pro/${team?.customerPortalId}`)
                                message.success('Enlace copiado al portapapeles')
                            }}
                            type="dashed"
                            style={{ width: '100%' }}
                        >
                            https://portal.gigstack.pro/{team?.customerPortalId}
                        </Button>
                        <Row justify="center" style={{ marginTop: '15px' }}>
                            <Typography.Text className="smallparagraph descriptions">
                                El portal de {team?.brand?.alias} está listo, comparte el enlace
                                https://portal.gigstack.pro/{team?.customerPortalId} para que tus clientes puedan
                                visualizar sus transacciones, además de actualizar su información.{' '}
                            </Typography.Text>
                        </Row>
                    </div>
                ) : (
                    <Col xs={24}>
                        <TextInput
                            disabled={loading}
                            rules={[
                                //NO SPACES ALLOWED, NOR SPECIAL CHARACTERS
                                { pattern: /^\S*$/, message: 'No se permiten espacios' },
                                { pattern: /^[a-zA-Z0-9]*$/, message: 'No se permiten caracteres especiales' },
                                { required: true, message: 'Este campo es requerido' },
                            ]}
                            name="customerPortalId"
                            label="Alias del portal de cliente"
                            placeholder="tumarca"
                        />
                        {team?.customerPortalId !== undefined && team?.customerPortalId ? (
                            <></>
                        ) : (
                            <Col xs={24}>
                                <Row justify="end">
                                    <Button type="primary" loading={loading} htmlType="submit">
                                        Crear portal
                                    </Button>
                                </Row>
                            </Col>
                        )}
                    </Col>
                )}

                <Col xs={24} style={{ marginTop: '15px' }}>
                    <label>Como lo verán tus clientes</label>
                </Col>
                <Col xs={24}>
                    <div
                        style={{
                            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                            width: '100%',
                            height: 'auto',
                            marginTop: '5px',
                            borderRadius: '10px',
                            backgroundColor: '#fafbfd',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '20px 0px',
                        }}
                        className="f-flex flex-column"
                    >
                        <div
                            style={{
                                backgroundColor: 'white',
                                width: '60%',
                                height: 'auto',
                                padding: '15px 20px',
                                borderRadius: '10px',
                            }}
                        >
                            <Row>
                                <Col xs={24}>
                                    <Row justify="end">
                                        <Image width={40} src={team?.brand?.logo} />
                                    </Row>
                                </Col>
                                <Col xs={24}>
                                    <Row justify="center">
                                        <div
                                            style={{
                                                padding: '6px',
                                                border: `1px solid ${team?.brand?.primaryColor}`,
                                                borderRadius: '10px',
                                            }}
                                            className="d-flex flex-column"
                                        >
                                            <Key
                                                size={15}
                                                weight="regular"
                                                style={{
                                                    color: team?.brand?.primaryColor,
                                                }}
                                            />
                                        </div>
                                    </Row>
                                </Col>
                                <Col
                                    xs={24}
                                    className="d-flex flex-column"
                                    style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: '10px',
                                    }}
                                >
                                    <Typography.Text className="smallheader" style={{ textAlign: 'center' }}>
                                        Acceso al portal de cliente
                                    </Typography.Text>
                                    <Typography.Text
                                        className="smallparagraph descriptions"
                                        style={{ textAlign: 'center' }}
                                    >
                                        Recupera todos los pagos, facturas recibos de venta y estados de cuenta.
                                    </Typography.Text>
                                    <div style={{ marginTop: '15px' }}></div>
                                    <TextInput
                                        disabled={true}
                                        prefix={<Envelope size={15} weight="regular" className="icon" />}
                                        name="_ignore"
                                        label=""
                                        placeholder="Correo electrónico"
                                        suffix={<Question size={15} weight="regular" className="icon" />}
                                    />
                                    <Button
                                        htmlType="button"
                                        type="primary"
                                        style={{ backgroundColor: team?.brand?.primaryColor, marginTop: '15px' }}
                                    >
                                        Enviar código
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </Form>
    )
}

export default CustomerPortalForm
