import { Alert, Avatar, Button, Col, Form, message, Row, Typography } from "antd"
import { LoadingOutlined } from '@ant-design/icons'
import { useEffect, useState } from "react"
import { ColorInput, LogoUploader, TextInput } from "./Inputs"
import useLogoAndColors from "../../customHooks/useLogoAndColors"
import { Globe, Trash } from "@phosphor-icons/react"
import { generateCode, uploadLogoToStorage } from "../../functions/helpers"
import { getAuth } from "firebase/auth"
import CountrySelect from "../../new-onboarding/onboarding/CountrySelect"
import { useDispatch, useSelector } from "react-redux"
import { createTeam } from "../../new-onboarding/auth/utils"
import { useStorage } from "reactfire"
import { doc, getFirestore, updateDoc } from "firebase/firestore"
import { closeModal } from "../../context/modalsSlice"
import { useChangeTeam } from "../../customHooks/useTeam"

export default function CreateTeamForm({ disabled } : { disabled: boolean }) {
    const { team } = useSelector((state: any) => state.team)

    const [ error, setError ] = useState<string | null>(null)
    const [file, setFile] = useState<File | null>(null)
    const [imageUrl, setImageUrl] = useState<string | null>(null)

    const { billingAccount } = useSelector((state: any) => state.billingAccount)
    const { user } = useSelector((state: any) => state.user)
    const { createTeamVisible } = useSelector((state: any) => state.modals)

    const { changeTeam } = useChangeTeam()

    const cleanFormStates = (stateSetters: React.Dispatch<React.SetStateAction<any>>[]) => {
        for (const setter of stateSetters) {
            setter(() => null)
        }
    }

    useEffect(() => {
        if (!createTeamVisible) {
            cleanFormStates([setFile, setImageUrl, setError])
        }
        // eslint-disable-next-line
    }, [createTeamVisible])


    const [form] = Form.useForm()
    const auth = getAuth()
    const dispatch = useDispatch()
    const storage = useStorage()

    const { fetchLogoAndColors, loading: logoLoading, logo: logofromapi, colors, removeLogo } = useLogoAndColors()

    const createLogoFromApiFile = async () => {
        if (logofromapi) {
            const response = await fetch(logofromapi);
            const blob = await response.blob();
            const file = new File([blob], "logo.png", { type: blob.type });
            setFile(file);
        }
    }


    useEffect(() => {
        createLogoFromApiFile()    

        if (colors) {

            form.setFieldsValue({
                primaryColor: colors?.[0],
                secondaryColor: colors?.[1],
                logo: logofromapi,
            })
        }
    // eslint-disable-next-line
    }, [colors, form, logofromapi])


    const handleCreateTeam = async (values: any) => {
        try {
            const primaryColor =
                typeof values?.primaryColor === 'string'
                    ? values?.primaryColor
                    : `#${values?.primaryColor?.toHex() ?? ''}`
            const secondaryColor =
                typeof values?.secondaryColor === 'string'
                    ? values?.secondaryColor
                    : `#${values?.secondaryColor?.toHex() ?? ''}`

            const isValidColor = (color: string) => {
                return /^#[0-9A-Fa-f]{6}$/.test(color)
            }

            const teamData = {
                brand: {
                    alias: values?.alias,
                    primaryColor:
                        primaryColor && isValidColor(primaryColor)
                            ? primaryColor
                            : (team?.brand?.primaryColor ?? '#000000'),
                    secondaryColor:
                        secondaryColor && isValidColor(secondaryColor)
                            ? secondaryColor
                            : (team?.brand?.secondaryColor ?? '#FFFFFF'),
                },
                address: {
                    country: values?.country,
                    state: values?.state,
                },
                website: values?.website,
                billingAccount: billingAccount?.id,
                defaults: {
                    skipOnboarding: true,
                },
            }

            console.log('teamData', teamData)

            const newTeam = await createTeam(teamData, user, dispatch, auth)
            if (!newTeam?.id) return null

            const fileId = generateCode(10, 'file')
            const filePath = `teams/${newTeam.id}/files/${fileId}`

            const fileUrl = await uploadLogoToStorage(file, storage,  filePath)

            if (fileUrl) {
                await updateDoc(doc(getFirestore(), 'teams', newTeam.id), {"brand.logo" : fileUrl})
            }
            
            dispatch(closeModal('createTeamVisible'))
           
            await changeTeam(newTeam)
        } catch (error) {
            console.error('Error creating team:', error)
            setError('Hubo un error al crear tu cuenta. Por favor, intenta de nuevo.')
        }
    }

    return (
        <>
        <Row justify="center" gutter={[0, 0]} style={{ marginBottom: '24px' }}>
            <Col span={24} className="d-flex flex-column">
                <Typography className="h4-bold" style={{ margin: 0 }}>
                    Información de tu nueva marca
                </Typography>
            </Col>
        </Row>
        {error && (
            <Alert
                message="Error"
                description={error}
                type="error"
                showIcon
                closable
                onClose={() => setError(null)}
                style={{ marginBottom: '24px' }}
            />
        )}
        <Form
            form={form}
            layout="vertical"
            onFinish={handleCreateTeam}
            style={{ width: '100%' }}
            onFinishFailed={(errorInfo) => {
                console.log('Form validation failed:', errorInfo)
                message.error('Por favor, corrige los errores en el formulario antes de continuar.')
            }}
        >
            <Row gutter={[32, { xs: 12, lg: 12 }]}>
                <Col xs={24} lg={24}>
                    <TextInput
                        onChange={(e: any) => {
                            let value = e.target.value
                            if (value && !value.match(/^https?:\/\//)) {
                                value = 'https://' + value
                                form.setFieldsValue({ website: value })
                            }
                        }}
                        onBlur={() => {
                            let value = form.getFieldValue('website')
                            if (value) {
                                if (!value.match(/^https?:\/\//)) {
                                    value = 'https://' + value
                                    form.setFieldsValue({ website: value })
                                }
                                fetchLogoAndColors(value)
                            }
                        }}
                        prefix={logoLoading ? <LoadingOutlined /> : <Globe size={16} />}
                        name="website"
                        label="Sitio Web"
                        placeholder="https://www.tusitio.com"
                        rules={[
                            { required: false },
                            { type: 'url', message: 'Por favor ingresa una URL válida' },
                            {
                                validator: (_: any, value: string) => {
                                    if (value && !value.match(/^https?:\/\//)) {
                                        return Promise.reject('La URL debe comenzar con http:// o https://')
                                    }
                                    return Promise.resolve()
                                },
                            },
                        ]}
                        disabled={disabled}
                    />
                </Col>
                <Col xs={24} lg={24}>
                    <TextInput
                        name="alias"
                        label="Nombre Comercial"
                        placeholder="Escribe tu Nombre comercial"
                        rules={[
                            { required: true, message: 'Por favor ingresa el nombre comercial' },
                            { max: 100, message: 'El nombre comercial no puede exceder los 100 caracteres' },
                        ]}
                        disabled={disabled}
                    />
                </Col>
                {logofromapi ? (
                    <Col xs={24}>
                        <Row justify="space-between">
                            <Row>
                                <Avatar src={logofromapi} size={20} />
                                <Typography.Text
                                    style={{
                                        color: 'var(--neutral-3)',
                                        paddingLeft: '3px',
                                    }}
                                >
                                    Logo obtenido desde sitio
                                </Typography.Text>
                            </Row>
                            <Button icon={<Trash size={16} />} onClick={removeLogo} />
                        </Row>
                    </Col>
                ) : (
                    <Col xs={24}>
                        <Row align="middle">
                            <div style={{height: "110px", width: "110px"}}>
                                <LogoUploader file={file} setFile={setFile} imageUrl={imageUrl} setImageUrl={setImageUrl} disabled={disabled}/>
                            </div>
                        </Row>
                    </Col>
                )}

                <Col xs={24} lg={24}>
                    <CountrySelect
                        name="country"
                        label="País de operación"
                        placeholder="Selecciona tu país de operación"
                        rules={[{ required: true, message: 'Por favor selecciona tu país' }]}
                        disabled={disabled}
                    />
                </Col>

                <Col xs={24} md={12}>
                    <div className="colorOverride">
                        <ColorInput
                            label="Color Primario"
                            name="primaryColor"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido',
                                },
                            ]}
                            disabled={disabled}
                        />
                    </div>
                </Col>
                <Col xs={24} md={12}>
                    <div className="colorOverride">
                        <ColorInput
                            label="Color Secundario"
                            name="secondaryColor"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido',
                                },
                            ]}
                            disabled={disabled}
                        />
                    </div>
                </Col>
                <Col xs={24} lg={24}>
                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button className="btn-primary p-base-regular" htmlType="submit">
                            Crear cuenta
                        </Button>
                    </div>                
                </Col>
            </Row>
        </Form>
    </>
    )
}
