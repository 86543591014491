import React, { useState } from 'react'
import { ClientDef } from '../../../interfaces/clientDef'
import { Dropdown, Row, Space, Tooltip, Typography, message } from 'antd'
import EditAction from './SingleActions/EditAction'
import { useDispatch } from 'react-redux'
import { closeModal, openModal } from '../../context/modalsSlice'
import { setData, deleteData } from '../../context/dataSlice'
import DeleteAction from './SingleActions/DeteleAction'
import { deleteDoc, doc, getFirestore } from 'firebase/firestore'
import { Cardholder, CaretRight, CurrencyDollar, DotsThree, Money } from '@phosphor-icons/react'
import { MenuProps } from 'antd/lib'
import { usePermissions } from '../../customHooks/usePermissions'
import { useNavigate } from 'react-router-dom'

interface Props {
    client: ClientDef
    showFull?: boolean
    from?: string
}

const ClientActions: React.FC<Props> = ({ client, showFull, from }) => {
    const [loading, setLoading] = useState<any[]>([])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { canCreateClient, canCreateInvoice, canCreatePayment, canCreateReceipt } = usePermissions()

    const thisRecordHasAnyLoading = loading.find((l) => l.id === client?.id) !== undefined

    const ActionsContent = () => {
        const clientActions: MenuProps['items'] = [
            {
                onClick: async () => {
                    if (!canCreatePayment) return message.warning('No tienes permisos para crear pagos')
                    dispatch(
                        setData({
                            item: 'client',
                            data: client,
                        }),
                    )
                    dispatch(openModal('paymentModalVisible'))
                },
                label: (
                    <Typography.Text className="smallparagaph ">
                        <Money weight="regular" size={15} style={{ marginRight: '5px' }} /> Solicitar pago
                    </Typography.Text>
                ),
                key: 'createPayment',
            },
            {
                disabled: !client?.profile?.completed,
                className: 'createInvoice',
                onClick: async () => {
                    if (!canCreateInvoice) return message.warning('No tienes permisos para crear facturas')
                    dispatch(
                        setData({
                            item: 'client',
                            data: client,
                        }),
                    )
                    dispatch(openModal('invoiceModalVisible'))
                },
                label: (
                    <Typography.Text className="smallparagaph ">
                        <CurrencyDollar weight="regular" size={15} style={{ marginRight: '5px' }} />
                        Crear factura
                    </Typography.Text>
                ),
                key: 'createInvoice',
            },
            {
                onClick: async () => {
                    if (!canCreateReceipt) return message.warning('No tienes permisos para crear recibos')
                    dispatch(
                        setData({
                            item: 'client',
                            data: client,
                        }),
                    )
                    dispatch(openModal('receiptModalVisible'))
                },
                label: (
                    <Typography.Text className="smallparagaph">
                        <Cardholder weight="regular" size={15} style={{ marginRight: '5px' }} />
                        Crear recibo
                    </Typography.Text>
                ),
                key: 'createReceipt',
            },
        ]

        return (
            <div className={showFull ? 'd-flex flex-column' : 'd-flex flex-row'}>
                {' '}
                <Space
                    direction={showFull ? 'vertical' : 'horizontal'}
                    align="start"
                    style={{ marginBottom: showFull ? '10px' : '0px' }}
                >
                    {canCreateClient && (
                        <EditAction
                            action={() => {
                                setLoading([...loading, { id: client?.id, action: `edit` }])
                                dispatch(
                                    setData({
                                        item: 'client',
                                        data: client,
                                    }),
                                )
                                dispatch(openModal('createClientDrawerVisible'))
                                setLoading(loading.filter((l) => l.id !== client?.id && l.action !== `edit`))
                            }}
                            loading={loading.find((l) => l.id === client?.id && l.action === `edit`) !== undefined}
                            full={false}
                        />
                    )}
                    {canCreateClient && (
                        <DeleteAction
                            action={async () => {
                                setLoading([...loading, { id: client?.id, action: `delete` }])
                                await deleteDoc(doc(getFirestore(), 'clients', client?.id))
                                dispatch(deleteData({ item: 'clients', id: client?.id }))
                                setLoading(loading.filter((l) => l.id !== client?.id && l.action !== `delete`))
                            }}
                            loading={false}
                        />
                    )}
                    <Dropdown
                        className="clientActionsMenu"
                        disabled={thisRecordHasAnyLoading}
                        menu={{ items: clientActions }}
                        trigger={['click']}
                    >
                        <DotsThree className="icon clickable" weight="regular" size={18} />
                    </Dropdown>
                </Space>
            </div>
        )
    }

    return from === 'typesenseSearch' ? (
        <Tooltip title="Ver detalles">
            <CaretRight
                style={{ fontSize: '24px', cursor: 'pointer' }}
                onClick={() => {
                    navigate(`/clientDetails?id=${client.id}`)
                    return dispatch(closeModal('searchResultContainerVisible'))
                }}
            />
        </Tooltip>
    ) : showFull ? (
        <Space direction="vertical" wrap={true}>
            <ActionsContent />
        </Space>
    ) : (
        <Row align="middle">
            <ActionsContent />
        </Row>
    )
}

export default ClientActions
