import { X } from '@phosphor-icons/react'
import { Info } from '@phosphor-icons/react/dist/ssr'
import { Alert, Button, Col, Form, Modal, Row, Typography, message } from 'antd'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'reactfire'
import ErrorsInFormComponent from '../components/Forms/ErrorsInFormComponent'
import { SelectInput } from '../components/Forms/Inputs'
import { setData } from '../context/dataSlice'
import { closeModal } from '../context/modalsSlice'
import { countryLabeledListWithCodeAlpha } from '../datasets/Countries'
import { SignedInternalAPIRequest } from '../functions/APIRequest'

const CreateInvoiceFromReceiptsModal = () => {
    const { createInvoiceFromReceiptModalVisible } = useSelector((state: any) => state.modals)
    const { receipt } = useSelector((state: any) => state.data)
    const { team } = useSelector((state: any) => state.team)
    const auth = useAuth()
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const [state, setState] = useState({
        typeOfGeneralPublic: 'national',
        country: 'MEX',
    })
    const [lastError, setLastError] = useState<any>(null)
    const [loading, setLoading] = useState(false)

    const GENERALPUBLICS = {
        national: {
            legal_name: 'PUBLICO EN GENERAL',
            rfc: 'XAXX010101000',
            address: {
                zip: team?.address?.zip,
            },
            tax_system: '616',
        },
        foreign: {
            legal_name: team?.legal_name ?? team?.legalName,
            rfc: '00-0000000',
            address: {
                zip: team?.address?.zip,
            },
            tax_system: '616',
        },
    }

    const handleCreateInvoice = async (v: any) => {
        try {
            setLoading(true)
            setLastError(null)
            var client =
                v.typeOfGeneralPublic === 'national'
                    ? {
                          ...GENERALPUBLICS.national,
                          address: { zip: team?.address?.zip ?? '10200', country: state?.country ?? 'MEX' },
                      }
                    : {
                          ...GENERALPUBLICS.foreign,
                          address: { zip: team?.address?.zip ?? '10200', country: state?.country ?? 'MEX' },
                      }
            if (v.typeOfGeneralPublic === 'client') client = receipt?.client
            console.log(client)
            if (!client.rfc && client?.address?.country !== 'MEX')
                client = {
                    ...client,
                    rfc: '00-0000000',
                }

            const receiptObj = {
                ...receipt,
                client,
                customer: client,
                internalClient: client,
            }
            await SignedInternalAPIRequest(receiptObj, 'receiptsApp/v1/generalpublicinvoice', auth.currentUser)
            setLoading(false)
            message.success('Factura creada con éxito')
            dispatch(closeModal('createInvoiceFromReceiptModalVisible'))
            dispatch(
                setData({
                    item: 'receipt',
                    data: null,
                }),
            )
        } catch (error: any) {
            setLoading(false)
            setLastError(error?.message ?? 'Ocurrió un error al crear la factura')
            message.error(error?.message ?? 'Ocurrió un error al crear la factura')
        }
    }

    return (
        <Modal
            open={createInvoiceFromReceiptModalVisible}
            footer={null}
            onCancel={() => {
                dispatch(closeModal('createInvoiceFromReceiptModalVisible'))
            }}
            closable={false}
            title={
                <div style={{ position: 'relative', paddingBottom: '15px' }}>
                    <Typography.Text
                        className="smallheader"
                        style={{
                            position: 'absolute',
                            //center the text
                            left: '50%',
                            transform: 'translateX(-50%)',
                            paddingTop: '5px',
                        }}
                    >
                        Timbrar recibo de venta
                    </Typography.Text>
                    <Row>
                        <Col xs={24} style={{ margin: 'auto' }}>
                            <Row justify="space-between" align="middle">
                                <X
                                    className="icon clickable"
                                    size={20}
                                    onClick={() => {
                                        dispatch(closeModal('createInvoiceFromReceiptModalVisible'))
                                        dispatch(
                                            setData({
                                                item: 'receipt',
                                                data: null,
                                            }),
                                        )
                                    }}
                                />
                                <></>
                            </Row>
                        </Col>
                    </Row>
                </div>
            }
        >
            <Form form={form} layout="vertical" onFinish={(v) => handleCreateInvoice(v)}>
                <Typography.Text className="smallheader descriptions">
                    Timbrar recibo con la información seleccionada
                </Typography.Text>

                <Row justify="center" style={{ marginTop: '20px' }}>
                    <SelectInput
                        placeholder="Facturar a ..."
                        name="typeOfGeneralPublic"
                        options={[
                            { label: 'Público general nacional', value: 'national' },
                            { label: 'Público general extranjero', value: 'foreign' },
                            { label: `${receipt?.client?.name ?? '-'} (cliente actual)`, value: 'client' },
                        ]}
                        customStyle={{ width: '100%' }}
                        onChange={(v: any) => {
                            setState({ ...state, typeOfGeneralPublic: v })
                        }}
                    />
                </Row>

                {state.typeOfGeneralPublic === 'client' && (
                    <Alert
                        style={{ marginBottom: '15px' }}
                        type="info"
                        icon={<Info />}
                        showIcon
                        message={
                            <Typography.Text className="smallparagraph description">
                                Intentaremos crear la factura a {receipt?.client?.name}, en caso de que algo fallé no se
                                creara nada.
                            </Typography.Text>
                        }
                    />
                )}

                {state?.typeOfGeneralPublic === 'foreign' && (
                    <Row justify="center" style={{}}>
                        <SelectInput
                            name="country"
                            placeholder="País"
                            options={countryLabeledListWithCodeAlpha
                                .map((c) => ({ label: c.name, value: c.code }))
                                .filter((c) => c.value !== 'MEX')}
                            customStyle={{ width: '100%' }}
                            onChange={(v: any) => {
                                setState({ ...state, country: v })
                            }}
                            // filterOption={(input: any, option: any) => {
                            //     return option.label.toLowerCase().includes(input.toLowerCase())
                            // }}
                        />
                    </Row>
                )}

                {lastError && (
                    <ErrorsInFormComponent errorMessage={lastError} title={'Ocurrió un error al crear la factura'} />
                )}

                <Row gutter={12} justify="end">
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Crear factura
                    </Button>
                </Row>
            </Form>
        </Modal>
    )
}

export default CreateInvoiceFromReceiptsModal
