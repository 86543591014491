import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Row, Space, Table, Tooltip, Typography } from 'antd'
import { closeModal, openModal } from '../context/modalsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { InternalItem } from '../../interfaces/internalItemDef'
import { TaxElement } from '../../interfaces/invoicesDef'
import { SearchInput } from '../components/Forms/Inputs'
import { returnCurrencyValue } from '../functions/helpers'
import { setData, updateData } from '../context/dataSlice'
import { PlusCircle } from '@phosphor-icons/react'
import moment from 'moment'
import { addParamToUrl } from '../functions/UrlParams'
import stripeLogo from '../../assets/images/stripeSquare.png'

const ServiceSeleccionModal: React.FC = () => {
    const { serviceSelectionVisible } = useSelector((state: any) => state.modals)
    const { services, selectedServices } = useSelector((state: any) => state.data)
    const [selectedRowsState, setSelectedRows] = useState<React.Key[]>([])
    const [search, setSearch] = useState('')
    const dispatch = useDispatch()

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: InternalItem[]) => {
            //SAVE SELECTED ON STATE TAKING IN MIND THE SEARCH AND FILTER
            setSelectedRows(selectedRowKeys)
        },

        getCheckboxProps: (record: InternalItem) => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.id,
        }),
    }

    useEffect(() => {
        setSelectedRows((selectedServices ?? [])?.map((s: any) => s.id))

        //IF SELECTEDSERVICES IS EMPTY REMOVE ALL SELECTED IN TABLE
    }, [serviceSelectionVisible, selectedServices, services])

    return (
        <Modal
            width={'65%'}
            style={{ top: 10 }}
            title={null}
            open={serviceSelectionVisible}
            onCancel={() => {
                dispatch(closeModal('serviceSelectionVisible'))
                setSelectedRows([])
            }}
            footer={null}
        >
            <div style={{ marginTop: '20px', width: '100%' }}></div>

            <Row justify="space-between">
                <Typography.Text className="smallheader">Seleccionar servicios / productos</Typography.Text>
                <Row
                    justify="end"
                    align="middle"
                    style={
                        {
                            // width: window.innerWidth > 768 ? '80%' : '100%'
                        }
                    }
                >
                    <Form>
                        <SearchInput
                            name="search"
                            placeholder="Buscar servicios / productos por nombre"
                            label={null}
                            styleProps={{
                                margin: 0,
                                padding: 0,
                                width: window.innerWidth > 768 ? '360px' : '100%',
                            }}
                            onChange={(v: any) => {
                                setSearch(v.target.value)
                            }}
                        />
                    </Form>
                    <Tooltip title="Crear servicio / producto">
                        <PlusCircle
                            size={20}
                            style={{ marginLeft: '10px', cursor: 'pointer' }}
                            onClick={() => {
                                dispatch(openModal('createServiceVisible'))
                            }}
                        />
                    </Tooltip>
                </Row>
            </Row>
            <div style={{ marginTop: '20px', width: '100%' }}></div>
            <Table
                //initiali sort by timestamp

                sortDirections={['descend', 'ascend']}
                locale={{
                    emptyText: 'No hay servicios o productos seleccionados',
                    sortTitle: 'Ordenar',
                    filterTitle: 'Filtrar',
                }}
                rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: [...(selectedRowsState ?? []), ...(selectedServices ?? [])],
                    ...(rowSelection ?? []),
                }}
                columns={[
                    {
                        title: 'Nombre',
                        dataIndex: 'name',
                        key: 'name',
                        filterSearch: true,
                        filteredValue: search ? [search] : undefined,
                        onFilter: (value: any, record: InternalItem) =>
                            record.name?.toLocaleLowerCase().includes(value?.toLocaleLowerCase()),
                        render(value, record, index) {
                            return (
                                <Space direction="vertical" size={0}>
                                    <Typography.Text
                                        className="smallparagraphbold primary"
                                        onClick={() => {
                                            dispatch(
                                                setData({
                                                    item: 'selectedServices',
                                                    data: [...selectedServices, record],
                                                }),
                                            )
                                        }}
                                    >
                                        {value}
                                    </Typography.Text>
                                    <Typography.Text className="smallparagraph descriptions">
                                        {record?.id}
                                    </Typography.Text>
                                </Space>
                            )
                        },
                    },
                    {
                        title: '',
                        dataIndex: 'source',
                        key: 'source',
                        render(value, record, index) {
                            if (record?.id?.startsWith('price_')) {
                                return (
                                    <Tooltip title="Este producto fue creado en Stripe e importado a Gigstack">
                                        <img
                                            src={stripeLogo}
                                            alt=""
                                            style={{ width: '20px', height: '20px', borderRadius: '3px' }}
                                        />
                                    </Tooltip>
                                )
                            }
                            return <></>
                        },
                    },
                    {
                        title: 'Precio',
                        dataIndex: 'total',
                        key: 'total',
                        sortDirections: ['descend', 'ascend'],
                        sorter: (a: any, b: any) => a.total - b.total,
                        showSorterTooltip: false,

                        render(value, record, index) {
                            return (
                                <Typography.Text
                                    className="smallparagraph descriptions"
                                    editable={{
                                        onChange: (e) => {
                                            if (!e) return

                                            const validNumber = e.replace(/[^0-9.]/g, '')

                                            // if (isNaN(Number(e))) return

                                            dispatch(
                                                updateData({
                                                    item: 'services',
                                                    data: {
                                                        ...record,
                                                        total: validNumber,
                                                        localEdited: true,
                                                    },
                                                }),
                                            )
                                        },
                                    }}
                                >
                                    {returnCurrencyValue(value)}
                                </Typography.Text>
                            )
                        },
                    },
                    {
                        title: 'Impuestos',
                        dataIndex: 'taxes',
                        key: 'taxes',

                        render(value, record, index) {
                            return (
                                <div>
                                    {value?.map((t: TaxElement) => (
                                        <Typography.Text
                                            className="p-small-regular descriptions"
                                            key={record.id + 'tax' + t.type + index}
                                        >
                                            {t.type} {Number(t.rate) * 1}% ({t.inclusive ? 'Incl.' : ''})
                                        </Typography.Text>
                                    ))}
                                </div>
                            )
                        },
                    },
                    {
                        title: 'Creado',
                        dataIndex: 'timestamp',
                        key: 'timestamp',
                        sorter: (a: any, b: any) => a.timestamp - b.timestamp,
                        defaultSortOrder: 'descend',
                        render(value, record, index) {
                            return (
                                <Typography.Text
                                    onClick={() => console.log(record.timestamp)}
                                    className="p-base-regular descriptions"
                                >
                                    {moment(record.timestamp)?.format('DD MMM YYYY HH:mm')}
                                </Typography.Text>
                            )
                        },
                    },
                ]}
                dataSource={services?.map((s: any) => ({
                    ...s,
                    key: s?.id,
                }))}
            />
            {/* Add your modal content here */}

            <Row justify="end">
                <Button
                    type="primary"
                    onClick={() => {
                        const { localSelected, localIdsSelected } = (selectedServices ?? [])?.reduce(
                            (acc: any, s: any) => {
                                if (selectedRowsState.includes(s.id)) {
                                    acc.localSelected.push(s)
                                    acc.localIdsSelected.push(s.id)
                                }
                                return acc
                            },
                            { localSelected: [] as any[], localIdsSelected: [] as string[] },
                        )

                        const remainingIdsSelected = selectedRowsState.filter((s) => !localIdsSelected.includes(s))
                        const remainingSelected = services.filter((s: any) => remainingIdsSelected.includes(s.id))

                        setSelectedRows([])
                        dispatch(
                            setData({
                                item: 'selectedServices',
                                data: [...remainingSelected, ...localSelected],
                            }),
                        )
                        dispatch(closeModal('serviceSelectionVisible'))
                        addParamToUrl('services', selectedRowsState.join(','))
                    }}
                >
                    Siguiente ({selectedRowsState.length})
                </Button>
            </Row>
        </Modal>
    )
}

export default ServiceSeleccionModal
