import { CaretRight, CheckCircle } from '@phosphor-icons/react'
import { Button, Row, Space, Typography } from 'antd'
import { collection, doc, getDoc, getDocs, getFirestore, query, where } from 'firebase/firestore'
import { useLottie } from 'lottie-react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import onboardingAutomation from '../../../../assets/animations/onboarding-automation.json'
import onboardingCheck from '../../../../assets/animations/onboarding-check.json'
import '../../../../css/CustomComponents.css' // Import the CSS file
import { TeamDef } from '../../../../interfaces/teamDef'
import { setNeedsOnboarding } from '../../../context/onboardingSlice'
import { setTeam, setTeams } from '../../../context/teamsSlice'
import { preserveQueryParams } from '../../../functions/helpers'
import { useEffect } from 'react'
import { usePostHog } from 'posthog-js/react'

export default function ConfirmOnboardingStep({
    type,
    success,
}: {
    type: 'waiting-subscription' | 'onboarding-finished'
    success?: boolean
}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const posthog = usePostHog()
    const { user } = useSelector((state: any) => state.user)
    const db = getFirestore()

    const searchParams = new URLSearchParams(window.location.search)

    const defaultOptions = {
        loop: type === 'waiting-subscription' ? true : false,
        autoplay: true,
        animationData: type === 'waiting-subscription' ? onboardingAutomation : onboardingCheck,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
        style: {
            width: '17%',
            height: '17%',
        },
    }

    // useEffect(() => {
    //     if(success) {
    //         setTimeout()
    //     }
    // }, [success])

    const { View } = useLottie(defaultOptions)

    useEffect(() => {
        try {
            posthog.capture('onboarding_finish_section_viewed', {
                v: 'v2',
                userId: user?.uid,
                billingAccount: user?.billingAccount,
            })
        } catch (error) {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (type === 'waiting-subscription') {
        return (
            <div className="confirm-onboarding--container">
                {View}
                {!success && (
                    <>
                        <Typography className="h1-bold neutral-1">Estamos configurando tu plan</Typography>
                        <Typography className="h6-regular neutral-3">
                            Houston, no tenemos problemas. Tu plan está en camino.
                        </Typography>
                    </>
                )}
                {success && (
                    <Row justify="center" className="fade-in fade-in-1">
                        <CheckCircle size={24} weight="fill" className="neutral-1" />
                        <Typography className="h6-bold neutral-1">Plan configurado correctamente</Typography>
                    </Row>
                )}
            </div>
        )
    }

    if (type === 'onboarding-finished') {
        return (
            <div className="confirm-onboarding--container onb-container--green">
                {View}
                <Typography.Title className="h4-bold" style={{ color: 'var(--primary-5)' }}>
                    Tu cuenta está lista
                </Typography.Title>
                <Space
                    className="fade-in fade-in-1"
                    direction="vertical"
                    size="middle"
                    style={{ width: '30%', alignItems: 'center' }}
                >
                    <Typography className="p-large-regular primary-5" style={{ textAlign: 'center' }}>
                        ¡Comienza a automatizar tus flujos financieros y ahorra tiempo en procesos administrativos!
                    </Typography>
                    {/* TODO QUITAR ESTO EVENTUALMENTE */}
                    <Button
                        className="btn btn-secondary"
                        onClick={async () => {
                            try {
                                posthog.capture('onboarding_finished_click', {
                                    v: 'v2',
                                })
                            } catch (error) {}
                            const requestId = searchParams.get('request_id')
                            dispatch(setNeedsOnboarding(false))
                            if (requestId) {
                                if (user?.lastTeamViewed) {
                                    const [teamDoc, teamsQuery] = await Promise.all([
                                        getDoc(doc(db, 'teams', user?.lastTeamViewed)),
                                        getDocs(
                                            query(
                                                collection(db, 'teams'),
                                                where('membersIds', 'array-contains', user?.uid),
                                            ),
                                        ),
                                    ])

                                    let teamData = teamDoc.data()
                                    dispatch(setTeam(teamData ?? []))
                                    dispatch(setTeams(teamsQuery.docs.map((doc) => doc.data() as TeamDef)))
                                }
                                navigate(preserveQueryParams('/oauth', searchParams))
                            } else {
                                navigate(preserveQueryParams('/', searchParams))
                            }
                        }}
                    >
                        <Typography className="p-large-regular neutral-1">Ir a mi cuenta</Typography>
                        <CaretRight size={24} className="neutral-1" />
                    </Button>
                </Space>
            </div>
        )
    }

    return <></>
}
