import React from 'react'
import { Row, Col, Typography, Space } from 'antd'
import { Link } from 'react-router-dom'

interface AuthFormLayoutProps {
    title: string
    subtitle?: string
    children: React.ReactNode
    footerText: string
    footerLinkText: string
    footerLinkTo: string
}

const AuthFormLayout: React.FC<AuthFormLayoutProps> = ({
    title,
    subtitle,
    children,
    footerText,
    footerLinkText,
    footerLinkTo,
}) => (
    <Row justify="center" align="middle" style={{ minHeight: '100vh', background: 'var(--neutral-8)' }}>
        <Col xs={24}>
            <div style={{ maxWidth: '400px', margin: '0 auto' }}>
                <Row justify="center" gutter={[0, 0]}>
                    <Col span={24}>
                        <Typography className="russoOne primary-1" style={{ fontSize: '18px', margin: 0 }}>
                            gigstack
                        </Typography>
                    </Col>
                    <Col span={24}>
                        <Typography className="h3-bold neutral-1" style={{ margin: 0 }}>
                            {title}
                        </Typography>
                    </Col>
                    {subtitle && (
                        <Col span={24}>
                            <Typography className="p-base-regular neutral-3" style={{ margin: 0 }}>
                                {subtitle}
                            </Typography>
                        </Col>
                    )}
                </Row>
                {children}
                <Row justify="center" style={{ marginTop: '16px' }}>
                    <Col>
                        <Space direction="horizontal" size={4}>
                            <Typography className="p-base-regular neutral-3">{footerText}</Typography>
                            <Link to={footerLinkTo}>
                                <Typography className="p-base-bold primary-1">{footerLinkText}</Typography>
                            </Link>
                        </Space>
                    </Col>
                </Row>
            </div>
        </Col>
    </Row>
)

export default AuthFormLayout
