import { Button} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { openModal } from '../../context/modalsSlice'

export default function CreateTeamButton({ closePop }: { closePop?: Function }) {
    const dispatch = useDispatch()
    const { billingAccount } = useSelector((state: any) => state.billingAccount)
    
    return (
        <Button
                hidden
                type="primary"
                disabled={billingAccount.price_id === 'free'}
                size="small"
                onClick={() => {
                    dispatch(openModal('createTeamVisible'))
                    closePop && closePop()
                }}
            >
                Nueva cuenta
            </Button>
    )   
}