export const fakeInvoiceData = {
    uuid: '2BD7BB66-A94E-4129-98A9-E13965A2C299',
    id: 'ABC123',
    from: 'Manual',
    customer: {
        name: 'John Doe',
        email: 'john.doe@example.com',
        legal_name: 'PUBLICO EN GENERAL',
        tax_id: 'XAXX010101000',
        tax_system: '616',
    },
    client: {
        name: 'John Doe',
        email: 'john.doe@example.com',
        legal_name: 'PUBLICO EN GENERAL',
        tax_id: 'XAXX010101000',
        tax_system: '616',
        rfc: 'XAXX010101000',
    },
    items: [{}],
    internalItems: [
        {
            // Datos ficticios para InternalItem
        },
    ],
    payment_form: '28',
    invoiceType: 'I',
    type: 'I',
    payment_method: 'PUE',
    use: 'G01',
    currency: 'MXN',
    exchange: 1.0,
    conditions: 'Payment due in 30 days',
    receipts: ['receipt_k1l2m3n4o5p6'],
    payments: ['pi_a1b2c3d4k1l5b3d4'],
    related_documents: [{}, {}],
    global: {},
    export: null,
    complements: [],
    date: '2024-01-08T12:00:00.000Z',
    address: {
        street: 'Calle 123',
        exterior_number: '123',
        interior_number: '123',
        neighborhood: 'Colonia',
        zip: '10200',
        locality: 'Ciudad',
        municipality: 'Municipio',
        state: 'Estado',
        country: 'País',
    },
    external_id: 'ext123',
    folio_number: 789,
    series: 'GIG',
    pdf_custom_section: 'Custom section for PDF',
    addenda: 'Additional information for the invoice',
    team: 'team123',
    namespaces: [{}],
    status: 'valid',
    livemode: true,
    timestamp: 1641686400000,
    total: 1500.0,
    cancellation_status: 'Not Cancelled',
    relation: {},
    clientName: 'Client Name',
}
