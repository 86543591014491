import { Modal, Space, Tag, Typography } from 'antd'

interface PlanConfirmationModalProps {
    isOpen: boolean
    onClose: () => void
    onConfirm: () => void
    selectedPlan: any
    currentPlan: any
    isLoading: boolean
    billingCycle: string
}

const PlanConfirmationModal = ({
    isOpen,
    onClose,
    onConfirm,
    selectedPlan,
    currentPlan,
    isLoading,
    billingCycle,
}: PlanConfirmationModalProps) => {
    const formatPrice = (amount: number) => {
        const price = billingCycle === 'monthly' ? Math.round(amount) / 100 : Math.round(amount) / 100 / 12
        return new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN',
        }).format(price)
    }

    return (
        <Modal
            title="Confirmar cambio de plan"
            open={isOpen}
            onCancel={onClose}
            onOk={onConfirm}
            okText="Confirmar cambio"
            cancelText="Cancelar"
            confirmLoading={isLoading}
            className="rounded-lg"
        >
            <Space direction="vertical" size="large" className="w-full">
                <Typography.Text className="text-center block">
                    ¿Estás seguro que deseas cambiar tu plan actual?
                </Typography.Text>

                <Space direction="vertical" className="w-full bg-gray-50 p-4 rounded-lg">
                    <Space className="w-full justify-between">
                        <Typography.Text type="secondary">Plan actual:</Typography.Text>
                        <Space>
                            <Typography.Text strong>{currentPlan?.name}</Typography.Text>
                            <Tag color="blue">{formatPrice(currentPlan?.price?.baseAmount)}</Tag>
                        </Space>
                    </Space>

                    <Space className="w-full justify-between">
                        <Typography.Text type="secondary">Nuevo plan:</Typography.Text>
                        <Space>
                            <Typography.Text strong>{selectedPlan?.name}</Typography.Text>
                            <Tag color="green">{formatPrice(selectedPlan?.price[billingCycle]?.baseAmount)}</Tag>
                        </Space>
                    </Space>
                </Space>

                <Typography.Text type="secondary" className="block text-center">
                    El cambio se aplicará inmediatamente y se ajustará tu facturación
                </Typography.Text>
            </Space>
        </Modal>
    )
}

export default PlanConfirmationModal
