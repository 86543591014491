import { Button, Form, Modal, Row, Typography } from 'antd'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AddressFormInputLayout from '../components/Forms/AddressFormInputLayout'
import { setData } from '../context/dataSlice'
import { closeModal } from '../context/modalsSlice'
import { usePostHog } from 'posthog-js/react'

interface AddressModalProps {
    useGoogleMaps?: boolean
    parentForm: any // Form instance from the parent component
}

export const AddressModal = ({ useGoogleMaps, parentForm }: AddressModalProps) => {
    const { addressModalVisible } = useSelector((state: any) => state.modals)
    const { fiscalInformationOnb } = useSelector((state: any) => state.data)
    const dispatch = useDispatch()
    const posthog = usePostHog()
    const [form] = Form.useForm()

    useEffect(() => {
        if (addressModalVisible) {
            // Initialize modal form with current address data from parent form
            const currentAddress = parentForm.getFieldValue('address') || {}
            form.setFieldsValue(currentAddress)
        }
    }, [addressModalVisible, parentForm, form])

    const handleFinish = (values: any) => {
        try {
            posthog.capture('onboarding_address_confirmed_manually', {
                v: 'v2',
            })
        } catch (error) {}
        const updatedAddress = {
            ...fiscalInformationOnb?.address,
            ...values,
        }

        const addressStr = `${values.street || ''} #${values.exterior || ''}, ${values.neighborhood || ''}, ${values.city || ''}, ${values.zip || ''} ${values.state || ''}`

        // Update the parent form
        parentForm.setFieldsValue({
            address: updatedAddress,
            addressStr: addressStr,
        })

        // Update the global context
        dispatch(
            setData({
                item: 'fiscalInformationOnb',
                data: {
                    ...fiscalInformationOnb,
                    address: updatedAddress,
                    addressStr: addressStr,
                },
            }),
        )

        dispatch(closeModal('addressModalVisible'))
    }

    return (
        <Modal
            title={null}
            open={addressModalVisible}
            footer={null}
            zIndex={1003}
            onCancel={() => {
                dispatch(closeModal('addressModalVisible'))
            }}
            width={450}
        >
            <Form form={form} onFinish={handleFinish} layout="vertical" style={{ width: '100%' }}>
                <div className="d-flex flex-column" style={{ padding: '15px 30px' }}>
                    <Typography.Text className="h5-bold">Confirma tu dirección</Typography.Text>
                    <Typography.Text className="p-base-regular neutral-3">
                        Asegúrate de que tu dirección esté correcta
                    </Typography.Text>

                    <AddressFormInputLayout useGoogleMaps={useGoogleMaps} disabled={false} />

                    <Row justify="end" style={{ marginTop: '15px' }}>
                        <Button
                            className="btn-secondary"
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                                dispatch(closeModal('addressModalVisible'))
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button className="btn-primary" htmlType="submit">
                            Guardar
                        </Button>
                    </Row>
                </div>
            </Form>
        </Modal>
    )
}
