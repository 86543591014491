import React, { useState } from 'react'
import { FormInstance, Col, Row, Form, Button, message, Typography, Alert } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { CheckBoxInput, NumberInput } from './Inputs'
import { setTeam } from '../../context/teamsSlice'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { closeModal } from '../../context/modalsSlice'

interface PropsDef {
    incomingForm?: FormInstance
    buttonTitle?: string
}

const PaymentsRemindersSettings: React.FC<PropsDef> = ({ incomingForm, buttonTitle }) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const usingForm = incomingForm ?? form
    const [messageApi, contextHolder] = message.useMessage()
    const { team } = useSelector((state: any) => state.team)

    const [sendPaymentsReminders, setSendPaymentsReminders] = useState(team?.defaults?.sendPaymentsReminders ?? false)

    const onFinish = async (values: any) => {
        try {
            await usingForm.validateFields()
            messageApi.open({
                type: 'loading',
                content: 'Actualizando información...',
                duration: 0,
                icon: ' ',
            })

            dispatch(
                setTeam({
                    ...team,
                    defaults: {
                        ...team?.defaults,
                        paymentsReminderDays: values.paymentsReminderDays ?? team?.defaults?.paymentsReminderDays ?? 0,
                    },
                }),
            )
            await updateDoc(doc(getFirestore(), 'teams', team?.id), {
                'defaults.paymentsReminderDays':
                    values.paymentsReminderDays ?? team?.defaults?.paymentsReminderDays ?? 0,
            })
            messageApi.destroy()

            message.success('Información actualizada correctamente')
            await new Promise((resolve) => setTimeout(resolve, 500))
            dispatch(closeModal('settingsDrawerVisible'))
        } catch (e: any) {
            // messageApi.destroy();
            messageApi.error(e.message)
        }
    }

    return (
        <Form layout="vertical" form={usingForm} onFinish={onFinish} initialValues={{ ...team?.defaults }}>
            {/* Form content goes here */}
            {contextHolder}
            <Row>
                <Col xs={24}>
                    <CheckBoxInput
                        label="Activar envíos de recordatorios"
                        name="sendPaymentsReminders"
                        onChange={async (v: any) => {
                            setSendPaymentsReminders(v.target.checked)
                            dispatch(
                                setTeam({
                                    ...team,
                                    defaults: { ...team?.defaults, sendPaymentsReminders: v.target.checked },
                                }),
                            )
                            await updateDoc(doc(getFirestore(), 'teams', team?.id), {
                                'defaults.sendPaymentsReminders': v.target.checked,
                            })
                        }}
                        content={
                            <Typography.Text className="smallparagraph descriptions">
                                Si la opción se encuentra activa, enviaremos un correo de recordatorio para realizar el
                                pago.
                            </Typography.Text>
                        }
                    />
                </Col>
                {sendPaymentsReminders && (
                    <Col xs={24}>
                        <NumberInput
                            placeholder="5 días"
                            label="Enviar correo x días anteriores al vencimiento"
                            name="paymentsReminderDays"
                            min={0}
                            max={(team?.defaults?.limitDaysToPay ?? 31) - 1}
                            step={1}
                        />
                        <Alert
                            message={
                                <Typography.Text className="smallparagraph descriptions">
                                    El máximo de días será definido por el límite de días, el cual puedes configurar en
                                    "Días límite de pago"
                                </Typography.Text>
                            }
                            type="info"
                            showIcon
                            style={{ marginTop: '10px' }}
                        />
                    </Col>
                )}
                <Col span={24} style={{ marginTop: '25px' }}>
                    <Row justify="end">
                        <Button type="primary" htmlType="submit">
                            {buttonTitle ?? 'Actualizar información'}
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Form>
    )
}

export default PaymentsRemindersSettings
