import { Button, Col, Form, FormInstance, Row, message } from 'antd'
import React, { useState } from 'react'
import { SelectInput, TextInput } from './Inputs'
import { CFDIUses, taxRegimes } from '../../datasets/Fiscal'
import { countryLabeledListWithCodeAlpha3 } from '../../datasets/Countries'
import ErrorsInFormComponent from './ErrorsInFormComponent'
import { useDispatch, useSelector } from 'react-redux'
import { generateCode } from '../../functions/helpers'
import { arrayUnion, doc, getFirestore, setDoc, updateDoc } from 'firebase/firestore'
import { billingAccountDefSet } from '../../../interfaces/billingAccountDef'
import { setBillingAccount } from '../../context/billingAccountSlice'
import { setUser } from '../../context/userSlice'
import { teamDefSet } from '../../../interfaces/teamDef'
import { setTeam } from '../../context/teamsSlice'
import { usePostHog } from 'posthog-js/react'
import moment from 'moment'

const BillingAccountForm: React.FC<any> = ({
    incomingForm,
    buttonTitle,
    onUpdate,
    onValuesUpdate,
}: {
    incomingForm?: FormInstance
    buttonTitle?: string
    onUpdate?: any
    onValuesUpdate?: (v: any) => void
}) => {
    // Add your component logic here
    const posthog = usePostHog()
    const dispatch = useDispatch()
    const [errors, setErrors] = useState<any>([])

    const [form] = Form.useForm()

    const [country, setcountry] = useState('')
    const [loading, setloading] = useState(false)
    const { billingAccount } = useSelector((state: any) => state.billingAccount)
    const { user } = useSelector((state: any) => state.user)

    let usingForm = incomingForm ?? form

    const prepareBillingAccount = async (values: any) => {
        const id =
            billingAccount?.id && billingAccount?.id !== '' && billingAccount?.id !== ' '
                ? billingAccount?.id
                : generateCode(15, 'ba')
        return billingAccountDefSet({
            ...billingAccount,
            ...values,
            id,
            owner: user?.uid,
            admins: [user?.uid],
            teamMembers: 1,
            uid: user?.uid,
            address: {
                zip: values.zip,
                country: values.country,
            },
        })
    }

    const createUpdateBillingAccount = async (values: any) => {
        const ba = await prepareBillingAccount(values)
        console.log(ba)

        const id =
            billingAccount?.id && billingAccount?.id !== '' && billingAccount?.id !== ' ' ? billingAccount?.id : ba.id
        const db = doc(getFirestore(), 'billingAccounts', id)
        const userdb = doc(getFirestore(), 'users', user?.uid)

        try {
            await setDoc(db, ba, { merge: true })
            await updateDoc(userdb, {
                billingAccounts: arrayUnion(id),
                lastBillingAccountViewed: id,
                billingAccount: id,
            })

            try {
                const firsTime = window.location?.pathname?.includes('finishSetup')
                posthog?.capture(firsTime ? 'first_time_ba_setup' : 'update_ba', {
                    ba: id,
                    [firsTime ? 'baProfileCompleted' : 'baUpdated']: moment().format('DD/MM/YYYY HH:mm'),
                })
            } catch (error) {}

            setBillingAccount({
                ...ba,
            })
            setUser({
                ...user,
                billingAccounts: arrayUnion(id),
                lastBillingAccountViewed: id,
                billingAccount: id,
            })
            return ba
        } catch (error) {
            console.log('🚀 ~ createUpdateBillingAccount ~ error:', error)
        }

        console.log(id)
    }

    const createTeam = async (ba: any) => {
        const teamId = generateCode(15, 'team')
        const teamdb = doc(getFirestore(), 'teams', teamId)
        const badb = doc(getFirestore(), 'billingAccounts', ba.id)
        const userdb = doc(getFirestore(), 'users', user?.uid)
        const team = teamDefSet({
            id: teamId,
            name: ba.legalName,
            owner: user?.uid,
            admins: [user?.uid],
            membersIds: [user.uid],
            members: [
                {
                    email: user?.email,
                    expenses: 'editor',
                    id: user?.uid,
                    invoices: 'editor',
                    payments: 'editor',
                    isAdmin: true,
                    isSuperAdmin: true,
                    rol: 'admin',
                    status: 'active',
                    services: 'editor',
                },
            ],
            teamMembers: 1,
            uid: user?.uid,
            billingAccount: ba.id,
            billingAccounts: [ba.id],
            address: {
                zip: ba.zip,
                country: ba.country,
            },
        })

        setDoc(teamdb, team, { merge: true })

        updateDoc(badb, {
            teams: arrayUnion(teamId),
        })
        updateDoc(userdb, {
            lastTeamViewed: teamId,
            teams: arrayUnion(teamId),
            lastBillingAccountViewed: ba.id,
            billingAccounts: arrayUnion(ba.id),
        })

        dispatch(setTeam(team))
        dispatch(
            setUser({
                ...user,
                lastTeamViewed: teamId,
                teams: [...(user?.teams ?? []), teamId],
                lastBillingAccountViewed: ba.id,
                billingAccounts: [...(user?.billingAccounts ?? []), ba.id],
            }),
        )
        dispatch(
            setBillingAccount({
                ...ba,
                teams: [...(billingAccount?.teams ?? []), teamId],
            }),
        )
        console.log({
            ...ba,
            teams: [...(billingAccount?.teams ?? []), teamId],
        })

        return team
    }

    const onFinish = async (values: any) => {
        try {
            usingForm.validateFields()
        } catch (error: any) {
            setErrors(error.errorFields)
            return
        }

        setloading(true)
        let ba: any

        try {
            ba = await createUpdateBillingAccount(values)
        } catch (error) {
            return message.error('Ocurrio un error al crear la cuenta de facturación')
        }

        if (ba?.teams?.length === 0) {
            console.log('SHOULD CREATE TEAM')
            try {
                await createTeam(ba)
            } catch (error) {
                console.log('🚀 ~ onFinish ~ error:', error)
                return
            }
        } else {
            console.log(ba)

            dispatch(
                setUser({
                    ...user,
                    lastBillingAccountViewed: ba?.id,
                    billingAccount: ba?.id,
                    billingAccounts: [...(user?.billingAccounts ?? []), ba?.id],
                }),
            )
            dispatch(setBillingAccount({ ...ba }))
        }
        setloading(false)

        return
        // const id = (billingAccount?.id && billingAccount?.id !== "" && billingAccount?.id !== ' ') ? billingAccount?.id : generateCode(15, 'ba')
        // const db = doc(getFirestore(), 'billingAccounts', id)

        // // setloading(true)
        // let baData = billingAccountDefSet({
        //     id,
        //     ...values, address: {
        //         zip: values.zip,
        //         country: values.country
        //     },
        // })

        // // const getCurrent = await getDoc(db)
        // // console.log(getCurrent.exists());

        // //(!user?.billingAccounts?.length || !user?.lastBillingAccountViewed || !billingAccount?.legalName || !(billingAccount?.teams ?? []).length)

        // if (!billingAccount?.id) {
        //     console.log('CREATE BILLING ACCOUNT');
        //     console.log(id);
        //     baData = billingAccountDefSet({
        //         ...baData,
        //         uid: user?.uid,
        //         owner: user?.uid,
        //         admins: [user?.uid],
        //         teamMembers: 1
        //     })
        //     // BILLING ACCOUNT CREATED

        //     try {
        //         await setDoc(db, baData)
        //     } catch (error) {

        //         message.error('Ocurrio un error al crear la cuenta de facturación')
        //         setloading(false)
        //         return;
        //     }

        //     try {
        //         const teamId = await createTeam(baData)
        //         await updateDoc(doc(getFirestore(), 'billingAccounts', id), {
        //             teams: arrayUnion(teamId)
        //         })
        //         await updateDoc(doc(getFirestore(), 'users', user?.uid), {
        //             billingAccounts: arrayUnion(id),
        //             lastBillingAccountViewed: id,
        //             lastTeamViewed: teamId,
        //             billingAccount: id
        //         })
        //         const latestBA = await getDoc(db);
        //         const latestUser = await getDoc(doc(getFirestore(), 'users', user?.uid))
        //         const latestTeam = await getDoc(doc(getFirestore(), 'teams', teamId))

        //         dispatch(setUser({
        //             ...latestUser.data()
        //         }))
        //         dispatch(setTeam({
        //             ...latestTeam.data()
        //         }))
        //         dispatch(setBillingAccount({
        //             ...latestBA.data()
        //         }))
        //         setloading(false)
        //         if (onUpdate) {
        //             onUpdate({ ...values, id: id })
        //         }
        //         return;
        //     } catch (error) {
        //         console.log("🚀 ~ onFinish ~ error:", error)
        //         setloading(false)
        //     }

        //     console.log(baData);

        //     // await setDoc(db, baData)
        //     // await updateDoc(doc(getFirestore(), 'users', user?.uid), {
        //     //     billingAccounts: [id],
        //     //     lastBillingAccountViewed: id,
        //     //     billingAccount: id
        //     // })

        //     dispatch(setBillingAccount({
        //         ...values,
        //         id: id
        //     }))
        //     dispatch(setUser({
        //         ...user,
        //         billingAccounts: [id],
        //         lastBillingAccountViewed: id,
        //         billingAccount: id
        //     }))

        //     // createTeam(baData)
        //     if (onUpdate) {
        //         onUpdate({ ...values, id: id })
        //     }

        //     setloading(false)
        // } else {
        //     console.log(billingAccount?.id);

        //     console.log('ENTERING');
        //     baData = billingAccountDefSet({
        //         ...baData,
        //         ...billingAccount,
        //         ...values,
        //         address: {
        //             zip: values.zip,
        //             country: values.country
        //         },
        //     })

        //     try {
        //         await updateDoc(db, { ...baData })
        //     } catch (error) {
        //         console.log("🚀 ~ onFinish ~ error:", error)
        //         setloading(false)
        //         return
        //     }

        //     if (billingAccount?.teams?.length === 0) {
        //         createTeam(baData)
        //     } else dispatch(setBillingAccount({ ...baData }))

        //     setloading(false)
        // }
    }

    return (
        <Form
            onValuesChange={(c: any, v: any) => {
                if (onValuesUpdate) {
                    onValuesUpdate(v)
                }
            }}
            onFinish={onFinish}
            onError={(e: any) => {
                setErrors(e.errorFields)
            }}
            onErrorCapture={(e: any) => {
                setErrors(e.errorFields)
            }}
            layout="vertical"
            form={incomingForm ?? form}
            initialValues={{ ...billingAccount, ...billingAccount?.address }}
        >
            {/* Add your form elements here */}
            <Row gutter={[32, { xs: 12, lg: 12 }]} align="top">
                <Col xs={24}>
                    <TextInput
                        name="legalName"
                        label="Nombre o Razón Social"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingresa el nombre legal de la empresa',
                            },
                        ]}
                        description="Conforme a la constancia de situación fiscal: sin acentos, ni terminaciones."
                        placeholder="Nombre Legal"
                    />
                </Col>
                <Col xs={24} lg={12}>
                    <TextInput
                        name="rfc"
                        label={country === 'MEX' || !country ? 'RFC' : 'Identificador Tributario'}
                        placeholder="RFC"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingresa tu RFC',
                            },
                        ]}
                    />
                </Col>
                <Col xs={24} lg={12}>
                    <SelectInput
                        name="country"
                        label="País de incorporación"
                        placeholder="País de incorporación"
                        options={countryLabeledListWithCodeAlpha3.map((c) => ({ label: c.name, value: c.code }))}
                        onChange={(value: any) => {
                            setcountry(value)
                            if (value !== 'MEX') {
                                usingForm.setFieldsValue({
                                    tax_system: '616',
                                })
                            } else {
                                usingForm.setFieldsValue({
                                    tax_system: '601',
                                })
                            }
                        }}
                        rules={[
                            {
                                required: true,
                                message: 'Por favor selecciona el país de incorporación',
                            },
                        ]}
                    />
                </Col>
                <Col xs={24} lg={12}>
                    <SelectInput
                        name="tax_system"
                        label="Régimen Fiscal"
                        placeholder="Régimen Fiscal"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor selecciona el régimen fiscal',
                            },
                        ]}
                        options={taxRegimes.map((r) => ({ label: r.label, value: r.value }))}
                    />
                </Col>
                <Col xs={24} lg={12}>
                    <TextInput
                        name="zip"
                        label="Código Postal"
                        placeholder="000000"
                        description="Código postal del domicilio fiscal"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingresa tu código postal',
                            },
                        ]}
                    />
                </Col>
                <Col xs={24}>
                    <SelectInput
                        name="use"
                        label="Uso de Factura"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor selecciona el uso de las facturas',
                            },
                        ]}
                        options={CFDIUses.map((r) => ({ label: r.label, value: r.value }))}
                    />
                </Col>
                <Col xs={24}>
                    <ErrorsInFormComponent title={'Errores'} form={usingForm} formErrors={errors} />
                </Col>
                <Col xs={24} style={{ marginTop: '25px' }}>
                    <Row>
                        <Button type="primary" className="btn-primary w-100" loading={loading} htmlType="submit">
                            {buttonTitle ?? 'Siguiente'}
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Form>
    )
}

export default BillingAccountForm
