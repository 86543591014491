import { Button, Divider, Form, FormInstance, message, Modal, Row, Space, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../context/modalsSlice'
import { IntegrationOnboarding } from '../../components/Integrations/IntegrationOnboarding'
import { integrations } from '../../datasets/Integrations'
import { useEffect, useState } from 'react'
import { addParamToUrl } from '../../functions/UrlParams'
import { Robot } from '@phosphor-icons/react'
import { GetTeamHelper } from '../../context/databaseContextHelpers'
import SwitchHolder from '../../components/Forms/SwitchHolder'
import { NumberInput, SelectInput, TextInput } from '../../components/Forms/Inputs'
import { SignedInternalAPIRequest } from '../../functions/APIRequest'
import { useAuth } from 'reactfire'
import { updateAutomaticInvoicingIntegration } from './helpers'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { paymentForms } from '../../datasets/Fiscal'
import { Auth } from 'firebase/auth'
import PaypalLogo from '../../../assets/images/logo-Paypal.png'

const PaypalCheckoutButton = ({ order }: any) => {
    const handleClick = () => {
        const url = order?.links?.find((l: any) => l.rel === 'payer-action')?.href;
        if (url) {
        window.open(url, '_blank');
        } else {
        console.error('URL de PayPal no encontrada.');
        }
    };

    return (
        <Button
            type="primary"
            style={{
                backgroundColor: '#FFC439', // Color del botón de PayPal
                border: 'none',
                fontWeight: 'bold',
                padding: '25px 30px',
                fontSize: '16px',
                borderRadius: '5px',
                width: '100%',
            }}
            onClick={handleClick}
        >
             <img src={PaypalLogo} style={{ height: '35px' }} alt="Paypal" />
        </Button>
    );
};
    
const createPaypalPaymentForTesting = async ({ form, setLoading, auth, teamId, setOrder, setAccount } : { form: FormInstance, setLoading: (v: null | string) => void, auth: Auth, teamId: string, setOrder: (v: any) => void, setAccount: (v: any) => void}) => {
    try {
        setLoading('create_payment')

        const { amount, currency } = await form.validateFields()
        console.log("AMOUNT", amount)
        console.log("CURRENCY", currency)
        const resp = await SignedInternalAPIRequest(
            { amount, currency, teamId },
            'paypalconnectionapp/v1/orders/create',
            auth?.currentUser
        )
        
        setOrder(resp.order)
        setAccount(resp.paypalAccount)

    } catch (error: any) {
        message.error(error?.message || 'Ocurrió un error al crear el pago de prueba')
    } finally {
        setLoading(null)
    }
}

export const PayPalModal = () => {
    const dispatch = useDispatch()
    const auth = useAuth()
    const { paypalModalVisible } = useSelector((state: any) => state.modals)
    const { team } = useSelector((state: any) => state.team)
    
    const [order, setOrder] = useState<any>(null)
    const [account, setAccount] = useState<any>(null)
    const [loading, setLoading] = useState<null | string>(null)
    const integration = integrations(team).find((i) => i.id === 'paypal')
    const [step, setStep] = useState(team?.paypal?.completed ? 1 : 0)
    const [automaticInvoicing, setAutomaticInvoicing] = useState(team?.paypal?.automaticInvoicing ?? false)
    const [form] = Form.useForm()

    useEffect(() => {
        if (paypalModalVisible) {
            addParamToUrl('open', 'paypal')
        }
    }, [paypalModalVisible])

    const handleSubmit = async () => {
        try {
            setLoading('submit')

            const values = await form.validateFields()

            if(team?.paypal?.completed) {
                await updateDoc(doc(getFirestore(), `teams/${team?.id}`), {
                    'paypal.defaults.serviceDescription': values.serviceDescription,
                    'paypal.defaults.paymentForm': values.paymentForm,
                })
                await GetTeamHelper(team?.id, dispatch)
                setLoading(null)
                
                dispatch(closeModal('paypalModalVisible'))
                return message.success('Configuración actualizada')
            }

            const data = await SignedInternalAPIRequest(
                { ...values, teamId: team?.id },
                'paypalconnectionapp/v1/connect',
                auth.currentUser
            )
            console.log("DATA", data)
            if (data?.completed) {
                await GetTeamHelper(team?.id, dispatch)
                setAutomaticInvoicing(true)
                message.success('Configuración actualizada')
            }
            setLoading(null)
        } catch (error: any) {
            console.error('Error: ', error)

            message.error(error?.message || 'Ocurrió un error al actualizar la configuración')
            setLoading(null)
        }
    }


    const ToRender = () => {
        switch (step) {
            case 0:
                return (
                    <IntegrationOnboarding
                        logo={integration?.logo ?? ''}
                        title="Conecta PayPal con gigstack"
                        description="Cobra y factura automáticamente con PayPal"
                        bullets={[
                            { type: 'positive', description: 'Facturar automáticamente tus ventas' },
                            { type: 'positive', description: 'Sincroniza automáticamente tus ventas' },
                        ]}
                        IntegrationFooter={
                            <Row justify="space-between" style={{ padding: '20px 24px 20px 24px' }}>
                                <Row>
                                    <Button type="primary" onClick={() => setStep(step + 1)}>
                                        Conectar
                                    </Button>
                                </Row>
                            </Row>
                        }
                    />
                )
            case 1:
                return (
                    <div style={{ padding: '20px 24px' }}>
                        <Typography.Text className="mediumparagraphbold">Conecta tu cuenta en PayPal</Typography.Text>
                        <Typography.Text className="smallparagraph descriptions">
                            Conecta tu cuenta y automatiza todas las transacciones que recibas.
                        </Typography.Text>

                        <Form form={form} layout="vertical" style={{ marginTop: '15px' }} onFinish={handleSubmit} initialValues={{
                            currency: 'MXN',
                            paymentForm: '06',
                            amount: '100.00'
                        }}>
                            <Divider />
                            {team?.paypal?.completed && (
                                <Space direction='vertical'>
                                    <SwitchHolder
                                        changeState={async () => {
                                            await updateAutomaticInvoicingIntegration({
                                                automaticInvoicing,
                                                dispatch, 
                                                setAutomaticInvoicing,
                                                team,
                                                integrationName: 'paypal'
                                            })
                                        }}
                                        value={automaticInvoicing}
                                        switchState={automaticInvoicing}
                                        icon={<Robot className="icon clickable" weight="regular" size={16} />}
                                        title={'Activar facturación automática de paypal'}
                                        description="Intentaremos facturar automáticamente tus ventas de paypal, si no es posible, crearemos un recibo de venta para que tu cliente genere su factura"
                                    />

                                    <Divider />
                                    
                                    <TextInput defaultValue={team?.paypal?.defaults?.serviceDescription || 'Venta de paypal'} label="Descripción por defecto para las ordenes" name="serviceDescription" description='Usaremos esta descripción para las órdenes sin descripción' />    
                                    
                                    <SelectInput name='paymentForm' label='Forma de pago por defecto' defaultValue={team?.paypal?.defaults?.paymentForm || '06'} options={paymentForms} description='Esta opción se utilizará cuando Paypal no proporcione el método de pago' />
                                    
                                    <Divider style={{ margin: '0px 0px' }} />

                                    <Typography.Text className="mediumparagraphbold">
                                        Crea un pago de prueba para verificar que la conexión sea correcta.
                                    </Typography.Text>

                                    <Row justify="space-between" align="middle">
                                        <NumberInput name='amount' min={1} max={10000} step={1} propsStyle={{ margin: 0, width: '45%' }} />
                                        <SelectInput name='currency' options={[{ label: 'MXN', value: 'MXN' }, { label: 'USD', value: 'USD' }]} customStyle={{ margin: 0, width: '27%' }} />
                                        <Button onClick={async () => {
                                            await createPaypalPaymentForTesting({
                                                form,
                                                setLoading,
                                                auth,
                                                teamId: team?.id, 
                                                setOrder, 
                                                setAccount
                                            })
                                        }} type="default"style={{ width: '25%' }} loading={loading === 'create_payment'}>Crear pago</Button>
                                    </Row>

                                    {order?.id && <Space direction='vertical' style={{ width: '100%' }}>
                                        <Typography.Text className='mediumparagraph'>Puedes usar las siguientes credenciales para pagar el pago</Typography.Text>
                                        <Row justify="space-between">
                                            <TextInput label="Correo" name="email" defaultValue={account?.email} customStyle={{ width: '55%', }}/>
                                            <TextInput label="Contraseña" name="password" defaultValue={account?.psw} customStyle={{ width: '43%' }} />
                                        </Row>
                                        <PaypalCheckoutButton order={order} />
                                    </Space>}
                                    
                                    <Row justify={'end'} style={{ marginTop: '20px' }}>
                                        <Button loading={loading === 'submit'} type="primary" htmlType="submit">
                                            Guardar
                                        </Button>
                                    </Row>
                                </Space>
                            )}
                            {!team?.paypal?.completed && (
                                <>
                                    <TextInput
                                        label="Client ID Live"
                                        name="clientId"
                                        rules={[{ required: true, message: 'Por favor ingrese Client ID Live' }]}
                                    />
                                    <TextInput
                                        label="Client ID Test"
                                        name="clientIdTest"
                                        rules={[{ required: true, message: 'Por favor ingrese su API Key Test' }]}
                                    />

                                    <Divider />

                                    <TextInput
                                        label="Secret Live"
                                        name="clientSecret"
                                        rules={[{ required: true, message: 'Por favor ingrese su API Key Test' }]}
                                    />

                                    <TextInput
                                        label="Secret Test"
                                        name="clientSecretTest"
                                        rules={[{ required: true, message: 'Por favor ingrese su API Key Test' }]}
                                    />
                                    <Row justify="end" style={{ marginTop: '35px' }}>
                                        <Button loading={loading === 'submit'} type="primary" htmlType="submit">
                                            Conectar mi cuenta
                                        </Button>
                                    </Row>
                                </>
                            )}
                        </Form>
                    </div>
                )
            default:
                return <></>
        }
    }

    return (
        <Modal
            open={paypalModalVisible}
            onCancel={() => {
                dispatch(closeModal('paypalModalVisible'))
            }}
            className="integrationModal"
            title={null}
            footer={null}
            style={{ top: 55 }}
        >
            <ToRender />
        </Modal>
    )
}
