import { doc, getDoc, getFirestore } from "firebase/firestore"

export const getExchangeRatesByDate = async (date: string) => {
    try {
        const rate = await getDoc(doc(getFirestore(), 'rates', date))
        if (rate.exists()) {
            return rate.data()?.conversion_rates
        } else {
            const currentRates = await getDoc(doc(getFirestore(), 'rates', 'rates'))
            return currentRates.data()?.conversion_rates
        }
    } catch (error) {
        return null
    }
}
