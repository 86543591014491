export interface Automation {
    action: string
    from: string
    ref: string
    type: string
}

export const automationSet = (object: any) => {
    const automation: Automation = {
        action: object['action'] ?? null,
        from: object['from'] ?? null,
        ref: object['ref'] ?? null,
        type: object['type'] ?? null,
    }
    return automation
}
