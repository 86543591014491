import { Route, Routes } from 'react-router-dom'
import { SuspenseWithPerf } from 'reactfire'
import Loader from '../appStates/LoadingView'
import AuthWrapper from '../auth/AuthWrapper'
import OnboardingRoutes from '../auth/routes/OnboardingRoutes'
import PublicRoutes from '../auth/routes/PublicRoutes'
import PricingCardDeck from '../components/ComponentIndividual/PricingCardDeck'
import MainLayout from '../Layouts/MainLayout'
import ConfirmOnboardingStep from '../new-onboarding/onboarding/steps/ConfirmOnboardingStep'
import { OAuthView } from '../pages/OAuthView'

const MainRoutes = () => {
    return (
        <SuspenseWithPerf traceId={'firebase-user-wait'} fallback={<Loader loading={true} />}>
            <AuthWrapper publicRoutes={<PublicRoutes />} onboardingRoutes={<OnboardingRoutes />}>
                <Routes>
                    <Route path="/:tab" element={<MainLayout element="home" />} />
                    <Route path="/onboarding/finished" element={<ConfirmOnboardingStep type="onboarding-finished" />} />
                    <Route path="*" element={<MainLayout element="404" />} />
                    <Route path="/oauth" element={<OAuthView />} />
                    <Route path="/memberships" element={<PricingCardDeck />} />
                </Routes>
            </AuthWrapper>
        </SuspenseWithPerf>
    )
}

export default MainRoutes
