import React from 'react'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'

interface TabConfig {
    key: string
    title: React.ReactNode | string
    content: React.ReactNode
}

interface DynamicTabsProps extends TabsProps {
    tabsConfig: TabConfig[]
}

const DynamicTabs: React.FC<DynamicTabsProps> = ({ tabsConfig, ...restProps }) => {
    return (
        <Tabs type="card" {...restProps}>
            {tabsConfig.map((tab) => (
                <Tabs.TabPane key={tab.key} tab={tab.title}>
                    {tab.content}
                </Tabs.TabPane>
            ))}
        </Tabs>
    )
}

export default DynamicTabs
