export const tagsColors = [
    {
        //BLUE
        name: 'blue',
        bg: '#DCE0FF',
        text: '#6B7CF9',
    },
    {
        //PURPLE
        name: 'purple',
        bg: '#DCE0FF',
        text: '#8666FF',
    },
    {
        //GREEN
        name: 'lightgreen',
        bg: '#DCF2EA',
        text: '#52BD94',
    },
    {
        //GREEN
        name: 'green',
        bg: '#DCF2EA',
        text: '#429777',
    },
    {
        //GRAY
        name: 'gray',
        bg: '#F0F1F3',
        text: '#474D66',
    },
    {
        //YELLOW
        name: 'yellow',
        bg: '#FFEFD2',
        text: '#66460D',
    },
    {
        //RED
        name: 'red',
        bg: '#D3F5F7',
        text: '#0F5156',
    },
    {
        //ORANGE
        name: 'orange',
        bg: '#F8E3DA',
        text: '#DE7548',
    },
    {
        //PINK
        name: 'pink',
        bg: '#FBDDF3',
        text: '#ED55C2',
    },
    {
        name: 'teal',
        bg: '#D3F5F7',
        text: '#0F5156',
    },
]
