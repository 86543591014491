import { Pencil, Trash } from '@phosphor-icons/react'
import { Space, Tooltip, Typography } from 'antd'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { setData } from '../../../context/dataSlice'
import { paymentForms } from '../../../datasets/Fiscal'
import { openModal } from '../../../context/modalsSlice'
import { returnCurrencyValue } from '../../../functions/helpers'

interface ComplementColumn {
    invoices: string
    paymentForm: string
    currency: string
    exchange: number
    date: string
}

const ComplementsActionsColumn = ({ index }: { index: number }) => {
    const dispatch = useDispatch()
    const { invoice } = useSelector((state: any) => state.data)

    return (
        <div>
            <Space direction="horizontal" size="small">
                <Tooltip title="Editar complemento">
                    <Pencil
                        weight="regular"
                        className="clickable"
                        size={16}
                        onClick={() => {
                            console.log(invoice)

                            dispatch(openModal('paymentComplementModalVisible'))
                            dispatch(
                                setData({
                                    item: 'invoiceComplement',
                                    data: { ...invoice?.complements[index].data[0], edit: true, index },
                                }),
                            )
                        }}
                    />
                </Tooltip>
                <Tooltip title="Eliminar complemento">
                    <Trash
                        weight="regular"
                        className="clickable"
                        size={16}
                        onClick={() => {
                            // Use index to delete complement with slice
                            console.log('SETTING CONTEXT')
                            const newComplements = invoice?.complements?.filter(
                                (complement: any, i: number) => i !== index,
                            )
                            dispatch(
                                setData({
                                    item: 'invoice',
                                    data: {
                                        ...invoice,
                                        complements: newComplements,
                                    },
                                }),
                            )
                        }}
                    />
                </Tooltip>
            </Space>
        </div>
    )
}

export const complementColumns: ColumnsType<ComplementColumn> = [
    {
        title: 'Facturas',
        dataIndex: 'invoices',
        key: 'invoices',
        fixed: 'left',
        render: (invoices: any, record: any) => {
            // const invoicesCount = (record[0]?.related_documents ?? [])?.length
            return (
                <div className="d-flex flex-column">
                    {record[0]?.related_documents?.map((invoice: any, index: number) => {
                        return (
                            <Typography.Text
                                key={index}
                                className="smallparagraph descriptions"
                                onClick={() => console.log(record)}
                            >
                                {invoice?.series}-{invoice.folio_number}
                            </Typography.Text>
                        )
                    })}
                </div>
            )
        },
    },
    {
        title: 'Forma de pago',
        dataIndex: 'payment_form',
        key: 'paymentForm',
        render: (paymentForm: any, record: any) => {
            const pf = paymentForms.find((pf: any) => pf.value === record[0].payment_form)
            return <Typography.Text className="smallparagraph descriptions">{pf?.label}</Typography.Text>
        },
    },
    {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        render: (v: any, record: any) => {
            const total = record[0].related_documents.reduce((acc: number, invoice: any) => acc + invoice.amount, 0)
            return (
                <Typography.Text className="smallparagraph descriptions">{returnCurrencyValue(total)}</Typography.Text>
            )
        },
    },
    {
        title: 'Moneda',
        dataIndex: 'currency',
        key: 'currency',
        render: (currency: any, record: any) => {
            return <Typography.Text className="smallparagraph descriptions">{record[0].currency}</Typography.Text>
        },
    },
    {
        title: 'Tipo de cambio',
        dataIndex: 'exchange',
        key: 'exchange',
        render: (exchange: any, record: any) => {
            return <Typography.Text className="smallparagraph descriptions">{record[0].exchange}</Typography.Text>
        },
    },
    {
        title: 'Fecha',
        dataIndex: 'date',
        key: 'date',
        render: (date: any, record: any) => {
            return (
                <Typography.Text className="smallparagraph descriptions">
                    {dayjs(record[0].date).format('DD/MM/YYYY')}
                </Typography.Text>
            )
        },
    },
    {
        title: 'Acciones',
        dataIndex: 'actions',
        key: 'actions',
        width: 110,
        align: 'center',
        fixed: 'right',
        render: (actions: any, record: any, index: number) => {
            return <ComplementsActionsColumn index={index} />
        },
    },
]
