import { colors } from '../app/designSystem/colors'
import { InvoiceDef, OptimizedInvoiceDef } from '../interfaces/invoicesDef'
import { OptimizedPaymentDef, Payment } from '../interfaces/paymentsDef'
import { OptimizedReceiptDef, ReceiptDef } from '../interfaces/receiptsDef'

// PAYMENTS
export const readablePaymentStatus = (record: Payment | OptimizedPaymentDef) => {
    if (record.refunded) {
        return 'Reembolsado'
    }

    switch (record.status) {
        case 'requires_payment_method':
            return 'En espera'
        case 'pending':
            return 'En espera'
        case 'review_requested':
            return 'Revisión'
        case 'requires_confirmation':
            return 'Por confirmar'
        case 'requires_action':
            return 'Requiere acción'
        case 'processing':
            return 'Procesando...'
        case 'requires_capture':
            return 'capture su pago'
        case 'succeeded':
            return 'Exitoso'
        case 'paid':
            return 'Exitoso'
        case 'canceled':
            return 'Cancelado'
        case 'failed':
            return 'Fallido'
        case 'void':
            return 'Inválido'
        case 'refunded':
            return 'Reembolsado'
        case 'partial_refunded':
            return 'Reembolsado parcialmente'
        case 'partially_paid':
            return 'Pagado parcialmente'
        default:
            return 'En espera'
    }
}

export const paymentStatusColor = (record: Payment | OptimizedPaymentDef) => {
    switch (record.status) {
        case 'review_requested':
            return '#ffd659'
        case 'requires_payment_method':
            return '#F8F6FF'
        case 'requires_confirmation':
            return '#F8F6FF'
        case 'pending':
            return '#F8F6FF'
        case 'requires_action':
            return '#FFECE1'
        case 'processing':
            return '#F8F6FF'
        case 'requires_capture':
            return '#E7F3FA'
        case 'succeeded':
            return record?.livemode ? '#52BD94' : '#D1C5FF'
        case 'paid':
            return '#52BD94'
        case 'canceled':
            return '#FEE9E9'
        case 'failed':
            return '#FEE9E9'
        case 'void':
            return '#D8DAE5'
        case 'partially_paid':
            return '#F8F6FF'
        default:
            return '#D8DAE5'
    }
}

export const paymentStatusColorText = (record: Payment | OptimizedPaymentDef) => {
    if (record.refunded) {
        return colors.yellow
    }

    switch (record.status) {
        case 'requires_action':
            return colors.orange // '#C1C4D6'
        case 'succeeded':
            return colors.green // '#0AB649'
        case 'paid':
            return colors.green // '#0AB649'
        case 'canceled':
            return colors.red // '#F15B5B'
        case 'failed':
            return colors.red // '#F15B5B'
        case 'void':
            return colors.icons_normal // '#8167F6'
        case 'review_requested':
            return colors.purple
        case 'partially_paid':
            return colors.icons_normal
        default:
            return 'var(--neutral-1)'
    }
}

export const paymentStatusIcon = (record: Payment | OptimizedPaymentDef) => {
    switch (record.status) {
    }
}

// INVOICES

export const readableInvoiceStatus = (record: InvoiceDef | OptimizedInvoiceDef) => {
    switch (record.status) {
        case 'valid':
            if (record?.livemode) {
                return 'Válida'
            }
            return 'Válida sin timbrar'
        case 'canceled':
            return 'Cancelada'
    }
}

export const invoiceStatusColor = (record: InvoiceDef | OptimizedInvoiceDef) => {
    switch (record.status) {
        case 'valid':
            if (record?.livemode) {
                return '#0F172A'
            }
            return '#0F172A'
        case 'canceled':
            return '#0F172A'
    }
}

export const invoiceStatusColorText = (record: InvoiceDef | OptimizedInvoiceDef) => {
    switch (record.status) {
        case 'valid':
            if (record?.livemode) {
                return '#0F172A'
            }
            return '#0F172A'
        case 'canceled':
            return '#0F172A'
    }
}

export const receiptStatusColor = (record: ReceiptDef | OptimizedReceiptDef) => {
    switch (record.status) {
        case 'pending':
            if (record?.livemode) {
                return '#0F172A'
            }
            return '#0F172A'
        case 'failed':
            return '#0F172A'
        case 'canceled':
            return '#0F172A'
        default:
            return '#0F172A'
    }
}

export const receiptStatusColorText = (record: ReceiptDef | OptimizedReceiptDef) => {    
    switch (record.status) {
        case 'pending':
            if (record?.livemode) {
                return '#0F172A'
            }
            return '#0F172A'
        case 'completed':
            return '#0F172A'
        case 'processing':
            return '#0F172A'
        case 'canceled':
            return '#0F172A'
        case 'failed':
            return '#0F172A'
    }
}

export const readableReceiptStatus = (record: ReceiptDef | OptimizedReceiptDef) => {
    switch (record.status) {
        case 'pending':
            if (record?.livemode) {
                return 'Pendiente'
            }
            return 'Pendiente'
        case 'failed':
            return 'Fallido'
        case 'canceled':
            return 'Cancelado'
        case 'completed':
            if (record.disallowInvoice) {
                return 'Completado sin timbrar'
            }
            return 'Completado'
        case 'processing':
            return 'Procesando'
        default:
            return 'En espera'
    }
}

export const readableReceiptPeriodicity = (record: ReceiptDef | OptimizedReceiptDef) => {}
export const readableReceiptSource = (record: ReceiptDef | OptimizedReceiptDef) => {
    switch (record.from ?? 'payment') {
        case 'payment':
            return 'Pago'

        default:
            //return only first letter in uppercase
            return `${(record.from ?? '').charAt(0).toUpperCase()}${(record.from ?? '').slice(1)}`
    }
}
