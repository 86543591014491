import { Card, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'

export default function UpgradePlanCard() {
    const navigate = useNavigate()

    return (
        <Card
            title={<Typography className="p-large-bold">Actualiza tu plan</Typography>}
            style={{ width: '400px', margin: 'auto' }}
        >
            <div>
                <Typography className="p-base-regular">
                        Tu plan no tiene acceso a esta funcionalidad. Puedes contratar uno más completo{" "}
                    <span>
                        <Typography
                            className="p-bold-regular"
                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => {
                                navigate('/memberships?show_all=true')
                            }}
                        >
                            aquí
                        </Typography>
                    </span>
                    .
                </Typography>
            </div>
        </Card>
    )
}
