const IconHolder = ({
    icon,
    bgColor = 'white',
    height = '50px',
    width = '50px',
    circular = false,
}: {
    icon: JSX.Element
    bgColor?: string
    width?: string
    height?: string
    circular?: boolean
}) => {
    return (
        <div
            className="d-flex flex-column d-center "
            style={{
                backgroundColor: bgColor,
                width,
                height,
                minHeight: height,
                minWidth: width,
                borderRadius: circular ? '50%' : '10px',
            }}
        >
            {icon}
        </div>
    )
}

export default IconHolder
