import { Button, Col, Divider, Form, Row, Typography } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import Hero from '../../../assets/images/signup-hero.png'
import { CheckBoxInput, PasswordInput, TextInput } from '../../components/Forms/Inputs'
import { useCustomAuth } from '../../customHooks/useCustomAuth'
import { preserveQueryParams } from '../../functions/helpers'
import AuthFormLayout from './AuthFormLayout'
import GoogleSignInButton from './GoogleSignInButton'
import { usePostHog } from 'posthog-js/react'

const SignUp = () => {
    const [form] = Form.useForm()
    const posthog = usePostHog()
    const { loading, signup, googleSignIn } = useCustomAuth()
    const location = useLocation()

    const getLoginLink = () => {
        const currentSearchParams = new URLSearchParams(location.search)
        const signup = preserveQueryParams('/login', currentSearchParams)
        return signup
    }

    const onFinish = (values: any) => {
        try {
            posthog.capture('signed_up_with_email', {
                email: values.email,
                v: 'v2',
            })
        } catch (error) {}
        signup(values)
    }

    return (
        <Row style={{ minHeight: '100vh' }}>
            <Col xs={24} md={12}>
                <AuthFormLayout
                    title="Crea una cuenta"
                    subtitle="Regístrate fácil y rápido"
                    footerText="¿Ya tienes cuenta?"
                    footerLinkText="Inicia Sesión"
                    footerLinkTo={getLoginLink()}
                >
                    <Form
                        form={form}
                        name="register"
                        onFinish={onFinish}
                        layout="vertical"
                        style={{ marginTop: '20px' }}
                    >
                        <TextInput
                            name="fullName"
                            label="Nombre completo"
                            placeholder="Escribe tu nombre completo"
                            rules={[{ required: true, message: 'Por favor ingresa tu nombre completo' }]}
                        />

                        <TextInput
                            name="email"
                            label="Correo electrónico"
                            placeholder="Escribe tu correo electrónico empresarial"
                            rules={[
                                { required: true, message: 'Por favor ingresa tu correo electrónico' },
                                { type: 'email', message: 'Por favor ingresa un correo electrónico válido' },
                            ]}
                        />

                        <PasswordInput
                            label="Contraseña"
                            name="password"
                            placeholder="Escribe tu contraseña"
                            rules={[
                                { required: true, message: 'Por favor ingresa tu contraseña' },
                                { min: 8, message: 'La contraseña debe tener al menos 8 caracteres' },
                            ]}
                        />

                        <CheckBoxInput
                            rules={[
                                {
                                    validator: (_: any, value: boolean) =>
                                        value
                                            ? Promise.resolve()
                                            : Promise.reject(new Error('Por favor acepta los términos y condiciones')),
                                },
                            ]}
                            name="terms-privacy"
                            content={
                                <Typography.Paragraph className="p-small-regular neutral-1" style={{ margin: 0 }}>
                                    Acepto y reconozco que he leído los &nbsp;
                                    <Link
                                        to="https://pro-gigstack.s3.us-east-2.amazonaws.com/legal/terms.pdf"
                                        target="_blank"
                                    >
                                        <span className="p-small-bold primary-1">términos y condiciones</span>
                                    </Link>
                                    &nbsp; y el &nbsp;
                                    <Link
                                        to="https://pro-gigstack.s3.us-east-2.amazonaws.com/legal/privacy.pdf"
                                        target="_blank"
                                    >
                                        <span className="p-small-bold primary-1">aviso de privacidad</span>
                                    </Link>
                                </Typography.Paragraph>
                            }
                        />

                        <Button
                            className="btn-primary"
                            htmlType="submit"
                            block
                            loading={loading.includes('email')}
                            disabled={loading.length !== 0}
                            onClick={() => form.submit()}
                        >
                            Regístrate
                        </Button>
                    </Form>

                    <Divider plain className="neutral-4">
                        <Typography.Text className="p-xsmall-regular neutral-3">o régistrate con</Typography.Text>
                    </Divider>
                    <GoogleSignInButton
                        onClick={() => {
                            if (form.getFieldValue('terms-privacy')) {
                                try {
                                    posthog.capture('signed_up_with_google', {
                                        v: 'v2',
                                    })
                                } catch (error) {}
                                googleSignIn()
                            } else {
                                form.setFields([
                                    { name: 'terms-privacy', errors: ['Por favor acepta los términos y condiciones'] },
                                ])
                            }
                        }}
                        loading={loading.includes('google')}
                    />
                </AuthFormLayout>
            </Col>
            <Col
                xs={0}
                md={12}
                style={{
                    background: 'linear-gradient(221.51deg, #000000 0.75%, #044737 69.64%)',
                }}
            >
                <img
                    src={Hero}
                    alt="Hero"
                    draggable={false}
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        mixBlendMode: 'soft-light',
                    }}
                />
            </Col>
        </Row>
    )
}

export default SignUp
