import { Space, Tooltip, Typography } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useDispatch, useSelector } from 'react-redux'
import { setData } from '../../../context/dataSlice'
import { returnCurrencyValue } from '../../../functions/helpers'
import { Pencil, Trash } from '@phosphor-icons/react'
import { openModal } from '../../../context/modalsSlice'

interface RelatedDocumentColumn {
    uuid: string
    amount: number
    installment: number
    last_balance: number
    currency: string
    exchange: number
    taxes: any[]
    actions: any
}

const DeleteRelatedDocument = (uuid: any) => {
    const dispatch = useDispatch()
    const { invoiceComplement } = useSelector((state: any) => state.data)
    return (
        <div>
            <Tooltip title="Eliminar documento relacionado">
                <Trash
                    weight="regular"
                    className="clickable"
                    size={16}
                    onClick={() => {
                        dispatch(
                            setData({
                                item: 'invoiceComplement',
                                data: {
                                    ...invoiceComplement,
                                    related_documents: (invoiceComplement?.related_documents ?? [])?.filter(
                                        (doc: any) => doc.uuid !== uuid.uuid,
                                    ),
                                },
                            }),
                        )
                    }}
                />
            </Tooltip>
        </div>
    )
}

const EditRelatedDocument = (record: any) => {
    const dispatch = useDispatch()
    return (
        <Tooltip title="Editar documento relacionado">
            <Pencil
                weight="regular"
                className="clickable"
                size={16}
                onClick={() => {
                    dispatch(openModal('paymentComplementRelationsModalVisible'))
                    dispatch(
                        setData({
                            item: 'selectedRelatedDocument',
                            data: record.uuid,
                        }),
                    )
                }}
            />
        </Tooltip>
    )
}

export const relatedDocumentsColumns: ColumnsType<RelatedDocumentColumn> = [
    {
        title: 'UUID',
        dataIndex: 'uuid',
        key: 'uuid',
        fixed: 'left',
        render: (uuid: any) => {
            return (
                <Typography.Text className="smallparagraph descriptions primary" copyable>
                    {uuid}
                </Typography.Text>
            )
        },
    },
    {
        title: 'Monto',
        dataIndex: 'amount',
        key: 'amount',
        render: (amount: any, record: any) => {
            return (
                <Typography.Text className="smallparagraph descriptions" ellipsis>
                    {' '}
                    {returnCurrencyValue(amount)} {record?.currency}
                </Typography.Text>
            )
        },
    },
    {
        title: 'Parcialidad',
        dataIndex: 'installment',
        key: 'installment',
        render: (installment: any) => {
            return (
                <Typography.Text className="smallparagraph descriptions" ellipsis>
                    {installment}
                </Typography.Text>
            )
        },
    },
    {
        title: 'Último balance',
        dataIndex: 'last_balance',
        key: 'last_balance',
        render: (last_balance: any, record: any) => {
            return (
                <Typography.Text className="smallparagraph descriptions" ellipsis>
                    {returnCurrencyValue(last_balance)} {record?.currency}
                </Typography.Text>
            )
        },
    },
    {
        title: 'Tipo de cambio',
        dataIndex: 'exchange',
        key: 'exchange',
        render: (exchange: any) => {
            return (
                <Typography.Text className="smallparagraph descriptions" ellipsis>
                    {exchange}
                </Typography.Text>
            )
        },
    },
    {
        title: 'Impuestos',
        dataIndex: 'taxes',
        key: 'taxes',
        render: (taxes: any) => {
            return (
                <div>
                    {taxes.map((tax: any) => {
                        return (
                            <div>
                                <Typography.Text className="smallparagraph descriptions" ellipsis>
                                    {tax.type} - {tax.rate} (Base: {returnCurrencyValue(tax.base)})
                                </Typography.Text>
                            </div>
                        )
                    })}
                </div>
            )
        },
    },
    {
        title: 'Acciones',
        dataIndex: 'actions',
        key: 'actions',
        width: 110,
        align: 'center',
        fixed: 'right',
        render: (actions: any, record: any) => {
            return (
                <Space direction="horizontal">
                    <EditRelatedDocument uuid={record} />
                    <DeleteRelatedDocument uuid={record?.uuid} />
                </Space>
            )
        },
    },
]
