import React, { useEffect, useState } from 'react'
import { SignedInternalAPIRequest } from '../../functions/APIRequest'
import { useDispatch, useSelector } from 'react-redux'
import { setTeam } from '../../context/teamsSlice'
import { useAuth } from 'reactfire'
import { Button, Col, Popconfirm, Row, Select, Table, Tag, Typography, message } from 'antd'
import { MetroSpinner } from 'react-spinners-kit'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { TrashSimple } from '@phosphor-icons/react'
import {
    generateCode,
    returnColorByUserRole,
    returnTextColorByUserRole,
    userRoleMessage,
} from '../../functions/helpers'
import { usePermissions } from '../../customHooks/usePermissions'

const SeatsTeamList: React.FC = () => {
    const [messageApi, contextHolder] = message.useMessage()
    const dispatch = useDispatch()
    const permission = usePermissions()

    const auth = useAuth()
    const { team } = useSelector((state: any) => state.team)
    const { user } = useSelector((state: any) => state.user)
    const [loading, setLoading] = useState<any[]>([])
    const [teamMembersState, setTeamMembersState] = useState({
        loaded: false,
        members: [] as any[],
        teamId: team?.id,
        failed: false,
        error: null,
    })

    const HandleDeleteMember = async (member: any) => {
        try {
            setLoading([...loading, member.id])

            await updateDoc(doc(getFirestore(), `teams/${team.id}`), {
                members: team.members.filter((m: any) => m.id !== member.id),
                membersIds: team.membersIds.filter((m: any) => m !== member.id),
            })
            setLoading(loading.filter((l: any) => l !== member.id))
            getMembers()
            dispatchMembers()
        } catch (error) {}
    }

    const getMembers = async () => {
        try {
            if (!team?.id) return

            setTeamMembersState({
                loaded: false,
                members: [],
                teamId: team?.id,
                failed: false,
                error: null,
            })
            const rawMembers = await SignedInternalAPIRequest(
                {},
                `handleSeats/v1/teams/${team.id}/members`,
                auth.currentUser,
                {},
                'GET',
            )

            setTeamMembersState({
                loaded: true,
                members: rawMembers,
                teamId: team.id,
                failed: false,
                error: null,
            })
        } catch (error: any) {
            setTeamMembersState({
                loaded: true,
                members: [],
                teamId: team.id,
                failed: true,
                error: error?.message ?? '',
            })
        }
    }

    const dispatchMembers = () => {
        dispatch(
            setTeam({
                ...team,
                rawMembers: teamMembersState.members,
            }),
        )
    }

    const RolSelect = (text: string, record: any, column: any) => {
        let firstOptionLabel = {}

        switch (text) {
            case 'editor':
                firstOptionLabel = {
                    label: 'Editor',
                    value: 'editor',
                }
                break
            case 'viewer':
                firstOptionLabel = {
                    label: 'Visor',
                    value: 'viewer',
                }
                break
            case 'blocked':
                firstOptionLabel = {
                    label: 'Bloqueado',
                    value: 'blocked',
                }
                break
            default:
                firstOptionLabel = {
                    label: '',
                    value: '',
                }
                break
        }

        return (
            <Select
                style={{ minWidth: '100px' }}
                disabled={record.id === user?.uid}
                className="pro-select-input"
                options={[
                    { label: 'Editor', value: 'editor' },
                    { label: 'Visor', value: 'viewer' },
                    { label: 'Bloqueado', value: 'blocked' },
                ]}
                defaultValue={firstOptionLabel}
                onChange={async (val: any) => {
                    try {
                        const members = teamMembersState?.members
                        const memberIndex = members.findIndex((member: any) => member.id === record.id)

                        if (!memberIndex) return

                        if (column === 'payments') {
                            members[memberIndex].payments = val
                        } else if (column === 'services') {
                            members[memberIndex].services = val
                        } else if (column === 'invoices') {
                            members[memberIndex].invoices = val
                        }

                        await updateDoc(doc(getFirestore(), `teams/${team.id}`), {
                            members: members,
                        })

                        setTeamMembersState((oldState) => {
                            return {
                                ...oldState,
                                members: members,
                            }
                        })
                        // Wait 2 seconds show the message
                        messageApi.open({
                            type: 'success',
                            content: 'Se cambiaron los permisos del usuario',
                        })
                    } catch (error: any) {
                        console.log('*** Error', error)
                        messageApi.open({
                            type: 'error',
                            content: error.message,
                        })
                    }
                }}
            />
        )
    }

    useEffect(() => {
        getMembers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns: any = [
        {
            title: 'Usuario',
            dataIndex: 'email',
            key: generateCode(5),
            fixed: 'left',
            width: '20%',
            sorter: (a: any, b: any) => (a?.email).localeCompare(b?.email),
            render: (text: string, record: any) => {
                return (
                    <div className="d-flex flex-column">
                        <Typography.Text className="primary">
                            {record?.firstName ?? ''} {record?.lastName ?? ''}
                        </Typography.Text>
                        <Typography.Text className="secondary" type="secondary">
                            {text}
                        </Typography.Text>
                    </div>
                )
            },
        },
        {
            title: 'Rol',
            dataIndex: 'rol',
            key: generateCode(5),
            width: '10%',
            sorter: (a: any, b: any) => (a?.rol).localeCompare(b?.rol),
            render: (text: string) => {
                return (
                    <div>
                        {' '}
                        <Tag
                            className="statusTag"
                            color={returnColorByUserRole(text)}
                            style={{
                                color: returnTextColorByUserRole(text),
                                borderRadius: '5px',
                                opacity: 0.7,
                            }}
                        >
                            {userRoleMessage(text)}
                        </Tag>
                    </div>
                )
            },
        },
        {
            title: 'Pagos',
            dataIndex: 'payments',
            key: generateCode(5),
            width: '12%',
            render: (text: string, record: any) => {
                return RolSelect(text, record, 'payments')
            },
        },
        {
            title: 'Facturación',
            dataIndex: 'invoices',
            key: generateCode(5),
            width: '12%',
            render: (text: string, record: any) => {
                return RolSelect(text, record, 'invoices')
            },
        },
        {
            title: 'Servicios',
            dataIndex: 'services',
            key: generateCode(5),
            width: '12%',
            render: (text: string, record: any) => {
                return RolSelect(text, record, 'services')
            },
        },
        {
            title: '',
            dataIndex: 'actions',
            key: generateCode(5),
            width: '5%',
            render: (text: string, record: any) => {
                const deleteShouldShow = record.id !== user.uid && (permission.isBillingAccountAdmin || !record.isAdmin)

                if (!deleteShouldShow) return null

                return (
                    <Popconfirm
                        title="¿Estás seguro de eliminar este miembro?"
                        onConfirm={() => HandleDeleteMember(record)}
                        okText="Sí"
                        cancelText="No"
                    >
                        <Button type="link" style={{ color: '#FF4D4F' }} icon={<TrashSimple size={15} />} />
                    </Popconfirm>
                )
            },
        },
    ]

    return (
        <Row>
            {contextHolder}
            <Col xs={24}>
                <label>Miembros del equipo</label>
                <div style={{ marginTop: '20px' }}>
                    {teamMembersState.loaded ? (
                        <Table
                            dataSource={teamMembersState?.members}
                            columns={columns}
                            pagination={false}
                            scroll={{ x: 1000 }}
                        />
                    ) : (
                        <MetroSpinner size={30} color="#686769" loading={true} />
                    )}
                </div>
            </Col>
        </Row>
    )
}

export default SeatsTeamList
