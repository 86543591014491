import {
    Cardholder,
    CaretDoubleRight,
    Checks,
    CurrencyDollar,
    MinusCircle,
    Money,
    Plus,
    SelectionSlash,
    SlidersHorizontal,
    Trash,
    X,
} from '@phosphor-icons/react'
import { Button, Col, Dropdown, Modal, Row, Space, Tooltip, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { GetServices } from '../context/databaseContextHelpers';
import ServicesTable from '../components/Tables/ServiceTable'
import TablesContainer from '../components/Tables/TablesContainer'
import { deleteData, setData } from '../context/dataSlice'
import { deleteDoc, doc, getFirestore } from 'firebase/firestore'
import { InternalItem } from '../../interfaces/internalItemDef'
import { RingSpinner } from 'react-spinners-kit'
import { ClientDef } from '../../interfaces/clientDef'
import { returnCurrencyValue } from '../functions/helpers'
import { openModal } from '../context/modalsSlice'
import { usePermissions } from '../customHooks/usePermissions'

const ConfirmDeleteModal = ({
    visible,
    onConfirm,
    onCancel,
}: {
    visible: boolean
    onConfirm: Function
    onCancel: Function
}) => {
    const [deleted, setDeleted] = useState<string[]>([])
    const [loading, setLoading] = useState<string[]>([])

    const { selectedServices } = useSelector((state: any) => state.data)
    const dispatch = useDispatch()

    const ConfirmDelete = async () => {
        setDeleted([])
        setLoading([...loading, 'deleteAll'])
        // wait 500ms
        var alreadyDelt: string[] = []
        await new Promise((r) => setTimeout(r, 100))
        for (const client of selectedServices) {
            await new Promise((r) => setTimeout(r, 100))
            try {
                try {
                    await deleteDoc(doc(getFirestore(), 'services', client.id))
                    if (alreadyDelt.includes(client.id)) continue
                    alreadyDelt.push(client.id)
                    setDeleted(alreadyDelt)
                    dispatch(deleteData({ item: 'services', id: client.id }))
                } catch (error) {}
            } catch (error) {}

            await new Promise((r) => setTimeout(r, 100))
        }

        setLoading(loading.filter((l) => l !== 'deleteAll'))
    }

    useEffect(() => {}, [deleted])

    return (
        <Modal
            open={visible}
            closeIcon={<></>}
            closable={false}
            style={{ top: 5 }}
            title={
                <div style={{ position: 'relative', paddingBottom: '15px' }}>
                    <Typography.Text
                        className="smallheader"
                        style={{
                            position: 'absolute',
                            //center the text
                            left: '50%',
                            transform: 'translateX(-50%)',
                            paddingTop: '5px',
                        }}
                    ></Typography.Text>
                    <Row>
                        <Col xs={24} style={{ margin: 'auto' }}>
                            <Row justify="space-between" align="middle">
                                <X className="icon clickable" size={20} onClick={() => onCancel()} />
                                <></>
                                <Button
                                    danger
                                    disabled={
                                        loading.includes('deleteAll') || deleted.length === selectedServices?.length
                                    }
                                    onClick={(e) => {
                                        ConfirmDelete()
                                    }}
                                >
                                    Eliminar
                                    <CaretDoubleRight
                                        size={15}
                                        style={{ marginLeft: '5px' }}
                                        className=""
                                        weight="regular"
                                    />
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </div>
            }
            footer={null}
        >
            <div className="d-flex flex-column">
                <Typography.Text className="mediumheader" style={{ marginBottom: '5px' }}>
                    ¿Deseas eliminar {selectedServices?.length} servicio{selectedServices?.length > 1 ? 's' : ''}{' '}
                    seleccionado{selectedServices?.length > 1 ? 's' : ''}?
                </Typography.Text>
                <Typography.Text className="smallparagraph descriptions">
                    Esta acción es irreversible y no se podrán recuperar los servicios eliminados, sin embargo, todos
                    los pagos, facturas, recibos, etc. que fueron registrados con alguno de ellos, seguirán funcionando.
                </Typography.Text>
                <ul
                    style={{
                        listStyle: 'none',
                        margin: 0,
                        padding: 0,
                        marginTop: '15px',
                    }}
                >
                    {selectedServices?.map((c: InternalItem) => {
                        return (
                            <li key={c.id}>
                                <Row
                                    style={{
                                        width: '100%',
                                        padding: '10px 15px',
                                        backgroundColor: '#FAFBFF',
                                        borderRadius: '10px',
                                        marginBottom: '5px',
                                    }}
                                    justify="space-between"
                                    align="middle"
                                >
                                    <div className="d-flex flex-column">
                                        <Typography.Text className="smallparagraphbold" style={{ marginBottom: '5px' }}>
                                            {c.name || 'Sin nombre'}
                                        </Typography.Text>
                                        <Typography.Text className="smallparagraph">
                                            {returnCurrencyValue(c.total)} ({c.quantity}x)
                                        </Typography.Text>
                                        <Typography.Text className="smallparagraph" style={{ marginTop: '5px' }}>
                                            <SlidersHorizontal
                                                className="icon"
                                                style={{ marginRight: '5px' }}
                                                size={14}
                                            />
                                            {c.product_key || 'Sin código de producto'} - {c.unit_name} (
                                            {c.unit_key || ''}){' '}
                                        </Typography.Text>
                                        {c.taxes.map((t: any) => {
                                            return (
                                                <Typography.Text
                                                    className="smallparagraph"
                                                    style={{ marginTop: '5px' }}
                                                >
                                                    <SlidersHorizontal
                                                        size={14}
                                                        className="icon"
                                                        style={{ marginRight: '5px' }}
                                                    />
                                                    {t.type} {Number(t.rate) * 1} {t.inclusive ? '(Inc.)' : ''}{' '}
                                                    {t.withholding ? '(Ret.)' : ''}
                                                </Typography.Text>
                                            )
                                        })}
                                    </div>
                                    {deleted.includes(c.id) ? (
                                        <Tooltip title="Eliminado de forma correcta">
                                            <Checks color="#52BD94" size={20} weight="regular" />
                                        </Tooltip>
                                    ) : loading.includes('deleteAll') ? (
                                        <RingSpinner color="#FFB020" size={20} />
                                    ) : (
                                        <Tooltip title="Remover selección">
                                            <MinusCircle
                                                size={20}
                                                weight="regular"
                                                className="icon clickable"
                                                onClick={() => {
                                                    const newselectedServices = selectedServices?.filter(
                                                        (sc: ClientDef) => sc.id !== c.id,
                                                    )
                                                    dispatch(
                                                        setData({
                                                            item: 'selectedServices',
                                                            data: newselectedServices,
                                                        }),
                                                    )
                                                    if (newselectedServices?.length === 0) onCancel()
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                </Row>
                            </li>
                        )
                    })}
                </ul>
                <Row justify="center" style={{ marginTop: '10px' }}>
                    {deleted.length === selectedServices?.length && (
                        <div className="d-flex flex-column">
                            <Typography.Text className="smallparagraph">
                                Se han eliminado los productos seleccionados de forma correcta
                            </Typography.Text>
                            <Button
                                style={{ marginTop: '10px' }}
                                onClick={() => {
                                    setDeleted([])
                                    dispatch(setData({ item: 'selectedServices', data: [] }))
                                    onCancel()
                                }}
                            >
                                Cerrar
                            </Button>
                        </div>
                    )}
                </Row>
            </div>
        </Modal>
    )
}

const ProductsPage: React.FC = () => {
    const dispatch = useDispatch()
    const { canCreateService } = usePermissions()
    // const { team } = useSelector((state: any) => state.team)
    const { /*services,*/ selectedServices } = useSelector((state: any) => state.data)

    const [loading, setLoading] = useState<string[]>([])

    useEffect(() => {
        // if (services.length === 0) {
        //     GetServices(dispatch, team.id)
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <ConfirmDeleteModal
                visible={loading.includes('deleteAll')}
                onCancel={() => {
                    setLoading(loading.filter((l) => l !== 'deleteAll'))
                }}
                onConfirm={() => {
                    const keysToDelete = selectedServices?.map((c: any) => c.id)
                    console.log(keysToDelete)
                    setLoading(loading.filter((l) => l !== 'deleteAll'))
                }}
            />
            <Typography.Text className="h2-bold" style={{ marginBottom: '15px' }}>
                Productos / Servicios
            </Typography.Text>
            {/* <Typography.Title level={4} className='mediumheader'><Barcode style={{ width: '18px', height: '18px', marginRight: '6px' }} />Productos / Servicios</Typography.Title> */}

            <TablesContainer
                children={
                    <div className="d-flex flex-column">
                        <ServicesTable max={100} />
                    </div>
                }
                title={'Productos / Servicios'}
                description={'Productos / Servicios'}
                actions={
                    <Row justify="end"className='w-100'>
                        <Space>
                            {(selectedServices?.length ?? 0) > 0 && (
                                <Dropdown
                                    disabled={loading.includes('deleteAll')}
                                    menu={{
                                        items: [
                                            {
                                                onClick: async () => {
                                                    setLoading([...loading, 'deleteAll'])
                                                },
                                                label: (
                                                    <Typography.Text className="smallparagaph ">
                                                        <Trash
                                                            weight="regular"
                                                            size={15}
                                                            style={{ marginRight: '5px' }}
                                                        />{' '}
                                                        Eliminar seleccionados
                                                    </Typography.Text>
                                                ),
                                                key: 'deleteAll',
                                            },
                                            {
                                                onClick: async () => {
                                                    dispatch(setData({ item: 'selectedServices', data: [] }))
                                                },
                                                label: (
                                                    <Typography.Text className="smallparagaph ">
                                                        <SelectionSlash
                                                            weight="regular"
                                                            size={15}
                                                            style={{ marginRight: '5px' }}
                                                        />{' '}
                                                        Deshacer selección
                                                    </Typography.Text>
                                                ),
                                                key: 'unselect',
                                            },
                                            {
                                                onClick: async () => {
                                                    dispatch(openModal('paymentModalVisible'))
                                                },
                                                label: (
                                                    <Typography.Text className="smallparagaph ">
                                                        <Money
                                                            weight="regular"
                                                            size={15}
                                                            style={{ marginRight: '5px' }}
                                                        />{' '}
                                                        Solicitar pago
                                                    </Typography.Text>
                                                ),
                                                key: 'createPayment',
                                            },
                                            {
                                                onClick: async () => {
                                                    dispatch(openModal('invoiceModalVisible'))
                                                },
                                                label: (
                                                    <Typography.Text className="smallparagaph ">
                                                        <CurrencyDollar
                                                            weight="regular"
                                                            size={15}
                                                            style={{ marginRight: '5px' }}
                                                        />
                                                        Crear factura
                                                    </Typography.Text>
                                                ),
                                                key: 'createInvoice',
                                            },
                                            {
                                                onClick: async () => {
                                                    dispatch(openModal('receiptModalVisible'))
                                                },
                                                label: (
                                                    <Typography.Text className="smallparagaph ">
                                                        <Cardholder
                                                            weight="regular"
                                                            size={15}
                                                            style={{ marginRight: '5px' }}
                                                        />
                                                        Crear recibo
                                                    </Typography.Text>
                                                ),
                                                key: 'createReceipt',
                                            },

                                            //
                                        ],
                                    }}
                                    trigger={['click']}
                                >
                                    <Button style={{ marginLeft: '10px' }}>Opciones masivas</Button>
                                </Dropdown>
                            )}
                            <Button
                                disabled={!canCreateService}
                                type="primary"
                                icon={<Plus weight="regular" size={15} />}
                                onClick={() => {
                                    dispatch(
                                        setData({
                                            item: 'service',
                                            data: null,
                                        }),
                                    )
                                    dispatch(
                                        setData({
                                            item: 'taxes',
                                            data: [],
                                        }),
                                    )
                                    dispatch(openModal('createServiceVisible'))
                                }}
                            >
                                Crear nuevo
                            </Button>
                        </Space>
                    </Row>
                }
            />
        </>
    )
}
export default ProductsPage
