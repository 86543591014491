import { Button, message, Modal, Row } from 'antd'
import { arrayUnion, doc, getFirestore, updateDoc } from 'firebase/firestore'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
// import moment from 'moment';
// import { InvoiceStatusTag } from '../components/UI/ElementStatus';
// import { getItemsAmounts } from '../functions/helpers';
import { setData } from '../context/dataSlice'

const AddRelatedInvoice = () => {
    const dispatch = useDispatch()

    const { relations } = useSelector((state: any) => state.data)
    const { relatedInvoicesModalVisible } = useSelector((state: any) => state.modals)
    // const { team } = useSelector((state: any) => state.team);

    const [loading, setLoading] = useState(false)
    const [selectedInvoices, setSelectedInvoices] = useState<any[]>([])

    // const q = query(
    //     collection(getFirestore(), 'invoices'),
    //     where('team', '==', team.id),
    //     orderBy('timestamp', 'desc')
    // );

    // const { /*status,*/ data } = useFirestoreCollectionData(q, {
    //     idField: 'id'
    // });

    // const columns = [

    //     {
    //         title: 'Cliente',
    //         dataIndex: (['client', 'name'] || ['client', 'legal_name']),
    //         key: 'clientName'
    //     },
    //     {
    //         title: 'Estado',
    //         dataIndex: 'status',
    //         key: 'status',
    //         render: (status: any) => (<InvoiceStatusTag record={status} shape="circle" />)
    //     },
    //     {
    //         title: 'Total',
    //         dataIndex: 'internalItems',
    //         key: 'amount',
    //         render: (internalItems: any) => getItemsAmounts(internalItems).totalString
    //     },
    //     {
    //         title: 'Date',
    //         dataIndex: 'timestamp',
    //         key: 'timestamp',
    //         render: (timestamp: any) => moment(timestamp).format('YYYY-MM-DD')
    //     },

    // ];

    // const rowSelection: any = {
    //     onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
    //         setSelectedInvoices(selectedRows)
    //     },
    //     getCheckboxProps: (record: any) => ({
    //         disabled: !record.id, // Column configuration not to be checked
    //         name: record.id,
    //     }),
    // };

    return (
        <Modal
            open={relatedInvoicesModalVisible}
            onCancel={() => {
                dispatch(
                    setData({
                        item: 'relations',
                        data: {},
                    }),
                )
                dispatch(closeModal('relatedInvoicesModalVisible'))
                setSelectedInvoices([])
            }}
            footer={null}
            title="Asociar una factura"
            width="60%"
        >
            {/* <Table
                style={{ marginTop: '20px' }}
                rowKey={(rec: any) => rec?.id ?? 'as'}
                scroll={{ x: 1200 }}
                bordered
                rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: [...selectedInvoices?.map((c: any) => c.id)],
                    ...rowSelection
                }}

                dataSource={[]} columns={columns} /> */}
            <Row></Row>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                <Button
                    disabled={selectedInvoices.length === 0 || loading}
                    loading={loading}
                    type="primary"
                    onClick={async () => {
                        setLoading(true)
                        try {
                            const ids = selectedInvoices.map((i: any) => i.id)
                            await updateDoc(doc(getFirestore(), relations.relatedTo, relations.element), {
                                invoices: arrayUnion(...ids),
                            })
                            message.success('Se han asociado los pagos.')
                            dispatch(closeModal('relatedInvoicesModalVisible'))
                            dispatch(
                                setData({
                                    item: 'relations',
                                    data: {},
                                }),
                            )
                            setLoading(false)
                            setSelectedInvoices([])
                        } catch (error: any) {
                            message.error(error.message)
                            dispatch(
                                setData({
                                    item: 'relations',
                                    data: {},
                                }),
                            )
                            dispatch(closeModal('relatedInvoicesModalVisible'))
                            setLoading(false)
                            setSelectedInvoices([])
                        }
                    }}
                >
                    Relacionar
                </Button>
            </div>
        </Modal>
    )
}

export default AddRelatedInvoice
