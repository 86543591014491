import { Alert, Col, Form, Row, Typography, message } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import { FormInstance } from 'antd/lib/form'
import { getAuth, updateEmail, updateProfile } from 'firebase/auth'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { TextInput } from '../../../components/Forms/Inputs'
import { setStepLoading } from '../../../context/onboardingSlice'
import { setUser } from '../../../context/userSlice'
import StepLayout from '../StepsLayout'

interface PersonalInformationStepProps {
    form: FormInstance
    onStepCompleted: () => void
    renderButtons: () => React.ReactNode
}

const PersonalInformationStep = ({ form, onStepCompleted, renderButtons }: PersonalInformationStepProps) => {
    const auth = getAuth()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [error, setError] = useState<string | null>(null)

    const { user } = useSelector((state: any) => state.user)
    const fullName = useWatch('fullName', form)

    useEffect(() => {
        const loadUserData = async () => {
            try {
                dispatch(setStepLoading(true))
                if (user) {
                    const fullName = user?.name || [user.firstName, user.lastName].filter(Boolean).join(' ')
                    form.setFieldsValue({
                        email: auth?.currentUser?.email,
                        fullName,
                        country: user.country,
                    })
                }
            } catch (err) {
                console.error('Error loading user data:', err)
                setError('No se pudo cargar la información del usuario. Por favor, intenta de nuevo.')
            } finally {
                dispatch(setStepLoading(false))
            }
        }

        loadUserData()
    }, [auth?.currentUser?.email, dispatch, form, user])

    const separateFullName = (fullName: string): { firstName: string; lastName: string } => {
        const nameParts = fullName.trim().split(/\s+/)
        if (nameParts.length === 1) return { firstName: nameParts[0], lastName: '' }
        const firstName = nameParts[0]
        const lastName = nameParts.slice(1).join(' ')
        return { firstName, lastName }
    }

    const handleFinish = async (values: any) => {
        try {
            if (auth.currentUser) {
                const { firstName, lastName } = separateFullName(values.fullName)
                try {
                    if (!user.displayName) {
                        await updateProfile(auth.currentUser, {
                            displayName: values.fullName,
                        })
                    }
                    await updateEmail(auth.currentUser, values.email)
                } catch (error) {
                    console.log('🚀 ~ handleFinish ~ error:', error)
                }
                const toupdate = {
                    firstName,
                    lastName,
                    name: values?.fullName,
                    country: 'MEX',
                    email: values?.email,
                    uid: auth.currentUser?.uid,
                    timestamp: moment().valueOf(),
                }
                await updateDoc(doc(getFirestore(), 'users', auth.currentUser?.uid), toupdate)

                dispatch(setUser({ ...toupdate, ...values }))
            }

            navigate('/onboarding/plan')

            onStepCompleted()
        } catch (error: any) {
            console.error('Error submitting form:', error)
            setError(error.message ?? 'Hubo un problema al guardar tus datos. Por favor, intenta de nuevo.')
            message.error(error.message ?? 'Hubo un problema al guardar tus datos. Por favor, intenta de nuevo.')
        }
    }

    const InformationForm = (
        <>
            <Row justify="center" gutter={[0, 0]} style={{ marginBottom: '24px' }}>
                <Col span={24}>
                    <Typography className="h3-bold" style={{ margin: 0 }}>
                        Información personal
                    </Typography>
                </Col>
            </Row>
            {error && (
                <Alert
                    message="Error"
                    description={error}
                    type="error"
                    showIcon
                    closable
                    onClose={() => setError(null)}
                    style={{ marginBottom: '24px' }}
                />
            )}
            <Form
                form={form}
                layout="vertical"
                onFinish={handleFinish}
                style={{ width: '100%' }}
                onFinishFailed={(errorInfo) => {
                    console.log('Form validation failed:', errorInfo)
                    message.error('Por favor, corrige los errores en el formulario antes de continuar.')
                }}
            >
                <TextInput
                    name="fullName"
                    label="Nombre completo"
                    placeholder="Escribe tu nombre completo"
                    rules={[
                        { required: true, message: 'Por favor ingresa tu nombre completo' },
                        { min: 2, message: 'El nombre debe tener al menos 2 caracteres' },
                        { max: 100, message: 'El nombre no puede exceder los 100 caracteres' },
                    ]}
                />
                <TextInput
                    name="email"
                    label="Correo electrónico"
                    placeholder="Escribe tu correo electrónico empresarial"
                    rules={[
                        { required: true, message: 'Por favor ingresa tu correo electrónico' },
                        { type: 'email', message: 'Por favor ingresa un correo electrónico válido' },
                        { max: 255, message: 'El correo electrónico no puede exceder los 255 caracteres' },
                    ]}
                />
                {renderButtons()}
            </Form>
        </>
    )

    const SupporElement = (
        <div style={{ height: '100%', padding: '0px 20px' }}>
            <div style={{ padding: '30px', borderRadius: '10px' }} className="d-flex flex-column">
                <Typography.Text className="h4-bold neutral-1" style={{ padding: 0, marginTop: '40px' }}>
                    Hola {fullName} 🚀
                </Typography.Text>
                <Typography.Text className="h6-regular neutral-2 fade-in-animation" style={{ marginTop: '15px' }}>
                    Nos encanta tenerte aquí en gigstack,
                    <br /> comencemos con tu información personal.
                </Typography.Text>
            </div>
        </div>
    )

    return <StepLayout form={InformationForm} supportElement={SupporElement} />
}

export default PersonalInformationStep
