import { Col, Divider, Form, FormInstance, Modal, Radio, Row, Space, Tag, Typography, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
import { setData } from '../context/dataSlice'
import { colors } from '../designSystem/colors'
import { SignedInternalAPIRequest } from '../functions/APIRequest'
import { useState } from 'react'
import { useAuth } from 'reactfire'
import { SelectInput, TextInput } from '../components/Forms/Inputs'
import { taxRegimes } from '../functions/helpers'
import { ClientDef } from '../../interfaces/clientDef'
import { countryLabeledListWithCodeAlpha3 } from '../datasets/Countries'

export const ClientsToMergeForm = ({
    form,
    loading,
    setLoading,
    onSuccess,
}: {
    form: FormInstance
    loading: boolean
    setLoading: (v: boolean) => void
    onSuccess: (v: ClientDef[]) => void
}) => {
    const dispatch = useDispatch()
    const auth = useAuth()

    const { clientsToMerge } = useSelector((state: any) => state.data)

    const mergeClientsData = (id: string) => {
        const client = clientsToMerge.find((c: any) => c.id === id)
        console.log('Clients to merge: ', clientsToMerge)
        const newData = {
            name: client?.name,
            legalName: client?.legal_name,
            taxSystem: client?.tax_system || client?.taxSystem,
            rfc: client?.rfc || client?.tax_id,
            zip: client?.zip || client?.address?.zip,
            country: client?.country || client?.address?.country || 'MEX',
        }

        for (const c of clientsToMerge) {
            if (!newData.name && c.name) newData.name = c.name
            if (!newData.legalName && c.legal_name) newData.legalName = c.legal_name
            if (!newData.taxSystem && c.tax_system) newData.taxSystem = c.tax_system
            if (!newData.rfc && c.rfc) newData.rfc = c.rfc
            if (!newData.zip && (c.zip || c?.address?.zip)) newData.zip = c.zip || c?.address?.zip
            if (!newData.country && c.country) newData.country = c.country || c?.address?.country || 'MEX'
        }

        form.setFieldsValue(newData)
    }

    const handleMergeClients = async (values: any) => {
        try {
            setLoading(true)
            const body = {
                clientId: values.client,
                clientsToDelete: clientsToMerge.map((c: any) => c.id).filter((id: string) => id !== values.client),
                mergedData: {
                    name: values.name,
                    legal_name: values.legalName,
                    legalName: values.legalName,
                    taxSystem: values.taxSystem,
                    tax_system: values.taxSystem,
                    rfc: values.rfc,
                    zip: values.zip,
                    country: values.country,
                    bcc: [...new Set(clientsToMerge.flatMap((c: any) => c.bcc))],
                    address: {
                        ...((clientsToMerge ?? [])[0]?.address || {}),
                        zip: values.zip,
                        country: values.country,
                    },
                },
            }
            await SignedInternalAPIRequest(body, 'mergeClients', auth.currentUser, {}, 'POST')
            setLoading(false)

            if (onSuccess) onSuccess(clientsToMerge)

            message.success('Clientes unificados correctamente')
            dispatch(closeModal('mergeClientsModalVisible'))
        } catch (error: any) {
            setLoading(false)
            message.error(error.message || 'Ocurrió un error al unificar los clientes')
        }
    }

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={{
                client: clientsToMerge.find((c: any) => c.id.startsWith('cus_'))?.id || clientsToMerge[0]?.id || null,
            }}
            onFinish={handleMergeClients}
        >
            <Space direction="vertical" style={{ marginTop: '15px', width: '100%' }}>
                <Form.Item
                    name="client"
                    label="Selecciona el cliente que deseas mantener"
                    rules={[{ required: true, message: 'Por favor selecciona un cliente' }]}
                >
                    <Radio.Group
                        onChange={(v) => {
                            mergeClientsData(v.target.value)
                            form.setFieldsValue({ client: v.target.value })
                        }}
                    >
                        <Space direction="vertical">
                            {clientsToMerge.map((client: any) => {
                                return (
                                    <Radio key={client.id} value={client.id}>
                                        <Row>
                                            <Typography.Text>
                                                {client?.name || client?.email || client?.legal_name || client?.id}
                                            </Typography.Text>
                                            {client?.id?.startsWith('cus_') && (
                                                <Tag color={colors.green_200} style={{ marginLeft: '15px' }}>
                                                    <Typography.Text style={{ color: colors.green_600 }}>
                                                        Recomendado
                                                    </Typography.Text>
                                                </Tag>
                                            )}
                                        </Row>
                                    </Radio>
                                )
                            })}
                        </Space>
                    </Radio.Group>
                    <Divider />
                    <Row gutter={12} style={{ marginTop: '15px' }}>
                        <Col xs={12}>
                            <TextInput
                                name="name"
                                label="Nombre del cliente"
                                rules={[{ required: true, message: 'Por favor selecciona un cliente' }]}
                            />
                        </Col>
                        <Col xs={12}>
                            <TextInput name="legalName" label="Nombre Legal" />
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col xs={12}>
                            <SelectInput name="taxSystem" label="Régimen fiscal" options={[...taxRegimes]} />
                        </Col>
                        <Col xs={12}>
                            <TextInput name="rfc" label="RFC" />
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col xs={12}>
                            <TextInput name="zip" label="Código postal" />
                        </Col>
                        <Col xs={12}>
                            <SelectInput
                                name="country"
                                label="País"
                                options={countryLabeledListWithCodeAlpha3.map((c) => {
                                    return {
                                        label: c.name,
                                        value: c.code,
                                    }
                                })}
                            />
                        </Col>
                    </Row>
                </Form.Item>
            </Space>
        </Form>
    )
}

export const MergeClientsModal = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const { mergeClientsModalVisible } = useSelector((state: any) => state.modals)
    const [loading, setLoading] = useState(false)

    return (
        <Modal
            title="Unificar clientes"
            open={mergeClientsModalVisible}
            onCancel={() => {
                dispatch(closeModal('mergeClientsModalVisible'))
                dispatch(setData({ item: 'clientsToMerge', data: [] }))
            }}
            confirmLoading={loading}
            onOk={() => {
                form.submit()
            }}
        >
            <ClientsToMergeForm form={form} loading={loading} setLoading={(v) => setLoading(v)} onSuccess={() => {}} />
        </Modal>
    )
}
