import { Col, Drawer, Form, message, Row, Typography } from "antd"
import { DrawerHeader } from "./CreateInvoiceDrawerV2"
import { useSelector } from "react-redux"
import { ReactNode, useEffect, useState } from "react"
import { Upload } from "@phosphor-icons/react"
import { generateCode } from "../functions/helpers"
import moment from "moment"
import { doc, getFirestore, setDoc } from "firebase/firestore"
import { RenderImportTypeForm, RenderImportView } from "./MassiveActionsHelpers"

type CardRenderType = 'payments' | 'csv' | null

const ImportTypeCard = ({
    icon,
    text,
    id,
    url,
    urlText, 
    setSelectedImportType,
    selectedImportType,
}: {
    icon: ReactNode
    text: string
    id: 'payments' | 'csv'
    url?: string 
    urlText?: string
    setSelectedImportType: (id: CardRenderType) => void
    selectedImportType: 'payments' | 'csv' | null
}) => {
    return (
        <button
            className={`d-flex flex-column formCard d-center ${selectedImportType === id ? 'formCardSelected' : ''}`}
            style={{
                borderRadius: '8px',
                padding: '16px',
                width: '40%',
                backgroundColor: 'white',
            }}
            onClick={() => setSelectedImportType(id)}
        >
            {icon}
            <Typography.Text>{text}</Typography.Text>
            {url && urlText && (
                <p className="p-small-regular neutral-3">
                    {urlText + ' '}
                    <a style={{ color: 'black' }} href={url} target="_blank" rel="noreferrer">
                        aquí
                    </a>
                </p>
            )}
        </button>
    )
}

const ToRender = ({
    step, 
    selectedImportType, 
    setSelectedImportType, 
    loading, 
    fileName, 
    handleButtonClick,
    setStep
}: {
    step: number
    selectedImportType: CardRenderType
    setSelectedImportType: (id: CardRenderType) => void
    loading: boolean
    fileName: string | null
    handleButtonClick: () => void
    setStep: (step: number) => void
}) => {
    switch (step) {
        case 0:
            return <SelectImportType 
                selectedImportType={selectedImportType} 
                setSelectedImportType={setSelectedImportType} 
                loading={loading} 
                fileName={fileName} 
                handleButtonClick={handleButtonClick}
            />

        case 1:
            return <RenderImportView type={selectedImportType} setStep={setStep}/>
    }

    return <SelectImportType 
    selectedImportType={selectedImportType} 
    setSelectedImportType={setSelectedImportType} 
    loading={loading} 
    fileName={fileName} 
    handleButtonClick={handleButtonClick}
/>

}

const SelectImportType = ({
    selectedImportType, 
    setSelectedImportType, 
    loading, 
    fileName, 
    handleButtonClick} 
: {
    selectedImportType: CardRenderType
    setSelectedImportType: (id: CardRenderType) => void
    loading: boolean
    fileName: string | null
    handleButtonClick: () => void
    }) => {
    return (
        <Row justify="center">
            <Col xs={24} md={10} xl={10}>
                <Row gutter={16} justify="center">
                    <ImportTypeCard
                        icon={<Upload />}
                        text="Sube un .csv"
                        selectedImportType={selectedImportType}
                        setSelectedImportType={setSelectedImportType}
                        id="csv"
                        url="https://firebasestorage.googleapis.com/v0/b/gigstackpro.appspot.com/o/public%2Ftemplate_facturas_masivas_gigstack.csv?alt=media&token=297e8528-865d-4c62-a6fc-0cb1a8661816"
                        urlText="Descarga la plantilla"
                    />
                </Row>
                <Row justify="center">
                    <RenderImportTypeForm
                        loading={loading}
                        type={selectedImportType}
                        fileName={fileName}
                        onClick={handleButtonClick}
                    />
                </Row>
            </Col>
        </Row>
    )
}


export const CreateInvoiceMassively = () => {
    
    const [form] = Form.useForm()

    const { testmode } = useSelector((state: any) => state.data)
    const { team } = useSelector((state: any) => state.team)
    const { user } = useSelector((state: any) => state.user)
    const { createMassiveInvoicesModalVisible } = useSelector((state: any) => state.modals)

    const [step, setStep] = useState(0)
    const [selectedImportType, setSelectedImportType] = useState<'payments' | 'csv' | null>(null)
    const [loading, setLoading] = useState(false)
    const [fileName, setFileName] = useState<string | null>(null)


    useEffect(() => {
        if(createMassiveInvoicesModalVisible) {
            setStep(0)
            setLoading(false)
            setSelectedImportType(null)
            setFileName(generateCode(15, 'file'))
        }
    }, [createMassiveInvoicesModalVisible])

    const handleUploadFile = async () => {
        try {
            setLoading(true)
            const maDoc = {
                id: generateCode(15, 'mact'),
                timestamp: moment().valueOf(),
                livemode: !testmode,
                action: 'create_invoices',
                type: 'csv',
                storageRef: `teams/${team.id}/files/${fileName}`,
                status: 'processing',
                team: team.id,
                owner: user.uid,
            }
            await setDoc(doc(getFirestore(), 'massiveActions', maDoc.id), maDoc)
            setLoading(false)
            setStep(1)
        } catch (error: any) {
            message.error(error.message || 'Ocurrió un error al cargar el archivo')
        }

        return true
    }

    const handleButtonClick = () => {
        if(selectedImportType === 'csv') {
            handleUploadFile()
        } else {
            setStep(1)
        }
    }

    return (
        <Drawer
            style={{ marginTop: testmode ? '32px' : '0px' }}
            width="100%"
            title={
                <DrawerHeader
                    edit={false}
                    title={'Facturación masiva'}
                    buttonText={'Crear facturas'}
                    ctxElementName="invoice"
                    ctxModalName="createMassiveInvoicesModalVisible"
                    readableElementType="factura"
                    form={form}
                    state={{}}
                />
            }
            open={createMassiveInvoicesModalVisible}
            closable={false}
        >
            <ToRender 
                step={step} 
                selectedImportType={selectedImportType} 
                setSelectedImportType={setSelectedImportType} 
                loading={loading} 
                fileName={fileName} 
                handleButtonClick={handleButtonClick}
                setStep={setStep}
            />
        </Drawer>
    )
}