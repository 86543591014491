import { Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal, setElementSelected } from '../context/modalsSlice'

const IntegrationModal: React.FC = () => {
    const dispatch = useDispatch()
    const { integrationModalVisible } = useSelector((state: any) => state.modals)

    const handleClose = () => {
        dispatch(closeModal('integrationModalVisible'))
        dispatch(
            setElementSelected({
                element: 'integration',
                data: null,
            }),
        )
    }

    return (
        <Modal title="Basic Modal" open={integrationModalVisible} onCancel={handleClose}>
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
        </Modal>
    )
}

export default IntegrationModal
