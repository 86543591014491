import { Col, Row, Typography } from 'antd'
import { OnboardingStepCard } from '../../customHooks/useNoticeBoard';
import { CheckCircle, ArrowRight } from '@phosphor-icons/react';

export default function MissingOnboardingStepsCard({ card, index }: { card: OnboardingStepCard, index: number }) {
    const background = (index: number, isCompleted: boolean) => {
        if (isCompleted) {
            return 'bg-neutral-5'
        } else {
            return index === 0 ? 'bg-primary-8' : 'bg-primary-7'
        }
    }


    return (
        <Col style={{ width: '20%', height: '80%' }}>
            <div
                className={`rounded-md ${background(index, card.isCompleted)}`}
                style={{
                    padding: '15px',
                    margin: '5px',
                    height: '100px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Row>
                    <Typography.Text
                        className="p-base-bold"
                        style={{ textDecoration: card.isCompleted ? 'line-through' : 'none' }}
                    >
                        {card.description}
                    </Typography.Text>
                </Row>
                <Row>
                    {card.isCompleted ? (
                        <Typography
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '57%',
                            justifyContent: 'space-between',  
                        }}
                    >
                        <span>
                            <CheckCircle size={17} weight= "bold" color="var(--primary-3)" />
                        </span>
                        <span className="primary-3 p-base-bold">Completado</span>
                    </Typography>
                    ) : (
                        <Typography
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '31%',
                                alignItems: 'baseline',
                                justifyContent: 'space-between',
                                cursor: 'pointer',
                            }}
                            onClick={() => card.action()}
                        >
                            <span className="primary-1 p-base-bold">Iniciar</span>
                            <ArrowRight size={10} weight="bold" color="var(--primary-1)" />
                        </Typography>
                    )}
                </Row>
            </div>
        </Col>
    )
}
