import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NumberInput, SelectInput } from '../Forms/Inputs'
import { setData } from '../../context/dataSlice'
import { Col, Row } from 'antd'
import { currecies } from '../../datasets/Currencies'
import { FormInstance } from 'antd/lib'

const PaymentLimit = () => {
    const { team } = useSelector((state: any) => state.team)
    const { client, limitDaysToPay } = useSelector((state: any) => state.data)
    const dispatch = useDispatch()
    const [defaultOption, setDefaultOption] = useState(
        client?.defaults?.limitDaysToPay
            ? client.defaults?.limitDaysToPay
            : typeof team?.defaults?.limitDaysToPay === 'object'
              ? team?.defaults?.limitDaysToPay?.value
              : team?.defaults?.limitDaysToPay ?? null,
    )
    useEffect(() => {
        if (client?.defaults?.limitDaysToPay) {
            setDefaultOption(client?.defaults?.limitDaysToPay)
        }

        dispatch(
            setData({
                item: 'limitDaysToPay',
                data: defaultOption,
            }),
        )
        //eslint-disable-next-line
    }, [client])

    const options = Array.from(Array(90).keys()).map((v) => ({
        value: v + 1,
        label: `${v + 1} día${v > 0 ? 's' : ''}`,
    }))

    return (
        <div style={{ width: '100%' }}>
            <SelectInput
                label="Día límite de pago"
                defaultValue={limitDaysToPay}
                options={options}
                description="Después de esta cantidad de días marcaremos el pago como atrasado y enviaremos un recordatorio a tu cliente."
                onChange={(v: any) => {
                    if (v) {
                        setDefaultOption(v)
                    }
                    dispatch(
                        setData({
                            item: 'limitDaysToPay',
                            data: v,
                        }),
                    )
                }}
                name={'limitDaysToPay'}
                placeholder={'Días para pagar'}
            />
        </div>
    )
}

const PaymentConfig = ({ form }: { form: FormInstance }) => {
    const { exchangeRates } = useSelector((state: any) => state.data)

    const handleCurrencyChange = (value: string) => {
        const selectedCurrency = currecies.find((currency) => currency.value === value)
        if (selectedCurrency) {
            const exchangeRate = exchangeRates[selectedCurrency.value.toUpperCase()]
            form.setFieldsValue({
                currency: selectedCurrency.value,
                exchange: exchangeRate ? (1 / exchangeRate).toFixed(4) : '', // Actualizar el tipo de cambio
            })
        }
    }

    return (
        <>
            <Row gutter={[16, 16]} style={{ marginTop: '15px' }}>
                <Col xs={24} md={12}>
                    <SelectInput
                        rules={[
                            { required: true, message: `Por favor seleccion la moneda` }, // Agregar regla de validación requerida
                        ]}
                        label="Moneda"
                        name="currency"
                        options={currecies}
                        placeholder="Moneda"
                        // onChange={() => {
                        //     form.setFieldsValue({
                        //         exchange: (1 / exchangeRates[currency]).toFixed(4)
                        //     })
                        // }}
                        onChange={handleCurrencyChange}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <NumberInput
                        name={'exchange'}
                        placeholder="Tipo de cambio"
                        disabled={false}
                        min={0}
                        max={100000}
                        step={0.1}
                        label="Tipo de cambio"
                    />
                </Col>
            </Row>
            <Row style={{ margin: '20px 0px' }}>
                <PaymentLimit />
            </Row>
        </>
    )
}

export default PaymentConfig
