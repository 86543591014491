import { OptimizedReceiptDef, ReceiptDef } from '../../../interfaces/receiptsDef'
import { ReceiptsColumns } from './Columns/ReceiptsColumns'
import ReceiptActions from '../ElementActions/ReceiptsActions'
import { PaymentsColumns } from './Columns/PaymentsColumns'
import PaymentsActions from '../ElementActions/PaymentsActions'
import { OptimizedPaymentDef, Payment, PaymentLink } from '../../../interfaces/paymentsDef'
import { InvoiceDef, OptimizedInvoiceDef } from '../../../interfaces/invoicesDef'
import InvoiceActions from '../ElementActions/InvoiceActions'
import { InvoicesColumns } from './Columns/InvoicesColumns'
import { PaymentLinkColumns } from './Columns/PaymentLinksColumns'

export const PaymentColumnsObject = ({
    customKeysToShow,
    navigate,
}: {
    customKeysToShow?: any
    navigate: Function
}) => {
    let def: OptimizedPaymentDef | Payment
    var keysToShow: any = customKeysToShow
        ? customKeysToShow
        : {
              clientInfo: 'Cliente',
              amount: 'Total',
              status: 'Status',
              from: 'Fuente',
              timestamp: 'Creado',
              succeededTimestamp: 'Pagado',
              automations: '',
              relations: 'Relaciones',
          }
    const columns = [
        ...Object.keys(keysToShow).map((k: any) => {
            let col: any = {
                title: keysToShow[k],
                dataIndex: k,
                render: (text: any, record: typeof def) => (
                    <div key={k} style={{ cursor: 'pointer' }}>
                        {PaymentsColumns({
                            record,
                            columnName: k,
                            navigate: () => navigate(`/paymentDetails?id=${record.fid}`),
                        })}
                    </div>
                ),
            }
            if (k === 'clientInfo') {
                col.fixed = 'left'
                col.width = '25%'
            }

            if (k === 'amount') col.width = '10%'

            if (k === 'status') col.width = '5%'

            if (k === 'from') col.width = '10%'

            if (k === 'timestamp') {
                col.width = '10%'
                col.sorter = (a: any, b: any) => a.timestamp - b.timestamp
            }
            if (k === 'succeededTimestamp') {
                col.width = '15%'
                col.sorter = (a: any, b: any) => a.succeededTimestamp - b.succeededTimestamp
            }

            if (k === 'automations') {
                col.width = '5%'
            }

            if (k === 'relations') col.width = '15%'

            return col
        }),
        {
            title: '',
            dataIndex: 'actions',
            fixed: 'right',
            width: '5%',
            render: (text: any, record: typeof def) => <PaymentsActions payment={record} hide={false} />,
        },
    ]
    return columns
}

export const PaymentLinksColumnsObject = ({
    customKeysToShow,
    navigate,
}: {
    customKeysToShow?: any
    navigate: Function
}) => {
    let def: PaymentLink
    let keysToShow: any = customKeysToShow
        ? customKeysToShow
        : {
              id: 'ID',
              amount: 'Monto',
              status: 'Estado',
              timestamp: 'Creado',
              views: 'Vistas',
              relations: 'Relaciones',
              automations: 'Automatizaciones',
          }

    const columns = [
        ...Object.keys(keysToShow).map((k: any) => {
            let col: any = {
                title: keysToShow[k],
                dataIndex: k,
                render: (text: any, record: typeof def) => (
                    <div key={k} style={{ cursor: 'pointer' }}>
                        {PaymentLinkColumns({
                            record,
                            columnName: k,
                            navigate: () => navigate(`/paymentLinkDetails?id=${record.id}`),
                        })}
                    </div>
                ),
            }
            if (k === 'id') col.width = '10%'
            if (k === 'amount') col.width = '10%'
            if (k === 'status') col.width = '7%'
            if (k === 'views') col.width = '6%'
            if (k === 'timestamp') col.width = '10%'
            if (k === 'relations') col.width = '10%'
            if (k === 'automations') col.width = '12%'
            return col
        }),
        {
            title: '',
            dataIndex: 'actions',
            fixed: 'right',
            width: '10%',
            render: (text: any, record: typeof def) => <PaymentsActions payment={record} hide={false} />,
        },
    ]

    return columns
}

export const ReceiptsColumnsObject = ({
    customKeysToShow,
    navigate,
}: {
    customKeysToShow?: any
    navigate: Function
}) => {
    let def: OptimizedReceiptDef | ReceiptDef
    const keysToShow: any = customKeysToShow
        ? customKeysToShow
        : {
              clientInfo: 'Cliente',
              amount: 'Total',
              status: 'Estado',
              timestamp: 'Creado',
              validUntil: 'Válido hasta',
              paymentMethod: 'Método de pago',
              from: 'Fuente',
              relations: 'Relaciones',
          }
    const columns = [
        ...Object.keys(keysToShow).map((k: any) => {
            let col: any = {
                title: keysToShow[k],
                dataIndex: k,
                render: (text: any, record: typeof def) => (
                    <div key={k} style={{ cursor: 'pointer' }} onClick={() => {}}>
                        {ReceiptsColumns({
                            record,
                            columnName: k,
                            navigate: () => navigate(`/receiptDetails?id=${record.id}`),
                        })}
                    </div>
                ),
            }
            if (k === 'clientInfo') {
                col.fixed = 'left'
                col.width = '15%'
            }

            if (k === 'status') col.width = '7%'

            return col
        }),
        {
            title: '',
            dataIndex: 'actions',
            fixed: 'right',
            width: '10%',
            render: (text: any, record: typeof def) => <ReceiptActions receipt={record} hide={false} />,
        },
    ]

    return columns
}

export const InvoicesColumnsObject = ({
    customKeysToShow,
    navigate,
}: {
    customKeysToShow?: any
    navigate: Function
}) => {
    let def: OptimizedInvoiceDef | InvoiceDef
    let keysToShow: any = customKeysToShow
        ? customKeysToShow
        : {
              clientInfo: 'Emitida a',
              amount: 'Total',
              status: 'Status',
              from: 'Fuente',
              invoiceType: 'Tipo',
              series: 'Folio',
              paymentFormAndMethod: 'Forma de pago',
              timestamp: 'Creado',
              relations: 'Relaciones',
          }
    const columns = [
        ...Object.keys(keysToShow).map((k: any) => {
            let column: any = {
                title: keysToShow[k],
                dataIndex: k,
                render: (text: any, record: typeof def) => (
                    <div key={k} style={{ cursor: 'pointer' }}>
                        {InvoicesColumns({
                            record,
                            columnName: k,
                            navigate:
                                k === 'clientInfo'
                                    ? () => {
                                          navigate(`/invoiceDetails?id=${record.id}`)
                                      }
                                    : undefined,
                        })}
                    </div>
                ),
            }
            if (k === 'clientInfo') {
                column['width'] = '25%'
                column['fixed'] = 'left'
            }

            if (k === 'amount') column['width'] = '15%'
            if (k === 'status') column['width'] = '5%'
            if (k === 'from') column['width'] = '5%'
            if (k === 'invoiceType') column['width'] = '5%'
            if (k === 'series') column['width'] = '10%'
            if (k === 'paymentFormAndMethod') column['width'] = '10%'
            if (k === 'timestamp') column['width'] = '10%'
            if (k === 'timestamp') column['sorter'] = (a: any, b: any) => a.timestamp - b.timestamp
            if (k === 'relations') column['width'] = '10%'
            if (k === 'actions') column['fixed'] = 'right'

            return column
        }),
        {
            width: '5%',
            fixed: 'right',
            dataIndex: 'actions',
            render: (text: any, record: typeof def) => <InvoiceActions hide={false} invoice={record} />,
        },
    ]

    return columns
}
