import { DotsThree, FileMagnifyingGlass } from "@phosphor-icons/react"
import { Dropdown, Row, Typography } from "antd"
import { MenuProps } from "antd/lib"
import { doc, DocumentData, getDoc, getFirestore } from "firebase/firestore"
import { useDispatch } from "react-redux"
import { openModal } from "../../../context/modalsSlice"
import { setData } from "../../../context/dataSlice"

export async function refactorInvoices(invoices: any[], operationId: string | undefined) {
    const refactoredInvoices = [];
    for (const invoice of invoices) {
      let paymentDoc: DocumentData | undefined = {};
      if (invoice.relatedTo) {
        const docRef = doc(getFirestore(), invoice.relatedTo);
        const docSnap = await getDoc(docRef);
        paymentDoc = docSnap.data();
      }
      refactoredInvoices.push({
        data: {
          client: {
            RFCvalidated: paymentDoc?.client?.RFCvalidated,
            address: {
              country: paymentDoc?.client.address?.country,
              zip: paymentDoc?.client?.address.zip,
            },
            billingAccount: paymentDoc?.billingAccount,
            clabe: paymentDoc?.client?.metadata?.clabe,
            company: paymentDoc?.client?.company,
            country: paymentDoc?.client?.country,
            defaults: paymentDoc?.client?.defaults,
            email: paymentDoc?.client?.email,
            emailAddresses: paymentDoc?.client?.emailAddresses,
            id: paymentDoc?.client?.id,
            internalId: paymentDoc?.client?.metadata?.internalId,
            lastEdited: {
              by: paymentDoc?.client?.lastEdited?.by,
              timestamp: paymentDoc?.client?.lastEdited?.timestamp,
            },
            legalName: invoice?.data?.client?.legalName || paymentDoc?.client?.legalName,
            legal_name: paymentDoc?.client?.legal_name,
            livemode: paymentDoc?.client?.livemode,
            metadata: paymentDoc?.client?.metadata,
            name: paymentDoc?.client?.name,
            names: paymentDoc?.client?.names,
            owner: paymentDoc?.client?.owner,
            phone: paymentDoc?.client?.phone,
            rfc: paymentDoc?.client?.rfc,
            taxSystem: paymentDoc?.client?.taxSystem,
            tax_system: paymentDoc?.client?.tax_system,
            team: paymentDoc?.client?.team,
            timestamp: paymentDoc?.client?.timestamp,
            use: paymentDoc?.client?.use,
            zip: paymentDoc?.client?.zip,
          },
          clientID: paymentDoc?.clientID,
          clientId: paymentDoc?.clientId,
          currency: paymentDoc?.currency,
          exchange: paymentDoc?.exchange || paymentDoc?.exchangeRate,
          emails: paymentDoc?.emails,
          internalItems: paymentDoc?.internalItems,
          invoiceType: paymentDoc?.invoiceType,
          items: paymentDoc?.items,
          livemode: paymentDoc?.livemode,
          notes: paymentDoc?.notes,
          owner: paymentDoc?.owner,
          payment_form: paymentDoc?.payment_form,
          series: paymentDoc?.series,
          team: paymentDoc?.team,
          test: paymentDoc?.test,
          type: paymentDoc?.type,
          use: paymentDoc?.use,
          payments: invoice.data.payments,
        },
        payment_method: invoice?.data?.payment_method,
        error: invoice.error,
        id: invoice.id,
        owner: invoice.owner,
        ref: invoice.ref,
        relatedTo: invoice.relatedTo,
        status: invoice.status,
        succeeded: invoice.succeeded,
        team: invoice.team,
        timestamp: invoice.timestamp,
        from: invoice.from,
        invoiceType: invoice.invoiceType,
        folio_number: invoice.folio_number,
        actionsDocs:{
          error: [invoice.error ? invoice.error : null, invoice.id ? invoice.id : null],
          route: `massiveActions/${operationId}/docs/${invoice.id}`
        } 
      });
    }
    return refactoredInvoices;
  }

export const MassInvoiceTableAction = ({id}: {id:string}) => {

    const dispatch = useDispatch()
    
    const handleDisplayMassInvoiceGroup = async () => {
          dispatch(setData({ item: 'massiveInvoiceStatus', data: {
            id,
          } }))
          dispatch(openModal('massiveInvoiceStatusDrawerVisible'))
    }

    const actionMenu: MenuProps['items'] = [
        {
            label: (
                <Row align="middle">
                    <FileMagnifyingGlass className="icon" size={18} weight="regular" />
                    <Typography.Text
                        style={{
                            margin: 0,
                            padding: 0,
                            fontSize: '13px',
                            marginLeft: '5px',
                        }}
                    >
                        Visualizar facturas
                    </Typography.Text>
                </Row>
            ),
            key: 'display_invoice_group',
            onClick: handleDisplayMassInvoiceGroup
        },
    ]

    return (
        <Row>
            <Dropdown menu={{ items: actionMenu }} trigger={['click']} placement="bottomRight">
                <DotsThree style={{ fontSize: '24px', cursor: 'pointer' }} />
            </Dropdown>
        </Row>
    )
}