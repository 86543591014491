import React, { useState } from 'react'
import { Row, Col } from 'antd'
import { NumberInput, SelectInput } from '../Forms/Inputs'
import { useDispatch, useSelector } from 'react-redux'
import { setData } from '../../context/dataSlice'
import { currecies } from '../../datasets/Currencies'
import { FormInstance } from 'antd/lib'
import { paymentForms } from '../../datasets/Fiscal'

// interface ReceiptInfoProps {
//     onChange?: any
//     state?: any,
//     setState?: any
// }

const PaymentForm = ({ form }: { form: FormInstance }) => {
    const { exchangeRates, currency } = useSelector((state: any) => state.data)
    const dispatch = useDispatch()
    const [, /*currencyState*/ setCurrency] = useState('') // Estado para la moneda seleccionada

    // const handleCurrencyChange = (value: string) => {
    //     const selectedCurrency = currecies.find(currency => currency.value === value);
    //     if (selectedCurrency) {
    //         const exchangeRate = exchangeRates[(selectedCurrency.value).toUpperCase()];
    //         // dispatch(setData({
    //         //     item: 'currency',
    //         //     data: selectedCurrency.value
    //         // }))
    //         // dispatch(setData({
    //         //     item: 'exchange',
    //         //     data: 1 / exchangeRate,
    //         // }))
    //     }

    // };

    const handleCurrencyChange = (value: string) => {
        const selectedCurrency = currecies.find((currency) => currency.value === value)
        if (selectedCurrency) {
            setCurrency(selectedCurrency.value) // Actualizar el estado de la moneda
            const exchangeRate = exchangeRates[selectedCurrency.value.toUpperCase()]
            form.setFieldsValue({
                exchange: exchangeRate ? (1 / exchangeRate).toFixed(4) : '',
                exchange_rate: exchangeRate ? (1 / exchangeRate).toFixed(4) : '', // Actualizar el tipo de cambio
            })
        }
    }

    return (
        <Row style={{ width: '100%', marginTop: '15px' }} gutter={{ xs: 0, md: 10, lg: 12 }}>
            <Col xs={24}>
                <SelectInput
                    rules={[
                        { required: true, message: `Por favor seleccion forma de pago` }, // Agregar regla de validación requerida
                    ]}
                    label="Forma de pago"
                    options={paymentForms}
                    name="payment_form"
                    placeholder="Forma de pago"
                    onChange={(value: any) => {
                        dispatch(
                            setData({
                                item: 'payment_form',
                                data: value,
                            }),
                        )
                    }}
                />
            </Col>
            <Col xs={24} md={12}>
                <SelectInput
                    rules={[
                        { required: true, message: `Por favor seleccion la moneda` }, // Agregar regla de validación requerida
                    ]}
                    label="Moneda"
                    name="currency"
                    options={currecies}
                    placeholder="Moneda"
                    // onChange={() => {
                    //     form.setFieldsValue({
                    //         exchange: (1 / exchangeRates[currency]).toFixed(4)
                    //     })
                    // }}
                    onChange={handleCurrencyChange}
                    // defaultValue={currency}
                />
            </Col>
            <Col xs={24} md={12}>
                <NumberInput
                    name={'exchange'}
                    placeholder="Tipo de cambio"
                    disabled={currency === 'MXN' ? true : false}
                    min={0}
                    max={100000}
                    step={0.1}
                    label="Tipo de cambio"
                />
            </Col>
            <Col xs={24}>
                <SelectInput
                    label="Periodicidad"
                    rules={[
                        { required: true, message: `Por favor seleccion la periodicidad` }, // Agregar regla de validación requerida
                    ]}
                    options={[
                        {
                            value: 'day',
                            label: 'Día',
                        },
                        {
                            value: 'week',
                            label: 'Semana',
                        },
                        {
                            value: 'month',
                            label: 'Mes',
                        },
                        {
                            value: 'two_months',
                            label: '2 meses',
                        },
                    ]}
                    name="periodicity"
                    placeholder="Periodicidad"
                    onChange={(value: any) => {
                        dispatch(
                            setData({
                                item: 'periodicity',
                                data: value,
                            }),
                        )
                    }}
                />
            </Col>
        </Row>
    )
}

export default PaymentForm
