import { MetroSpinner } from 'react-spinners-kit'
import { OptimizedReccuringDef, Recurring } from '../../../interfaces/RecurringDef'
import { InvoiceDef, OptimizedInvoiceDef } from '../../../interfaces/invoicesDef'
import { OptimizedPaymentDef, Payment } from '../../../interfaces/paymentsDef'
import { ReceiptDef, OptimizedReceiptDef } from '../../../interfaces/receiptsDef'
import { useRelations } from '../../customHooks/useRelations'
import { RelationsDrawerContainer } from './RelationsDrawerView'
import { RelationsTable } from './RelationsTableView'
import { handleDef } from '../../../helpers/helperFunctions'

export interface RelationsTypes {
    title: string
    viewType: 'drawer' | 'table'
    type: 'payments' | 'invoices' | 'receipts' | 'recurringInvoices' | 'recurringPayments'
    collection: 'payments' | 'invoices' | 'receipts'
    subcollection?: 'payments' | 'invoices' | 'receipts'
    item:
        | InvoiceDef
        | Payment
        | ReceiptDef
        | OptimizedPaymentDef
        | OptimizedInvoiceDef
        | OptimizedReceiptDef
        | Recurring
        | OptimizedReccuringDef
        | any
    itemId: string | null
    toCancel?: boolean
}

export const RelationsHandler = ({
    title,
    viewType,
    type,
    collection,
    item,
    itemId,
    subcollection,
    toCancel,
}: RelationsTypes) => {
    let relationIds = []
    let relationData: any[] = []

    if (type === 'recurringInvoices' || type === 'recurringPayments') {
        relationIds = item?.relations ?? []
    }

    if (!toCancel) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { status, data } = useRelations({
            relatedToCollection: collection,
            relatedToId: itemId ?? '',
            searchInKey: type,
            relationIds,
            def: handleDef(collection),
        })

        relationData = data

        if (status === 'loading') {
            return (
                <>
                    <MetroSpinner size={13} color="#686769" />
                </>
            )
        }

        if (status === 'error') {
            return <></>
        }
    } else {
        relationData = item
    }

    const toRender = () => {
        switch (viewType) {
            case 'drawer':
                return <RelationsDrawerContainer items={relationData} title={title} elementType={collection} />
            case 'table':
                return <RelationsTable type={collection} items={relationData} />
        }
    }

    return <div>{toRender()}</div>
}
