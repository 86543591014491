import { Checkbox, Col, Modal, Row, Switch, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
import { X } from '@phosphor-icons/react'
import { setData } from '../context/dataSlice'
import { useState } from 'react'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'

const ClientDefaultsModal = () => {
    const dispatch = useDispatch()
    const { clientDefaultsModalVisible } = useSelector((state: any) => state.modals)
    const { client, clients } = useSelector((state: any) => state.data)

    const [defaults, setDefaults] = useState(
        client?.defaults ?? {
            avoidNextInvoices: false,
        },
    )

    const setClientDefaults = async () => {
        dispatch(closeModal('clientDefaultsModalVisible'))
        const clientUpdate = {
            ...client,
            defaults: defaults,
        }
        dispatch(
            setData({
                item: 'clients',
                data: clients.map((client: any) => {
                    if (clientUpdate.id === client.id) {
                        return clientUpdate
                    }
                    return client
                }),
            }),
        )

        await updateDoc(doc(getFirestore(), 'clients', clientUpdate.id), { defaults: defaults })
        dispatch(
            setData({
                item: 'client',
                data: clientUpdate,
            }),
        )
    }

    return (
        <Modal
            title={
                <div style={{ position: 'relative', paddingBottom: '15px' }}>
                    <Typography.Text
                        className="smallheader"
                        style={{
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            paddingTop: '5px',
                        }}
                    >
                        Valores predeterminados del cliente
                    </Typography.Text>
                    <Row>
                        <Col xs={24} style={{ margin: 'auto' }}>
                            <Row justify="space-between" align="middle">
                                <X className="icon clickable" size={20} onClick={async () => setClientDefaults()} />
                            </Row>
                        </Col>
                    </Row>
                </div>
            }
            closeIcon={<></>}
            width={window.innerWidth > 768 ? '50%' : '90%'}
            style={{ top: 15 }}
            open={clientDefaultsModalVisible}
            footer={null}
        >
            <div
                style={{
                    maxHeight: '80vh',
                    overflowY: 'auto',
                    padding: '0px 10px',
                }}
            >
                <Typography.Text className="cLabel">Emitir facturas automáticas para este cliente</Typography.Text>
                <div style={{ marginBottom: '5px', marginTop: '5px' }}>
                    <Switch
                        onChange={async (v) => {
                            setDefaults({ ...defaults, avoidNextInvoices: !v })
                        }}
                        defaultChecked={!defaults?.avoidNextInvoices}
                    />
                </div>
                <div className="d-flex flex-row" style={{ marginTop: '15px' }}>
                    <Checkbox
                        disabled={!defaults?.avoidNextInvoices}
                        checked={defaults?.invoiceableReceipts}
                        onChange={(v) => {
                            setDefaults({ ...defaults, invoiceableReceipts: v.target.checked })
                        }}
                    >
                        Crear recibos de venta facturables
                    </Checkbox>
                </div>
                <Typography.Text type="secondary" style={{ fontSize: '10px' }}>
                    {defaults?.avoidNextInvoices
                        ? `*Emitiremos recibos de venta que ${defaults?.invoiceableReceipts ? '' : 'NO'} podrán ser facturados`
                        : '*Emitiremos facturas o recibos de venta facturables para este cliente.'}
                </Typography.Text>
            </div>
        </Modal>
    )
}

export default ClientDefaultsModal
