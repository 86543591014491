import { LoadingOutlined } from '@ant-design/icons'
import { Plus } from '@phosphor-icons/react'
import { Row, Space, Typography } from 'antd'
import { useState } from 'react'
import { generateCode } from '../../../helpers/helperFunctions'
import { InviteDef } from '../../../interfaces/invitesDef'
import { InviteCard } from './InviteCard'

export const InvitesContainer = ({
    invites,
    newTeamFunction,
}: {
    invites: InviteDef[]
    newTeamFunction?: Function
}) => {
    const [loadingNewBATEAM, setLoadingNewBATEAM] = useState(false)

    return (
        <div
            style={{
                backgroundColor: 'white',
                padding: '40px 60px',
                minWidth: '100%',
                minHeight: '60vh',
                borderRadius: '6px',
            }}
        >
            <Space direction="vertical" size="small" style={{ marginBottom: '20px' }}>
                <Typography.Text className="h5-bold">Te han invitado a un equipo</Typography.Text>
                <Typography.Text className="p-base-regular">
                    Únete a una de las siguientes cuentas o bien crea una nueva
                </Typography.Text>
            </Space>

            {invites.map((invite, index) => {
                return <InviteCard key={generateCode(5)} invite={invite}  />
            })}

            <Row
                className="clickable"
                style={{ margin: '35px 0px' }}
                onClick={async () => {
                    if (loadingNewBATEAM) return
                    if (newTeamFunction) {
                        setLoadingNewBATEAM(true)
                        newTeamFunction()
                        await new Promise((resolve) => setTimeout(resolve, 2000))
                        setLoadingNewBATEAM(false)
                    }
                }}
            >
                <div
                    style={{
                        borderRadius: '6px',
                        width: '60px',
                        height: '60px',
                        backgroundColor: 'var(--neutral-5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '15px',
                    }}
                >
                    {loadingNewBATEAM ? <LoadingOutlined /> : <Plus size={25} />}
                </div>
                <Typography.Text className="p-base-regular">
                    {loadingNewBATEAM ? 'Creando equipo' : 'Crear un nuevo equipo'}
                </Typography.Text>
            </Row>
        </div>
    )
}
