import { CaretDoubleRight, ChartLineDown, ChartLineUp, CurrencyCircleDollar } from '@phosphor-icons/react'
import { Button, Col, Form, FormInstance, Popover, Row, Space, Switch, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { setData } from '../../context/dataSlice'
import {
    CheckBoxInput,
    ChipsSelect,
    DateInput,
    MetadataListInput,
    SelectInput,
    TextAreaInput,
    TextInput,
} from '../Forms/Inputs'
import { CFDIRelations } from '../../datasets/Fiscal'
import { taxRegimes } from '../../functions/helpers'
import dayjs, { Dayjs } from 'dayjs'
import ItemsSelection from '../ComponentIndividual/ItemsSelection'
import ProductsServicesTotals from '../ComponentIndividual/ProductsServicesTotals'
import { addParamToUrl } from '../../functions/UrlParams'
import { MetroSpinner } from 'react-spinners-kit'
import { useState } from 'react'
import { SignedInternalAPIRequest } from '../../functions/APIRequest'
import { useAuth } from 'reactfire'
import { setFolio } from '../../context/folioSlice'
import { SuccessView } from '../UI/ResourceCreatedView'

export const InvoiceTypeDescription = ({
    icon,
    description,
    boldDescription,
}: {
    icon: any
    description: string
    boldDescription: string
}) => {
    return (
        <div style={{ width: '200px' }}>
            <Space direction="vertical">
                {icon}
                <p style={{ margin: 0 }}>
                    <span className="mediumparagraphbold">
                        {boldDescription} {'\u00A0'}
                    </span>
                    {description}
                </p>
            </Space>
        </div>
    )
}

export const SelectInvoiceType = ({ state, setState }: { state: any; setState: any }) => {
    const auth = useAuth()
    const { invoice } = useSelector((state: any) => state.data)
    const { team } = useSelector((state: any) => state.team)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const dispatchInvoice = () => {
        dispatch(
            setData({
                item: 'invoice',
                data: {
                    ...invoice,
                    type: invoice?.type,
                    step: 1,
                },
            }),
        )
    }

    const baseFolioFields = () => {
        dispatch(
            setFolio({
                forceAutomaticFolio: true,
            }),
        )
    }

    const getAndSetIncomeFolioValues = async () => {
        let ftpiConfig: any = {}

        try {
            setLoading(true)
            const ftpiData = await SignedInternalAPIRequest(
                {},
                'facturapiAppHandlerInfo/data',
                auth.currentUser,
                {},
                'GET',
            )
            ftpiConfig = await ftpiData?.customization

            const incomeFolio = ftpiConfig?.next_folio_number ?? team?.invoice_folio ?? 0
            const incomeFolioTest = ftpiConfig?.next_folio_number_test ?? team?.invoice_folio_test ?? 0

            dispatch(
                setFolio({
                    automaticFolio: incomeFolio,
                    automaticFolioTest: incomeFolioTest,
                    forceAutomaticFolio: true,
                }),
            )
            setLoading(false)
        } catch (error) {
            console.log('Error getting FTPI data', error)
            baseFolioFields()
        }
    }

    const LoadingIcon = () => {
        return <MetroSpinner size={16} color="white" />
    }

    return (
        <Row style={{ marginTop: '35px' }}>
            <Col xs={24}>
                <Typography.Text className="bigparagraph">1. Selecciona el tipo de factura</Typography.Text>
                <Row align="middle" justify="center" style={{ marginTop: '30px' }}>
                    <Space>
                        <Popover
                            content={
                                <InvoiceTypeDescription
                                    icon={
                                        <ChartLineUp className="green" weight="regular" style={{ fontSize: '24px' }} />
                                    }
                                    description="son todos aquellos comprobantes que se emiten al realizar un trámite de compra-venta"
                                    boldDescription="Los CFDI de ingreso "
                                />
                            }
                            placement="bottom"
                            arrow={false}
                        >
                            <Button
                                onClick={() => {
                                    dispatch(
                                        setData({
                                            item: 'invoice',
                                            data: {
                                                ...invoice,
                                                type: 'I',
                                                step: 0,
                                                invoiceType: 'I',
                                            },
                                        }),
                                    )
                                    addParamToUrl('invoiceType', 'I')
                                }}
                                className={invoice?.type === 'I' ? 'activeBtn' : ''}
                            >
                                Factura de ingreso
                            </Button>
                        </Popover>
                        <Popover
                            content={
                                <InvoiceTypeDescription
                                    icon={
                                        <CurrencyCircleDollar
                                            className="blue"
                                            weight="regular"
                                            style={{ fontSize: '24px' }}
                                        />
                                    }
                                    description="son todos los comprobantes que se emiten relacionados a un CFDI ingreso en PPD con los detalles del pago"
                                    boldDescription="Los CFDI de pago"
                                />
                            }
                            placement="bottom"
                            arrow={false}
                        >
                            <Button
                                onClick={() => {
                                    dispatch(
                                        setData({
                                            item: 'invoice',
                                            data: {
                                                ...invoice,
                                                type: 'P',
                                                step: 0,
                                                invoiceType: 'P',
                                            },
                                        }),
                                    )
                                    addParamToUrl('invoiceType', 'P')
                                }}
                                className={invoice?.type === 'P' ? 'activeBtn' : ''}
                            >
                                Factura de pago
                            </Button>
                        </Popover>
                        <Popover
                            content={
                                <InvoiceTypeDescription
                                    icon={
                                        <ChartLineDown className="red" weight="regular" style={{ fontSize: '24px' }} />
                                    }
                                    description="son todos aquellos comprobantes que detallan una transacción de egreso, por ejemplo una devolución"
                                    boldDescription="Los CFDI de egreso"
                                />
                            }
                            placement="bottom"
                            arrow={false}
                        >
                            <Button
                                onClick={() => {
                                    dispatch(
                                        setData({
                                            item: 'invoice',
                                            data: {
                                                ...invoice,
                                                step: 0,
                                                type: 'E',
                                                invoiceType: 'E',
                                            },
                                        }),
                                    )
                                    addParamToUrl('invoiceType', 'E')
                                }}
                                className={invoice?.type === 'E' ? 'activeBtn' : ''}
                            >
                                Factura de egreso
                            </Button>
                        </Popover>
                    </Space>
                </Row>
                {invoice?.type && (
                    <Row style={{ marginTop: '30px' }} justify="end">
                        <Button
                            type="primary"
                            onClick={async () => {
                                if (invoice?.type === 'I') {
                                    await getAndSetIncomeFolioValues()
                                    dispatchInvoice()
                                } else {
                                    baseFolioFields()
                                    dispatchInvoice()
                                }
                            }}
                        >
                            {invoice?.type !== 'I' ? (
                                <>
                                    Siguiente <CaretDoubleRight weight="regular" />
                                </>
                            ) : (
                                <>
                                    Siguiente
                                    {loading ? (
                                        <div style={{ marginLeft: '.5rem' }}>
                                            <LoadingIcon />
                                        </div>
                                    ) : (
                                        <CaretDoubleRight weight="regular" />
                                    )}
                                </>
                            )}
                        </Button>
                    </Row>
                )}
            </Col>
        </Row>
    )
}

export const InvoiceMoreOptionsSection = ({
    state,
    setState,
    form,
}: {
    state: any
    setState: any
    form: FormInstance
}) => {
    const { invoice } = useSelector((state: any) => state.data)

    return (
        <div>
            {invoice?.type === 'P' && (
                <>
                    <Row justify="start" align="middle" style={{ marginTop: '15px' }}>
                        <Form.Item
                            labelAlign="right"
                            label="¿Es un pago por cuenta de terceros?"
                            valuePropName="checked"
                            name="isThirdParty"
                        >
                            <Switch
                                onChange={() => {
                                    setState({
                                        ...state,
                                        isThirdParty: !state.isThirdParty,
                                    })
                                }}
                            />
                        </Form.Item>
                    </Row>
                    {state?.isThirdParty && (
                        <Row justify="center" gutter={10} style={{ marginTop: '10px' }}>
                            <Col xs={12}>
                                <TextInput
                                    name="thirdPartyLegalName"
                                    label="Nombre legal"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Es necesario capturar el nombre legal',
                                        },
                                    ]}
                                />
                            </Col>
                            <Col xs={12}>
                                <TextInput
                                    name="thirdPartyTaxId"
                                    label="RFC"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Es necesario capturar el RFC',
                                        },
                                    ]}
                                />
                            </Col>
                            <Col xs={12}>
                                <TextInput
                                    name="thirdPartyZip"
                                    label="Código postal"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Es necesario capturar el código postal',
                                        },
                                    ]}
                                />
                            </Col>
                            <Col xs={12}>
                                <SelectInput
                                    name="thirdPartyTaxRegimen"
                                    label="Régimen fiscal"
                                    options={taxRegimes}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Es necesario capturar el régimen fiscal',
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                    )}
                </>
            )}
            <Row justify="center" gutter={10} style={{ marginTop: '10px' }}>
                <Col xs={12}>
                    <DateInput
                        name="date"
                        label="Fecha"
                        defaultValue={dayjs()}
                        disabledDate={(currentDate: Dayjs) => {
                            return (
                                currentDate &&
                                (currentDate <= dayjs().add(-73, 'hours') || currentDate > dayjs().add(1, 'minute'))
                            )
                        }}
                    />
                </Col>
                <Col xs={12}>
                    <TextInput name="orderId" label="Número de orden (Order ID)" />
                </Col>
                <Col xs={24}>
                    <TextAreaInput name="notes" label="Agrega una nota a tu factura" />
                </Col>
            </Row>
            <Row justify="center" gutter={10} style={{ marginTop: '20px' }}>
                <Col xs={12}>
                    <ChipsSelect
                        name="relatedDocuments"
                        label="CFDI Relacionados"
                        placeholder="Pega los folios fiscales de los CFDI"
                        options={[]}
                        change={() => console.log([])}
                    />
                </Col>
                <Col xs={12}>
                    <SelectInput name="relationship" label="Tipo de relación" options={CFDIRelations} />
                </Col>
            </Row>
            {/* <Row justify="end" align="middle">
            <Button type="link" className="primary" style={{ padding: 0 }}>
                <PlusCircle className="primary" size={16} weight="regular" style={{ marginRight: '5px' }} />
                Agregar otra relación
            </Button>
        </Row> */}
        </div>
    )
}

export const InvoiceEmailsForm = () => {
    const { client } = useSelector((state: any) => state.data)
    const { team } = useSelector((state: any) => state.team)

    const availableEmails: any = [
        ...new Set(
            [...(client?.bcc || []), client?.email, ...(team?.contactEmails || [])].filter((email: any) => email),
        ),
    ].map((email: any) => {
        return {
            label: email,
            value: email,
        }
    })

    return (
        <ChipsSelect
            name="emails"
            label=""
            placeholder="Selecciona o agrega los correos"
            options={availableEmails}
            change={() => {}}
        />
    )
}

export const InvoiceCreatedView = () => {
    const { invoice, recurringEventData } = useSelector((state: any) => state.data)

    const returnTitleAndDescription = () => {
        if(recurringEventData?.id) {
            return {
                title: 'Recurrencia creada correctamente',
                description: 'La recurrencia ha sido programada correctamente.\nNo hemos enviado ningún correo',
            }
        }

        return {
            title: 'Factura creada correctamente',
            description: invoice?.emails?.length > 0 ? 'La factura ha enviado al siguiente destinatario:' : 'No hemos enviado ningún correo',
        }
    }

    return (
        <>
            <SuccessView resource={invoice ?? recurringEventData} resourceType={invoice?.uuid ? 'invoice' : 'recurringInvoice'} title={returnTitleAndDescription().title} description={returnTitleAndDescription().description} tags={invoice?.emails ?? {}}/>
            {/* <Result
                status="success"
                icon={
                    <CheckCircle weight="regular" size={64} color={tagsColors.find((t) => t.name === 'green')?.text} />
                }
                title={
                    <Typography.Text className="mainheader">
                        {isRecurring ? 'Recurrencia creada correctamente' : 'Factura creada correctamente'}
                    </Typography.Text>
                }
                subTitle={
                    <Typography.Text
                        style={{ whiteSpace: 'pre-wrap' }}
                        className="mediumparagraph descriptions"
                    >{`La factura ${inv?.series}-${
                        isRecurring ? '"x"' : (inv?.folio_number ?? '')
                    } (${invoiceUuid ?? ''}) ha sido ${isRecurring ? 'programada' : 'creada'} correctamente.\n ${
                        inv?.emails?.length > 0 ? `La enviaremos a: ${inv?.emails.join(', ')}` : ''
                    }`}</Typography.Text>
                }
                extra={[
                    <Space direction="vertical" size="large">
                        {!isRecurring && (
                            <Row justify="center">
                                <InvoiceActions invoice={inv} />
                            </Row>
                        )}
                        <Button
                            type="primary"
                            key="console"
                            onClick={() => {
                                // dispatch(closeModal('invoiceModalVisible'))
                                // dispatch(setData({ item: 'invoice', data: null }))
                                // dispatch(
                                //     setData({ item: 'recurringEventData', data: null })
                                // )
                                // dispatch()
                                dispatch(closeModal('invoiceModalVisible'))
                                dispatch(
                                    setElementSelected({
                                        element: 'recurringInvoice',
                                        data: false,
                                    }),
                                )
                                dispatch(
                                    setData({
                                        item: 'recurringInvoiceData',
                                        data: null,
                                    }),
                                )
                                dispatch(
                                    setData({
                                        item: 'invoice',
                                        data: null,
                                    }),
                                )
                                dispatch(
                                    setData({
                                        item: 'client',
                                        data: null,
                                    }),
                                )
                                dispatch(
                                    setData({
                                        item: 'selectedServices',
                                        data: [],
                                    }),
                                )
                                dispatch(closeModal('invoiceModalVisible'))
                                removeParamFromUrl('open')
                                removeParamFromUrl('invoiceType')
                                removeParamFromUrl('clientId')
                                removeParamFromUrl('services')
                            }}
                        >
                            Ir a facturas
                            <CaretDoubleRight size={15} style={{ marginLeft: '5px' }} className="" weight="regular" />
                        </Button>
                    </Space>,
                ]}
            /> */}
        </>
    )
}

export const InvoiceProductsServices = () => {
    return (
        <Row justify="center">
            <Col xs={24}>
                <Typography.Title className="bigparagraph" level={5}>
                    Productos / Servicios
                </Typography.Title>
                <ItemsSelection hideTitle={true} />
                <ProductsServicesTotals boxed={false} />
            </Col>
        </Row>
    )
}

export const GeneralInvoiceInfo = ({ state, setState, form }: { state: any; setState: any; form: FormInstance }) => {}

export const InvoiceEmailsComponent = () => {
    return (
        <Row justify="center">
            <Col xs={24}>
                <Typography.Title className="bigparagraph" level={5}>
                    Entrega
                </Typography.Title>
                <InvoiceEmailsForm />
            </Col>
        </Row>
    )
}

export const InvoiceAutomations = () => {
    return (
        <Row justify="center">
            <Col xs={24}>
                <Typography.Title className="bigparagraph" level={5}>
                    5. Automatizaciones disponibles
                </Typography.Title>
                <CheckBoxInput
                    name="automations"
                    label=""
                    content={
                        <Typography.Text>Solicitar pago: Crea un link de pago para cobrar a tu cliente</Typography.Text>
                    }
                />
            </Col>
        </Row>
    )
}

export const InvoiceTestMode = () => {
    const dispatch = useDispatch()

    return (
        <Row justify="center">
            <Col xs={24}>
                <Typography.Title className="bigparagraph" level={5}>
                    Factura en modo pruebas
                </Typography.Title>
                <Form.Item label="¿Deseas emitir la factura en modo pruebas?" valuePropName="checked" name="test">
                    <Switch
                        onChange={(testmode: boolean) => {
                            dispatch(
                                setData({
                                    item: 'testmode',
                                    data: testmode,
                                }),
                            )
                        }}
                    />
                </Form.Item>
            </Col>
        </Row>
    )
}

export const InvoiceMetadata = () => {
    return (
        <Row justify="center">
            <Col xs={24}>
                <Typography.Title className="bigparagraph" level={5}>
                    Metadatos
                </Typography.Title>
                <MetadataListInput name="metadata" />
            </Col>
        </Row>
    )
}
