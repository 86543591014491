import { Row, Space, Tag, Tooltip, Typography } from 'antd'
import { PaymentLink } from '../../../../interfaces/paymentsDef'
import { CopyableElementId } from '../../UI/CopyableElementId'
import moment from 'moment'
import { Robot } from '@phosphor-icons/react'
import { colors } from '../../../designSystem/colors'
import RelationInvoice from '../../Relations/RelationInvoiceWithPayments'
import { getItemsAmounts } from '../../../functions/helpers'
import PaymentsActions from '../../ElementActions/PaymentsActions'

export const PaymentLinkColumns = ({
    record,
    columnName,
    navigate,
}: {
    record: PaymentLink
    columnName: string
    navigate: Function
}) => {
    switch (columnName) {
        case 'id':
            const description = record.items.map((item) => item?.description || item?.name || '').join(', ')
            return (
                <Space direction="vertical">
                    <Typography.Text className="p-base-bold neutral-1">{description}</Typography.Text>
                    <CopyableElementId id={record?.id} idName="ID" />
                </Space>
            )
        case 'amount':
            const amounts = getItemsAmounts(record.items)
            return (
                <Row justify="end">
                    <Typography.Text onClick={() => {}} className="p-base-bold">
                        {' '}
                        {amounts.totalString} {(record?.currency ?? '').toUpperCase()}
                    </Typography.Text>
                </Row>
            )
        case 'status':
            const isActive = record?.status === 'active'
            return (
                <Tag color={isActive ? colors.green_100 : colors.background_darker}>
                    <Typography.Text
                        className="p-base-bold"
                        style={{ color: isActive ? colors.green_400 : colors.text_muted }}
                    >
                        {isActive ? 'Activo' : 'Inactivo'}
                    </Typography.Text>
                </Tag>
            )

        case 'timestamp':
            return (
                <Space direction="vertical" size={1}>
                    <Typography.Text className="p-base-bold">
                        {moment(record.timestamp).format('DD MMM YY HH:mm')}
                    </Typography.Text>
                </Space>
            )
        case 'views':
            return <Typography.Text>{record.views}</Typography.Text>

        case 'relations':
            return <RelationInvoice record={record} />

        case 'automations':
            const hasAutomations = (record.automations ?? []).length >= 1
            if (!hasAutomations) return <></>
            return (
                <Row align="middle" justify="center" style={{ width: '100%' }}>
                    {hasAutomations && (
                        <Tooltip title={'Pago automatizado'}>
                            <Robot
                                size={20}
                                weight="regular"
                                color={colors.text_muted}
                                style={{ display: 'flex', padding: 0 }}
                            />
                        </Tooltip>
                    )}
                </Row>
            )

        case 'actions':
            return <PaymentsActions payment={{ ...record, hide: false }} />
    }
}
