import React from 'react'
import { Modal, Typography, Button, Row, Col, Card, Tag } from 'antd'
import { Info } from '@phosphor-icons/react'

const { Title, Text } = Typography

interface FreePlansModalProps {
    isOpen: boolean
    onClose: () => void
    ids: {
        free: string
        freePlus: string
    }
    selectedFreePlan: (id: string) => void
    selectedToLoading: string | null
}

const FreePlansModal: React.FC<FreePlansModalProps> = ({
    isOpen,
    onClose,
    ids,
    selectedFreePlan,
    selectedToLoading,
}) => {
    // const benefits = [
    //     'Obtén más créditos',
    //     'Paga por evento cuando lo necesites',
    //     'No se te cobrará nada',
    //     'Sin interrupciones de tu servicio',
    // ]

    const planComparison = [
        { title: '', withoutCard: '3 eventos', withCard: '20 eventos' },
        { title: '', withoutCard: 'Sin eventos adicionales', withCard: 'Adicional: $10 MXN' },
        { title: '', withoutCard: '10 clientes', withCard: '10 clientes' },
        { title: '', withoutCard: '1 integración', withCard: '1 integración' },
    ]

    return (
        <Modal title="" open={isOpen} onCancel={onClose} footer={null} width={600}>
            <Title level={3}>
                Añade tu tarjeta y obtén más beneficios del{' '}
                <span style={{ textDecoration: 'underline' }}>plan gratis</span>
            </Title>
            {/* <List
                dataSource={benefits}
                renderItem={(item) => (
                    <List.Item>
                        <CheckOutlined style={{ color: 'green', marginRight: 8 }} />
                        {item}
                    </List.Item>
                )}
            /> */}
            <Row gutter={16} style={{ marginTop: 24 }}>
                <Col span={12} style={{ display: 'flex' }}>
                    <Card
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            border: 'none',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            borderRadius: '10px',
                        }}
                    >
                        <Title level={5} style={{ margin: 0, padding: 0, marginBottom: '10px', marginTop: '33px' }}>
                            SIN TARJETA
                        </Title>
                        <div style={{ flex: 1 }}>
                            {planComparison.map((item, index) => (
                                <Row
                                    key={index}
                                    align="middle"
                                    style={{
                                        gap: 10,
                                        marginTop: '5px',
                                    }}
                                >
                                    <Info size={15} />
                                    <Text className="smallparagraph">{item.withoutCard}</Text>
                                </Row>
                            ))}
                        </div>
                        <Button
                            loading={selectedToLoading === ids.free}
                            style={{ marginTop: 16 }}
                            className="btn-outline"
                            block
                            onClick={() => selectedFreePlan(ids.free)}
                        >
                            Continuar sin tarjeta
                        </Button>
                    </Card>
                </Col>
                <Col span={12} style={{ display: 'flex' }}>
                    <Card
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            border: 'none',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            borderRadius: '10px',
                        }}
                    >
                        <Row justify="center">
                            <Tag
                                style={{
                                    backgroundColor: '#A9FE9B',
                                    color: '#044737',
                                    fontWeight: 'bold',
                                    border: 'none',
                                }}
                            >
                                Recomendado
                            </Tag>
                        </Row>
                        <Title level={5} style={{ margin: 0, padding: 0, marginBottom: '10px', marginTop: '10px' }}>
                            CON TARJETA
                        </Title>
                        <div style={{ flex: 1 }}>
                            {planComparison.map((item, index) => (
                                <Row
                                    key={index}
                                    align="middle"
                                    style={{
                                        gap: 10,
                                        marginTop: '5px',
                                    }}
                                >
                                    <Info size={15} />
                                    <Text className="smallparagraph">{item.withCard}</Text>
                                </Row>
                            ))}
                        </div>
                        <Button
                            loading={selectedToLoading === ids.freePlus}
                            type="primary"
                            className="btn-pricing-plans"
                            style={{ marginTop: 16 }}
                            block
                            onClick={() => selectedFreePlan(ids.freePlus)}
                        >
                            Continuar con tarjeta
                        </Button>
                    </Card>
                </Col>
            </Row>
        </Modal>
    )
}

export default FreePlansModal
