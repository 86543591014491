import React from 'react'
import { Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'

const CannyModalWidget: React.FC = () => {
    const { openCanny } = useSelector((state: any) => state.modals)
    const dispatch = useDispatch()

    return (
        <Modal open={openCanny} onCancel={() => dispatch(closeModal('openCanny'))}>
            <div data-canny></div>
        </Modal>
    )
}

export default CannyModalWidget
