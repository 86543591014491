import React, { useEffect, useState } from 'react'
import { FormInstance, Col, Row, Form, Button, message, Switch, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { MetadataListInput, SelectInput, TextAreaInput, TextInput } from './Inputs'
import { generateCode, HandleMetadataInput } from '../../functions/helpers'
import moment from 'moment'
import { setTeam } from '../../context/teamsSlice'
import { arrayUnion, doc, getFirestore, updateDoc } from 'firebase/firestore'
import { closeModal } from '../../context/modalsSlice'

interface PropsDef {
    incomingForm?: FormInstance
    buttonTitle?: string
    preloadedWebhookData?: any
}

const WebooksCreateForm: React.FC<PropsDef> = ({ incomingForm, buttonTitle, preloadedWebhookData }) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const usingForm = incomingForm ?? form
    const [messageApi, contextHolder] = message.useMessage()
    const { team } = useSelector((state: any) => state.team)
    const { user } = useSelector((state: any) => state.user)

    const [sendCustomHeaders, setSendCustomHeaders] = useState(preloadedWebhookData?.security ?? false)
    const [regenerateSecret, setRegenerateSecret] = useState(!preloadedWebhookData?.security?.secretCreated ?? true)

    const availableEvents = [
        { label: 'payment.created', value: 'payment.created' }, // check
        { label: 'payment.updated', value: 'payment.updated' }, // check
        { label: 'payment.succeeded', value: 'payment.succeeded' }, // check
        { label: 'payment.canceled', value: 'payment.canceled' }, // check
        { label: 'payment.deleted', value: 'payment.deleted' }, // check
        { label: 'payment.upcoming_due_date', value: 'payment.upcoming_due_date' }, // check
        { label: 'invoice.created', value: 'invoice.created' }, // check
        { label: 'invoice.canceled', value: 'invoice.canceled' }, // check
        { label: 'invoice.failed', value: 'invoice.failed' }, // check
        { label: 'receipt.created', value: 'receipt.created' }, // check
        { label: 'receipt.updated', value: 'receipt.updated' }, // check
        { label: 'receipt.completed', value: 'receipt.completed' }, // check
        { label: 'receipt.deleted', value: 'receipt.deleted' }, // check
        { label: 'client.created', value: 'customer.created' }, // check
        { label: 'client.updated', value: 'customer.updated' }, // check
        { label: 'client.deleted', value: 'customer.deleted' }, // check
    ]

    if (team.isMasterTeam) {
        availableEvents.push(
            { label: 'teams.created', value: 'teams.created' }, // check
            { label: 'teams.sat.completed', value: 'teams.sat.completed' }, // check
            { label: 'teams.stripe.completed', value: 'teams.stripe.completed' }, // check
            { label: 'teams.openpay.completed', value: 'teams.openpay.completed' }, // check
        )
    }

    const onFinish = async (values: any) => {
        try {
            console.log("VALUES: ", values)
            console.log("PRELOADED: ", preloadedWebhookData)
            await usingForm.validateFields()
            messageApi.open({
                type: 'loading',
                content: 'Actualizando información...',
                duration: 0,
            })
            let webhooks = [...(team.webhooks ?? [])]

            const webhook = {
                id: preloadedWebhookData?.id || generateCode(8, 'wh'),
                ...values,
                created: preloadedWebhookData?.created || moment().valueOf(),
                owner: user?.uid,
                status: 'active',
            }   

            if(preloadedWebhookData?.security?.headers) {
                webhook.security = preloadedWebhookData.security
            }

            if(webhook?.headers) {
                delete webhook.headers
            }

            if (sendCustomHeaders && values.headers && regenerateSecret) {
                const headers = HandleMetadataInput({ metadataValue: values.headers })
                webhook.security = {
                    secretCreated: !regenerateSecret,
                    headers: headers.metadata,
                }
            }

            if (preloadedWebhookData?.id) {
                webhooks = webhooks.map(w => {
                    if (preloadedWebhookData.id === w.id) {
                        return {
                            ...w,
                            ...webhook
                        }
                    }
                    return {
                        ...w
                    }
                })
            } else {
                webhooks.push(webhook)
            }

            dispatch(setTeam({ ...team, webhooks }))
            await updateDoc(doc(getFirestore(), 'teams', team.id), {
                webhooks,
                logs: arrayUnion({
                    date: moment().valueOf(),
                    description: 'Se creó el webhook ' + values.url,
                    user: user.uid,
                    value: 'created',
                }),
            })
            message.success('Se actualizó la información correctamente.')
            usingForm.resetFields()

            dispatch(closeModal('editWebhooksModalVisible'))
            dispatch(closeModal('settingsDrawerVisible'))
        } catch (e) { }
    }

    useEffect(() => {
        usingForm.setFieldsValue({
            url: preloadedWebhookData?.url,
            description: preloadedWebhookData?.description,
            events: preloadedWebhookData?.events,
            headers: Object.keys(preloadedWebhookData?.security?.headers || {}).map(k => {
                return {
                    key: k,
                    value: '******'//preloadedWebhookData?.security?.headers[k] 
                }
            })
        })
        setRegenerateSecret(!preloadedWebhookData?.security?.secretCreated ?? true)
        //eslint-disable-next-line
    }, [preloadedWebhookData?.id])

    return (
        <Form layout="vertical" form={usingForm} onFinish={onFinish}>
            {/* Form content goes here */}
            {contextHolder}
            <Row>
                <Col xs={24}>
                    <TextInput
                        rules={[
                            { required: true, message: 'Ingresa una URL' },
                            {
                                type: 'url',
                                message: 'Ingresa una URL válida',
                            },
                        ]}
                        name="url"
                        label="URL"
                        placeholder="https://..."
                        initialValue={preloadedWebhookData && preloadedWebhookData?.url}
                    />
                </Col>
                <Col xs={24}>
                    <TextAreaInput
                        name="description"
                        label="Descripción"
                        placeholder="Descripción"
                        maxRows={1}
                        minRows={1}
                        maxLength={100}
                        showCount={true}
                        defaultValue={preloadedWebhookData && preloadedWebhookData?.description}

                    />
                </Col>
                <Col xs={24}>
                    <SelectInput
                        rules={[{ required: true, message: 'Selecciona al menos un evento' }]}
                        name="events"
                        label="Eventos"
                        placeholder="Selecciona al menos un evento"
                        mode="multiple"
                        options={availableEvents}
                        defaultValue={preloadedWebhookData && preloadedWebhookData?.events}
                    />
                </Col>
                <Col xs={24} md={24} xl={24}>
                    <Form.Item label="Enviar headers de autenticación" valuePropName="checked" name="security">
                        <Switch
                            defaultChecked={preloadedWebhookData?.security ? true : false}
                            onChange={(v) => {
                                setSendCustomHeaders(v)
                            }} />
                    </Form.Item>
                    {sendCustomHeaders && <MetadataListInput
                        disabled={!regenerateSecret}
                        name='headers'
                        onChange={(v: any) => {
                            setRegenerateSecret(true)
                        }}
                        initialValue={preloadedWebhookData?.headers}
                    />}
                    <Typography.Text className="smallparagraph" style={{ marginBottom: '15px' }}>
                        Si agregas un nuevo header debes eliminar los creados previamente.
                    </Typography.Text>
                </Col>

                <Col span={24}>
                    <Row justify="end">
                        <Button type="primary" htmlType="submit">
                            {buttonTitle ?? 'Actualizar información'}
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Form>
    )
}

export default WebooksCreateForm
