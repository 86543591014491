/**
 * List of available currencies on gigstack
 * @constant
 * @type {Array}
 * @default
 */
export const currecies = [
    { label: 'MXN - Pesos Mexicanos', value: 'MXN' },
    { label: 'USD - Dólares estadounidenses', value: 'USD' },
    { label: 'EUR - Euros', value: 'EUR' },
    { label: 'BRL - Reales brasileños', value: 'BRL' },
    { label: 'CAD - Dólares canadienses', value: 'CAD' },
    { label: 'ARS - Pesos Argentinos', value: 'ARS' },
    { label: 'COP - Pesos Colombianos', value: 'COP' },
    { label: 'CLP - Pesos Chilenos', value: 'CLP' },
    { label: 'PEN - Soles Peruanos', value: 'PEN' },
    { label: 'UYU - Pesos Uruguayos', value: 'UYU' },
    { label: 'BOB - Bolivianos', value: 'BOB' },
    { label: 'CRC - Colones Costarricenses', value: 'CRC' },
]

export const curreciesSmallLabel = [
    { label: 'MXN', value: 'MXN' },
    { label: 'USD', value: 'USD' },
    { label: 'EUR', value: 'EUR' },
    { label: 'BRL', value: 'BRL' },
    { label: 'CAD', value: 'CAD' },
    { label: 'ARS', value: 'ARS' },
    { label: 'COP', value: 'COP' },
    { label: 'CLP', value: 'CLP' },
    { label: 'PEN', value: 'PEN' },
    { label: 'UYU', value: 'UYU' },
    { label: 'BOB', value: 'BOB' },
    { label: 'CRC', value: 'CRC' },
]
