import { Button, Col, FormInstance, Modal, Row, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
import CreateClientForm from '../components/Clients/CreateClientForm'
import { CaretDoubleRight, X } from '@phosphor-icons/react'
import { setData } from '../context/dataSlice'
import { removeParamFromUrl } from '../functions/UrlParams'
import { useEffect } from 'react'

const CreateClientModal = () => {
    const dispatch = useDispatch()
    const { createClientModalVisible } = useSelector((state: any) => state.modals)
    const { client } = useSelector((state: any) => state.data)

    var receivedForm: FormInstance

    useEffect(() => {
        if(createClientModalVisible && !client) {
            receivedForm?.resetFields()
        }
        // eslint-disable-next-line
    }, [createClientModalVisible, client])

    return (
        <Modal
            title={
                <div style={{ position: 'relative', paddingBottom: '15px' }}>
                    <Typography.Text
                        className="smallheader"
                        style={{
                            position: 'absolute',
                            //center the text
                            left: '50%',
                            transform: 'translateX(-50%)',
                            paddingTop: '5px',
                        }}
                    >
                        {client ? 'Editar cliente' : 'Crear nuevo cliente'}
                    </Typography.Text>
                    <Row>
                        <Col xs={24} style={{ margin: 'auto' }}>
                            <Row justify="space-between" align="middle">
                                <X
                                    className="icon clickable"
                                    size={20}
                                    onClick={() => {
                                        removeParamFromUrl('open')
                                        dispatch(closeModal('createClientModalVisible'))
                                        dispatch(
                                            setData({
                                                item: 'client',
                                                data: null,
                                            }),
                                        )
                                    }}
                                />
                                <></>
                                <Button
                                    type="primary"
                                    onClick={(e) => {
                                        receivedForm?.submit()
                                    }}
                                >
                                    {client ? 'Guardar cambios' : 'Crear cliente'}
                                    <CaretDoubleRight
                                        size={15}
                                        style={{ marginLeft: '5px' }}
                                        className=""
                                        weight="regular"
                                    />
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </div>
            }
            closeIcon={<></>}
            width={window.innerWidth > 768 ? '60%' : '90%'}
            style={{ top: 15 }}
            open={createClientModalVisible}
            onCancel={() => {
                removeParamFromUrl('open')
                dispatch(closeModal('createClientModalVisible'))

                dispatch(
                    setData({
                        item: 'client',
                        data: null,
                    }),
                )
            }}
            footer={null}
            zIndex={1001}
        >
            <div
                style={{
                    maxHeight: '80vh',
                    overflowY: 'auto',
                    padding: '0px 10px',
                }}
            >
                {createClientModalVisible && <CreateClientForm
                    passForm={(fn: FormInstance) => {
                        receivedForm = fn
                    }}
                    closeElement="createClientModalVisible"
                    hideTitle={true}
                    useClient={false}
                />}
            </div>
        </Modal>
    )
}

export default CreateClientModal
