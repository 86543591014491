import { collection, getFirestore, orderBy, query, where } from 'firebase/firestore'
import { useSelector } from 'react-redux'
import { useFirestoreCollectionData } from 'reactfire'
import { OptimizedInvoiceDef } from '../../interfaces/invoicesDef'
import { OptimizedPaymentDef } from '../../interfaces/paymentsDef'
import { OptimizedReceiptDef } from '../../interfaces/receiptsDef'

export const useRelations = ({
    relatedToCollection = 'invoices',
    relatedToId = 'payment id',
    searchInKey = 'payments',
    relationIds = [],
    def,
}: {
    relatedToCollection: string
    relatedToId: string
    searchInKey: string
    relationIds?: string[]
    def: (object: any) => OptimizedInvoiceDef | OptimizedPaymentDef | OptimizedReceiptDef
}) => {
    const { team } = useSelector((state: any) => state.team)

    const fs = getFirestore()

    let collectionQueryRef = query(
        collection(fs, relatedToCollection),
        where('team', '==', team?.id ?? ''),
        where(searchInKey, 'array-contains-any', [relatedToId]),
        orderBy('timestamp', 'desc'),
    )

    if (searchInKey === 'recurringInvoices' || searchInKey === 'recurringPayments') {
        collectionQueryRef = query(
            collection(fs, relatedToCollection),
            where('team', '==', team?.id ?? ''),
            where('id', 'in', (relationIds ?? []).slice(-30)),
            orderBy('timestamp', 'desc'),
        )
    }

    var { status, data, error, isComplete, hasEmitted } = useFirestoreCollectionData(collectionQueryRef, {
        idField: 'fid',
    })

    return { status, data: (data ?? []).map((i) => def(i ?? {})), error, isComplete, hasEmitted }
}
