import { Col, Divider, Radio, Row } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import DynamicTabs from '../Menu/Tabs';
import {
    AppWindow,
    BellRinging,
    ChatTeardropDots,
    ClockAfternoon,
    CurrencyDollar,
    GlobeSimple,
    PaperPlane,
    Percent,
    Receipt,
} from '@phosphor-icons/react'
import SettingsDivider from './Components/SettingsDivider'
import { SettingCard } from './Components/SettingCard'
import { setData } from '../../context/dataSlice'
import { openModal } from '../../context/modalsSlice'
import { addParamToUrl } from '../../functions/UrlParams'

const InvoicesDefaults = () => {
    const dispatch = useDispatch()

    return (
        <Row
            gutter={{
                xs: 10,
                md: 12,
            }}
        >
            <Col xs={24}>
                {' '}
                <Divider />{' '}
            </Col>

            <SettingsDivider
                title={'Automatización'}
                description={'Configura los valores que utilizaremos para automatizar los eventos.'}
                options={[
                    <SettingCard
                        title="Configura las facturas automáticas"
                        description="La información predeterminada de tus facturas para las automatizaciones"
                        icon={<CurrencyDollar size={20} color="#6B7CF9" weight="regular" />}
                        onClick={() => {
                            dispatch(
                                setData({
                                    item: 'settingsDrawerConfig',
                                    data: {
                                        type: 'defaults.invoice',
                                        title: 'Configura las facturas automáticas',
                                        description:
                                            'La información predeterminada de tus facturas para las automatizaciones',
                                    },
                                }),
                            )
                            dispatch(openModal('settingsDrawerVisible'))
                            addParamToUrl('open', 'settings_automatic_invoices')
                        }}
                    />,
                    <SettingCard
                        title="Configura los impuestos"
                        description="Los impuestos para facturas nacionales e internacionales"
                        icon={<Percent size={20} color="#6B7CF9" weight="regular" />}
                        onClick={() => {
                            dispatch(
                                setData({
                                    item: 'settingsDrawerConfig',
                                    data: {
                                        type: 'defaults.taxes',
                                        title: 'Configura los impuestos',
                                        description: 'Los impuestos predefinidos de la cuenta.',
                                    },
                                }),
                            )
                            dispatch(openModal('settingsDrawerVisible'))
                            addParamToUrl('open', 'settings_taxes')
                        }}
                    />,
                    <SettingCard
                        title="Configura la factura global"
                        description="Para las conciliaciones automáticas a final de un periodo"
                        icon={<GlobeSimple size={20} color="#6B7CF9" weight="regular" />}
                        onClick={() => {
                            dispatch(
                                setData({
                                    item: 'settingsDrawerConfig',
                                    data: {
                                        type: 'defaults.globalInvoice',
                                        title: 'Configura la factura global',
                                        description: 'Para las conciliaciones automáticas a final de un periodo',
                                    },
                                }),
                            )
                            dispatch(openModal('settingsDrawerVisible'))
                            addParamToUrl('open', 'settings_global_invoice')
                        }}
                    />,
                ]}
            />

            <Col xs={24}>
                {' '}
                <Divider />{' '}
            </Col>

            <SettingsDivider
                title={'Opcionales'}
                description={'Agrega componentes extras a tus facturas.'}
                options={[
                    <SettingCard
                        title="Personaliza tus facturas"
                        description="Agrega una sección de mensaje personalizada para tus clientes en el pdf de tus facturas"
                        icon={<ChatTeardropDots size={20} color="#6B7CF9" weight="regular" />}
                        onClick={() => {
                            dispatch(
                                setData({
                                    item: 'settingsDrawerConfig',
                                    data: {
                                        type: 'defaults.invoicePDFExtra',
                                        title: 'Personaliza tus facturas',
                                        description:
                                            'Agrega una sección de mensaje personalizada para tus clientes en el pdf de tus facturas',
                                    },
                                }),
                            )
                            dispatch(openModal('settingsDrawerVisible'))
                            addParamToUrl('open', 'settings_invoices_personalization')
                        }}
                    />,
                    <SettingCard
                        title="Addenda"
                        description="Se incorpora en el CFDI en su formato XML y se agregará al PDF."
                        icon={<AppWindow size={20} color="#6B7CF9" weight="regular" />}
                        onClick={() => {
                            dispatch(
                                setData({
                                    item: 'settingsDrawerConfig',
                                    data: {
                                        type: 'defaults.addenda',
                                        title: 'Addenda',
                                        description: 'Se incorpora en el CFDI en su formato XML y se agregará al PDF.',
                                    },
                                }),
                            )
                            dispatch(openModal('settingsDrawerVisible'))
                            addParamToUrl('open', 'settings_addenda')
                        }}
                    />,
                ]}
            />

            <Col xs={24}>
                {' '}
                <Divider />{' '}
            </Col>
            <SettingsDivider
                title={'Mensajería'}
                description={'Configura los mensajes que le llegan a tus clientes.'}
                options={[
                    <SettingCard
                        title="Correo electrónico"
                        description="Configura los correos que le llegan a tus clientes con sus facturas"
                        icon={<PaperPlane size={20} color="#6B7CF9" weight="regular" />}
                        onClick={() => {
                            dispatch(
                                setData({
                                    item: 'settingsDrawerConfig',
                                    data: {
                                        type: 'defaults.invoiceMessage',
                                        title: 'Plantilla de correo electrónico',
                                        description:
                                            'Configura el mensaje que reciben tus clientes al recibir una nueva factura.',
                                    },
                                }),
                            )
                            dispatch(openModal('settingsDrawerVisible'))
                            addParamToUrl('open', 'settings_invoices_emails')
                        }}
                    />,
                ]}
            />
        </Row>
    )
}

const PaymentsDefaults = () => {
    const dispatch = useDispatch()

    return (
        <Row
            gutter={{
                xs: 10,
                md: 12,
            }}
        >
            <Col xs={24}>
                {' '}
                <Divider />{' '}
            </Col>

            <SettingsDivider
                title={'Automatización'}
                description={'Configura los valores que utilizaremos para automatizar los eventos.'}
                options={[
                    <SettingCard
                        title="Días límite de pago"
                        description="Los días límite de pago para los pagos abiertos, enviaremos notificaciones y recordatorios"
                        icon={<ClockAfternoon size={20} color="#6B7CF9" weight="regular" />}
                        onClick={() => {
                            dispatch(
                                setData({
                                    item: 'settingsDrawerConfig',
                                    data: {
                                        type: 'defaults.limitDaysToPay',
                                        title: 'Días límite de pago',
                                        description:
                                            'Los días límite de pago para los pagos abiertos, enviaremos notificaciones y recordatorios',
                                    },
                                }),
                            )
                            dispatch(openModal('settingsDrawerVisible'))
                            addParamToUrl('open', 'settings_limit_days')
                        }}
                    />,
                    <SettingCard
                        title="Recordatorios de pago"
                        description="Configura los recordatorios de pago basado en los días límite"
                        icon={<BellRinging size={20} color="#6B7CF9" weight="regular" />}
                        onClick={() => {
                            dispatch(
                                setData({
                                    item: 'settingsDrawerConfig',
                                    data: {
                                        type: 'defaults.paymentsReminderDays',
                                        title: 'Recordatorios de pago',
                                        description: 'Configura los recordatorios de pago basado en los días límite',
                                    },
                                }),
                            )
                            dispatch(openModal('settingsDrawerVisible'))
                            addParamToUrl('open', 'settings_payments_reminders')
                        }}
                    />,
                ]}
            />

            <SettingsDivider
                title={'Mensajería'}
                description={'Configura los mensajes que le llegan a tus clientes.'}
                options={[
                    <SettingCard
                        title="Correo electrónico"
                        description="Configura los correos que le llegan a tus clientes con sus facturas"
                        icon={<PaperPlane size={20} color="#6B7CF9" weight="regular" />}
                        onClick={() => {
                            dispatch(
                                setData({
                                    item: 'settingsDrawerConfig',
                                    data: {
                                        type: 'defaults.paymentsEmailsTemplate',
                                        title: 'Plantilla de correo electrónico',
                                        description:
                                            'Configura el mensaje que reciben tus clientes al recibir un nuevo pago.',
                                    },
                                }),
                            )
                            dispatch(openModal('settingsDrawerVisible'))
                            addParamToUrl('open', 'settings_payments_emails')
                        }}
                    />,
                ]}
            />
        </Row>
    )
}

const SalesReceiptsDefaults = () => {
    const dispatch = useDispatch()

    return (
        <Row
            gutter={{
                xs: 10,
                md: 12,
            }}
        >
            <Col xs={24}>
                {' '}
                <Divider />{' '}
            </Col>

            <SettingsDivider
                title={'Automatización'}
                description={'Configura los valores que utilizaremos para automatizar los eventos.'}
                options={[
                    <SettingCard
                        title="Configura los recibos de venta"
                        description="Información y ajustes de los recibos de venta y portal de auto-factura."
                        icon={<Receipt size={20} color="#6B7CF9" weight="regular" />}
                        onClick={() => {
                            dispatch(
                                setData({
                                    item: 'settingsDrawerConfig',
                                    data: {
                                        type: 'salesReceipts',
                                        title: 'Recibos de venta y portal de auto-factura.',
                                        description:
                                            'Información y ajustes de los recibos de venta y portal de auto-factura.',
                                    },
                                }),
                            )
                            dispatch(openModal('settingsDrawerVisible'))
                            addParamToUrl('open', 'settings_receipts')
                        }}
                    />,
                ]}
            />

            <Col xs={24}>
                {' '}
                <Divider />{' '}
            </Col>

            <SettingsDivider
                title={'Mensajería'}
                description={'Configura los mensajes que le llegan a tus clientes.'}
                options={[
                    <SettingCard
                        title="Correo electrónico"
                        description="Configura los correos que le llegan a tus clientes con sus facturas"
                        icon={<PaperPlane size={20} color="#6B7CF9" weight="regular" />}
                        onClick={() => {
                            dispatch(
                                setData({
                                    item: 'settingsDrawerConfig',
                                    data: {
                                        type: 'defaults.receiptsEmailsTemplate',
                                        title: 'Plantilla de correo electrónico',
                                        description:
                                            'Configura el mensaje que reciben tus clientes al recibir un nuevo recibo de venta.',
                                    },
                                }),
                            )
                            dispatch(openModal('settingsDrawerVisible'))
                            addParamToUrl('open', 'settings_receipts_emails')
                        }}
                    />,
                ]}
            />
        </Row>
    )
}

const DefaultsSettings: React.FC = () => {
    const dispatch = useDispatch()
    const { subtab } = useSelector((state: any) => state.data)

    return (
        <div style={{ marginTop: '15px' }}>
            <Radio.Group
                value={subtab ?? 'invoicing'}
                onChange={(v: any) => {
                    // setSelectedSubtab(v.target.value)
                    addParamToUrl('subtab', v.target.value)
                    dispatch(setData({ item: 'subtab', data: v.target.value }))
                }}
                defaultValue={subtab}
                style={{ marginBottom: 16 }}
            >
                <Radio.Button value="invoicing" className="customRButton">
                    Facturación
                </Radio.Button>
                <Radio.Button value="payments" className="customRButton">
                    Pagos
                </Radio.Button>
                <Radio.Button value="receipts" className="customRButton">
                    Recibos de venta
                </Radio.Button>
            </Radio.Group>

            {(subtab === 'invoicing' || !subtab) && <InvoicesDefaults />}
            {subtab === 'payments' && <PaymentsDefaults />}
            {subtab === 'receipts' && <SalesReceiptsDefaults />}
        </div>
    )
}

export default DefaultsSettings
