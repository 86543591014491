import { Table } from 'antd'
import { OptimizedInvoiceDef, optimizedInvoiceDefSet } from '../../../interfaces/invoicesDef'
import { OptimizedPaymentDef, optimizedPaymentDefSet } from '../../../interfaces/paymentsDef'
import { OptimizedReceiptDef, optimizedReceiptDefSet } from '../../../interfaces/receiptsDef'

import { InvoicesColumnsObject, PaymentColumnsObject, ReceiptsColumnsObject } from '../Tables/ColumnsSettlers'
import { useNavigate } from 'react-router-dom'

interface RelationsTableProps {
    type: 'payments' | 'invoices' | 'receipts'
    items: (OptimizedInvoiceDef | OptimizedPaymentDef | OptimizedReceiptDef)[]
}

const RelationsTableInvoices = ({ items }: { items: OptimizedInvoiceDef[] }) => {
    const navigate = useNavigate()
    return (
        <Table
            scroll={{ x: 1000 }}
            dataSource={items}
            columns={InvoicesColumnsObject({
                navigate: (id: string) => navigate(id),
            })}
        />
    )
}

const RelationsTablePayments = ({ items }: { items: OptimizedPaymentDef[] }) => {
    const navigate = useNavigate()
    return (
        <Table
            scroll={{ x: 1200 }}
            pagination={{
                hideOnSinglePage: true,
            }}
            dataSource={items}
            columns={PaymentColumnsObject({
                navigate: (id: string) => navigate(id),
            })}
        />
    )
}

const RelationsTableReceipts = ({ items }: { items: OptimizedReceiptDef[] }) => {
    const navigate = useNavigate()
    return (
        <Table
            pagination={{
                hideOnSinglePage: true,
            }}
            scroll={{ x: 1000 }}
            dataSource={items}
            columns={ReceiptsColumnsObject({ customKeysToShow: null, navigate: (id: string) => navigate(id) })}
        />
    )
}

export const RelationsTable: React.FC<RelationsTableProps> = ({ type, items }) => {
    const handleTableToRender = (type: 'payments' | 'invoices' | 'receipts') => {
        switch (type) {
            case 'invoices':
                return <RelationsTableInvoices items={items.map((i) => optimizedInvoiceDefSet(i))} />
            case 'payments':
                return <RelationsTablePayments items={items.map((i) => optimizedPaymentDefSet(i))} />
            case 'receipts':
                return <RelationsTableReceipts items={items.map((i) => optimizedReceiptDefSet(i))} />
        }
    }

    return <div>{handleTableToRender(type)}</div>
}
