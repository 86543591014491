import { Button, DatePicker, Form, Modal, Row, Table, Tag, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
import { setData } from '../context/dataSlice'
import { ArrowCircleRight, CalendarBlank, CheckCircle, CircleDashed, ClockAfternoon } from '@phosphor-icons/react'
import dayjs from 'dayjs'
import { collection, doc, getDoc, getDocs, getFirestore, query, where } from 'firebase/firestore'
import _ from 'lodash'
import { paymentStatusColor, readablePaymentStatus } from '../../helpers/statusHelpers'
import { optimizedPaymentDefSet } from '../../interfaces/paymentsDef'
import { useState } from 'react'
import { returnCurrencyValue } from '../functions/helpers'

const PaymentConcilliationModal: React.FC = () => {
    const dispatch = useDispatch()
    const { paymentConcilliationModalVisible } = useSelector((state: any) => state.modals)
    const { conciliatePaymentsData } = useSelector((state: any) => state.data)
    const { team } = useSelector((state: any) => state.team)

    const [data, setdata] = useState<any[]>([])
    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        dispatch(closeModal('paymentConcilliationModalVisible'))
        dispatch(setData({ conciliatePaymentsData: {} }))
    }

    const conciliateProcess = async () => {
        for (const p of data) {
            await new Promise((resolve) => setTimeout(resolve, 100))

            //CHANGE STATUS OF EACH PAYMENT TO localConciliatedRunned = true
            const invoices: any[] = []
            const receipts: any[] = []

            //GET INVOICES AND RECEIPTS
            for (const i of p?.invoices ?? []) {
                if (!i) continue
                const dataQuery = await getDoc(doc(getFirestore(), 'invoices', i))
                if (dataQuery.exists()) {
                    invoices.push(dataQuery.data())
                }
            }
            for (const r of p?.receipts ?? []) {
                if (!r) continue
                const dataQuery = await getDoc(doc(getFirestore(), 'receipts', r))
                if (dataQuery.exists()) {
                    receipts.push(dataQuery.data())
                }
            }

            setdata((prevData) =>
                prevData.map((d) =>
                    d.id === p.id
                        ? {
                              ...d,
                              localConciliatedRunned: true,
                              invoicesR: invoices,
                              receiptsR: receipts,
                              invoiceTotals: invoices?.map((i: any) => i?.total).reduce((a: any, b: any) => a + b, 0),
                              receiptsTotals: receipts?.map((i: any) => i?.total).reduce((a: any, b: any) => a + b, 0),
                              conciliated:
                                  Number(p?.amount / 100).toFixed(0) ===
                                  Number(
                                      invoices?.map((i: any) => i?.total).reduce((a: any, b: any) => a + b, 0),
                                  ).toFixed(0),
                          }
                        : d,
                ),
            )
        }
    }

    const handleFinish = async (v: any) => {
        setLoading(true)
        const dates = v.dates
        const dataQuery = query(
            collection(getFirestore(), 'payments'),
            where('team', '==', team.id),
            where('timestamp', '>=', dates[0].valueOf()),
            where('timestamp', '<=', dates[1].valueOf()),
        )
        const rawD = await getDocs(dataQuery)
        const data = rawD.docs.map((d: any) => {
            return { ...d.data(), localConciliated: false, localConciliatedRunned: false }
        })

        setdata(data)
        setLoading(false)

        dispatch(
            setData({
                item: 'conciliatePaymentsData',
                data: { ...conciliatePaymentsData, started: true, loading: true, data: data },
            }),
        )

        if (data.length > 0) {
            // conciliateProces();
        }
    }

    return (
        <Modal
            maskClosable={false}
            width="80%"
            style={{ top: 10 }}
            title="Conciliar pagos"
            footer={null}
            open={paymentConcilliationModalVisible}
            onCancel={handleClose}
        >
            <Form layout="vertical" onFinish={handleFinish}>
                <Form.Item label="Fechas de conciliación" name="dates">
                    <DatePicker.RangePicker
                        style={{ width: '100%' }}
                        suffixIcon={<CalendarBlank size={17} weight="regular" className="icon" />}
                        format="DD MMM YYYY"
                        onCalendarChange={(a: any) => {
                            if (!a) {
                                return
                            }
                            if (a.length < 2) {
                                return
                            }
                            if (a[0] && a[1]) {
                            }
                        }}
                        onChange={(a: any) => {
                            if (!a) {
                                dispatch(
                                    setData({
                                        item: 'conciliatePaymentsData',
                                        data: { ...conciliatePaymentsData, dates: null, started: false },
                                    }),
                                )
                                setdata([])
                                setLoading(false)
                            } else
                                dispatch(
                                    setData({
                                        item: 'conciliatePaymentsData',
                                        data: { ...conciliatePaymentsData, dates: a },
                                    }),
                                )
                        }}
                        // disabledDate={(c: any) => {
                        // ANY DATE BEFORE TODAY, WHEN I SELECT ONE DATE I CAN'T SELECT 31 DAYS AFTER THE INITIAL DATE. ALSO DATES IN THE FUTURE ARE NOT ALLOWED
                        // if (c > moment()) {
                        //     return true
                        // }
                        // if (c < moment().subtract(60, 'd')) {
                        //     return true
                        // }
                        // return false

                        // }}
                        presets={[
                            { label: 'Periodo actual', value: [dayjs().startOf('month'), dayjs()] },
                            {
                                label: 'Mes anterior',
                                value: [
                                    dayjs().add(-1, 'month').startOf('month'),
                                    dayjs().add(-1, 'month').endOf('month'),
                                ],
                            },
                            { label: '2 meses', value: [dayjs().add(-2, 'month').startOf('month'), dayjs()] },
                        ]}
                    />
                </Form.Item>

                {data.length > 0 && (
                    <Row style={{ marginBottom: '10px' }} align="middle" justify="space-between">
                        <div className="d-flex flex-column">
                            <Row wrap>
                                {Object.keys(_.groupBy(conciliatePaymentsData?.data, 'status')).map(
                                    (k: any, indx: any) => {
                                        return (
                                            <Tag color={paymentStatusColor(optimizedPaymentDefSet({ status: k }))}>
                                                {readablePaymentStatus(optimizedPaymentDefSet({ status: k }))} (
                                                {_.groupBy(conciliatePaymentsData?.data, 'status')[k].length})
                                            </Tag>
                                        )
                                    },
                                )}
                            </Row>
                            <Typography.Text className="smallparagraph descriptions" style={{ marginTop: '5px' }}>
                                Conciliando los pagos en estado exitoso.
                            </Typography.Text>
                        </div>
                        <Row>
                            <Button
                                onClick={() => {
                                    dispatch(
                                        setData({
                                            item: 'conciliatePaymentsData',
                                            data: { ...conciliatePaymentsData, data: [] },
                                        }),
                                    )
                                    setdata([])
                                    setLoading(false)
                                }}
                                type="dashed"
                                htmlType="button"
                            >
                                Reset
                            </Button>
                            <Button onClick={conciliateProcess} type="dashed" icon={<CircleDashed />} />
                        </Row>
                    </Row>
                )}

                {data?.length > 0 && (
                    <Table
                        loading={loading}
                        columns={[
                            {
                                title: 'Charge id',
                                dataIndex: 'id',
                                key: 'id',
                                render: (text: any, record: any) => (
                                    <div className="d-flex flex-column">
                                        <Row align="middle">
                                            {record?.localConciliatedRunned ? (
                                                <CheckCircle
                                                    size={20}
                                                    weight="regular"
                                                    className="icon"
                                                    style={{ marginRight: '5px' }}
                                                />
                                            ) : (
                                                <ClockAfternoon
                                                    size={20}
                                                    weight="regular"
                                                    className="icon"
                                                    style={{ marginRight: '5px' }}
                                                />
                                            )}
                                            <div className="d-flex flex-column">
                                                {record?.id !== record?.fid && (
                                                    <Typography.Text className="smallparagraph descriptions">
                                                        {record.fid}
                                                    </Typography.Text>
                                                )}
                                                <Typography.Text className="smallparagraph descriptions">
                                                    {record.id}
                                                </Typography.Text>
                                                <Typography.Text className="smallparagraph descriptions">
                                                    {record.charge ?? record?.chargeId ?? ''}
                                                </Typography.Text>
                                            </div>
                                        </Row>
                                        <Row align="middle" style={{ marginTop: '5px' }}>
                                            <ArrowCircleRight weight="regular" className="icon" />
                                            <div
                                                style={{
                                                    height: '1px',
                                                    borderBottom: '1px dotted #cecece',
                                                    flex: 1,
                                                    marginLeft: '5px',
                                                }}
                                            ></div>
                                            <Typography.Text className="smallparagraph descriptions">
                                                {returnCurrencyValue(record?.amount / 100)}
                                            </Typography.Text>
                                            <div
                                                style={{
                                                    height: '1px',
                                                    borderBottom: '1px dotted #cecece',
                                                    flex: 1,
                                                    marginLeft: '5px',
                                                }}
                                            ></div>
                                        </Row>
                                    </div>
                                ),
                            },
                            {
                                title: 'Facturas',
                                dataIndex: 'invoices',
                                key: 'invoices',
                                render: (text: any, record: any) => {
                                    const totalAmounts = record?.invoicesR
                                        ?.map((i: any) => i?.total)
                                        .reduce((a: any, b: any) => a + b, 0)
                                    return (
                                        <div className="d-flex flex-column">
                                            {record?.invoicesR?.map((i: any) => (
                                                <Typography.Text className="smallparagraph descriptions">
                                                    {i.id} ({returnCurrencyValue(i.total)})
                                                </Typography.Text>
                                            ))}
                                            <Row align="middle" style={{ marginTop: '5px' }}>
                                                <div
                                                    style={{
                                                        height: '1px',
                                                        borderBottom: '1px dotted #cecece',
                                                        flex: 1,
                                                        marginLeft: '5px',
                                                    }}
                                                ></div>
                                                <Typography.Text className="smallparagraph descriptions">
                                                    {returnCurrencyValue(totalAmounts)}
                                                </Typography.Text>
                                                <div
                                                    style={{
                                                        height: '1px',
                                                        borderBottom: '1px dotted #cecece',
                                                        flex: 1,
                                                        marginLeft: '5px',
                                                    }}
                                                ></div>
                                            </Row>
                                        </div>
                                    )
                                },
                            },
                            {
                                title: 'Recibos',
                                dataIndex: 'receipts',
                                key: 'receipts',
                                render: (text: any, record: any) => {
                                    const paymentTotal = record?.amount / 100
                                    const invoiceTotals = record?.invoicesR
                                        ?.map((i: any) => i?.total)
                                        .reduce((a: any, b: any) => a + b, 0)
                                    const totals = record?.receiptsR
                                        ?.map((i: any) => i?.total)
                                        .reduce((a: any, b: any) => a + b, 0)
                                    const conciliated =
                                        Number(paymentTotal).toFixed(0) === Number(invoiceTotals).toFixed(0)

                                    return (
                                        <div className="d-flex flex-column">
                                            {record?.receiptsR?.map((i: any) => (
                                                <Typography.Text className="smallparagraph descriptions">
                                                    {i.id} ({returnCurrencyValue(i.total)})
                                                </Typography.Text>
                                            ))}
                                            <Row align="middle" style={{ marginTop: '5px' }}>
                                                <div
                                                    style={{
                                                        height: '1px',
                                                        borderBottom: '1px dotted #cecece',
                                                        flex: 1,
                                                        marginLeft: '5px',
                                                    }}
                                                ></div>
                                                <Typography.Text className="smallparagraph descriptions">
                                                    {returnCurrencyValue(totals)}
                                                </Typography.Text>
                                                <div
                                                    style={{
                                                        height: '1px',
                                                        borderBottom: '1px dotted #cecece',
                                                        flex: 1,
                                                        marginLeft: '5px',
                                                    }}
                                                ></div>
                                                {conciliated && (
                                                    <CheckCircle
                                                        weight="regular"
                                                        className="icon"
                                                        style={{ marginLeft: '5px' }}
                                                    />
                                                )}
                                            </Row>
                                        </div>
                                    )
                                },
                            },
                        ]}
                        dataSource={data?.filter((d: any) => d.status === 'succeeded')}
                    />
                )}

                {!conciliatePaymentsData?.started && (
                    <Row justify="center">
                        <Button type="dashed" htmlType="submit">
                            Iniciar conciliación
                        </Button>
                    </Row>
                )}
            </Form>
        </Modal>
    )
}

export default PaymentConcilliationModal
