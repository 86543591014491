import { Tray } from '@phosphor-icons/react'
import { Button, Table, Typography } from 'antd'
import { openModal } from '../../context/modalsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { TaxElement, taxElementSet } from '../../../interfaces/invoicesDef'
import { TaxesColumns } from './Columns/TaxesColumns'

const TaxesTable = ({
    incomingTaxes,
    customAction,
    customDelete,
}: {
    incomingTaxes?: any
    customAction?: Function
    customDelete?: Function
}) => {
    const dispatch = useDispatch()
    const { service, taxes } = useSelector((state: any) => state.data)

    let usingTaxes = incomingTaxes ?? taxes

    const preparedTaxes = [...usingTaxes, ...(service?.taxes ?? [])]
    //remove equal taxes
    const uniqueTaxes = preparedTaxes.filter(
        (tax: any, index: number, self: any) =>
            index ===
            self.findIndex(
                (t: any) =>
                    t.type === tax.type &&
                    t.factor === tax.factor &&
                    t.rate === tax.rate &&
                    t.inclusive === tax.inclusive,
            ),
    )

    var keysToShow: any = {
        type: 'Impuesto',
        factor: 'Factor',
        rate: 'Tasa',
        inclusive: 'Incluido',
        withholding: 'Retención',
        base: 'Base',
        // total: 'Total',
        actions: '',
    }

    return (
        <Table
            scroll={{ x: '100%' }}
            columns={[
                ...Object.keys(keysToShow).map((k: any) => {
                    var el: any = {
                        title: keysToShow[k],
                        width: k === 'name' ? '20%' : 'auto',
                        dataIndex: k,
                        render: (text: any, record: TaxElement) =>
                            TaxesColumns({
                                record,
                                columnName: k,
                                taxes: uniqueTaxes.map((t) => taxElementSet(t)),
                                customDelete,
                            }),
                    }
                    // if (k === 'actions') {
                    //     el['fixed'] = 'right'
                    //     el['width'] = '10%'
                    // }
                    // if (k === 'name') {
                    //     el['fixed'] = 'left'
                    //     el['width'] = '15%'
                    // }
                    return el
                }),
            ]}
            dataSource={[...(uniqueTaxes ?? [])?.map((d) => taxElementSet(d))]}
            locale={{
                emptyText: (
                    <div
                        className="d-flex flex-column"
                        style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Tray size={25} weight="regular" />
                        <Typography.Text style={{ color: '#cecece' }}>
                            No hay impuestos registrados para este producto / servicio
                        </Typography.Text>
                        {
                            <Button
                                type="primary"
                                onClick={() => {
                                    if (customAction) return customAction()

                                    dispatch(openModal('addTaxModalVisible'))
                                }}
                                style={{ marginTop: '20px' }}
                            >
                                Añadir impuesto
                            </Button>
                        }
                    </div>
                ),
            }}
        />
    )
}

export default TaxesTable
