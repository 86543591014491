import { Space, Tag, Typography } from 'antd'

import moment from 'moment'
import VendorActions from '../../ElementActions/VendorActions'
import { CopyableElementId } from '../../UI/CopyableElementId'
import { TeamDef } from '../../../../interfaces/teamDef'
import { tagsColors } from '../../Forms/TagsColors'
// import { useNavigate } from "react-router-dom"

type TypeVendorColumnName =
    | 'id'
    | 'amount'
    | 'vendorInfo'
    | 'email'
    | 'rfc'
    | 'status'
    | 'timestamp'
    | 'from'
    | 'invoiceType'
    | 'series'
    | 'relations'
    | 'actions'
    | 'name'
    | 'company'
    | 'profile'
    | keyof TeamDef

/**
 * 
 *     var keysToShow: any = {
        name: 'Cliente',
        company: 'Compañia',
        email: 'Email',
        profile: 'Perfil',
        timestamp: 'Creación',
        totalInvoiced: 'Total facturado',
        actions: ''
    }
 */

export const VendorsColumns = ({
    record,
    columnName,
    text,
    navigate,
}: {
    record: TeamDef
    columnName: TypeVendorColumnName
    text?: string
    navigate?: Function
}) => {
    // const navigate = useNavigate()
    const tagColors = tagsColors.find((color) =>
        record.facturapi.completed ? color.name === 'green' : color.name === 'gray',
    )

    switch (columnName) {
        case 'name':
            const wrappedText =
                (record.brand.alias ?? record.legalName ?? '')?.length > 20
                    ? (record.brand.alias ?? record.legalName)?.substring(0, 20) + '...'
                    : record.brand.alias ?? record.legalName

            return (
                <Space direction="vertical">
                    <Typography.Text
                        className="smallparagraphbold primary clickable"
                        onClick={() => {
                            navigate && navigate(record.id)
                        }}
                        style={{
                            //max chars 30 then ellipsis
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {wrappedText}
                    </Typography.Text>
                    <CopyableElementId id={record?.id} idName="ID" />
                </Space>
            )
        case 'company':
            return <Typography.Text className="smallparagraph descriptions">{record.legalName ?? ''}</Typography.Text>
        case 'id':
            return <Typography.Link>{record.id}</Typography.Link>
        case 'amount':
            return <></>
        case 'timestamp':
            return (
                <Typography.Text className="smallparagraph descriptions">
                    {moment(record.timestamp).format('DD MMM YYYY HH:mm') ?? ''}
                </Typography.Text>
            )
        case 'vendorInfo':
            // const elements = []
            // if (record?.company) elements.push(record?.company)
            // if (record?.id || record?.id) elements.push(record?.id ?? record?.id)
            // if (record?.metadata?.stripeId) elements.push(record?.metadata?.stripeId)
            // const name = record.legal_name ?? record?.name
            // const nameTruncated = name?.length > 20 ? name?.substring(0, 20) + '...' : name

            return <></>
        case 'email':
            return (
                <Typography.Text className="smallparagraph descriptions" style={{ textDecoration: 'lowercase' }}>
                    {record.contactEmails ?? ''}{' '}
                </Typography.Text>
            )
        case 'profile':
            return (
                <Tag color={tagColors?.bg} style={{ color: tagColors?.text }}>
                    {record.facturapi.completed ? 'Completo' : 'Sin Info. fiscal'}
                </Tag>
            )
        case 'from':
            return <></>
        case 'status':
            return <></>
        case 'relations':
            return <></>
        case 'actions':
            return <VendorActions vendor={record} showFull={false} />

        default:
            return <Typography.Text className="smallparagraph descriptions">{text}</Typography.Text>
    }
}
