import { setData } from "../context/dataSlice";
import { Dispatch } from "redux";
import { removeParamFromUrl } from "../functions/UrlParams";


export const cleanInvoicesContext = ({ dispatch } : { dispatch: Dispatch }) => {
    dispatch(
        setData({
            item: 'invoice',
            data: {
                step: 0,
            },
        }),
    )
    dispatch(
        setData({
            item: 'client',
            data: null,
        }),
    )
    dispatch(
        setData({
            item: 'selectedServices',
            data: [],
        }),
    )
    dispatch(
        setData({
            item: 'recurringEventData',
            data: null,
        }),
    )
    removeParamFromUrl('open')
    removeParamFromUrl('invoiceType')
    removeParamFromUrl('clientId')
    removeParamFromUrl('services')
}