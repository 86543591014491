import { Button, Modal, Row, Typography, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../context/modalsSlice'
import { integrations } from '../../datasets/Integrations'
import { IntegrationOnboarding } from '../../components/Integrations/IntegrationOnboarding'
import { Question } from '@phosphor-icons/react'
import { useEffect } from 'react'
import { addParamToUrl } from '../../functions/UrlParams'

export const SheetsModal = () => {
    const dispatch = useDispatch()

    const { googleSheetsModalVisible } = useSelector((state: any) => state.modals)
    const { team } = useSelector((state: any) => state.team)

    const sheetsIntegration = integrations(team).find((i) => i.id === 'googlesheets')

    useEffect(() => {
        if (googleSheetsModalVisible) {
            addParamToUrl('open', 'sheets')
        }
    }, [googleSheetsModalVisible])

    return (
        <Modal
            open={googleSheetsModalVisible}
            onCancel={() => {
                dispatch(closeModal('googleSheetsModalVisible'))
            }}
            className="integrationModal"
            title={null}
            footer={null}
        >
            <IntegrationOnboarding
                logo={sheetsIntegration?.logo ?? ''}
                title="Conecta Google Sheets con Gigstack"
                description="Registra pagos y factura automáticamente desde Google Sheets"
                bullets={[
                    { type: 'positive', description: 'Facturar automáticamente tus ventas' },
                    { type: 'positive', description: 'Crea facturas masivamente' },
                ]}
                IntegrationFooter={
                    <Row justify="space-between" style={{ padding: '20px 24px 20px 24px' }}>
                        <Row>
                            {/** TODO: Agregar funcionalidad */}
                            <Button icon={<Question className="icon clickable" weight="regular" size={16} />}>
                                <Typography.Text className="mediumparagraphbold">¿Cómo funciona?</Typography.Text>
                            </Button>
                        </Row>
                        <Row>
                            <Button type="primary" onClick={() => message.info('Próximamente')}>
                                Conectar
                            </Button>
                        </Row>
                    </Row>
                }
            />
        </Modal>
    )
}
