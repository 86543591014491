import { Copy } from '@phosphor-icons/react'
import { Row, Typography, message } from 'antd'

export const CopyableElementId = ({ id, idName = 'id' }: { id: string; idName: string }) => {
    return (
        <Row
            align="middle"
            onClick={() => {
                navigator.clipboard.writeText(id ?? '')
                message.success(`${idName} copiado al portapapeles`)
            }}
        >
            <Typography.Text className="extrasmallparagraph descriptions clickable" style={{ margin: 0, padding: 0 }}>
                {id}
            </Typography.Text>
            <Copy size={12} className="icon clickable" weight="regular" style={{ marginLeft: '3px' }} />
        </Row>
    )
}
