import { Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
import UpgradePlanCard from '../components/Cards/UpgradePlanCard'
import { useEffect, useState } from 'react'
import CreateTeamForm from '../components/Forms/CreateTeamForm'



const CreateTeamModal: React.FC = () => {
    const { createTeamVisible } = useSelector((state: any) => state.modals)
    
    const  [ canCreateTeam, setCanCreateTeam ] = useState(true)
    const { billingAccount } = useSelector((state: any) => state.billingAccount)

    const dispatch = useDispatch()

    const plansAllowed = ['professional', 'professional-plus', 'custom']
    useEffect(() => {
        
        if (billingAccount) {
            setCanCreateTeam(plansAllowed.includes(billingAccount?.plan?.id))
        }
        // eslint-disable-next-line
    }, [])


    return (
        <Modal
            open={createTeamVisible}
            onCancel={() => dispatch(closeModal('createTeamVisible'))}
            width={window.innerWidth <= 768 ? '70%' : '60%'}
            style={{ top: 40 }}
            footer={null}
        >
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '30px',
                    marginBottom: '20px',
                }}
            >
                <div id="form" style={{ width: '100%', position: 'relative', opacity: !canCreateTeam ? 0.5 : 1 }}>
                    <CreateTeamForm disabled={!canCreateTeam} />
                </div>

                <div
                    className="rounded-md bg-white"
                    style={{
                        display: !canCreateTeam ? 'block' : 'none',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        padding: '20px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        zIndex: 1000,
                    }}
                >
                    <UpgradePlanCard />
                </div>
            </div>
        </Modal>
    )
}

export default CreateTeamModal
