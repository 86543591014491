import { Tooltip, Typography } from 'antd'
import { Query, getDocs } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { MetroSpinner } from 'react-spinners-kit'

interface ClientDataWrapperProps {
    clientId: string
    loadingComponent?: React.ReactNode
    query: Query
    renderData?: (data: any, totalCount?: number) => React.ReactNode
}

const ClientDataWrapper: React.FC<ClientDataWrapperProps> = ({ clientId, loadingComponent, query, renderData }) => {
    const { testmode } = useSelector((state: any) => state.data)

    const [status, setStatus] = useState('loading')
    const [data, setData] = useState<any>([])
    const [error, setError] = useState('')
    const { relationInfo } = useSelector((state: any) => state.data)

    const getData = async () => {
        try {
            if (status !== 'loading') return
            const d = await getDocs(query)
            await new Promise((resolve) => setTimeout(resolve, 500))
            setData(d.docs.map((doc) => doc.data()))
            setStatus('success')
        } catch (error: any) {
            setError(error.message ?? '')
            setStatus('error')
        }
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testmode, relationInfo])

    return (
        <div>
            {status === 'loading' &&
                (!loadingComponent ? <MetroSpinner color="#8666FF" size={20} /> : loadingComponent)}
            {status === 'error' && (
                <Tooltip title={error ?? ''}>
                    <Typography.Text className="smallparagraph">Ocurrió un error</Typography.Text>
                </Tooltip>
            )}
            {status === 'success' && renderData && renderData(data)}
        </div>
    )
}

export default ClientDataWrapper
