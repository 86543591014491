import { Row, Tag, Tooltip } from 'antd'
import { InvoiceDef, OptimizedInvoiceDef } from '../../../interfaces/invoicesDef'
import { OptimizedPaymentDef, Payment } from '../../../interfaces/paymentsDef'
import { OptimizedReceiptDef, ReceiptDef } from '../../../interfaces/receiptsDef'
import {
    invoiceStatusColor,
    invoiceStatusColorText,
    paymentStatusColorText,
    readableInvoiceStatus,
    readablePaymentStatus,
    readableReceiptStatus,
    receiptStatusColorText,
} from '../../../helpers/statusHelpers'
import { CheckCircle, Circle, Clock, Files, HourglassHigh, Prohibit, X } from '@phosphor-icons/react'
import { colors } from '../../designSystem/colors'
import { returnCurrencyValue } from '../../functions/helpers'

export const PaymentStatusTag = ({
    record,
    shape,
}: {
    record: Payment | OptimizedPaymentDef
    shape?: 'circle' | 'tag'
}) => {
    const icon =
        record?.status === 'canceled' ? (
            <Prohibit
                weight="regular"
                size={20}
                style={{ color: paymentStatusColorText(record), borderRadius: '20px', margin: 'auto' }}
            />
        ) : record?.status === 'processing' ? (
            <Circle
                weight="regular"
                size={20}
                style={{ color: paymentStatusColorText(record), borderRadius: '20px', margin: 'auto' }}
            />
        ) : record?.status === 'succeeded' ? (
            <CheckCircle
                weight="regular"
                size={20}
                style={{ color: paymentStatusColorText(record), borderRadius: '20px', margin: 'auto' }}
            />
        ) : record?.status === 'review_requested' ? (
            <Files
                weight="regular"
                size={20}
                style={{ color: paymentStatusColorText(record), borderRadius: '20px', margin: 'auto' }}
            />
        ) : (
            <Clock
                size={20}
                weight="regular"
                style={{ color: paymentStatusColorText(record), borderRadius: '20px', margin: 'auto' }}
            />
        )

    return (
        <Row style={{ width: 'auto' }}>
            <Tooltip
                title={`Estatus actual del pago: ${readablePaymentStatus(record)} ${Number(record?.amount_remaining) > 0 ? `Monto pendiente ${returnCurrencyValue((record?.amount_remaining || 0) / 100)}` : ''} ${record?.livemode ? '' : '(Test)'}`}
            >
                {shape === 'circle' ? (
                    icon
                ) : (
                    <Tag color={paymentStatusColorText(record)}>{readablePaymentStatus(record)}</Tag>
                )}
            </Tooltip>
        </Row>
    )
}

export const InvoiceStatusTag = ({
    record,
    shape = 'tag',
}: {
    record: InvoiceDef | OptimizedInvoiceDef
    shape: 'circle' | 'tag'
}) => {
    const icon =
        record?.status !== 'canceled' && record?.cancellation_status === 'pending' ? (
            <HourglassHigh
                size={20}
                weight="regular"
                style={{
                    borderRadius: '20px',
                    margin: 'auto',
                    color: colors.purple_400,
                }}
            />
        ) : record?.status === 'canceled' ? (
            <Prohibit
                weight="regular"
                size={20}
                style={{ color: invoiceStatusColorText(record), borderRadius: '20px', margin: 'auto' }}
            />
        ) : record?.status === 'processing' ? (
            <Circle
                weight="regular"
                size={20}
                style={{ color: invoiceStatusColorText(record), borderRadius: '20px', margin: 'auto' }}
            />
        ) : record?.status === 'valid' ? (
            <CheckCircle weight="regular" size={20} style={{ color: invoiceStatusColorText(record), margin: 'auto' }} />
        ) : (
            <Clock
                size={20}
                weight="regular"
                style={{ color: invoiceStatusColorText(record), borderRadius: '20px', margin: 'auto' }}
            />
        )
    return (
        <Row style={{ width: 'auto' }}>
            <Tooltip title={`Estado actual: ${readableInvoiceStatus(record)}`}>
                {shape === 'circle' ? (
                    icon
                ) : (
                    <Tag color={invoiceStatusColor(record)}>{readableInvoiceStatus(record)}</Tag>
                )}
            </Tooltip>
        </Row>
    )
}

export const ReceiptStatusTag = ({
    record,
    shape = 'tag',
}: {
    record: ReceiptDef | OptimizedReceiptDef
    shape: 'circle' | 'tag'
}) => {
    const circledIcon =
        record?.status === 'failed' ? (
            <X
                weight="regular"
                size={20}
                style={{ color: receiptStatusColorText(record), borderRadius: '20px', margin: 'auto' }}
            />
        ) : record?.status === 'canceled' ? (
            <Prohibit
                weight="regular"
                size={20}
                style={{ color: receiptStatusColorText(record), borderRadius: '20px', margin: 'auto' }}
            />
        ) : record?.status === 'processing' ? (
            <Circle
                weight="regular"
                size={20}
                style={{ color: receiptStatusColorText(record), borderRadius: '20px', margin: 'auto' }}
            />
        ) : record?.status === 'completed' ? (
            <CheckCircle
                weight="regular"
                size={20}
                style={{ color: receiptStatusColorText(record), borderRadius: '20px', margin: 'auto' }}
            />
        ) : (
            <Clock
                size={20}
                weight="regular"
                style={{ color: receiptStatusColorText(record), borderRadius: '20px', margin: 'auto' }}
            />
        )
    return (
        <Row style={{ width: 'auto' }}>
            <Tooltip title={readableReceiptStatus(record)}>
                {shape === 'circle' ? (
                    circledIcon
                ) : (
                    <Tag color={receiptStatusColorText(record)}>{readableReceiptStatus(record)}</Tag>
                )}
            </Tooltip>
        </Row>
    )
}
