import { Avatar, Button, Card, Col, Divider, message, Row, Space, Typography } from 'antd'
import { collection, doc, getDoc, getDocs, getFirestore, query, where } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAuth } from 'reactfire'
import Hero from '../../assets/images/signup-hero.png'
import { TeamDef } from '../../interfaces/teamDef'
import { SignedInternalAPIRequest } from '../functions/APIRequest'
import CreateTeamButton from '../components/ComponentIndividual/CreateTeamButton'
import CreateTeamModal from '../modals/CreateTeamModal'

export const OAuthView = () => {
    const { teams } = useSelector((state: any) => state.team)
    
    const [loading, setLoading] = useState<string[]>([])
    const [from, setFrom] = useState('')
    const [teamList, setTeamList] = useState<TeamDef[]>([])
    const [searchParams, ] = useSearchParams()


    const requestId = searchParams.get('request_id')
    const state = searchParams.get('state')
    const accountId = searchParams.get('account_id')
    const db = getFirestore()
    const auth = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const oAuthRequestDoc = await getDoc(doc(db, 'oauthRequest', requestId!))
                if (oAuthRequestDoc.exists()) {
                    setFrom(oAuthRequestDoc.data()?.from)
                } else {
                    navigate('/home')
                }
                
            } catch (error) {
                console.log('🚀 ~ fetchData ~ error:', error)
            }
        }
        
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(() => {
        const fetchFilterTeams = async () => {
            const teamsQuery = query(
                collection(db, "teams"),
                where("membersIds", "array-contains", auth?.currentUser?.uid),
                where("stripe.accountId", "==", accountId)
            )
            const teamsToShow = await getDocs(teamsQuery)
            if(!teamsToShow.empty){
                setTeamList(teamsToShow.docs.map((doc) => doc.data() as TeamDef))
            } else {
                setTeamList(teams.filter((team: TeamDef) => !team.stripe?.accountId ))
            }
        }
        fetchFilterTeams()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamList])

    const handleTeamConnect = async (e: any, teamId: string) => {
        e.preventDefault()
        setLoading((prev) => [...prev, teamId])

        try {
            const response = await SignedInternalAPIRequest(
                {
                    team: teamId,
                    requestId,
                    state,
                },
                'oauth2/approve',
                auth.currentUser,
            )

            if (!response?.redirectUri) {
                throw new Error('No redirect URI provided')
            }

            // Construct redirect URL safely
            const redirectUrl = new URL(response.redirectUri)

            // Add required parameters
            redirectUrl.searchParams.set('team', teamId)
            if (state) {
                redirectUrl.searchParams.set('state', state)
            }

            // Perform redirect using navigate for SPA navigation when possible

            window.location.href = redirectUrl.toString()
        } catch (error) {
            console.error('Team connection error:', error)
            message.error('Error connecting team. Please try again.')
        } finally {
            setLoading((prev) => prev.filter((id) => id !== teamId))
        }
    }

    return (
        <>
            <CreateTeamModal />
            <Row
                style={{
                    minHeight: '100vh',
                    width: '100%',
                    background: 'linear-gradient(221.51deg, #000000 0.75%, #044737 69.64%)',
                }}
            >
                <Col
                    xs={24}
                    md={11}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '50px',
                    }}
                >
                    <div style={{ backgroundColor: 'white', borderRadius: '10px', padding: '30px', minWidth: '90%' }}>
                        <Space direction="vertical">
                            <Typography.Text className="h5-bold">Selecciona una cuenta a conectar</Typography.Text>
                            <Typography.Text className="p-base-regular">
                                La cuenta seleccionado será conectada con {from ?? 'el servicio'}
                            </Typography.Text>
                        </Space>
                        <Divider />
                        {teamList.length === 0 ? (
                            <Card>
                                <div className="d-flex" style={{ flexDirection: 'column', marginLeft: '5px' }}>
                                    <Typography.Text className="p-small-bold">
                                        No hay cuentas disponibles para conectar
                                    </Typography.Text>
                                    <Typography.Text className="p-small-regular" style={{marginBottom: '5px', alignSelf: 'center'}}>
                                        Es posible que todas tus cuentas ya estén conectadas con diferentes perfiles de{' '}
                                        {from ?? 'el servicio'}.                        
                                    </Typography.Text>
                                    <CreateTeamButton />
                                </div>
                            </Card>
                        ) : (
                            teamList?.map((a: TeamDef) => {
                                return (
                                    <Card
                                        style={{
                                            width: '100%',
                                            borderRadius: 8,
                                            border: 'none',
                                            backgroundColor: 'var(--primary-5)', 
                                            marginTop: '5px',
                                        }}
                                        key={a.id}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Avatar size={50} src={a.brand?.logo} style={{ marginRight: '10px' }} />
                                                <div
                                                    className="d-flex"
                                                    style={{ flexDirection: 'column', marginLeft: '5px' }}
                                                >
                                                    <Typography.Text style={{ margin: 0 }}>
                                                        {a?.brand?.alias}
                                                    </Typography.Text>
                                                    <span style={{ color: '#8C8C8C', fontSize: 12 }}>
                                                        {a.members.length ?? 1} Miembros
                                                    </span>
                                                </div>
                                            </div>
                                            <Button
                                                className="btn-primary"
                                                size="small"
                                                loading={loading.includes(a.id)}
                                                onClick={(e) => handleTeamConnect(e, a.id)}
                                            >
                                                Conectar
                                            </Button>
                                        </div>
                                    </Card>
                                )
                            })
                        )}
                    </div>
                </Col>
                <Col xs={0} md={13}>
                    <img
                        src={Hero}
                        alt="Hero"
                        draggable={false}
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            mixBlendMode: 'soft-light',
                        }}
                    />
                </Col>
            </Row>
        </>
    )
}
