import { doc, getFirestore, updateDoc } from "firebase/firestore"
import posthog from "posthog-js"
import { TeamDef } from "../../../interfaces/teamDef"
import { GetTeamHelper } from "../../context/databaseContextHelpers"
import { message } from "antd"


export const updateAutomaticInvoicingIntegration = async ({
    automaticInvoicing,
    dispatch,
    setAutomaticInvoicing,
    team,
    integrationName
}: {
    automaticInvoicing: boolean,
    dispatch: any,
    setAutomaticInvoicing: any,
    team: TeamDef,
    integrationName: string
}) => {

        try {
            try {
                posthog.capture(`${integrationName}_automatic_invoicing`, {
                    active: !automaticInvoicing,
                })
            } catch (error) {}
            await updateDoc(doc(getFirestore(), 'teams', team?.id), {
                [`${integrationName}.automaticInvoicing`]: !automaticInvoicing,
                [`${integrationName}.completed`]: true
            })

            await GetTeamHelper(team?.id, dispatch)
            setAutomaticInvoicing(!automaticInvoicing)
            message.success('Configuración actualizada')
        } catch (error) {
            message.error('Ocurrió un error al actualizar la configuración')
        }
}