import {
    Avatar,
    Button,
    Col,
    Descriptions,
    Divider,
    Dropdown,
    MenuProps,
    Row,
    Space,
    Table,
    Tag,
    Tooltip,
    Typography,
    message,
} from 'antd'
import {
    DocumentData,
    collection,
    doc,
    getCountFromServer,
    getFirestore,
    orderBy,
    query,
    where,
} from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { ClientDef, clientSet } from '../../interfaces/clientDef'
import HeaderForViewDetail from '../components/ComponentIndividual/HeaderForViewDetail'
import { useAuth, useFirestoreDocData } from 'reactfire'
import { SearchParamInURL, invoiceUsage, returnCurrencyValue } from '../functions/helpers'
import {
    Bank,
    Cardholder,
    CurrencyCircleDollar,
    CurrencyDollar,
    DownloadSimple,
    FolderUser,
    Gear,
    Money,
    Pencil,
    Receipt,
} from '@phosphor-icons/react'
import { setData } from '../context/dataSlice'
import { useDispatch, useSelector } from 'react-redux'
import { openModal } from '../context/modalsSlice'
import moment from 'moment'
import DynamicTabs from '../components/Menu/Tabs'
import ClientDataWrapper from '../datawrappers/clientDataWrapper'
import {
    InvoicesColumnsObject,
    PaymentColumnsObject,
    ReceiptsColumnsObject,
} from '../components/Tables/ColumnsSettlers'
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'
import { useNavigate } from 'react-router-dom'
import TablesContainer from '../components/Tables/TablesContainer'
import { SignedInternalAPIRequest } from '../functions/APIRequest'
import RecurringInvoicesTable from '../components/Tables/RecurringInvoices'
import RecurringPaymentsTable from '../components/Tables/RecurringPayments'
import { createdByObject } from '../components/ComponentIndividual/helpers'

const handleDownloadClientBalance = async ({
    client,
    loading,
    setLoading,
    teamId,
    auth,
}: {
    client: ClientDef
    loading: boolean
    setLoading: (v: boolean) => void
    teamId: string
    auth: any
}) => {
    try {
        setLoading(true)
        await SignedInternalAPIRequest(
            { clientId: client.id, teamId },
            'generateClientAccountBalance',
            auth.currentUser,
            {
                isFile: true,
                fileName: `${client.rfc ?? 'Balance'}-${moment().format('DD/MM/YYYY')}`,
                fileExtension: 'pdf',
            },
            'POST',
        )
        setLoading(false)
    } catch (error: any) {
        message.error(error.message || 'Error al descargar el archivo')
        setLoading(false)
    }
}

const handleDownloadClientAccountStatement = async ({
    clientId,
    loading,
    setLoading,
    auth,
}: {
    clientId: string
    loading: boolean
    setLoading: (v: boolean) => void
    auth: any
}) => {
    try {
        setLoading(true)
        await SignedInternalAPIRequest(
            {
                clientId,
            },
            `exportFiles/v1/collectionPayments`,
            auth.currentUser,
            {
                isFile: true,
                fileName: `Estado_de_cuenta-${moment().format('DD/MM/YYYY')}`,
                fileExtension: 'xlsx',
            },
            'POST',
        )
        setLoading(false)
    } catch (error: any) {
        message.error(error.message || 'Error al descargar el archivo')
        setLoading(false)
    }
}

const ClientViewDetail: React.FC = () => {
    const [reviewPayments, setReviewPayments] = useState({
        loaded: false,
        visible: false,
        count: 0,
    })

    const navigate = useNavigate()
    const auth = useAuth()
    const { team } = useSelector((state: any) => state.team)
    const { user } = useSelector((state: any) => state.user)
    const { testmode } = useSelector((state: any) => state.data)
    const fs = getFirestore()
    const dispatch = useDispatch()
    const id = SearchParamInURL('id')

    const clientRef = doc(fs, 'clients', id ?? '')
    const { status, data } = useFirestoreDocData(clientRef)
    const client = clientSet(data) 

    const [tab, setTab] = useState('payments')

    useEffect(() => {
        if (!reviewPayments.loaded) {
            const q = query(
                collection(getFirestore(), 'payments'),
                where('team', '==', team?.id ?? ''),
                where('clientId', '==', id),
                where('status', '==', 'review_requested'),
                orderBy('timestamp', 'desc'),
            )
            getCountFromServer(q).then((count: DocumentData) => {
                setReviewPayments({
                    loaded: true,
                    visible: count.data().count > 0,
                    count: count.data().count,
                })
            })
        }
    }, [reviewPayments, team?.id, id])

    if (status === 'loading') return <></>

    const ActionsHeader = () => {
        return (
            <Col xs={24}>
                <div
                    style={{
                        borderRadius: '10px',
                        backgroundColor: '#F9FAFC',
                        padding: '10px',
                    }}
                >
                    <Row justify="space-between" align="middle">
                        <Typography.Text className="smallparagraphbold">Acciones</Typography.Text>
                        <Space>
                            <Tooltip title="Solicitar Pago">
                                <Money
                                    size={18}
                                    weight="regular"
                                    className="icon clickable createClientPayment"
                                    onClick={() => {
                                        dispatch(
                                            setData({
                                                item: 'client',
                                                data: client,
                                            }),
                                        )
                                        dispatch(openModal('paymentModalVisible'))
                                    }}
                                />
                            </Tooltip>
                            <Tooltip title="Crear Recibo">
                                <Cardholder
                                    size={18}
                                    weight="regular"
                                    className="icon clickable createClientReceipt"
                                    onClick={() => {
                                        dispatch(
                                            setData({
                                                item: 'client',
                                                data: client,
                                            }),
                                        )
                                        dispatch(openModal('receiptModalVisible'))
                                    }}
                                />
                            </Tooltip>
                            <Tooltip title="Crear Factura">
                                <CurrencyDollar
                                    size={18}
                                    weight="regular"
                                    className="icon clickable createClientInvoice"
                                    onClick={() => {
                                        dispatch(
                                            setData({
                                                item: 'client',
                                                data: client,
                                            }),
                                        )
                                        dispatch(openModal('invoiceModalVisible'))
                                    }}
                                />
                            </Tooltip>
                            <Divider type="vertical" />
                            <Tooltip title="Editar">
                                <Pencil
                                    size={18}
                                    weight="regular"
                                    className="icon clickable"
                                    onClick={() => {
                                        dispatch(
                                            setData({
                                                item: 'client',
                                                data: client,
                                            }),
                                        )
                                        dispatch(openModal('createClientDrawerVisible'))
                                    }}
                                />
                            </Tooltip>
                            {client?.metadata?.stripeId && (
                                <Tooltip title="Ver datos bancarios para la transferencia">
                                    <Bank
                                        size={18}
                                        weight="regular"
                                        className="icon clickable"
                                        onClick={() => {
                                            dispatch(
                                                setData({
                                                    item: 'client',
                                                    data: client,
                                                }),
                                            )
                                            dispatch(openModal('stripeBankInstructionsVisible'))
                                        }}
                                    />
                                </Tooltip>
                            )}
                            <Tooltip title="Valores Predeterminados">
                                <Gear
                                    size={18}
                                    weight="regular"
                                    className="icon clickable"
                                    onClick={() => {
                                        dispatch(
                                            setData({
                                                item: 'client',
                                                data: client,
                                            }),
                                        )
                                        dispatch(openModal('clientDefaultsModalVisible'))
                                    }}
                                />
                            </Tooltip>
                        </Space>
                    </Row>
                </div>
            </Col>
        )
    }

    

    const ClientGeneralData = () => {
        let details = [
            {
                key: 'legalName',
                label: <Typography.Text className="smallparagraphbold">Nombre Legal</Typography.Text>,
                children: <Typography.Text className="smallparagraph ">{client.legalName ?? 'S/N'} </Typography.Text>,
            },

            {
                key: 'address.country',
                label: <Typography.Text className="smallparagraphbold">País</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        {typeof client?.address?.country === 'string'
                            ? client?.address?.country
                            : client?.address?.country?.label ?? 'S/N'}{' '}
                    </Typography.Text>
                ),
            },
            {
                key: 'address.zip',
                label: <Typography.Text className="smallparagraphbold">Código Postal</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">{client.address?.zip ?? 'S/N'} </Typography.Text>
                ),
            },
            {
                key: 'rfc',
                label: <Typography.Text className="smallparagraphbold">RFC / Identificador Tributario</Typography.Text>,
                children: <Typography.Text className="smallparagraph ">{client.rfc ?? 'S/N'} </Typography.Text>,
            },

            {
                key: 'use',
                label: <Typography.Text className="smallparagraphbold">Uso del CFDI</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        {client.use ? invoiceUsage.find((usage) => usage.value === client.use)?.label : 'S/N'}{' '}
                    </Typography.Text>
                ),
            },
            {
                key: 'stripeBalance',
                label: <Typography.Text className="smallparagraphbold">Balance</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph">
                        {returnCurrencyValue((client.stripeBalance || 0) / 100)}
                    </Typography.Text>
                ),
            },
            {
                key: 'date',
                label: <Typography.Text className="smallparagraphbold">Creado</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        {moment(data?.timestamp).format('DD MMMM YYYY HH:mm')}{' '}
                    </Typography.Text>
                ),
            },
        ]

        if (client?.address?.street !== '') {
            details.splice(1, 0, {
                key: 'address.address',
                label: <Typography.Text className="smallparagraphbold">Dirección</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">{client.address?.street ?? 'S/N'} </Typography.Text>
                ),
            })
        }

        if((client.from === 'manual' || !client.from) && data?.owner) {
            details.push({
                ...createdByObject({ resource: data, team, user }),
            })
        }

        return (
            <Col xs={24} lg={24}>
                <div
                    style={{
                        width: '100%',
                        padding: '20px',
                        borderRadius: '10px',
                        background:
                            'linear-gradient(180deg, rgba(186, 216, 239, 0.34) 0%, rgba(134, 102, 255, 0.06) 100%)',
                    }}
                    className="d-flex flex-column"
                >
                    <Descriptions
                        title={
                            <Row justify="space-between" align="middle">
                                <Typography.Text className="mediumparagraphbold">
                                    Información general de {client?.name}{' '}
                                    <Tag style={{ marginLeft: '5px' }} color="blue">
                                        {client?.company}
                                    </Tag>
                                </Typography.Text>
                                <></>
                            </Row>
                        }
                        layout="vertical"
                        size="small"
                        items={details}
                    />
                </div>
            </Col>
        )
    }

    const InfoTabs = () => {
        const tabsConfig = [
            {
                key: 'payments',
                title: 'Pagos',
                content:
                    tab === 'payments' ? (
                        <TablesContainer
                            title={''}
                            description={''}
                            actions={undefined}
                            children={
                                <ClientDataWrapper
                                    query={query(
                                        collection(fs, 'payments'),
                                        where('team', '==', team.id),
                                        where('clientId', '==', id),
                                        where('livemode', '==', !testmode),
                                        orderBy('timestamp', 'desc'),
                                    )}
                                    renderData={(data) => {
                                        return (
                                            <Table
                                                pagination={{
                                                    hideOnSinglePage: true,
                                                }}
                                                locale={{
                                                    emptyText: 'No hay pagos',
                                                }}
                                                scroll={{ x: 1200 }}
                                                columns={PaymentColumnsObject({
                                                    navigate: (id: string) => {
                                                        navigate(id)
                                                    },
                                                })}
                                                dataSource={data}
                                            />
                                        )
                                    }}
                                    clientId={id ?? ''}
                                />
                            }
                        />
                    ) : (
                        <></>
                    ),
            },
            {
                key: 'recurring_payments',
                title: 'Pagos Recurrentes',
                content: <RecurringPaymentsTable max={10} from={'clientsView'} clientId={client.id} />,
            },
            {
                key: 'receipts',
                title: 'Recibos de venta',
                content: (
                    <TablesContainer
                        title={''}
                        description={''}
                        actions={undefined}
                        children={
                            <ClientDataWrapper
                                query={query(
                                    collection(fs, 'receipts'),
                                    where('team', '==', team.id),
                                    where('clientId', '==', id),
                                    where('livemode', '==', !testmode),
                                    orderBy('timestamp', 'desc'),
                                )}
                                renderData={(data) => {
                                    return (
                                        <Table
                                            locale={{
                                                emptyText: 'No hay recibos',
                                            }}
                                            pagination={{
                                                hideOnSinglePage: true,
                                            }}
                                            scroll={{ x: 1200 }}
                                            columns={ReceiptsColumnsObject({
                                                navigate: (id: string) => {
                                                    navigate(id)
                                                },
                                            })}
                                            dataSource={data}
                                        />
                                    )
                                }}
                                clientId={id ?? ''}
                            />
                        }
                    />
                ),
            },
            {
                key: 'invoices',
                title: 'Facturas',
                content: (
                    <TablesContainer
                        title={''}
                        description={''}
                        actions={undefined}
                        children={
                            <ClientDataWrapper
                                query={query(
                                    collection(fs, 'invoices'),
                                    where('team', '==', team.id),
                                    where('clientId', '==', id),
                                    where('livemode', '==', !testmode),
                                    orderBy('timestamp', 'desc'),
                                )}
                                renderData={(data) => {
                                    return (
                                        <Table
                                            pagination={{
                                                hideOnSinglePage: true,
                                            }}
                                            locale={{
                                                emptyText: 'No hay facturas',
                                            }}
                                            scroll={{ x: 1200 }}
                                            columns={InvoicesColumnsObject({
                                                navigate: (id: string) => {
                                                    navigate(id)
                                                },
                                            })}
                                            dataSource={data}
                                        />
                                    )
                                }}
                                clientId={id ?? ''}
                            />
                        }
                    />
                ),
            },
            {
                key: 'recurring_invoices',
                title: 'Facturas Recurrentes',
                content: <RecurringInvoicesTable max={10} from={'clientsView'} clientId={client.id} />,
            },
        ]

        if (reviewPayments.visible)
            tabsConfig.splice(0, 0, {
                key: 'reviewPayments',
                title: 'Revisar pagos',
                content:
                    tab === 'reviewPayments' ? (
                        <TablesContainer
                            title={''}
                            description={''}
                            actions={undefined}
                            children={
                                <ClientDataWrapper
                                    query={query(
                                        collection(fs, 'payments'),
                                        where('team', '==', team.id),
                                        where('clientId', '==', id),
                                        where('status', '==', 'review_requested'),
                                        where('livemode', '==', !testmode),
                                        orderBy('timestamp', 'desc'),
                                    )}
                                    renderData={(data) => {
                                        return (
                                            <Table
                                                pagination={{
                                                    hideOnSinglePage: true,
                                                }}
                                                locale={{
                                                    emptyText: 'No hay pagos',
                                                }}
                                                scroll={{ x: 1200 }}
                                                columns={PaymentColumnsObject({
                                                    customKeysToShow: {
                                                        // clientInfo: 'Cliente',
                                                        suggestedActions: '',
                                                        amount: 'Monto',
                                                        // status: 'Estado',
                                                        // timestamp: 'Creado',
                                                        succeededTimestamp: 'Pagado',
                                                        // from: 'Fuente',
                                                        // automations: 'Automatizaciones',
                                                        relations: 'Relaciones',
                                                    },
                                                    navigate: (id: string) => {
                                                        navigate(id)
                                                    },
                                                })}
                                                dataSource={data}
                                            />
                                        )
                                    }}
                                    clientId={id ?? ''}
                                />
                            }
                        />
                    ) : (
                        <></>
                    ),
            })

        return (
            <ErrorBoundary>
                <Col xs={24}>
                    <DynamicTabs defaultActiveKey={tab} tabsConfig={tabsConfig} onChange={setTab} />
                </Col>
            </ErrorBoundary>
        )
    }

    const DownloadButtons = () => {
        const [loading, setLoading] = useState<boolean>(false)

        const items: MenuProps['items'] = [
            {
                key: 'balance',
                label: 'Estado de cuenta',
                icon: <FolderUser className="icon" size={18} weight="regular" />,
                onClick: () =>
                    handleDownloadClientBalance({
                        client: client,
                        loading,
                        setLoading,
                        teamId: team.id,
                        auth,
                    }),
            },
            {
                key: 'account_statement',
                label: 'Pagos pendientes',
                icon: <Receipt className="icon" size={18} weight="regular" />,
                onClick: () =>
                    handleDownloadClientAccountStatement({
                        clientId: client.id,
                        loading,
                        setLoading,
                        auth,
                    }),
            },
            {
                key: 'invoices',
                label: 'Facturas',
                icon: <Cardholder className="icon" size={18} weight="regular" />,
                onClick: () => {
                    dispatch(
                        setData({
                            item: 'downloadInfo',
                            data: {
                                type: 'invoices',
                                readableType: 'facturas',
                                clientId: id,
                            },
                        }),
                    )
                    dispatch(openModal('downloadsModalVisible'))
                },
            },
            {
                key: 'payments',
                label: 'Pagos',
                icon: <CurrencyCircleDollar className="icon" size={18} weight="regular" />,
                onClick: () => {
                    dispatch(
                        setData({
                            item: 'downloadInfo',
                            data: {
                                type: 'payments',
                                readableType: 'pagos',
                                clientId: id,
                            },
                        }),
                    )
                    dispatch(openModal('downloadsModalVisible'))
                },
            },
        ]

        return (
            <Row align="middle" justify="end" style={{ width: '100%' }}>
                <Dropdown menu={{ items }}>
                    <Button
                        icon={<DownloadSimple className="icon" size={18} weight="regular" />}
                        loading={loading}
                        disabled={loading}
                    >
                        Descargas
                    </Button>
                </Dropdown>
            </Row>
        )
    }

    return (
        <div>
            <HeaderForViewDetail
                data={{ ...data, livemode: true }}
                title={`${client?.name} - ${client?.company ?? ''}`}
            />
            <Row gutter={[16, 16]} style={{ padding: '20px 20px' }}>
                <Col xs={24}>
                    <Row>
                        <Avatar
                            style={{
                                verticalAlign: 'middle',
                                marginRight: '10px',
                                background:
                                    'linear-gradient(180deg, rgba(186, 216, 239, 0.34) 0%, rgba(134, 102, 255, 0.06) 100%)',
                            }}
                            size={40}
                        >
                            <Typography.Text className="smallparagraphbold">
                                {(client.legalName ?? '')[0] ?? client?.name[0] ?? ''}
                            </Typography.Text>
                        </Avatar>
                        <div className="d-flex flex-column">
                            <Typography.Text className="smallheader">{client?.name}</Typography.Text>
                            <Typography.Text className="smallparagraph descriptions">
                                {client?.rfc} - {client?.email}
                            </Typography.Text>
                            <Typography.Text className="smallparagraph descriptions">
                                {typeof client?.address?.country === 'string'
                                    ? client?.address?.country
                                    : client?.address?.country?.label ?? ''}{' '}
                                - C.P. {client?.address?.zip}
                            </Typography.Text>
                        </div>
                    </Row>
                </Col>
                <ActionsHeader />

                <ClientGeneralData />

                <DownloadButtons />

                <InfoTabs />
            </Row>
        </div>
    )
}

export default ClientViewDetail
