import { Col, Divider, Drawer, Row, Table, Tag, Timeline, Typography } from 'antd'
import { OptimizedInvoiceDef } from '../../../interfaces/invoicesDef'
import { OptimizedPaymentDef } from '../../../interfaces/paymentsDef'
import { OptimizedReceiptDef } from '../../../interfaces/receiptsDef'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../context/modalsSlice'
import { RelationsHandler } from './RelationsHandler'
import { ArrowCircleLeft, Copy, Envelope, HandCoins } from '@phosphor-icons/react'
import { tagsColors } from '../Forms/TagsColors'
import { PaymentsColumns } from '../Tables/Columns/PaymentsColumns'
import { InvoicesColumns } from '../Tables/Columns/InvoicesColumns'
import { ReceiptsColumns } from '../Tables/Columns/ReceiptsColumns'
import { setData } from '../../context/dataSlice'
import moment from 'moment'
import { getItemsAmounts, readableElementSource, returnCurrencyValue } from '../../functions/helpers'
import { useEffect, useState } from 'react'
import { MetroSpinner } from 'react-spinners-kit'
import { collection, getDocs, getFirestore, orderBy, query, where } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'

export const RelationsDrawerContainer = ({
    items,
    title,
    elementType,
}: {
    items?: (OptimizedInvoiceDef | OptimizedPaymentDef | OptimizedReceiptDef)[]
    title: string
    elementType: 'payments' | 'receipts' | 'invoices'
}) => {
    let columns: any = {
        clientInfo: 'Cliente',
        status: 'Estado',
        amount: 'Monto',
        timestamp: 'Fecha',
        actions: '',
    }
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const HandleColumnsComponent = (elementType: 'payments' | 'invoices' | 'receipts') => {
        switch (elementType) {
            case 'payments':
                return PaymentsColumns
            case 'invoices':
                return InvoicesColumns
            case 'receipts':
                return ReceiptsColumns
        }
    }

    if (items?.length === 0) return <></>

    return (
        <div style={{ margin: '20px 0px' }}>
            <Typography.Text className="smallheader" style={{ marginLeft: '15px' }}>
                {title}
            </Typography.Text>
            <Divider style={{ margin: '10px 0px 0px 0px' }} />
            <Table
                showHeader={false}
                pagination={(items ?? [])?.length > 10 ? { pageSize: 10 } : false}
                bordered={false}
                style={{ padding: 0 }}
                columns={Object.keys(columns).map((k: any) => {
                    return {
                        title: columns[k],
                        dataIndex: k,
                        render: (text: any, record: any) => (
                            <div key={k} style={{ cursor: 'pointer' }}>
                                {HandleColumnsComponent(elementType)({
                                    record,
                                    columnName: k,
                                    navigate: (route: string) => {
                                        navigate(route);
                                        dispatch(closeModal('relationsDrawerVisible'))
                                    },
                                })}
                            </div>
                        ),
                    }
                })}
                dataSource={items}
            />
        </div>
    )
}

export const RelationsDrawer = () => {
    const dispatch = useDispatch()

    const { relationsDrawerVisible } = useSelector((state: any) => state.modals)
    const { relationInfo, testmode } = useSelector((state: any) => state.data)

    const { title, sourceType: type, item } = relationInfo
    const visibleItemId = type === 'invoices' ? item?.uuid : type === 'payments' ? item?.fid : item?.id

    const visibleTitle = type === 'invoices' ? `${title} ${item?.series} - ${item?.folio_number}` : title

    const handleTimelineText = () => {
        switch (type) {
            case 'invoices':
                return 'Factura creada desde'
            case 'payments':
                return 'Pago creado desde'
            case 'receipts':
                return 'Recibo creado desde'
            case 'recurringInvoices':
                return 'Factura recurrente creada desde'
            case 'recurringPayments':
                return 'Pago recurrente creado desde'
            default:
                return 'Creado desde'
        }
    }

    const [sentEmails, setSentEmails] = useState<any>({
        loaded: false,
        emails: [],
    })

    const [cronogram, setCronogram] = useState<any>([
        {
            id: 'emails_loading',
            timestamp: moment().valueOf(),
            dot: <MetroSpinner size={18} color="#8666FF" />,
            children: (
                <div className="d-flex flex-column">
                    <Typography.Text className="smallparagraphbold">
                        Correos
                        <Typography.Text className="smallparagraphbold primary">enviados</Typography.Text>
                    </Typography.Text>
                    <Typography.Text className="smallparagraph">
                        {moment(item?.timestamp).format('DD MMM YY HH:mm')}
                    </Typography.Text>
                </div>
            ),
        },
    ])

    const getRelatedEmails = async () => {
        const queryEmails = query(
            collection(getFirestore(), 'emails'),
            where('related', '==', `${type}/${visibleItemId}`),
            orderBy('timestamp', 'desc'),
        )
        try {
            const emails = await getDocs(queryEmails)
            setSentEmails((prev: any) => ({
                ...prev,
                loaded: true,
                emails: emails.docs.map((d) => d.data()),
            }))
            var newCronogram = cronogram
            newCronogram = newCronogram.filter((c: any) => c.id !== 'emails_loading')

            for (const email of emails.docs.map((d) => d.data())) {
                newCronogram.push({
                    id: email.id,
                    dot: <Envelope size={18} className="icon" />,
                    children: (
                        <div className="d-flex flex-column">
                            <Typography.Text className="smallparagraphbold">
                                Enviado a
                                <Typography.Text className="smallparagraphbold primary">
                                    {'\u00A0' + email.to.join(', ')}
                                </Typography.Text>
                            </Typography.Text>
                            <Typography.Text className="smallparagraph">
                                {moment(email.timestamp).format('DD MMM YY HH:mm')}
                            </Typography.Text>
                        </div>
                    ),
                })
                setCronogram(newCronogram)
            }
        } catch (error) {
            console.log('Error getting related emails', error)
        }
    }

    useEffect(() => {
        if (relationsDrawerVisible && !sentEmails.loaded) {
            setSentEmails((prev: any) => ({ ...prev, emails: [] }))
            setCronogram([])
            getRelatedEmails()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [relationsDrawerVisible, sentEmails.loaded])

    return (
        <Drawer
            style={{ marginTop: testmode ? '32px' : '0px', paddingBottom: testmode ? '32px' : '0px' }}
            open={relationsDrawerVisible}
            onClose={() => {
                dispatch(closeModal('relationsDrawerVisible'))
                setCronogram([])
                setSentEmails((prev: any) => ({ ...prev, loaded: false, emails: [] }))
                dispatch(
                    setData({
                        item: 'relationInfo',
                        data: {},
                    }),
                )
            }}
            width={window.innerWidth < 700 ? '95%' : '60%'}
            title={null}
            closable={false}
        >
            <Row align="middle" justify="center">
                <Col xs={23} style={{ marginTop: '15px' }}>
                    <Row justify="space-between" align="middle">
                        <div style={{ width: '10%' }}>
                            <ArrowCircleLeft
                                size={24}
                                weight="regular"
                                className="icon clickable"
                                onClick={() => {
                                    setSentEmails((prev: any) => ({
                                        ...prev,
                                        loaded: false,
                                        emails: [],
                                    }))
                                    setCronogram([])
                                    dispatch(
                                        setData({
                                            item: 'relationInfo',
                                            data: {},
                                        }),
                                    )
                                    dispatch(closeModal('relationsDrawerVisible'))
                                }}
                            />
                        </div>
                        <div style={{ width: '90%' }} className="d-flex flex-column">
                            <Typography.Text className="mediumheader">{visibleTitle}</Typography.Text>
                            <Row>
                                <Typography.Text
                                    className="smallparagraph"
                                    copyable={{
                                        icon: (
                                            <div className="d-flex flex-column">
                                                <Copy
                                                    weight="regular"
                                                    className="icon clickable"
                                                    size={16}
                                                    style={{ marginTop: '10px', marginLeft: 0 }}
                                                />
                                            </div>
                                        ),
                                        text: visibleItemId,
                                    }}
                                >
                                    <Tag color={tagsColors.find((t) => t.name === 'teal')?.bg} style={{ margin: 0 }}>
                                        <Typography.Text
                                            className="smallparagraphbold"
                                            style={{
                                                color: tagsColors.find((t) => t.name === 'teal')?.text,
                                            }}
                                        >
                                            {visibleItemId}
                                        </Typography.Text>
                                    </Tag>
                                </Typography.Text>
                            </Row>
                            <Typography.Text
                                onClick={() => {
                                    console.log('relationInfo', relationInfo?.item?.replicateElement)
                                }}
                                className="smallparagraph descriptions"
                            >
                                {relationInfo?.sourceType?.includes('recurring')
                                    ? getItemsAmounts(relationInfo?.item?.replicateElement?.internalItems, false)
                                          .totalString +
                                      ' ' +
                                      relationInfo?.item?.replicateElement?.currency?.toUpperCase()
                                    : relationInfo?.sourceType === 'payments'
                                      ? returnCurrencyValue(relationInfo?.item?.amount / 100)
                                      : returnCurrencyValue(relationInfo?.item?.total)}{' '}
                                {relationInfo?.item?.currency?.toUpperCase()}
                            </Typography.Text>
                        </div>
                    </Row>

                    <RelationsHandler
                        title="Pagos relacionados" // TODO Change title to match the type of relation
                        viewType="drawer"
                        type={relationInfo?.sourceType}
                        collection={'payments'}
                        item={relationInfo?.item}
                        itemId={relationInfo?.item?.id}
                    />
                    <RelationsHandler
                        title="Facturas relacionadas" // TODO Change title to match the type of relation
                        viewType="drawer"
                        type={relationInfo?.sourceType}
                        collection={'invoices'}
                        item={relationInfo?.item}
                        itemId={relationInfo?.item?.id}
                    />
                    <RelationsHandler
                        title="Recibos relacionados" // TODO Change title to match the type of relation
                        viewType="drawer"
                        type={relationInfo?.sourceType}
                        collection={'receipts'}
                        item={relationInfo?.item}
                        itemId={relationInfo?.item?.id}
                    />
                    {['recurringInvoices'].includes(relationInfo?.sourceType) && (
                        <RelationsHandler
                            title="Facturas creadas"
                            viewType="drawer"
                            type={relationInfo?.sourceType}
                            collection="invoices"
                            item={relationInfo?.item}
                            itemId={relationInfo?.item?.id}
                        />
                    )}
                    {['recurringPayments'].includes(relationInfo?.sourceType) && (
                        <RelationsHandler
                            title="Pagos creados"
                            viewType="drawer"
                            type={relationInfo?.sourceType}
                            collection="payments"
                            item={relationInfo?.item}
                            itemId={relationInfo?.item?.id}
                        />
                    )}

                    <div style={{ margin: '10px 0px' }}>
                        <Typography.Text className="smallheader" style={{ marginLeft: '15px', marginTop: '35px' }}>
                            Cronograma
                        </Typography.Text>
                        <Divider style={{ margin: '10px 0px 0px 0px' }} />

                        <Timeline
                            style={{ margin: '30px 15px' }}
                            // TODO Add more items to timeline
                            items={[
                                {
                                    id: 'creation',
                                    timestamp: item?.timestamp,
                                    dot: <HandCoins size={18} weight="regular" className="icon" />,
                                    children: (
                                        <div className="d-flex flex-column">
                                            <Typography.Text className="smallparagraphbold">
                                                {handleTimelineText()}
                                                <Typography.Text className="smallparagraphbold primary">
                                                    {'\u00A0' + readableElementSource(item?.from ?? 'manual')}
                                                </Typography.Text>
                                            </Typography.Text>
                                            <Typography.Text className="smallparagraph">
                                                {moment(item?.timestamp).format('DD MMM YY HH:mm')}
                                            </Typography.Text>
                                        </div>
                                    ),
                                },
                                ...cronogram,
                            ].sort((a: any, b: any) => b.timestamp - a.timestamp)}
                        />
                    </div>
                </Col>
            </Row>
        </Drawer>
    )
}
