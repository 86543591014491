import { Col, Row } from 'antd'
import { useSelector } from 'react-redux'
import Hero from '../../../assets/images/signup-hero.png'
import { InvitesContainer } from './InvitesContainer'

export const PendingInvitesView = ({ generateNewTeamF }: { generateNewTeamF: Function }) => {
    const { invites } = useSelector((state: any) => state.onboarding)
    return (
        <Row
            style={{
                minHeight: '100vh',
                width: '100%',
                background: 'linear-gradient(221.51deg, #000000 0.75%, #044737 69.64%)',
            }}
        >
            <Col
                xs={24}
                md={11}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    padding: '50px',
                }}
            >
                <InvitesContainer invites={invites} newTeamFunction={generateNewTeamF} />
            </Col>
            <Col xs={0} md={13}>
                <img
                    src={Hero}
                    alt="Hero"
                    draggable={false}
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        mixBlendMode: 'soft-light',
                    }}
                />
            </Col>
        </Row>
    )
}
