import { tagsColors } from '../app/components/Forms/TagsColors'

export interface AddressDef {
    country?: string
    street?: string
    zip?: string
    city?: string
    state: string
    exterior?: string | null
    interior?: string | null
    municipality?: string
    neighborhood?: string
    countryObj?: {
        value?: string
        label?: string
        code?: string
    }
}

interface ProfileDef {
    name: string
    color: string
    completed?: boolean
    textColor: string
}

export const CompleteProfileElement = ({ record }: { record: any }): ProfileDef => {
    const gr = tagsColors.filter((t) => t.name === 'gray')[0]
    // const efos = record["efos"] || { is_valid: false }
    const address = record['address'] || {
        country: '',
        street: '',
        zip: '',
        city: '',
        state: '',
        exterior: null,
        municipality: null,
        neighborhood: null,
        countryObj: {},
    }
    if (
        (record['legalName'] || record['legal_name']) &&
        record['rfc'] &&
        address.zip &&
        record['tax_system']
        // && efos["is_valid"]
    ) {
        const gc = tagsColors.filter((t) => t.name === 'green')[0]
        return {
            name: 'Completo',
            completed: true,
            color: gc.bg,
            textColor: gc.text,
        }
    } else
        return {
            name: 'Sin Info. fiscal',
            color: gr.bg,
            textColor: gr.text,
            completed: false,
        }
}

export interface ClientDef {
    RFCValidated?: boolean | null
    address?: AddressDef | any
    billingAccount: string
    efos?: any | null
    defaults?: any | null
    email: string | null
    from: string
    id: string
    company?: string | null
    legalName?: string | null
    legal_name?: string | null
    livemode: boolean
    metadata?: any | null
    name: string
    note?: string
    owner: string
    phone?: string
    rfc?: string | null
    tax_id?: string | null
    tax_system?: string | null
    team: string
    timestamp: number
    type?: string
    bcc?: string[]
    profile?: ProfileDef
    use: string
    stripeBalance?: number
    lastEdited?: {
        by: string
        timestamp: number
    }
}

export const addressSet = (o: any) => {
    const object = o || {}
    const address: AddressDef = {
        country: object['country'] ?? '',
        street: object['street'] ?? '',
        zip: object['zip'] ?? '',
        city: object['city'] ?? '',
        state: object['state'] ?? '',
        exterior: object['exterior'] ?? null,
        interior: object['interior'] ?? null,
        municipality: object['municipality'] ?? null,
        neighborhood: object['neighborhood'] ?? null,
        // TODO: change to countryObj
        countryObj: object['countryObj'] ?? {},
    }
    return address
}

export const clientSet = (o: any) => {
    const object = o || {}
    const client: ClientDef = {
        address: !object ? {} : object['address'] ? addressSet(object['address'] ?? {}) : {},
        RFCValidated: object['RFCValidated'] ?? null,
        billingAccount: object['billingAccount'] ?? null,
        efos: object['efos'] ?? '',
        email: object['email'] ?? '',
        from: object['from'] ?? '',
        id: object['id'] ?? '',
        legalName: object['legal_name'] ?? object['legalName'] ?? '',
        legal_name: object['legal_name'] ?? object['legalName'] ?? '',
        livemode: object['livemode'],
        metadata: object['metadata'] ?? null,
        defaults: object['defaults'] ?? null,
        name: object['name'] ?? null,
        note: object['note'] ?? '',
        owner: object['owner'] ?? '',
        phone: object['phone'] ?? '',
        rfc: object['rfc'] ?? '',
        tax_id: object['tax_id'] ?? '',
        tax_system: object['tax_system'] ?? '',
        team: object['team'] ?? '',
        timestamp: object['timestamp'] ?? null,
        type: object['type'] ?? null,
        company: object['company'] ?? null,
        bcc: object['bcc'] ?? [],
        profile: CompleteProfileElement({ record: object }),
        use: object['use'] ?? '',
        stripeBalance: object['stripeBalance'] ?? 0,
        lastEdited: object['lastEdited'] ?? {
            by: '',
            timestamp: 0,
        },
    }
    return client
}
