import { Question } from '@phosphor-icons/react'
import { Col, Row, Typography } from 'antd'
import { ReactNode } from 'react'

export const SettingCard: React.FC<{ title: string; description: string; icon: ReactNode; onClick?: any }> = ({
    title,
    description,
    icon,
    onClick,
}) => {
    return (
        <Col xs={24} md={24} lg={12} style={{ padding: '10px', minHeight: '88px' }}>
            <div
                className="clickable settingCard"
                onClick={onClick ?? (() => {})}
                style={{
                    padding: '15px 10px',
                    borderRadius: '10px',
                    border: '1px solid var(--Neutral-Extended-Borders, #D8DAE5)',
                }}
            >
                <Row>
                    {icon ?? <Question size={20} color="#6B7CF9" />}
                    <div className="d-flex flex-column" style={{ marginLeft: '10px', flex: 1 }}>
                        <Typography.Text className="smallheader">{title ?? '-'}</Typography.Text>
                        <Typography.Text className="smallparagraph descriptions">{description ?? '-'}</Typography.Text>
                    </div>
                </Row>
            </div>
        </Col>
    )
}
