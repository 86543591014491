import { Button, Col, Divider, Modal, Result, Row, Typography, message } from 'antd'
import React, { useEffect, useState } from 'react'

// import { SearchParamInURL } from '../../functions/helpers';
import SettingsDivider from './Components/SettingsDivider'
import { SettingCard } from './Components/SettingCard'
import { FirstAidKit, Key, List, LockKey, PlusCircle, X } from '@phosphor-icons/react'
import { useDispatch, useSelector } from 'react-redux'
import { collection, getCountFromServer, getFirestore, query, where } from 'firebase/firestore'
import { setData } from '../../context/dataSlice'
import { openModal } from '../../context/modalsSlice'
import { SignedInternalAPIRequest } from '../../functions/APIRequest'
import { useAuth } from 'reactfire'
import { MetroSpinner } from 'react-spinners-kit'
import { usePermissions } from '../../customHooks/usePermissions'
import { addParamToUrl } from '../../functions/UrlParams'

const SecureModeModal = ({ open, close }: { open: boolean; close: Function }) => {
    const { team } = useSelector((state: any) => state.team)
    const auth = useAuth()
    const [loading, setLoading] = useState(true)
    const [completed, setCompleted] = useState(false)
    const [count, setCount] = useState(0)
    const q = query(collection(getFirestore(), 'apikeys'), where('team', '==', team.id), where('valid', '==', true))

    useEffect(() => {
        return () => {
            if (!open) return
            getCountFromServer(q)
                .then((count) => {
                    setCount(count.data().count)
                    setLoading(false)
                })
                .catch((error: any) => {})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    const RequestCancelAPIKeys = async () => {
        try {
            setLoading(true)
            await SignedInternalAPIRequest(
                {
                    team: team?.id,
                },
                'cancelAPIKeys',
                auth.currentUser,
            )
            setLoading(false)
            setCompleted(true)
        } catch (error: any) {
            setLoading(false)
            message.error(error?.message ?? 'Ocurrió un error.')
            setCompleted(false)
        }
    }

    return (
        <Modal
            open={open}
            onCancel={() => close()}
            footer={null}
            title={
                <div style={{ position: 'relative', paddingBottom: '15px' }}>
                    <Typography.Text
                        className="smallheader"
                        style={{
                            position: 'absolute',
                            //center the text
                            left: '50%',
                            transform: 'translateX(-50%)',
                            paddingTop: '5px',
                        }}
                    ></Typography.Text>
                    <Row align="middle">
                        <Col xs={24} style={{ margin: 'auto' }}>
                            <Row justify="space-between" align="middle">
                                <X
                                    className="icon clickable"
                                    size={20}
                                    onClick={() => {
                                        setLoading(false)
                                        close()
                                    }}
                                />
                                <></>
                                <></>
                            </Row>
                        </Col>
                    </Row>
                </div>
            }
            closable={false}
        >
            {count === 0 && loading ? (
                <Row justify="center">
                    <MetroSpinner size={30} color="#8666FF" loading={true} />
                </Row>
            ) : count === 0 ? (
                <>
                    <Row justify="center">
                        <Typography.Text className="smallheader" style={{ textAlign: 'center' }}>
                            No cuentas con API keys activas
                        </Typography.Text>
                    </Row>
                </>
            ) : !completed ? (
                <Row justify="center">
                    <Col>
                        <Typography.Text className="smallheader" style={{ textAlign: 'center' }}>
                            Entrar en modo seguro
                        </Typography.Text>
                    </Col>
                    <Col xs={24} style={{ marginTop: '10px' }}>
                        <Row justify="center">
                            <Button
                                danger
                                loading={loading}
                                onClick={RequestCancelAPIKeys}
                                icon={<LockKey size={20} weight="regular" />}
                            >
                                Desactivar API Keys actuales
                            </Button>
                        </Row>
                    </Col>
                </Row>
            ) : (
                <Result
                    status="success"
                    title="Hemos desactivado las llaves que hubieran estado en uso."
                    subTitle="Si tienes dudas, por favor contacta a soporte."
                    extra={[
                        <Button
                            type="primary"
                            onClick={() => {
                                setCompleted(false)
                                close()
                            }}
                        >
                            Volver
                        </Button>,
                    ]}
                />
            )}
        </Modal>
    )
}

const APISettings: React.FC = () => {
    const dispatch = useDispatch()

    const { canCreateApiKeys, canCreateWebhooks } = usePermissions()

    const [secure, setSecure] = useState(false)

    return (
        // Your JSX code goes here
        <Row
            gutter={{
                xs: 10,
                md: 12,
            }}
        >
            <SecureModeModal open={secure} close={() => setSecure(false)} />

            <Col xs={24}>
                {' '}
                <Divider />{' '}
            </Col>

            <SettingsDivider
                title={'Llaves del API'}
                description={'Consulta y administra las llaves para utilizar el API de gigstack.'}
                options={[
                    <SettingCard
                        title="Keys"
                        description="Crea y elimina llaves de acceso al API."
                        icon={<Key size={20} color="#6B7CF9" weight="regular" />}
                        onClick={() => {
                            if (!canCreateApiKeys)
                                return message.warning('No tienes permisos para crear llaves del API')
                            dispatch(
                                setData({
                                    item: 'settingsDrawerConfig',
                                    data: {
                                        type: 'apikeys',
                                        title: 'Llaves del API',
                                        description:
                                            'Consulta y administra las llaves para utilizar el API de gigstack',
                                    },
                                }),
                            )
                            dispatch(openModal('settingsDrawerVisible'))
                            addParamToUrl('open', 'settings_apikeys')
                        }}
                    />,
                    <SettingCard
                        title="Modo seguro"
                        description="Deshabilita las llaves del API para evitar que se utilicen, por ejemplo, en caso de haberlas comprometido."
                        icon={<FirstAidKit size={20} color="#6B7CF9" weight="regular" />}
                        onClick={() => {
                            if (!canCreateApiKeys)
                                return message.warning('No tienes permisos para crear llaves del API')
                            setSecure(true)
                        }}
                    />,
                ]}
            />
            <Col xs={24}>
                {' '}
                <Divider />{' '}
            </Col>
            <SettingsDivider
                title={'Webhooks'}
                description={'Agrega URLs para recibir información de los eventos que ocurren en gigstack.'}
                options={[
                    <SettingCard
                        title="Creados"
                        description="Administra los webhooks que ya has creado."
                        icon={<List size={20} color="#6B7CF9" weight="regular" />}
                        onClick={() => {
                            if (!canCreateWebhooks) return message.warning('No tienes permisos para crear webhooks')
                            dispatch(
                                setData({
                                    item: 'settingsDrawerConfig',
                                    data: {
                                        type: 'webhooks.list',
                                        title: 'Webhooks',
                                        description: 'Administra los webhooks que ya has creado.',
                                    },
                                }),
                            )
                            dispatch(openModal('settingsDrawerVisible'))
                            addParamToUrl('open', 'settings_webhooks')
                        }}
                    />,
                    <SettingCard
                        title="Nuevo Webhook"
                        description="Crea un nuevo webhook para recibir información de los eventos que ocurren en gigstack."
                        icon={<PlusCircle size={20} color="#6B7CF9" weight="regular" />}
                        onClick={() => {
                            if (!canCreateWebhooks) return message.warning('No tienes permisos para crear webhooks')
                            dispatch(
                                setData({
                                    item: 'settingsDrawerConfig',
                                    data: {
                                        type: 'webhooks.create',
                                        title: 'Nuevo Webhook',
                                        description:
                                            'Crea un nuevo webhook para recibir información de los eventos que ocurren en gigstack.',
                                    },
                                }),
                            )
                            dispatch(openModal('settingsDrawerVisible'))
                            addParamToUrl('open', 'settings_webhooks_create')
                        }}
                    />,
                ]}
            />
        </Row>
    )
}

export default APISettings
