import { Button, Modal, Row, Typography, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../context/modalsSlice'
import { integrations } from '../../datasets/Integrations'
import { IntegrationOnboarding } from '../../components/Integrations/IntegrationOnboarding'
import { Question } from '@phosphor-icons/react'
import { useEffect } from 'react'
import { addParamToUrl } from '../../functions/UrlParams'

export const HilosModal = () => {
    const dispatch = useDispatch()

    const { hilosModalVisible } = useSelector((state: any) => state.modals)
    const { team } = useSelector((state: any) => state.team)

    const hilosIntegration = integrations(team).find((i) => i.id === 'hilos')

    useEffect(() => {
        if (hilosModalVisible) {
            addParamToUrl('open', 'hilos')
        }
    }, [hilosModalVisible])

    return (
        <Modal
            open={hilosModalVisible}
            onCancel={() => {
                dispatch(closeModal('hilosModalVisible'))
            }}
            className="integrationModal"
            title={null}
            footer={null}
        >
            <IntegrationOnboarding
                logo={hilosIntegration?.logo ?? ''}
                title="Conecta Hilos con Gigstack"
                description="Envía mensajes de Whatsapp a tus clientes"
                bullets={[
                    { type: 'positive', description: 'Envía mensajes de Whatsapp a tus clientes' },
                    { type: 'positive', description: 'Envía links de pago a tus clientes' },
                    { type: 'positive', description: 'Envía links de autofactura' },
                ]}
                IntegrationFooter={
                    <Row justify="space-between" style={{ padding: '20px 24px 20px 24px' }}>
                        <Row>
                            <Button icon={<Question className="icon clickable" weight="regular" size={16} />}>
                                <Typography.Text className="mediumparagraphbold">¿Cómo funciona?</Typography.Text>
                            </Button>
                        </Row>
                        <Row>
                            <Button type="primary" onClick={() => message.info('Próximamente')}>
                                Conectar
                            </Button>
                        </Row>
                    </Row>
                }
            />
        </Modal>
    )
}
