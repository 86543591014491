export const CompanyRoles = [
    { label: 'Dirección', value: 'Dirección' },
    { label: 'Desarrollo', value: 'Desarrollo' },
    { label: 'Ventas', value: 'Ventas' },
    { label: 'Marketing', value: 'Marketing' },
    { label: 'RRHH', value: 'RRHH' },
    { label: 'Contabilidad', value: 'Contabilidad' },
    { label: 'Administración', value: 'Administración' },
    { label: 'Finanzas', value: 'Finanzas' },
    { label: 'Customer Service', value: 'Customer Service' },
    { label: 'Otro', value: 'Otro' },
]
