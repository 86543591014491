import { Button, Col, Form, Image, Row, message } from 'antd'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import moment from 'moment'
import { usePostHog } from 'posthog-js/react'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TeamDef, brandDefSet } from '../../../interfaces/teamDef'
import { setTeam } from '../../context/teamsSlice'
import ErrorsInFormComponent from './ErrorsInFormComponent'
import { ColorInput, FileUpload, TextInput } from './Inputs'
// import { useNavigate } from 'react-router-dom';

interface FormProps {
    buttonTitle?: string
    onUpdate?: (data: any) => void
    incomingTeam?: any
    onValuesUpdate?: (data: any) => void
    showLogoPreview?: boolean
    updateContext?: boolean
}

const TeamForm: React.FC<FormProps> = ({
    buttonTitle,
    onUpdate,
    incomingTeam,
    onValuesUpdate,
    showLogoPreview,
    updateContext,
}) => {
    // Add your component logic here
    // const navigate = useNavigate()
    const posthog = usePostHog()
    const dispatch = useDispatch()
    const { team } = useSelector((state: any) => state.team)

    const { billingAccount } = useSelector((state: any) => state.billingAccount)
    const [form] = Form.useForm()
    const [errors, setErrors] = useState<any>([])
    const [loading, setLoading] = useState(false)
    const [logo, setLogo] = useState(team?.brand?.logo ?? '')

    const [messageApi, contextHolder] = message.useMessage()

    const onFinish = async (values: any) => {
        try {
            form.validateFields()
        } catch (error: any) {
            setErrors(error.errorFields)
            return
        }

        var primaryColor =
            typeof values?.primaryColor === 'string' ? values?.primaryColor : `#${values?.primaryColor?.toHex() ?? ''}`
        var secondaryColor =
            typeof values?.secondaryColor === 'string'
                ? values?.secondaryColor
                : `#${values?.secondaryColor?.toHex() ?? ''}`

        const branddef = brandDefSet({
            ...team?.brand,
            primaryColor,
            secondaryColor,
            logo: logo,
            alias: values.alias ?? team?.brand?.alias ?? '',
        })
        const teamdef = {
            ...(incomingTeam ?? team ?? {}),
            brand: branddef,
            supportEmail: values.supportEmail ?? team?.supportEmail ?? '',
        } as TeamDef

        setLoading(true)

        try {
            try {
                const firstTime = window.location?.pathname?.includes('finishSetup')
                posthog?.capture(firstTime ? 'first_time_team_setup' : 'team_updated_profile', {
                    [firstTime ? 'teamProfileCompleted' : 'teamUpdated']: moment().format('DD/MM/YYYY HH:mm'),
                })
            } catch (error) {}
            await updateDoc(doc(getFirestore(), 'teams', team.id), { ...teamdef })
            dispatch(setTeam(teamdef))
            if (billingAccount.pendingTeamConfiguration === team.id) {
                try {
                    posthog?.capture('using_pending_configuration', {
                        pendingTeamConfig: billingAccount.pendingTeamConfiguration,
                    })
                } catch (error) {}
                await updateDoc(doc(getFirestore(), 'billingAccounts', billingAccount.id), {
                    pendingTeamConfiguration: null,
                })
            }

            setLoading(false)
            if (onUpdate) {
                onUpdate(teamdef)
            }
            if (!showLogoPreview) {
                // navigate('/gettingStarted')
                // window.location.reload()
            }
        } catch (error) {
            setLoading(false)
            messageApi.error('Ocurrió un error al actualizar la información')
        }
    }

    return (
        // Add your JSX code here
        <Form
            layout="vertical"
            onValuesChange={(changedValues, allValues) => {
                if (onValuesUpdate) {
                    onValuesUpdate({
                        ...allValues,
                        primaryColor:
                            typeof allValues?.primaryColor === 'string'
                                ? allValues?.primaryColor
                                : `#${allValues?.primaryColor?.toHex() ?? ''}`,
                        secondaryColor:
                            typeof allValues?.secondaryColor === 'string'
                                ? allValues?.secondaryColor
                                : `#${allValues?.secondaryColor?.toHex() ?? ''}`,
                    })
                }
            }}
            onFinish={onFinish}
            onError={(e: any) => {
                setErrors(e.errorFields)
            }}
            onErrorCapture={(e: any) => {
                setErrors(e.errorFields)
            }}
            form={form}
            initialValues={{
                ...(team?.brand ?? {}),
                supportEmail: team?.supportEmail,
                ...incomingTeam,
                primaryColor: team?.brand?.primaryColor !== '' ? team?.brand?.primaryColor : '#8666FF',
                secondaryColor: team?.brand?.secondaryColor !== '' ? team?.brand?.secondaryColor : '#D3C2FF',
            }}
        >
            {contextHolder}
            <Row gutter={[32, { xs: 12, lg: 12 }]}>
                {/* <Col xs={24} lg={24}>
                    <Divider />
                </Col> */}
                <Col xs={24}>
                    <Row align="middle">
                        {showLogoPreview && (
                            <Image
                                src={team?.brand?.logo ?? ''}
                                height={50}
                                style={{ objectFit: 'contain', marginRight: '10px' }}
                            />
                        )}
                        <div style={{ flex: 1 }}>
                            <FileUpload
                                showUploadList={true}
                                allowedFiles={['image/png', 'image/jpeg', 'image/jpg']}
                                placeholder="Sube el logo de la empresa"
                                label="Logo"
                                name="logo"
                                storagePath={`teams/${team.id}/files/logo`}
                                firestorePath={`teams/${team.id}/files/logo`}
                                previousDataKey="brand"
                                firestoreKey="logo"
                                firestorePreviousData={team?.brand}
                                onURLUploaded={(url: string) => {
                                    console.log(url)

                                    if (onValuesUpdate) {
                                        onValuesUpdate({ logo: url })
                                    }
                                    if (updateContext) {
                                        const brd = {
                                            ...team?.brand,
                                            ...form.getFieldsValue(),
                                            primaryColor:
                                                typeof form.getFieldsValue()?.primaryColor === 'string'
                                                    ? form.getFieldsValue()?.primaryColor
                                                    : `#${form.getFieldsValue()?.primaryColor?.toHex() ?? ''}`,
                                            secondaryColor:
                                                typeof form.getFieldsValue()?.secondaryColor === 'string'
                                                    ? form.getFieldsValue()?.secondaryColor
                                                    : `#${form.getFieldsValue()?.secondaryColor?.toHex() ?? ''}`,
                                            logo: url,
                                            alias: form.getFieldsValue()?.alias ?? team?.brand?.alias ?? '',
                                        }
                                        dispatch(setTeam({ ...team, brand: brd }))
                                        form.setFieldsValue(brd)
                                    }
                                    setLogo(url)
                                }}
                            />
                        </div>
                    </Row>
                </Col>
                <Col xs={24}>
                    <TextInput
                        label="Nombre Comercial"
                        placeholder="Nombre comercial"
                        name="alias"
                        rules={[
                            {
                                required: true,
                                message: 'Este campo es requerido',
                            },
                        ]}
                    />
                </Col>
                <Col xs={24}>
                    <TextInput
                        label="Correo de soporte"
                        placeholder="soporte@empresa.com"
                        name="supportEmail"
                        rules={[
                            {
                                required: true,
                                message: 'Añade un correo de soporte para tus clientes',
                            },
                            {
                                type: 'email',
                                message: 'Ingresa un correo válido',
                            },
                        ]}
                    />
                </Col>
                {/* {
                    showLogoPreview && <Col xs={24}>
                        <Row>
                            <Image
                                src={team?.brand?.logo ?? ''}
                                height={50}

                                style={{ objectFit: 'contain' }} />
                        </Row>
                    </Col>
                } */}

                <Col xs={24} md={12}>
                    <div className="colorOverride">
                        <ColorInput
                            label="Color Primario"
                            name="primaryColor"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido',
                                },
                            ]}
                        />
                    </div>
                </Col>
                <Col xs={24} md={12}>
                    <ColorInput
                        label="Color Secundario"
                        name="secondaryColor"
                        rules={[
                            {
                                required: true,
                                message: 'Este campo es requerido',
                            },
                        ]}
                    />
                </Col>

                <Col xs={24}>
                    <ErrorsInFormComponent title={''} form={form} formErrors={errors} />
                </Col>

                <Col xs={24} style={{ marginTop: '20px' }}>
                    <Row>
                        <Button loading={loading} className="btn-primary w-100" htmlType="submit">
                            {buttonTitle ?? 'Actualizar información'}{' '}
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Form>
    )
}

export default TeamForm
