import { PaperPlaneTilt } from '@phosphor-icons/react'
import { Row, Tooltip, Typography } from 'antd'
import React from 'react'
import { MetroSpinner } from 'react-spinners-kit'

type Props = {
    action: () => void
    loading: boolean
    full?: boolean
    isMenu?: boolean
}

const EmailActions = ({ action, loading, full, isMenu }: Props) => {
    const LoadingIcon = () => {
        return <MetroSpinner size={16} color="#686769" />
    }

    return (
        <Row onClick={action}>
            {isMenu ? (
                <Typography>Enviar por correo</Typography>
            ) : (
                <Tooltip title="Enviar por correo">
                    {loading ? (
                        <LoadingIcon />
                    ) : (
                        <PaperPlaneTilt size={18} weight="regular" className="icon clickable" />
                    )}
                    {full && (
                        <Typography.Text style={{ margin: 0, padding: 0, marginLeft: '5px' }}>
                            Enviar por correo
                        </Typography.Text>
                    )}
                </Tooltip>
            )}
        </Row>
    )
}

export default EmailActions

// <Tooltip title="Enviar por correo">
//     <Row onClick={action}>
//         {loading ? <LoadingIcon /> : <PaperPlaneTilt size={18} weight="regular" className="icon clickable" />}
//         {full && (
//             <Typography.Text style={{ margin: 0, padding: 0, marginLeft: '5px' }}>
//                 Enviar por correo
//             </Typography.Text>
//         )}
//     </Row>
// </Tooltip>
