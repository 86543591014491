import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter'
import { TeamDef } from '../../interfaces/teamDef'

const typesenseClient = ({
    team,
    testmode,
    indexName,
}: {
    team: TeamDef
    testmode: boolean
    indexName: 'payments' | 'invoices' | 'receipts'
}) => {
    let base = 'client.name,client.email,fid,charge,objectId,whmcsInvoiceId,metadata.orderId'
    let queryBy = base

    switch (indexName) {
        case 'invoices':
            queryBy =
                'client.name,client.email,uuid,client.legal_name,client.rfc,metadata.orderId,objectId,whmcsInvoiceId'
            // folio_number
            break
        case 'receipts':
            queryBy = 'client.name,client.email,client.legal_name,objectId,whmcsInvoiceId'
            break
        case 'payments':
            queryBy = base
            break
        default:
            break
    }

    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: {
            apiKey: team?.typesense?.keys?.live_key ?? '',
            nodes: [
                {
                    host: 'typesense.gigstack.pro',
                    port: 443,
                    protocol: 'https',
                },
            ],
            connectionTimeoutSeconds: 500,
        },
        additionalSearchParameters: {
            query_by: queryBy,
            facet_by: `team:${team?.id},livemode:${testmode ? false : true}`,
            sort_by: 'timestamp:desc',
        },
    })

    return typesenseInstantsearchAdapter.searchClient
}

export default typesenseClient
