const neutral = {
    headlines: '#101840',
    text_muted: '#696F8C',
    icons_muted: '#C1C4D6',
    text_description: '#989BAB',
    border_muted: '#E6E8F0',
    background_lighter: '#FAFBFF',
    background_darker: '#EDEFF5',
    text_normal: '#474D66',
    icons_normal: '#50566B',
    border_normal: '#D8DAE5',
    background_light: '#F9FAFC',
    background_white: '#FFFFFF',
}

const purple = {
    600: '#483593',
    500: '#6A50CC',
    400: '#8666FF', // Primary
    300: '#AD97FF',
    200: '#D1C5FF',
    100: '#E8E2FF',
    50: '#F8F6FF', // Lightest
}

const green = {
    600: '#317159',
    500: '#429777',
    400: '#52BD94', // Primary
    300: '#7FDDB3',
    200: '#DCF2EA',
    100: '#EEF8F4',
    50: '#F5FBF8', // Lightest
}

const yellow = {
    600: '#66460D',
    500: '#996A13',
    400: '#FFB020', // Primary
    100: '#FFEFD2',
    50: '#FFFAF1', // Lightest
}

const red = {
    600: '#7D2828',
    500: '#A73636',
    400: '#D14343', // Primary
    100: '#F9DADA',
    50: '#FDF4F4', // Lightest
}

const blue = {
    600: '#515FC3',
    400: '#6B7CF9', // Primary
    100: '#D3D9FF',
    50: '#D3D9FF', // Lightest
}

const teal = {
    600: '#0F5156',
    100: '#25CBD6', // Primary
    50: '#D3F5F7', // Lightest
}

const pink = {
    600: '#8E3374',
    400: '#ED55C2', // Primary
    50: '#FBDDF3', // Lightest
}

const orange = {
    600: '#85462B',
    400: '#DE7548', // Primary
    100: '#F8E3DA', // Lightest
}

export const colors = {
    // PURPLE
    purple_600: purple[600],
    purple_500: purple[500],
    purple_400: purple[400],
    purple_300: purple[300],
    purple_200: purple[200],
    purple_100: purple[100],
    purple_50: purple[50],
    primary: purple[400],
    purple: purple[400],
    purple_light: purple[50],

    // GREEN
    green_600: green[600],
    green_500: green[500],
    green_400: green[400],
    green_300: green[300],
    green_200: green[200],
    green_100: green[100],
    green_50: green[50],
    green: green[400],
    green_light: green[50],

    // YELLOW
    yellow_600: yellow[600],
    yellow_500: yellow[500],
    yellow_400: yellow[400],
    yellow_100: yellow[100],
    yellow_50: yellow[50],
    yellow: yellow[400],
    yellow_light: yellow[50],

    // RED
    red_600: red[600],
    red_500: red[500],
    red_400: red[400],
    red_100: red[100],
    red_50: red[50],
    red: red[400],
    red_light: red[50],

    // BLUE
    blue_600: blue[600],
    blue_400: blue[400],
    blue_100: blue[100],
    blue_50: blue[50],
    blue: blue[400],
    blue_light: blue[50],

    // TEAL
    teal_600: teal[600],
    teal_100: teal[100],
    teal_50: teal[50],
    teal: teal[100],
    teal_light: teal[50],

    // PINK
    pink_600: pink[600],
    pink_400: pink[400],
    pink_50: pink[50],
    pink: pink[400],
    pink_light: pink[50],

    // ORANGE
    orange_600: orange[600],
    orange_400: orange[400],
    orange_100: orange[100],
    orange: orange[400],
    orange_light: orange[100],

    // NEUTRAL
    ...neutral,
}
