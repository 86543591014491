import React from 'react'
import EditAction from './EditAction'
import { Button, Col, Popconfirm, Popover, Row, Space, Tooltip, Typography, message } from 'antd'
import DeleteAction from './DeteleAction'
import { arrayUnion, doc, getFirestore, updateDoc } from 'firebase/firestore'
import { useDispatch, useSelector } from 'react-redux'
import { setData } from '../../../context/dataSlice'
import { usePermissions } from '../../../customHooks/usePermissions'
import moment from 'moment'
import { openModal, setElementSelected } from '../../../context/modalsSlice'
import { Clock, Lightning, WarningCircle } from '@phosphor-icons/react'
import dayjs from 'dayjs'

const RecurringEventsActions = ({ record, customEdit }: { record: any; customEdit?: any }) => {
    const { canCreateInvoice } = usePermissions()
    const dispatch = useDispatch()
    const { user } = useSelector((state: any) => state.user)
    const ForceExecution = async () => {
        try {
            await updateDoc(doc(getFirestore(), `recurringEvents/${record?.id}`), {
                status: 'active',
                nextRun: moment().add(1, 'minute').valueOf(),
                tried: 0,
                errors: [],
                errorMessages: [],
                processed: false,
                pausedReason: null,
                logs: arrayUnion({
                    timestamp: moment().valueOf(),
                    message: 'Ejecución forzada',
                    uid: user?.uid,
                }),
            })
            message.success('Ejecutaremos la automatización en un minuto')
        } catch (error: any) {
            message.error(error.message ?? 'Error al pausar evento')
        }
    }
    return (
        <Space size="small" align="end">
            {customEdit
                ? customEdit
                : canCreateInvoice && (
                      <EditAction
                          action={() => {
                              dispatch(openModal('invoiceModalVisible'))
                              dispatch(
                                  setData({
                                      item: 'invoice',
                                      data: {
                                          id: record?.id,
                                          ...record?.replicateElement,
                                          step: 1,
                                          type:
                                              record?.replicateElement?.invoiceType ??
                                              record?.replicateElement?.type ??
                                              'I',
                                          startDate: dayjs(record.startDate).valueOf(),
                                          endDate: dayjs(record.endDate).valueOf(),
                                          startEndDate: [
                                              dayjs(record.startDate).valueOf(),
                                              dayjs(record.endDate).valueOf(),
                                          ],
                                          from: 'recurringEvent',
                                          // timestamp: dayjs(record.timestamp)
                                      },
                                  }),
                              )
                              dispatch(
                                  setData({
                                      item: 'recurringEventData',
                                      data: {
                                          id: record?.id,
                                          ...record,
                                          startDate: dayjs(record.startDate).valueOf(),
                                          endDate: dayjs(record.endDate).valueOf(),
                                          startEndDate: [
                                              dayjs(record.startDate).valueOf(),
                                              dayjs(record.endDate).valueOf(),
                                          ],
                                          // timestamp: dayjs(record.timestamp)
                                      },
                                  }),
                              )
                              dispatch(setData({ item: 'client', data: record?.client }))
                              dispatch(
                                  setData({ item: 'selectedServices', data: record?.replicateElement?.internalItems }),
                              )
                              dispatch(
                                  setElementSelected({
                                      element: 'recurringInvoice',
                                      data: true,
                                  }),
                              )
                          }}
                          loading={false}
                      />
                  )}
            <Tooltip title="Borrar recurrencia?">
                <DeleteAction
                    action={async () => {
                        try {
                            await updateDoc(doc(getFirestore(), 'recurringEvents', record.id), {
                                status: 'deleted',
                                team: `old-${record?.team}`,
                                logs: arrayUnion({
                                    timestamp: moment().valueOf(),
                                    message: 'Evento eliminado',
                                    uid: user?.uid,
                                }),
                            })
                        } catch (error: any) {
                            message.error(error.message ?? 'Error al pausar evento')
                        }
                    }}
                    loading={false}
                />
            </Tooltip>

            {(record?.errors ?? []).length > 0 && (
                <Popover
                    title="Errores en ejecución"
                    content={
                        <div style={{ width: '200px', maxHeight: '200px', overflowY: 'auto' }}>
                            {record?.errorMessages.map((e: any, key: string) => (
                                <div
                                    key={key}
                                    style={{
                                        marginBottom: '8px',
                                        borderBottom: '1px solid #ddd',
                                        paddingBottom: '5px',
                                    }}
                                    className="d-flex flex-column"
                                >
                                    <Typography.Text className="smallparagraph descriptions">
                                        {e?.message}
                                    </Typography.Text>
                                    <Typography.Text
                                        style={{ marginTop: '5px' }}
                                        className="smallparagraph descriptions"
                                    >
                                        <Clock style={{ paddingRight: '5px' }} />{' '}
                                        {moment(e?.timestamp).format('DD MMM YYYY HH:mm')}
                                    </Typography.Text>
                                </div>
                            ))}
                        </div>
                    }
                >
                    <Row style={{ margin: 0, padding: 0 }}>
                        <WarningCircle weight="regular" className="icon clickable" size={18} />
                    </Row>
                </Popover>
            )}
            <Tooltip title="Ejecutar automatización">
                <Popconfirm
                    onConfirm={ForceExecution}
                    okText="Ejecutar"
                    title={<Typography.Text className="smallheader">Forzar la ejecución</Typography.Text>}
                    description={
                        <Typography.Text className="smallparagraph">
                            Se ejecutará la automatización de forma inmediata
                        </Typography.Text>
                    }
                >
                    <Row style={{ margin: 0, padding: 0 }}>
                        <Lightning size={18} weight="regular" className="icon" />
                    </Row>
                </Popconfirm>
            </Tooltip>
            {record?.pausedReason && record?.status === 'paused' && (
                <Row>
                    <Popover
                        align={{ offset: [-100, 0] }}
                        content={
                            <div style={{ maxWidth: '250px', minWidth: '250px' }}>
                                <Row>
                                    <Col xs={24}>
                                        <Typography.Text className="smallheader">Motivo de pausa</Typography.Text>
                                    </Col>
                                    <Col xs={24}>
                                        <Typography.Text className="smallparagraph">
                                            {record?.pausedReason}
                                        </Typography.Text>
                                    </Col>
                                    <Col xs={24}>
                                        <Typography.Text className="smallparagraph">
                                            Último evento NO EJECUTADO fue programado para{' '}
                                            {moment(record?.nextRun).format('DD MMMM YYYY HH:mm')}
                                        </Typography.Text>
                                    </Col>
                                    <Col xs={24} style={{ marginTop: '10px' }}>
                                        {record?.endDate >= moment().valueOf() && (
                                            <Popconfirm
                                                onConfirm={ForceExecution}
                                                okText="Ejecutar"
                                                title={
                                                    <Typography.Text className="smallheader">
                                                        Forzar la ejecución
                                                    </Typography.Text>
                                                }
                                                description={
                                                    <Typography.Text className="smallparagraph">
                                                        Se ejecutará la automatización de forma inmediata
                                                    </Typography.Text>
                                                }
                                            >
                                                <Button type="dashed">Ejecutar</Button>
                                            </Popconfirm>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        }
                    >
                        <WarningCircle weight="regular" className="icon clickable" size={18} />
                    </Popover>
                </Row>
            )}
        </Space>
    )
}

export default RecurringEventsActions
