import { Col } from 'antd'

interface StepLayoutProps {
    form: JSX.Element
    supportElement?: JSX.Element
}

const StepLayout = ({ form, supportElement }: StepLayoutProps) => {
    return (
        <>
            <Col
                xs={24}
                md={12}
                style={{
                    padding: '24px',
                    height: '100%',
                    overflowY: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        maxWidth: '420px',
                        minWidth: '420px',
                        margin: '0 auto',
                    }}
                >
                    {form}
                </div>
            </Col>
            <Col
                xs={0}
                md={12}
                style={{
                    background: 'var(--neutral-8)',
                    height: '100%',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <div style={{ margin: '0 auto' }}>{supportElement}</div>
            </Col>
        </>
    )
}

export default StepLayout
