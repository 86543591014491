import React, { useState } from 'react'
import { FormInstance, Col, Row, Form, Button, message, Alert, Typography, Tag, Divider, Popconfirm } from 'antd'
import { useSelector } from 'react-redux'
import { collection, getFirestore, query, where } from 'firebase/firestore'
import ClientDataWrapper from '../../datawrappers/clientDataWrapper'
import { Info, Key } from '@phosphor-icons/react'
import moment from 'moment'
import { SignedInternalAPIRequest } from '../../functions/APIRequest'
import { useAuth } from 'reactfire'
import { MetroSpinner } from 'react-spinners-kit'

interface PropsDef {
    incomingForm?: FormInstance
    buttonTitle?: string
}

const ApiKeysForm: React.FC<PropsDef> = ({ incomingForm, buttonTitle }) => {
    const [form] = Form.useForm()
    const usingForm = incomingForm ?? form
    const [messageApi, contextHolder] = message.useMessage()
    const { team } = useSelector((state: any) => state.team)
    const q = query(collection(getFirestore(), 'apikeys'), where('team', '==', team.id), where('valid', '==', true))
    const auth = useAuth()

    const [loading, setLoading] = useState('')

    const onFinish = async (values: any) => {
        try {
            await usingForm.validateFields()
            messageApi.open({
                type: 'loading',
                content: 'Actualizando información...',
                duration: 0,
            })
        } catch (e) {}
    }

    const RequestAPIKeys = async () => {
        //generateAPIKeys
        if (loading === 'generate') return
        try {
            setLoading('generate')
            try {
                await RequestCancelAPIKeys()
            } catch (error) {}
            await SignedInternalAPIRequest(
                {
                    team: team?.id,
                },
                'publicApiKeys/generate',
                auth.currentUser,
            )
            message.success('API Keys generadas correctamente.')
            setLoading('')
        } catch (error: any) {
            message.error(error?.message ?? 'Ocurrió un error.')
            setLoading('')
        }
    }
    const RequestCancelAPIKeys = async () => {
        return await SignedInternalAPIRequest(
            {
                team: team?.id,
            },
            'publicApiKeys/cancel',
            auth.currentUser,
        )
    }
    return (
        <Form layout="vertical" form={usingForm} onFinish={onFinish}>
            {/* Form content goes here */}
            {contextHolder}

            <Alert
                message={
                    <Typography.Text className="smallparagraph descriptions">
                        Por favor mantén tus API Keys seguras y si sientes que han sido comprometidas, cancelalas y
                        genera nuevas..
                    </Typography.Text>
                }
                type="info"
                showIcon
                icon={<Key className="icon" weight="regular" />}
            />
            <Divider />
            {loading === 'generate' ? (
                <Row>
                    <Col xs={24}>
                        <Row justify="center">
                            <MetroSpinner size={30} color="#5B8FF9" loading={true} />
                        </Row>
                    </Col>
                </Row>
            ) : (
                <ClientDataWrapper
                    query={q}
                    clientId=""
                    renderData={(d: any) => {
                        return (
                            <>
                                <label style={{ marginBottom: '25px' }}>API Keys activas</label>
                                {d.map((item: any) => {
                                    return (
                                        <Row key={item.id} style={{ marginTop: '10px' }}>
                                            <Col xs={24}>
                                                <Row justify="space-between">
                                                    <Row>
                                                        <Tag color={item.livemode ? 'green' : 'blue'}>
                                                            {item?.livemode ? 'Producción' : 'Sandbox'}
                                                        </Tag>
                                                        <div className="d-flex flex-column">
                                                            <Typography.Text className="smallparagraphbold">
                                                                {item.keyid}
                                                            </Typography.Text>
                                                            <Typography.Text className="smallparagraph ">
                                                                {moment(item.timestamp).format('DD MMM YYYY HH:mm')}
                                                            </Typography.Text>
                                                        </div>
                                                    </Row>
                                                    <Button
                                                        type="primary"
                                                        size="small"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(item.apikey)
                                                            message.success('Key copiada al portapapeles')
                                                        }}
                                                    >
                                                        Copiar Key
                                                    </Button>
                                                </Row>
                                            </Col>
                                        </Row>
                                    )
                                })}
                            </>
                        )
                    }}
                />
            )}
            <Divider />

            <Row>
                <Col xs={24}>
                    <Popconfirm
                        onConfirm={RequestAPIKeys}
                        title={
                            <Typography.Text className="smallparagraph">
                                Las API Keys anteriores serán canceladas. ¿Deseas continuar?
                            </Typography.Text>
                        }
                        okText="Generar nuevas"
                        icon={<Info size={20} className="icon" />}
                    >
                        <Button style={{ width: '100%' }} type="dashed">
                            Generar nuevas API Keys
                        </Button>
                    </Popconfirm>
                </Col>
            </Row>

            <Row>
                <Col xs={24}>
                    <div
                        style={{
                            border: '1px solid #E8E8E8',
                            background:
                                'linear-gradient(180deg, rgba(186, 216, 239, 0.34) 0%, rgba(134, 102, 255, 0.06) 100%)',
                            borderRadius: '10px',
                            padding: '15px',
                            marginTop: '25px',
                        }}
                        className="d-flex flex-column"
                    >
                        <Typography.Text className="smallparagraphbold">Documentación</Typography.Text>
                        <Typography.Text className="smallparagraph descriptions">
                            Consulta la documentación para utilizar el API, en https://gigstack.xyz/api-docs
                        </Typography.Text>
                        <div>
                            <Button
                                style={{ marginTop: '10px' }}
                                type="primary"
                                onClick={() => {
                                    window.open('https://gigstack.xyz/api-docs', '_blank')
                                }}
                            >
                                Visitar documentación
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>

            {/* <Row>
                <Col span={24}>
                    <Row justify="end">
                        <Button type="primary" htmlType="submit">
                            {buttonTitle ?? 'Actualizar información'}
                        </Button>
                    </Row>
                </Col>
            </Row> */}
        </Form>
    )
}

export default ApiKeysForm
