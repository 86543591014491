import { Alert, Layout, Typography } from 'antd'

function FallbackErrorComponent({ error, resetErrorBoundary }: { error: any; resetErrorBoundary: any }) {
    return (
        <Layout.Content style={{ padding: '20px' }}>
            <Alert
                message={
                    <Typography.Title
                        level={5}
                        style={{ margin: 0, padding: 0, fontSize: '14px' }}
                    >{`Error: ${error.name ?? ''} ${error.code ?? ''}`}</Typography.Title>
                }
                description={
                    <Typography.Text style={{ fontSize: '13px' }} type="secondary">
                        {error.message ?? ''}
                    </Typography.Text>
                }
                type="error"
                closable={false}
                action={
                    <Typography.Link onClick={() => resetErrorBoundary()} style={{ fontSize: '13px' }}>
                        Volver a intentar
                    </Typography.Link>
                }
            />
        </Layout.Content>
    )
}

export default FallbackErrorComponent
