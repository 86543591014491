import { Button, Col, Modal, Row, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
import { setData } from '../context/dataSlice'
import { RelationsHandler } from '../components/Relations/RelationsHandler'
import { useRelations } from '../customHooks/useRelations'
import { handleDef } from '../../helpers/helperFunctions'
import { SignedInternalAPIRequest } from '../functions/APIRequest'
import { useAuth } from 'reactfire'
import { doc, getDoc, getFirestore } from 'firebase/firestore'
import { MetroSpinner } from 'react-spinners-kit'
import { useState } from 'react'

const setSubTitle = (paymentReceipts: number, paymentInvoices: number) => {
    const receiptText = paymentReceipts
        ? paymentReceipts === 1
            ? '1 recibo relacionado pendiente'
            : `${paymentReceipts} recibos relacionados pendientes`
        : ''
    const invoiceText = paymentInvoices
        ? paymentInvoices === 1
            ? '1 factura relacionada válida'
            : `${paymentInvoices} facturas relacionadas válidas`
        : ''

    const conjunction = paymentReceipts && paymentInvoices ? ' y ' : ''

    return `Encontramos ${receiptText}${conjunction}${invoiceText}.`
}

const fillReceipts = (item: any, type: string) => {
    let receiptsData: any[] = []
    let receiptsStatus = ''

    if (item.receipts?.length) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { status, data } = useRelations({
            relatedToCollection: 'receipts',
            relatedToId: item.fid ?? item.id ?? '',
            searchInKey: type,
            def: handleDef('receipts'),
        })

        receiptsData = data
        receiptsStatus = status
    }

    return { receiptsData, receiptsStatus }
}

const fillInvoices = (item: any, type: string) => {
    let invoicesData: any[] = []
    let invoicesStatus = ''

    if (item.invoices?.length) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { status, data } = useRelations({
            relatedToCollection: 'invoices',
            relatedToId: item.fid ?? item.id ?? '',
            searchInKey: type,
            def: handleDef('invoices'),
        })

        invoicesData = data
        invoicesStatus = status
    }

    return { invoicesData, invoicesStatus }
}

const CancelResourcesModal = () => {
    const [messageApi, contextHolder] = message.useMessage()
    const [loadingPayment, setLoadingPayment] = useState(false)
    const [loadingPaymentResource, setLoadingPaymentResource] = useState(false)
    const dispatch = useDispatch()
    const auth = useAuth()

    const { cancelResourcesModalVisible } = useSelector((state: any) => state.modals)
    const { relationInfo } = useSelector((state: any) => state.data)

    let { title, sourceType: type, item } = relationInfo

    const { receiptsData, receiptsStatus } = fillReceipts(item, type)
    const { invoicesData, invoicesStatus } = fillInvoices(item, type)

    const pendingReceipts = receiptsData.filter((r) => r.status === 'pending')
    const validInvoices = invoicesData.filter((i) => i.status === 'valid')

    const pendingReceiptsLength = pendingReceipts.length
    const validInvoicesLength = validInvoices.length

    const existSomeResourcesToCancel = pendingReceiptsLength + validInvoicesLength >= 1

    const subTitle = existSomeResourcesToCancel ? setSubTitle(pendingReceiptsLength, validInvoicesLength) : ''

    title = existSomeResourcesToCancel ? title : '¿Estás seguro que deseas cambiar el estado del pago a "Pendiente"?'

    const getIdsFromResources = (resources: any[]) => {
        return resources.map((r) => r.id)
    }

    const changePaymentStatusAndCancelResources = async () => {
        const receiptsIdsToCancel = getIdsFromResources(pendingReceipts)
        const invoicesIdsToCancel = getIdsFromResources(validInvoices)

        const completeInvoicesToCancel = []

        try {
            setLoadingPaymentResource(true)
            changePaymentStatus({
                removeCreatedAutomations: true,
            })

            pendingReceiptsLength > 0 &&
                changeReceiptsStatus({
                    receiptsIdsToCancel,
                })

            for (const invoiceId of invoicesIdsToCancel) {
                const invoiceData = await getDoc(doc(getFirestore(), 'invoices', invoiceId))
                completeInvoicesToCancel.push(invoiceData.data())
            }

            for (const invoice of completeInvoicesToCancel) {
                try {
                    const req: any = {
                        ...invoice,
                        invoicingIntegration: 'facturapi',
                        test: !invoice?.livemode,
                        id: invoice?.id,
                        type: 'cancel_invoice',
                        motive: '03',
                    }

                    req.extra = {
                        auth: auth.currentUser,
                        paymentId: item.fid ?? item.fid ?? '',
                    }

                    const invoiceResponse = await SignedInternalAPIRequest(req, 'invoicing', auth.currentUser)

                    if (invoiceResponse.error) {
                        messageApi.open({
                            type: 'error',
                            content: invoiceResponse.error,
                        })
                    }
                } catch (error: any) {
                    messageApi.open({
                        type: 'error',
                        content: error.message,
                    })
                }
            }

            messageApi.open({
                type: 'success',
                content: 'Facturas canceladas correctamente',
            })

            setLoadingPaymentResource(false)
            setTimeout(() => {
                dispatch(
                    setData({
                        item: 'relationInfo',
                        data: {},
                    }),
                )
                dispatch(closeModal('cancelResourcesModalVisible'))
            }, 1000)
        } catch (error: any) {
            messageApi.open({
                type: 'error',
                content: error.message,
            })
        }
    }

    const changeReceiptsStatus = async (extraData?: any) => {
        try {
            const data: any = {
                type: 'payment_cancel_receipt_request',
                id: item.paymentIntent,
                paymentID: item.id,
                fid: item.fid,
            }

            data.extra = {
                auth: auth.currentUser,
            }

            if (extraData) {
                data.extra.receiptsIdsToCancel = extraData.receiptsIdsToCancel
            }

            const receiptsResponse = await SignedInternalAPIRequest(data, 'paymentsHandler', auth.currentUser)

            if (receiptsResponse.error) {
                messageApi.open({
                    type: 'error',
                    content: receiptsResponse.error,
                })
                throw new Error(receiptsResponse.error)
            }

            messageApi.open({
                type: 'success',
                content: 'Los recibos fueron cancelados',
            })
        } catch (error: any) {
            messageApi.open({
                type: 'error',
                content: error.message,
            })
        }
    }

    const changePaymentStatus = async (removeCreatedAutomations?: any) => {
        try {
            const data: any = {
                type: 'payment_status_request',
                id: item.paymentIntent,
                paymentID: item.id,
                fid: item.fid,
            }

            data.extra = {
                auth: auth.currentUser,
            }

            if (removeCreatedAutomations) {
                data.extra.removeCreatedAutomations = true
            }

            const paymentResponse = await SignedInternalAPIRequest(data, 'paymentsHandler', auth.currentUser)

            if (paymentResponse.error) {
                messageApi.open({
                    type: 'error',
                    content: paymentResponse.error,
                })
                throw new Error(paymentResponse.error)
            }

            messageApi.open({
                type: 'success',
                content: paymentResponse.message,
            })
        } catch (error: any) {
            messageApi.open({
                type: 'error',
                content: error.message,
            })
        }
    }

    return (
        <Modal
            open={cancelResourcesModalVisible}
            onCancel={() => {
                dispatch(
                    setData({
                        item: 'relationInfo',
                        data: {},
                    }),
                )
                dispatch(closeModal('cancelResourcesModalVisible'))
            }}
            width={existSomeResourcesToCancel ? '100%' : '350px'}
            style={existSomeResourcesToCancel ? { paddingRight: '10%', paddingLeft: '10%' } : {}}
            footer={null}
            title={title}
        >
            {invoicesStatus !== 'loading' || receiptsStatus !== 'loading' ? (
                <>
                    {contextHolder}
                    <Col>
                        <Row>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    flexDirection: 'column',
                                    width: '100%',
                                }}
                            >
                                <p style={{ margin: '0' }}>{subTitle}</p>
                                <div
                                    style={{
                                        height: pendingReceiptsLength > 1 && validInvoicesLength > 1 ? '50vh' : 'auto',
                                        overflowY:
                                            pendingReceiptsLength > 1 && validInvoicesLength > 1 ? 'scroll' : 'hidden',
                                    }}
                                >
                                    {receiptsStatus === 'loading' ? (
                                        <MetroSpinner size={13} color="#686769" />
                                    ) : receiptsStatus === 'error' ? (
                                        <p>Hubo un error al cargar los recibos.</p>
                                    ) : (
                                        pendingReceiptsLength > 0 && (
                                            <RelationsHandler
                                                title="Recibos relacionados"
                                                viewType="drawer"
                                                type={relationInfo?.sourceType}
                                                collection={'receipts'}
                                                item={pendingReceipts}
                                                itemId={relationInfo?.item?.id}
                                                toCancel={true}
                                            />
                                        )
                                    )}
                                    {invoicesStatus === 'loading' ? (
                                        <MetroSpinner size={13} color="#686769" />
                                    ) : invoicesStatus === 'error' ? (
                                        <p>Hubo un error al cargar las facturas.</p>
                                    ) : (
                                        validInvoicesLength > 0 && (
                                            <RelationsHandler
                                                title="Facturas relacionadas"
                                                viewType="drawer"
                                                type={relationInfo?.sourceType}
                                                collection={'invoices'}
                                                item={validInvoices}
                                                itemId={relationInfo?.item?.id}
                                                toCancel={true}
                                            />
                                        )
                                    )}
                                </div>
                                {existSomeResourcesToCancel ? (
                                    <div>
                                        <p
                                            style={{
                                                marginBottom: '1.2rem',
                                                textAlign: 'center',
                                                fontWeight: 'medium',
                                            }}
                                        >
                                            {`¿Qué acción deseas aplicar con ${
                                                existSomeResourcesToCancel
                                                    ? 'los recursos relacionados'
                                                    : 'el recurso relacionado'
                                            }?`}
                                        </p>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <Button
                                                style={{ margin: '.5rem' }}
                                                type="dashed"
                                                disabled={loadingPayment || loadingPaymentResource}
                                                onClick={() => {
                                                    setLoadingPayment(true)
                                                    changePaymentStatus()
                                                    setLoadingPayment(false)
                                                    messageApi.open({
                                                        type: 'success',
                                                        content:
                                                            'Estado del pago cambiado a "Pendiente" correctamente.',
                                                    })
                                                    setTimeout(() => {
                                                        dispatch(
                                                            setData({
                                                                item: 'relationInfo',
                                                                data: {},
                                                            }),
                                                        )
                                                        dispatch(closeModal('cancelResourcesModalVisible'))
                                                    }, 1000)
                                                }}
                                            >
                                                {loadingPayment && (
                                                    <div style={{ marginRight: '.3rem' }}>
                                                        <MetroSpinner size={13} color="#686769" />
                                                    </div>
                                                )}
                                                Cambiar solo el estado del pago
                                            </Button>
                                            <Button
                                                style={{ margin: '.5rem' }}
                                                type="primary"
                                                disabled={loadingPayment || loadingPaymentResource}
                                                onClick={() => changePaymentStatusAndCancelResources()}
                                            >
                                                {loadingPaymentResource && (
                                                    <div style={{ marginRight: '.3rem' }}>
                                                        <MetroSpinner size={13} color="#686769" />
                                                    </div>
                                                )}
                                                Cancelar todos los recursos
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            marginTop: '1rem',
                                        }}
                                    >
                                        <Button
                                            type="primary"
                                            disabled={loadingPayment}
                                            onClick={() => {
                                                dispatch(closeModal('cancelResourcesModalVisible'))
                                            }}
                                        >
                                            No
                                        </Button>
                                        <Button
                                            type="dashed"
                                            disabled={loadingPayment}
                                            onClick={async () => {
                                                setLoadingPayment(true)
                                                await changePaymentStatus()
                                                setTimeout(() => {
                                                    setLoadingPayment(false)
                                                    dispatch(
                                                        setData({
                                                            item: 'relationInfo',
                                                            data: {},
                                                        }),
                                                    )
                                                    dispatch(closeModal('cancelResourcesModalVisible'))
                                                }, 1000)
                                            }}
                                        >
                                            {loadingPayment && (
                                                <div style={{ marginRight: '.3rem' }}>
                                                    <MetroSpinner size={13} color="#686769" />
                                                </div>
                                            )}
                                            Sí
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </Row>
                    </Col>
                </>
            ) : (
                <MetroSpinner size={13} color="#686769" />
            )}
        </Modal>
    )
}

export default CancelResourcesModal
