/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

const BoardToken = '352dc14c-9680-1f75-5ef5-9b35e2d86ff9'

const Feedback = () => {
    const { user } = useSelector((state) => state.user)
    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        // eslint-disable-next-line
        ;(function (w, d, i, s) {
            function l() {
                if (!d.getElementById(i)) {
                    var f = d.getElementsByTagName(s)[0],
                        e = d.createElement(s)
                    // eslint-disable-next-line no-sequences
                    ;(e.type = 'text/javascript'),
                        (e.async = !0),
                        (e.src = 'https://canny.io/sdk.js'),
                        f.parentNode.insertBefore(e, f)
                }
            }
            if ('function' != typeof w.Canny) {
                var c = function () {
                    c.q.push(arguments)
                }
                // eslint-disable-next-line no-sequences
                ;(c.q = []),
                    (w.Canny = c),
                    'complete' === d.readyState
                        ? l()
                        : w.attachEvent
                          ? w.attachEvent('onload', l)
                          : w.addEventListener('load', l, !1)
            }
        })(window, document, 'canny-jssdk', 'script')

        window.Canny('render', {
            boardToken: BoardToken,
            basePath: null, // See step 2
            ssoToken: user.cannySSO, // See step 3,
            theme: 'light', // options: light [default], dark, auto
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <div data-canny />
}

export default Feedback
