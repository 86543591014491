import React from 'react'
import { Col, Row, FormInstance, Typography, message, Alert } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { CheckCircle, Info, LockKey } from '@phosphor-icons/react'
import { setTeam } from '../../context/teamsSlice'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { Circle } from '@phosphor-icons/react/dist/ssr'

interface GlobalInvoiceFormProps {
    incomingForm?: FormInstance
    showTitle?: boolean
}

const GlobalInvoiceForm: React.FC<GlobalInvoiceFormProps> = ({ incomingForm, showTitle = true }) => {
    const { team } = useSelector((state: any) => state.team)
    const { billingAccount } = useSelector((state: any) => state.billingAccount)

    const dispatch = useDispatch()
    const isPaid = billingAccount?.subscription?.plan?.amount > 199000

    const HandleChange = async () => {
        if (!isPaid) return
        try {
            dispatch(
                setTeam({
                    ...team,
                    defaults: {
                        ...team.defaults,
                        invoicePerReceipt: !team?.defaults?.invoicePerReceipt,
                    },
                }),
            )
            await updateDoc(doc(getFirestore(), 'teams', team.id), {
                'defaults.invoicePerReceipt': !team?.defaults?.invoicePerReceipt,
            })
        } catch (error) {
            message.error('Ocurrió un error al actualizar la información.')
        }
    }

    return (
        <Row gutter={12} justify="center">
            {showTitle && (
                <Col xs={24} style={{ marginBottom: '25px' }}>
                    <label>Emisión de factura global</label>
                </Col>
            )}
            <Col xs={24} md={12}>
                <div
                    onClick={HandleChange}
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '2px solid var(--Neutral-Extended-Borders, #D8DAE5)',
                        borderRadius: '7px',
                        minHeight: '100px',
                        padding: '10px',
                    }}
                    className={`d-flex flex-column formCard ${
                        !team?.defaults?.invoicePerReceipt ? 'formCardSelected' : ''
                    }`}
                >
                    {!team?.defaults?.invoicePerReceipt ? (
                        <CheckCircle size={20} className="neutral-1" weight="regular" />
                    ) : (
                        <Circle size={20} className="neutral-1" weight="regular" />
                    )}
                    <Typography.Text className="p-base-bold neutral-1">Una sola factura</Typography.Text>
                    <Typography.Text className="p-xsmall-regular neutral-3" style={{ textAlign: 'center' }}>
                        Se emite una sola factura con todos los conceptos.
                    </Typography.Text>
                </div>
            </Col>
            <Col xs={24} md={12}>
                <div
                    onClick={() => {
                        if (isPaid) {
                            HandleChange()
                        } else {
                            message.error('Debes tener una suscripción activa para poder usar esta función.')
                        }
                    }}
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '2px solid var(--Neutral-Extended-Borders, #D8DAE5)',
                        borderRadius: '7px',
                        minHeight: '100px',
                        padding: '10px',
                        cursor: isPaid ? 'pointer' : 'not-allowed',
                    }}
                    className={`d-flex flex-column ${isPaid ? 'formCard' : ''} ${
                        team?.defaults?.invoicePerReceipt ? 'formCardSelected' : ''
                    }`}
                >
                    {!isPaid ? (
                        <LockKey size={20} className="icon" weight="regular" />
                    ) : team?.defaults?.invoicePerReceipt ? (
                        <CheckCircle size={20} className="neutral-1" weight="regular" />
                    ) : (
                        <Circle size={20} className="neutral-1" weight="regular" />
                    )}

                    <Typography.Text className="p-base-bold neutral-1">Factura por recibo</Typography.Text>
                    <Typography.Text className="p-xsmall-regular neutral-3" style={{ textAlign: 'center' }}>
                        Para utilizar esta función nesitas tener el plan growing.
                    </Typography.Text>
                </div>
            </Col>
            {!isPaid && (
                <Col xs={24} style={{ marginTop: '25px' }}>
                    <Alert
                        type="info"
                        icon={<Info size={15} className="icon" weight="regular" />}
                        showIcon
                        message={
                            <Typography.Text className="smallparagraph">
                                Para utilizar esta opción es necesario contar con una cuenta en el plan Growing.
                            </Typography.Text>
                        }
                    />
                </Col>
            )}
        </Row>
    )
}

export default GlobalInvoiceForm
