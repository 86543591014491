import { Button, Modal, Row, Typography, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../context/modalsSlice'
import { integrations } from '../../datasets/Integrations'
import { IntegrationOnboarding } from '../../components/Integrations/IntegrationOnboarding'
import { Question } from '@phosphor-icons/react'
import { useEffect } from 'react'
import { addParamToUrl } from '../../functions/UrlParams'

export const WhmcsModal = () => {
    const dispatch = useDispatch()

    const { whmcsModalVisible } = useSelector((state: any) => state.modals)
    const { team } = useSelector((state: any) => state.team)
    const whmcsIntegration = integrations(team).find((i) => i.id === 'whmcs')

    useEffect(() => {
        if (whmcsModalVisible) {
            addParamToUrl('open', 'whmcs')
        }
    }, [whmcsModalVisible])

    return (
        <Modal
            open={whmcsModalVisible}
            onCancel={() => {
                dispatch(closeModal('whmcsModalVisible'))
            }}
            className="integrationModal"
            title={null}
            footer={null}
        >
            <IntegrationOnboarding
                logo={whmcsIntegration?.logo ?? ''}
                title="Conecta WHMCS con Gigstack"
                description="Recibe pagos y factura automáticamente con WHMCS"
                bullets={[{ type: 'positive', description: 'Facturar automáticamente tus ventas' }]}
                IntegrationFooter={
                    <Row justify="space-between" style={{ padding: '20px 24px 20px 24px' }}>
                        <Row>
                            <Button icon={<Question className="icon clickable" weight="regular" size={16} />}>
                                <Typography.Text className="mediumparagraphbold">¿Cómo funciona?</Typography.Text>
                            </Button>
                        </Row>
                        <Row>
                            <Button type="primary" onClick={() => message.info('Próximamente')}>
                                Conectar
                            </Button>
                        </Row>
                    </Row>
                }
            />
        </Modal>
    )
}
