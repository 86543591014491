import { Row } from 'antd'
import React, { ReactNode } from 'react'

type Props = {
    children: ReactNode
    title: string
    description: string
    actions: ReactNode
}

const TablesContainer: React.FC<Props> = ({ children, title, description, actions }) => {
    return (
        <div>
            <Row justify="space-between" align="middle" style={{ padding: '15px 0px' }}>
                {actions}
            </Row>
            <div style={{ border: '1px solid var(--neutral-4)' }}>{children}</div>
        </div>
    )
}

export default TablesContainer
