import { Button, Modal, Row, Typography } from "antd"
import { FileUpload } from "../components/Forms/Inputs"
import { useDispatch, useSelector } from "react-redux"
import PaymentsTable from "../components/Tables/PaymentsTable"
import { useEffect, useState } from "react"
import { closeModal } from "../context/modalsSlice"
import { SwapSpinner } from "react-spinners-kit"

type ImportTypeAct = 'payments' | 'csv' | null

export const RenderImportTypeForm = ({
    type,
    loading,
    fileName,
    onClick,
}: {
    type: ImportTypeAct
    loading: boolean
    fileName: string | null
    onClick: () => void
}) => {
    const { team } = useSelector((state: any) => state.team)
    const [fileUploaded, setFileUploaded] = useState(false)
    const buttonText = type === 'payments' ? 'Ir a seleccionar pagos' : 'Cargar archivo'
    return (
        <div className="d-flex flex-column" style={{ marginTop: '16px', width: '100%' }}>
            {type === 'csv' && (
                <FileUpload
                    name="file"
                    storagePath={`teams/${team.id}/files/${fileName}`}
                    inputType="box"
                    allowedFiles={['.csv']}
                    placeholder="Selecciona un archivo .csv"
                    onFileUploaded={() => {
                        setFileUploaded(true)
                    }}
                />
            )}
            {type && <Row justify="end" style={{ marginTop: '16px' }}>
                <Button type="primary" loading={loading} disabled={!fileUploaded} onClick={onClick}>
                    {buttonText}
                </Button>
            </Row>}
        </div>
    )
}

export const FinishedProcessModal = ({ open, setOpen, setStep } : { open: boolean, setOpen: (v: boolean) => void, setStep: (v:number) => void}) => {
    return (
        <Modal
            title="Estamos en proceso de emisión"
            open={open}
            onCancel={() => setOpen(false)}
            footer={
                [<Button 
                    key = "accept"
                    onClick={() => {
                        setStep(0)
                        setOpen(false)
                    }}>
                    Aceptar
                </Button>]}
        >  
            <Typography.Text>Podrás consultar el status dentro de Facturación&gt;Masivas</Typography.Text>
        </Modal>
    )
}

export const RenderImportView = ({type, setStep}: {type: ImportTypeAct, setStep: (v: number) => void}) => {

    const dispatch = useDispatch()
    
    const [modalOpen, setModalOpen] = useState(false)

    useEffect(() => {
        if(type === 'csv') {
            setTimeout(() => {
                dispatch(closeModal('createMassiveInvoicesModalVisible'))
                setModalOpen(true)
            }, 5000)
        }
        // eslint-disable-next-line
    }, [type])
    
    if(type === 'payments') {
        return <PaymentsTable max={100} />
    }
    
    if(type === 'csv') {
        return (
            <Row justify="center" align="middle" style={{ height: '90%' }}>
                <FinishedProcessModal open={modalOpen} setOpen={setModalOpen} setStep={setStep} />
                <div className="d-flex flex-column d-center">
                    <Row style={{ margin: '15px 10px' }}>
                        <SwapSpinner />
                    </Row>
                    <Typography.Text className="h6-bold">Estamos procesando tu archivo</Typography.Text>
                    <Typography.Text className="p-base-regular">Por favor no cierres esta ventana</Typography.Text>
                </div>
            </Row>
        )
    }

    return <></>
}