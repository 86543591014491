import { Row, Space, Tag, Tooltip, Typography, message } from 'antd'
import { CircleNotch, PauseCircle, PlayCircle, ShootingStar } from '@phosphor-icons/react'
import { getItemsAmounts } from '../../../functions/helpers'
import moment from 'moment'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { useState } from 'react'
import { usePermissions } from '../../../customHooks/usePermissions'
import { ElementRelations } from '../../Relations/ElementRelations'
import RecurringEventsActions from '../../ElementActions/SingleActions/RecurringEventsActions'
import { CopyableElementId } from '../../UI/CopyableElementId'
import { SummarizeMessage } from '../../Calendarize/SummarizeRecurring'

function decideStatus(actionOnInvoice: any, record: any) {
    let statusComponent
    if (actionOnInvoice === record?.id) {
        statusComponent = <CircleNotch size={20} weight="regular" className="icon" />
    } else if (record?.status === 'active') {
        statusComponent = <PauseCircle className="icon" size={20} weight="regular" />
    } else {
        statusComponent = <PlayCircle size={20} weight="regular" className="icon" />
    }
    return statusComponent
}

function decideTemporality(record: any){
    let temporalityText;
    if (record?.temporality === 'daily') {
        temporalityText = 'Diario a las';
    } else if (record?.temporality === 'weekly') {
        temporalityText = `Semanal los ${moment().day(record.onWeekday ?? '01').format('dddd')} a las`;
    } else {
        temporalityText = `Mensual los días ${record?.onDay} a las`;
    }
    return temporalityText;
}

function formatDate(record: any, active: boolean) {
    if (active) {
        if ((record?.errors ?? []).length > 0) {
            return 'Error en la ejecución';
        } else if (record.nextRun) {
            return moment(record.nextRun).format('ddd D MMMM YYYY HH:mm ');
        } else if (record.pausedReason) {
            return record.pausedReason;
        } else {
            return '-';
        }
    } else {
        return '-';
    }
}

type TypeInvoicesColumnName =
    | 'client'
    | 'status'
    | 'amount'
    | 'create'
    | 'temporality'
    | 'relations'
    | 'nextRun'
    | 'Acciones'

export const RecurringInvoicesColumns = ({
    record,
    columnName,
}: {
    record: any
    columnName: TypeInvoicesColumnName
}) => {
    const { canCreateInvoice } = usePermissions()

    const [actionOnInvoice, setactionOnInvoice] = useState(null)
    const fs = getFirestore()

    const PauseRecurringEvent = async (event: any) => {
        setactionOnInvoice(event?.id)

        try {
            let nexRun = null
            console.log(event)
            const temporality =
                typeof event?.temporality !== 'string' ? event?.temporality?.value ?? 'monthly' : event?.temporality
            console.log(temporality)

            if (event?.status === 'paused') {
                nexRun = SummarizeMessage({
                    startDate: event?.startDate,
                    endDate: event?.endDate,
                    temporality,
                    onDay: event?.onDay,
                    onTime: event?.onTime,
                    onWeekday: event?.onWeekday,
                }).nextHit
            }
            console.log(nexRun)

            await updateDoc(doc(fs, `recurringEvents/${event?.id}`), {
                status: event?.status === 'paused' ? 'active' : 'paused',
                tried: 0,
                errors: [],
                errorMessages: [],
                pausedReason: null,
                temporality,
                nextRun: nexRun,
            })
            setactionOnInvoice(null)
        } catch (error: any) {
            message.error(error.message ?? 'Error al pausar evento')
            setactionOnInvoice(null)
        }
    }
    switch (columnName) {
        case 'client':
            const name = record?.client?.name ?? record?.client?.legal_name ?? record.client?.company ?? ''
            const nameTruncated = name?.length > 25 ? name?.substring(0, 25) + '...' : name

            return (
                <Space direction="vertical">
                    <Row>
                        {/* <Tooltip title={name}> */}
                        <Typography.Text
                            strong
                            className="p-base-regular neutral-1"
                            style={{ margin: 0, padding: 0, flex: 1 }}
                        >
                            {nameTruncated}{' '}
                            {!record.replicateElement?.livemode && (
                                <Tooltip title="Modo de prueba">
                                    <ShootingStar
                                        size={16}
                                        className="icon"
                                        style={{
                                            marginLeft: '5px',
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </Typography.Text>
                        {/* </Tooltip> */}
                    </Row>
                    {record?.client?.company && (
                        <Typography.Text className="p-base-regular" style={{ margin: '0', padding: '0' }}>
                            {record?.client?.company ? `(${record?.client?.company})` : ''}
                        </Typography.Text>
                    )}
                    <CopyableElementId id={record?.id} idName="ID" />
                </Space>
            )

        case 'status':
            return (
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title={record.status === 'active' ? 'Pausar' : 'Activar'}>
                        <div
                            onClick={() => {
                                if (!canCreateInvoice) return message.warning('No tienes permisos para crear facturas')
                                PauseRecurringEvent(record)
                            }}
                            className="customCircledTag"
                            style={{ width: '30px', height: '30px', marginTop: '5px' }}
                        >
                            {decideStatus(actionOnInvoice, record)}
                        </div>
                    </Tooltip>
                </Row>
            )
        case 'amount':
            return (
                <Row align="middle">
                    <Typography.Text className="p-base-regular" style={{ margin: 0, padding: 0 }}>
                        {
                            getItemsAmounts(
                                record?.replicateElement?.internalItems,
                                record?.replicateElement?.paidIn === 'bank',
                            ).totalString
                        }{' '}
                        {record?.replicateElement?.currency}
                    </Typography.Text>
                    {record?.replicateElement?.addTransactionFee && (
                        <Tooltip title="Se añadirá el fee de transacción">
                            {/* <PlusCircleOutlined style={{ marginLeft: '5px', fontSize: '10px', color: '#333' }} /> */}
                        </Tooltip>
                    )}
                </Row>
            )
        case 'create':
            return (
                <div>
                    {/* <div><Typography.Text className="secondary" style={{ fontSize: '12px', marginBottom: '0px' }} >{record.temporality === 'daily' ? `Diario a las ${record.onTime}` : record.temporality === 'weekly' ? `Semanal, los ${moment().day(record.onWeekday ?? '01').format('dddd')} ${record.onTime}` : `Mensual los días ${record.onDay} ${record.onTime}`}</Typography.Text></div> */}

                    <div>
                        {' '}
                        <Typography.Text className="secondary" style={{ fontSize: '10px', marginBottom: '0px' }}>
                            {`Creado ${moment(record.timestamp).fromNow()} Iniciando ${moment(record.startDate).format('DD MMM YYYY')}`}{' '}
                            terminando {moment(record.endDate).format('DD MMM YYYY')}
                        </Typography.Text>
                    </div>
                </div>
            )
        case 'temporality':
            return (
                <Row align="middle">
                    <Typography.Text className="p-base-regular">
                        {decideTemporality(record)}
                    </Typography.Text>

                    <Tag>{record?.onTime}</Tag>
                </Row>
            )
        case 'relations':
            return <ElementRelations record={record} elementType="recurringInvoices" hideAdd={true} />
        // return <Typography.Text className="secondary" style={{ fontSize: '12px', marginBottom: '0px' }} >{relations?.length > 0 ? `${relations.length} creados` : ''} </Typography.Text>
        case 'nextRun':
            const active = record.status === 'active'
            return (
                <Typography.Text className="secondary" style={{ fontSize: '12px', marginBottom: '0px' }}>
                    {formatDate(record, active)}
                </Typography.Text>
            )
        case 'Acciones':
            return <RecurringEventsActions record={record} />
        default:
            return <></>
    }
}
