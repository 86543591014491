import { Avatar, Col, Popover, Row, Tag, Typography } from 'antd'
import { Area, AreaChart, Bar, BarChart, Legend, ResponsiveContainer, Tooltip, Treemap, XAxis } from 'recharts'
import { generateCode, returnCurrencyValue } from '../../functions/helpers'
import { Funnel, Invoice, Question } from '@phosphor-icons/react'
import moment from 'moment'
import { useSelector } from 'react-redux'

export const InvoicesAreaChart = ({
    pastChartData,
    chartData,
    title = 'Pagos',
}: {
    pastChartData: any
    chartData: any
    title: string
}) => {
    const areas = [
        <Area
            type="monotone"
            dataKey="invoices"
            stackId="1"
            strokeWidth={1.5}
            display="Facturas emitidas"
            stroke="#b4e3d1"
            fillOpacity={1}
            fill="url(#ValidInvoices)"
        />,
        <Area
            type="monotone"
            dataKey="substitutedInvoices"
            display="Facturas sustituidas"
            activeDot={{ r: 1 }}
            stackId="3"
            strokeWidth={1.5}
            stroke="#de7648"
            fillOpacity={1}
            fill="url(#SubstitutedInvoices)"
        />,
        <Area
            type="monotone"
            dataKey="canceledInvoices"
            display="Facturas canceladas"
            activeDot={{ r: 1 }}
            stackId="2"
            strokeWidth={1.5}
            stroke="#ebaeae"
            fillOpacity={1}
            fill="url(#CanceledInvoices)"
        />,

        // <Area type="monotone" dataKey="receiptsPending" display="Autofactura pendientes"
        //     activeDot={{ r: 1 }}
        //     stackId="3"
        //     strokeWidth={0.5}
        //     stroke="#71717a" fillOpacity={1} fill="url(#receiptsPending)" />,
    ]
    const colors = ['#b4e3d1', '#ebaeae', '#de7648']
    const color1 = colors[0]
    const color2 = colors[1]
    const color3 = colors[2]

    const CustomTooltip = ({ active, payload, label }: { active: any; payload: any; label: any }) => {
        if (active && payload && payload.length) {
            return (
                <div
                    className="d-flex flex-column"
                    style={{
                        backgroundColor: 'white',
                        padding: '10px',
                        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                        borderRadius: '5px',
                        minWidth: 'auto',
                        position: 'relative',
                    }}
                >
                    <Typography.Text className="smallparagraph descriptions" style={{ marginBottom: '5px' }}>
                        {payload[0].payload?.name}
                    </Typography.Text>
                    {payload.map((p: any) => {
                        return (
                            <Row key={generateCode(5)} style={{ marginBottom: '5px' }}>
                                <Typography.Text className="smallparagraph descriptions" style={{ marginRight: '5px' }}>
                                    {p.display}
                                </Typography.Text>
                                <Tag>{returnCurrencyValue(p.value)}</Tag>
                            </Row>
                        )
                    })}
                </div>
                // <div className="custom-tooltip">
                //     <p className="label">{`${label} : ${payload[0].value}`}</p>
                //     <p className="intro">{getIntroOfPage(label)}</p>
                //     <p className="desc">Anything you want can be displayed here.</p>
                // </div>
            )
        }

        return null
    }
    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={chartData} stackOffset="none">
                <defs>
                    <linearGradient
                        id={`ValidInvoices`}
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                        // color={color1}
                    >
                        <stop offset="5%" color={color1} stopColor={color1} stopOpacity={0.8} />
                        <stop offset="95%" color={color1} stopColor={color1} stopOpacity={0} />
                    </linearGradient>
                    <linearGradient
                        id={`CanceledInvoices`}
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                        // color={color2}
                    >
                        <stop offset="5%" color={color2} stopColor={color2} stopOpacity={0.8} />
                        <stop offset="95%" color={color2} stopColor={color2} stopOpacity={0} />
                    </linearGradient>
                    <linearGradient
                        id={`SustitutedInvoices`}
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                        // color={color3}
                    >
                        <stop offset="5%" color={color3} stopColor={color3} stopOpacity={0.8} />
                        <stop offset="95%" color={color3} stopColor={color3} stopOpacity={0} />
                    </linearGradient>
                </defs>

                <Tooltip
                    content={({ active, payload, label }) => (
                        <CustomTooltip active={active} payload={payload} label={label} />
                    )}
                />
                {/* <XAxis dataKey="name"
                padding="no-gap"
                tick={
                    ({ x, y, stroke, payload }) => {
                        return <g transform={`translate(${x},${y})`} fill='#cecece'>
                            <text x={2} y={4} dy={2} textAnchor="start" fontSize={"8px"} fill="#f7f7f7" transform="rotate(0)">
                                {payload.value}
                            </text>
                        </g>
                    }
                } /> */}
                {areas.map((area, index) => {
                    return area
                })}
            </AreaChart>
        </ResponsiveContainer>
    )
}

export const InvoiceSourcesPieChart = ({
    data,
    title = 'Pagos',
    invoices,
}: {
    data: any
    title: string
    invoices: any
}) => {
    const SourcesToReadable = (source: string) => {
        switch (source) {
            case 'stripe':
                return 'Stripe'
            case 'paypal':
                return 'Paypal'
            case 'openpay':
                return 'Openpay'
            case 'conekta':
                return 'Conekta'
            case 'mercado pago':
                return 'Mercado Pago'
            case 'clip':
                return 'Clip'
            case 'manual':
                return 'Manual'
            case 'api':
                return 'API'
            case 'receipt':
                return 'Recibo'
            case 'substitute':
                return 'Sustitución'
            case 'global':
                return 'Global'
            default:
                return source
        }
    }

    const sources = invoices?.current?.joinKeys?.filter((key: string) => key !== 'valid' && key !== 'canceled')

    const data2: any = sources?.map((source: string) => {
        return {
            name: SourcesToReadable(source),
            value: invoices.current?.[source]?.valid?.amount,
        }
    })

    const CustomTooltip = ({ active, payload, label }: { active: any; payload: any; label: any }) => {
        if (active && payload && payload.length) {
            return (
                <div
                    className="d-flex flex-column"
                    style={{
                        backgroundColor: 'white',
                        padding: '10px',
                        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                        borderRadius: '5px',
                        minWidth: 'auto',
                        position: 'relative',
                    }}
                >
                    <Typography.Text className="smallparagraph descriptions" style={{ marginBottom: '5px' }}>
                        {SourcesToReadable(payload[0].payload?.name)}
                    </Typography.Text>
                    {payload.map((p: any) => {
                        return (
                            <Row key={generateCode(5)} style={{ marginBottom: '5px' }}>
                                <Typography.Text className="smallparagraph descriptions" style={{ marginRight: '5px' }}>
                                    {SourcesToReadable(p.display)}
                                </Typography.Text>
                                <Tag>{returnCurrencyValue(p.value)}</Tag>
                            </Row>
                        )
                    })}
                </div>
                // <div className="custom-tooltip">
                //     <p className="label">{`${label} : ${payload[0].value}`}</p>
                //     <p className="intro">{getIntroOfPage(label)}</p>
                //     <p className="desc">Anything you want can be displayed here.</p>
                // </div>
            )
        }

        return null
    }

    return (
        <ResponsiveContainer width="100%" height="100%">
            <Treemap
                width={200}
                height={200}
                data={data2}
                dataKey="value"
                aspectRatio={4 / 4}
                stroke="#303870"
                fill="#dbe0fe"
            >
                <Tooltip
                    content={({ active, payload, label }) => (
                        <CustomTooltip active={active} payload={payload} label={label} />
                    )}
                />
            </Treemap>
        </ResponsiveContainer>
    )
}

export const InvoiceTypeChart = ({
    pastChartData,
    chartData,
    title = 'Pagos',
    invoices,
}: {
    pastChartData: any
    chartData: any
    title: string
    invoices: any
}) => {
    const CustomTooltip = ({ active, payload, label }: { active: any; payload: any; label: any }) => {
        if (active && payload && payload.length) {
            const name = payload[0].payload?.name
            return (
                <div
                    className="d-flex flex-column"
                    style={{
                        backgroundColor: 'white',
                        padding: '10px',
                        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                        borderRadius: '5px',
                        minWidth: 'auto',
                        position: 'relative',
                    }}
                >
                    <Typography.Text className="smallparagraph descriptions" style={{ marginBottom: '5px' }}>
                        {name === 'I' ? 'Ingreso' : name === 'P' ? 'Complemento de pago' : 'Egreso'}
                    </Typography.Text>
                    {payload.map((p: any) => {
                        return (
                            <Row key={p.dataKey} style={{ marginBottom: '5px' }}>
                                <Typography.Text className="smallparagraph descriptions" style={{ marginRight: '5px' }}>
                                    {p.display}
                                </Typography.Text>
                                <Tag>{returnCurrencyValue(p.value)}</Tag>
                            </Row>
                        )
                    })}
                </div>
                // <div className="custom-tooltip">
                //     <p className="label">{`${label} : ${payload[0].value}`}</p>
                //     <p className="intro">{getIntroOfPage(label)}</p>
                //     <p className="desc">Anything you want can be displayed here.</p>
                // </div>
            )
        }

        return null
    }

    const types = ['I', 'P', 'E']
    const data = types.map((type) => {
        return {
            name: type,
            Monto: (invoices?.current?.valid?.[type]?.amount ?? 0) + (invoices?.current?.canceled?.[type]?.amount ?? 0),
        }
    })

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart width={150} height={40} data={data}>
                <Bar dataKey="Monto" fill="#b39fff" />
                <XAxis dataKey="name" />
                {/* <YAxis /> */}
                <Tooltip
                    content={({ active, payload, label }) => (
                        <CustomTooltip active={active} payload={payload} label={label} />
                    )}
                />
                <Legend />
            </BarChart>
        </ResponsiveContainer>
    )
}

export const FullChartInvoices = ({
    data,
    refElement,
    refElement2,
    receipts,
    invoices,
    pastChartData,
}: {
    data: any
    refElement: React.MutableRefObject<null>
    refElement2: React.MutableRefObject<null>
    receipts: any
    invoices: any
    pastChartData: any
}) => {
    const { period } = useSelector((state: any) => state.data)
    return (
        <Col xs={24}>
            <div
                ref={refElement}
                style={{
                    backgroundColor: '#F8F8F8',
                    padding: '20px',
                    borderRadius: '8px',
                    marginTop: '10px',
                    height: '240px',
                }}
                className="d-flex flex-column"
            >
                <Row justify="space-between">
                    <Row align="middle" style={{ marginTop: '4px' }}>
                        <Avatar
                            style={{ backgroundColor: 'white', marginRight: '10px' }}
                            icon={<Invoice weight="regular" color="#334055" />}
                        />
                        <div className="d-flex flex-column">
                            <Typography.Text className="smallheader">Facturas del periodo</Typography.Text>
                            <Typography.Text className="smallparagraph descriptions" style={{ fontSize: '10px' }}>
                                {moment(period?.start).format('DD MMM YYYY')} -{' '}
                                {moment(period?.end).format('DD MMM YYYY')}
                            </Typography.Text>
                        </div>
                    </Row>
                    <div className="d-flex flex-column" style={{ alignItems: 'end' }}>
                        <Typography.Text className="smallheader">
                            {returnCurrencyValue(invoices?.current?.valid?.I?.amount)}
                        </Typography.Text>
                        <Popover
                            placement="left"
                            content={
                                <div className="d-flex flex-column" style={{ maxWidth: '300px' }}>
                                    <Row>
                                        <Avatar
                                            style={{ backgroundColor: 'white', marginRight: '4px' }}
                                            icon={<Question weight="regular" color="#334055" />}
                                        />
                                        <Typography.Text className="smallparagraphbold">
                                            {receipts.current?.pending?.total} recibos pendientes de facturar
                                        </Typography.Text>
                                    </Row>
                                    <Typography.Text className="smallparagraph">
                                        La facturación al finalizar el periodo será de{' '}
                                        {returnCurrencyValue(
                                            receipts.current?.pending?.amount + invoices?.current?.valid?.I?.amount,
                                        )}
                                        , al revisar los montos verifica las posibles sustituciones del periodo anterior
                                        y las facturas manuales.
                                    </Typography.Text>
                                </div>
                            }
                        >
                            <Typography.Text
                                ref={refElement2}
                                className="smallparagraph descriptions"
                                style={{ fontSize: '10px', cursor: 'help' }}
                            >
                                {returnCurrencyValue(receipts.current?.pending?.amount)}
                            </Typography.Text>
                        </Popover>
                    </div>
                </Row>
                <div style={{ height: '150px' }}>
                    <InvoicesAreaChart pastChartData={pastChartData} chartData={data} title="Facturas" />
                </div>
                <Row justify="space-between" style={{ marginTop: '5px' }}>
                    {/* DIVIDE THE AMOUNT OF DATES IN 3 DATES 
                    dates
                    */}
                    <Typography.Text
                        className="smallparagraph descriptions"
                        style={{ borderLeft: '1px solid #cecece', paddingLeft: '5px', fontSize: '10px' }}
                    >
                        {moment(period?.start).format('DD MMM')}
                    </Typography.Text>
                    <Typography.Text className="smallparagraph descriptions" style={{ fontSize: '10px' }}>
                        {
                            // moment(period?.start).add(moment(period.start).diff(period.start, 'days') / 3, 'days').format('DD MMM')
                            moment(period?.start)
                                .add(moment(period.end).diff(moment(period.start), 'days') / 3, 'days')
                                .format('DD MMM')
                        }
                    </Typography.Text>
                    <Typography.Text
                        className="smallparagraph descriptions"
                        style={{ borderRight: '1px solid #cecece', paddingRight: '5px', fontSize: '10px' }}
                    >
                        {moment(period?.end).format('DD MMM')}
                    </Typography.Text>
                </Row>
            </div>
        </Col>
    )
}

export const InvoiceSourcesChart = ({ data, invoices }: { data: any; invoices: any }) => {
    return (
        <Col xs={24}>
            <div
                style={{
                    backgroundColor: '#F8F8F8',
                    padding: '20px',
                    borderRadius: '8px',
                    marginTop: '10px',
                    height: '300px',
                }}
                className="d-flex flex-column"
            >
                <Row justify="space-between">
                    <Row align="middle" style={{ marginTop: '4px' }}>
                        <Avatar
                            style={{ backgroundColor: 'white', marginRight: '10px' }}
                            icon={<Funnel weight="regular" color="#334055" />}
                        />
                        <div className="d-flex flex-column">
                            <Typography.Text className="smallheader">Fuente de facturas</Typography.Text>
                            <Typography.Text className="smallparagraph descriptions" style={{ fontSize: '10px' }}>
                                Desde donde se están emitiendo tus facturas
                            </Typography.Text>
                        </div>
                    </Row>
                </Row>
                <div style={{ height: '100%', marginTop: '15px' }}>
                    <InvoiceSourcesPieChart data={data} title="Pagos" invoices={invoices} />
                </div>
            </div>
        </Col>
    )
}

export const InvoiceTypesCharts = (data: any) => {
    return (
        <Col xs={24}>
            <div
                style={{
                    backgroundColor: '#F8F8F8',
                    padding: '20px',
                    borderRadius: '8px',
                    marginTop: '10px',
                    height: '300px',
                }}
                className="d-flex flex-column"
            >
                <Row justify="space-between">
                    <Row align="middle" style={{ marginTop: '4px' }}>
                        <Avatar
                            style={{ backgroundColor: 'white', marginRight: '10px' }}
                            icon={<Funnel weight="regular" color="#334055" />}
                        />
                        <div className="d-flex flex-column">
                            <Typography.Text className="smallheader">Tipo de facturas</Typography.Text>
                            <Typography.Text className="smallparagraph descriptions" style={{ fontSize: '10px' }}>
                                Tipo de CFDI's emitidos en el periodo
                            </Typography.Text>
                        </div>
                    </Row>
                </Row>
                <div style={{ height: '100%', marginTop: '15px' }}>
                    <InvoiceTypeChart
                        pastChartData={data?.pastPeriod}
                        chartData={data?.data}
                        title="Pagos"
                        invoices={data.invoices}
                    />
                </div>
            </div>
        </Col>
    )
}
