import { Avatar, Modal } from 'antd'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
import { useFirestore } from 'reactfire'
import { doc, updateDoc } from 'firebase/firestore'
import { GetBATeamsHelper, GetBillingAccountHelper } from '../context/databaseContextHelpers'

interface HeaderProps {
    setCollapseSider?: (collapse: boolean) => void
    collapseSider?: boolean
    authData?: any
    changeRender?: (value: any) => void
}

declare global {
    interface Window {
        Headway?: {
            init: (config: HeadwayConfig) => void
        }
    }
}

interface HeadwayConfig {
    selector: string
    account: string
}

const TeamSelectionModal: React.FC<HeaderProps> = () => {
    const fs = useFirestore()

    const dispatch = useDispatch()
    const { teamSelectionVisible } = useSelector((state: any) => state.modals)

    const handleClose = () => {
        dispatch(closeModal('teamSelectionVisible'))
    }
    const { team, teams } = useSelector((state: any) => state.team)
    const { user } = useSelector((state: any) => state.user)

    const [, /*setshowSearch*/ setSetshowSearch] = useState(false)
    const [items, setItems] = useState<{ label: string; icon: string; alias: string; key: string }[]>([])
    const handleMenuClick = async (e: string) => {
        try {
            GetBATeamsHelper(user?.uid, dispatch)
            var lastTeam = teams?.find((team: any) => team?.id === e)
            await updateDoc(doc(fs, 'users', user?.uid), {
                lastTeamViewed: e,
                lastBillingAccountViewed: lastTeam?.billingAccount,
            })
            GetBillingAccountHelper(team?.billingAccount, dispatch)
            return window.location.reload()
        } catch (error) {}
    }
    useEffect(() => {
        //ADD LISTENER FOR COMAND F TO OPEN SEARCH
        var config = {
            selector: '#newFeats',
            account: 'xaVPV7',
        }

        window?.Headway?.init(config)
        const handleKeyDown = (e: any) => {
            if ((e.keyCode === 114 || e.keyCode === 70) && (e.ctrlKey || e.metaKey)) {
                e.preventDefault()
                setSetshowSearch(true)
            }
        }
        window?.addEventListener('keydown', handleKeyDown)
        let newItems = teams.map((team: any) => {
            return {
                label: team?.brand?.alias,
                key: team?.id,
                icon: team?.brand?.logo,
            }
        })
        setItems(newItems)
    }, [teams])

    return (
        <Modal title="Selecciona un team" visible={teamSelectionVisible} onCancel={handleClose} footer={null}>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ul style={{ listStyle: 'none', padding: 0 }}>
                    {items.map((i) => (
                        <li
                            key={i.key}
                            className="d-flex flex-row align-items-center"
                            style={{
                                backgroundColor: 'white',
                                boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.05)',
                                padding: '5px 10px',
                                borderRadius: '10px',
                                marginBottom: '10px',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleMenuClick(i.key)}
                        >
                            <Avatar size={25} src={i.icon} />
                            <span style={{ marginLeft: '10px' }}>{i.label}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </Modal>
    )
}

export default TeamSelectionModal
