import { getItemsAmounts } from '../../functions/helpers'
import { useDispatch } from 'react-redux'
import { Button, Dropdown, Menu, Popconfirm, Row, message } from 'antd'
import { setData } from '../../context/dataSlice'
import { openModal } from '../../context/modalsSlice'
import { ElementRelations } from './ElementRelations'
import { doc, getDoc, getFirestore } from 'firebase/firestore'
import { useState } from 'react'
import { usePermissions } from '../../customHooks/usePermissions'
import dayjs from 'dayjs'
import RelationPaymentComplementOption from './RelationPaymentWithInvoiceAddComplementOption'

const RelationPayment = ({ record, hide }: { record: any; hide?: string }) => {
    const { canCreatePayment, canCreateInvoice } = usePermissions()

    const dispatch = useDispatch()

    const [loading, setloading] = useState(false)

    const handleCancel = () => {
        setloading(false)
    }

    const menu = (
        <Menu>
            <Menu.Item
                key="1"
                onClick={() => {
                    if (!canCreatePayment || !canCreateInvoice)
                        return message.warning('No tienes permisos para crear pagos o facturas')

                    dispatch(
                        setData({
                            item: 'client',
                            data: record.client,
                        }),
                    )
                    dispatch(
                        setData({
                            item: 'selectedServices',
                            data: record.internalItems || record.items || [],
                        }),
                    )
                    dispatch(
                        setData({
                            item: 'payment',
                            data: {
                                invoices: [record?.id],
                                amount: getItemsAmounts(record?.items).total * 100,
                                items: record?.internalItems,
                                currency: record?.currency ?? 'MXN',
                                exchange: record?.exchange ?? 1,
                            },
                        }),
                    )

                    dispatch(openModal('paymentModalVisible'))
                }}
            >
                Solicitar pago
            </Menu.Item>
            <Menu.Item
                key="2"
                onClick={() => {
                    if (!canCreatePayment || !canCreateInvoice)
                        return message.warning('No tienes permisos para crear pagos o facturas')

                    dispatch(
                        setData({
                            item: 'relating',
                            data: {
                                element: record,
                                type: 'invoices',
                                collection: 'invoices',
                            },
                        }),
                    )
                }}
            >
                Asociar pago
            </Menu.Item>
            <Popconfirm
                title="Continuar a los detalles de registro de pago"
                description="Registra y asocia un pago"
                onConfirm={async () => {
                    let items = record?.internalItems
                    setloading(true)
                    let currency = record?.currency
                    let exchange = record?.exchange
                    let payment_form = record?.payment_form
                    if (record?.invoiceType === 'P' && record?.invoices?.length > 0) {
                        let invoice = await getDoc(doc(getFirestore(), 'invoices', record?.invoices[0]))
                        console.log('invoice', invoice.data())

                        items = invoice.data()?.internalItems
                        currency = invoice.data()?.currency
                        exchange = invoice.data()?.exchange
                        payment_form = invoice.data()?.payment_form
                    }
                    dispatch(
                        setData({
                            item: 'payment',
                            data: {
                                currency,
                                exchange,
                                timestamp: dayjs(record.timestamp),
                                paymentForm: payment_form,
                                invoices: [record?.id],
                            },
                        }),
                    )
                    dispatch(setData({ item: 'client', data: record.client }))
                    dispatch(setData({ item: 'selectedServices', data: items }))

                    // dispatch(setData({
                    //     item: 'payment',
                    //     data: record?.internalItems
                    // }))
                    setloading(false)
                    dispatch(openModal('registerPaymentModalVisible'))
                }}
                okButtonProps={{
                    loading: loading,
                }}
                onCancel={handleCancel}
                okText="Registrar"
                cancelText="Cancelar"
            >
                <Menu.Item key="3">Registrar Pago</Menu.Item>
            </Popconfirm>
        </Menu>
    )

    return (
        <Row justify={'center'}>
            {(record.payments ?? []).length === 0 && (record.invoices ?? []).length === 0 ? (
                <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
                    <Button style={{ margin: '3px' }}>Agregar relación</Button>
                </Dropdown>
            ) : (
                <ElementRelations record={record} elementType="invoices" />
            )}

            {hide !== 'complement' && <RelationPaymentComplementOption record={record} />}
        </Row>
    )
}
export default RelationPayment
