import { useFirestoreCollectionData, useFirestoreDocData } from 'reactfire'
import { RelationsHandler } from '../components/Relations/RelationsHandler'
import { collection, doc, getFirestore, orderBy, query, where } from 'firebase/firestore'
import { Avatar, Col, Descriptions, Row, Tag, Typography, message } from 'antd'
import { SearchParamInURL, invoiceUsage, readableElementSource, returnCurrencyValue } from '../functions/helpers'
import { useNavigate } from 'react-router-dom'
import EmailsTable from '../components/ComponentIndividual/EmailSend'
import HeaderForViewDetail from '../components/ComponentIndividual/HeaderForViewDetail'
import ItemsSelection from '../components/ComponentIndividual/ItemsSelection'
import ProductsServicesTotals from '../components/ComponentIndividual/ProductsServicesTotals'
import { paymentForms, paymentMethods } from '../datasets/Fiscal'
import moment from 'moment'
import { InvoiceStatusTag } from '../components/UI/ElementStatus'
import { invoiceDefSet, optimizedInvoiceDefSet } from '../../interfaces/invoicesDef'
import InvoiceActions from '../components/ElementActions/InvoiceActions'
import { useState } from 'react'
import { CaretCircleDoubleDown, CaretCircleDoubleUp } from '@phosphor-icons/react'
import { useSelector } from 'react-redux'
import { createdByObject } from '../components/ComponentIndividual/helpers'
import { LogsRelatedToElement } from '../components/Logs/LogsRelatedToElement'

export const InvoiceDetails = () => {
    const navigate = useNavigate()
    const fs = getFirestore()
    const id = SearchParamInURL('id')

    if (!id) {
        message.warning('No se encontró el id de la factura')
        navigate('invoices')
    }

    const invoiceRef = doc(fs, 'invoices', id ?? '')
    const { status, data } = useFirestoreDocData(invoiceRef)
    const { status: emailStatus, data: emailsData } = useFirestoreCollectionData(
        query(collection(fs, 'emails'), where('related', '==', `invoices/${id}`), orderBy('timestamp', 'desc')),
    )

    const [show, setShow] = useState<any[]>([])

    const HandleShow = (id: string) => {
        if (show.includes(id)) {
            setShow(show.filter((s) => s !== id))
        } else {
            setShow([...show, id])
        }
    }

    const ShowIcon = ({ id }: { id: string }) => {
        return show.includes(id) ? (
            <CaretCircleDoubleUp size={18} className="icon" weight="regular" style={{ marginLeft: '5px' }} />
        ) : (
            <CaretCircleDoubleDown size={18} style={{ marginLeft: '5px' }} className="icon" weight="regular" />
        )
    }

    if (status === 'loading' || !data) {
        return <>Cargando...</>
    }

    const ProductAndServices = () => {
        return (
            <Col xs={24} lg={24} style={{ marginTop: '25px', marginBottom: '25px' }}>
                <div className="d-flex flex-column">
                    <Row align="middle" className="clickable" onClick={() => HandleShow('products')}>
                        <Typography.Text className="smallparagraph descriptions">Productos / Servicios</Typography.Text>
                        <div
                            style={{ height: '1px', borderBottom: '1px solid #D8DAE5', flex: 1, marginLeft: '15px' }}
                        ></div>
                        <ShowIcon id="products" />
                    </Row>
                    {show.includes('products') && (
                        <div
                            className="d-flex flex-column"
                            style={{
                                marginTop: '5px',
                                backgroundColor: '#fafbfd',
                                padding: '10px',
                                borderRadius: '15px',
                            }}
                        >
                            <ItemsSelection incomingServices={data?.internalItems} disabled={true} hideTitle={true} />
                            <Row justify="end">
                                <ProductsServicesTotals incomingItems={data?.internalItems} boxed={false} />
                            </Row>
                        </div>
                    )}
                </div>
            </Col>
        )
    }
    const InvoiceDetailsGeneral = () => {

        const { team } = useSelector((state: any) => state.team)
        const { user } = useSelector((state: any) => state.user)

        let details = [
            {
                key: 'uuid',
                label: <Typography.Text className="smallparagraphbold">Folio Fiscal (UUID)</Typography.Text>,
                children: <Typography.Text className="smallparagraph descriptions">{data?.uuid}</Typography.Text>,
            },
            {
                key: '1',
                label: <Typography.Text className="smallparagraphbold">Tipo de CFDI</Typography.Text>,
                children: <Typography.Text className="smallparagraph descriptions">{data?.type}</Typography.Text>,
            },

            {
                key: 'series',
                label: <Typography.Text className="smallparagraphbold">Serie</Typography.Text>,
                children: <Typography.Text className="smallparagraph ">{data?.series}</Typography.Text>,
            },
            {
                key: 'folio_number',
                label: <Typography.Text className="smallparagraphbold">Folio</Typography.Text>,
                children: <Typography.Text className="smallparagraph ">{data?.folio_number}</Typography.Text>,
            },
            {
                key: 'use',
                label: <Typography.Text className="smallparagraphbold">Uso de CFDI</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        {invoiceUsage?.filter((u) => u.value === data?.use)[0]?.label ?? ''}
                    </Typography.Text>
                ),
            },
            {
                key: 'payment_method',
                label: <Typography.Text className="smallparagraphbold">Método de pago</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        {paymentMethods?.filter((u) => u.value === data?.payment_method)[0]?.label ?? ''}
                    </Typography.Text>
                ),
            },
            {
                key: 'currency',
                label: <Typography.Text className="smallparagraphbold">Moneda</Typography.Text>,
                children: <Typography.Text className="smallparagraph ">{data?.currency}</Typography.Text>,
            },
            {
                key: 'exchange_rate',
                label: <Typography.Text className="smallparagraphbold">Tipo de cambio</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        1 {data?.currency} = {data?.exchange} MXN
                    </Typography.Text>
                ),
            },
            {
                key: 'total',
                label: <Typography.Text className="smallparagraphbold">Total del CFDI</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        {returnCurrencyValue(data?.total)} {data?.currency}{' '}
                    </Typography.Text>
                ),
            },
            {
                key: 'date',
                label: <Typography.Text className="smallparagraphbold">Fecha</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        {moment(data?.timestamp).format('DD MMMM YYYY HH:mm')}{' '}
                    </Typography.Text>
                ),
            },
            {
                key: 'payment_form',
                label: <Typography.Text className="smallparagraphbold">Forma de pago</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        {paymentForms.find((d) => d.value === data?.payment_form)?.label}{' '}
                    </Typography.Text>
                ),
            },
            {
                key: 'source',
                label: <Typography.Text className="smallparagraphbold">Fuente</Typography.Text>,
                children: (
                    <Tag color="#D3D9FF">
                        <Typography.Text className="smallparagraphbold blue">
                            {readableElementSource(data.from ?? '')}
                        </Typography.Text>
                    </Tag>
                ),
            },
        ]

        if (data.global)
            details.push({
                key: 'global',
                label: <Typography.Text className="smallparagraphbold">Factura global</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        {data?.global?.periodicity === 'month' ? 'Mensual' : data?.global?.periodicity}{' '}
                        {moment(data?.global?.months, 'MM').format('MMMM')} {data?.global?.year}{' '}
                    </Typography.Text>
                ),
            })

        if (data?.from === 'manual' || !data?.from) {
            details.push({
                ...createdByObject({ resource: data, team, user }),
            })
        }
        
        return (
            <Col xs={24} lg={24}>
                <div
                    style={{
                        width: '100%',
                        padding: '20px',
                        borderRadius: '10px',
                        background:
                            'linear-gradient(180deg, rgba(186, 216, 239, 0.34) 0%, rgba(134, 102, 255, 0.06) 100%)',
                    }}
                    className="d-flex flex-column"
                >
                    <Descriptions
                        title={
                            <Row justify="space-between" align="middle">
                                <Typography.Text className="mediumparagraphbold">
                                    Información general del CFDI
                                </Typography.Text>
                                <InvoiceStatusTag shape="tag" record={invoiceDefSet(data)} />
                            </Row>
                        }
                        layout="vertical"
                        size="small"
                        items={details}
                    />
                </div>
            </Col>
        )
    }
    const ActionsHeader = () => {
        return (
            <Col xs={24}>
                <div
                    style={{
                        borderRadius: '10px',
                        backgroundColor: '#F9FAFC',
                        padding: '10px',
                    }}
                >
                    <Row justify="space-between" align="middle">
                        <Typography.Text className="smallparagraphbold">Acciones</Typography.Text>
                        <InvoiceActions
                            invoice={{ ...data, emails: typeof data?.emails === 'object' ? [] : data?.emails }}
                        />
                    </Row>
                </div>
            </Col>
        )
    }

    const EmailsRelated = () => {
        return (
            <Col xs={24}>
                <EmailsTable
                    customHeader={
                        <Row align="middle" style={{ marginBottom: '15px' }} className="">
                            <Typography.Text className="smallparagraph descriptions">Correos enviados</Typography.Text>
                            <div
                                style={{
                                    height: '1px',
                                    borderBottom: '1px solid #D8DAE5',
                                    flex: 1,
                                    marginLeft: '15px',
                                }}
                            ></div>
                            {/* <ShowIcon id="emails" /> */}
                        </Row>
                    }
                    emailsData={emailsData}
                    emailStatus={emailStatus}
                />
            </Col>
        )
    }

    return (
        <div>
            <HeaderForViewDetail
                data={data}
                title={`${data?.invoiceType === 'P' ? 'Complemento de pago' : 'Factura'} ${data?.series} - ${data?.folio_number}`}
            />
            <Row gutter={[16, 16]} style={{ padding: '20px 20px' }}>
                {/* <Col xs={24} md={24} lg={24}>
                <ViewDetailInvoice data={data} title={'Factura'} icon={<Article style={{ marginRight: '10px' }} size={20} weight="regular" />} />
            </Col> */}
                <Col xs={24}>
                    <Row>
                        <Avatar
                            style={{
                                verticalAlign: 'middle',
                                marginRight: '10px',
                                background:
                                    'linear-gradient(180deg, rgba(186, 216, 239, 0.34) 0%, rgba(134, 102, 255, 0.06) 100%)',
                            }}
                            size={40}
                        >
                            <Typography.Text className="smallparagraphbold">
                                {data?.client?.legal_name[0]}
                            </Typography.Text>
                        </Avatar>
                        <div className="d-flex flex-column">
                            <Typography.Text className="smallheader">{data?.client?.legal_name}</Typography.Text>
                            <Typography.Text className="smallparagraph descriptions">
                                {data?.client?.rfc} - {data?.client?.email}
                            </Typography.Text>
                            <Typography.Text className="smallparagraph descriptions">
                                {typeof data?.customer?.address?.country === 'string'
                                    ? data?.customer?.address?.country
                                    : data?.customer?.address?.country?.label ?? ''}{' '}
                                - C.P. {data?.customer?.address?.zip}
                            </Typography.Text>
                        </div>
                    </Row>
                </Col>

                <ActionsHeader />
                <InvoiceDetailsGeneral />
                {data?.invoiceSingleExtra && (
                    <Col xs={24}>
                        <div
                            style={{
                                borderRadius: '10px',
                                backgroundColor: '#F9FAFC',
                                padding: '10px',
                            }}
                            className="d-flex flex-column"
                        >
                            <Typography.Text className="smallparagraphbold">Notas en factura</Typography.Text>
                            <Typography.Text className="smallparagraph descriptions" style={{ marginTop: '5px' }}>
                                {data?.invoiceSingleExtra}
                            </Typography.Text>
                        </div>
                    </Col>
                )}

                <ProductAndServices />

                <Col xs={24} lg={24}>
                    <Row
                        align="middle"
                        style={{ marginBottom: '15px' }}
                        className="clickable"
                        onClick={() => HandleShow('receipts')}
                    >
                        <Typography.Text className="smallparagraph descriptions">Recibos relacionados</Typography.Text>
                        <div
                            style={{ height: '1px', borderBottom: '1px solid #D8DAE5', flex: 1, marginLeft: '15px' }}
                        ></div>
                        <ShowIcon id="receipts" />
                    </Row>
                    {show.includes('receipts') && (
                        <div
                            style={{
                                marginTop: '5px',
                                backgroundColor: '#fafbfd',
                                padding: '10px',
                                borderRadius: '15px',
                            }}
                        >
                            <RelationsHandler
                                title="Recibos relacionados"
                                viewType="table"
                                type="invoices"
                                collection="receipts"
                                subcollection="invoices"
                                item={optimizedInvoiceDefSet(data ?? {})}
                                itemId={id}
                            />
                        </div>
                    )}
                </Col>
                <Col xs={24} lg={24}>
                    <Row
                        align="middle"
                        style={{ marginBottom: '15px' }}
                        className="clickable"
                        onClick={() => HandleShow('payments')}
                    >
                        <Typography.Text className="smallparagraph descriptions">Pagos relacionados</Typography.Text>
                        <div
                            style={{ height: '1px', borderBottom: '1px solid #D8DAE5', flex: 1, marginLeft: '15px' }}
                        ></div>
                        <ShowIcon id="payments" />
                    </Row>
                    {show.includes('payments') && (
                        <div
                            style={{
                                marginTop: '5px',
                                backgroundColor: '#fafbfd',
                                padding: '10px',
                                borderRadius: '15px',
                            }}
                        >
                            <RelationsHandler
                                title="Pagos relacionados"
                                viewType="table"
                                type="invoices"
                                collection="payments"
                                subcollection="invoices"
                                item={optimizedInvoiceDefSet(data ?? {})}
                                itemId={id}
                            />
                        </div>
                    )}
                </Col>
                <Col xs={24} lg={24}>
                    <Row
                        align="middle"
                        style={{ marginBottom: '15px' }}
                        className="clickable"
                        onClick={() => HandleShow('invoices')}
                    >
                        <Typography.Text className="smallparagraph descriptions">Facturas relacionadas</Typography.Text>
                        <div
                            style={{ height: '1px', borderBottom: '1px solid #D8DAE5', flex: 1, marginLeft: '15px' }}
                        ></div>
                        <ShowIcon id="invoices" />
                    </Row>
                    {show.includes('invoices') && (
                        <div
                            style={{
                                marginTop: '5px',
                                backgroundColor: '#fafbfd',
                                padding: '10px',
                                borderRadius: '15px',
                            }}
                        >
                            <RelationsHandler
                                title="Facturas relacionadas"
                                viewType="table"
                                type="invoices"
                                collection="invoices"
                                subcollection="invoices"
                                item={optimizedInvoiceDefSet(data ?? {})}
                                itemId={id}
                            />
                        </div>
                    )}
                </Col>

                <EmailsRelated />

                <Col xs={24} lg={24}>
                    <Row
                        align="middle"
                        style={{ marginBottom: '15px' }}
                        className="clickable"
                        onClick={() => HandleShow('logs')}
                    >
                        <Typography.Text className="smallparagraph descriptions">Eventos enviados</Typography.Text>
                        <div
                            style={{
                                height: '1px',
                                borderBottom: '1px solid #D8DAE5',
                                flex: 1,
                                marginLeft: '15px',
                            }}
                        ></div>
                        <ShowIcon id="logs" />
                    </Row>
                    {show.includes('logs') && (
                        <div
                            style={{
                                marginTop: '5px',
                                backgroundColor: '#fafbfd',
                                padding: '10px',
                                borderRadius: '15px',
                            }}
                        >
                            <LogsRelatedToElement id={id} />
                        </div>
                    )}
                </Col>
            </Row>

            {/* <Row gutter={[16, 16]}>
            <Col xs={24} md={24} lg={24}>
                <Client data={data} />
            </Col>
        </Row> */}
            {/* <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Typography.Title level={5}>Pagos relacionadas</Typography.Title>
                        <RelationsHandler
                            title="Pagos relacionadas"
                            viewType="table"
                            type="invoices"
                            collection="payments"
                            subcollection="invoices"
                            item={optimizedPaymentDefSet(data ?? {})}
                            itemId={id}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Typography.Title level={5}>Recibos relacionados</Typography.Title>
                        <RelationsHandler
                            title="Recibos relacionados"
                            viewType="table"
                            type="invoices"
                            collection="receipts"
                            subcollection="invoices"
                            item={optimizedPaymentDefSet(data ?? {})}
                            itemId={id}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <EmailsTable emailsData={emailsData} emailStatus={emailStatus} />
            </Col>
        </Row> */}
        </div>
    )
}
