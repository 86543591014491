import { CaretCircleDown, CaretCircleUp, CheckCircle, CircleNotch, ClockCountdown, CursorClick, Envelope, EnvelopeOpen, EnvelopeSimpleOpen, Info, Prohibit } from '@phosphor-icons/react'
import { Button, Col, Divider, Row, Tag, Timeline, Tooltip, Typography } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { generateCode, HandleEmailStatus } from '../../functions/helpers'
import { collection, getDocs, getFirestore, orderBy, query, where } from 'firebase/firestore'

interface Email {
    id: string
    timestamp: number
    subject: string
    lastEvent: string
    lastEventAt: number
    to: string[]
}

interface EmailsTableProps {
    emailStatus: string
    emailsData: any
    customHeader?: React.ReactNode
}
interface EmailCardProps {
    email: Email
}

const emailsEventsData = [
    {
        event: 'open',
        icon: <EnvelopeSimpleOpen size={14} />,
        label: 'Abierto',
        description: 'El correo ha sido abierto por el destinatario, indicando que interactuó con el mensaje.',
        type: 'success',
    },
    {
        event: 'processed',
        icon: <CheckCircle size={14} />,
        label: 'Procesado',
        description: 'El correo ha sido recibido por el sistema y está listo para ser enviado.',
        type: 'info',
    },
    {
        event: 'deferred',
        icon: <ClockCountdown size={14} />,
        label: 'Diferido',
        description:
            'El correo no pudo ser entregado de inmediato. El sistema intentará enviarlo de nuevo durante un máximo de 72 horas.',
        type: 'warning',
    },
    {
        event: 'delivered',
        icon: <CheckCircle size={14} />,
        label: 'Entregado',
        description:
            'El correo ha sido aceptado por el servidor del destinatario. Sin embargo, esto no garantiza que haya llegado a la bandeja de entrada.',
        type: 'success',
    },
    {
        event: 'click',
        icon: <CursorClick size={14} />,
        label: 'Click',
        description: 'El destinatario ha hecho clic en uno de los enlaces dentro del correo.',
        type: 'success',
    },
    {
        event: 'bounce',
        icon: <Prohibit size={14} />,
        label: 'Rebotado',
        description:
            'El correo no pudo ser entregado porque la dirección del destinatario es incorrecta o ya no está activa.',
        type: 'error',
    },
    {
        event: 'dropped',
        icon: <Prohibit size={14} />,
        label: 'Rechazado',
        description:
            'El correo no ha sido enviado debido a un problema, como una dirección inválida o haber sido bloqueado.',
        type: 'error',
    },
    {
        event: 'spamreport',
        icon: <Prohibit size={14} />,
        label: 'Reportado como spam',
        description:
            'El destinatario ha marcado el correo como spam, lo que puede afectar la reputación del remitente.',
        type: 'error',
    },
]

const EmailsTable: React.FC<EmailsTableProps> = ({ emailStatus, emailsData, customHeader }) => {
    return (
        <div style={{ marginTop: '5px' }}>
            {customHeader ? (
                customHeader
            ) : (
                <Row justify="space-between" align="middle" style={{ marginLeft: '10px' }}>
                    <Typography.Title level={5} style={{ display: 'flex', alignItems: 'center' }}>
                        <Envelope style={{ marginRight: '10px' }} size={20} weight="regular" />
                        <span>Correos enviados</span>
                    </Typography.Title>
                </Row>
            )}
            {emailStatus === 'loading' ? (
                <CircleNotch size={15} weight="regular" />
            ) : (
                emailsData?.map((email: any) => <EmailCard key={email.id} email={email} timestamp={email.timestamp} />)
            )}
        </div>
    )
}

interface EmailCardProps {
    email: Email
    timestamp: number
}

const EmailCard: React.FC<EmailCardProps> = ({ email }) => {

    const [collapsed, setCollapsed] = useState(true)

    return (
        <>
            <div style={{ marginTop: '10px', marginLeft: '0px', backgroundColor: '#fafbfd', padding: '10px' }}>
                <Row align="middle" justify="space-between" key={email.id} style={{ width: '100%' }}>
                    <Row>
                        <Row align="middle" style={{ flex: 1 }}>
                            {email?.lastEvent === 'open' ? (
                                <EnvelopeOpen size={20} weight="regular" />
                            ) : (
                                <Envelope size={20} weight="regular" />
                            )}
                            <Divider type="vertical" />
                            <div style={{ alignItems: 'start', marginLeft: '5px' }} className="d-flex flex-column">
                                <Typography.Text style={{ margin: 0, padding: 0, marginTop: '10px', fontSize: '8px' }}>
                                    {moment(email.timestamp).format('DD MMM YYYY HH:mm')}
                                </Typography.Text>
                                <Typography.Text
                                    style={{
                                        margin: 0,
                                        padding: 0,
                                        marginBottom: '5px',
                                        marginTop: '5px',
                                        fontSize: '12px',
                                    }}
                                >
                                    "{email.subject}"
                                </Typography.Text>
                            </div>
                        </Row>
                    </Row>
                    <Tag
                        color={HandleEmailStatus(email.lastEvent).color}
                        style={{ fontSize: '11px', marginLeft: '20px' }}
                    >
                        {HandleEmailStatus(email.lastEvent).message}{' '}
                        {moment(email.lastEventAt ? email.lastEventAt * 1000 : email.timestamp).fromNow(false)}
                    </Tag>
                </Row>
                <Row align="middle" justify="space-between">
                    <Row align="middle">
                        {email?.to?.map((to, index) => (
                            <Tag style={{ fontSize: '10px', marginTop: '5px' }} key={index}>
                                {to}
                            </Tag>
                        ))}
                    </Row>
                    <Button type="link" onClick={() => setCollapsed(!collapsed)}>
                        <Typography.Text className="smallparagraphbold" style={{ fontSize: '12px' }}>
                            {collapsed ? 'Ver' : 'Ocultar'} detalles
                        </Typography.Text>
                        {collapsed ? (
                            <CaretCircleDown size={15} color="var(--neutral-1)" />
                        ) : (
                            <CaretCircleUp size={15} color="var(--neutral-1)" />
                        )}
                    </Button>
                </Row>
            </div>
            {collapsed ? null : <EmailDetails email={email} />}
        </>
    )
}

const EmailButton = ({ selected, email, onClick }: { selected: boolean, email: string, onClick: (email: string) => void }) => {
    return (
        <div
            style={{ margin: '5px 5px', borderRadius: '4px', ...(selected && { backgroundColor: 'white' }) }}
        >
            <Button type='link' onClick={() => onClick(email)} style={{ padding: '8px' }}>
                <Typography.Text className="smallparagraph">{email}</Typography.Text>
            </Button>
        </div>
    )
}

const EmailLogsView = ({ email, emailSelected } : { email: Email, emailSelected: string | null }) => {

    const [events, setEvents] = useState<any[]>([])
    const [timelineitems, setTimelineitems] = useState<any[]>([])

    const getEvents = async () => {

        const db = getFirestore()
        const querySnapshot = await getDocs(
            query(collection(db, 'emails', email.id, 'events'), where('email', '==', emailSelected), orderBy('timestamp', 'desc')),
        )
        const eventsDocs = querySnapshot.docs.map(doc => doc.data())
        setEvents(eventsDocs)
    }

    useEffect(() => {
        if(emailSelected) {
            getEvents()
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(events?.length > 0) {
            const timelineitems = events.reverse().map((event: any) => {
                const eventData = emailsEventsData.find((eventData) => eventData.event === event.event)
                return {
                    id: event.id || generateCode(10),
                    timestamp: event.timestamp * 1000,
                    dot: eventData?.icon,
                    children: (
                        <div className="d-flex flex-column">
                            <Typography.Text className="smallparagraphbold">
                                {eventData?.label}
                                <Tooltip title={eventData?.description}>
                                    <Info style={{ marginLeft: '5px' }} />
                                </Tooltip>
                            </Typography.Text>
                            <Typography.Text className="smallparagraph">
                                {moment(event.timestamp * 1000).format('DD MMM YY HH:mm:ss')}
                            </Typography.Text>
                        </div>
                    ),
                }
            })
            setTimelineitems(timelineitems)
        } else {
            setTimelineitems([{
                id: generateCode(10),
                timestamp: email.timestamp || moment().valueOf(),
                dot: <CheckCircle size={14} />,
                children: (
                    <div className="d-flex flex-column">
                        <Typography.Text className="smallparagraphbold">
                            Correo enviado
                        </Typography.Text>
                        <Typography.Text className="smallparagraph">
                            {moment(email.timestamp).format('DD MMM YY HH:mm')}
                        </Typography.Text>
                    </div>
                )
            }])
        }
        // eslint-disable-next-line
    }, [events?.length])

    return (
        <div style={{ padding: '10px 20px', marginTop: '5px', backgroundColor: '#fafbfd' }}>
            <Timeline items={timelineitems} />
            <Divider style={{ margin: '10px 0' }} />
            <Typography.Text className="smallparagraph descriptions">
                *** Los eventos mostrados son regístros del sistema de envío de correos. Si el destinatario tiene
                configurado un bloqueador de cookies, es posible que no se registren todos los eventos.
            </Typography.Text>
        </div>
    )
}

const EmailDetails = ({ email } : { email: Email }) => {

    const [emailSelected, setEmailSelected] = useState<string | null>(email?.to?.length > 0 ? email.to[0] : null)

    return <div style={{ padding: '10px', marginTop: '5px', backgroundColor: '#fafbfd' }}>
        <Row>
            <Col xs={8}>
                {email?.to?.map((to, index) => (
                    <EmailButton key={`${to}-${index}`} selected={emailSelected === to} email={to} onClick={setEmailSelected} />
                ))}
            </Col>
            <Col xs={16}>
                <EmailLogsView email={email} emailSelected={emailSelected} />
            </Col>
        </Row>
    </div>
}

export default EmailsTable
