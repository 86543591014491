import { LightbulbFilament, SmileySad, StarFour } from '@phosphor-icons/react'
import { Alert, Col, Form, FormInstance, message, Row, Space, Tag, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { doc, getDoc, getFirestore } from 'firebase/firestore'
import { useAuth } from 'reactfire'
import { CSDForm } from '../../../components/Forms/Integrations/SatIntegration'
import { setStepLoading } from '../../../context/onboardingSlice'
import { setTeam } from '../../../context/teamsSlice'
import { SignedInternalAPIRequest } from '../../../functions/APIRequest'
import StepLayout from '../StepsLayout'
import { usePostHog } from 'posthog-js/react'

interface SatConnectionStepProps {
    form: FormInstance
    onStepCompleted: () => void
    renderButtons: () => React.ReactNode
}

const SatConnectionStep = ({ form, onStepCompleted, renderButtons }: SatConnectionStepProps) => {
    const dispatch = useDispatch()
    const auth = useAuth()
    const posthog = usePostHog()

    const { team } = useSelector((state: any) => state.team)

    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
        try {
            posthog.capture('onboarding_sat_section_viewed', {
                v: 'v2',
                billingAccount: team?.billingAccount,
                userId: auth?.currentUser?.uid,
            })
        } catch (error) {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleFinish = async (values: any) => {
        try {
            dispatch(setStepLoading(true))
            setError(null)

            // await updateDoc(doc(getFirestore(), 'teams', team.id), {
            //     'facturapi.completed': true,
            // })
            // dispatch(
            //     setTeam({
            //         ...team,
            //         facturapi: {
            //             completed: true,
            //         },
            //     }),
            // )
            const resp = await SignedInternalAPIRequest(
                {
                    password: values.password,
                    rfc: team.rfc,
                    invoicingIntegration: 'facturapi',
                    type: 'connect',
                    typeCsd: 'update',
                },
                'invoicing',
                auth.currentUser,
            )

            if (resp) {
                const latestTeam = await getDoc(doc(getFirestore(), 'teams', team.id))
                const teamData = latestTeam.data()

                try {
                    posthog.capture('sat_connection', {
                        from: 'onboarding',
                        v: 'v2',
                    })
                } catch (error) {}

                dispatch(setTeam(teamData))
                onStepCompleted()
                dispatch(setStepLoading(false))
            } else {
                throw new Error(resp.message || 'Error al conectar con el SAT')
            }
            onStepCompleted()
            dispatch(setStepLoading(false))
        } catch (error: any) {
            try {
                posthog.capture('sat_connection_errors', {
                    from: 'onboarding',
                    v: 'v2',
                })
            } catch (error) {}
            dispatch(setStepLoading(false))
            setError(error.message || 'Hubo un problema al conectar con el SAT. Por favor, intenta de nuevo.')
            console.error('Error al conectar con el SAT:', error)
            message.error(error.message || 'Hubo un problema al conectar con el SAT. Por favor, intenta de nuevo.')
            console.error(error.message)
        }
    }

    const satForm = (
        <Form
            form={form}
            onFinish={handleFinish}
            layout="vertical"
            style={{ width: '100%' }}
            onFinishFailed={(errorInfo) => {
                console.log('Form validation failed:', errorInfo)
                message.error('Por favor, corrige los errores en el formulario antes de continuar.')
            }}
        >
            <Row justify="center" gutter={[0, 0]} style={{ marginBottom: '24px' }}>
                <Col span={24} className="d-flex flex-column">
                    <Typography className="h3-bold" style={{ margin: 0 }}>
                        Conéctate con el SAT
                    </Typography>
                    <Typography
                        className="p-base-regular neutral-2"
                        style={{
                            color: 'var(--neutral-3)',
                        }}
                    >
                        Utiliza tu sello del Certificado Digital para conectarte con el SAT
                    </Typography>
                </Col>
            </Row>
            <CSDForm error={null} />
            {error && (
                <Alert
                    message={<Typography.Text className="p-base-bold red-6">Ocurró un error</Typography.Text>}
                    description={<Typography.Text className="p-base-regular red-6">{error}</Typography.Text>}
                    type="error"
                    showIcon
                    icon={<SmileySad color="var(--red-6)" />}
                    closable
                    onClose={() => setError(null)}
                    style={{
                        marginBottom: '24px',
                        backgroundColor: 'white',
                        borderRadius: '12px',
                        border: '1px solid var(--red-6)',
                    }}
                />
            )}
            {renderButtons()}
        </Form>
    )

    const supportElement = (
        <Row justify="center">
            <Col sm={18} md={18} lg={16}>
                <Space direction="vertical" size="large" style={{ width: '100%' }}>
                    <div>
                        <Typography className="h5-bold neutral-1" style={{ marginBottom: 8 }}>
                            ¿Qué son los Sellos de Certificado Digital?
                        </Typography>
                        <Typography className="p-base-regular neutral-2">
                            Un Certificado de Sello Digital (CSD) es un certificado digital específico para emitir
                            facturas electrónicas (CFDI) y otros documentos fiscales, expedido por el SAT.
                        </Typography>
                    </div>
                    <Row align="middle">
                        <LightbulbFilament size={18} style={{ marginRight: '10px' }} />
                        <Typography
                            className="p-base-bold neutral–2"
                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                            onClick={() => {
                                window.open(
                                    'https://helpcenter.gigstack.pro/es/articles/6902738-genera-en-el-sat-tu-csd-certificado-de-sello-digital',
                                    'WindowName',
                                    'noopener',
                                )
                            }}
                        >
                            Recuerda que el CSD no es igual que la FIEL
                        </Typography>
                    </Row>
                    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                        <Typography className="h5-bold neutral-1" style={{ marginBottom: 8 }}>
                            Beneficios
                        </Typography>
                        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                            <Tag
                                className="rounded-xl"
                                style={{ border: 'none', backgroundColor: 'var(--green-11)', padding: '0px 15px' }}
                            >
                                <StarFour
                                    weight="fill"
                                    size={16}
                                    color="var(--primary-1)"
                                    style={{ marginRight: '5px' }}
                                />
                                <Typography className="p-large-bold primary-1">Conexión segura</Typography>
                            </Tag>
                            <Tag
                                className="rounded-xl"
                                style={{ border: 'none', backgroundColor: 'var(--primary-1)', padding: '0px 15px' }}
                            >
                                <StarFour
                                    weight="fill"
                                    size={16}
                                    color="var(--green-11)"
                                    style={{ marginRight: '5px' }}
                                />
                                <Typography className="p-large-bold green-11">Validación directa con el SAT</Typography>
                            </Tag>
                            <Tag
                                className="rounded-xl"
                                style={{ border: 'none', backgroundColor: 'var(--primary-8)', padding: '0px 15px' }}
                            >
                                <StarFour
                                    weight="fill"
                                    size={16}
                                    color="var(--neutral-2)"
                                    style={{ marginRight: '5px' }}
                                />
                                <Typography className="p-large-bold neutral-2">Integridad de los datos</Typography>
                            </Tag>
                        </Space>
                    </Space>
                </Space>
            </Col>
        </Row>
    )

    return <StepLayout form={satForm} supportElement={supportElement} />
}

export default SatConnectionStep
