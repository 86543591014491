import { HandCoins } from '@phosphor-icons/react'
import { Typography } from 'antd'

const CollectionsPage: React.FC = () => {
    return (
        <>
            <Typography.Title level={4} className="mediumheader">
                <HandCoins style={{ width: '18px', height: '18px', marginRight: '6px' }} />
                Cobranza
            </Typography.Title>
        </>
    )
}
export default CollectionsPage
