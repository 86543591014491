import { useEffect, useRef, useState } from 'react'
import { Button, Input, InputRef, Space, Table, Tooltip, Typography } from 'antd'
import {
    query,
    collection,
    orderBy,
    getFirestore,
    where,
    limit,
    startAfter,
    getCountFromServer,
    getDocs,
} from 'firebase/firestore'
import { useSelector } from 'react-redux'
import { useFirestoreCollectionData } from 'reactfire'
import { OptimizedPaymentDef, Payment, optimizedPaymentDefSet } from '../../../interfaces/paymentsDef'
// import { useNavigate } from 'react-router-dom';
// import { openModal } from '../../context/modalsSlice';
import { PaymentColumnsObject } from './ColumnsSettlers'
import { useNavigate } from 'react-router-dom'
import { FilterConfirmProps } from 'antd/lib/table/interface'
import { MagnifyingGlass, X } from '@phosphor-icons/react'
import { ColumnType } from 'antd/es/table'
import { PendingPaymentsColumns } from './Columns/PaymentsColumns'
import { SearchParamInURL } from '../../functions/helpers'
// import moment from 'moment';

const PendingPaymentsTable = ({
    max: incomingMax = 10,
    search,
    startPeriod,
    endPeriod,
}: {
    max: number
    search?: any
    startPeriod?: number | null
    endPeriod?: number | null
}) => {
    const navigate = useNavigate()
    const { testmode } = useSelector((state: any) => state.data)

    const source = 'payments'
    let def: OptimizedPaymentDef | Payment
    let defSet = (data: any) => {
        // return data

        return optimizedPaymentDefSet(data)
    }

    //IMPORTS
    const fs = getFirestore()

    // HOOKS
    // const navigate = useNavigate()

    //CONTEXTS
    const { team } = useSelector((state: any) => state.team)

    const ps = SearchParamInURL('ps') ?? 10
    const p = SearchParamInURL('page') ?? 1

    let max = incomingMax

    if (p !== 1) {
        max = Number(ps) * Number(p)
    }

    //STATES
    const [newLoading, setNewLoading] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    const [pageSize, setPageSize] = useState(ps ?? 10)
    const [currentPage, setCurrentPage] = useState(p ?? 1)
    const [items, setItems] = useState<(typeof def)[]>([])

    const [, /*searchText*/ setSearchText] = useState('')
    const [, /*searchedColumn*/ setSearchedColumn] = useState('')
    const searchInput = useRef<InputRef>(null)

    type DataIndex = keyof typeof def

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = (clearFilters: () => void) => {
        clearFilters()
        setSearchText('')
    }

    //VARIABLES
    var initialQuery = query(
        collection(fs, source),
        where('team', '==', team.id),
        where('status', '==', 'review_requested'),
        where('livemode', '==', !testmode),
        orderBy('review_created_at', 'desc'),
        limit(max),
    )
    var totalCountQuery = query(
        collection(fs, source),
        where('team', '==', team?.id ?? ''),
        where('status', '==', 'review_requested'),
        orderBy('review_created_at', 'desc'),
        where('livemode', '==', !testmode),
    )

    //DATA
    const { status: initialLoadStatus, data } = useFirestoreCollectionData(initialQuery, {
        idField: 'fid',
    })

    //FUNCTIONS
    const getInitialCount = async () => {
        setTotalCount((await getCountFromServer(totalCountQuery)).data().count)
    }

    const getNewData = async ({ lastItem: newItem, ps, p }: { lastItem: number; ps: number; p: number }) => {
        if (ps * p < items.length) return
        setNewLoading(true)
        const newDataQuery =
            startPeriod && endPeriod
                ? query(
                      collection(fs, source),
                      where('team', '==', team?.id ?? ''),
                      where('timestamp', '>=', startPeriod),
                      where('timestamp', '<=', endPeriod),
                      orderBy('timestamp', 'desc'),
                      limit(ps * p - items.length + 1),
                      where('livemode', '==', !testmode),
                      startAfter(newItem),
                  )
                : query(
                      collection(fs, source),
                      where('team', '==', team?.id ?? ''),
                      orderBy('timestamp', 'desc'),
                      limit(ps * p - items.length + 1),
                      where('livemode', '==', !testmode),
                      startAfter(newItem),
                  )
        var newItems = await getDocs(newDataQuery)
        // setLastItem(newItem)

        setItems([...items, ...(newItems.docs.map((c) => defSet(c.data())) ?? [])])
        setNewLoading(false)
    }

    //EFFECTS
    useEffect(() => {
        getInitialCount()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, testmode, team?.id])

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<typeof def> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} className="d-flex flex-column" onKeyDown={(e) => e.stopPropagation()}>
                <Tooltip
                    title={`Solo buscará en los pagos que ya se hayan cargado en la lista (${items.length + max} de ${totalCount}).`}
                >
                    <Typography.Text className="smallparagraph descriptions" style={{ marginBottom: '5px' }}>
                        Búsqueda local
                    </Typography.Text>
                </Tooltip>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Typography.Text
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch(selectedKeys as string[], confirm, dataIndex)
                        }}
                        className="smallparagraph descriptions clickable"
                    >
                        Restablecer
                    </Typography.Text>

                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<MagnifyingGlass />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>

                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close()
                        }}
                    >
                        <X className="icon" />
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => {
            return (
                <MagnifyingGlass
                    style={{
                        color: filtered ? '#8666FF' : '#50566B',
                    }}
                    size={16}
                />
            )
        },
        onFilter: (value, record) => {
            return (
                (record[dataIndex] ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                (record.id ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) ||
                (record.fid ?? '')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase())
            )
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
            }
        },
        render: (text, record) =>
            PendingPaymentsColumns({
                record,
                columnName: 'clientInfo',
                navigate: () => {
                    navigate(`/paymentDetails?id=${record.fid}&page=${currentPage}&ps=${pageSize}&pn=payments`)
                },
            }),
    })

    return (
        <>
            <Table
                scroll={{ x: 1500 }}
                rowKey={(rec: any) => rec?.id ?? 'as'}
                loading={initialLoadStatus === 'loading' || newLoading}
                bordered
                pagination={{
                    total: totalCount,
                    defaultPageSize: Number(pageSize),
                    defaultCurrent: Number(currentPage),
                    pageSize: Number(pageSize),
                    current: Number(currentPage),
                    onChange(p, ps) {
                        setPageSize(ps)
                        setCurrentPage(p)
                        var allItems = [...data, ...items]
                        getNewData({ lastItem: allItems[allItems.length - 1]?.timestamp, ps, p })
                        const path = `/payments?subtab=actions&page=${p}&ps=${ps}`
                        navigate(path)
                    },
                }}
                columns={PaymentColumnsObject({
                    customKeysToShow: null,
                    navigate: (p: string) => navigate(p),
                }).map((c) => {
                    let column = c
                    if (c.dataIndex === 'clientInfo') {
                        column = {
                            ...column,
                            ...getColumnSearchProps('clientName'),
                        }
                    }
                    if (c.dataIndex === 'status') {
                        column = {
                            ...column,
                            filterMode: 'tree',
                            filterSearch: true,
                            filters: [
                                {
                                    text: 'Pagado',
                                    value: 'succeeded',
                                },
                                {
                                    text: 'Por revisar',
                                    value: 'review_requested',
                                },
                                {
                                    text: 'Pendiente',
                                    value: 'requires_payment_method',
                                },
                                {
                                    text: 'Acción Requerida',
                                    value: 'requires_action',
                                },
                            ],
                            onFilter: (value: string, record: any) => record.status.includes(value),
                        }
                    }
                    return column
                })}
                dataSource={[...(data ?? [])?.map((d) => defSet(d)), ...(items ?? []).map((d) => defSet(d))]}
            />
        </>
    )
}

export default PendingPaymentsTable
