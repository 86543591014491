import { LocalTaxElement, TaxElement } from './invoicesDef'

const handleAmount = (amount: number, taxes: TaxElement[]) => {
    let subtotal = Number(amount)
    let total = Number(amount)
    let includedTaxes = 0
    let excludedTaxes = 0
    let withholdedTaxes = 0
    taxes?.forEach((tax) => {
        var rate = Number(tax.rate) + 1
        if (tax.withholding || tax.inclusive) {
            withholdedTaxes += tax.withholding ? subtotal - subtotal / rate : 0
            includedTaxes += subtotal - subtotal / rate
        }

        if (!tax.inclusive) {
            excludedTaxes += subtotal * rate - subtotal
        }
    })

    return {
        taxes,
        subtotal: subtotal,
        total,
        includedTaxes: includedTaxes,
        excludedTaxes: excludedTaxes,
        withholdedTaxes: withholdedTaxes,
    }
}

export interface InternalItem {
    from?: string
    hours?: number | null
    description: string
    quantity: number
    owner: string
    discount: number
    product_key: string
    unit_key: string
    unit_name: string
    feeAdded?: number
    id: string
    paymentType: {
        label: 'Precio fijo'
        value: 'fixed' | 'hour'
    }
    total: number
    timestamp: number
    taxes: TaxElement[]
    localTaxes?: LocalTaxElement[]
    name: string
    tax_included?: boolean
    localChanges?: boolean
    fid?: string
    sku?: string
    team?: string
    billingAccount?: string
    property_tax_account?: string
    amounts?: {
        subtotal?: number
        total?: number
        includedTaxes?: number
        excludedTaxes?: number
        withholdedTaxes?: number
    }
    livemode?: boolean
    disabled?: boolean
    localId?: string
}

export function taxIncluded(taxes: TaxElement[]): boolean {
    let taxIncluded = false
    taxes?.forEach((tax) => {
        if (tax.inclusive) {
            taxIncluded = true
        }
    })
    return taxIncluded
}

export const internalItemSet = (object: any) => {
    const internalItem: InternalItem = {
        from: object['from'] ?? 'manual',
        description: object['description'] ?? null,
        quantity: object['quantity'] ?? null,
        owner: object['owner'] ?? null,
        discount: object['discount'] ?? null,
        product_key: object['product_key'] ?? null,
        unit_key: object['unit_key'] ?? null,
        unit_name: object['unit_name'] ?? null,
        id: object['id'] ?? null,
        paymentType: object['paymentType'] ?? { label: 'Precio fijo', value: 'fixed' },
        total: object['total'] ?? null,
        timestamp: object['timestamp'] ?? null,
        taxes: object['taxes'] ?? null,
        name: object['name'] ?? null,
        tax_included: taxIncluded(object['taxes']) ?? null,
        feeAdded: object['feeAdded'] ?? null,
        hours: object['hours'] ?? null,
        localChanges: object['localChanges'] ?? false,
        property_tax_account: object['property_tax_account'] ?? null,
        fid: object['fid'] ?? null,
        sku: object['sku'] ?? null,
        team: object['team'] ?? null,
        billingAccount: object['billingAccount'] ?? null,
        amounts: handleAmount(object['total'], object['taxes']),
        livemode: object['livemode'] ?? false,
        localId: object['id'] ?? null,
    }
    return internalItem
}
