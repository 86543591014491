import React from 'react'
import { FormInstance, Col, Row, Form, Button, message, Typography, Alert } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { SelectInput } from './Inputs'
import { setTeam } from '../../context/teamsSlice'
import { arrayUnion, doc, getFirestore, updateDoc } from 'firebase/firestore'
import { PlayCircle, ProhibitInset, StopCircle, WarningCircle } from '@phosphor-icons/react'
import moment from 'moment'
import SwitchHolder from './SwitchHolder'
import { ReceiptsPeriodicity } from '../../datasets/Receipts'
import { closeModal } from '../../context/modalsSlice'

interface PropsDef {
    incomingForm?: FormInstance
    buttonTitle?: string
}

const GeneralSalesReceiptsForm: React.FC<PropsDef> = ({ incomingForm, buttonTitle }) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const usingForm = incomingForm ?? form
    const [messageApi, contextHolder] = message.useMessage()
    const { team } = useSelector((state: any) => state.team)
    const { user } = useSelector((state: any) => state.user)

    const onFinish = async (values: any) => {
        try {
            await usingForm.validateFields()
            messageApi.open({
                type: 'loading',
                content: 'Actualizando información...',
                duration: 0,
            })
            dispatch(setTeam({ ...team, defaults: { ...team?.defaults, ...values } }))
            await updateDoc(doc(getFirestore(), 'teams', team?.id), {
                'defaults.periodicity': values.periodicity ?? team?.defaults?.periodicity ?? 'month',
                logs: arrayUnion({
                    date: moment().valueOf(),
                    user: user.uid,
                    value: values,
                    description: `Configuración de recibos de venta`,
                }),
            })
            message.success('Información actualizada correctamente')
            dispatch(closeModal('settingsDrawerVisible'))
        } catch (e) {
            message.error('Ocurrió un error al actualizar la información.')
        }
    }
    return (
        <Form
            layout="vertical"
            form={usingForm}
            onFinish={onFinish}
            initialValues={{ ...team, ...(team?.defaults ?? {}) }}
        >
            {/* Form content goes here */}
            {contextHolder}
            <Row>
                <Col xs={24}>
                    <SwitchHolder
                        changeState={async (v: any) => {
                            dispatch(setTeam({ ...team, defaults: { ...team?.defaults, avoidReceipts: !v } }))
                            await updateDoc(doc(getFirestore(), 'teams', team?.id), {
                                'defaults.avoidReceipts': !v,
                                logs: arrayUnion({
                                    date: moment().valueOf(),
                                    user: user.uid,
                                    value: !v,
                                    description: `Emitir recibos de venta avoidReceipts:${v}`,
                                }),
                            })
                        }}
                        switchState={!team?.defaults?.avoidReceipts}
                        icon={
                            team?.defaults?.avoidReceipts ? (
                                <StopCircle size={20} className="icon" weight="regular" />
                            ) : (
                                <PlayCircle size={20} className="icon" weight="regular" />
                            )
                        }
                        title={'Crear recibos de venta'}
                        description="Crearemos recibos de venta para habilitar un portal de auto-factura cuando no podamos hacer una factura de forma automática"
                    />

                    {team?.defaults?.avoidReceipts && (
                        <Alert
                            style={{ marginTop: '15px' }}
                            type="warning"
                            showIcon
                            icon={<WarningCircle />}
                            message={
                                <Typography.Text className="smallparagraph descriptions">
                                    No crearemos recibos de venta para los pagos a los que no pudimos emitir factura. Es
                                    importante tener en cuenta que las facturas al público en general no se emitirán.
                                </Typography.Text>
                            }
                        />
                    )}
                </Col>
                {!team?.defaults?.avoidReceipts && (
                    <>
                        <Col xs={24} style={{ marginTop: '15px' }}>
                            <SwitchHolder
                                changeState={async (v: any) => {
                                    dispatch(
                                        setTeam({
                                            ...team,
                                            defaults: { ...team?.defaults, cancelReceiptsOnRefunds: v },
                                        }),
                                    )
                                    await updateDoc(doc(getFirestore(), 'teams', team?.id), {
                                        'defaults.cancelReceiptsOnRefunds': v,
                                        logs: arrayUnion({
                                            date: moment().valueOf(),
                                            user: user.uid,
                                            value: v,
                                            description: `Cancelar recibos de venta reembolsados cancelReceiptsOnRefunds:${v}`,
                                        }),
                                    })
                                }}
                                switchState={team?.defaults?.cancelReceiptsOnRefunds}
                                icon={<ProhibitInset size={20} className="icon" weight="regular" />}
                                title={'Cancelar recibos de pagos reembolsados'}
                                description="En caso de que detectemos un reembolso y el pago contenga un recibo de venta abierto, cancelaremos el recibo para su factura."
                            />
                        </Col>
                        <Col xs={24} style={{ marginTop: '15px' }}>
                            <SelectInput
                                rules={[
                                    {
                                        required: true,
                                        message: 'Selecciona una periodicidad',
                                    },
                                ]}
                                options={ReceiptsPeriodicity}
                                name="periodicity"
                                description={
                                    'La factura global se realizará en la periodicidad configurada. Por ejemplo, si la periodicidad es diaria y se emite una venta el día de hoy, se realizará una factura global hoy a las 11:59pm.'
                                }
                                label="Periodicidad de los recibos"
                                placeholder="Diario"
                            />
                        </Col>
                    </>
                )}
                <Col xs={24} style={{ marginTop: '25px' }}>
                    <Row justify="end">
                        <Button type="primary" htmlType="submit">
                            {buttonTitle ?? 'Actualizar información'}
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Form>
    )
}

export default GeneralSalesReceiptsForm
