import { createSlice } from '@reduxjs/toolkit'
import { doc, getDoc, getFirestore } from 'firebase/firestore'
import { billingAccountDefSet, BillingAccountDef } from '../../interfaces/billingAccountDef'

//CREATE A FUNCTION THAT GETS THE BILLING ACCOUNT INFO FROM THE DATABASE AND SET INTO CONTEXT

const getBillingAccountInfo = (BAId: string) => {
    const fs = getFirestore()
    try {
        const ba = billingAccountDefSet(
            getDoc(doc(fs, 'billingAccounts', BAId)).then((doc) => {
                if (doc.exists()) {
                    return doc.data()
                } else {
                    throw new Error('Billing account not found')
                }
            }),
        )
        return ba
    } catch (error) {
        throw error
    }
}

interface BillingAccountState {
    billingAccount: BillingAccountDef
}

const initialState: BillingAccountState = {
    billingAccount: billingAccountDefSet({}),
}

export const billingAccountSlice = createSlice({
    name: 'billingAccount',
    initialState,
    reducers: {
        setBillingAccount: (state, action) => {
            state.billingAccount = action.payload
        },
        getBillingAccount: (state, action) => {
            state.billingAccount = billingAccountDefSet(getBillingAccountInfo(action.payload))
            // state.billingAccount = getBillingAccountInfo(action.payload)
        },
    },
})

export const { setBillingAccount, getBillingAccount } = billingAccountSlice.actions

// const getBillingAccountInfo = (BAId: string) => {
//     const fs = getFirestore()
//     try {
//         const ba = billingAccountDefSet((getDoc(doc(fs, 'billingAccounts', BAId))).then((doc) => {
//             if (doc.exists()) {
//                 return doc.data()
//             } else {
//                 throw new Error('Billing account not found')
//             }
//         }))
//         return ba
//     } catch (error) {
//         throw error
//     }
// }

// interface BillingAccountState {
//     billingAccount: BillingAccountDef
// }

// const initialState: BillingAccountState = {
//     billingAccount: billingAccountDefSet({}),
// }

// export const billingAccountSlice = createSlice({
//     name: 'billingAccount',
//     initialState,
//     reducers: {
//         setBillingAccount: (state, action) => {
//             state.billingAccount = action.payload
//         },
//         getBillingAccount: (state, action) => {
//             state.billingAccount = billingAccountDefSet(getBillingAccountInfo(action.payload));
//             // state.billingAccount = getBillingAccountInfo(action.payload)
//         }
//     }
// })

// export const { setBillingAccount, getBillingAccount } = billingAccountSlice.actions

export default billingAccountSlice.reducer
