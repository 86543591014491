import { notification } from 'antd'
import { useCallback, useState } from 'react'

import {
    createUserWithEmailAndPassword,
    getAuth,
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup,
    updateProfile,
} from 'firebase/auth'
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { setBillingAccount } from '../context/billingAccountSlice'
import { setTeam } from '../context/teamsSlice'
import { setUser } from '../context/userSlice'
import { preserveQueryParams, ReadableFirebaseErrorMessage } from '../functions/helpers'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const useCustomAuth = () => {
    const [loading, setLoading] = useState<string[]>([])
    const [searchParams, ] = useSearchParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const requestId = searchParams.get('request_id')

    const handleAuthError = (error: any) => {
        console.error('Auth error:', error)
        notification.error({
            message: 'Error al iniciar sesión',
            description: ReadableFirebaseErrorMessage(error),
        })
    }

    const navigateToOAuth = useCallback(
        () => navigate(preserveQueryParams('/oauth', searchParams)),
        [navigate, searchParams],
    )

    const login = async (email: string, password: string) => {
        setLoading((l) => [...l, 'email'])
        try {
            const user = await signInWithEmailAndPassword(getAuth(), email, password)
            dispatch(setUser({ uid: user.user?.uid }))
            // Navigate or perform additional actions after successful login
            if (requestId) {
                navigateToOAuth()
            }
        } catch (error) {
            handleAuthError(error)
        } finally {
            setLoading((l) => l.filter((e) => e !== 'email'))
        }
    }

    const signup = async (formData: any) => {
        setLoading((l) => [...l, 'email'])
        try {
            const user = await createUserWithEmailAndPassword(getAuth(), formData.email, formData.password)
            dispatch(setUser({ uid: user.user?.uid }))

            await updateProfile(user.user, {
                displayName: formData.fullName,
            })
            await setDoc(
                doc(getFirestore(), 'users', user.user.uid),
                {
                    name: formData.fullName,
                    firstName: formData.fullName.split(' ')[0],
                    lastName: formData.fullName.split(' ')[1],
                    email: formData.email,
                    uid: user.user.uid,
                    timestamp: moment().valueOf(),
                },
                { merge: true },
            )
            dispatch(
                setUser({
                    name: formData.fullName,
                    firstName: formData.fullName.split(' ')[0],
                    lastName: formData.fullName.split(' ')[1],
                    email: formData.email,
                    uid: user.user.uid,
                    timestamp: moment().valueOf(),
                }),
            )
            await new Promise((resolve) => setTimeout(resolve, 200))
            // navigate('/')
        } catch (error) {
            handleAuthError(error)
        } finally {
            setLoading((l) => l.filter((e) => e !== 'email'))
        }
    }

    const googleSignIn = async () => {
        setLoading((l) => [...l, 'google'])
        try {
            const auth = getAuth()
            const provider = new GoogleAuthProvider()
            const userCredential = await signInWithPopup(auth, provider)
            const user = userCredential.user
            dispatch(setUser({ uid: user?.uid }))

            const db = getFirestore()
            const userDocRef = doc(db, 'users', user.uid)
            const userDoc = await getDoc(userDocRef)

            if (!userDoc.exists()) {
                // User doesn't exist, create new user document

                const userData = {
                    name: user.displayName ?? user.email?.split('@')[0],
                    firstName: user.displayName ?? user.email?.split('@')[0],
                    lastName: '',
                    email: user.email,
                    uid: user.uid,
                    timestamp: moment().valueOf(),
                }
                dispatch(setUser(userData))
                await setDoc(userDocRef, userData, { merge: true })

                await updateProfile(user, {
                    displayName: user.displayName ?? user.email?.split('@')[0],
                })
            } else {
                // User exists, fetch existing data
                if (userDoc.exists() && userDoc?.data().lastTeamViewed) {
                    const lastTeam = getDoc(doc(db, 'teams', userDoc.data()?.lastTeamViewed))
                    dispatch(setTeam((await lastTeam).data()))
                }
                if (userDoc.exists() && userDoc?.data().lastBillingAccountViewed) {
                    const lastBa = getDoc(doc(db, 'billingAccounts', userDoc?.data().lastBillingAccountViewed))
                    dispatch(setBillingAccount((await lastBa).data()))
                }

                try {
                    await updateProfile(user, {
                        displayName: user.displayName ?? user.email?.split('@')[0],
                    })
                    const userData: any = {
                        ...userDoc.data(),
                        name: userDoc.data()?.name ?? user.displayName ?? user.email?.split('@')[0],
                        firstName: userDoc.data()?.firstName ?? user.displayName ?? user.email?.split('@')[0],
                        lastName: userDoc.data()?.lastName ?? '',
                        email: userDoc.data()?.email ?? user.email,
                        uid: user.uid,
                        timestamp: userDoc.data()?.timestamp ?? moment().valueOf(),
                    }
                    await setDoc(userDocRef, userData, { merge: true })
                    dispatch(setUser(userData))
                    if (requestId) {
                        navigateToOAuth()
                    }
                } catch (error) {
                    console.log('🚀 ~ googleSignIn ~ error:', error)
                }
            }
        } catch (error) {
            console.log('🚀 ~ googleSignIn ~ error:', error)
            handleAuthError(error)
        } finally {
            setLoading((l) => l.filter((e) => e !== 'google'))
        }
    }

    return { loading, login, signup, googleSignIn }
}
