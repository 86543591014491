import { createSlice } from '@reduxjs/toolkit'
import { FolioDef, FolioDefSet } from '../../interfaces/folioDef'

interface FolioState {
    folio: FolioDef
}

const initialState: FolioState = {
    folio: FolioDefSet({}),
}

export const folioSlice = createSlice({
    name: 'folio',
    initialState,
    reducers: {
        setFolio: (state, action) => {
            state.folio = action.payload
        },
    },
})

export const { setFolio } = folioSlice.actions

export default folioSlice.reducer
