import { Drawer, Empty, Input, Row, Select, Table, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
import { Configure, Hits, Index, InstantSearch } from 'react-instantsearch'
// import { algoliaSearchClient } from "./algoliaSetting"
import { setData } from '../context/dataSlice'
import { useEffect, useState } from 'react'
import { useForm } from 'antd/es/form/Form'
import { InvoicesColumns, TypeInvoicesColumnName } from '../components/Tables/Columns/InvoicesColumns'
import { PaymentsColumns } from '../components/Tables/Columns/PaymentsColumns'
import { ReceiptsColumns } from '../components/Tables/Columns/ReceiptsColumns'
import searchClient from './typesenseClient'
import { MagnifyingGlass } from '@phosphor-icons/react'
import { X } from '@phosphor-icons/react/dist/ssr'

const invoiceKeysToShow: any = {
    clientInfo: {
        width: '20%',
        title: 'Emitida a',
    },
    status: {
        width: '5%',
        title: '',
        align: 'center',
    },
    timestamp: {
        width: '10%',
        title: 'Fecha',
    },
    from: {
        width: '15%',
        title: 'Fuente',
    },
    invoiceType: {
        width: '5%',
        title: 'Tipo',
    },
    amount: {
        width: '15%',
        title: 'Total',
    },
    series: {
        width: '10%',
        title: 'Folio',
    },
    relations: {
        width: '5%',
        title: 'Relaciones',
    },
    actions: {
        width: '15%',
        title: 'Acciones',
    },
}

const PaymentskeysToShow: any = {
    clientInfo: {
        width: '20%',
        title: 'Cliente',
    },
    status: {
        width: '5%',
        title: '',
        align: 'center',
    },
    amount: {
        width: '10%',
        title: 'Monto',
        align: 'right',
    },
    timestamp: {
        width: '15%',
        title: 'Creado',
        align: 'center',
    },
    succeededTimestamp: {
        width: '15%',
        title: 'Pagado',
        align: 'center',
    },
    from: {
        width: '10%',
        title: 'Fuente',
        align: 'center',
    },
    automations: {
        width: '5%',
        title: 'Automatizaciones',
        align: 'center',
    },
    relations: {
        width: '5%',
        title: 'Relaciones',
        align: 'center',
    },
    actions: {
        width: '15%',
        title: 'Acciones',
        align: 'right',
    },
}
const ReceiptkeysToShow: any = {
    clientInfo: {
        width: '20%',
        title: 'Cliente',
    },
    status: {
        width: '10%',
        title: '',
    },
    timestamp: {
        width: '15%',
        title: 'Creado',
    },
    from: {
        width: '15%',
        title: 'Fuente',
    },
    amount: {
        width: '15%',
        title: 'Total',
    },
    relations: {
        width: '15%',
        title: 'Relaciones',
    },
    actions: {
        width: '10%',
        title: 'Acciones',
    },
}

export const SearchResultsView = ({ showIndexSelector }: { showIndexSelector: boolean }) => {
    const dispatch = useDispatch()

    const { testmode, search } = useSelector((state: any) => state.data)
    const { team } = useSelector((state: any) => state.team)

    const [isEmpty, setIsEmpty] = useState(false)

    const HitComponent = ({ hit }: { hit: any }) => {
        // const navigateTo = `/${search?.type === 'invoices' ? 'invoiceDetails' : search?.type === 'payments' ? 'paymentDetails' : 'receiptsDetail'}?id=${hit?.id}`
        const keysToShow =
            search?.type === 'invoices'
                ? invoiceKeysToShow
                : search?.type === 'payments'
                  ? PaymentskeysToShow
                  : ReceiptkeysToShow

        const HandleColumns = ({ ...props }: { record: any; columnName: string }) => {
            switch (search?.type) {
                case 'invoices':
                    return InvoicesColumns({
                        record: props.record,
                        columnName: props.columnName as TypeInvoicesColumnName,
                    })
                case 'payments':
                    return PaymentsColumns({ record: props.record, columnName: props.columnName })
                case 'receipts':
                    return ReceiptsColumns({ record: props.record, columnName: props.columnName })
                default:
                    return InvoicesColumns({
                        record: props.record,
                        columnName: props.columnName as TypeInvoicesColumnName,
                    })
            }
        }

        return (
            <Table
                pagination={false}
                showHeader={false}
                bordered={false}
                columns={Object.keys(keysToShow).map((key: any) => {
                    return {
                        dataIndex: key,
                        key: key,
                        width: `${keysToShow[key].width}`,
                        align: keysToShow[key].align ?? 'left',
                        render: (text: any, record: any) => HandleColumns({ record, columnName: key }),
                    }
                })}
                dataSource={[hit]}
                rowKey="id"
                size="small"
                onRow={(record: any) => {
                    return {
                        // onClick: () => {
                        //     dispatch(closeModal('ViewAllAlgoliaDrawerVisible'))
                        //     dispatch(setData({
                        //         item: 'search',
                        //         data: {
                        //             type: null,
                        //             query: ""
                        //         }
                        //     }))
                        //     navigate(navigateTo)
                        // }
                    }
                }}
            />
        )
    }

    return (
        <InstantSearch
            searchClient={searchClient({ team, testmode, indexName: search?.type })}
            indexName={search?.type}
            future={{
                preserveSharedStateOnUnmount: true,
            }}
        >
            {search.query && <Configure /*query={search?.query}*/ index={search?.type} /*filters={`livemode:${!testmode}`*}*/ />}
            <Input
                className=".main-search--input"
                prefix={<MagnifyingGlass size={12} weight="regular" />}
                style={{ margin: '5px 0px 20px 0px' }}
                placeholder={`Buscar...`}
                value={search?.query}
                onChangeCapture={(event: any) => {
                    dispatch(
                        setData({
                            item: 'search',
                            data: {
                                ...search,
                                query: event.target.value,
                            },
                        }),
                    )
                }}
            />
            <Row align="middle" justify="space-between">
                <Typography.Text className="smallheader">Resultados de búsqueda</Typography.Text>
                {showIndexSelector && (
                    <Select
                        options={[
                            { label: 'Pagos', value: 'payments' },
                            { label: 'Facturas', value: 'invoices' },
                            { label: 'Recibos de venta', value: 'receipts' },
                        ]}
                        style={{ width: '150px' }}
                        value={search?.type}
                        onChange={(v) => {
                            dispatch(
                                setData({
                                    item: 'search',
                                    data: {
                                        ...search,
                                        type: v,
                                    },
                                }),
                            )
                        }}
                    />
                )}
            </Row>
            <div style={{ marginTop: '30px' }}>
                <Index indexName={search?.type}>
                    <Hits
                        classNames={{ list: 'instant-search--algolia-search-hits-list' }}
                        style={{ listStyleType: 'none' }}
                        hitComponent={HitComponent}
                        transformItems={(items, { result }: any) => {
                            if (items.length === 0) {
                                setIsEmpty(true)
                            } else {
                                setIsEmpty(false)
                            }
                            return items
                        }}
                    />
                </Index>
            </div>

            {isEmpty && (
                <Row align="middle" justify="center">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No encontramos resultados" />
                </Row>
            )}
        </InstantSearch>
    )
}

export const ViewAllAlgolia = () => {
    const { ViewAllAlgoliaDrawerVisible } = useSelector((state: any) => state.modals)
    const { search, testmode } = useSelector((state: any) => state.data)

    const dispatch = useDispatch()
    const [form] = useForm()

    useEffect(() => {
        if (ViewAllAlgoliaDrawerVisible) {
            form.setFieldsValue({
                query: search.query,
            })
        }
        // eslint-disable-next-line
    }, [ViewAllAlgoliaDrawerVisible])

    // const readableType = search?.type === 'invoices' ? 'facturas' : search?.type === 'payments' ? 'pagos' : 'recibos'

    return (
        <Drawer
            open={ViewAllAlgoliaDrawerVisible}
            style={{ marginTop: testmode ? '32px' : '0px', paddingBottom: testmode ? '32px' : '0px' }}
            width={'100%'}
            closable={false}
            title={null}
            onClose={() => {
                dispatch(closeModal('ViewAllAlgoliaDrawerVisible'))
                dispatch(
                    setData({
                        item: 'search',
                        data: {
                            type: search?.type,
                            query: '',
                        },
                    }),
                )
            }}
            placement="right"
        >
            <div>
                <Row style={{ marginBottom: '10px' }}>
                    <X
                        size={20}
                        className="clickable"
                        onClick={() => {
                            dispatch(closeModal('ViewAllAlgoliaDrawerVisible'))
                            dispatch(
                                setData({
                                    item: 'search',
                                    data: {
                                        type: search?.type,
                                        query: '',
                                    },
                                }),
                            )
                        }}
                    />
                </Row>
                <SearchResultsView showIndexSelector={true} />
            </div>
        </Drawer>
    )
}
