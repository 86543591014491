import { Avatar, Button, Card, Typography } from 'antd'
import { usePostHog } from 'posthog-js/react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { InviteDef } from '../../../interfaces/invitesDef'
import { handleAcceptInvite } from '../../customHooks/useInvites'
import { setInviteLoading } from '../../context/onboardingSlice'

export const InviteCard = ({ invite }: { invite: InviteDef }) => {
    const posthog = usePostHog()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { inviteLoading } = useSelector((state: any) => state.onboarding)

    const acceptInvite = async () => {
        dispatch(setInviteLoading(inviteLoading.concat(invite?.id ?? '')))
        await handleAcceptInvite(invite, dispatch, posthog, navigate)
        dispatch(setInviteLoading(inviteLoading.filter((i: string) => invite?.id !== i)))
    }

    return (
        <Card style={{ width: '100%', borderRadius: 8, border: 'none', backgroundColor: 'var(--primary-5)' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar size={50} src={invite?.team?.logo} style={{ marginRight: '10px' }} />
                    <div className="d-flex" style={{ flexDirection: 'column' }}>
                        <Typography.Text style={{ margin: 0 }}>{invite?.teamName}</Typography.Text>
                        <span style={{ color: '#8C8C8C', fontSize: 12 }}>{invite.team?.members ?? 1} Miembros</span>
                    </div>
                </div>
                <Button
                    className="btn-primary"
                    size="small"
                    loading={inviteLoading?.includes(invite?.id)}
                    onClick={() => {
                        acceptInvite()
                    }}
                >
                    Aceptar invitación
                </Button>
            </div>
        </Card>
    )
}
