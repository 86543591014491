import { Form } from 'antd'
import { getBlob, getStorage, ref } from 'firebase/storage'
import { useDispatch, useSelector } from 'react-redux'
import { FileUpload } from '../../../components/Forms/Inputs'
import { setStepLoading } from '../../../context/onboardingSlice'
import { usePostHog } from 'posthog-js/react'

export const UploadCSFInput = ({
    onFiscalLoaded,
    loadingInfoRetrieval,
}: {
    onFiscalLoaded: (v: any) => void
    loadingInfoRetrieval?: boolean
}) => {
    const dispatch = useDispatch()
    const posthog = usePostHog()
    const { team } = useSelector((state: any) => state.team)

    return (
        <Form.Item name="fiscal-file">
            <FileUpload
                allowedFiles={['application/pdf']}
                label="Seleccionar constancia de situación fiscal"
                name="fiscal-file"
                placeholder="Pre-llena la información desde tu constancia"
                storagePath={`teams/${team.id}/files/csf`}
                firestorePath={`teams/${team.id}/files/csf`}
                previousDataKey="brand"
                firestoreKey="csd"
                onFileUploaded={async (file: any) => {
                    console.log('File uploaded:', file)
                    try {
                        posthog.capture('onboarding_fiscal_from_csf', {
                            v: 'v2',
                        })
                    } catch (error) {}
                    dispatch(setStepLoading(true))
                    const fileReference = ref(getStorage(), file.url)
                    const fileBlob = await getBlob(fileReference)

                    const formData = new FormData()
                    formData.append('constancia', fileBlob, file.name)

                    const res = await fetch(
                        `${process.env.REACT_APP_INTERNAL_API_URL}/fiscalDataAPI/v1/getFromConstancia`,
                        {
                            method: 'POST',
                            body: formData,
                        },
                    )

                    const data = await res.json()
                    console.log(data)

                    const fiscalData = data?.fiscalData || {}
                    dispatch(setStepLoading(false))
                    if (onFiscalLoaded) {
                        onFiscalLoaded(fiscalData)
                    }
                }}
            />
        </Form.Item>
    )
}
