import { Typography } from 'antd'
import React from 'react'
import { SwapSpinner } from 'react-spinners-kit'

interface LoaderProps {
    loading: boolean
    color?: string
}
const Loader: React.FC<LoaderProps> = ({ loading, color = 'var(--primary-3)' }) => {
    if (!loading) {
        return null
    }

    return (
        <div
            className="loader-container d-flex flex-column"
            style={{ justifyContent: 'center', alignItems: 'center', height: '100vh' }}
        >
            <SwapSpinner size={90} color={color} loading={loading} />
            <Typography.Text style={{ marginTop: '50px' }} className="p-base-bold neutral-3">
                Cargando{' '}
            </Typography.Text>
        </div>
    )
}

export default Loader
