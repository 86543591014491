import { Button, Form, Modal, Row, Typography, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal, setElementSelected } from '../context/modalsSlice'
import { SignedInternalAPIRequest } from '../functions/APIRequest'
import { useForm } from 'antd/es/form/Form'
import { useAuth } from 'reactfire'
import { useEffect, useState } from 'react'
import { SelectInput, TextInput } from '../components/Forms/Inputs'

const CancelInvoiceModal = () => {
    const dispatch = useDispatch()
    const { cancelInvoiceModalVisible } = useSelector((state: any) => state.modals)
    //access to the element invoice in state modals
    const { invoice } = useSelector((state: any) => state.modals)
    const [loading, setLoading] = useState(false)
    const auth = useAuth()
    const [form] = useForm()

    const CancelInvoice = async (invoice: any) => {
        try {
            setLoading(true)
            const req = {
                invoicingIntegration: 'facturapi',
                type: 'cancel_invoice',
                test: !invoice?.livemode,
                id: invoice?.id,
                ...invoice,
            }
            const resp = await SignedInternalAPIRequest(req, 'invoicing', auth.currentUser)
            message.success(
                resp?.status === 'canceled'
                    ? 'Factura cancelada correctamente'
                    : resp.cancellationCodeText || 'Hemos solicitado la cancelación de la factura',
            )
            dispatch(closeModal('cancelInvoiceModalVisible'))
            dispatch(
                setElementSelected({
                    element: 'invoice',
                    data: null,
                }),
            )
            setLoading(false)
        } catch (error: any) {
            setLoading(false)
            message.error(error.message)
        }
    }

    useEffect(() => {
        // console.log("CANCEL INVOICE MODAL: ", invoice)
        form.setFieldValue('substitution', invoice?.uuid ?? null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Modal
            title={null}
            open={cancelInvoiceModalVisible}
            onCancel={() => {
                dispatch(closeModal('cancelInvoiceModalVisible'))
                dispatch(
                    setElementSelected({
                        element: 'invoice',
                        data: null,
                    }),
                )
            }}
            footer={null}
        >
            <div>
                <Typography.Title level={4} style={{ padding: 0, margin: 0 }}>
                    Cancelar factura <span style={{ color: '#b7b7b7', fontSize: '16px', margin: 0, padding: 0 }}></span>
                </Typography.Title>
                <Typography.Text style={{ padding: 0, margin: 0, marginBottom: '15px' }}>
                    {invoice?.series}-{invoice?.folio_number}
                </Typography.Text>
                <Form
                    layout="vertical"
                    name="cancelInvoice"
                    style={{ marginTop: '25px' }}
                    onFinish={(obj) => {
                        // Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key]);
                        // if (!obj.motive) {
                        //     return message.error('Debes especificar el motivo de la cancelación')
                        // }

                        CancelInvoice({ id: invoice?.id, livemode: invoice?.livemode, ...obj, motive: obj.motive })
                    }}
                >
                    <SelectInput
                        name={'motive'}
                        options={[
                            { value: '01', label: 'Comprobante emitido con errores con relación' },
                            { value: '02', label: 'Comprobante emitido con errores sin relación' },
                            { value: '03', label: 'No se llevó a cabo la operación' },
                            { value: '04', label: 'Operación nominativa relacionada en la factura global' },
                        ]}
                        placeholder="Motivo de la cancelación"
                        onChange={(e: any) => console.log(e)}
                    />
                    <Typography.Text type="secondary" style={{ fontSize: '10px' }}>
                        ID de la factura que sustituye a la factura que se está cancelando.
                    </Typography.Text>
                    <TextInput
                        name={'substitution'}
                        placeholder="UUID"
                        defaultValue={invoice?.actionType === 'substitute' ? invoice?.uuid ?? null : null}
                    />
                    <Row justify="center" style={{ marginTop: '55px' }}>
                        <Button danger htmlType="submit" disabled={loading} loading={loading}>
                            Cancelar factura
                        </Button>
                    </Row>
                </Form>
            </div>
        </Modal>
    )
}

export default CancelInvoiceModal
