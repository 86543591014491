import { useDispatch } from 'react-redux'
import { setData } from '../dataSlice'
import { setElementSelected } from '../modalsSlice'

const useSetContextCloseDrawers = ({ form }: { form?: any }) => {
    const dispatch = useDispatch()
    const setContextCloseDrawers = () => {
        form.resetFields()
        dispatch(
            setElementSelected({
                element: 'receipt',
                data: null,
            }),
        )
        dispatch(
            setElementSelected({
                element: 'recurringPayment',
                data: null,
            }),
        )
        dispatch(
            setData({
                item: 'client',
                data: null,
            }),
        )
        dispatch(
            setData({
                item: 'selectedServices',
                data: [],
            }),
        )
        dispatch(
            setData({
                item: 'addAutomations',
                data: [],
            }),
        )
        dispatch(
            setData({
                item: 'limitDaysToPay',
                data: null,
            }),
        )
        dispatch(
            setData({
                item: 'custom_method_types',
                data: [],
            }),
        )
        dispatch(
            setData({
                item: 'payment_method',
                data: [],
            }),
        )
        dispatch(
            setData({
                item: 'addAutomations',
                data: [],
            }),
        )
        dispatch(
            setData({
                element: 'recurringEventData',
                data: null,
            }),
        )
        dispatch(
            setData({
                item: 'custom_method_types',
                data: [],
            }),
        )
        dispatch(
            setData({
                item: 'payment_method',
                data: [],
            }),
        )
        dispatch(
            setData({
                item: 'invoice',
                data: null,
            }),
        )
        dispatch(
            setData({
                item: 'payment_form',
                data: null,
            }),
        )
        dispatch(
            setData({
                item: 'currency',
                data: null,
            }),
        )
        dispatch(
            setData({
                item: 'exchage',
                data: null,
            }),
        )
        dispatch(
            setData({
                item: 'periodicity',
                data: null,
            }),
        )
        dispatch(
            setData({
                item: 'livemode',
                data: null,
            }),
        )
        dispatch(
            setData({
                item: 'temporality',
                data: null,
            }),
        )
    }
    return setContextCloseDrawers
}

export default useSetContextCloseDrawers
